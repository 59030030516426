import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProfileConfig } from './../../profile.config';

@Component({
  selector: 'view-profile-address',
  templateUrl: './view-profile-address.component.html',
  providers: []
})

export class ViewProfileAddressComponent {
  @Input() viewProfileData: Object;
  profileKey: string;
 // isAddressLine2Required: boolean = false;

  constructor() {
  }

  ngOnInit() {
    this.profileKey = ProfileConfig.getViewProfileAddressKey();
    // if (this.viewProfileData['address_line_2'] !== undefined && this.viewProfileData['address_line_2'] !== '') {
    //   this.isAddressLine2Required = true;
    // } else {
    //   this.isAddressLine2Required = false;
    // }
  }
}