import { Component, OnInit, AfterContentInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/fieldconfig.interface';
import { FormRadioExplanationConfig } from './form-radio-explanation.config';
import { EventBusService } from './../../../../framework/eventbus.service';
import { LocalStorageService } from 'framework/localstorage.service';


@Component({
  selector: 'form-radio-explanation',
  styleUrls: ['form-radio-explanation.component.scss'],
  templateUrl: './form-radio-explanation.component.html'
})

export class FormRadioExplanationComponent implements Field, OnInit, AfterViewInit {
  config: FieldConfig;
  group: FormGroup;

  // text area object
  textArea: Object = {
    'name': '',
    'options': ''
  };

  // User selected radio button status
  radioSelection = [];

  // Textarea name of selected radio button
  textAreaOfSelectedRadio: string = '';
  isConditionalRadio: boolean = false;

  constructor(private _eventBus: EventBusService, private _localStorage: LocalStorageService) { }

  ngOnInit() {
    /*
     * CLIENT FIX: Radio Button: if no options is provided,
    */
    if (this._localStorage.getItem('IS_CONDITIONAL_RADIO') === 'Yes') {
      this.isConditionalRadio = true;
    } else {
      this.isConditionalRadio = false;
    }

    if (this.config !== undefined && ((this.config['options'] === undefined) ||
      (this.config['options'] !== undefined && this.config['options'].length === 0))) {
      this.config['options'] = FormRadioExplanationConfig.DEFAULT_OPTIONS;

      // text area control object details setting
      this.textArea['name'] = `${this.config['name']}_textarea`;
      this.textArea['options'] = this.prepareTextAreaOptions();

      // Need to handle Review & Edit for showing TextArea controls
      if (this.config['value']) {
        let concatValues = this.config['value'].split('|');

        // Update 'value' set in PUT call : TRUE | <<Text area value>> to TRUE
        // Set the user clicked radio button
        if (concatValues[0]) {
          this.config['value'] = concatValues[0];
          this.group.controls[this.config.name].setValue(concatValues[0]);

          for (let index in this.config['options']) {
            let x = (this.config['value'] === this.config['options'][index]['value']) ? true : false;
            this.radioSelection.push(x);
          }
        }

        // Textarea display & value
        if (concatValues[1]) {
          this.manageTextArea(this.config['value'], concatValues[1]);
        }
      }
      if (this.isConditionalRadio && this.config['indicator'] === 'CONDITIONAL_RADIO') {
        this.manageTextArea('TRUE');
      }
    }
  }

  ngAfterViewInit() {

  }

  /**
   *
   * @param data
   * @param selectedOption
   */
  onSelectionChange(data: any, selectedOption: any) {
    if (data['indicator'] !== undefined && data['indicator'] === 'CONDITIONAL_RADIO') {
      this._eventBus.conditionalRadioAnnounced({
        selectedObj: { indicator: data['indicator'], option: selectedOption }
      });
      if (selectedOption === 'TRUE') {
        this._localStorage.setItem('IS_CONDITIONAL_RADIO', 'Yes');
      } else {
        this._localStorage.setItem('IS_CONDITIONAL_RADIO', 'No');
      }
    }
    this.manageTextArea(selectedOption);
  }

  /**
   *
   * @param display
   * @param value
   */
  manageTextArea(display: string, value: string = ''): void {
    // display = true >> add and display the control
    if (display === 'TRUE') {
      // Set it mandatory based on parameter
      if (this.config['validation_data']['is_required'] || this.textArea['options']['required']) {
        let validators = [];
        validators.push(Validators.required);
        validators.push(this.nonBlankValidator);
        this.group.addControl(this.textArea['name'], new FormControl(value, validators));
      } else {
        this.group.addControl(this.textArea['name'], new FormControl(value));
      }

      this.textAreaOfSelectedRadio = this.textArea['name'];
    } else {
      this.textAreaOfSelectedRadio = '';
      this.group.removeControl(this.textArea['name']);
    }
  }

  /**
   * Prepare textarea 'option' object. Template of it is in FormRadioExplanationConfig.TEXTAREA_OPTIONS.
   * But if any parameter comes, then that value will be used
   */
  prepareTextAreaOptions(): Object {
    let optionObject = {
      'required': FormRadioExplanationConfig.TEXTAREA_OPTIONS['required'],
      'visible': FormRadioExplanationConfig.TEXTAREA_OPTIONS['visible']
    };

    // Set max length based of parameter else take from form-radio-explanation.config.ts
    if (this.config['validation_data']['max_length']) {
      optionObject['maxlength'] = this.config['validation_data']['max_length'];
    } else {
      optionObject['maxlength'] = FormRadioExplanationConfig.TEXTAREA_OPTIONS['maxlength'];
    }

    if (this.config['explaination_label']) {
      optionObject['label'] = this.config['explaination_label'];
    } else {
      optionObject['label'] = FormRadioExplanationConfig.TEXTAREA_OPTIONS['label'];
    }

    return optionObject;

  }

  nonBlankValidator(control: AbstractControl): { [key: string]: any } | null {
    if (!!control.value) {
      let val = control.value.trim();
      if (val.length === 0) {
        return { nonBlank: true }
      }
    }
    return null;
  }

}
