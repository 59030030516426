<div>
  <button type="button" class="btn arrowrightfl" (click)="onOptionalNextClick()" *ngIf="isInviteEiaf"><i
      class="icon-arrow-right pull-right" role="img" [attr.aria-label]="'SCREEN_FARWARD_ARROW' | translate"></i></button>
  <button type="button" class="btn arrowleftfl" (click)="getPrevSwipe()" *ngIf="!inputOptionalIsDocEmptyList || isInviteEiaf"><i
      class="icon-arrow-left" role="img" [attr.aria-label]="'SCREEN_BACKWARD_ARROW' | translate"></i></button>
  <div class="anime-clean contetnheight pad_30 posrel" (onGesture)="doSwipe($event)" hammer-gestures>
    <div class="row">
      <div class="col-md-12 col-xs-12 col-sm-12"  *ngIf="!isInviteEiaf">
        <div class="header-txt header_txt_white" *ngIf="inputIsOptionalDocsPresent">
          <h2 class="m-b-20"  *ngIf="!isInviteAssessment">{{ 'OPTIONAL_DOCUMENT_PRIMARY_TEXT' | translate }}</h2>
          <h2 class="m-b-20"  *ngIf="isInviteAssessment">{{ 'ADHOC_DOCUMENT_UPLOAD_ASSESSMENT_TEXT' | translate }}</h2>
          <h4 class="m-b-20">{{ 'OPTIONAL_DOCUMENT_SECONDARY_TEXT' | translate }}</h4>
        </div>
        <div class="header-txt header_txt_white" *ngIf="!inputIsOptionalDocsPresent">
          <h2 class="m-b-20">{{ 'DOCUMENT_UPLOAD_PRIMARY_TEXT_EMPTY' | translate:[firstName] }}</h2>
          <h4 class="m-t-10 m-b-20">{{ 'DOCUMENT_UPLOAD_SECONDARY_TEXT_EMPTY' |  translate }}</h4>
        </div>
      </div>

      <div class="col-md-12 col-xs-12 col-sm-12" *ngIf="isInviteEiaf">
          <div class="header-txt header_txt_white" *ngIf="inputIsOptionalDocsPresent">
            <h2 class="m-b-20">{{ 'OPTIONAL_DOCUMENT_EIAF_PRIMARY_TEXT' | translate:[companyName] }}</h2>
            <h4 class="m-b-20">{{ 'OPTIONAL_DOCUMENT_EIAF_SECONDARY_TEXT' | translate }}</h4>
          </div>
          <div class="header-txt header_txt_white" *ngIf="!inputIsOptionalDocsPresent">
            <h2 class="m-b-20">{{ 'OPTIONAL_DOCUMENT_EIAF_PRIMARY_TEXT' | translate:[companyName] }}</h2>
            <h4 class="m-t-10 m-b-20">{{ 'DOCUMENT_UPLOAD_EIAF_SECONDARY_TEXT_EMPTY' |  translate }}</h4>
          </div>
      </div>
    </div>
    <div class="row docListStyle">
      <ul>
        <li *ngFor='let option of inputOptionalGroupObject; let i=index;'>
          <div class="row">
            <div class="col-md-12 col-xs-12 col-sm-12" style="padding-bottom:5px">
              <document-category [grpIndex]='i' [grpObj]='option' (documentOnUpload)='optionalUploadAction($event)'
                (documentOnDelete)='optionalDeleteAction($event)' (documentOnDownload)='optionalDownloadAction($event)'
                (documentOnView)='optionalViewAction($event)'
                (documentOnInstruction)='optionalInstructionAction($event)'></document-category>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="row p-t-15" >
      <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
        <button type="button" id="submit_profile" class="btn button grow font_extrabold senButton ssnfrmbtn"
          (click)="onOptionalNextClick()">{{ 'COMMONS_NEXT' | translate }}</button>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb" *ngIf="!isInviteEiaf">
        <button type="button" class="btn button_wbg grow font_extrabold sendButton button_wbgSav"
          (click)="onOptionalSaveExitClick()">{{ 'COMMONS_SAVE_AND_EXIT' | translate }}</button>
      </div>
    </div>
  </div>
</div>
