import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { LocalStorageService } from '../../../framework/localstorage.service';

declare var google: any;

export type Maps = typeof google.maps;


@Injectable()
export class GoogleApiService {
  constructor( private localStorage: LocalStorageService) {  }

  load(language): Promise<Maps> {
    
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    // tslint:disable-next-line:no-bitwise
    const callbackName = 'googleApiFailCallback';
    script.src = this.getScriptSrc(language, callbackName);

    interface MyWindow { [name: string]: Function; };
    const myWindow: MyWindow = window as any;

    const promise = new Promise((resolve, reject) => {
      myWindow[callbackName] = resolve;
      script.onerror = reject;
    });
    document.body.appendChild(script);

    // Set place search api to 'EA' if google api call fails
    return promise.then(() => {
      google.maps
    }).catch(err => {
      this.localStorage.setItem('place_search_api', 'EA');
    });
  }

  private getScriptSrc(language, callback: string): string {
    interface QueryParams { [key: string]: string; };
    const query: QueryParams = {
      v: '3',
      callback,
      key: environment.google_api_key,
      libraries: 'places',
    };
    const params = Object.keys(query).map(key => `${key}=${query[key]}`).join('&');
    return `//maps.googleapis.com/maps/api/js?${params}&language=${language}`;
  }

  validateGoogleApiService() {
    
    const displaySuggestions = function (
      predictions: google.maps.places.QueryAutocompletePrediction[] | null,
      status: google.maps.places.PlacesServiceStatus
    ) {
      
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        window['is_googel_api_valid'] = true;
      } else {
        window['is_googel_api_valid'] = false;
      }

    };

    const service = new google.maps.places.AutocompleteService();
    service.getQueryPredictions({ input: "pizza" }, displaySuggestions);
  }

  

}
