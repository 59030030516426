<div class="pad_30" [formGroup]="empSubForm" (onGesture)="doSwipe($event,empSubForm.value)" hammer-gestures>
  <button type="button" class="btn arrowrightctr" attr.data-id="empCityStateRightArrow_{{row}}"
    [disabled]="(empSubForm.controls.city.value === undefined || empSubForm.controls.city.value === '') || (empSubForm.controls.stateName.value === undefined || empSubForm.controls.stateName.value === '') || (isPaDOTDirectEnabled && countryOptions['countryShortName'] === 'US' && (empSubForm.controls.streetAddress.value === undefined || empSubForm.controls.streetAddress.value === '' || empSubForm.controls.zipCode.value === undefined || empSubForm.controls.zipCode.value === ''))"
    debounceDirective (debounceClick)="cityState(empSubForm.value)"> <i class="icon-arrow-right" role="img" [attr.aria-label]="'SCREEN_FARWARD_ARROW' | translate"></i></button>
  <button type="button" class="btn arrowleftctr" attr.data-id="empCityStateLeftArrow_{{row}}"
    debounceDirective (debounceClick)="getPrevSwipe()"><i class="icon-arrow-left" role="img" [attr.aria-label]="'SCREEN_BACKWARD_ARROW' | translate"></i></button>
  <div class="anime-clean contetnheight posrel">
    <div class="row">
      <div class="col-md-12 col-xs-12 col-sm-12">
        <div class="header_txt_white" *ngIf="empType === 'current' || (empType === 'selfEmployed' && row === 0)">
          <h2>{{'EMPLOYMENT_CITY_HEADER_1' | translate}}</h2>
          <!-- <h4 class="m-t-15">
           <i class="icon-work-experience"></i> <span class="company">{{empSubForm.controls['companyName'].value}}</span>
         </h4> -->
        </div>
        <div class="header_txt_white" *ngIf="empType === 'prior' || (empType === 'selfEmployed' && row > 0)">
          <h2>{{'EMPLOYMENT_CITY_HEADER_2' | translate:[userDetails.firstname] }}</h2>
          <!--
         <h4>{{'EMPLOYMENT_CITY_SUBHEADER_1' | translate}}</h4>
         -->
          <!-- <h4 class="m-t-15">
           <i class="icon-work-experience"></i> <span class="company">{{empSubForm.controls['companyName'].value}}</span>
         </h4> -->
        </div>
      </div>
    </div>
    <!--<pre>{{showCitySateErr}}</pre>-->
    <div class="row form-element-section btn_center">
      <div *ngIf="isPaDOTDirectEnabled && countryOptions['countryShortName'] === 'US'"
        class="col-xs-12 col-sm-12 col-md-12 m-b-10">
        <div class="group ">
          <input type="text" maxlength="100" attr.id="empStreetAddress_{{row}}" aria-required="true" class="form-control" formControlName="streetAddress">
          <span class="highlight"></span>
          <span class="bar"></span>
          <label class="placeholder_white" attr.for="empStreetAddress_{{row}}">{{ 'EMPLOYMENT_STREET_ADDRESS' | translate }}</label>
        </div>
      </div>
    </div>
    <div class="row ">
      <div *ngIf="isPaDOTDirectEnabled && countryOptions['countryShortName'] === 'US'"
        class="col-xs-12 col-sm-4 col-md-4 m-b-20">
        <div class="group padv-dropdown">
            <label class="control-labelss" [attr.for]="zipId">{{ 'ZIPCODE_LABLE' | translate }}</label>
          <!--
          <input type="text" maxlength="20" attr.id="empZipTextField_{{row}}" class="form-control" formControlName="zipCode"
            (keyup)="zipLookUp(empSubForm.value.zipCode, $event)" autocomplete="off"
            (blur)="setHiddenValuesZip()">
        -->
        <custom-select [items]="zipCodeList" [typeahead]="zipCodeTypeahead" [typeToSearchText]="" bindLabel="zip_code"
        bindvalue="zip_code" [group] = "empSubForm" [selectedValue]="selectedZipCode" controlName="zipCode" [id]="zipId"
        [dispValues]="['zip_code', 'city', 'state_code']" (valueChange)="trackZipCodeChange($event)" (clearData)="onClearZipCodeData()"></custom-select>

          <span class="highlight"></span>
          <span class="bar"></span>
          <span class="error_info" *ngIf="zipCodeError">
            <i class="fa fa-exclamation error_icon" aria-hidden="true" [class.red_error]="!zipCodeError"
              [class.cursor_hand_none]="zipCodeError" debounceDirective (debounceClick)="openServerError()"></i>
          </span>
          <div class="custom-error error_new_ui" *ngIf="zipCodeError">
            <div class="left_div">
              <ul>
                <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate }}</li>
                <li class="message">{{ 'ADDRESS_ZIPCODE_ERROR_MSG' | translate }}</li>
              </ul>
            </div>
            <div class="right_div">
              <div class="close" aria-hidden="true" debounceDirective (debounceClick)="closeServerError()">
                <i class="icon-cancel"></i>
              </div>
            </div>
          </div>
          <!--
          <ul [class.hide]="isZipListToShow === false" class="generic-dropdown-list">
            <li *ngFor="let zipData of zipCodeList" debounceDirective (debounceClick)="enterZipInput(zipData)">
              <div>
                <span>{{zipData['zip_code']}}, {{zipData['city']}}, {{zipData['state_code']}}</span>
              </div>
            </li>
          </ul>
        -->
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
        <div class="group padv-dropdown">
          <label class="control-labelss" [attr.for]="stateId">{{'EMPLOYMENT_STATE_LABLE' | translate }}</label>
         <!-- 
          <input type="text" class="form-control" formControlName="stateName" maxlength="250" aria-required="true" autocomplete="off"
            placeholder="" [attr.id]="stateId" (keyup)="stateLookUp(empSubForm.controls.stateName.value, $event)"
            (focus)="stateLookUp(empSubForm.controls.stateName.value, $event)" (blur)="setStateManualData()" />
         -->
         <custom-select [items]="stateList" [typeahead]="stateTypeahead" [typeToSearchText]="" bindLabel="state_name"
         bindvalue="state_name" [group] = "empSubForm"  [selectedValue]="selectedState" controlName="stateName" [id]="stateId"
         displayValue="state_name" (valueChange)="trackStateChange($event)" (clearData)="onClearStateData()"
         (onFocusValue)="stateLookUp('', $event)"></custom-select>

          <!--<input type="hidden" class="form-control" formControlName="stateCode" maxlength="250" />
         <input type="hidden" class="form-control" formControlName="zipCode" maxlength="250"  />-->
          <span class="error_info" *ngIf="empSubForm.get('stateName').hasError('serverError') && !showStateErr">
            <i class="fa fa-exclamation error_icon" aria-hidden="true" [class.red_error]="showStateErr"
              [class.cursor_hand_none]="!showStateErr" debounceDirective (debounceClick)="openShowStateErr()"></i>
          </span>
          <div *ngIf="empSubForm.get('stateName').hasError('serverError') && !showStateErr">
            <div class="validate custom-error error_new_ui">
              <div class="left_div">
                <ul>
                  <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate }}</li>
                  <li class="message">{{ 'EMPLOYMENT_STATE_ERROR' | translate }}</li>
                </ul>
              </div>
              <div class="right_div">
                <div class="close" aria-hidden="true" debounceDirective (debounceClick)="closeStateError()">
                  <i class="icon-cancel"></i>
                </div>
              </div>
            </div>
          </div>
          <!--
          <ul [class.hide]="isStateListToShow === false" class="generic-dropdown-list">
            <li *ngFor="let stateData of stateList" debounceDirective (debounceClick)="enterStateInput(stateData)">
              <div>
                <span>{{stateData['state_name']}}</span>
              </div>
            </li>
          </ul>
        -->
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
        <div class="group padv-dropdown">
          <label class="control-labelss" [attr.for]="cityId">{{'EMPLOYMENT_CITY_LABLE' | translate }}</label>
          <!-- 
          <input type="text" class="form-control" placeholder="" formControlName="city" aria-required="true" [attr.id]="cityId"
            autocomplete="off" maxlength="250" (keyup)="cityLookUp(empSubForm.controls.city.value)"
            (blur)="setCityManualData()" />
        -->
        <custom-select [items]="cityList" [typeahead]="cityTypeahead" [typeToSearchText]="" bindLabel="city"
        bindvalue="city" [group] = "empSubForm" [selectedValue]="selectedCity" controlName="city" [id]="cityId"
        [dispValues]="['city', 'state_name']" (valueChange)="trackCityChange($event)" (clearData)="onClearCityData()"></custom-select>

          <span class="error_info" *ngIf="empSubForm.get('city').hasError('serverError') && !showCityErr">
            <i class="fa fa-exclamation error_icon" aria-hidden="true" [class.red_error]="showCityErr"
              [class.cursor_hand_none]="!showCityErr" debounceDirective (debounceClick)="openShowCityErr()"></i>
          </span>
          <div *ngIf="empSubForm.get('city').hasError('serverError') && !showCityErr">
            <div class="validate custom-error error_new_ui">
              <div class="left_div">
                <ul>
                  <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate }}</li>
                  <li class="message">{{ 'EMPLOYMENT_CITY_ERROR' | translate }}</li>
                </ul>
              </div>
              <div class="right_div">
                <div class="close" aria-hidden="true" debounceDirective (debounceClick)="closeCityError()">
                  <i class="icon-cancel"></i>
                </div>
              </div>
            </div>
          </div>
          <!-- 
          <ul [class.hide]="isCityListToShow === false" class="generic-dropdown-list">
            <li *ngFor="let cityData of cityList" debounceDirective (debounceClick)="enterCityInput(cityData)">
              <div>
                <span>{{cityData['city']}}, {{cityData['state_name']}}</span>
              </div>
            </li>
          </ul>
        -->
        </div>
      </div>
    </div>
    <div class="row fixedbtn">
      <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
        <button type="submit" class="btn button font_extrabold senButton" attr.data-id="empCityStateNext_{{row}}"
          [class.grow]="validCityFlag && validStateFlag && (empSubForm.controls.city.value !== undefined && empSubForm.controls.city.value !== '') && (empSubForm.controls.stateName.value !==undefined && empSubForm.controls.stateName.value !== '') && (isPaDOTDirectEnabled && countryOptions['countryShortName'] === 'US' && empSubForm.controls.streetAddress.value !== undefined && empSubForm.controls.streetAddress.value !== '' &&  empSubForm.controls.zipCode.value !== undefined && empSubForm.controls.zipCode.value !== '')"
          [disabled]="(empSubForm.controls.city.value === undefined || empSubForm.controls.city.value === '') || (empSubForm.controls.stateName.value === undefined || empSubForm.controls.stateName.value === '') || (isPaDOTDirectEnabled && countryOptions['countryShortName'] === 'US' && (empSubForm.controls.streetAddress.value === undefined || empSubForm.controls.streetAddress.value === '' || empSubForm.controls.zipCode.value === undefined || empSubForm.controls.zipCode.value === ''))"
          debounceDirective (debounceClick)="cityState(empSubForm.value)">{{ 'COMMONS_NEXT' | translate }}</button>
      </div>
      <!--<div class="col-xs-12 col-sm-4 col-md-3 marginbot20 mar30 p_l_0 m-t-100 p_r_0 m-r-15  m-b-15">
                  <button type="button" class="btn button grow font_extrabold ">INFORMATION UNAVAILABLE</button>
              </div>-->
      <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
        <button type="button" class="btn button_wbg font_extrabold sendButton button_wbgSav"
          attr.data-id="empCityStateSaveExit_{{row}}"
          [class.grow]="validCityFlag && validStateFlag && (empSubForm.controls.city.value !== undefined && empSubForm.controls.city.value !== '') && (empSubForm.controls.stateName.value !==undefined && empSubForm.controls.stateName.value !== '') && (isPaDOTDirectEnabled && countryOptions['countryShortName'] === 'US' && empSubForm.controls.streetAddress.value !== undefined && empSubForm.controls.streetAddress.value !== '' &&  empSubForm.controls.zipCode.value !== undefined && empSubForm.controls.zipCode.value !== '')"
          [disabled]="(empSubForm.controls.city.value === undefined || empSubForm.controls.city.value === '') || (empSubForm.controls.stateName.value === undefined || empSubForm.controls.stateName.value === '') || (isPaDOTDirectEnabled && countryOptions['countryShortName'] === 'US' && (empSubForm.controls.streetAddress ===  undefined || empSubForm.controls.streetAddress.value === '' || empSubForm.controls.zipCode.value === undefined || empSubForm.controls.zipCode.value === ''))"
          debounceDirective (debounceClick)="saveExit(empSubForm.value)">{{ 'COMMONS_SAVE_AND_EXIT' | translate }}</button>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</div>
