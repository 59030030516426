export class ReferencesConfig {
  static STAGE_ID: string = 'reference-data-v1';
  static referencesSteps: any = [
    'confirm',
    'type',
    'country',
    'state',
    'name',
    'title',
    'relation',
    'employername',
    'number',
    //'alternate',
    'reconfirm'
  ];

  static subStepIndexMapping: Object = {
    confirm: {
      subStep: 'confirm',
      subStepIndex: 0
    },
    type: {
      subStep: 'type',
      subStepIndex: 1
    },
    country: {
      subStep: 'country',
      subStepIndex: 2
    },
    state: {
      subStep: 'state',
      subStepIndex: 3
    },
    name: {
      subStep: 'name',
      subStepIndex: 4
    },
    title: {
      subStep: 'title',
      subStepIndex: 5
    },
    relation: {
      subStep: 'relation',
      subStepIndex: 6
    },
    employername: {
      subStep: 'employername',
      subStepIndex: 7
    },
    number: {
      subStep: 'number',
      subStepIndex: 8
    },
    // alternate: {
    //   subStep: 'alternate',
    //   subStepIndex: 9
    // },
    reconfirm: {
      subStep: 'reconfirm',
      subStepIndex: 9
    }
  };

  static defaultCountry: string = '';
  static defaultCountryShortName: string = '';

  static defaultCountryOptions(): Object {
    let countryOptions = {};
    countryOptions['defaultCountry'] = ReferencesConfig.defaultCountry;
    countryOptions['defaultCountryShortName'] = ReferencesConfig.defaultCountryShortName;
    return countryOptions;
  }

  static getSteps(): any {
    return ReferencesConfig.referencesSteps;
  }

  static getReferencesType(): Array<Object> {
    let types = [
      { id: "Professional", name: "REFERENCES_TYPE_1" },
      { id: "Personal", name: "REFERENCES_TYPE_2" }
    ];
    return types;
  }

  static getReferencesTitle(): Array<Object> {
    let titles = [
      { id: "Direct Manager", name: "REFERENCES_TITLE_TYPE_1" },
      { id: "Next Level Up Manager", name: "REFERENCES_TITLE_TYPE_2" },
      { id: "Same Level Peer", name: "REFERENCES_TITLE_TYPE_3" },
      { id: "Subordinate", name: "REFERENCES_TITLE_TYPE_4" },
    ];
    return titles;
  }

  static ats: Object = {
    'id': '',
    'country': '',
    'country_code': '',
    'state_name': '',
    'state_code': '',
    'city': '',
    'type': '',
    'reference_firstname': '',
    'reference_middlename': '',
    'reference_lastname': '',
    'title': '',
    'employername': '',
    'mobilenumber': '',
    'source': '',
    'alternatemobilenumber': '',
    'relation': '',
    'is_reference': '',
    'status': '',
    'email': '',
    'pa_sync_id' : '',
    'state': {
      'step_status': '',
      'stage_status': '',
      'current_iteration': '',
      'current_stage': '',
      'current_step': '',
      'current_step_index': '',
      'current_sub_step': '',
      'current_sub_step_index': '',
      'current_status': ''
    }
  };

  static regular: Object = {
    'id': '',
    'country': '',
    'country_code': '',
    'state_name': '',
    'state_code': '',
    'city': '',
    'type': '',
    'reference_firstname': '',
    'reference_middlename': '',
    'reference_lastname': '',
    'title': '',
    'employername': '',
    'mobilenumber': '',
    'source': '',
    'alternatemobilenumber': '',
    'relation': '',
    'is_reference': '',
    'status': '',
    'email': '',
    'remaining_scope': '',
    'pa_sync_id' : '',
    'state': {
      'step_status': '',
      'stage_status': '',
      'current_iteration': '',
      'current_stage': '',
      'current_step': '',
      'current_step_index': '',
      'current_sub_step': '',
      'current_sub_step_index': '',
      'current_status': ''
    }
  };

  static DisplayLabel: Object = {
    'country': '',
    'country_code': '',
    'state_name': '',
    'state_code': '',
    'city': '',
    'type': '',
    'reference_firstname': '',
    'reference_middlename': '',
    'reference_lastname': '',
    'employername': '',
    'mobilenumber': '',
    'source': '',
    'delete_link': '',
    'email': ''
  };
}