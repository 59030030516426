import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfig } from "app/app.config";
import { environment } from "environments/environment";
import { TranslateService } from "./translate.service";

declare var window: any;

@Injectable({
    providedIn: 'root'
})
export class LanguageInit {
    constructor(private http: HttpClient, private _ts: TranslateService) {

    }

    async initialize() {
        let browserLangCode = navigator.language;
        let langCode = (browserLangCode !== undefined && browserLangCode !== '') ? this.getLanguageCode(browserLangCode.toLowerCase()) : AppConfig.getDefaultLanguage();
        await this.useLanguage(langCode);
    }

    
    async listSupportedLanguages() {
        let hashCode = environment.crowdin_hashCode;
        let languages = [];
        await this.http.get('https://distributions.crowdin.net/' + hashCode + '/manifest.json').toPromise().then(async x => {
            languages = x['languages'];
            // console.log("inside listSupportedLanguages");
        });
        // console.log("languages ", languages);
        return languages;
    }

    async useLanguage(lang) {
        window.track_event("language init", { stage_name: 'pre auth', action_value: 'incoming language ' + lang }, true, true);
        lang = lang.toLowerCase();
        if (AppConfig.getSupportedLanguageList()[lang] !== undefined) {
            lang = AppConfig.getSupportedLanguageList()[lang];
        }

        window.track_event("language init", { stage_name: 'pre auth', action_value: 'supported language: ' + lang }, true, true);
        if (!this._ts.hasLanguageBundle(lang)) {
            window.track_event("language init", { stage_name: 'pre auth', action_value: 'has language bundle: ' + lang }, true, true);
            let hashCode = environment.crowdin_hashCode;
            let isLoadedFromCrowdin : boolean  = false;
            await this.http.get('https://distributions.crowdin.net/' + hashCode + '/content/master/src/assets/i18n/app/language-' + lang + '.json').toPromise()
                .then((resp) => {
                    window.track_event("language init", { stage_name: 'pre auth', action_value: 'got the language bundle from crowdin: ' + lang }, true, true);
                    // validate the content available in response if not load it from assets
                    if (!resp || !resp['COMMONS_NEXT']) {
                        window.track_event("language init", { stage_name: 'pre auth', action_value: 'got the language bundle  from crowdin but content not available: ' + lang }, true, true);
                        isLoadedFromCrowdin = false;
                        // this.loadTranslationFromServer(lang);
                    } else {
                        this._ts.setLanguageBundle(lang, resp);
                        isLoadedFromCrowdin = true;
                    }
                }).catch(err => {
                    isLoadedFromCrowdin = false;
                    window.track_event("language init", { stage_name: 'pre auth', action_value: 'error in loading language bundle from crowdin : ' + lang + 'err: ' + err }, true, true);
                    console.log("Error while loading language bundle ", lang, ' from crowdin', err);
                    
                });

                if (!isLoadedFromCrowdin) {
                    await this.loadTranslationFromServer(lang);
                }
        } else {
            window.track_event("language init", { stage_name: 'pre auth', action_value: 'language bundle available : ' + lang }, true, true);
        }
    }

    /**
     * load translation contents from server
     * 
     * @param lang 
     */
    async loadTranslationFromServer(lang) {
        await this.http.get(AppConfig.API_ENDPOINT() +  '/assets/i18n/app/language-' + lang + '.json').toPromise()
        .then((resp) => {
            window.track_event("language init", { stage_name: 'pre auth', action_value: 'got the language bundle from the pa server : ' + lang }, true, true);
            // validate the content available in response if not load it from assets
            if (!resp || !resp['COMMONS_NEXT']) {
                window.track_event("language init", { stage_name: 'pre auth', action_value: 'got the language bundle but content not available: from pa server' + lang }, true, true);
                console.log("loaded language bundle from", lang, ' from pa server but no contents found');
            } else {
                console.log("successfully loaded language bundle from", lang, ' from pa server');
                this._ts.setLanguageBundle(lang, resp);
            }
        }).catch(err => {
            window.track_event("language init", { stage_name: 'pre auth', action_value: 'error in loading language bundle from pa server : ' + lang + 'err: ' + err }, true, true);
            console.log("Error while loading language bundle from", lang, ' from pa server', err);
        });
    }

    getLanguageCode(browserLangCode: string): string {
        // default language set in App.config
        let langCode: string = AppConfig.getDefaultLanguage();

        // Map the browser language code to PA Defined code, if not found return default
        if (AppConfig.getSupportedLanguageList()[browserLangCode] !== undefined) {
            langCode = AppConfig.getSupportedLanguageList()[browserLangCode];
        }
        return langCode;
    }

}