import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TRANSLATION_PROVIDERS, TranslatePipe, TranslateService } from './../../../framework/i18n';

@Component({
    selector: 'error',
    templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit {
    _data = new BehaviorSubject<any>([]);
    errorConfigData: Object = {};

    @Input()
    set errorConfig(value) {
        this._data.next(value);
    };

    get errorConfig() {
        return this._data.getValue();
    }

    @Output() closeErrorPopUp: EventEmitter<any> = new EventEmitter();
    @Output() openErrorPopUp: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
        // get value from parent. Config will change dynamically in parent..
        this._data
            .subscribe(x => {
                if (x !== undefined) {
                    this.errorConfigData = x;
                }
            });
    }

    // close error popup
    closeErrorPop() {
        this.closeErrorPopUp.emit();
    }

    // open error popup
    openErrorPop() {
        this.openErrorPopUp.emit();
    }
}
