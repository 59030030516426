import { BrowserCacheLocation, Configuration, LogLevel } from '@azure/msal-browser';

const MITEK_LICENSE_KEY = 'eyJzaWduYXR1cmUiOiJvUmpCSWc0OC81MFFRS0FlTlNsWDZFWllPU0VTbi9aeUhvVjlpUHFEN0NrZ2ZoajhCSEkvdnhtN0JQZnpORTJsdDdVNGpDQTAveitRUWlGaVZaMHJOY1NVaXgva3dnUXdVT3NmQzNGTjdGbDRHQ1lCN0wzNG44SXFzVmkrajdmTG5LOEJXOTk0d3gxTENqVU9sTzRXTExQZi9iSlFPcUQvUTZIMi80LzdjbCtCWDU2ckZOQ05DaFZEOVk2OTBLcnpZWXNoZjlSN0VKdmh3eG5lak1aL3p3a0NYemZNQSt3L0NWenhOWmpiekVianlIbEJyanhqZ0YyZjZYczhMaDVTZW1MbEZDNUNDbUtUNmVGNkw3d1dKdjNSOXREQVh1c0tGYWloYlErYXk4dUVCOEpzTURHdWUvQ2xLb3lCdC9mYmNkbkRDZkNZbkErd2dsamlQTldJd0E9PSIsInNpZ25lZCI6eyJleHBpcnkiOiIyMDI2LTA2LTA1IiwiZmVhdHVyZXMiOlsiZ2VuZXJpYyIsImlkIiwiZmFjZSIsImJhcmNvZGUiXSwiYXBwbGljYXRpb25JZGVudGlmaWVyIjp7IndlYiI6WyIqLmZhZHYubmV0IiwiKi5mYWR2LmNvbSJdfSwiZ3BvIjoiMTZkIiwib3JnYW5pemF0aW9uIjoiTWl0ZWsgU3lzdGVtcyBJbmMuIiwidmVyc2lvbiI6IjEuMCJ9LCJiYXJjb2RlV2ViTGljZW5zZSI6Ilg2UFpkd3c0UXB5Z0pERHNFVmQ2NnAxdk12dE43VExLOTlXVXJyQXZPdkVTZHg5Unp2WllxNzhEKzVhK0h1ZEc0eGkvK0lsRmNhZTc3Mm4wVWVTYWhYS3d0TlprZzNmN0VwU2lSUzc1cjFNeU1tRTBDcU5BYit1a3o3UjNZcitjTUFQanRQRm5sSlJrZVdWcXNwMGZNU2tPRFNldURRdXozeFFPS29FamcwejluQ0FpSUN5WnJtTEFmWXhZL3F0WHJyMFFDMG5uN0pTM1VXRGVQWGpFQWYxM1BkRlB0NHlJblo5aGo2QVlidnk2dHJKdXJoME5nM3k0YXZWT1pZMWYrWHdpMmVGMlorVTdSeTk5TDhla2dqdWJTUE9xcHNqRFZkUE1ab0o2NWR0Q3pOMk9BS2Fva0hFYTBnU0FCV1htb0ZtV0N1dUJPR2J5bU5hQWRGMmZsaTY2UjJsUEVtSEFsZ3FoTWRydWJHOFF0bmZySE94RGw3N2xVK3g0aXZOTUdUMFhpbnl4d1JDVmVzRnQvQzhnZkJYZTYvTDBqaGFlY3JQVEVXYkxuTEYwUmNEMlcyMzNLMUhFTHNBVG5hQ1RBbzdycHYwT2FTRDNidU5qWHlqVFdGeTZJYzVtVWloNjY1WWNIK2NwL1JxM0ZxemJhZkpvMFBuK0x5ekdoeHdiIn0=';

const policies = {
  names: {
    signUp: 'B2C_1A_SIGN_UP_WITH_INVITE',
    signIn: 'B2C_1A_SIGN_IN',
    resetPassword: 'B2C_1A_PASSWORDRESET',
    signInByToken: 'B2C_1A_SIGN_IN_WITH_LINK_TOKEN',
  },
  scopes: ['https://authyfaexternaluat.onmicrosoft.com/ded132e7-e021-4b2a-8e89-b90aaed28169/default'],
  authorities: {
    signUp: {
      authority: 'https://authy.uat.switch.fadv.com/authyfaexternaluat.onmicrosoft.com/B2C_1A_SIGN_UP_WITH_INVITE',
    },
    signIn: {
      authority: 'https://authy.uat.switch.fadv.com/authyfaexternaluat.onmicrosoft.com/B2C_1A_SIGN_IN',
    },
    signInByToken: {
      authority: 'https://authy.uat.switch.fadv.com/authyfaexternaluat.onmicrosoft.com/B2C_1A_SIGN_IN_WITH_LINK_TOKEN',
    },
    resetPassword: {
      authority: 'https://authy.uat.switch.fadv.com/authyfaexternaluat.onmicrosoft.com/B2C_1A_PASSWORDRESET',
    },
  },
  authorityDomain: 'https://authy.uat.switch.fadv.com',
};

export function loggerCallback(_logLevel: LogLevel, message: string) {
  console.log(message);
}

const msalConfig: Configuration = {
  auth: {
    clientId: 'ded132e7-e021-4b2a-8e89-b90aaed28169', // This is the ONLY mandatory field that you need to supply.
    authority: policies.authorities.signIn.authority, // Defaults to "https://login.microsoftonline.com/common"
    knownAuthorities: [policies.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: '/', // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
    postLogoutRedirectUri: '/', // Points to window.location.origin by default.
    // by default MSAL will try to return to the page that trigger the login,
    // when the login or register is from an invite this isn't wanted, so always go back to the main page
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
  },
  system: {
    /**
     * Below you can configure MSAL.js logs. For more information, visit:
     * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
     */
    loggerOptions: {
      loggerCallback,
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
  },
};

export const environment = {
    production: true,
    name: 'uat',
    apiUrl: 'https://pa-ua.fadv.com',
    global_error_show: false,
    apm_environment: 'uat',
    minAndroidVersion: 9,
    enableConsoleDebugging: false,
    minIosVersion: 12,
    minChromeVersion: 64,
    minFirefoxVersion: 55,
    minIExplorerVersion: 11,
    minSafariVersion: 9,
    camerasettings: {
      captureMode : 'Auto'
    },
    websdk_vendor: 'MITEK',
    verification_api_vendor: 'MITEK',
    languageEditEnabled: false,
    crowdin_hashCode: '33393b4292639cd214fcd47zrgk',
    default_language: 'en',
    google_api_key: 'AIzaSyAU88N0EBLhmlwTB1N7NcT1lmqkKEt9xpI',
    rumId: 'AD-AAB-ABF-YDH',
    okta_security_key: 'aus4zlbw3ghifCpCa696',  // same for non prod, change for prod
    okta_client_id: '0oa51kvruecdpZKNb696',      // each env different key
    okta_resend_code_seconds: 30,
    okta_base_url: 'https://cloudsso-test.fadv.com',    // same for non-prod, diff for prod
    yoti_client_sdk_id: 'e72baa96-8df9-4164-ad76-569b06413370',
    salesForceInitializeUrl: 'https://service.force.com/embeddedservice/5.0/esw.min.js',
    salesForceChatSettings: {
      displayHelpButton: true,
      language: '',
      defaultMinimizedText: '',
      disabledMinimizedText: '',
      loadingText: '',
      storageDomain: '',
      prepopulatedPrechatFields: {},
      enabledFeatures: ['LiveAgent'],
      entryFeature: 'LiveAgent',
      initEnvUrl: 'https://firstadv--staging.sandbox.my.salesforce.com',
      initAgentUrl: 'https://firstadv--staging.sandbox.my.site.com',
      initParam1: '00D7e00000Jywkx',
      initAgentName: 'Customer_Care_Chat_Clone',
      customSettings: {
        baseLiveAgentContentURL: 'https://c.la3-c1cs-ia5.salesforceliveagent.com/content',
        deploymentId: '5725a000000Goie',
        buttonId: '5735a000000oLor',
        baseLiveAgentURL: 'https://d.la3-c1cs-ia5.salesforceliveagent.com/chat',
        eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I7e00000000ZcEAI_18abd7a271c',
        isOfflineSupportEnabled: false
      }
    },
    salesForceChatEmbeddedServiceMinJS: 'https://firstadv--staging.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js',
    salesForceChatBaseUrl: 'https://service.force.com',
    salesForceChatPosition: 'left',
    default_chat_exp : 'SFC',
    stripe_pk: "pk_test_51NfjJ1A0emjIZImakLQOXaMVRxb1KziwqFjPXhTafFKNWGt75qF3mgIUVAqTAr7DJB9witVwJ26VYGM4gY9BB6j000GMoLfERB",
    cognitive_search_key: 'oKgbkv15ibxluqfXIswnL2XcesGqNhnw0CsGlJI76QAzSeAZ7ycy',
    hotjar_id: 3779754,
    policies,
    msalConfig,
    magicLinkPolicies: {
      'password-reset': policies.authorities.resetPassword.authority,
      signin: policies.authorities.signInByToken.authority,
    },
    mitekLicenseKey: MITEK_LICENSE_KEY
  };