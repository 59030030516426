import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// import { Options } from 'selenium-webdriver/firefox';
import { ProfileIntlConfig } from './profile-intl.config';

@Component({
  selector: 'profile-intl',
  templateUrl: './profile-intl.component.html',
  providers: []
})

export class ProfileIntlComponent implements OnInit {
  @Input() options: any;
  @Output() onViewProfile: EventEmitter<any> = new EventEmitter();
  intlDisplayTitle: string = '';
  data: any;
  title: any;
  customFormsData: Array<Object> = [];
  candidateFormFieldValue: string;

  constructor() {
  }

  ngOnInit(): void {
    // Should come from config
    let booleanLabelMapping = {
      'true': 'YES',
      'false': 'NO'
    };

    /*
      * Custom forms key preparation since options.data object does not have
      * proper fixed key
    */
    if (this.options['key'] === 'forms') {
      let _data = this.options.data[0];

      for (let key in _data) {
        if (_data.hasOwnProperty(key)) {
          this.customFormsData.push(_data[key]);
        }
      }

      /* Need to move it to util file as this is a common code for
       * clipboard-custom-forms.component.ts (prepareCustomFormsDisplayObject)
       */
      for (let index in this.customFormsData) {
        if (this.customFormsData[index]['value']) {
          // Westpac
          let temp = this.customFormsData[index]['value'].split('|');

          if (booleanLabelMapping[temp[0].toLowerCase()]) {
            this.candidateFormFieldValue = booleanLabelMapping[temp[0].toLowerCase()].toUpperCase();
          } else {
            this.candidateFormFieldValue = temp[0];
          }

          break;
        }
      }
    } else {
      this.data = this.options['data'][0];
      this.title = this.options['title'];

      for (let prop in this.data) {
        if (ProfileIntlConfig.DATA_KEY_LIST.indexOf(prop) > -1) {
          this.intlDisplayTitle = this.data[prop];
          break;
        }
      }
    }
  }

  openViewProfilePopup(data, key): void {
    data['key'] = key;
    this.onViewProfile.emit(data);
  }
}
