import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, Renderer2, SimpleChanges, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { SubscriptionLike } from 'rxjs';

import { EventBusService } from '../../../../framework/eventbus.service';
import { SharedService } from '../../../../app/shared/services/shared.service';
import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/fieldconfig.interface';
import { LocalStorageService } from '../../../../framework/localstorage.service';
import { LocationPickerConfig } from './location-picker.config';
import { TranslateService } from 'framework/i18n';

declare let $: any;

@Component({
  selector: 'location-picker',
  templateUrl: './location-picker.component.html',
  providers: [],
})

export class LocationPickerComponent implements OnInit, OnDestroy, Field {
  static countryName: string;

  @Input() countryPickerConfigOptions: any;

  config: FieldConfig;
  group: FormGroup;

  locationPickerConfigOptions = {
    ATTRIBUTE_ID: '',
    TYPE: '',
    NAME: '',
    OPTIONS: '',
    FORM_CONTROL_NAME: '',
    CLASS_BOOLEAN: true,
    HIDDEN_FORM_CONTROL_NAME_1: '',
    HIDDEN_FORM_CONTROL_NAME_2: ''
  };

  flag: string;
  showCountryEsclamation: boolean = false;
  showStateEsclamation: boolean = false;
  invalidCountry: boolean = false;
  inValidState: boolean = false;
  attributeID: string;
  countryControlName: string;
  maxLength: string;
  hiddenCountryNameControl: string;
  hiddenCountryCodeControl: string;
  booleanClass: boolean;
  stateList: any = [];
  countryList: any = [];

  isCountryListToShow: boolean = false;
  isStateListToShow: boolean = false;
  errBlockHeading: string = '';
  errorMessage: string = '';
  stateCode: string = '';
  readonly: boolean = false;

  nationalityList: any = [];
  invalidNationality: boolean = false;
  isNationlityListToShow: boolean = false;
  showNationalityEsclamation: boolean = false;

  private _subscription: SubscriptionLike;
  private _data = new BehaviorSubject<any>([]);
  private _clearCountryList = new BehaviorSubject<any>([]);
  private _clearStateList = new BehaviorSubject<any>([]);
  private _clearNationalityList = new BehaviorSubject<any>([]);

  @Input()
  set inputClearCountryList(value: string) {
    if (value !== '') {
      this._clearCountryList.next(value);
    }
  };

  get inputClearCountryList() {
    return this._clearCountryList.getValue();
  }

  @Input()
  set inputClearStateList(value: string) {
    if (value !== '') {
      this._clearStateList.next(value);
    }
  };

  get inputClearStateList() {
    return this._clearStateList.getValue();
  }

  @Input()
  set inputClearNationalityList(value: string) {
    if (value !== '') {
      this._clearNationalityList.next(value);
    }
  };

  get inputClearNationalityList() {
    return this._clearNationalityList.getValue();
  }

  private input;
  private autoComplete;
  private countryShortName: string;

  constructor(private _renderer: Renderer2,
    private shareService: SharedService,
    private eventBus: EventBusService,
    private localStorage: LocalStorageService,
    private _ts: TranslateService) {
    this._subscription = this.eventBus.locationPickerErrorAnnounced$.subscribe(item => {
      this.errBlockHeading = item['errBlockHeading'];
      this.errorMessage = item['errorMessage'];

      if (item['flag'] === 'ADDSTATE') {
        // Hode Country error icon, block
        this.showHideErrorBlock(false);
      } else if (item['flag'] === 'ADDCOUNTRY') {
        // Show Country error icon, block
        this.showHideErrorBlock(true);
      } else if (item['flag'] === 'ADDNATIONALITY') {
        this.showHideNationalityErrorBlock(true);
      } else {
        // Hide all Country, State error blocks & icons
        this.hideAllErrorIconsBlock();
      }
    });
  }

  ngOnInit(): void {
    if (!!this.config) {
      this.locationPickerConfigOptions['TYPE'] = this.config['type'];
      this.locationPickerConfigOptions['NAME'] = this.config['name'];
      this.locationPickerConfigOptions['ATTRIBUTE_ID'] = this.config['id'];
    }

    this.locationPickerConfigOptions['FORM_CONTROL_NAME'] = this.locationPickerConfigOptions['NAME'];
    this.locationPickerConfigOptions['HIDDEN_FORM_CONTROL_NAME_1'] = this.locationPickerConfigOptions['TYPE'] + '_name';
    this.locationPickerConfigOptions['HIDDEN_FORM_CONTROL_NAME_2']  = this.locationPickerConfigOptions['TYPE'] + '_code';
    this.booleanClass = false;

    let lockPrePopulatedFields = (this.localStorage.getItem('pa_lock_pre_population_for_custom_form_fields') == 'Y' ? true : false);
    this.readonly = false;
    if (this.config.value === undefined) {
      this.config.value = '';
    }
    this.config['altvalue'] = '';
    if (this.config.value !== '') {
      if (lockPrePopulatedFields) {
        let fieldValue = this.config.field_value;
        if (fieldValue && fieldValue.startsWith("${")) {
          this.readonly = true;
        }
      }
      let value = this.config.value;
      if (value && value.startsWith("${")) {
        (<FormControl>this.group.controls[this.config.name]).setValue('');
      }
      let that = this;
      this.shareService.getInterpolationData(this.config.value).then(function (resp) {
        if (resp && resp != '') {
          that.config.value = resp;
          if (that.config.type === 'COUNTRY') {
            let countryCode = that.config.value;
            that.config['altvalue'] = countryCode;

            let body = [];

            if (!!that.config.list && that.config.list.length > 0) {
              body['countryList'] = that.config.list;

              that.shareService.countryLookUpServiceByList(body).subscribe(response => {
                let countryData = response;
                let countryList = countryData['country_list'];
                if (countryList != undefined && countryList.length == 1) {
                  that.config.value = countryList[0]['country_name'];
                  (<FormControl>that.group.controls[that.config.name]).setValue(that.config.value);
                }
              }, error => {

              });
            } else {
              body['countryParam'] = countryCode;
              // call shared service to get country data
              that.shareService.countryLookUpServiceByCountryCode(body).subscribe(response => {
                let countryData = response;
                let countryList = countryData['country_list'];
                if (countryList != undefined && countryList.length == 1) {
                  that.config.value = countryList[0]['country_name'];
                  (<FormControl>that.group.controls[that.config.name]).setValue(that.config.value);
                }
              }, error => {

              });
            }
          } else if (that.config.type === 'StateListBox' || that.config.type === 'STATE') {
            that.stateCode =  that.config.value;
            that.config['altvalue'] =  that.stateCode;
            const localStorageCountryCode =  that.localStorage.getItem('consentCountry');
            const countryCode = ( localStorageCountryCode ) ? JSON.parse( localStorageCountryCode )['country_code'] : "US";
                // call shared service to get state datas
                that.shareService.lookUpStateCodeByName(countryCode,  that.stateCode).subscribe(response => {
                  let stateData = response;
                  that.config.value = stateData['state_name'];
                  (<FormControl>that.group.controls[that.config.name]).setValue(that.config.value);
                }, error => {

                });
          }
        } else {
          that.readonly = false;
        }
      }
      );
    }

    // Clear country dropdown list
    this._clearCountryList
    .subscribe(res => {
      if (res !== undefined && res !== '' && res !== null) {
        this.isCountryListToShow = false;
      }
    });

     // Clear state dropdown list
    this._clearStateList
    .subscribe(res => {
      if (res !== undefined && res !== '' && res !== null) {
        this.isStateListToShow = false;
      }
    });

    // Check country based consent - This will execute only if there no (value attribute) in the config form.
    if ((this.config['value'] === undefined || this.config['value'] === '') && (this.config.name !== 'DBS_ISSUE_COUNTRY')) {
      this.setCountryBasedConsent();
    }
  }


  ngAfterViewInit() {
  }

  ngOnChange(change: SimpleChanges) {
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  // get lookup service for both country and state
  countryStateLookUp(event) {
    let data = this.group.get(this.locationPickerConfigOptions['FORM_CONTROL_NAME']).value;
    this.flag = (this.locationPickerConfigOptions['TYPE']).toLowerCase();
    this.countryShortName = this.shareService.getSelectedCountryCode();

    this._renderer.setStyle(event.target, this.locationPickerConfigOptions['CSS_CLASS'], true);

    // Country Field Check - If there is no value in the country field, It will not give auto pre-populate list.
    // State Field Check - Even though if there is no value in state field, It must give auto pre-populate list - if and only if country code exist.
    if (this.flag === 'country' && data !== undefined) {
      // If type is country and config.list is not undefined and not empty, then pass the list
      // as query param to country lookup by list. Else part is the existing code
      if (!!this.config && !!this.config.list && this.config.list.length > 0) {
        let queryParam = {
          'countryList': this.config.list,
          'countryParam': data.trim()
        };

        this.shareService.countryLookUpServiceByList(queryParam).subscribe(response => {
          let countryData = response;
          this.countryList = countryData['country_list'];

         if (this.countryList.length > 0) {
            this.isCountryListToShow = true;
          } else {
            this.isCountryListToShow = false;
          }
        }, error => {

        });
      } else {
        let queryParam = {
          'countryParam': data.trim()
        };

        this.shareService.countryCodeLookupSwitch(queryParam).subscribe(response => {
          let countryData = response;
          // console.log('country Data: ' + JSON.stringify(countryData));
          this.countryList = countryData['country_list'];
          if (this.countryList.length > 0) {
            this.isCountryListToShow = true;
          } else {
            this.isCountryListToShow = false;
          }
        }, error => {

        });
      }
    } if (this.flag === 'nationality' && data !== undefined) {
      let queryParam = {
        'nationalityParam': data.trim()
      };

      this.shareService.nationalityLookUpService(queryParam).subscribe(response => {
        let nationalityData = response;
        this.nationalityList = nationalityData['nationality_list'];
        if (!!this.nationalityList && this.nationalityList.length > 0) {
          this.isNationlityListToShow = true;
        } else {
          this.isNationlityListToShow = false;
        }
      }, error => {

      });
    } else if (this.flag === 'state') {
      let queryParam;
      data = (data !== undefined) ? data : '';
      if (!this.config['defaultCountry']) {
        queryParam = this.getQueryParam(data);
        let countryCode = queryParam['countryCode'];
        if (countryCode == undefined || countryCode === '') {
          const localStorageCountryCode = this.localStorage.getItem('consentCountry');
          countryCode = (localStorageCountryCode) ? JSON.parse(localStorageCountryCode)['country_code'] : "US";
          queryParam = {
            'countryCode': countryCode,
            'stateParam': data.trim()
          };
        }
      } else {
        queryParam = {
          'countryCode': this.config['defaultCountry'],
          'stateParam': data.trim()
        };
      }
      if (queryParam['countryCode'] !== undefined) {
        if (this.locationPickerConfigOptions['NAME'] !== undefined && this.locationPickerConfigOptions['NAME'] === 'WWC_STATE') {
          queryParam = {
            'countryCode': 'Personal_WWCStateOrTerritory|' + queryParam['countryCode'],
            'stateParam': data.trim()
          };
        }

        if (!!this.locationPickerConfigOptions['NAME'] && this.locationPickerConfigOptions['NAME'] === 'ACIC_STATE_ADDITIONAL_FIELD') {
          // SET country code to AU for ACIC STATE FIELD
          queryParam['countryCode'] = 'AU'
        }

        this.shareService.stateLookupSwitch(queryParam).subscribe(response => {
          let stateData = response;
          // console.log('state Data: ' + JSON.stringify(stateData));
          this.stateList = stateData['state-data-list'];
          if (!!this.locationPickerConfigOptions['NAME'] && this.locationPickerConfigOptions['NAME'] === 'ACIC_STATE_ADDITIONAL_FIELD') {
            // add OTHER in state list for ACIC STATE
            const otherStateobj = {
              'country_code': 'AU',
              'state_code': 'OTH',
              'state_name': this._ts.instant('OTHER_STATE')
            }

            this.stateList.push(otherStateobj);
          }
          if (this.stateList.length > 0) {
            this.isStateListToShow = true;
          } else {
            this.isStateListToShow = false;
          }
        }, error => {

        });
      }
    }
  }

  /**
   * The method prepares Query param
   * using either of dependency/counsentCountry value
   * @param data
   */
  getQueryParam(data: string): Object {
    let countryCd = '';
    let retValue = this.isConsentCountryAndDependency();

    /**
     * Hukou - China, dependency key is used of config, else country based
     * consent short code will be used.
     *
     * #TODO - Need Error Handling in case countryCd value is NONE
     */

    if (LocationPickerConfig.STATE_LOOKUP_PREFERENCE === LocationPickerConfig.DEPENDENCY) {
      if (retValue === LocationPickerConfig.BOTH_PRESENT || retValue === LocationPickerConfig.DEPENDENCY_PRESENT) {
        countryCd = this.config['dependency']['country_code'];
      } else if (retValue === LocationPickerConfig.CONSENT_COUNTRY_PRESENT) {
        countryCd = this.countryShortName;
      }
    } else {
      if (retValue === LocationPickerConfig.BOTH_PRESENT || retValue === LocationPickerConfig.CONSENT_COUNTRY_PRESENT) {
        countryCd = this.countryShortName;
      } else if (retValue === LocationPickerConfig.DEPENDENCY_PRESENT) {
        countryCd = this.config['dependency']['country_code'];
      }
    }

    return { countryCode: countryCd, stateParam: data.trim() };
  }

  /**
   * The method return four (BOTH_PRESENT,CONSENT_COUNTRY_PRESENT,DEPENDENCY_PRESENT,NONE)
   * possible values based on presence of their value
   */
  isConsentCountryAndDependency(): String {
    let retValue = '';

    if (this.countryShortName !== undefined && (this.config['dependency'] !== undefined &&
      this.config['dependency']['country_code'] !== undefined)) {
      retValue = LocationPickerConfig.BOTH_PRESENT;
    } else if (this.countryShortName !== undefined) {
      retValue = LocationPickerConfig.CONSENT_COUNTRY_PRESENT;
    } else if (this.config['dependency'] !== undefined &&
      this.config['dependency']['country_code'] !== undefined) {
      retValue = LocationPickerConfig.DEPENDENCY_PRESENT;
    } else {
      retValue = LocationPickerConfig.NONE;
    }
    return retValue;
  }

  /* ------------- Bind methods start here ------------- */
  enterStateInput(data) {
    this.isStateListToShow = false;
    this.stateCode = data['state_code'];
    this.config['altvalue'] = data['state_code'];
    this.group.get(this.locationPickerConfigOptions['FORM_CONTROL_NAME']).patchValue(data['state_name']);
    this.setDependency(data);
  }

  /**
   * This is used for storing the values in dependency object, as of now utilized by
   * WWC Number feature in Additional Information.
   * @param data
   */
  setDependency(data) {
    if (this.config['dependency'] !== undefined && this.config['dependency'] !== null) {
      this.config['dependency']['selected_country_code'] = data['country_code'];
      this.config['dependency']['selected_state_code'] = data['state_code'];
      this.config['dependency']['selected_state_name'] = data['state_name'];
    }
  }

  enterCountryInput(data) {
    this.isCountryListToShow = false;
    this.config['altvalue'] = data['country_code'];
    this.shareService.setSelectedCountryCode(data['country_code']);

    (<FormControl>this.group.controls[this.locationPickerConfigOptions['FORM_CONTROL_NAME']]).setValue(data['country_name']);
  }

  setStateCountryData() {
    this.flag = (this.locationPickerConfigOptions['TYPE']).toLowerCase();
    // Removed country if check and enterCountryInput as clearing country was resetting value
    if (this.flag === 'state') {
      if (this.stateList.length === 1 && this.isStateListToShow === true) {
        this.enterStateInput(this.stateList[0]);
      }
    }
  }

  closeCountryError() {
    this.invalidCountry = false;
  }

  openCountryError() {
    this.invalidCountry = true;
  }

  openStateError() {
    this.inValidState = true;
  }

  closeStateError() {
    this.inValidState = false;
  }

  private showHideErrorBlock(status: boolean) {
    this.showCountryEsclamation = status;
    this.invalidCountry = status;

    this.showStateEsclamation = !this.showCountryEsclamation;
    this.inValidState = !this.invalidCountry;
  }

  private hideAllErrorIconsBlock() {
    this.showCountryEsclamation = false;
    this.invalidCountry = false;
    this.showStateEsclamation = false;
    this.inValidState = false;
    this.showNationalityEsclamation = false;
    this.invalidNationality = false;
  }

  /**
   * Set consent country value
   */
  private setCountryBasedConsent() {
    this.flag = (this.locationPickerConfigOptions['TYPE']).toLowerCase();
    let consentCountry = this.localStorage.getItem('consentCountry');
    let countryData;

    // if consent country exist in the flow, it will set the default value - EX:- Australia
    if (this.flag === 'country' && consentCountry !== undefined) {
      countryData = JSON.parse(consentCountry);
      this.enterCountryInput(countryData);
    }
  }

  showFullLabelText() {
    $('[data-toggle="popover"]').popover();
  }

  /**
   * function to handle selected nationality from the list
   *
   * @param data
   */
  enterNationalityInput(data) {
    this.isNationlityListToShow = false;
    this.config['altvalue'] = data['country_code'];
    this.shareService.setSelectedNationality(data['nationality']);

    (<FormControl>this.group.controls[this.locationPickerConfigOptions['FORM_CONTROL_NAME']]).setValue(data['nationality']);
  }

  closeNationalityError() {
    this.invalidNationality = false;
  }

  private showHideNationalityErrorBlock(status: boolean) {
    this.showNationalityEsclamation = status;
    this.invalidNationality = status;
  }

  openNationalityError() {
    this.invalidNationality = true;
  }
}
