import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EmploymentService } from 'app/employment';
import { ProfileConfig } from './../../profile.config';

@Component({
  selector: 'view-profile-employment',
  templateUrl: './view-profile-employment.component.html',
  providers: []
})

export class ViewProfileEmploymentComponent {
  @Input() viewProfileData: Object;
  profileKey: string;
  dateFormat: string = 'MMMM YYYY';

  constructor(private _es: EmploymentService) {
    this.dateFormat = this._es.getDateFormat();
  }

  ngOnInit() {
    this.profileKey = ProfileConfig.getViewProfileEmploymentKey();
  }
}