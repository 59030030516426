import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { EmploymentConfig } from '../employment.config';

import { EventBusService } from 'framework/eventbus.service';

//State
import { Store } from '@ngrx/store';
import * as ACTIONS_INTERFACE from '../../actions';
import { AppConfig } from 'app/app.config';
import { BehaviorSubject } from 'rxjs';


declare var google: any;
declare var jQuery: any;

@Component({
  selector: 'job-title',
  templateUrl: './job-title.component.html',
  styleUrls: ['./job-title.component.css']
})
export class JobTitleComponent implements OnInit, AfterViewInit {
  _countryData = new BehaviorSubject<any>([]);
  @Input('group')
  public empSubForm: FormGroup;
  @Input() userDetails: any;
  @Input() empType: any;
  @Input() row: any;
  @Input() configOptions: any;
  @Input() isCheckSelfEmploymentEnabled: boolean;
  @Input() dotLimitEmploymentVerification: boolean;
  @Input() isDotLicencePreferenceEnabled: boolean;
  @Input() isPaDOTDirectEnabled: boolean;
  @Input() isReasonForLeavingMandatory: boolean;
  @Input() isMultiPositionEnabled: boolean;
  @Input() faaSafetyCheckPreferenceEnabled: boolean;
  @Input() selfEmploymentPreferenceEnabled: boolean;

  @Input()
  set companyPrefillConfig(value) {
    this._countryData.next(value);
  };  

  @Output() onJobTitle: EventEmitter<any> = new EventEmitter();
  @Output() onJobTitleSwipe: EventEmitter<any> = new EventEmitter();


  //SE functionality
  static STAGE_ID: string;
  static STEP_ID: string;
  static STEP_INDEX: number;
  public SUB_STEP_ID: string;
  public SUB_STEP_INDEX: number;
  PARENT_STAGE_STEP_CONFIG: Object;
  isViewInit;

  //State Maintenance Related Activity
  static BUTTON_CLICKED: string = 'CLICKED_SAVE_EXIT_BUTTON';
  currentStatus: string = 'VISITED';
  payLoad: Object;
  startTime: any;
  showDriverDuties: boolean = false;
  showReasonForLeaving: boolean = false;
  showTitle: boolean = true;
  DOMESTIC_COUNTRY_CODE: string = AppConfig.APP_CONSTANTS.DOMESTIC_COUNTRY_CODE;
  prefillConfigObj: Object = {};
  showFaaSafetyCheck: boolean = false;
  
  constructor(
    private _eventBus: EventBusService, // Auto Generated PPA-8745 - Arun Supreet for Save n Exit Patch
    private _store: Store<ACTIONS_INTERFACE.AppState>) {
    this.startTime = new Date().getTime();
  }

  ngOnInit() {
    this.showDriverDuties = this.dotLimitEmploymentVerification && (this.empType === 'current' || this.empType === 'prior' || this.empType === 'selfEmployed');
    this.showReasonForLeaving = ((this.isPaDOTDirectEnabled && (this.empType === 'current' || this.empType === 'prior' || this.empType === 'selfEmployed')) || 
                                  this.isReasonForLeavingMandatory && (this.empType === 'current' || this.empType === 'prior' || (this.empType === 'selfEmployed' && this.selfEmploymentPreferenceEnabled)));
    this.showFaaSafetyCheck = this.faaSafetyCheckPreferenceEnabled && (this.empType === 'current' || this.empType === 'prior' || (this.empType === 'selfEmployed' && this.selfEmploymentPreferenceEnabled));                                

    if ((this.empType === 'current' || this.empType === 'prior') && this.isMultiplePositionEnabled(this.empSubForm.controls['countryCode'].value, this.empType)) {
      this.removeJobTitleAndDriverDuties();
    }

    this._countryData.subscribe(x => {
      this.prefillConfigObj = (x !== undefined) ? x : '';
      this.showDriverDuties = this.dotLimitEmploymentVerification && (this.empType === 'current' || this.empType === 'prior' || this.empType === 'selfEmployed');
      this.showReasonForLeaving = ((this.isPaDOTDirectEnabled && (this.empType === 'current' || this.empType === 'prior' || this.empType === 'selfEmployed')) ||
          this.isReasonForLeavingMandatory && (this.empType === 'current' || this.empType === 'prior' || (this.empType === 'selfEmployed' && this.selfEmploymentPreferenceEnabled)));
          this.showFaaSafetyCheck = this.faaSafetyCheckPreferenceEnabled && (this.empType === 'current' || this.empType === 'prior' || (this.empType === 'selfEmployed' && this.selfEmploymentPreferenceEnabled));       

      if (this.prefillConfigObj['country'] === this.DOMESTIC_COUNTRY_CODE &&  !!this.empSubForm.controls['jobTitle']) {
        this.showTitle = true;
        this.empSubForm.controls['jobTitle'].setValidators(Validators.compose([Validators.required]));
      } else if ((this.empType === 'current' || this.empType === 'prior') && this.isMultiplePositionEnabled(this.empSubForm.controls['countryCode'].value, this.empType)) {
        this.removeJobTitleAndDriverDuties();
      }

      if (!this.empSubForm.controls['jobTitle']) {
        this.showTitle = false;
      }
      if (!this.empSubForm.controls['driverDuties']) {
        this.showDriverDuties = false;
      }
    });
    
    if (!this.empSubForm.controls['jobTitle']) {
      this.showTitle = false;
    }
    if (!this.empSubForm.controls['driverDuties']) {
      this.showDriverDuties = false;
    }

  }

  ngAfterViewInit(): void {
    //console.log('----------- ngAfterViewInit -----------------');
    this.processConfig();
    this.isViewInit = true;
  }

  private isMultiplePositionEnabled(countryCode: string, empType: string) {
    // isMultiPositionEnabled is true if allow_multi_position is true in workflow preference and it enables multiple position
    if (this.isMultiPositionEnabled &&
      countryCode !== this.DOMESTIC_COUNTRY_CODE &&
      (!!empType && (empType === 'current') || empType === 'prior')) {
      return true;
    } else {
      return false;
    }
  }  

  private removeJobTitleAndDriverDuties() {
    if (this.isReasonForLeavingMandatory) {
      this.showTitle = false;
      this.showDriverDuties = false;
      this.empSubForm.controls['jobTitle'].clearValidators();
      this.empSubForm.controls['jobTitle'].setValue('');
      if (this.empSubForm.controls['driverDuties']) {
        this.empSubForm.controls['driverDuties'].clearValidators();
        this.empSubForm.controls['driverDuties'].setValue('');
      }
    }
  }

  private processConfig(): void {
    //Get the Config Details from Parent Component - Education
    //console.log(this.configOptions);

    this.PARENT_STAGE_STEP_CONFIG = this.configOptions['PARENT_CONFIG'];
    this.SUB_STEP_ID = EmploymentConfig.subStepIndexMapping['job_title']['subStep'];
    this.SUB_STEP_INDEX = EmploymentConfig.subStepIndexMapping['job_title']['subStepIndex'];

    this.setState();
  }

  private setState(): void {
    this.payLoad = {
      name: this.PARENT_STAGE_STEP_CONFIG['STAGE_ID'],
      step: this.PARENT_STAGE_STEP_CONFIG['STEP_ID'],
      stepIndex: this.PARENT_STAGE_STEP_CONFIG['STEP_INDEX'],
      subStep: this.SUB_STEP_ID,
      subStepIndex: this.SUB_STEP_INDEX,
      iteration: this.row,
      status: this.currentStatus,
      dbRecordPresent: this.PARENT_STAGE_STEP_CONFIG['IS_DB_RECORD_PRESENT']
    };

    // console.log('EMP - job_title - setState');
    // console.log(this.payLoad);

    this._store.dispatch({ type: this.currentStatus, payload: this.payLoad });
  }

  ngOnDestroy() {

  }

  doSwipe(direction: string, data) {
    // alert(direction);
    if (direction === 'swiperight') {
      this.getPrevSwipe();
    }
    if (direction === 'swipeleft') {
      if (!!data['jobTitle']) {
        this.jobTitle(data);
      }
    }
  }


  getPrevSwipe() {
    this.onJobTitleSwipe.emit();
  }

  saveExit(obj) {
    // PPA-8745 - Auto-generated via regex - Arun, Supreet, Save n Exit Patch
    var re = this._eventBus.logoutAnnounced({ dummy: "workflow_exit" });
    if (re == undefined) return;
  }

  
  jobTitle(obj): void {
    /*
*
* User has submitted the form using 'NEXT' button, then current status should be changed to 'COMPLETED'.
* If user has submitted the form using 'SAVE & EXIT' button, the current status has already been
* changed to 'PENDING' in saveExit()
*
* */
    if (this.currentStatus === 'VISITED') {
      this.currentStatus = 'COMPLETED'
    }

    //Update the state
    this.setState();

    let indexDetail: Object = {};
    indexDetail['row'] = this.row;
    obj['indexDetail'] = indexDetail;
    obj['COMPONENT_STATE'] = this.payLoad;
    obj['startTime'] = this.startTime;
    this.onJobTitle.emit(obj);
  }


}
