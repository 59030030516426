<div class="pad0 body_container container loginMobilePad m-t-20 mar10 ">
  <div class=" anime-clean signup">
    <div class="row margin0">
      <!-- Image Section Starts here-->
      <div class="col-md-6 col-sm-6 hidden-xs dark_bg p-t-50">
        <!--<img src="../../assets/img/illustration02_1.png">-->
        <h3 class="text-center m-t-10 pad5">{{'UNSUBSCRIBE_HEADER_1'|translate }}</h3>
      </div>
      <!-- Image Section Ends here-->
      <!-- Unsubscribe Form Starts here-->
      <div class="pad0 col-xs-12  col-sm-6 col-md-6">
        <div class="signup_container">

          <div class="row m-t-40 mar-top80 pad15">
            <div class="col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 col-xs-12 text-center">
              <div class="header_txt_white">
                <h3 *ngIf="!isUnSubscribedFromSms && !isAlreadyUnsubscribedFromSMS">
                  {{'UNSUBSCRIBE_HEADER_2'|translate }}</h3>
                <h3 *ngIf="isUnSubscribedFromSms">{{'UNSUBSCRIBE_SUCCESS_MSG'|translate }}</h3>
                <h3 *ngIf="isUnSubscriptionError">{{'UNSUBSCRIBE_ERROR_MSG'|translate }}</h3>
                <h3 *ngIf="isAlreadyUnsubscribedFromSMS">{{'UNSUBSCRIBE_ALREADY_MSG'|translate }}</h3>
              </div>
              <div class="header_txt_white"
                *ngIf="isInitializationCompleted && !isUnSubscribedFromSms && !isAlreadyUnsubscribedFromSMS">
                <div class="col-xs-12  col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8  m-t-5 loginPad">
                  <button type="button" class="btn button font_extrabold" (click)="unsubscribeFromSms()"
                    [disabled]="!isResponsed" name="action"
                    [class.grow]="true">{{'UNSUBSCRIBE_BUTTON' | translate}}</button>
                </div>
              </div>
            </div>
          </div>

          <div class="row m-t-40 text-center padbot40 pad15 m-b-20">
            <img src="../../assets/img/sms_unsubscribe.svg" height="125px">
          </div>
        </div>
      </div>
      <!--Sign up From Ends here-->
    </div>
  </div>
  <!--Tab Navigation Slider End-->
  <div class="clearfix"></div>
</div>
