import { Pipe, PipeTransform } from '@angular/core';
import { SharedService } from 'app/shared/services/shared.service';

@Pipe({name: 'ssnMask'})
export class SSNMaskPipe implements PipeTransform {
  transform(value: string): string {
    var newVal = '';

    let ssn = value;
    if (ssn.length === 11) {
      newVal = ssn.replace(ssn.substr(0, 6), '***-**');
      return newVal;
    }
  }
}

@Pipe({name: 'maskGID'})
export class maskGIDPipe implements PipeTransform{
  transform(value: string): string {
    let newVal = '';

    let gidVal = value;
    if (gidVal !== undefined && gidVal.length > 0) {
      newVal = gidVal.replace(gidVal.substr(0, 5), '*****');
      return newVal;
    }
  }
}

@Pipe({name: 'mask'})
export class Mask implements PipeTransform{

  transform(value: string, field: string, type: string, isMaskEnabled: boolean): string {
    
    if (!isMaskEnabled) {
      return value;
    }

    // return empty string if type is DROP
    if (type === 'DROP') {
      return '';
    }

    let result = '';
    if (field === 'PHONE_NUMBER') {
      result = this.maskPhoneNumber(value);
    } 
    else {
      result = this.maskData(value);
    }
    
    return result;

  }

  /**
   * Function to mask GID Data
   * 
   * @param value 
   * @returns 
   */
  private maskData(value) {
    if (!!value && value !== 'NA') {
      value = value.trim().replace(new RegExp(".", "g"), "*");
    }

    value = (value === undefined) ? '': value;

    return value;
  }

  /**
   * Function to mask date of birth value
   * 
   * @param value 
   * @returns 
   */
  // private maskDateOfBirth(value) {
  //   debugger
  //   return value.trim().replace(new RegExp("[^, ]", "g"), "*");
  // }

  /**
   * Function to mask phone number.
   * Do not mask country code
   * 
   * @param value 
   * @returns 
   */
  private maskPhoneNumber(value) {
    if (!!value && value !== 'NA') {
      if (value.indexOf(' ')) {
        const countryCode = value.split(' ')[0];
        const data =  value.substring(value.indexOf(' '), value.length);
        const maskedValue = this.maskData(data);
        return countryCode + ' ' + maskedValue;
      } else {
        return value.replace(new RegExp(".", "g"), "*");
      }
    }
    return value;
  }
  
}