import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EmploymentService } from 'app/employment';

@Component({
  selector: 'profile-employment',
  templateUrl: './profile-employment.component.html',
  providers: []
})

export class ProfileEmploymentComponent {
  @Input() options: any;
  @Output() onViewProfile: EventEmitter<any> = new EventEmitter();
  dateFormat: string = 'MMMM YYYY';

  constructor(private _es: EmploymentService) {
    this.dateFormat = this._es.getDateFormat();
  }

  openViewProfilePopup(data, key): void {
    data['key'] = key;
    this.onViewProfile.emit(data);
  }
}