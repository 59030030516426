export enum YotiStatus {
    COMPLETED = 'COMPLETED',
    CANCELLED = 'CANCELLED',
    TIMEDOUT = 'TIMEDOUT',
    WAITING = 'WAITING',
    FAILED = 'FAILED',
    FRAUD_DETECTED = 'FRAUD_DETECTED'
};

export class YotiConfig {
     //TODO
}
