<div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
  <div class="group" [formGroup]="group">
    <label class="control-labelss" for="country" [class.labelEllipsis]="config.label && config.label.length > 30">
      {{config.label|translate}}
    </label>
    <a data-toggle="popover" data-placement="bottom" title="{{config.label|translate}}"
      *ngIf="config.hint != 'NoLabelRender' && config.label && config.label.length > 30">
      <i class="icon-info labelHelpIcon" (click)="showFullLabelText()"></i>
    </a>
    <input type="hidden" class="form-control" style="margin-top:10px;margin-bottom:10px;"
      [formControlName]="config.name">
  </div>
</div>