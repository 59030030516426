import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'app/app.config';
import { LocalStorageService } from 'framework/localstorage.service';
import { WorkflowService } from 'framework/workflow.service';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InstructionsService {

  headerOptions: any;
  workflow_instance_id: string;


  constructor(private _authHttp: HttpClient, private _workflow: WorkflowService, private localStorage: LocalStorageService) {
    this.workflow_instance_id = this.localStorage.getItem('workflow_instance_id');
    this.headerOptions = this._workflow.getHeaderOptions();
  }

  saveWorkflowInstanceAuditData(data: string): Observable<any> {
    let body = {};
    body['data'] = data;
    return this._authHttp.put(AppConfig.API_ENDPOINT() + '/api/v1/workflow/' + this.workflow_instance_id + '/workflow-audit', body, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this._handleError(err, 'instructions_viewed_update_failed'))
      );
  }

  private _extractData(res: any) {
    return res;
  }

  private _doAction(response: any) {
    return response;
  }

  private _handleError(error: any, eventName?) {
    let _body = error || {};
    let errorMessage: string;

    console['server'](eventName, error);

    switch (error.status) {
      case 400:
      case 401:
        errorMessage = _body['error']['error'];
        break;
      case 404:
        errorMessage = _body['error']['error'];
        break;
      case 412:
        errorMessage = "412";
    }
    return throwError(errorMessage);
  }

}
