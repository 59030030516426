/*
 * Parent ATS Component
 */
import { Component,OnInit,Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '../../../../framework/localstorage.service';

declare let window:any;
@Component({
  selector: 'ats-strip',
  templateUrl: './ats.component.html',
  styleUrls: ['./ats.component.css']
})
export class AtsComponent implements OnInit {

  @Input() data: any;
  @Input() source: string;
  @Input() iteration: string;
  @Input()  selectedCardIndex: number;
  @Input() showAddNew: boolean;
  
  selectedTemplate: any;
  sourceTemplate: any;

  // Required Properties - Employment
  isEmploymentDateToShow: boolean = true;

  @Output() onUpdateAtsData: EventEmitter < any > = new EventEmitter();
  @Output() onAddNewData: EventEmitter<any> = new EventEmitter();

  constructor(private localStorage: LocalStorageService) {

  }

  ngOnInit() {
    switch(this.source) {
      case 'employment_company':
        this.handleEmployment();
      break;
      default:
    }

  }

  ngAfterViewInit(): void {
    jQuery(".invitebtn").click(function (e) {
      e.preventDefault();
      jQuery('.dashboardCard').removeClass("activeborder");
      jQuery(this).parent().addClass("activeborder");
    });
  }

  updateAtsData(index, delete_link): void {
    window.track_event("ats selected, index: " + index + ', iteration: ' + this.iteration, { stage_name: 'ats', action_value: 'fill controls' }, true, true);
    let params = {
      'index': index,
      'iteration': this.iteration,
      'delete_link': delete_link
    }
    this.onUpdateAtsData.emit(params);
  }

  addNewData(): void {
    window.track_event("add new data", { stage_name: 'ats', action_value: 'add button clicked' }, true, true);
    this.onAddNewData.emit();
  }

  /**
   * 
   * 1. Handling DATES
   */
  handleEmployment(): void {

  }

}
