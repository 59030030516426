<div class="group" [formGroup]="group" ng-style="clear:both; width: 100%;">
  <div class="col-xs-12 col-sm-12 col-md-12">
    <h4>{{ config.displayContent.subheader | translate }}</h4>
  </div>
  <div class="col-xs-12 col-sm-12 col-md-12 custom_ul_li">
    <h4>{{ config.displayContent.selectedOption }}</h4>
    <ul *ngIf="isPostOfficeSelected">
      <li><b>{{ postOfficeName }}</b></li>
      <li>{{ postOfficeAddressLine1 }}</li>
      <li>{{ postOfficeAddressLine2 }}</li>
      <li>{{ postOfficePostalCode }}</li>
    </ul>
    <div *ngIf="!isPostOfficeSelected">
      <span>{{ employerName }}</span>
    </div>
  </div>
  <div class="col-xs-12 col-sm-12 col-md-12 custom_ul_li">
    <h4>{{ config.displayContent.documentHeader }}</h4>
    <ul class="custom_ul">
      <li *ngFor="let docData of config.displayContent.documentsList">
        {{ docData }}
      </li>
    </ul>
  </div>
</div>