import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

declare var jQuery: any;
@Component({
  selector: 'loader-only',
  templateUrl: './loader-only.component.html',
  styleUrls: ['./loader-only.component.css']
})
export class LoaderOnlyComponent implements OnInit {

  loaderContentKey = 'WAIT_ON_AGGREMENT_MODAL_TEXT';
  constructor(private _route: ActivatedRoute) {
    const os = this.getMobileOperatingSystem();
    if(!!os && os!=='IOS') {
      const paramType = (!!_route && !!_route.snapshot && !!_route.snapshot.params && !!_route.snapshot.params.type && _route.snapshot.params.type) || '';
      if(!!paramType && paramType.toUpperCase()==='PDF-VIEW') {
        this.loaderContentKey = 'PDF_VIEW_DOWNLOAD_MESSAGE';
      }
    }
  }

  ngOnInit(): void {
    jQuery('#dashboardWait-modal').modal('show');
  }

  getMobileOperatingSystem() {
    let res = '';
    try {
      const userAgent = navigator.userAgent || navigator.vendor || '';
      if (/android/i.test(userAgent)) {
        res = 'ANDROID';
      }
      else if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
        res = 'IOS';
      }
    }
    catch(e) {
      console.log('Error occurred while detecting device: ', e);
    }
    return res;
  }
}
