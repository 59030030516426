/**
 * Defines mapping between validators available on client with response from API service
 * from rules. Rule resolver uses this enum to properly map `ValidatorFn` to validator rule string.
 * */

export enum Validator {
  required = 'REQUIRED',
  requiredIf = 'REQUIRED_IF',
  conditionalZip = 'CONDITIONAL_ZIP',
  conditionalUsZip = 'CONDITIONAL_US_ZIP',
  conditionalUkZip = 'CONDITIONAL_UK_ZIP',
  noSpace = 'NOSPACE',
  noBlanks = 'NO_BLANKS',
  email = 'EMAIL',
  allowedValue = 'ALLOWED_VALUE',
  allowedValueOrLabels = 'ALLOWED_VALUE_OR_LABELS',
  fieldMatchAnother = 'FIELD_MATCH_ANOTHER',
  dateNotInFuture = 'DATE_NOT_IN_FUTURE',
  dateNotInPast = 'DATE_NOT_IN_PAST',
  dateAfter = 'DATE_AFTER',
  dateNotAfter= 'DATE_NOT_AFTER',
  dateBefore = 'DATE_BEFORE',
  dateNotBefore = 'DATE_NOT_BEFORE',
  dateIsValid = 'DATE_IS_VALID', // is input date valid date string ie. not 9228321jfefef
  legalAge = 'LEGAL_AGE', // when legal age is required (ie. dob at least 16 or 18 years, depending on parameter)
  conditionalPassportValidator = 'CONDITIONAL_PASSPORT_VALIDATOR',
  conditionalDrivingLicenseValidator = 'CONDITIONAL_DRIVING_LICENSE_NUMBER_VALIDATOR'
}
