import { Component, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/fieldconfig.interface';
import { AppConstants } from 'app/app.constants';
import { FormTextAreaConfig } from './form-textarea.config';

@Component({
  selector: 'form-textarea',
  styleUrls: ['form-textarea.component.scss'],
  templateUrl: './form-textarea.component.html'
})
export class FormTextareaComponent implements Field {
  config: FieldConfig;
  group: FormGroup;

  getRowsCount(name) {
    return (FormTextAreaConfig.textAreaRowsValue[name]) ?
      FormTextAreaConfig.textAreaRowsValue[name] :
      FormTextAreaConfig.textAreaRowsValue[AppConstants.DEFAULT_ROWS];
  }
}
