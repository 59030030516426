/* Modules Added Begin */
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './../shared/shared.module';
/* Modules Added End */

/* Components Begin */
import { EmploymentComponent } from './employment.component';
import { CategoryComponent } from './category/category.component';
import { VerificationComponent } from './verification/verification.component';
import { ReconfirmComponent } from './reconfirm/reconfirm.component';
import { VerificationConfirmComponent } from './verification-confirm/verification-confirm.component';
import { CompanyNameComponent } from './company-name/company-name.component';
import { CountryComponent } from './country/country.component';
import { CountryConfirmComponent } from './country-confirm/country-confirm.component';
import { CityStateComponent } from './city-state/city-state.component';
import { JobTitleComponent } from './job-title/job-title.component';
import { JobPeriodComponent } from './job-period/job-period.component';
import { CurrentEmploymentConfirmComponent } from './current-employment-confirm/current-employment-confirm.component';
import { PriorEmploymentConfirmComponent } from './prior-employment-confirm/prior-employment-confirm.component';
import { GapComponent } from './gap/gap.component';
import { GapConfirmComponent } from './gap-confirm/gap-confirm.component';
import { DatetocontactComponent } from './datetocontact/datetocontact.component';
import { ReportingManagerDetailsComponent } from './reporting-manager-details/reporting-manager-details.component';
import { PositionComponent } from './position/position.component';
import { MultiplePositionComponent } from './multiple-position/multiple-position.component';
import { IntlEmployementComponent } from './intl-employement/intl-employement.component';
import { SupervisorComponent } from './supervisor/supervisor.component';
import { EmploymentCustomformComponent } from './employment-customform/employment-customform.component';
/* Components End */

/* Service Begin */
import { EmploymentService } from './employment.service';
import { progressBarStatus } from './../../framework/progressbar.service';
import { EventBusService } from './../../framework/eventbus.service';
import { StatusbarService } from './../status-bar/status-bar.service';
import { arrowService } from './../../framework/arrow.service';
import { recordidService } from './employment-recordid.service';
import { TimeStampDateFormat } from '../pipes/dateformat.pipe';
import { GapFindService } from './../../framework/gapfind.service';
import { ClipboardService } from '../clipboard/clipboard.service';
import { DynamicFormModule } from '../../framework/dynamicCard';
import { EmploymentRoutingModule } from './employment-routing.module';
import { CustomFormService } from 'app/custom-form/custom-form.service';
import { CustomSelectModule } from '../../framework/customSelect';/* Service End */
import { DebounceModule } from 'framework/directive/debounce.module';
import { PayrollLookupComponent } from './payroll-lookup/payroll-lookup.component';
import { DataProviderSelectionComponent } from './plaid-flow/data-provider-selection/data-provider-selection.component';
import { EmploymentDetailsComponent } from './employment-details/employment-details.component';

import { NgxPlaidLinkModule } from 'ngx-plaid-link';

@NgModule({
  imports: [
    ReactiveFormsModule,
    SharedModule,
    DebounceModule,
    DynamicFormModule,
    CustomSelectModule,
    EmploymentRoutingModule,
    NgxPlaidLinkModule
  ],
  declarations: [
    EmploymentComponent,
    CategoryComponent,
    VerificationComponent,
    VerificationConfirmComponent,
    CompanyNameComponent,
    CountryComponent,
    ReportingManagerDetailsComponent,
    IntlEmployementComponent,
    PositionComponent,
    MultiplePositionComponent,
    CountryConfirmComponent,
    CityStateComponent,
    JobTitleComponent,
    JobPeriodComponent,
    CurrentEmploymentConfirmComponent,
    PriorEmploymentConfirmComponent,
    GapComponent,
    DatetocontactComponent,
    GapConfirmComponent,
    EmploymentCustomformComponent,
    ReconfirmComponent,
    SupervisorComponent,
    PayrollLookupComponent,
    DataProviderSelectionComponent,
    EmploymentDetailsComponent
  ],
  providers: [EmploymentService, progressBarStatus, EventBusService,
    StatusbarService, arrowService, recordidService,
    TimeStampDateFormat, GapFindService, ClipboardService, CustomFormService
  ]
})
export class EmploymentModule {

}
