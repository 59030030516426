export class EducationConfig {
  static STAGE_ID: string = 'education-data-v1';
  static FROM_YEAR = 2000;
  static priorEducationSelect = ['Secondary', 'High School', 'GED']; // priorselectionlist1
  static currentEducationSelect = ['College', 'Community', 'Trade']; // priorselectionlist2
  static EDUCATION_TYPES = 'EDUCATION_TYPES';
  static COLLEGE_QUALIFICATION_TYPES = 'COLLEGE_QUALIFICATION_TYPES';
  static SECONDARY_QUALIFICATION_TYPES = 'SECONDARY_QUALIFICATION_TYPES';
  static OTHER_QUALIFICATION_TYPES = 'OTHER_QUALIFICATION_TYPES';
  static TRADE_QUALIFICATION_TYPES = 'TRADE_QUALIFICATION_TYPES';
  static COMMUNITY_QUALIFICATION_TYPES = 'COMMUNITY_QUALIFICATION_TYPES';
  static GED_QUALIFICATION_TYPES = 'GED_QUALIFICATION_TYPES';
  static HIGHSCHOOL_QUALIFICATION_TYPES = 'HIGHSCHOOL_QUALIFICATION_TYPES';
  static TECNICAL_QUALIFICATION_TYPES = 'TECHNICAL_QUALIFICATION_TYPES';
  static POSTGRADUATE_QUALIFICATION_TYPES = 'POSTGRADUATE_QUALIFICATION_TYPES';

  static eduSteps: Object = {
    type: 'type',
    confirm: 'confirm',
    country: 'country',
    state: 'state',
    institution: 'institution',
    typedegree: 'typedegree',
    qualification: 'qualification',
    gpa: 'gpa',
    startdate: 'startdate',
    additional: 'additional',
    custform : 'custform',
    finalstep: 'finalstep'
  };

  static eduStepsArray: any = [
    'type',
    'country',
    'state',
    'institution',
    'confirm',
    'typedegree',
    'startdate',
    'qualification',
    'gpa',
    'additional',
    'custform',
    'finalstep'
  ];

  static subStepIndexMapping: Object = {
    type: {
      subStep: 'type',
      subStepIndex: 0
    },
    country: {
      subStep: 'country',
      subStepIndex: 1
    },
    state: {
      subStep: 'state',
      subStepIndex: 2
    },
    institution: {
      subStep: 'institution',
      subStepIndex: 3
    },
    confirm: {
      subStep: 'confirm',
      subStepIndex: 4
    },
    typedegree: {
      subStep: 'typedegree',
      subStepIndex: 5
    },
    qualification: {
      subStep: 'qualification',
      subStepIndex: 6
    },
    gpa: {
      subStep: 'gpa',
      subStepIndex: 7
    },
    startdate: {
      subStep: 'startdate',
      subStepIndex: 8
    },
    additional: {
      subStep: 'additional',
      subStepIndex: 9
    },
    custform: {
      subStep: 'custform',
      subStepIndex: 10
    },
    finalstep: {
      subStep: 'finalstep',
      subStepIndex: 11
    }
  };

  static headerSubheaders = [{
    'header': 'Education Information',
    'sub_header': 'Education Information'
  }];

  static getHeaderSubheaders(): any {
    return EducationConfig.headerSubheaders;
  }

  static ats: Object = {
    'city': '',
    'country': '',
    'country_name': '',
    'current_study': '',
    'degree-date': '',
    'degree-type': '',
    'end-date': '',
    'id': '',
    'name': '',
    'qualification': '',
    'source': '',
    'start-date': '',
    'state_code': '',
    'state_name': '',
    'type': '',
    'status': '',
    'major': '',
    'name_in_local_language': '',
    'address': '',
    'source_id': '',
    'form' : '',
    'state': {
      'step_status': '',
      'stage_status': '',
      'current_iteration': '',
      'current_stage': '',
      'current_step': '',
      'current_step_index': '',
      'current_sub_step': '',
      'current_sub_step_index': '',
      'current_status': ''
    }
  };

  static formTextDetails: Object = {
    highestdegreeprimarytext: "EDUCATION_DEGREE_HEADER_1",
    highestdegreesecondarytext: "EDUCATION_DEGREE_SUBHEADER_1",
    highestdegreeinstitutionprimarytext: "EDUCATION_DEGREE_HEADER_2",
    highestdegreeinstitutionsecondarytext: "EDUCATION_DEGREE_SUBHEADER_2",
    highestdegreeconfirmprimarytext: "EDUCATION_DEGREE_HEADER_3",
    highesttypeofdegreeprimarytext: "EDUCATION_DEGREE_HEADER_4",
    highestdegreequalificationprimarytext: "EDUCATION_DEGREE_HEADER_5",
    highestdegreequalificationsecondarytext: "EDUCATION_DEGREE_SUBHEADER_3",
    highestdegreecountryprimarytext: "EDUCATION_DEGREE_HEADER_6",
    highestdegreestateprimarytext: "EDUCATION_DEGREE_HEADER_7",
    highestdegreeyearfrom: "EDUCATION_DEGREE_FROM_DATE_1",
    highestdegreeyearto: "EDUCATION_DEGREE_TO_DATE_2",
    highestdegreefinalsteptext: "EDUCATION_DEGREE_SUBHEADER_4",
    currentstudyinginstitutionprimarytext: "EDUCATION_DEGREE_HEADER_8",
    currentstudyingprimarytext: "EDUCATION_DEGREE_HEADER_9",
    currentstudyingtypeofdegreeprimarytext: "EDUCATION_DEGREE_HEADER_10",
    currentstudyingqualificationprimarytext: "EDUCATION_DEGREE_HEADER_11",
    currentstudyingqualificationsecondarytext: "EDUCATION_DEGREE_SUBHEADER_5",
    currentstudyingcountrytext: "EDUCATION_DEGREE_INSTRUCTION_1",
    currentstudyingstatetext: "EDUCATION_DEGREE_INSTRUCTION_2",
    currentstudyingfromyeartext: "EDUCATION_DEGREE_INSTRUCTION_3",
    currentstudyingaftertypesecondarytext: "EDUCATION_DEGREE_SUBHEADER_6",
    currentstudyingafterqualificationtext: "EDUCATION_DEGREE_SUBHEADER_7",
    currentstudyingaftercountrytext: "EDUCATION_DEGREE_INSTRUCTION_4",
    currentstudyingafterstatetext: "EDUCATION_DEGREE_INSTRUCTION_5",
    highestdegreestartdateprimarytext: 'EDUCATION_DEGREE_HEADER_12',
    highestdegreeenddateprimarytext: 'EDUCATION_DEGREE_HEADER_12',
    currentstudyingtypeofdegreenoprimarytext: 'EDUCATION_DEGREE_HEADER_14'
  };

  static defaultCountry: string = '';
  static defaultCountryShortName: string = '';
  static typeDecision: string = 'type';
  static confirmDecision: string = 'confirm';
  static currentStudyConfirmDecision: string = 'currentstudyconfirm';
  static typeDegreeDecision: string = 'typedegree';
  static const_clear: string = "CLEAR";
  static regular: Object = {
    'city': '',
    'country': '',
    'country_name': '',
    'current_study': '',
    'degree-date': '',
    'degree-type': '',
    'end-date': '',
    'id': '',
    'name': '',
    'qualification': '',
    'source': '',
    'start-date': '',
    'state_code': '',
    'state_name': '',
    'type': '',
    'degree_confirm': '',
    'current_study_confirm': '',
    'status': '',
    'major': '',
    'source_id': '',
    'form' : '',
    'state': {
      'step_status': '',
      'stage_status': '',
      'current_iteration': '',
      'current_stage': '',
      'current_step': '',
      'current_step_index': '',
      'current_sub_step': '',
      'current_sub_step_index': '',
      'current_status': ''
    }
  };

  static displayLabel: Object = {
    'City': '',
    'Country': '',
    'Degree Date': '',
    'Degree Type': '',
    'End Date': '',
    'Name': '',
    'Qualification': '',
    'Source': '',
    'State Date': '',
    'State': '',
    'Type': '',
    'delete_link': '',
    'major': '',
    'display_card': true
  };

  static defaultCountryOptions(): Object {
    let countryOptions = {};
    countryOptions['defaultCountry'] = EducationConfig.defaultCountry;
    countryOptions['defaultCountryShortName'] = EducationConfig.defaultCountryShortName;

    return countryOptions;
  }

  static decisionChangeOptions(): Object {
    let decisionChangeOptions = {};
    decisionChangeOptions['typeDecision'] = EducationConfig.typeDecision;
    decisionChangeOptions['confirmDecision'] = EducationConfig.confirmDecision;
    decisionChangeOptions['currentStudyConfirmDecision'] = EducationConfig.currentStudyConfirmDecision;
    decisionChangeOptions['typeDegreeDecision'] = EducationConfig.typeDegreeDecision;
    return decisionChangeOptions;
  }

  static getType(): Array<Object> {
    let types = [
      { id: 'college', name: 'EDUCATION_TYPE_1' },
      { id: 'community', name: 'EDUCATION_TYPE_2' },
      { id: 'technicalCollege', name: 'EDUCATION_TYPE_3' },
      { id: 'postGraduate', name: 'EDUCATION_TYPE_4' },
      { id: 'other', name: 'EDUCATION_TYPE_5' },
      { id: 'highschool', name: 'EDUCATION_TYPE_6' },
      { id: 'GED', name: 'EDUCATION_TYPE_7' }
    ];

    return types;

  }
  static getQualification(degreeType): Array<Object> {
    let qualification: any;
    // debugger;
    if (degreeType === 'college') {
      qualification = [
        { id: 'associates', name: 'EDUCATION_DEGREE_TYPE_6' },
        { id: 'baccalaureate', name: 'EDUCATION_DEGREE_TYPE_2' },
        { id: 'bachelors', name: 'EDUCATION_DEGREE_TYPE_1' },
        { id: 'diploma', name: 'EDUCATION_DEGREE_TYPE_9' },
        { id: 'professional', name: 'EDUCATION_DEGREE_TYPE_5' },
        { id: 'other', name: 'EDUCATION_DEGREE_TYPE_10' },
        { id: 'none', name: 'EDUCATION_DEGREE_TYPE_11' },
        { id: 'masters', name: 'EDUCATION_PG_DEGREE_TYPE_1'},
        { id: 'Phd', name: 'EDUCATION_PG_DEGREE_TYPE_2'},
        { id: 'postdoctorate', name: 'EDUCATION_PG_DEGREE_TYPE_3'},
        { id: 'Post-graduate', name: 'EDUCATION_PG_DEGREE_TYPE_4'}
      ];
    }
    if (degreeType === 'technicalCollege') {
      qualification = [
        { id: 'certification', name: 'EDUCATION_TECHNICAL_DEGREE_TYPE_1' },
        { id: 'professional', name: 'EDUCATION_TECHNICAL_DEGREE_TYPE_2' },
        { id: 'vocational', name: 'EDUCATION_TECHNICAL_DEGREE_TYPE_3' },
        { id: 'other', name: 'EDUCATION_TECHNICAL_DEGREE_TYPE_4' },
        { id: 'none', name: 'EDUCATION_TECHNICAL_DEGREE_TYPE_5' }
      ];
    }
    if (degreeType === 'postGraduate') {
      qualification = [
        { id: 'masters', name: 'EDUCATION_PG_DEGREE_TYPE_1' },
        { id: 'Phd', name: 'EDUCATION_PG_DEGREE_TYPE_2' },
        { id: 'postdoctorate', name: 'EDUCATION_PG_DEGREE_TYPE_3' },
        { id: 'Post-graduate', name: 'EDUCATION_PG_DEGREE_TYPE_4' },
        { id: 'Other', name: 'EDUCATION_PG_DEGREE_TYPE_5' },
        { id: 'None', name: 'EDUCATION_PG_DEGREE_TYPE_6' }
      ];
    }

    if (degreeType === 'community') {
      qualification = [

        { id: 'associates', name: 'EDUCATION_COMMUNITY_DEGREE_TYPE_1' },
        { id: 'baccalaureate', name: 'EDUCATION_COMMUNITY_DEGREE_TYPE_2' },
        { id: 'bachelors', name: 'EDUCATION_COMMUNITY_DEGREE_TYPE_3' },
        { id: 'diploma', name: 'EDUCATION_COMMUNITY_DEGREE_TYPE_4' },
        { id: 'professional', name: 'EDUCATION_COMMUNITY_DEGREE_TYPE_5' },
        { id: 'vocational', name: 'EDUCATION_COMMUNITY_DEGREE_TYPE_6' },
        { id: 'other', name: 'EDUCATION_COMMUNITY_DEGREE_TYPE_7' },
        { id: 'none', name: 'EDUCATION_DEGREE_TYPE_11' }
      ];
    }
    if (degreeType === 'GED') {
      qualification = [
        { id: 'GCE', name: 'EDUCATION_GED_DEGREE_TYPE_2' },
        { id: 'GED', name: 'EDUCATION_GED_DEGREE_TYPE_1' },
        { id: 'other', name: 'EDUCATION_GED_DEGREE_TYPE_3' },
        { id: 'none', name: 'EDUCATION_GED_DEGREE_TYPE_4' }
      ];
    }
    if (degreeType === 'highschool' || degreeType === 'high school') {
      qualification = [
        { id: 'diploma', name: 'EDUCATION_HIGHSCHOOL_DEGREE_TYPE_4' },
        { id: 'highschool', name: 'EDUCATION_HIGHSCHOOL_DEGREE_TYPE_1' },
        { id: 'other', name: 'EDUCATION_HIGHSCHOOL_DEGREE_TYPE_2' },
        { id: 'none', name: 'EDUCATION_HIGHSCHOOL_DEGREE_TYPE_3' }
      ];
    }
    if (degreeType === 'other') {
      qualification = [

        { id: 'certification', name: 'EDUCATION_OTHER_DEGREE_TYPE_2' },
        { id: 'diploma', name: 'EDUCATION_OTHER_DEGREE_TYPE_4' },
        { id: 'professional', name: 'EDUCATION_OTHER_DEGREE_TYPE_1' },
        { id: 'vocational', name: 'EDUCATION_OTHER_DEGREE_TYPE_3' },
        { id: 'other', name: 'EDUCATION_OTHER_DEGREE_TYPE_5' },
        { id: 'none', name: 'EDUCATION_OTHER_DEGREE_TYPE_6' }
      ];
    }

    return qualification;
  }

  static getGeoConfig(): Object {
    return {
      country: {},
      address: {
        types: ['address'],
        componentRestrictions: { country: EducationConfig.defaultCountryShortName }
      },
      state: {
        types: ['(regions)'],
        componentRestrictions: { country: EducationConfig.defaultCountryShortName }
      },
      city: {
        types: ['(cities)'],
        componentRestrictions: { country: EducationConfig.defaultCountryShortName }
      }
    };
  }

  static getSteps(): any {
    return EducationConfig.eduStepsArray;
  }

  static getQualificationType(key: string) {
    let qualificationTypesMap: Map<string, string> = new Map<string, string>();

    qualificationTypesMap.set('college', this.COLLEGE_QUALIFICATION_TYPES);
    qualificationTypesMap.set('technicalCollege', this.TECNICAL_QUALIFICATION_TYPES);
    qualificationTypesMap.set('other', this.OTHER_QUALIFICATION_TYPES);
    qualificationTypesMap.set('postGraduate', this.POSTGRADUATE_QUALIFICATION_TYPES);
    qualificationTypesMap.set('community', this.COMMUNITY_QUALIFICATION_TYPES);
    qualificationTypesMap.set('GED', this.GED_QUALIFICATION_TYPES);
    qualificationTypesMap.set('highschool', this.HIGHSCHOOL_QUALIFICATION_TYPES);

    return qualificationTypesMap.get(key);
  }

  static getEducationTypeObject(): Object {
    let typeObj = {
      'postgraduate': 'postGraduate',
      'college': 'college',
      'technicalcollege': 'technicalCollege',
      'community': 'community',
      'other': 'other',
      'highschool': 'highschool',
      'ged': 'GED',
      'DEFAULT': 'none'
    };

    return typeObj;
  }

  /**
   * get education name for the given type for translation
   *
   * @param type
   */
  static getEducationType(type: String) {
    let educationTypes = this.getType();

    for (let educationType of educationTypes) {
      if (educationType['id'].toUpperCase() === type.toUpperCase()) {
        return educationType['name'];
      }
    }

    return null;
  }
}
