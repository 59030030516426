import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/fieldconfig.interface';
import { EventBusService } from 'framework/eventbus.service';

declare var $: any;

@Component({
  selector: 'form-checkbox',
  styleUrls: ['form-checkbox.component.scss'],
  templateUrl: './form-checkbox.component.html'
})
export class FormCheckboxComponent implements Field {
  config: FieldConfig;
  group: FormGroup;
  checkboxClass: string;
  currentSelection: boolean = false;

  constructor(private _eventBus: EventBusService,) { }

  ngOnInit() {
    if (this.config["checkBoxClass"] !== undefined) {
      this.checkboxClass = this.config["checkBoxClass"];
    }
    this.currentSelection = (!!this.config['value'] && (this.config['value'] === "true" || this.config['value'] === "TRUE" || this.config['value'] === true)) ? true : false;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if(this.config['value'] === undefined || this.config['value'] === false) {
        this._eventBus.dynamicFormCheckBoxGenericAnnounced({
          selectedObj: {
            value: false,
            type: this.config["type"],
            name: this.config["name"]
          }
        });
      }
    }, 300);
  }

  onSelectionChange(data: any) {
    // Subscribe event added in custom disclosure component
    this._eventBus.dynamicFormCheckboxAnnounced({
      selectedObj: {
        value: this.group["controls"][data["name"]].value,
        type: this.config["type"],
        name: data["name"]
      }
    });

    // Subscribe event added in dynamic form component in ngOninit
    this._eventBus.dynamicFormCheckBoxGenericAnnounced({
      selectedObj: {
        value: this.group["controls"][data["name"]].value,
        type: this.config["type"],
        name: data["name"]
      }
    });
  }
}
