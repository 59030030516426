import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../../../app.config';
import { WorkflowService } from '../../../../framework/workflow.service';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class CountryPickerService {
  headerOptions: any;
  constructor(private _authHttp: HttpClient,
    private _workflow: WorkflowService) {
    this.headerOptions = this._workflow.getHeaderOptions();
  }

  lookUpService(country): Observable<any> {
    let step = "lookup/shortcodes";

    return this._authHttp.get(AppConfig.API_ENDPOINT() + `/api/v2/workflow/lookup/shortcodes/?country=${country}` + '&t=' + new Date(), this.headerOptions)
    .pipe(
      map(res => this._extractData(res)),
      map(res => this._doAction(res)),
      catchError(this._handleError)
    );
  }

  private _extractData(res: any) {
    return res;
  }

  private _doAction(response: any) {
    return response;
  }

  private _handleError(error: any) {
    let _body = error || {};
    let errorMessage: string;
    switch (error.status) {
      case 400:
      case 401:
        //Bad request, Invalid Credentials - login invalid
        errorMessage = _body['error']['error'] || 'Invalid Login';
        break;
      case 404:
        //Zip code
        errorMessage = _body['error']['error'];
        break;
    }
    return throwError(errorMessage);
  }
}
