<div class="pad0 body_container container m-t-20 mar10 expired-link">
  <div class="anime-clean">
    <div class="row margin0">
      <div class="col-sm-12 col-md-12">
        <h2 class="text-center m-t-10 pad5">
          {{ 'EXPIRED_LINK_HEADER' | translate }}
        </h2>
      </div>
    </div>
    <div class="row margin0 text-center">
      <img src="../../assets/img/expired_link.png" width="280px" height="180px" >
    </div>
    <div class="row margin0">
      <div class="col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8">
        <!-- <p class="text-center m-t-10 pad5">
          {{ 'EXPIRED_LINK_SUBHEADER' | translate }}
        </p> -->
        <p class="text-center m-t-10 pad5">
          {{ 'EXPIRED_LINK_SUBHEADER_1' | translate}}
        </p>
      </div>
    </div>
    <div class="row margin0 text-center">
      <div class="col-xs-offset-3 col-xs-6 col-sm-offset-3 col-sm-6 col-md-offset-3 col-md-6 m-t-5 loginPad">
        <button type="submit" class="btn button btn-primary font_extrabold" name="action" (click)="onStartAgainClick()">{{ 'EXPIRED_LINK_BUTTON' | translate }}</button>
      </div>
    </div>
  </div>
</div>
