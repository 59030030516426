<form [formGroup]="documentUploadSubForm" novalidate>
<div class="anime-clean posrel skippedDocuments" hammer-gestures>
    <button type="button" class="btn arrowrightfl" [disabled]="!documentUploadSubForm.valid" debounceDirective (click)="docSubmissionDetails(documentUploadSubForm.value)">
        <i class="icon-arrow-right pull-right" role="img" [attr.aria-label]="'SCREEN_FARWARD_ARROW' | translate"></i>
    </button>
    <button type="button" class="btn arrowleftctr" debounceDirective (click)="getPrevSwipe()">
        <i class="icon-arrow-left" role="img" [attr.aria-label]="'SCREEN_BACKWARD_ARROW' | translate"></i>
    </button>
    <div class="contetnheight padn_30 posrel">
        <div class="row">
            <div class="col-md-12 col-xs-12 col-sm-12">
                <div class="header-txt header_txt_white">
                    <h2 class="m-b-20" tabindex="0" *ngIf="!isPendingInfoDueDateAvailable">
                       {{ 'DOC_UPLOAD_SKIPPEDDOCS_HEADER' | translate : [firstName] }}
                    </h2>
                    <h2 class="m-b-20" tabindex="0" *ngIf="isPendingInfoDueDateAvailable">
                        {{ 'DOC_UPLOAD_SKIPPEDDOCS_HEADER_DUE_DATE' | translate : [firstName, pendingInfoDueDate] }}
                    </h2>
                </div>
            </div>
        </div>
        <div class="skippedDocumentContent">
            <div class="row">
                <div class="col-md-8 col-sm-8 col-xs-12" *ngFor="let option of inputSkippedDocumentsData['skipped-documents-list']; let i = index;">
                    <div class="skippedDocumentList">
                        <h3>{{ option | accordionTitleFormat }}</h3>
                        <ul class="skippedDocList" *ngFor="let indFile of option['statewide-criminal-data-list']; let j = index" >
                            <li> {{ indFile['file_desc'] }} </li>
                        </ul>
                    </div>
                </div>                
            </div>
            <div class="row m-t-20" *ngIf="!isPendingInfoDueDateAvailable">
                <div class="col-xs-12 col-sm-4 col-md-4 m-b-20">
                    <div class="group">
                        <label class="control-labelss" for="futureDatetoSubmitDocuments">{{ 'DOC_UPLD_FUTURE_DATE_TO_SUBMIT_DOC' | translate }}</label>
                        <input type="hidden" class="form-control" aria-required="true" name="futureDateToSubmitDoc"
                            formControlName="futureDateToSubmitDoc">
                        <pa-date-picker id="futureDatetoSubmitDocuments" picker="doc-submission-date" [setDate]="futureSubmissionSetDate"
                            [maxDate]="futureSubmissionMaxDate"
                            appear="bubble" theme="material" placeholder="" (ongetDpValue)="getFutureDateDpValue($event)" (onDateError)="getFutureDateToSubmitDocError($event)">
                        </pa-date-picker>
                        <div class="error_info" *ngIf="showFutureDateToSubmitDocErrorMsg">
                            <div class="custom-error error_new_ui">
                                <div class="left_div">
                                    <ul>
                                        <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate}}</li>
                                        <li class="message">{{'DATE_OUTOF_RANGE' | translate}}</li>
                                    </ul>
                                </div>
                                <div class="right_div">
                                    <div class="close" aria-hidden="true" debounceDirective (click)="closeFutureDateToSubmitDocErrorMsg()">
                                    <i class="icon-cancel"></i>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                    </div>
                </div>
            </div>
            <div class="row skipDocControls">
                <div class="col-xs-12 col-sm-11 col-md-11 m-b-10">
                    <div class="group marbot15">
                        <label class="control-labelss" for="reasonToSkipDocSubmission">{{'DOC_UPLD_REASON_TO_SKIP_DOC_SUBMISSION' | translate }}</label>
                        <textarea aria-required="true" class="skippedReason" rows="3" id="reasonToSkipDocSubmission"
                            formControlName="reasonToSkipDocSubmission" maxlength="3000" (focusout)="reasonToSkipData()"
                        ></textarea>                       
                    </div>
                </div>
            </div>
        </div>

        <div class="row fixedbtn">
            <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
                <button type="button" class="btn button grow font_extrabold" [disabled]="!documentUploadSubForm.valid" attr.data-id="docSubmissionDetailsNext"
                    debounceDirective (click)="docSubmissionDetails(documentUploadSubForm.value)">{{ 'COMMONS_NEXT' | translate }}</button>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
                <button type="button" class="btn button_wbg grow font_extrabold sendButton button_wbgSav" attr.data-id="docSubmissionDetailsSaveExit"
                    debounceDirective (click)="saveExit(documentUploadSubForm.value)">{{ 'COMMONS_SAVE_AND_EXIT' | translate }}</button>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</div>
</form>