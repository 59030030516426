<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12">
    <p class="black_text">{{ options['title'] }}</p>
    <hr>
  </div>
</div>
<div class="addCard m-b-10">
  <div class="row">
    <div class="col-xs-12 col-sm-4 col-md-3 dp-r-0" *ngFor="let data of options.data">
      <!-- If Standard Criminal  Exist -->
      <div class="clipCard" (click)="openViewProfilePopup(data,options['key'])"
        *ngIf="data['pkge'] === 'standardCriminal'">
        <!--<div class="verify">
                  <img src="/assets/img/verifiedimage.png" class="height-102">
              </div>-->
        <img src="/assets/img/criminal.png" height="30px">
        <p class="company" *ngIf="data['type'] && data['type'].length > 0">{{data['type'] | translate}}</p>
        <p class="text_ellipses">
          <span *ngIf="data['category'] && data['category'].length > 0">{{data['category'] | translate}}, </span>
          <span *ngIf="data['city'] && data['city'].length > 0">{{data['city']}}, </span>
          <span *ngIf="data['state_name'] && data['state_name'].length > 0">{{data['state_name']}}</span>
        </p>
        <p class="text_ellipses">
          <span *ngIf="data['country_name'] && data['country_name'].length > 0">{{data['country_name']}}, </span>
          <span *ngIf="data['county'] && data['county'].length > 0">{{data['county']}}</span>
        </p>
      </div>

      <!-- If Standard Disclosure Exist -->
      <div class="clipCard" (click)="openViewProfilePopup(data,options['key'])"
        *ngIf="data['pkge'] === 'standardDisclosure'">
        <!--<div class="verify">
                  <img src="/assets/img/verifiedimage.png" class="height-102">
              </div>-->
        <img src="/assets/img/criminal.png" height="30px">
        <p class="company">{{data['type']  | translate}}</p>
        <p class="text_ellipses">
          <span
            *ngIf="data['enforcement_detail'] && data['enforcement_detail'].length > 0">{{ data['enforcement_detail'] }}</span>
        </p>
        <p class="text_ellipses">
          <span *ngIf="data['convicted_detail'] && data['convicted_detail'].length > 0">{{data['convicted_detail']}}
          </span>
        </p>
      </div>

      <!-- If Standard Disclosure Extension Exist -->
      <div class="clipCard" (click)="openViewProfilePopup(data,options['key'])"
        *ngIf="data['pkge'] === 'standardDisclosureExtension'">
        <img src="/assets/img/criminal.png" height="30px">
        <p class="company">{{data['type']  | translate}}</p>
      </div>
    </div>
  </div>
</div>
