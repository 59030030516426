import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'app/app.config';
import { LocalStorageService } from 'framework/localstorage.service';
import { WorkflowService } from 'framework/workflow.service';
 
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CitadelidLookupService {
  headerOptions: any;
  workflow_instance_id: string;
  
  constructor(private _httpClient: HttpClient,
    private _workflow: WorkflowService,
    private localStorage: LocalStorageService) {
    this.headerOptions = this._workflow.getHeaderOptions();
    this.workflow_instance_id = this.localStorage.getItem('workflow_instance_id');
  }

  getBridgeToken(): Observable<any> {
    return this._httpClient.get(AppConfig.API_ENDPOINT() + '/api/v1/workflow/' + this.workflow_instance_id + '/citadel-step' + '/?t=' + new Date(), this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this._handleError('get_bridge_token_failed', err))
      );
  }

  getEmploymentDetails(publicToken: string): Observable<any> {
    let body = {
      'public_token': publicToken
    };    
    return this._httpClient.put(AppConfig.API_ENDPOINT() + '/api/v1/workflow/' + this.workflow_instance_id + '/citadel-step', body, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this._handleError('get_citadel_employment_details_failed', err))
      );
  }

  private _extractData(res: any) {
    return res;
  }

  private _doAction(response: any) {
    return response;
  }

  private _handleError(eventName, error: any) {
    this.localStorage.setItem('citadel_error_status', error.headers.get('location'));
    let _body = error || {};
    let errorMessage: string;

    console['server'](eventName, error);

    switch (error.status) {
      case 400:
      case 401:
        // Bad request, Invalid Credentials - login invalid
        errorMessage = _body['error']['error'] || 'Invalid Login';
        break;
      case 404:
        break;
    }

    return throwError(errorMessage);
  }
}
