import { Component, OnInit } from '@angular/core';
import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/fieldconfig.interface';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.css']
})
export class DynamicDocumentUploadComponent implements Field {

  config: FieldConfig;
  group: FormGroup
  
  constructor() { }

  ngOnInit() {
    // console.log(JSON.stringify(this.config));
  }

  uploadAction(obj){
    // debugger
    // console.log(JSON.stringify(obj));
  }

  uploadDocument(obj) {
    // debugger
    // console.log(obj);
  }

}
