export class FormRadioConfig {
    static DEFAULT_OPTIONS = [
        {
            'label': 'COMMONS_YES',
            'position': '1',
            'isDefault': 'false',
            'value': 'TRUE'
        },
        {
            'label': 'COMMONS_NO',
            'position': '2',
            'isDefault': 'false',
            'value': 'FALSE'
        }
    ];

    static VERIFY_DOCUMENTS_OPTIONS = [
        {
            'label': 'UK_POST_OFFICE',
            'position': '1',
            'isDefault': 'false',
            'value': 'UK Post Office'
        },
        {
            'label': 'EMPLOYER',
            'position': '2',
            'isDefault': 'false',
            'value': 'Employer'
        }
    ];
}
