import { Component, Input, Output, EventEmitter, AfterViewInit, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { EmploymentConfig } from '../employment.config';
import { EventBusService } from 'framework/eventbus.service';
import { TranslateService } from 'framework/i18n';
import { EmploymentService } from '../employment.service';
import { Store } from '@ngrx/store';
import * as ACTIONS_INTERFACE from '../../actions';
import { LocalStorageService } from 'framework/localstorage.service';

@Component({
  selector: 'payroll-lookup',
  templateUrl: './payroll-lookup.component.html',
  styleUrls: ['./payroll-lookup.component.css']
})
export class PayrollLookupComponent implements OnInit {

  @Input('group')
  public empSubForm: FormGroup;
  @Input() row: any;
  @Input() options: any;
  @Input() configOptions: any;
  @Input() showCitadelData: boolean;
  citadelExplainerContent: any;
  @Output() onPayrollLookup: EventEmitter<any> = new EventEmitter();
  @Output() onPayrollOptOut: EventEmitter<any> = new EventEmitter();
  @Output() onPayrollLookupPrevSwipe: EventEmitter<any> = new EventEmitter();

  //SE functionality
  static STAGE_ID: string;
  static STEP_ID: string;
  static STEP_INDEX: number;
  public SUB_STEP_ID: string;
  public SUB_STEP_INDEX: number;
  PARENT_STAGE_STEP_CONFIG: Object;

  currentStatus: string = 'VISITED';
  payLoad: Object;
  isViewInit;
  dateFormat: string = 'MMMM YYYY';


  constructor(private _store: Store<ACTIONS_INTERFACE.AppState>, private _es: EmploymentService, private _ts:TranslateService,
    private localStorage: LocalStorageService) {
    this.dateFormat = this._es.getDateFormat();
  }

  ngOnInit(): void {
    var firstName = this.localStorage.getItem('first_name');
    this.citadelExplainerContent = this._ts.instant('EMP_CITADEL_DESC', firstName);
  }

  ngAfterViewInit(): void {
    //console.log('----------- ngAfterViewInit -----------------');
    this.processConfig();
    this.isViewInit = true;
  }


  private processConfig(): void {
    //console.log(this.configOptions);
    this.PARENT_STAGE_STEP_CONFIG = this.configOptions['PARENT_CONFIG'];
    this.SUB_STEP_ID = EmploymentConfig.subStepIndexMapping['payroll_lookup']['subStep'];
    this.SUB_STEP_INDEX = EmploymentConfig.subStepIndexMapping['payroll_lookup']['subStepIndex'];
    this.setState();
  }

  private setState(): void {
    this.payLoad = {
      name: this.PARENT_STAGE_STEP_CONFIG['STAGE_ID'],
      step: this.PARENT_STAGE_STEP_CONFIG['STEP_ID'],
      stepIndex: this.PARENT_STAGE_STEP_CONFIG['STEP_INDEX'],
      subStep: this.SUB_STEP_ID,
      subStepIndex: this.SUB_STEP_INDEX,
      iteration: this.row,
      status: this.currentStatus,
      dbRecordPresent: this.PARENT_STAGE_STEP_CONFIG['IS_DB_RECORD_PRESENT']
    };
    this._store.dispatch({ type: this.currentStatus, payload: this.payLoad });
  }

  payrollLookup(obj) {
    if (!!this.showCitadelData) {
      if (this.currentStatus === 'VISITED') {
        this.currentStatus = 'COMPLETED';
      }
      this.setState();
      obj['COMPONENT_STATE'] = this.payLoad;
      let indexDetail: Object = {};
      indexDetail['row'] = this.row;
      obj['indexDetail'] = indexDetail;
    }
    this.onPayrollLookup.emit(obj);
  }

  payrollOptOut(obj) {
    this.onPayrollOptOut.emit(obj);
  }

  getPrevSwipe(obj) {
    let indexDetail: Object = {};
    indexDetail['row'] = this.row;
    obj['indexDetail'] = indexDetail;
    this.onPayrollLookupPrevSwipe.emit(obj);
  }
}
