import { UtilConfig } from './utils.config';

let monthNames: any; /*string[] = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];*/

export function getEnglishMonths() {
  return [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];
}

export function getDateFormat(date: string, monthNames: any[]) {
  let dte = date.replace(/-/g, '/');
  let dt = new Date(dte);
  let dateformat = monthNames[dt.getMonth()] + ' ' + dt.getFullYear();
  return dateformat;
}

export function formatDateLabeltoYyyyMmDd(d, monthNames): any {
  let monthYear = d.split(' ');
  let monthIndex = 0;
  let yearIndex = 1;
  if (monthYear.length > 2) {
    // set month index as 1 and year index as 2 if the index is more than 2 after split
    // else firefox sending as NaNaN chrome sends wrong date
    monthIndex = 1;
    yearIndex = 2;
  }
  let month = (monthNames.indexOf(monthYear[monthIndex]) + 1).toString();
  let year = monthYear[yearIndex];
  let date = '01';
  let formatedDate = `${year}/${month}/${date}`;
  return formatedDate;
}

/**
 * 
 * @param d - date
 * @param langMonthNames - multilingual language month
 * @param engMonthNames - english language month
 * This function will return english date format EX:- AUGO 2017 to AUGUST 2017
 */
export function formatEngDateLabel(d, langMonthNames, engMonthNames): any {
  if(!d){
    return d;
  }
  let monthYear = d.split(' ');
  let month = monthYear[0];
  let year = monthYear[1];
  let engFormat;
  let monthIndex = (engMonthNames.indexOf(month));
  if (monthIndex > -1) {
    engFormat = monthYear[0] + ' ' + monthYear[1];
  } else {
    monthIndex = (langMonthNames.indexOf(month));
    engFormat = engMonthNames[monthIndex] + ' ' + year;
  }
  return engFormat;
}

export function formatEngFullDateLabel(d, langMonthNames, engMonthNames): any {
  let dayMonthYear = d.split(' ');
  let month = dayMonthYear[0];
  let dayYear: any;
  if (dayMonthYear.length === 3) {
    dayYear = dayMonthYear[1] + dayMonthYear[2];
  } else {
    dayYear = dayMonthYear[1];
  }
  let engFormat: string;
  let monthIndex = (engMonthNames.indexOf(month));
  if (monthIndex > -1) {
    engFormat = dayMonthYear[0] + ' ' + dayYear;
  } else {
    monthIndex = (langMonthNames.indexOf(month));
    engFormat = engMonthNames[monthIndex] + ' ' + dayYear;
  }
  return engFormat;
}

/* Accepts date in Month Year ex:January 2014
 and returns ISO formated date ==>yyyy-mm-ddd ex:2014-01-01
 */
export function formatISODateLabel(d, monthNames): any {
  let monthYear = d.split(' ');
  let month = (monthNames.indexOf(monthYear[0]) + 1).toString();
  let year = monthYear[1];
  let date = '01';
  let formatedDate = `${year}-${month}-${date}`;
  return formatedDate;
}

/* Accepts date in Month Year ex:January 2014
 and returns ISO formated date ==>yyyy-mm-ddd ex:2014-01-01
 Only in case of multilingual
 */

export function convertToEngFormat(date, langMonthName: any, engMonthName: any) {
  let monthYear = date.split(' ');
  let month = monthYear[0];
  let year = monthYear[1];
  let monthIndex: any;

  // Find the month whether it is existing in Other language, Else continue with Engmonth
  monthIndex = langMonthName.indexOf(month);
  if (monthIndex < 0) {
    monthIndex = engMonthName.indexOf(month);
  }

  return `${engMonthName[monthIndex]} ${year}`;
}

export function convertFullDateToEngFormat(date, langMonthName: any, engMonthName: any) {
  let dayMonthYear = date.split(' ');
  let month = dayMonthYear[0];
  let dayYear: any;
  let monthIndex: any;
  if (dayMonthYear.length === 3) {
    dayYear = dayMonthYear[1] + dayMonthYear[2];
  } else {
    dayYear = dayMonthYear[1];
  }

  // Find the month whether it is existing in Other language, Else continue with Engmonth
  monthIndex = langMonthName.indexOf(month);
  if (monthIndex < 0) {
    monthIndex = engMonthName.indexOf(month);
  }

  return `${engMonthName[monthIndex]} ${dayYear}`;
}

/* Accepts date in Month Year ex:January 6,2014
 and returns respective language formated date ex:Janvier 6,2014
 */
export function formatToLangDate(d, monthNames, engMonthNames): any {
  let monthYear = d.split(' ');

  // Find the month whether it is existing in Other language, Else continue with Engmonth
  let month = monthNames.indexOf(monthYear[0]);
  if (month < 0) {
    month = engMonthNames.indexOf(monthYear[0]);
  }
  let day = monthYear[1].split(',')[0];
  let year = monthYear[1].split(',')[1];
  let formatedDate = `${engMonthNames[month]} ${day},${year}`;
  return formatedDate;
}

export function formatDOBLabeltoYyyyMmDd(d, monthNames): any {
  let monthDateYear = d.split(',');
  let monthDateString = monthDateYear[0].toString();
  let monthDate = monthDateString.split(' ');
  let month = (monthNames.indexOf(monthDate[0]) + 1).toString();
  let year = monthDateYear[1];
  let date = monthDate[1];
  let formatedDate = `${year}/${month}/${date}`;
  return formatedDate;
}

//Returns ISO format of Date => yyyy-mm-dd
export function formatToISODate(d, monthNames): any {
  let monthDateYear = d.split(',');
  let monthDateString = monthDateYear[0].toString();
  let monthDate = monthDateString.split(' ');
  let month = (monthNames.indexOf(monthDate[0]) + 1).toString();
  let year = monthDateYear[1];
  let date = monthDate[1];
  month = (month <= 9) ? "0" + month : month;
  date = (date <= 9) ? "0" + parseInt(date) : date;
  let formatedDate = `${year}-${month}-${date}`;
  return formatedDate;
}

export function monthDiffOfDates(date1: string, date2: string): number {
  let months: number;
  let d2: any = new Date(date2);
  let d1: any = new Date(date1);
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= parseInt(d1.getMonth(), 10) + 1;
  months += parseInt(d2.getMonth(), 10) + 1;
  return months <= 1 ? 0 : months - 1;
}

export function monthDiff(date: string) {
  let dateMonthYear = new Date(date.split("-")[0] + "/" + date.split("-")[1] + "/01");
  let months: any;
  let d2: any = new Date();
  let d1: any = new Date(dateMonthYear);

  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= parseInt(d1.getMonth(), 10) + 1;
  months += parseInt(d2.getMonth(), 10) + 1;

  return months <= 0 ? 0 : months;
}

export function dateDiffInDays(a1, b1) {
  let a: any = new Date(a1);
  let b: any = new Date(b1);
  let _MS_PER_DAY = 1000 * 60 * 60 * 24;

  // Discard the time and time-zone information.
  let utc1: any = new Date(a.getFullYear(), a.getMonth(), a.getDate());
  let utc2: any = new Date(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function dateDiffInYears(a1, b1) {
  let a: any = new Date(a1);
  let b: any = new Date(b1);

  // Discard the time and time-zone information.
  let utc1: any = new Date(a.getFullYear());
  let utc2: any = new Date(b.getFullYear());

  return Math.floor(utc2 - utc1);
}

export function getDate(dateString, monthNames: any, langMonthName?: any) {
  let monthYear = dateString.split(" ");
  let month = (monthNames.indexOf(monthYear[0])).toString();
  if (month <= 0 && langMonthName !== undefined) {
    month = (langMonthName.indexOf(monthYear[0])).toString();
  }
  let year = monthYear[1];

  return new Date(year, month);
}

export function getFullDate(dateString, monthNames: any, langMonthName?: any) {
  let dayMonthYear = dateString.split(" ");
  let month = (monthNames.indexOf(dayMonthYear[0])).toString();
  if (month <= 0 && langMonthName !== undefined) {
    month = (langMonthName.indexOf(dayMonthYear[0])).toString();
  }

  let dayYear =  dayMonthYear[1];
  let day: number;
  let year: number;
  if (dayYear.indexOf(",") !== -1) {
    day = dayYear.split(",")[0];
    if (dayMonthYear.length === 3) {
      year = dayMonthYear[2];
    } else {
      year = dayYear.split(",")[1];
    }
  }
  return new Date(year, month, day);
}

export function getDateobj(dateString) {
  return new Date(dateString);
}

export function fromDateOptions(date?): Object {
  if (!date) {
    date = new Date();
  }

  let from: Object = {};

  from['minDate'] = new Date(date.getFullYear() - 5, date.getMonth(), date.getDate());
  from['maxDate'] = new Date(date.getFullYear() - 1, date.getMonth(), date.getDate());
  from['defaultDate'] = new Date(date.getFullYear() - 2, date.getMonth(), date.getDate());

  return from;
}

export function fromatDate(d, monthNames: any): any {
  let monthYear = d.split(' ');
  let month = (monthNames.indexOf(monthYear[0]) + 1).toString();
  let year = monthYear[1];
  let date = '01';
  let formatedDate = `${year}/${month}/${date}`;
  return formatedDate;
}

export function dateFormatYyyyMmDd(date, monthNames: string[]) {
  let monthNo = (monthNames.indexOf(date.split(" ")[0]) + 1).toString();
  let formatedDate = new Date(date.split(" ")[1] + "/" + monthNo + "/01");
  let dateObj: any = new Date(formatedDate);
  let year: any = dateObj.getFullYear();
  let dd: any = parseInt(dateObj.getDate(), 10);
  let month: any = (parseInt(dateObj.getMonth(), 10) + 1);

  month = (month <= 9) ? "0" + month : month;
  dd = (dd <= 9) ? "0" + dd : dd;
  return year + "-" + month + "-" + dd;
}

export function getMinYearCompleteDate(configObj: Object) {
  let minYearCompleted = configObj['min'];
  let minMonthCompleted;
  if (configObj['unit'] === 'YEARS') {
    minMonthCompleted = minYearCompleted * 12;
  } else if (configObj['unit'] === 'MONTHS') {
    minMonthCompleted = minYearCompleted;
  } else {
    minYearCompleted = minYearCompleted * 12;
  }
  // minMonthCompleted = minYearCompleted * 12;
  var dt = new Date();
  dt.setMonth(dt.getMonth() - minMonthCompleted);
  return new Date(dt);
}

export function getDateFormatMonthYear(date, monthNames: string[]): string {
  let dateObj = date.replace(/-/g, '/');
  let dt = new Date(dateObj);
  let month = dt.getMonth();
  let year = dt.getFullYear();
  let monthYear = `${monthNames[month]} ${year}`;
  return monthYear;
}

/*export function getChangeFormatToYMD(dateString,monthNames: string[]) {
  let dt;
  let monthYear = dateString.split(" ");
  if (monthYear[1] == undefined) {
    dt = dateString;
    // dt = dt.replace(/-/g, '/')
  } else {
    let month = (this.monthNames.indexOf(monthYear[0])).toString();
    month = parseInt(month) + 1;
    month = (month <= 9) ? "0" + month : month;
    let year = monthYear[1];
    let dd = "01";
    dt = year + "-" + month + '-' + dd;
  }
  return dt;
}*/

/**
 * Format the date which is in January 01,1990 format
 * Returns in English formay
 * 
 * @param date 
 * @param format - return in which date format(ex, MMMM YYYY) 
 * @param langMonthNames 
 */
export function formatDateMMMMDDYYYY(date, format, langMonthNames) {
  let engMonthNames: any = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  let monthYearDay = date.split(' ');
  let month = monthYearDay[0];
  let yearDay = monthYearDay[1].split(',');
  let day = yearDay[0];
  let year = yearDay[1];

  let monthIndex = (engMonthNames.indexOf(month));
  if (monthIndex <= 0) {
    monthIndex = (langMonthNames.indexOf(month));
  }

  if (format === 'MMMM DD,YYYY') {
    return engMonthNames[monthIndex] + ' ' + day + ',' + year;
  }
}

export function getLabelDate(date, monthNameList?): string {
  let monthNames: any = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  //let dateObj = date.replace(/-/g, '/');
  if (monthNameList) {
    monthNames = monthNameList;
  }
  let dt = new Date(date);
  let month = dt.getMonth();
  let year = dt.getFullYear();
  let dd = get2D(dt.getDate());
  let monthYear = `${monthNames[month]} ${dd}, ${year}`;
  return monthYear;
}
export function formatMonthDateYear(date, monthList?) {
  var monthNames = [
    "January", "February", "March",
    "April", "May", "June", "July",
    "August", "September", "October",
    "November", "December"
  ];

  if (monthList) {
    monthNames = monthList;
  }

  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();
  return monthNames[monthIndex] + ' ' + day + ',' + year;
}

export function get2D(num) {
  if (num.toString().length < 2) {
    return "0" + num; // Prepend a zero!
  } else {
    return num.toString(); // return string for consistency
  }
}

export function dateDiffInYearsForDobAliasDates(a1, b1) {

  if (typeof a1 === "string" && typeof b1 === "string" && a1 !== undefined && b1 !== undefined) {
    let dob: Array<string> = [];
    let dobYear: number = 0;
    let regex = RegExp(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/);
    if (regex.test(a1)) {
      dob = a1.split("-");
      dobYear = parseInt(dob[0]);
    } else {
      dob = a1.split(",");
      dobYear = parseInt(dob[1]);
    }

    let stDate: Array<string> = b1.split("-");
    let stdateYear: number = parseInt(stDate[0]);

    return (stdateYear - dobYear);
  }
}

/**
 * 
 * @param date - selected date
 * @param langMonthName - multilingual month
 * @param engMonthName - English month
 */
export function convertDateToRespLanguage(date, langMonthName: any, engMonthName: any) {
  let monthYear = date.split(' ');
  let month = monthYear[0];
  let year = monthYear[1];
  // If - check month is available in International month list(langMonthName)
  // Else - get the index from english month list(engMonthName) and return respective language month
  let monthIndex = (langMonthName.indexOf(month));
  if (monthIndex > -1) {
    return monthYear[0] + ' ' + monthYear[1];
  } else {
    monthIndex = (engMonthName.indexOf(month));
    return langMonthName[monthIndex] + ' ' + year;
  }
}

export function convertFullDateToRespLanguage(date, langMonthName: any, engMonthName: any) {
  let monthDayYear = date.split(' ');
  let month = monthDayYear[0];
  let day: string;
  let year: string;
  if (monthDayYear.length === 3) {
    day = monthDayYear[1];
    year = monthDayYear[2];
  } else {
    let dayYear = monthDayYear[1];
    day = dayYear.split(",")[0] + ',';
    year = dayYear.split(",")[1];
  }
  
  // If - check month is available in International month list(langMonthName)
  // Else - get the index from english month list(engMonthName) and return respective language month
  let monthIndex = (langMonthName.indexOf(month));
  if (monthIndex > -1) {
    return monthDayYear[0] + ' ' + day + ' ' + year;
  } else {
    monthIndex = (engMonthName.indexOf(month));
    return langMonthName[monthIndex] + ' ' + day + ' '+year;
  }
}

/**
 *
 * @param dateString - date
 * @param format - Format needs to check
 */
export function checkIsOuputFormat(dateString, format) {

  // Get regex format
  let regexDate = UtilConfig.getRegex(format);

  // Check for the pattern
  return regexDate.test(dateString) ? true : false;
}

/**
 * Below function used to get date format as we need.
 * Utilize this function for global date transformation.
 * Enlarge this function as our need instead of writting new function to convert date format
 */
export function globalDateTransform(dateVal, expectedFormat, monthList?) {

  // Check date is valid or not
  if (isNaN(Date.parse(dateVal))) {
    return dateVal;
  }

  // Convert to date instance
  let dateInstance = new Date(dateVal);

  let day = dateInstance.getDate();
  let monthIndex = dateInstance.getMonth();
  let year = dateInstance.getFullYear();
  let responseDate;

  // Add 0 before date if it is less then or equal to 9
  let dd: any = dateInstance.getDate();
  dd = (dd <= 9) ? "0" + dd : dd;

  // Expected format operation
  switch (expectedFormat) {
    case 'Instance':
      responseDate = dateInstance;
      break;
    case 'MMMM DD,YYYY':
      responseDate = monthList[monthIndex] + ' ' + day + ',' + year;
      break;
    case 'DD MMMM YYYY':
      responseDate = day + ' ' + monthList[monthIndex] + ' ' + year;
      break;
    case 'YYYY-MM-DD':
      responseDate = year + '-' + (monthIndex + 1) + '-' + dd;
      break;
    case 'MM/DD/YYYY':
      let mm: any = (dateInstance.getMonth() + 1);
      mm = (mm <= 9) ? "0" + mm : mm;
      responseDate = mm + '/' + dd + '/' + year;
      break;
    case 'YYYY-MM-DD-TT0':
      responseDate = year + '-' + (monthIndex + 1) + '-' + dd + 'T00:00:00';
      break;
    case 'MMMM YYYY':
      responseDate = monthList[monthIndex] + ' ' + year;
      break;
    case 'DDDD MMMM,DD':
      let weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      let dayName = weekDays[dateInstance.getDay()];
      responseDate = dayName + ', ' + monthList[monthIndex] + ' ' + dd;
      break;
    case 'YYYY-MMM-DD':
        let mmm: any = (dateInstance.getMonth() + 1);
        mmm = (mmm <= 9) ? "0" + mmm : mmm;
        responseDate = year + '-' + mmm+ '-' + dd;
        break;
    case 'DD-MMM-YYYY':
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        responseDate = dd + '-' + months[monthIndex] + '-' + year;
        break;
    default:
  }

  return responseDate;
}

/**
* 
* @param date - selected date
* @param langMonthName - multilingual month
* @param engMonthName - English month
*/
export function getDateFormatWithMonthYearIndex(date, langMonthName: any, engMonthName: any) {
  let monthYear = date.split(' ');
  let month = monthYear[0];
  let year = monthYear[1];
  let value: any;
  // If - check month is available in International month list(langMonthName)
  // Else - get the index from english month list(engMonthName) and return respective language month
  let monthIndex = (langMonthName.indexOf(month));
  if (monthIndex > -1) {
    value = monthYear[0] + ' ' + monthYear[1];
  } else {
    monthIndex = (engMonthName.indexOf(month));
    value = langMonthName[monthIndex] + ' ' + year;
  }
  return { dateFormat: value, monthIndex: monthIndex, year: year }
}

export function compareDates(date1, date2, key) {
  let val = dateDiffInDays(date1, date2);
  let response: boolean = false

  switch (key) {
    case 'Equal':
      if (val === 0)
        response = true;
      break;
    case 'WithInRange':
      if (val >= 0)
        response = true;
      break;
  }

  return response;
}

/**
 * Converts dateVal 'YYYY-MM-DD' to new Date(YYYY, MM, DD)
 * 
 * @param dateVal
 */
export function getDateWithoutTimezone(dateVal) {
  let dateInstance = new Date(dateVal);
  let index = dateVal.indexOf("-");
  if (index !== -1) {
    let yearMonthDay = dateVal.split("-");
    let year = yearMonthDay[0];
    let month = yearMonthDay[1];
    let day = 1;
    if (yearMonthDay.length == 3) {
      day = yearMonthDay[2];
    }
    dateInstance = new Date(year, month - 1, day);
  }
  return dateInstance;
}

/**
 * 
 * 
 * @param dateVal ex: Timezone date val - 2019-11-20T00:00:00
 * @param time12h ex:01:30 PM
 * 
 * Output - 2019-11-14T13:30:00
 */
export function getDateWithTimezone(dateVal, time12h, engMonthNames) {
  let dateYYYYMMDD = globalDateTransform(dateVal, 'YYYY-MM-DD', engMonthNames);

  const [time, modifier] = time12h.split(' ');

  let [hours, minutes] = time.split(':');

  let seconds: any = '00';

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  let DateWithTimeZone: any = `${dateYYYYMMDD}T${hours}:${minutes}:${seconds}`;
  return DateWithTimeZone;
}

export function getDateWith7Days() {
  var date = new Date();
  date.setDate(date.getDate() + 7);

  return date;
}

export function getMIDateFormat(value) {
  if (value != null) {
    
    let monthList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let date = new Date(value);
    if (value.indexOf('-') > -1) {
      date = new Date(value.replace(/-/g, '\/'));
    }
    let year: any = date.getFullYear();
    let month : any = date.getMonth();
    let day: any = date.getDate();

    if (day < 10) {
      day = '0' + day;
    }
    value = day + "/" + monthList[month] + "/" + year;
  }
  return value;
}

export function getCurrentTime() {
  let date = new Date();
  let hours: any = date.getHours();
  let minutes: any = date.getMinutes();
  let hourformat = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  let currentTime = hours + ':' + minutes + ' ' + hourformat;
  return currentTime;
}

export function convertDOBtoCustomFormat(dob: string, format: string, monthArray) {
  
  let splitter;
  let resultDate = '';
  let dayValue;
  let monthValue;
  let YearValue;
  if (!!format) {
    if (format.indexOf(' ') > -1) {
      splitter = ' ';
    } else if (format.indexOf('/') > -1) {
      splitter = '/';
    } else if (format.indexOf('-') > -1) {
      splitter = '-';
    } else if (format.indexOf('.') > -1) {
      splitter = '.';
    }

    if (!!splitter) {
      let dobSplitter;
      if (dob.indexOf(' ') > -1) {
        dobSplitter = ' ';
      } else if (dob.indexOf('/') > -1) {
        dobSplitter = '/';
      } else if (dob.indexOf('-') > -1) {
        dobSplitter = '-';
      } else if (dob.indexOf('.') > -1) {
        dobSplitter = '.';
      }

      let dobArray = [];
      const dobValue = dob.split(dobSplitter);
      dobArray.push(dobValue[0]);
      if (dobValue[1].indexOf(',') > 0) {
        let value = dobValue[1].split(',');
        const month = dobArray[0];
        dobArray[0] = value[0];
        dobArray.push(month);
        dobArray.push(value[1]);
      } else {
        dobArray.push(dobValue[1]);
        dobArray.push(dobValue[2]);
      }
      

      let dateFormat = format.split(splitter);
      let dateFormatArray = [];
      if (dateFormat.length === 2 && format.indexOf(',') >= 0) {
        dateFormatArray.push(dateFormat[0]);
        dateFormat = dateFormat[1].split(',');
        dateFormatArray.push(dateFormat[0]+',');
        dateFormatArray.push(dateFormat[1]);
      } else {
        dateFormatArray = dateFormat;
      }
      
      let count = 0;
      for (let dateFormat of dateFormatArray) {
        if (dateFormat.trim().charAt(0) === 'd' || dateFormat.trim().charAt(0) === 'D') {
          let day = dobArray[0];
          if (day < 9 && day.length === 1) {
            day = '0'+ day;
          }
          resultDate = resultDate + day + splitter;
        } else if (dateFormat.trim().charAt(0) === 'm' || dateFormat.trim().charAt(0) === 'M') {
          let month = dobArray[1];
          if (dateFormat.trim().length <= 2 ) {
            month = monthArray.indexOf(month) + 1;
            if (month <= 9  && month.length === 1) {
              month = '0' + month;
            }
          }
          resultDate = resultDate + month + splitter;
        } else if (dateFormat.trim().charAt(0) === 'y' || dateFormat.trim().charAt(0) === 'Y') {
          let year = String(dobArray[2]);
          if (dateFormat.trim().length === 2) {
            const endIndex = year.length-1;
            const startIndex = year.length - 2;
            year = year.substr(startIndex, endIndex);
          }
          resultDate = resultDate + year + splitter;
        }

        if (count === (dateFormatArray.length - 1)) {
          resultDate = resultDate.substr(0, resultDate.length - 1);
        }

        if (dateFormat.indexOf(',') > -1) {
          resultDate = resultDate.substr(0, resultDate.length - 1) + ',';
          if (splitter === ' ') {
            resultDate = resultDate + ' ';
          }
        }

        count++;
      }
    }
    return resultDate;
  } else {
    return dob;
  }
  
}

//Return date in yyyy-MM-dd format.
export function getDateFormatYYYYMMDD(ts) {
  let dateObj = new Date(ts);
  let year: any = dateObj.getFullYear();
  let dd: any = dateObj.getDate();
  let month: any = (dateObj.getMonth() + 1);
  month = (month <= 9) ? "0" + month : month;
  dd = (dd <= 9) ? "0" + dd : dd;
  let toDateFormat = year + "-" + month + "-" + dd;
  return toDateFormat;
}

// Return Date object from yyyy-MM-dd format. 
export function getDateFromYYYYMMDD(dateStr) {
  let dateArray = dateStr.split('-');
  let year = parseInt(dateArray[0]);
  let month = parseInt(dateArray[1]) - 1; // Jan(0) - Dec(11)
  let day = parseInt(dateArray[2]);
  return new Date(year, month, day);
}

//Gives TimeZoneOffset.
export function getTimeZoneOffset() {
  let d = new Date();
  return (-1 * d.getTimezoneOffset());
}

export function formatFutureGraduationDate(d, monthNames): any {
  if (d.indexOf(',') >= 0) {
    return formatToISODate(d, monthNames);
  } else {
    let monthDateYear = d.split(' ');
    let month = (monthNames.indexOf(monthDateYear[1]) + 1).toString();
    let year = monthDateYear[2];
    let date = monthDateYear[0];
    let formatedDate = `${year}-${month}-${date}`;
    return formatedDate;
  }
  
}

export function formatDateLabeltoYyyyMmDate(d, monthNames): any {
  if (d.indexOf(',') >= 0) {
    let monthYear = d.split(' ');
    let month = (monthNames.indexOf(monthYear[0]) + 1).toString();
    let year = d.split(" ")[1].split(",")[1];
    let day = d.split(" ")[1].split(",")[0];
    let date = (day <= 9) ? "0" + parseInt(day) : day;
    let formatedDate = `${year}/${month}/${date}`;
    return formatedDate;
  } else if (d.indexOf('-') >= 0) {
    return d.replace(/-/g, '/');
  }
}

/**
* 
* @param date - selected date
* @param langMonthName - multilingual month
* @param engMonthName - English month
*/
export function fullDateGetDateFormatWithMonthYearIndex(date, langMonthName: any, engMonthName: any) {
  let _year = date.split(" ")[1].split(",")[1];
  let _month = date.split(' ')[0];
  let dd = date.split(" ")[1].split(",")[0];
  let _day = (parseInt(dd) <= 9) ? "0" + parseInt(dd) : dd;
  let monthYear = _month + ' ' + _year;
  let month = _month;
  let year = _year;
  let value: any;
  // If - check month is available in International month list(langMonthName)
  // Else - get the index from english month list(engMonthName) and return respective language month
  let monthIndex = (langMonthName.indexOf(month));
  if (monthIndex > -1) {
    value = _month + ' ' + _day +"," + _year;
  } else {
    monthIndex = (engMonthName.indexOf(month));
    value = langMonthName[monthIndex] + ' ' + _day +"," + year;
  }
  return { dateFormat: value, monthIndex: monthIndex, year: year, day: _day }
}

/**
 * function to validate YYYY-MM-DD format
 * 
 * @param date 
 */
export function isISOFormat(dateStr: string) {
  if (!dateStr) {
    return false;
  }
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (dateStr.match(regex) === null) {
    return false;
  }

  const date = new Date(dateStr);

  const timestamp = date.getTime();

  if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
    return false;
  }

  return true;
}

/**
 * format the given date string to YYYY-MM-DD format
 * this skips time zone
 * 
 * @param dateString 
 * @param monthNames 
 * @returns 
 */
export function formatDateYYYYMMDD(dateString: string, monthNames: any) {

  const engMonthNames: any = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  if (!dateString) {
    return null;
  }

  try {
    let year;
    let month;
    let day;
    if (dateString.indexOf(',') > -1) {
      const dateArray = dateString.split(',');
      year = dateArray[1];
      const dateArray1 = dateArray[0].split(' ');
      month = monthNames.indexOf(dateArray1[0]);
      if (month < 0) {// fallback to english month name if not found in specific language
        month = engMonthNames.indexOf(dateArray1[0]);
      }
      month = month + 1;
      day = dateArray1[1];
    } else {
      const date = getDateWithoutTimezone(dateString);
      year = date.getFullYear();
      month = date.getMonth() + 1;
      day = date.getDate();
    }

    const dateStr = year + '-' + month +  '-' + day;
    const date = getDateWithoutTimezone(dateStr);
    const result = date.getFullYear() + '-' + get2D(month) + '-' + get2D(day);

    return result;

  } catch(error) {
    console.error('failed to parse date => ' + dateString, error);
  }

}

export function getFormattedDate(date, month_names) {
  var formattedDate = date;
  var mon = 0;
  var tempDate = date.split('-');
  if (tempDate) {
    var year = tempDate[0];
    var month = tempDate[1];
    var day = tempDate[2];
    if (month != undefined) {
      mon = parseInt(month);
    }
    var displayDay = '';
    if (day != undefined && day.charAt(0) != 0 && parseInt(day) < 10) {
      displayDay = '0' + day;
    } else {
      displayDay = '' + day;
    }
    
    formattedDate = displayDay + "-" + month_names[mon - 1] + "-" + year;
  }
  return formattedDate;
}

export function getLastDayOfMonth(date: Date) {
  let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  return lastDay;
}

export function substractYearsAndMonths(date: Date, ny: number, nm: number) {
  let destDate = date;
  if (!!date) {
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    let destMonth = month - nm;
    if (destMonth < 0) {
      destMonth = 12 + destMonth;
    }
    destDate = new Date(year - ny, month - nm, day);
    let dm = destDate.getMonth();
    if (destMonth < dm) {
      //setting temporary date to set month correctly
      destDate.setDate(15);
      destDate.setMonth(destMonth);
      //Setting to the last day of the month
      destDate.setDate(getLastDayOfMonth(destDate));
    }
  }
  return destDate;
}