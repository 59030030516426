import {Component, OnInit} from '@angular/core';
import {LocalStorageService} from 'framework/localstorage.service';
import {Router} from '@angular/router';
import {AuthService} from '../login';
import { EventBusService } from 'framework/eventbus.service';
import { TranslateService } from 'framework/i18n';

declare var $: any;
declare var window: any;

@Component({
  selector: 'mfa-selection',
  templateUrl: './mfa-selection.component.html',
  providers: [AuthService]
})

export class MfaSelectionComponent implements OnInit {
  public errorMessage: string;
  public factors: FactorObj[] = [];
  public selectedFactor: FactorObj = null;
  trackObj: Object;
  isRedirectForgotPassword: boolean = false;

  constructor(private localStorage: LocalStorageService,
              private _service: AuthService,
              private _router: Router,
              private _eventBus: EventBusService,
              private _translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.errorMessage = null;
    this.isRedirectForgotPassword = window.location.href.indexOf('fromForgotPassword') > 0;

    const lgnData = this.localStorage.getItem('oidc_lgn_res');

    if(!lgnData || !lgnData['factors'] || !lgnData['factors']['0'] || !lgnData['user'] || !lgnData['user']['id']) {
      console.error('No factors data found...');
      this._next('/login');
    }
    else {
      const userObj = lgnData['user'];
      for(const fc of lgnData['factors']) {
        this.factors.push(this.generateFactor(fc,userObj));
      }
    }

    this._eventBus.languageListInInviteReceivedAnnounced({
      availableLangList: []
    });
  }

  public selectFactor(factor: FactorObj) {
    console.log('Selecting factor::'+factor.factorType);
    this.selectedFactor = factor;
  }

  public goBack() {
    this._next('/login');
  }

  public submitSelection() {
    console.log("Submitting selection::",this.selectedFactor);
    let that = this;
    $('#waitMfaModal').modal('show');

    if (!this.isRedirectForgotPassword) {
      this._service.enrollMfa(this.selectedFactor).subscribe(response => {
        $('#waitMfaModal').modal('hide');
        if(!response) {
          throw new Error('Some error occurred');
        }

        this.trackObj = {
          stage_name: 'mfa-select',
          action_value: 'submit'
        };
        window.track_event('mfa selected ' + this.selectedFactor.factorType, this.trackObj, true, true);
        this._next("/activate-mfa");
      },
      error => {
        $('#waitMfaModal').modal('hide');
        this.trackObj = {
          stage_name: 'mfa-select',
          action_value: 'clicked'
        };
        // debugger
        window.track_event('mfa enroll failed ' + this.selectedFactor.factorType, this.trackObj, true, true);
        this.errorMessage = this._translate.instant(error);
      });
    } else {
      let applicantEmail = this.localStorage.getItem('applicant_email');

      this._service.oidcForgotPassword(applicantEmail, this.selectedFactor.factorType).subscribe(response => {
        $('#waitMfaModal').modal('hide');
        if(!response) {
          throw new Error('Forgot password no response');
        }

        this.localStorage.setItem('oidc_enroll', response);
        this._router.navigate(['/activate-mfa'], {queryParams: {fromForgotPassword: true}});
      }, error => {
        $('#waitMfaModal').modal('hide');
        this.errorMessage = this._translate.instant(error);
      });
    }
  }

  private generateFactor(data, userObj): FactorObj {
    const factor:FactorObj = {
      enrollment: null,
      factorType: null,
      provider: null,
      status: null,
      val: null,
      vendorName: null
    };

    if(!!data) {
      factor.factorType = data['factorType'] || null;
      factor.provider = data['provider'] || null;
      factor.enrollment = data['enrollment'] || null;
      factor.vendorName = data['vendorName'] || null;
      factor.status = data['status'] || null;
      if(!!factor.factorType && !!userObj) {
        const mobile = !!userObj['mobilePhone'] ? userObj['mobilePhone'] : userObj['mobile_phone'];

        switch(factor.factorType.toUpperCase()) {

          case 'CALL':
            factor.val = "Call " + (mobile || '');
            break;
          case 'SMS':
            factor.val = "Text " + (mobile || '');
            break;
          case 'EMAIL':
            factor.val = "Email " + (userObj['email'] || '');
            break;
          default:
            factor.val = null;
        }
      }
    }
    return factor;
  }

  private _next(url): void {
    this._router.navigate([url]);
  }
}
interface FactorObj {
  factorType: string,
  provider: string,
  status: string,
  enrollment: string,
  vendorName: string,
  val: string
}

