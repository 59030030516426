<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12">
    <p class="black_text">{{ options['title'] }}</p>
    <hr>
  </div>
</div>
<div class="addCard m-b-10">
  <div class="row">
    <div class="col-xs-12 col-sm-4 col-md-3 dp-r-0" *ngFor="let data of options.data">
      <div class="clipCard" (click)="openViewProfilePopup(data,options['key'])">
        <img src="/assets/img/driver-license.png" height="30px">
        <p class="boldcom" *ngIf="!!data['state_name']">{{data['state_name']}}</p>
        <p class="text_ellipses">
          <span *ngIf="data['number'] && data['number'].length > 0">{{data['number']}}, </span>
        </p>
        <p class="text_ellipses">
          <span *ngIf="data['name_on_license'] && data['name_on_license'].length > 0">{{data['name_on_license']}}</span>
        </p>
      </div>
    </div>
  </div>
</div>
