<div class="body_container container m-t-20 mar10 mobile_container">
  <div class=" anime-clean signup">

    <div class="row neg_mar">
      <!-- Image Section Starts here-->
      <div class="col-md-6 col-sm-6 hidden-xs diff_bg">
        <!--<img src="../../assets/img/illustration01.png">-->
        <h3 class="text-center m-t-10 pad5">{{'DIFFERENT_EMAIL_HEADER'|translate }}</h3>
      </div>
      <!-- Image Section Ends here-->

      <!-- Sign up Form Starts here-->
      <div class="col-xs-12 col-sm-6 col-md-6 signup_form pad45 pad0">
        <div class="row mobile-header visible-xs">
          <div class="col-xs-12 ">
            <div class="header_txt_white">
              <h2>{{'DIFFERENT_EMAIL_HEADER'|translate }}</h2>
              <!--<h4 class="m-t-10">Already have a Profile ? - <a (click)="redirectSignin()">Sign in</a></h4>-->
            </div>
          </div>
        </div>


        <div class="row m-t-40 pad15">
          <div class="col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 col-xs-12">
            <div class="header_txt_white">
              <h2>{{'DIFFERENT_EMAIL_SUBHEADER'|translate }}</h2>
            </div>
          </div>
        </div>

        <form class="m-t-40 pad15" #diffEmailForm="ngForm"
          (ngSubmit)="emailLogin(diffEmailForm.value, diffEmailForm.valid)">
          <div class="row">
            <div class="col-xs-12 col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 dp-r-0 m-b-20 mb-b-10">
              <div class="group">
                <label class="control-labelss" for="inputDiffEmail">{{'DIFFERENT_EMAIL_LABLE'|translate }}</label>
                <input type="email" aria-required="true" ngModel id="inputDiffEmail" class="form-control" name="email" #email="ngModel"
                  pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$" required placeholderFloat />
              </div>
              <div class="error" *ngIf="email.touched && !email.valid">
                <i class="fa fa-times-circle"></i>
                Email is required and format should be john@doe.com.
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 pad0 m-b-20 ">
            <button type="submit" class="btn button font_extrabold" name="action" [disabled]="!diffEmailForm.form.valid"
              [class.grow]="diffEmailForm.form.valid">{{ 'COMMONS_NEXT' | translate }}</button>
          </div>
        </form>
      </div>
      <!--Sign up From Ends here-->
    </div>
  </div>


  <!--Tab Navigation Slider End-->
  <div class="clearfix"></div>
</div>
