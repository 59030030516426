import { Component, Input, AfterViewInit, ViewChild, ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ComponentHostDirective } from './component-host.directive';
import { ComponentItem } from './component-item';
import { AdComponent } from './ad.component';

@Component({
  selector: 'dynamic-component',
  templateUrl: './dynamic-component-loader.template.html'
})

export class DynamicComponentLoaderComponent implements AfterViewInit, OnDestroy {
  @Input() components: ComponentItem[];
  currentComponentIndex: number = -1;
  @ViewChild(ComponentHostDirective, { static: true }) componentHost: ComponentHostDirective;

  // Hold the reference of all dynamically loaded component reference
  componentRefList: any = [];

  private _data = new BehaviorSubject<any>([]);

  @Input()
  set currentSlide(value) {
    this._data.next(value);
  };

  get currentSlide() {
    return this._data.getValue();
  }

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit(): void {
    console.log('----------- ngOnInit DYNAMIC COMPONENT LOADER -----------------');
    this._data
      .subscribe(x => {
        this.updateChildComponentProp(x);
      });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loadComponent();
    });
  }

  ngOnDestroy(): void {

  }

  loadComponent(): void {
    let numberOfComponentsToLoad = this.components !== undefined ? this.components.length : 0;

    for (let index = 0; index < numberOfComponentsToLoad; index++) {
      let componentItem = this.components[index];

      let componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentItem.component);

      let viewContainerRef = this.componentHost.viewContainerRef;

      let componentRef = viewContainerRef.createComponent(componentFactory);

      // Add it to the list of component ref
      this.componentRefList.push(componentRef);

      (<AdComponent>componentRef.instance).data = componentItem.data;

      // Below are need to be dynamic; should be passed in 'data'
      (<AdComponent>componentRef.instance).currentSlide = this.currentSlide;
      // (<AdComponent>componentRef.instance).currentSlide = this.currentSlide;
      (<AdComponent>componentRef.instance).currentSlideWithDirection = this.currentSlide + '_right';
    }
  }

  updateChildComponentProp(slide: string): void {
    console.log('@@@@@@@ Slide value passed in DCL @@@@@' + slide);

    // slide and direction should be passed here
    for (let i = 0; i < this.componentRefList.length; i++) {
      (<AdComponent>this.componentRefList[i].instance).currentSlide = slide;
      // (<AdComponent>this.componentRefList[i].instance).currentSlideIndex = slide.split('_')[1];
      (<AdComponent>this.componentRefList[i].instance).currentSlideWithDirection = slide + '_right';
    }
  }
}
