import { Component, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/fieldconfig.interface';

@Component({
  selector: 'form-button',
  styleUrls: ['form-button.component.scss'],
  templateUrl: './form-button.component.html'
})
export class FormButtonComponent implements Field {
  @Input() buttonObj: Object;
  config: FieldConfig;
  group: FormGroup;
}
