<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12">
    <p class="black_text">{{'VP_NAME_HEADER' | translate }}</p>
    <hr>
  </div>
</div>
<div class="addCard m-b-10">
  <div class="row">
    <div class="col-xs-12 col-sm-4 col-md-3 dp-r-0">
      <div class="clipCard" (click)="openViewProfilePopup(options1.data,options1.key)">
        <img src="../../assets/img/you.png" height="30px">
        <p class="boldcom">{{'VP_NAME_HEADER' | translate }}</p>
        <p class="text_ellipses"> <span>{{ options1.data.title }} {{ options1.data.first_name }}
            {{ options1.data.middle_name }} {{ options1.data.last_name }} {{ options1.data.suffix }}</span></p>
      </div>
    </div>
  </div>
</div>
