<div class="addCard  m-t-20 hidden-xs">
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 m-b-20">

      <div class="emptyCard_profile">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-9">
            <div class="content_img profileheaderimg">
              {{userFirstName | slice:0:1 }}
            </div>
            <div class="content_img_text m-l-10">
              <h3 class="blue_text">{{'PROFILE_HEADER_1' | translate:[userFirstName] }}</h3>
              <p class="sub_text">{{'PROFILE_SUBHEADER' | translate}}</p>
              <!--<p class="text_height">+1 6464 200 1234; ted@gmail.com</p>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="addCard  m-t-20 visible-xs">
  <div class="row">
    <div class="col-xs-12 col-sm-4 col-md-4 m-b-20">
      <div class="innerCard_profile_recurit">
        <div class="row">
          <div class="col-xs-4 col-sm-6 col-md-5 ">
            <div class="content_img profileheaderimg">
              {{userFirstName | slice:0:1 }}
            </div>
          </div>
          <div class="col-xs-8 col-sm-6 col-md-7 negmar100">
            <h3 class="blue_text">{{'PROFILE_HEADER_1' | translate:[userFirstName] }}</h3>
            <p class="sub_text">{{'PROFILE_SUBHEADER' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
