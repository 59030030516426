import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProfileConfig } from './../../profile.config';

@Component({
  selector: 'view-profile-name',
  templateUrl: './view-profile-name.component.html',
  providers: []
})

export class ViewProfileNameComponent {
  @Input() viewProfileData: Object;
  profileKey: any;

  constructor() {
  }

  ngOnInit() {
    this.profileKey = ProfileConfig.getViewNameKey();
  }
}