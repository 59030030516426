import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'profile-requests',
  templateUrl: './profile-requests.component.html',
  providers: []
})

export class ProfileRequestsComponent {
  @Input() options: any;
  @Output() onViewProfile: EventEmitter<any> = new EventEmitter();
  remainingTime: number = 0;

  constructor() {
  }

  ngAfterViewInit() {
    let time: number = 1487150283836;
    this.remainingTime = Math.round((new Date().getTime() - time) / (1000 * 60 * 60 * 24));
  }

  openViewProfilePopup(data, key): void {
    data['key'] = key;
    this.onViewProfile.emit(data);
  }
}