<div *ngIf="!isDynamicDocumentUpload" class="anime-clean posrel" (onGesture)="doSwipe($event)" hammer-gestures>

  <button type="button" class="btn arrowrightfl" [disabled]="!inputEnableNextButton"
    *ngIf="!inputIsDocEmptyList && isOptionalDocsPresent" (click)="onNextClick()">
    <i class="icon-arrow-right pull-right" role="img" [attr.aria-label]="'SCREEN_FARWARD_ARROW' | translate"></i>
  </button>
  <button type="button" class="btn arrowrightfl" [disabled]="!inputIsDocEmptyList" *ngIf="inputIsDocEmptyList"
    (click)="onNextClick()">
    <i class="icon-arrow-right pull-right" role="img" [attr.aria-label]="'SCREEN_FARWARD_ARROW' | translate"></i>
  </button>
  <div class="contetnheight mandatoryDocuments pad_30 posrel">
    <div class="row">
      <div class="col-md-12 col-xs-12 col-sm-12">
        <div class="header-txt header_txt_white" *ngIf="inputIsDocEmptyList === false">
          <h2 *ngIf="!isInviteAssessment" class="m-b-20">{{ 'DOCUMENT_UPLOAD_PRIMARY_TEXT' | translate:[firstName]}}</h2>
          <h2 *ngIf="isInviteAssessment" class="m-b-20">{{ 'DOCUMENT_UPLOAD_ASSESSMENT_PRIMARY_TEXT' | translate:[firstName]}}</h2>
          <!-- <h4 class="m-t-10 m-b-20">{{ 'DOCUMENT_UPLOAD_SECONDARY_TEXT' |  translate }}</h4> -->
        </div>
        <div class="header-txt header_txt_white" *ngIf="inputIsDocEmptyList === true">
          <h2 class="m-b-20">{{ 'DOCUMENT_UPLOAD_PRIMARY_TEXT_EMPTY' | translate:[firstName] }}</h2>
          <h4 class="m-t-10 m-b-20">{{ 'DOCUMENT_UPLOAD_SECONDARY_TEXT_EMPTY' |  translate }}</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2 col-xs-12 col-sm-6" *ngIf="(checkIsIntlGID100P || checkIsIntlPRNCrim || checkIsAFP100P)">
        <h5>
          <span class="fontWeight-700">*&nbsp;</span>
          <a data-toggle="modal" data-target="#docUpload_info_modal"
            class="a_custom">{{ 'DOCUPLOAD_MIN_REQUIREMENTS' | translate }}</a>
          <i data-toggle="modal" data-target="#docUpload_info_modal" class="icon icon-info info-new"
            title="Instruction"></i>
        </h5>
      </div>
      <div class="col-md-3 col-xs-12 col-sm-6" *ngIf="checkIsIntlGID100P">
        <h5>
          <!-- Download INTL 100P instructions Guide -->
          <span class="fontWeight-700">*&nbsp;</span><a class="a_custom"
            (click)="downloadInstructionGuide('100P')">{{ 'DOCUPLOAD_INSTRUCTIONS_GUIDE_INTLGID' | translate }}</a>
        </h5>
      </div>
      <div class="col-md-3 col-xs-12 col-sm-6" *ngIf="checkIsIntlPRNCrim">
        <h5>
          <!-- Download CRIM 100P instructions Guide -->
          <span class="fontWeight-700">*&nbsp;</span><a class="a_custom"
            (click)="downloadInstructionGuide('CRIM')">{{ 'DOCUPLOAD_INSTRUCTIONS_GUIDE_INTLPRNCRIM' | translate }}</a>
        </h5>
      </div>
      <div class="col-md-4 col-xs-12 col-sm-6" *ngIf="checkIsAFP100P && inputAFP100PCalculationAvailable">
        <h5>
          <!-- Download AFP 100P instructions Guide -->
          <span class="fontWeight-700">*&nbsp;</span><a class="a_custom"
            (click)="downloadInstructionGuide('AFP')">{{ 'DOCUPLOAD_INSTRUCTIONS_GUIDE_INTLPRN_AFP' | translate }}</a>
        </h5>
      </div>
    </div>
    <div class="row" *ngIf="(checkIsIntlGID100P || checkIsIntlPRNCrim || checkIsAFP100P)">
      <div class="col-md-6 col-sm-12 m-b-10" *ngIf="checkIsIntlGID100P && inputIs100PFeatureAvailable">
        <div class="points-bar">
          <div class="main-group">
            <span class="points-label">{{ 'DOCUPLOAD_MODAL_HEADING_1' | translate }}: </span>
            <!-- <span class="points-value" [ngClass]="inputIs100pTotalPointsReq === 0 ? 'points-empty': 'points-filled'">{{ inputIs100pTotalPointsReq }} pts</span> -->
            <span class="progress">
              <div class="progress-bar bg-success" role="progressbar" [style.width.%]="inputIs100pTotalPointsReq"
                aria-valuenow="inputIs100pTotalPointsReq" aria-valuemin="0" aria-valuemax="100">
                {{ inputIs100pTotalPointsReq }} pts
              </div>
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 m-b-10" *ngIf="checkIsIntlPRNCrim && inputAUCrim100PCalculationAvailable">
        <div class="points-bar">
          <div class="main-group">
            <span class="points-label">{{ 'DOCUPLOAD_MODAL_HEADING_2' | translate }}: </span>
            <!-- <span class="points-value" [ngClass]="inputIsCrimTotalPointsReq === 0 ? 'points-empty': 'points-filled'">{{ inputIsCrimTotalPointsReq }} pts</span> -->
            <span class="progress">
              <div class="progress-bar bg-success" role="progressbar" [style.width.%]="inputIsCrimTotalPointsReq"
                aria-valuenow="inputIsCrimTotalPointsReq" aria-valuemin="0" aria-valuemax="100">
                {{ inputIsCrimTotalPointsReq }} pts
              </div>
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12 m-b-10" *ngIf="checkIsAFP100P && inputAFP100PCalculationAvailable">
        <div class="points-bar">
          <div class="main-group">
            <span class="points-label">{{ 'DOCUPLOAD_MODAL_HEADING_3' | translate }}: </span>
            <span class="progress">
              <div class="progress-bar bg-success" role="progressbar" [style.width.%]="inputIsAFPTotalPointsReq"
                aria-valuenow="inputIsAFPTotalPointsReq" aria-valuemin="0" aria-valuemax="100">
                {{ inputIsAFPTotalPointsReq }} pts
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row docListStyle" *ngIf="inputIsDocEmptyList === false">
      <ul>
        <li *ngFor='let option of inputGroupObject; let i=index;'>
          <div class="row">
            <div class="col-md-12 col-xs-12 col-sm-12" style="padding-bottom:5px">
              <document-category [inputIsIntlGID100P]="checkIsIntlGID100P"
                [inputIsIntlPRNCrim]="checkIsIntlPRNCrim" [inputIsIntlPRNAFP]="checkIsAFP100P" [grpIndex]="i"
                [grpObj]='option' [checkAUCrim100PCalculationAVailable]='inputAUCrim100PCalculationAvailable'
                [inputIsIntlRegNzNzpv]="checkIsIntlRegNzNzpv"
                [checkIs100PFeatureAvailable]='inputIs100PFeatureAvailable'
                [checkIsAFP100PCalculationAvailable]='inputAFP100PCalculationAvailable'
                [inputIsFromEnhancedDashboard]="inputFromEnhancedDashboard"
                (documentOnUpload)='uploadAction($event)' (documentOnDelete)='deleteAction($event)'
                (documentOnDownload)='downloadAction($event)' (documentOnView)='viewAction($event)'
                (documentOnInstruction)='instructionAction($event)'></document-category>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="row mandatoryDocuploadbtn fixedbtn p-t-15">
      <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
        <button type="button" [disabled]="!inputEnableNextButton" *ngIf="inputIsDocEmptyList === false"
          [class.grow]="inputEnableNextButton" class="btn button font_extrabold senButton ssnfrmbtn"
          (click)="onNextClick()">{{ buttonText }}</button>
        <button type="button" [disabled]="!inputIsDocEmptyList" *ngIf="inputIsDocEmptyList === true"
          [class.grow]="inputIsDocEmptyList" class="btn button font_extrabold senButton ssnfrmbtn"
          (click)="onNextClick()">{{ buttonText }}</button>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
        <button type="button" class="btn button_wbg grow font_extrabold sendButton button_wbgSav"
          (click)="onSaveExitClick()">{{ 'COMMONS_SAVE_AND_EXIT' | translate }}</button>
      </div>
    </div>
  </div>
</div>


<div *ngIf="isDynamicDocumentUpload" class="anime-clean posrel" (onGesture)="doSwipe($event)" hammer-gestures>
    <div class="contetnheight mandatoryDocuments pad_30 posrel">
        <div class="row">
          <div class="col-md-12 col-xs-12 col-sm-12">
            <div class="header-txt header_txt_white">
              <h2 class="m-b-20">{{ miDocument.header | translate}}</h2>
              <h4>{{ miDocument.sub_header | translate}}</h4>
            </div>
          </div>
        </div>
        <div class="row docListStyle">
            <ul>
              <li>
                <div class="row">
                  <div class="col-md-9 col-xs-12 col-sm-12" style="padding-bottom:5px">
                    <document-category [isDynamicDocumentUpload]="true" [miDocument]="miDocument"
                      [inputIsIntlGID100P]="checkIsIntlGID100P"
                      [inputIsIntlPRNCrim]="checkIsIntlPRNCrim" [inputIsIntlPRNAFP]="checkIsAFP100P" [grpIndex]='0'
                      [grpObj]='' [checkAUCrim100PCalculationAVailable]='inputAUCrim100PCalculationAvailable'
                      [inputIsIntlRegNzNzpv]="checkIsIntlRegNzNzpv"
                      [checkIs100PFeatureAvailable]='inputIs100PFeatureAvailable'
                      [checkIsAFP100PCalculationAvailable]='inputAFP100PCalculationAvailable'
                      (documentOnUpload)='uploadAction($event)' (documentOnDelete)='deleteAction($event)'
                      (documentOnDownload)='downloadAction($event)' (documentOnView)='viewAction($event)'
                      (documentOnInstruction)='instructionAction($event)' ></document-category>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div class="row fixedbtn mandatoryDocuploadbtn p-t-15">
            <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
              <button type="button" [disabled]="!inputEnableNextButton && !enableNextButtonForDynamicUpload" [class.grow]="inputEnableNextButton || enableNextButtonForDynamicUpload"
                class="btn button font_extrabold senButton ssnfrmbtn" (click)="onNextClick()">{{ buttonText }}</button>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb" *ngIf="!hideSkipForNow">
              <button type="button" class="btn button_wbg grow font_extrabold sendButton button_wbgSav"
                (click)="onSkipNowClick()">{{ 'COMMONS_SKIP_FOR_NOW' | translate }}</button>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
              <button type="button" class="btn button_wbg grow font_extrabold sendButton button_wbgSav"
                (click)="onSaveExitClick()">{{ 'COMMONS_SAVE_AND_EXIT' | translate }}</button>
            </div>
          </div>
      </div>
</div>