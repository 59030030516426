import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { EmploymentConfig } from '../employment.config';
import { EmploymentService } from '../employment.service';

import { EventBusService } from 'framework/eventbus.service';

//State
import { Store } from '@ngrx/store';
import * as ACTIONS_INTERFACE from '../../actions';


declare var google: any;
declare var jQuery: any;

@Component({
  selector: 'verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.css']
})
export class VerificationComponent implements OnInit {

  @Input('group')
  public empSubForm: FormGroup;
  @Input() userDetails: any;
  @Input() accountName: string;
  @Input() configOptions: any;

  @Input() row: any;
  @Input() verificationFlag: string;

  //@Output() onnoVerification: EventEmitter<any> = new EventEmitter();
  @Output() onVerification: EventEmitter<any> = new EventEmitter();

  isViewInit;

  //SE functionality
  static STAGE_ID: string;
  static STEP_ID: string;
  static STEP_INDEX: number;
  public SUB_STEP_ID: string;
  public SUB_STEP_INDEX: number;
  PARENT_STAGE_STEP_CONFIG: Object;

  //State Maintenance Related Activity
  static BUTTON_CLICKED: string = 'CLICKED_SAVE_EXIT_BUTTON';
  currentStatus: string = 'VISITED';
  payLoad: Object;
  startTime: any;

  constructor(private _es: EmploymentService,
    private _eventBus: EventBusService, // Auto Generated PPA-8745 - Arun Supreet for Save n Exit Patch
    private _store: Store<ACTIONS_INTERFACE.AppState>) {
    this.startTime = new Date().getTime();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    //console.log('----------- ngAfterViewInit -----------------');
    this.processConfig();
    this.isViewInit = true;
  }

  private processConfig(): void {
    //Get the Config Details from Parent Component - Education
    //console.log(this.configOptions);

    this.PARENT_STAGE_STEP_CONFIG = this.configOptions['PARENT_CONFIG'];
    this.SUB_STEP_ID = EmploymentConfig.subStepIndexMapping['verification']['subStep'];
    this.SUB_STEP_INDEX = EmploymentConfig.subStepIndexMapping['verification']['subStepIndex'];

    this.setState();
  }

  private setState(): void {
    this.payLoad = {
      name: this.PARENT_STAGE_STEP_CONFIG['STAGE_ID'],
      step: this.PARENT_STAGE_STEP_CONFIG['STEP_ID'],
      stepIndex: this.PARENT_STAGE_STEP_CONFIG['STEP_INDEX'],
      subStep: this.SUB_STEP_ID,
      subStepIndex: this.SUB_STEP_INDEX,
      iteration: null,
      status: this.currentStatus,
      dbRecordPresent: this.PARENT_STAGE_STEP_CONFIG['IS_DB_RECORD_PRESENT']
    };

    // console.log('EMP - verification - setState');
    // console.log(this.payLoad);

    this._store.dispatch({ type: this.currentStatus, payload: this.payLoad });
  }


  doSwipe(direction: string, data) {
    // alert(direction);
    if (direction === 'swipeleft') {
      this.verification(data);

    }
  }

  saveExit(flag) {
    // PPA-8745 - Auto-generated via regex - Arun, Supreet, Save n Exit Patch
    var re = this._eventBus.logoutAnnounced({ dummy: "workflow_exit" });
    if (re == undefined) return;
  }

  
  verification(flag): void {
    /*
 *
 * User has submitted the form using 'NEXT' button, then current status should be changed to 'COMPLETED'.
 * If user has submitted the form using 'SAVE & EXIT' button, the current status has already been
 * changed to 'PENDING' in saveExit()
 *
 * */
    if (this.currentStatus === 'VISITED') {
      this.currentStatus = 'COMPLETED'
    }

    let obj: Object = {};

    (<FormControl>this.empSubForm.controls['confirm']).setValue(flag);
    obj['confirm'] = flag;



    let serverData: Object[] = [];
    serverData = (JSON.parse(JSON.stringify(this._es.getServerData())));
    let serverDataLen: number = serverData.length;




    // return;
    //Update the state
    this.setState();


    //pass the state to server
    obj['COMPONENT_STATE'] = this.payLoad;
    obj['startTime'] = this.startTime;

    let indexDetail: Object = {};
    indexDetail['row'] = this.row;
    obj['indexDetail'] = indexDetail;
    this.onVerification.emit(obj);
  }
}
