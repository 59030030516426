<div class="body-main">
  <div class="body-bg pad0 container tab-height">
    <div class="pad_30">
      <div class="anime-clean contetnheight posrel">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12">
            <div class="header_txt_white">
              <h2 id="signintext">{{ 'SELECT_WHERE_TO_RECEIVE_CODE' | translate }}</h2>
              <h4 class="m-t-10 pad5 mandatoryGapError" *ngIf="!!errorMessage">{{errorMessage | translate}}</h4>
            </div>
          </div>
        </div>
        <div class="row form-element-section m-t-40 mar-top80 pad15">
          <div class="col-sm-12 col-md-12 col-xs-12">
            <div class="row">
              <div class="col-xs-12 col-sm-offset-3 col-sm-6 col-md-offset-4 col-md-4 dp-r-0 m-b-20 text-center" *ngFor="let fc of factors">
                <button class="btn button_wbg font_extrabold mfa-selection-button" (click)="selectFactor(fc)" [ngClass]="{'selectedFactorBtn':(!!selectedFactor && selectedFactor.factorType===fc.factorType)}">
                  {{fc.val}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row fixedbtn">
        <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
          <button type="submit" class="btn button font_extrabold" name="action"
            [disabled]="(!selectedFactor || !selectedFactor.factorType)"
            [class.grow]="(!!selectedFactor && !!selectedFactor.factorType)" (click)="submitSelection()">{{'COMMONS_NEXT' | translate}}</button>
        </div>
        <!-- <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
          <button type="submit" class="btn button_wbg font_extrabold" (click)="goBack()" name="action">{{'CONSENT_BACK_BTN' | translate}}</button>
        </div> -->
      </div>
    </div>
  </div>
  <div class="clearfix"></div>
</div>

<!-- Wait Modal -->

<div class="modal fade custom_modal" id="waitMfaModal" tabindex="-1" role="Application" aria-labelledby="loginWaitStatus" data-backdrop="static"
     data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="transparentbg modal-body m-t-15">
        <h4 class="modal-title" id="loginWaitStatus">{{'WAIT_ON_AGGREMENT_MODAL_TEXT' | translate }}
        </h4>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-xs-12 col-sm-4 col-md-4 p_l_0  p_r_0 m-r-15 ">
            <!-- <button type="button" class="btn button font_extrabold" data-dismiss="modal" data-toggle="resetPassword-modal" (click)="goToSignin()">CONTINUE</button>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
