export class FormRadioExplanationConfig {
    static DEFAULT_OPTIONS = [
        {
            'label': 'LABEL_YES',
            'position': '1',
            'isDefault': 'false',
            'value': 'TRUE',
            'selected': false
        },
        {
            'label': 'LABEL_NO',
            'position': '2',
            'isDefault': 'false',
            'value': 'FALSE',
            'selected': false
        }
    ];

    static TEXTAREA_OPTIONS = {
        'label': 'TEXT_AREA_MORE_DETAILS_LABEL',
        'required': true,
        'maxlength': '2000',
        'visible': false
    };
}
