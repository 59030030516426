export class MvrConfig {
  static STAGE_ID: string = 'mvr-data-v1';
  static REFERENCES_TYPES = 'REFERENCES_TYPES' ;
  static steps: any = [
    'confirm',
    'country',
    'state',
    'number',
    'type',
    'lastname',
    'reconfirm'
  ];

  static subStepIndexMapping: Object = {
    confirm: {
      subStep: 'confirm',
      subStepIndex: 0
    },
    country: {
      subStep: 'country',
      subStepIndex: 1
    },
    state: {
      subStep: 'state',
      subStepIndex: 2
    },
    number: {
      subStep: 'number',
      subStepIndex: 3
    },
     type: {
      subStep: 'type',
      subStepIndex: 4
    },
     lastname: {
      subStep: 'lastname',
      subStepIndex: 5
    },
    reconfirm: {
      subStep: 'reconfirm',
      subStepIndex: 6
    }
  };

  static defaultCountry: string = '';
  static defaultCountryShortName: string = '';

  static defaultCountryOptions(): Object {
    let countryOptions = {};
    countryOptions['defaultCountry'] = MvrConfig.defaultCountry;
    countryOptions['defaultCountryShortName'] = MvrConfig.defaultCountryShortName;
    return countryOptions;
  }

  static getSteps(): any {
    return MvrConfig.steps;
  }

  static addNewStepAndIndexMapping(stepName: string) {
    let len = this.steps.length;
    this.steps.splice(len - 1, 0, stepName);

    this.subStepIndexMapping[stepName] = {
      subStep: stepName,
      subStepIndex: len - 1
    };
    this.subStepIndexMapping['reconfirm']['subStepIndex'] = this.subStepIndexMapping['reconfirm']['subStepIndex'] + 1;
  }

  static resetStepAndIndexMapping(stepName: string) {
    let index = this.steps.indexOf(stepName);
    if (index != -1) {
      this.steps.splice(index, 1);
    }
    if (this.subStepIndexMapping[stepName]) {
      delete this.subStepIndexMapping[stepName];
      this.subStepIndexMapping['reconfirm']['subStepIndex'] = this.subStepIndexMapping['reconfirm']['subStepIndex'] - 1;
    }
  }

  static getReferencesType(): Array<Object> {
    let types = [
      { id: "Commercial", name: "MVR_TYPE_1" },
      { id: "Non-commercial" , name: "MVR_TYPE_2" }
   ];
    return types;
  }

  /**
   * get MVR name for the given type for translation
   *
   * @param type
   */
  static getMvrType(type: String ) {
    let types = this.getReferencesType();
    for (let currentType of types) {
      if (currentType['id'].toUpperCase() === type.toUpperCase()) {
        return currentType['name'];
      }
    }

    return null;
  }

 static headerSubheaders  = [{
  'header': 'MVR Information',
  'sub_header': 'MVR Information'
},
{
  'header': 'MVR Information',
  'sub_header': 'MVR Information'
}];

 static getHeaderSubheaders(): any {
  return MvrConfig.headerSubheaders ;
}

  static getlicenceValidation(): Object {
    let regularExpression = {
      'AZ': '^[A,B,D,Y][0-9]{8}$|^[0-9]{9}$',
      'MD': '^[A-Z][0-9]{12}$',
      'IL': '^[A-Z][0-9]{11}$',
      'ME': '^[0-9]{7}$',
      'VT': '^[0-9]{7}[A-Z]$|^[0-9]{8}$',
      'TX': '^[0-9]{8}$',
      'MI': '^[A-Z][0-9]{12}$',
      'OH': '^[A-Z]{2}[0-9]{6}$',
      'PR': '^[0-9]{5,7}$|^[0-9]{9}$',
      'IA': '^[0-9]{3}[A-Z]{2}[0-9]{4}$|^[0-9]{9}$',
      'VA': '^[A-Z][0-9]{8}$|^[0-9]{9}$',
      'NE': '^[A,B,C,E,G,H,V][0-9]{3,8}$',
      'KS': '^[K][0-9]{8}$|^[0-9]{9}$',
      'SC': '^[0-9]{1,10}$',
      'MA': '^[S][0-9]{8}$|^SA[0-9]{7}$|^[0-9]{9}$',
      'MO': '^[A-Z][0-9]{5,9}$|^[0-9]{9}$',
      'NH': '^[0-9]{2}[A-Z]{3}[0-9]{5}$|^[A-Z]{3}[0-9]{8}$',
      'PA': '^[0-9]{8}$',
      'IN': '^[A-Z][0-9]{9}$|^[0-9]{10}$',
      'CT': '^[0-9]{9}$',
      'TN': '^[0-9]{7,9}$',
      'NY': '^[A-Z][0-9]{18}$|^[0-9]{9}$',
      'ID': '^[A-Z]{2}[0-9]{6}[A-Z]$|^[0-9]{9}$',
      'CO': '^[A-Z][0-9]{1,6}$|^[0-9]{9}$',
      'AR': '^[0-9]{9}$',
      'MN': '^[A-Z][0-9]{12}$',
      'OK': '^[A-Z][0-9]{9}$|^[0-9]{9}$',
      'SD': '^[0-9]{8,9}$|^[0-9]{6}$',
      'DC': '^[0-9]{7}$|^[0-9]{9}$',
      'DE': '^[0-9]{1,7}$',
      'MS': '^[0-9]{9}$',
      // 'WA': '^[A-Z*]{7}[0-9*]{3}[\\w*]{2}$',
      'WA': '^[A-Z*]{7}[0-9*]{3}[\\w*]{2}$|^WDL[\\w]{9}$',
      'MT': '^[\\w]{9}$|^[0-9]{13}$',
      'KY': '^[A-Z][0-9]{8}$|^[0-9]{9}$',
      'OR': '^[\\w]{7}$|^[0-9]{1,9}$',
      'WI': '^[A-Z][0-9]{13}$',
      'FL': '^[A-Z][0-9]{12}$',
      'LA': '^[0-9]{9}$',
      'HI': '^[H][0-9]{8}$|^[0-9]{9}$',
      'UT': '^[0-9]{4,9}$',
      'NC': '^[0-9]{1,12}$',
      'ND': '^[A-Z]{3}[0-9]{6}$|^[0-9]{9}$',
      'WV': '^[\\w]{7}$',
      'NM': '^[0-9]{9}$',
      'RI': '^[A-Z][0-9]{6}$|^[0-9]{8}$|^[0-9]{7}$',
      'AK': '^[0-9]{7}$',
      'AL': '^[0-9]{7}$',
      'GA': '^[0-9]{7,9}$',
      'NV': '^[X][0-9]{8}$|^[0-9]{12}$|^[0-9]{10}$',
      'NJ':'^[A-Z][0-9]{14}$',
      'CA':'^[A-Z][0-9]{7}$',
      'WY':'^[0-9]{9,10}$'
    };

    return regularExpression;
  }
  static getlicenceErrormessage(): Object {

    let errorMessage = {
      'AZ':'The state of Arizona requires you to enter a DLN made up of either the alphabets (A,B,D or Y) + either 8 or 9 numerical values.',
      'MD':'The state of Maryland requires you to enter a DLN made up of 1 alphabetical + 12 numerical values',
      'IL':'The state of Illinois requires you to enter a DLN made up of 1 alphabetical + 11 numerical values',
      'ME':'The state of Maine requires you to enter a DLN made up of 7 numerical values',
      'VT':'The state of Vermont requires you to enter a DLN made up of either 7 numeric + 1 alphabetcal or 8 numerical values',
      'TX':'The state of Texas requires you to enter a DLN made up of 8 numerical values',
      'MI':'The state of Michigan requires you to enter a DLN made up of 1 alphabetical + 12 numerical values',
      'OH':'The state of Ohio requires you to enter a DLN made up of 2 alphabetical + 6 numerical values',
      'MN':'The state of Minnesota requires you to enter a DLN made up of 1 alphabetical + 12 numerical values',
      'OK':'The state of Oklahoma requires you to enter a DLN made up of either 1 alphabetical + 9 numerical or 9 numerical values',
      'SD':'The state of South Dakota requires you to enter a DLN made up of either 8-9 or 6 numerical values',
      'DC':'The District of Columbia requires you to enter a DLN made up of either 7 or 9 numerical values',
      'DE':'The state of Delaware requires you to enter a DLN made up of 1-7 numerical values',
      'MS':'The state of Mississippi requires you to enter a DLN made up of 9 numerical values',
      // 'WA':"The state of Washington requires a DLN made up of 7 alpha + 3 numeric + 2 alpha/numeric possibly including 1 or more '*'",
      'WA':"The state of Washington requires a DLN made up of 7 alpha + 3 numeric + 2 alpha/numeric possibly including 1 or more '*'  or WDL + 9 alpha/numeric",
      'MT':'The state of Montana requires you to enter a DLN made up of either 9 alpha-numerical or 13 numerical values',
      'KY':'The state of Kentucky requires you to enter a DLN made up of 1 alphabetical + 8 or 9 numerical values',
      'OR':'The state of Oregon requires you to enter a DLN made up of either 7 alpha-numerical or 1-9 numerical values',
      'WI':'The state of Wisconsin requires you to enter a DLN made up of 1 alphabetical  + 13 numerical values',
      'FL':'The state of Florida requires you to enter a DLN made up of  1 alphabetical + 12 numerical values',
      'LA':'The state of Louisiana requires you to enter a DLN made up of 9 numerical values',
      'HI':"The state of Hawaii requires you to enter a DLN made up of either the alphabet 'H' + 8 numerical values or 9 numerical values",
      'UT':'The state of Utah requires you to enter a DLN made up of 4-9 numerical values',
      'NC':'The state of North Carolina requires you to enter a DLN made up of 1-12 numerical values',
      'ND':'The state of North Dakota requires you to enter a DLN made up of either 3 alphabetical  + 6 numerical values or 9 numerical values',
      'WV':'The state of West Virginia requires you to enter a DLN made up of 7 alpha-numerical values',
      'NJ':'The state of New Jersey requires you to enter a DLN made up of 1 alphabetical + 14  numerical values',
      'CA':'The state of California requires you to enter a DLN made up of 1 alphabetical  + 7  numerical values',
      'WY':'The state of Wyoming requires you to enter a DLN made up of 9 or 10 numerical values',
      'NM':'The state of New Mexico requires you to enter a DLN made up of 9 numerical values',
      'RI':"The state of Rhode Island requires you to enter a DLN made up of 1 alpha + 6 numeric or 7 numeric or 8 numeric",
      'AK':'The state of Alaska requires you to enter a DLN made up of 7 numerical values',
      'AL':'The state of Alabama requires you to enter a DLN made up of 7 numerical values',
      'GA':'The state of Georgia requires you to enter a DLN made up of 7-9 numerical values',
      'NV':'The state of Nevada requires you to enter a DLN made up of either the alphabet X + 8  numerical values or either of 12  or 10 numerical values',
      'AR':'The state of Arkansas requires you to enter a DLN made up of 9 numerical values',
      'ID':'The state of Idaho requires you to enter a DLN made up of either 2 alphabetical + 6 numerical + 1 alphabetical-values or 9 numerical values',
      'NY':'The state of New York requires you to enter a DLN made up of either 1 alphabetical + 18 numerical values or 9 numerical values',
      'TN':'The state of Tennessee requires you to enter a DLN made up of 7-9 numerical values',
      'CT':'The state of Connecticut requires you to enter a DLN made up of 9 numerical values',
      'IN':'The state of Indiana requires you to enter a DLN made up of either 1 alphabetical + 9 numerical values or 10 numerical values',
      'PA':'The state of Pennsylvania requires you to enter a DLN made up of 8 numerical values',
      'NH':'The state of New Hampshire requires you to enter a DLN made up of 2 numeric + 3 alphas + 5 numeric or 3 alphas + 8 numeric',
      'MO':'The state of Missouri requires you to enter a DLN made up of either 1 alphabetical + 5-9 numerical values or 9 numerical values',
      'MA':'The state of Massachusetts requires a DLN made up of either the Alpha S + 8 numeric, 9 numeric or SA + 7 numeric',
      'SC':'The state of South Carolina requires you to enter a DLN made up of 1-10 numerical values',
      'KS':'The state of Kansas requires you to enter a DLN made up of either the alphabet K + 8 numerical values or 9 numerical values',
      'NE':'The state of Nebraska requires you to enter a DLN made up of either the alphabets (A,B,C,E,G,H,V) + 3-8 numerical values',
      'VA':'The state of Virginia requires you to enter a DLN made up of either 1 alphabet + 8 numerical values or 9 numerical values',
      'IA':'The state of Iowa requires you to enter a DLN made up of either 3 numerical + 2 alphabetical + 4 numerical values or 9 numerical values',
      'PR':'The state of Puerto Rico requires you to enter a DLN made up of either 5-7 numerical values or 9 numerical values'
    };

    return errorMessage;
  }

  static ats: Object = {
        'country' : '',
        'has_mvr' : '',
        'id' : '',
        'number' : '',
        'license_class' : '',
        'source' : '',
        'state_code' : '',
        'type' : '',
        'state_name' : '',
        'last_name' : '',
        'valid-from' : '',
        'valid-to' : '',
        'confirmation' : '',
        'name_on_license': '',
        'state' : {
          'step_status' : '',
          'stage_status' : '',
          'current_iteration' : '',
          'current_stage' : '',
          'current_step' : '',
          'current_step_index' : '',
          'current_sub_step' : '',
          'current_sub_step_index' : '',
          'current_status' : ''
        },
        'country_name' : ''
      };

  static regular: Object = {
        'country' : '',
        'has_mvr' : '',
        'id' : '',
        'number' : '',
        'license_class' : '',
        'source' : '',
        'state_code' : '',
        'type' : '',
        'state_name' : '',
        'last_name' : '',
        'valid-from' : '',
        'valid-to' : '',
        'confirmation' : '',
        'name_on_license': '',
        'form' : '',
        'state' : {
          'step_status' : '',
          'stage_status' : '',
          'current_iteration' : '',
          'current_stage' : '',
          'current_step' : '',
          'current_step_index' : '',
          'current_sub_step' : '',
          'current_sub_step_index' : '',
          'current_status' : ''
        },
        'country_name' : ''
      };

  static DisplayLabel: Object = {
        'Country':'',
        'License Number':'',
        'License Class':'',
        'Source':'',
        'State':'',
        'Type':'',
        'delete_link' : '',
        'CountryName' : '',
        'StateCode' : ''
      };
}
