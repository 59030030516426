<div class="pad_30" [formGroup]="empSubForm" (onGesture)="doSwipe($event,empSubForm.value)" hammer-gestures>
  <button type="button" class="btn arrowrightctr" attr.data-id="empCompanyNameRightArrow_{{row}}"
    [disabled]="!empSubForm.controls.companyName.valid" debounceDirective (debounceClick)="companyName(empSubForm.value)"> <i
      class="icon-arrow-right" role="img" [attr.aria-label]="'SCREEN_FARWARD_ARROW' | translate"></i></button>
  <button type="button" class="btn arrowleftctr" attr.data-id="empCompanyNameLeftArrow_{{row}}"
    debounceDirective (debounceClick)="getPrevSwipe(empSubForm.value)"><i class="icon-arrow-left" role="img" [attr.aria-label]="'SCREEN_BACKWARD_ARROW' | translate"></i></button>
  <div class="anime-clean contetnheight posrel">
    <div class="row">
      <div class="col-md-12 col-xs-12 col-sm-12">
        <div class="header_txt_white" *ngIf="empType === 'current' || (((empType === 'selfEmployed' && isPaDOTDirectEnabled) || isCheckSelfEmploymentEnabled) && row === 0)">
          <h2 *ngIf="empType === 'current'">{{'EMPLOYMENT_COMPANY_NAME_HEADER_1'|translate : [userDetails.firstname]}}
          </h2>
          <h4 *ngIf="empType === 'current'">{{'EMPLOYMENT_COMPANY_NAME_SUBHEADER_1'|translate }}</h4>
          <h2 *ngIf="isCheckSelfEmploymentEnabled || (empType === 'selfEmployed' && isPaDOTDirectEnabled)">
            {{'SELF_EMPLOYMENT_COMPANY_HEADER'|translate : [userDetails.firstname] }}</h2>
        </div>
        <div class="header_txt_white" *ngIf="empType === 'prior' || (((empType === 'selfEmployed' && isPaDOTDirectEnabled) || isCheckSelfEmploymentEnabled) && row > 0)">
          <h2 *ngIf="empType === 'prior'">{{'EMPLOYMENT_COMPANY_NAME_HEADER_2'|translate:[userDetails.firstname] }}</h2>
          <h4 *ngIf="empType === 'prior'">{{'EMPLOYMENT_COMPANY_NAME_SUBHEADER_2'|translate }}</h4>
          <h2 *ngIf="isCheckSelfEmploymentEnabled || (empType === 'selfEmployed' && isPaDOTDirectEnabled)">
            {{'SELF_EMPLOYMENT_COMPANY_HEADER'|translate : [userDetails.firstname] }}</h2>
        </div>
      </div>
    </div>
    <div class="row form-element-section">
      <div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
        <div class="group padv-dropdown">
          <label class="control-labelss" attr.for="empCompanyName_{{row}}">{{'EMPLOYMENT_COMPANY_NAME_LABLE'|translate }}</label>
          <!-- <input type="text" class="form-control" attr.id="empCompanyName_{{row}}" aria-required="true" formControlName="companyName" maxlength="50"
            (keyup)="companyLookUp(empSubForm.controls.companyName.value, $event)" /> -->

            <custom-select [items]="companyList" [typeahead]="companyNameTypeahead" [typeToSearchText]=""
                            bindLabel="name" bindvalue="name" [group] = "empSubForm" 
                            controlName="companyName" attr.id="empCompanyName_{{row}}" [selectedValue]="selectedCompany"
                            displayValue="companyDisplayName" (valueChange)="trackChange($event)" (clearData)="onClearData()"></custom-select>
          <input type="hidden" name="type" formControlName="type">

          <!-- <ul [class.hide]="isCompanyListToShow === false" class="generic-dropdown-list">
            <li *ngFor="let companyOption of companyList" debounceDirective (debounceClick)="entercompanyInput(companyOption)">
              <div>
                <span>{{companyOption['name']}}</span>
              </div>
            </li>
          </ul> -->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-6 col-md-8 m-b-20" *ngIf="options['countryOptions']['countryShortName'] != 'US'">
        <div class="group">
          <label class="control-labelss" attr.for="empCompAddress_{{row}}">{{'EMPLOYMENT_COMPANY_ADDRESS_LABLE'|translate }}</label>
          <input type="text" class="form-control" attr.id="empCompAddress_{{row}}" aria-required="true" formControlName="companyAddress" maxlength="50" (keyup) = "onCompanyAddressChange()"/>

          <div *ngIf="!isValidCompAdd">
            <div class="validate custom-error error_new_ui">
              <div class="left_div">
                <ul>
                  <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate }}</li>
                  <li class="message">{{ 'EMPLOYMENT_COMPANY_ADD_ERROR' | translate }}</li>
                </ul>
              </div>
              <div class="right_div">
                <div class="close" aria-hidden="true" debounceDirective (debounceClick)="closeCompAdd()">
                  <i class="icon-cancel"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row fixedbtn  ">
      <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
        <button type="button" class="btn button font_extrabold senButton" attr.data-id="empCompanyNameNext_{{row}}"
          [class.grow]="empSubForm.controls.companyName.valid" [disabled]="!empSubForm.controls.companyName.valid"
          debounceDirective (debounceClick)='companyName(empSubForm.value)'>{{ 'COMMONS_NEXT' | translate }}</button>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
        <button type="button" class="btn button_wbg font_extrabold  grow button_wbgSav"
          attr.data-id="empCompanyNameSaveExit_{{row}}" [disabled]="!empSubForm.controls.companyName.valid"
          debounceDirective (debounceClick)='saveExit(empSubForm.value)'>{{ 'COMMONS_SAVE_AND_EXIT' | translate }}</button>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</div>