<span class="error_info">
  <i class="fa fa-exclamation error_icon" aria-hidden="true" [class.red_error]="true" [class.cursor_hand_none]="false"
    (click)="openErrorPop()"></i>
  <!-- <div class="error_info_popup">{{ 'COMMONS_COUNTRY_ERROR' | translate }}<i class="fa fa-times" aria-hidden="true" (click)="closeCountryError()"></i></div> -->
</span>
<div class="custom-error error_new_ui" *ngIf="errorConfigData['isMessagePopUpShow']">
  <div class="left_div">
    <ul>
      <li class="alert"><i class="icon-warning"></i> {{ errorConfigData['alert'] | translate }}</li>
      <li class="message">{{ errorConfigData['message'] | translate }}</li>
    </ul>
  </div>
  <div class="right_div">
    <div class="close" aria-hidden="true" (click)="closeErrorPop()">
      <i class="icon-cancel"></i>
    </div>
  </div>
</div>
