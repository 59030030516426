import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { EmploymentConfig } from '../employment.config';
import { EmploymentService } from '../employment.service';
import { BehaviorSubject } from 'rxjs';

import { EventBusService } from '../../../framework/eventbus.service';
import * as dateUtils from 'framework/utils/date.utils';
//State
import { Store } from '@ngrx/store';
import * as ACTIONS_INTERFACE from '../../actions';
import { TranslateService } from 'framework/i18n';
import { SharedService } from './../../shared/services/shared.service';


@Component({
  selector: 'job-period',
  templateUrl: './job-period.component.html',
  styleUrls: ['./job-period.component.css']
})
export class JobPeriodComponent implements OnInit {
  @Input('group')
  public empSubForm: FormGroup;

  _data = new BehaviorSubject<any>([]);
  gapCheck = new BehaviorSubject<any>([]);
  _futureDateFlag = new BehaviorSubject<any>([]);

  public checkGapClick: boolean = true;
  public invalidDate: boolean = false;
  monthNames = this._ts.instant('MONTH_NAMES');
  @Input() row: any;
  @Input() empType: string;
  @Input() options: any;
  @Input() currentJob: any;
  @Input() currentSlide: any;
  @Input() IsSaveSuccess: boolean;
  @Input() isJobfromValid: boolean;
  @Input() isCheckSelfEmploymentEnabled: boolean;


  @Input()
  set dateOptions(value) {
    this._data.next(value);
  };

  get dateOptions() {
    return this._data.getValue();
  }

  @Input()
  set inputGapCheck(value) {
    this.gapCheck.next(value);
  };

  get inputGapCheck() {
    return this.gapCheck.getValue();
  }

  //  @Input() dateOptions: Object;

  // @Input() inputGapCheck: boolean;
  @Input() userDetails: any;
  @Input() activeGapOption: any;
  @Input() configOptions: any;
  @Input() isReviewEdit: boolean;
  @Input() isReview: boolean;
  @Input() isReviewAdd: boolean;
  @Input() isEmploymentGapInDaysEnabled: boolean;
  @Input() isCitadelData: boolean
  @Input() showPlaidData: boolean
  @Input()
  set futureDateFlags(value) {
    this._futureDateFlag.next(value);
  };

  get futureDateFlags() {
    return this._futureDateFlag.getValue();
  };

  @Output() onJobPeriod: EventEmitter<any> = new EventEmitter();
  @Output() onJobPeriodSwipe: EventEmitter<any> = new EventEmitter();
  jobFromId;
  jobToId;
  fromDateOptions: Object;
  toDateOptions: Object;
  fromMinDate: Date;
  fromMaxDate: Date;
  fromDefaultDate: Date;
  toMinDate: Date;
  toMaxDate: Date;
  toDefaultDate: Date;
  toSetDate: Date;
  fromSetDate: Date;
  isViewInit;
  isJobPeriodError: boolean = false;
  displayType: any;
  isJobToValid: boolean = false;
  checkGapValue: boolean = false;


  //SE functionality
  static STAGE_ID: string;
  static STEP_ID: string;
  static STEP_INDEX: number;
  public SUB_STEP_ID: string;
  public SUB_STEP_INDEX: number;
  PARENT_STAGE_STEP_CONFIG: Object;

  //State Maintenance Related Activity
  static BUTTON_CLICKED: string = 'CLICKED_SAVE_EXIT_BUTTON';
  currentStatus: string = 'VISITED';
  payLoad: Object;
  startTime: any;
  isStartDateIsFutureDate: boolean = false;
  isEndDateIsFutureDate: boolean = false;
  showStartDateRangeErrorMsg: boolean = false;
  showEndDateRangeErrorMsg: boolean = false;

  constructor(private _store: Store<ACTIONS_INTERFACE.AppState>,
    private _eventBus: EventBusService, // Auto Generated PPA-8745 - Arun Supreet for Save n Exit Patch

    private _es: EmploymentService,
    private _sharedService: SharedService,
    private _ts: TranslateService) {

      this.startTime = new Date().getTime();
  }

  ngOnInit(): void {
    this._data
      .subscribe(x => {
        this.setDate();
      });

    this.gapCheck
      .subscribe(y => {
        if (y !== undefined) {
          this.checkGapValue = true;
        }
      });

    this._futureDateFlag
      .subscribe(futureDateflgs => {
        if (futureDateflgs !== undefined) {
          this.isStartDateIsFutureDate = futureDateflgs['isStartDateIsFutureDate'];
          this.isEndDateIsFutureDate = futureDateflgs['isEndDateIsFutureDate'];
        }
      });

    this.isViewInit = false;
    this.jobFromId = `empJobFrom_${this.row}`;
    this.jobToId = `empJobTo_${this.row}`;
    if (this.activeGapOption) {
      this.setDate();
    }
    //  this.setDate();

    this.displayType = this._es.getCategory(this.empType);
  }

  ngOnChanges() {
    if (this.isViewInit && (this.empType === 'prior' || this.empType === 'selfEmployed')) {
      this.setDate();
    }
    if (this.currentSlide === undefined || this.currentSlide !== undefined && this.currentSlide !== null
      && (this.currentSlide.indexOf("job-period") > -1)) {
      this.displayType = this._es.getCategory(this.empType);
    }
    if (this.activeGapOption) {
      this.setDate();
    }

    if (this.IsSaveSuccess) {
      this.checkGapClick = this.IsSaveSuccess;
    }
    this.isJobfromValid = this.isJobfromValid;
  }

  ngAfterViewInit(): void {
    // console.log('----------- ngAfterViewInit -----------------');

    this.processConfig();
    this.isViewInit = true;

    if (this.empSubForm.controls['jobTo'] !== undefined && this.empSubForm.controls['jobTo'].value !== '') {
      this.isJobToValid = true;
    }
  }

  private processConfig(): void {
    //Get the Config Details from Parent Component - Education
    //console.log(this.configOptions);

    this.PARENT_STAGE_STEP_CONFIG = this.configOptions['PARENT_CONFIG'];
    this.SUB_STEP_ID = EmploymentConfig.subStepIndexMapping['job_period']['subStep'];
    this.SUB_STEP_INDEX = EmploymentConfig.subStepIndexMapping['job_period']['subStepIndex'];

    this.setState();
  }

  private setState(): void {
    this.payLoad = {
      name: this.PARENT_STAGE_STEP_CONFIG['STAGE_ID'],
      step: this.PARENT_STAGE_STEP_CONFIG['STEP_ID'],
      stepIndex: this.PARENT_STAGE_STEP_CONFIG['STEP_INDEX'],
      subStep: this.SUB_STEP_ID,
      subStepIndex: this.SUB_STEP_INDEX,
      iteration: this.row,
      status: this.currentStatus,
      dbRecordPresent: this.PARENT_STAGE_STEP_CONFIG['IS_DB_RECORD_PRESENT']
    };

    // console.log('EMP - job_period - setState');
    //console.log(this.payLoad);

    this._store.dispatch({ type: this.currentStatus, payload: this.payLoad });
  }

  ngOnDestroy(): void {
  }

  private setDate() {
    if (!this.isCitadelData && !this.showPlaidData && (!!this.currentSlide && this.currentSlide.indexOf("emp-details") === -1 && this.currentSlide.indexOf("data-provider") === -1)) {
      this.fromDateOptions = this.dateOptions['from'];

      this.toDateOptions = this.dateOptions['to'];
      // this.validateDate(this.fromDateOptions, this.toDateOptions);
      this.setFromDate();
      this.setToDate();
    }
  }

  private setFromDate() {
    if (this.dateOptions['from']) {
      this.fromMinDate = this.fromDateOptions['minDate'];
      this.fromMaxDate = this.fromDateOptions['maxDate'];
      this.fromDefaultDate = this.fromDateOptions['defaultDate'];
      if (this.empSubForm.controls['jobFrom'] !== undefined) {
        let jobFrom = (<FormControl>this.empSubForm.controls['jobFrom']).value;
        if (this.activeGapOption || this.isReviewEdit || this.checkGapValue || !!jobFrom) {
          if (!!jobFrom) {
            let currentLang = this._ts.instant("MONTH_NAMES");
            let engLang = this._sharedService.getEngMonths();
            let formatToEngLang: string;
            if (this.isEmploymentGapInDaysEnabled) {
              formatToEngLang = dateUtils.convertFullDateToEngFormat(jobFrom, currentLang, engLang);
            } else {
              formatToEngLang = dateUtils.convertToEngFormat(jobFrom, currentLang, engLang);
            }            
            this.fromSetDate = this._es.fromDateOptions(formatToEngLang)['defaultDate'];
          } else {
            this.fromSetDate = this.fromDateOptions['defaultDate'];
          }

          let setMonthNo = new Date(this.fromSetDate).getMonth();
          let setyear = new Date(this.fromSetDate).getFullYear();
          let setMonthName = this.monthNames[setMonthNo];
          let setMonthYear = `${setMonthName} ${setyear}`;
          if (this.fromSetDate == undefined) {
            let setMonthYear = ''
            this.getFromDpValue(setMonthYear);
          }
          else {
            if (this.isEmploymentGapInDaysEnabled) {
              let setDay = new Date(this.fromSetDate).getDate();
              let setMonthDayYear = `${setMonthName} ${setDay},${setyear}`;
              this.getFromDpValue(setMonthDayYear);
            } else {
              this.getFromDpValue(setMonthYear);
            }
          }
          this.checkGapValue = false;
        }
      }
      //this.fromSetDate = this.fromDateOptions['defaultDate'];
    } else {
      this.fromMinDate = undefined;
      this.fromMaxDate = undefined;
      this.fromDefaultDate = undefined;
      this.fromSetDate = undefined;
      (<FormControl>this.empSubForm.controls['jobFrom']).patchValue("");
    }
  }


  private setToDate() {

    // Handle gap scenario in case of prior - either with multiposition or without multiposition..
    if (!!this.dateOptions['to'] && this.empType === 'prior' && (this.activeGapOption || this.isReview)) {
      if (!isNaN(this.dateOptions['to'])) {
        let monthYear = dateUtils.globalDateTransform(this.dateOptions['to'], this._es.getDateFormat(), this._sharedService.getEngMonths());
        this.toDateOptions = this._es.fromDateOptions(monthYear);
      }
    }

    if (this.dateOptions['to']) {
      this.toMinDate = this.toDateOptions['minDate'];
      this.toMaxDate = this.toDateOptions['maxDate'];
      this.toDefaultDate = this.toDateOptions['defaultDate'];
      this.toSetDate = this.toDateOptions['setDate'];
      let setMonthNo = new Date(this.toSetDate).getMonth();
      let setyear = new Date(this.toSetDate).getFullYear();
      let setMonthName = this.monthNames[setMonthNo];
      let setMonthYear = `${setMonthName} ${setyear}`;
      if (this.isEmploymentGapInDaysEnabled) {
        let setDay = new Date(this.toSetDate).getDate();
        let setMonthDayYear = `${setMonthName} ${setDay},${setyear}`;
        this.getToDpValue(setMonthDayYear);
      } else {
        this.getToDpValue(setMonthYear);
      }
    } else {
      if (this.empSubForm.controls['jobTo'] !== undefined) {
        this.toMinDate = undefined;
        this.toMaxDate = undefined;
        this.toDefaultDate = undefined;
        this.toSetDate = undefined;
        (<FormControl>this.empSubForm.controls['jobTo']).patchValue("");
      }
    }

  }

  getFromDpValue(value): void {
    console.log('jobFrom values ---- ' + value);
    (<FormControl>this.empSubForm.controls['jobFrom']).setValue(value);
    let fromEngDate = this.getDateEngValue(value);// Calculcation purpose

    this._es.jobFromDateCollection[this.row] = fromEngDate;
    if (!!value) {
      this.dateOptions['from'] = this._es.fromDateOptions(fromEngDate);
      //  this.validateDate(value);
    }
    if (this.empSubForm.controls['jobFrom'] !== undefined && this.empSubForm.controls['jobTo'] === undefined) {
      this.validateDate(fromEngDate);
    }
    if (this.empSubForm.controls['jobFrom'] !== undefined && this.empSubForm.controls['jobTo'] !== undefined) {
      let toEngDate = this.getDateEngValue((<FormControl>this.empSubForm.controls['jobTo'].value));// Calculcation purpose
      this.validateDate(fromEngDate, toEngDate);
    }

  }

  getToDpValue(value): void {
    console.log('jobTo values ---- ' + value);
    if (this.empSubForm.controls['jobTo'] !== undefined) {
      this.isJobToValid = true;
      (<FormControl>this.empSubForm.controls['jobTo']).setValue(value);
      let toEngDate = this.getDateEngValue(value);// Calculcation purpose
      let fromEngDate = this.getDateEngValue((<FormControl>this.empSubForm.controls['jobFrom']).value);// Calculcation purpose
      this._es.jobToDateCollection[this.row] = toEngDate;
      if (!!toEngDate) {
        this.dateOptions['to'] = this._es.toDateOptions(toEngDate);
        this.validateDate(fromEngDate, toEngDate);
      }
    }
  }

  private getDateEngValue(languageDate) {
    if (this.isEmploymentGapInDaysEnabled) {
      return languageDate === '' ? languageDate : dateUtils.formatEngFullDateLabel(languageDate, this.monthNames, this._es.monthNames);
    } else {
      return languageDate === '' ? languageDate : dateUtils.formatEngDateLabel(languageDate, this.monthNames, this._es.monthNames);
    }
  }

  doSwipe(direction: string, data) {
    // alert(direction);
    if (direction === 'swiperight') {
      this.getPrevSwipe(data);
    }
    if (direction === 'swipeleft') {
      if ((this.row > 0 && !!data['jobFrom'] && !!data['jobTo'])
        ||
        (this.row == 0 && !!data['jobFrom'])) {
        this.jobPeriod(data);
      }


    }
  }

  getPrevSwipe(obj) {
    let indexDetail: Object = {};
    indexDetail['row'] = this.row;
    obj['indexDetail'] = indexDetail;
    this.onJobPeriodSwipe.emit(obj);
  }

  closeJobPeriodError() {
    this.isJobPeriodError = !this.isJobPeriodError;
  }


  openJobPeriodError() {
    this.isJobPeriodError = !this.isJobPeriodError;
  }




  saveExit(obj) {
    // PPA-8745 - Auto-generated via regex - Arun, Supreet, Save n Exit Patch
    var re = this._eventBus.logoutAnnounced({ dummy: "workflow_exit" });
    if (re == undefined) return;
  }

  
  jobPeriod(obj): void {

    if (this.currentStatus === 'VISITED') {
      this.currentStatus = 'COMPLETED';
    }
    if (this.empSubForm.controls['jobFrom'] !== undefined && this.empSubForm.controls['jobTo'] === undefined) {
      let jobFrom = this.getDateEngValue((<FormControl>this.empSubForm.controls['jobFrom']).value);
      obj['jobFrom'] = jobFrom;
      this.validateDate(jobFrom);
      if (this.isStartDateIsFutureDate) {
        this.showStartDateRangeErrorMsg = true;
      }
    }
    if (this.empSubForm.controls['jobFrom'] !== undefined && this.empSubForm.controls['jobTo'] !== undefined) {
      let jobFrom = this.getDateEngValue((<FormControl>this.empSubForm.controls['jobFrom']).value);
      let jobTo = this.getDateEngValue((<FormControl>this.empSubForm.controls['jobTo']).value);
      obj['jobFrom'] = jobFrom;
      obj['jobTo'] = jobTo;
      this.validateDate(jobFrom, jobTo);
      if (this.isStartDateIsFutureDate) {
        this.showStartDateRangeErrorMsg = true;
      }
      if (this.isEndDateIsFutureDate) {
        this.showEndDateRangeErrorMsg = true;
      }
    }
    // Update the state
    this.setState();

    let indexDetail: Object = {};
    indexDetail['row'] = this.row;
    obj['indexDetail'] = indexDetail;
    obj['COMPONENT_STATE'] = this.payLoad;
    obj['startTime'] = this.startTime;

    if (!this.isStartDateIsFutureDate && !this.isEndDateIsFutureDate) {
      this.onJobPeriod.emit(obj);
    }
    this.invalidDate = false;
  }

  public validateDate(jobFrom: any, jobTo?: any) {
    let monthDiff;
    let formatedFromDate = '';
    let formatedToDate = '';
    let startDate;
    let endDate;
    // this.isStartDateIsFutureDate = false;
    // this.isEndDateIsFutureDate = false;
    this.showStartDateRangeErrorMsg = false;
    this.showEndDateRangeErrorMsg = false;

    if (this.empSubForm.controls['jobFrom'] !== undefined && this.empSubForm.controls['jobTo'] === undefined) {
      formatedFromDate = this._es.formatDate(jobFrom);
      if (jobFrom !== undefined && jobFrom !== null) {
        startDate = this._es.getDateToTs(jobFrom);
      }
      if (formatedFromDate !== undefined && formatedFromDate !== null) {
        this.invalidDate = false;
        this.isJobPeriodError = false;
        this.checkForFutureDate(startDate, 'START_DATE');
      } else {
        this.invalidDate = true;
        this.isJobPeriodError = true;
      }
    }

    if (this.empSubForm.controls['jobFrom'] !== undefined && this.empSubForm.controls['jobTo'] !== undefined) {
      formatedToDate = this._es.formatDate(jobTo);
      formatedFromDate = this._es.formatDate(jobFrom);
      if (this.isEmploymentGapInDaysEnabled) {
        monthDiff = dateUtils.dateDiffInDays(formatedFromDate, formatedToDate);
      } else {
        monthDiff = this._es.monthDiffOfDates(formatedFromDate, formatedToDate);
      }

      if (jobFrom !== undefined && jobFrom !== null) {
        startDate = this._es.getDateToTs(jobFrom);
        this.checkForFutureDate(startDate, 'START_DATE');
      }

      if (jobTo !== undefined && jobTo !== null) {
        endDate = this._es.getDateToTs(jobTo);
        this.checkForFutureDate(endDate, 'END_DATE');
      }
    }
    if (monthDiff < 0) {
      this.invalidDate = true;
      this.isJobPeriodError = false;
    }
    else {
      this.invalidDate = false;
      this.isJobPeriodError = true;
    }
  }

  private getCurrentDate(): any {
    let now: Date = new Date();
    let currentMonth: string = this._es.monthNames[now.getMonth()];
    let currentYear: number = now.getFullYear();
    if (this.isEmploymentGapInDaysEnabled) {
      return `${currentMonth} ${now.getDate()},${now.getFullYear()}`;
    } else {
      return `${currentMonth} ${now.getFullYear()}`;
    }
  }

  closeStartDateRangeErrorMsg() {
    this.showStartDateRangeErrorMsg = false;
  }

  closeEndDateRangeErrorMsg() {
    this.showEndDateRangeErrorMsg = false;
  }

  getStartDateError(obj) {
    this.isStartDateIsFutureDate = false;
    if (obj !== undefined && obj !== null) {
      if (obj['isInvalidDate']) {
        this.isStartDateIsFutureDate = true;
      }
    }
  }

  getEndDateError(obj) {
    this.isEndDateIsFutureDate = false;;
    if (obj !== undefined && obj !== null) {
      if (obj['isInvalidDate']) {
        this.isEndDateIsFutureDate = true;
      }
    }
  }

  checkForFutureDate(date, dateLocation) {
    let currentDate = this.getCurrentDate();
    if (currentDate !== undefined) {
      let currentDateInTS = this._es.getDateToTs(currentDate);
      if (date !== undefined) {
        let diff: number;
        if (this.isEmploymentGapInDaysEnabled) {
          diff = dateUtils.dateDiffInDays(currentDateInTS, date);
        } else {
          diff = this._es.monthDiffOfDates(currentDateInTS, date);
        }
        if (diff > 1) {
          if (dateLocation === 'START_DATE') {
            this.isStartDateIsFutureDate = true;
            this.showStartDateRangeErrorMsg = true;
          } else if (dateLocation === 'END_DATE') {
            this.isEndDateIsFutureDate = true;
            this.showEndDateRangeErrorMsg = true;
          } 
        } 
      }
    }
  }
}
