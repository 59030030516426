import { InjectionToken } from '@angular/core';

/*
 * One solution to choosing a provider token for non-class dependencies is to define and use an OpaqueToken.
 * Token - TRANSLATION
*/
export const TRANSLATIONS = new InjectionToken('translations');
// export const TRANSLATIONS_DB = new InjectionToken('translations');

// all traslations
export const dictionary = {
};

const SUPPORTED_LANGUAGES = [
/* Bulgarian 		  */	'bg',
/* Czech          */  'cs',
/* German 		    */	'de',
/* English US 	  */	'en',
/* English UK	    */	'en-gb',
/* Spanish US 	  */	'es',
/* Spanish SP     */  'es-es',
/* Spanish Latam  */  'es-419',
/* French         */	'fr',
/* French CA	    */	'fr-ca',
/* Hungarian	    */	'hu',
/* Indonesian     */  'id',
/* Italian 	  	  */	'it',
/* Japanese   	  */	'ja',
/* Korean         */  'ko',
/* Dutch          */  'nl',
/* Polish 	  	  */	'pl',
/* Portuguese  	  */	'pt',
/* Portuguese BR  */  'pt-br',
/* Romanian       */  'ro',
/* Slovak         */  'sk',
/* Swedish        */  'sv-se',
/* Thai           */  'th',
/* Turkish        */  'tr',
/* Vietnamese     */  'vi',
/* Chinese CN 	  */	'zh-cn',
/* Chinese HK     */  'zh-hk',
/* Chinese ML     */  'zh-my',
/* Chinese SG     */  'zh-sg',
/* Chinese TW     */  'zh-tw',
/* Zulu Pseudo    */  'zu',

];

// Load all bundle json files
SUPPORTED_LANGUAGES.map((lang) => {
  //  dictionary[ lang ] = require('../../assets/i18n/app/language-' + lang + '.json');
  // console.log('Loaded ', lang, 'bundle', dictionary[ lang ]);
});


// providers
export const TRANSLATION_PROVIDERS = [
  { provide: TRANSLATIONS, useValue: dictionary }
];
