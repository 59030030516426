<div   id="dpText" *ngIf="!isNativePicker">
    <input type="text" [id]="wid" class="form-control test" aria-required="true" [placeholder]="placeholder" [disabled]="isDisableField" (click)="unmaskData()" (focusout)="hideData()" (keydown)="controlMaskedTextField($event)"/>
</div>
<div class="group" *ngIf="isNativePicker">
    <div  id="dpNativeDate"  *ngIf="isDateNative">
        <input type="date" [id]="wid" [min]="minValue" aria-required="true" [max]="maxValue" [ngClass]="{paDatePick: isIOSDevice}" [disabled]="isDisableField" [value]="defaultValue" class="form-control" (focus)="unmaskData()" (blur)="hideData()"
            (input)="setDateValue($event.target.value)" (keydown)="controlMaskedTextField($event)" />
    </div>
    <div  id="dpNativeMonth" *ngIf="isMonthNative">
        <input type="month" [id]="wid" [min]="minValue" aria-required="true" [max]="maxValue" [ngClass]="{paDatePick: isIOSDevice}" [disabled]="isDisableField" [value]="defaultValue" class="form-control" (focus)="closeInvalidDateError()" (input)="setDateValue($event.target.value)" />
    </div>
    <div class="error_info" *ngIf="isInvalidDate">
        <div class="custom-error error_new_ui" >
            <div class="left_div">
                <ul>
                    <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate}}</li>
                    <li class="message">{{errorMessage | translate}}</li>
                </ul>
            </div>
            <div class="right_div">
                <div class="close" aria-hidden="true" (click)="closeInvalidDateError()">
                    <i class="icon-cancel"></i>
                </div>
            </div>
        </div>
    </div>
</div>
