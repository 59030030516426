<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12">
    <p class="black_text">{{ options['title'] }}</p>
    <hr>
  </div>
</div>
<div class="addCard m-b-10">
  <div class="row">
    <div class="col-xs-12 col-sm-4 col-md-3 dp-r-0" *ngFor="let data of options.data">
      <!--<pre>{{ data | json }}</pre>-->
      <div class="clipCard" *ngIf="!!data['from_date']" (click)="openViewProfilePopup(data,options['key'])">
        <img src="/assets/img/briefcase.png" height="30px">

        <p *ngIf="data['type'] == 'neverEmployed'">
          <b>{{ 'NEVER_EMPLOYED' | translate }}</b>
        </p>

        <div *ngIf="data['type'] != 'neverEmployed'">
          <p class="text_ellipses boldcom">
            <b *ngIf="data['name']">{{data['name']}}</b>
            <b *ngIf="!data['name']">{{data['type_description']}}</b>
          </p>

          <p class="text_ellipses m-t-5" >
            <span *ngIf="data['job-title']">{{data['job-title']}}</span>
            <span *ngIf="!data['job-title']"><br>{{data['job-title']}} </span>
          </p>

          <div>
            <span class="duration" *ngIf="!data['to_date']">{{data['from_date'] | timeStampConvert :dateFormat}} -
              {{ 'EMPLOYMENT_PRESENT' | translate}}</span>
            <span class="duration" *ngIf="!!data['to_date']">{{data['from_date'] | timeStampConvert :dateFormat}} -
              {{data['to_date'] | timeStampConvert :dateFormat}}</span> 
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
