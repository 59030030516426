<div class="body-main">
  <!-- <pre>
  {{ profileCollection | json}}
  </pre>  -->
  <div class="container mobile-first">
    <div class="body-bg custompadding">
      <div class="tab-navigation1">
        <div class="mobile_container">
          <div>
            <profile-header [userFirstName]="firstName"></profile-header>
          </div>
          <div class="row m-b-20">
            <profile-communication></profile-communication>
          </div>
          <!--   <pre>{{ profileCollection | json }}</pre> -->
          <div *ngFor="let profile of profileCollection">
            <!--<pre *ngIf="profile.key == 'alias-data-list'">{{ profile| json }}</pre>
                      <pre *ngIf="profile.key == 'address-data-list'">{{ profile| json }}</pre>
                      <pre *ngIf="profile.key == 'name-data'">{{ profile| json }}</pre>-->
            <!-- <pre *ngIf="profile.key == 'name-data'">{{ profile | json }}</pre>
                      <pre *ngIf="profile.key == 'name-data'">Profile data - {{ profile.data | json }}</pre> -->
            <!--  <pre *ngIf="profile.key == 'forms'">Profile data - {{ profile.data | json }}</pre> -->
            <div>
              <div [ngSwitch]="profile.key">
                <profile-personal *ngSwitchCase="profile.data != null && profile.data.length > 0 && 'spi-data'"
                  (onViewProfile)="viewProfile($event)" [options]="profile"></profile-personal>
                <profile-requests *ngSwitchCase="profile.data != null && profile.data.length > 0 && 'request'"
                  [options]="profile"></profile-requests>
                <profile-address *ngSwitchCase="profile.data != null && profile.data.length > 0 && 'address-data-list'"
                  (onViewProfile)="viewProfile($event)" [options]="profile"></profile-address>
                <profile-employment
                  *ngSwitchCase="profile.data != null && profile.data.length > 0 && 'employment-data-list' "
                  (onViewProfile)="viewProfile($event)" [options]="profile"></profile-employment>
                <profile-driver-license
                  *ngSwitchCase="profile.data != null && profile.data.length > 0 && 'mvr-data-list' "
                  (onViewProfile)="viewProfile($event)" [options]="profile"></profile-driver-license>
                <profile-education
                  *ngSwitchCase="profile.data != null && profile.data.length > 0 && 'education-data-list'"
                  (onViewProfile)="viewProfile($event)" [options]="profile"></profile-education>
                <profile-references
                  *ngSwitchCase="profile.data != null && profile.data.length > 0 && 'reference-data-list'"
                  (onViewProfile)="viewProfile($event)" [options]="profile"></profile-references>
                <profile-license *ngSwitchCase="profile.data != null && profile.data.length > 0 && 'licence-data-list'"
                  (onViewProfile)="viewProfile($event)" [options]="profile"></profile-license>
                <profile-criminal
                  *ngSwitchCase="profile.data != null && profile.data.length > 0 && 'criminal-data-list'"
                  (onViewProfile)="viewProfile($event)" [options]="profile"></profile-criminal>
                <!--Added Criminal Component PPA6211-->
                <profile-documents *ngSwitchCase="profile.data != null && profile.data.length > 0 && 'document'"
                  [options]="profile" (pdfUrlEmit)="downloadAction($event)"></profile-documents>
                <profile-name *ngSwitchCase="profile.data != null && 'name-data'" (onViewProfile)="viewProfile($event)"
                  [contactNumber]="contactNumber" [gender]="gender" [options1]="profile"></profile-name>
                <profile-alias *ngSwitchCase="profile.data != null && profile.data.length > 0 && 'alias-data-list'"
                  (onViewProfile)="viewProfile($event)" [options]="profile"></profile-alias>
                <profile-intl *ngSwitchCase="profile.data != null && profile.data.length > 0 && 'intl-addl-field-list'"
                  (onViewProfile)="viewProfile($event)" [options]="profile"></profile-intl>
                <profile-intl *ngSwitchCase="profile.data != null && profile.data.length > 0 && 'forms'"
                  (onViewProfile)="viewProfile($event)" [options]="profile"></profile-intl>
                <profile-notification-requests *ngSwitchCase="profile.data != null && profile.data.length > 0 && 'notifications'"
                    [options]="profile"  (onViewNotificationInviteType)="onViewNotificationInviteType($event)"></profile-notification-requests>
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
      <!--Tab Navigation Slider End-->
      <div class="clearfix"></div>
    </div>
  </div>
  <!--Body Container-->
</div>

<div class="modal fade custom_modal" id="pdfmodal" role="dialog" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content contetnheight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="modalClose()">&times;</button>
      </div>
      <div class="transparentbg modal-body">
        <div class="row" [class.hide]="isIframe">
          <div class="col-md-4 col-sm-4 col-xs-4 text-center viewReportArrowAndPageNum">
            <button type="button" id="prev" style="margin-left:10px" class="btn arrowleftfl" (click)="onPrevPage()"><i
                class="icon-arrow-left"></i>
              <span class="pdfArrow" style="top:-5px; position: relative;"> {{'COMMONS_LOWER_PREVIOUS' | translate }}
              </span></button>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-4 text-center viewReportArrowAndPageNum">
            <span>{{'DASHBOARD_PAGE_LABEL' | translate }}: <span id="page_num"></span> / <span
                id="page_count"></span></span>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-4 text-center viewReportArrowAndPageNum">
            <button id="next" type="button" class="btn arrowrightfl" style="margin-right:10px" (click)="onNextPage()">
              <span class="pdfArrow" style="top:-5px; position: relative;"> {{'COMMONS_LOWER_NEXT' | translate }}</span>
              <i class="icon-arrow-right"></i></button>
          </div>
        </div>
        <div style="text-align: center;">
          <div [class.hide]="!isIframe">
            <iframe id="profilePdfView" style="width:100%; height:600px;" frameborder="0"></iframe>
          </div>
          <div [class.hide]="isIframe" style="height:425px;overflow:auto; width:100%">
            <canvas id="the-canvas" frameborder="0"></canvas>
          </div>
        </div>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>

<div class="modal fade dashview custom_modal" id="viewProfile" role="dialog" data-backdrop="static"
  data-keyboard="false">
  <div class="modal-dialog sign-modal ">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="modalClose()">&times;</button>
      </div>
      <div class="transparentbg modal-body" *ngIf="isViewProfile">
        <div [ngSwitch]="viewProfileData['key']">
          <view-profile-personal *ngSwitchCase="'spi-data'" [viewProfileData]="viewProfileData"></view-profile-personal>
          <view-profile-address *ngSwitchCase="'address-data-list'" [viewProfileData]="viewProfileData">
          </view-profile-address>
          <view-profile-employment *ngSwitchCase="'employment-data-list'" [viewProfileData]="viewProfileData">
          </view-profile-employment>
          <view-profile-education *ngSwitchCase="'education-data-list'" [viewProfileData]="viewProfileData">
          </view-profile-education>
          <view-profile-driver-license *ngSwitchCase="'mvr-data-list'" [viewProfileData]="viewProfileData">
          </view-profile-driver-license>
          <view-profile-license *ngSwitchCase="'licence-data-list'" [viewProfileData]="viewProfileData">
          </view-profile-license>
          <view-profile-criminal *ngSwitchCase="'criminal-data-list'" [viewProfileData]="viewProfileData">
          </view-profile-criminal> <!-- PPA6211-->
          <view-profile-references *ngSwitchCase="'reference-data-list'" [viewProfileData]="viewProfileData">
          </view-profile-references>
          <view-profile-name *ngSwitchCase="'name-data'" [viewProfileData]="viewProfileData"></view-profile-name>
          <view-profile-alias *ngSwitchCase="'alias-data-list'" [viewProfileData]="viewProfileData">
          </view-profile-alias>
          <view-profile-intl *ngSwitchCase="'intl-addl-field-list'" [viewProfileData]="viewProfileData">
          </view-profile-intl>
          <view-profile-intl *ngSwitchCase="'forms'" [viewProfileData]="viewProfileData"></view-profile-intl>
          <!--
                  <profile-documents *ngSwitchCase="profile.data.length > 0 && 'document'" [options]="profile" (pdfUrlEmit)="displayPdf($event)"></profile-documents>-->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade custom_modal" id="plsWaitAMoment" tabindex="-1" role="Application" aria-labelledby="profileWaitStatus" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
        <!--<button type="button" class="close" data-dismiss="modal" (click)="modalClose()">&times;</button>-->
      </div>
      <div class="transparentbg modal-body">
        <h4 class="modal-title m-t-10" id="profileWaitStatus">{{'WAIT_ON_AGGREMENT_MODAL_TEXT' | translate }}</h4>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>

<div class="modal fade custom_modal" id="noPdfFoundModal" role="dialog" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="noPdfModalClose()">&times;</button>
      </div>
      <div class="transparentbg modal-body">
        <h4 class="modal-title m-t-10">{{'DASHBOARD_PDF_ERROR' | translate }}</h4>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>


<div class="modal fade custom_modal" id="errorModal" role="dialog" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="errorModalClose()">&times;</button>
      </div>
      <div class="transparentbg modal-body">
        <h4 class="modal-title m-t-10">{{'DASHBOARD_NOTIFICATION_ERROR_MODAL' | translate }}</h4>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>

<div class="modal fade custom_modal" id="wait_for_pdf_modal" tabindex="-1" role="Application" aria-labelledby="profileWaitforpdfStatus" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
        <!--<button type="button" class="close" data-dismiss="modal" (click)="modalClose()">&times;</button>-->
      </div>
      <div class="transparentbg modal-body">
        <h4 class="modal-title m-t-10" id="profileWaitforpdfStatus">{{'WAIT_ON_AGGREMENT_MODAL_TEXT' | translate }}</h4>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>
