// Import the core angular services.
import { ErrorHandler } from "@angular/core";
import { forwardRef } from "@angular/core";
import { Inject } from "@angular/core";
import { Injectable } from "@angular/core";

// Import the application components and services.
import { ExceptionService } from "./exception.service";

export class PAErrorHandlerOptions {
    rethrowError?: boolean;
    unwrapError?: boolean;
}

export var LOGGING_ERROR_HANDLER_OPTIONS: PAErrorHandlerOptions = {
    rethrowError: false,
    unwrapError: false
};

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    //private errorLogService: ExceptionService;
    private options: PAErrorHandlerOptions;

    constructor(
        private errorLogService: ExceptionService,
        // @Inject(LOGGING_ERROR_HANDLER_OPTIONS) private options: PAErrorHandlerOptions
    ) {

        this.errorLogService = errorLogService;
        this.options = LOGGING_ERROR_HANDLER_OPTIONS;
    }

    /*
     * Default implementation for now logs the error but it can be modified to suit
     * any scenario.
    */

    public handleError(error: any): void {
        // Log to the console.
        try {
            if(error !== undefined){
                console.group("ErrorHandler");
                console.error(error.message);
                console.error(error.stack);
                console.groupEnd();
            }

        } catch (handlingError) {
            console.group("ErrorHandler");
            console.warn("Error when trying to output error.");
            console.error(handlingError);
            console.groupEnd();
        }

        // Send to the error-logging service.
        try {
            if(error !== undefined){
                this.options.unwrapError
                ? this.errorLogService.logError('', this.findOriginalError(error))
                : this.errorLogService.logError('', error);
            }
        } catch (loggingError) {
            console.group("ErrorHandler");
            console.warn("Error when trying to log error to", this.errorLogService);
            console.error(loggingError);
            console.groupEnd();
        }

        if (this.options.rethrowError) {
            throw (error);
        }
    }

    private findOriginalError(error: any): any {
        while (error !== undefined && error && error.originalError) {
            error = error.originalError;
        }
        return (error);
    }
}

export var LOGGING_ERROR_HANDLER_PROVIDERS = [
    
];
