import { Component, Input, OnInit, ViewEncapsulation, AfterViewInit, Renderer2, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs';
import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/fieldconfig.interface';
import { SharedService } from '../../../../app/shared/services/shared.service';
import { TranslateService, TRANSLATION_PROVIDERS, TranslatePipe } from 'framework/i18n';
import { PhoneConfig } from './phone.config';
declare var google: any;
declare var jQuery: any;

@Component({
    selector: 'phone',
    templateUrl: './phone.component.html',
    providers: [],
    styles: [
        `input[type=number]::-webkit-inner-spin-button,
            input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
            }`
    ],
    encapsulation: ViewEncapsulation.None,
})

export class PhoneComponent implements OnInit, AfterViewInit, Field {
    config: FieldConfig;
    group: FormGroup;

    @Input() row: any;
    @Input() options: any;
    @Input() referencename: any;
    @Input() configOptions: any;

    isPhoneNoValid;
    isPhoneNoError: boolean = false;
    enableNextButton: boolean = false;
    errMsg: string;
    isViewInit;
    countrycodelist: any;
    isNumberList: boolean = true;
    codeErrorMsg: boolean = false;
    countryVal: any;
    country: any;
    mobileVal: any;
    keys: any;
    countryCodeControl: any;
    phoneNoControl: any;
    validCountryCode = false;
    phoneNoValue: Subject<any> = new Subject();
    isKyp = false;
    isRequired: boolean = false;
    minLength: any;
    maxLength: any;
    regex: any;

    constructor(
        private _renderer: Renderer2,
        private shareService: SharedService,
        private _cdr: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.isKyp = this.shareService.checkInviteIsKYP();
        this.isPhoneNoValid = true;
        this.mobileVal = '';
        this.countryVal = '';
        this.country = '';
        console.log('FORMGROUP@@@@@@@@@@@@' + this.group);
        this.ValidatePhoneData(this.config);
    }

    ngAfterViewInit(): void {
        console.log('----------- ngAfterViewInit -----------------');
        this.isPhoneNoValid = true;
        this.isViewInit = true;
        this.keys = this.config['keys'];
        if (!!this.keys) {
            this.countryCodeControl = this.keys[0];
            this.phoneNoControl = this.keys[1];
            let validationData = null;
        
        if (!!this.config && !!this.config['validation_data']) {
            validationData = this.config['validation_data'];
            if (validationData['is_required'] !== null && validationData['is_required'] !== undefined)
                this.isRequired = validationData['is_required'];
            
            if(validationData['validation'] === "REQUIRED")
                this.isRequired = true;

            if (validationData['max_length'] !== null && validationData['max_length'] !== undefined)
                this.maxLength = validationData['max_length'];

            if (validationData['min_length'] !== null && validationData['min_length'] !== undefined)
                this.minLength = validationData['min_length'];

            if (validationData['regex'] !== null && validationData['regex'] !== undefined)
                this.regex = validationData['regex'];
        }
        }
        
        this._cdr.detectChanges();
    }

    ngAfterViewChecked(){
        if (this.isPhoneNoValid) {
            this.group.get(this.countryCodeControl).setErrors(null);
            this.group.get(this.phoneNoControl).setErrors(null);
        }
     }

    validatePhoneNumber(val) {
        let validPhoneNo = false;
        if (val === null) {
            val = '';
        }
        let phoneNo = val.toString();

        if (phoneNo.toString().length >= 8 && phoneNo.toString().length <= 12) {
            this.isPhoneNoValid = true;
            this.isPhoneNoError = true;
            validPhoneNo = true;
        } else {
            this.isPhoneNoValid = false;
            this.isPhoneNoError = false;
        }

        if (validPhoneNo) {
            this.group.get(this.countryCodeControl).setErrors(null);
            this.group.get(this.phoneNoControl).setErrors(null);
        }
        return validPhoneNo;
    }

    closePhoneNoError() {
        this.isPhoneNoError = true;
    }

    openPhoneNoError() {
        this.isPhoneNoError = false;
        this.isPhoneNoValid = false;
    }

    keyupCountryCode(event) {
        let data = event.target.value;
        this._renderer.setStyle(event.target, 'gray80', true);

        if (data.length > 0) {
            this.country = '';
            let body = data;
            this.shareService.lookUpServicemobilenumber(body).subscribe(response => {
                let serverzipdata = response;
                this.countrycodelist = serverzipdata['isd-code-list'];
                if (this.countrycodelist.length > 0) {
                    this.codeErrorMsg = false;
                    this.enableNextButton = true;
                    this.validCountryCode = false;
                } else {
                    this.codeErrorMsg = true;
                    this.enableNextButton = false;
                    this.countryVal = '';
                    this.validCountryCode = true;
                    this.group.get(this.countryCodeControl).setValue('');
                    this.group.get(this.phoneNoControl).setValue('');
                    if (!this.isRequired) {
                        this.codeErrorMsg = false;
                        this.enableNextButton = true;
                        this.validCountryCode = false;
                        this.group.get(this.countryCodeControl).setErrors(null);
                        this.group.get(this.phoneNoControl).setErrors(null);
                    }
                    
                }
                this.isNumberList = false;

            },
                error => {
                });

        } else if (data.length === 0) {
            this.isNumberList = true;
            this.codeErrorMsg = true;
            this.enableNextButton = false;
            this.countryVal = '';
            this.validCountryCode = true;
            this.group.get(this.countryCodeControl).setValue('');
            this.group.get(this.phoneNoControl).setValue('');
            if (!this.isRequired) {
                this.codeErrorMsg = false;
                this.enableNextButton = true;
                this.validCountryCode = false;
                this.group.get(this.countryCodeControl).setErrors(null);
                this.group.get(this.phoneNoControl).setErrors(null);
            }
        }
    }

    closeCodeError() {
        this.codeErrorMsg = false;
    }

    keyupnumber(phoneVal) {
        phoneVal.preventDefault();
        phoneVal.stopPropagation();
        // let debounceTime = PhoneConfig.debounceRate;
        // setTimeout(() => { this.setPhoneNumberVal(phoneVal.target.value)}, debounceTime);
        let input = phoneVal.target.value;
        // let phoneRegex = new RegExp('^(?=.*[0-9])[- ()0-9]+$');

        let phoneRegex = new RegExp(!!this.regex ? this.regex : '^[0-9]*$');

        if (!this.isRequired) {
            this.setPhoneNumberVal(phoneVal.target.value)
        }

        if (this.isRequired !== null && this.isRequired !== undefined) { // overrid validation if its part of config
            if ( this.isRequired && !input) {
                this.isPhoneNoValid = false;
                this.errMsg = 'REFERENCE_PHONE_NUMBER_ERROR';
                this.group.get(this.phoneNoControl).setErrors({ 'incorrect': true });
                return;
            }
        } else if (input == '' || input === undefined) { // here onwards, its existing validation
            this.isPhoneNoValid = false;
            this.errMsg = 'REFERENCE_PHONE_NUMBER_ERROR';
            this.group.get(this.phoneNoControl).setErrors({ 'incorrect': true });
            return;
        } else if (input !== undefined && !phoneRegex.test(input)) { //validate for phone number format on blur
            this.isPhoneNoError = false;
            this.isPhoneNoValid = false;
            this.errMsg = 'REFERENCE_NUMBER_ERROR_1';
            return;
        }

        if (!!input && !!this.minLength) {
            if (input.length < this.minLength) {
                this.isPhoneNoError = false;
                this.isPhoneNoValid = false;
                if (this.isRequired) {
                    this.errMsg = 'REFERENCE_PHONE_NUMBER_ERROR';
                    this.group.get(this.phoneNoControl).setErrors({ 'incorrect': true });
                }
                return;
            } 
        }

        this.isPhoneNoValid = true;
        this.setPhoneNumberVal(phoneVal.target.value)

        //Logic to ensure +1 ISD code ensures phonenumber length is 10.
        if (this.group.get(this.countryCodeControl).value !== undefined) {
            console.log("ISD Code : " + this.group.get(this.countryCodeControl).value);

            if (this.group.get(this.countryCodeControl).value === '+1') {
                if (input.length != 10) {
                    this.isPhoneNoError = false;
                    this.isPhoneNoValid = false;
                    this.errMsg = 'REFERENCE_NUMBER_ERROR_1';
                    this.group.get(this.phoneNoControl).setErrors({ 'incorrect': true });
                    return;
                }
            } else {
                if (!!this.maxLength) {
                    if (input.length > this.maxLength) {
                        this.isPhoneNoError = false;
                        this.isPhoneNoValid = false;
                        this.errMsg = 'REFERENCE_PHONE_NUMBER_ERROR';
                        this.group.get(this.phoneNoControl).setErrors({ 'incorrect': true });
                        return;
                    }

                } else if (input.length > 15) {
                    this.isPhoneNoError = false;
                    this.isPhoneNoValid = false;
                    this.errMsg = 'REFERENCE_PHONE_NUMBER_ERROR';
                    this.group.get(this.phoneNoControl).setErrors({ 'incorrect': true });
                    return;
                }
            }
        }
    }

    enterCityInput(data) {
        let code;
        if (!!data && !!data.code) {
            code = this.AddValidCountryCode(data.code);
        } else {
            code = this.AddValidCountryCode(data);
        }
        this.country = code;
        let validCountryCode = this.ValidateCountryCode(this.country);
        if (this.group.contains(this.countryCodeControl) && !validCountryCode) {
            this.group.get(this.countryCodeControl).setValue(code);
            this.codeErrorMsg = false;
            this.validCountryCode = false;
        }
        this.isNumberList = true;
    }
    ValidatePhoneData(config: any) {
        if (!!this.config && !!this.config['keys']) {
            this.keys = this.config['keys'];
            let value = this.config['value'];
            let type = this.config['type'].toLowerCase();
            if (type === 'phone') {
                this.countryCodeControl = this.keys[0];
                this.phoneNoControl = this.keys[1];
            }
            if (!!value && value !== '' && value !== undefined) {
                let splitval = value.split(' ');
                this.group.get(this.countryCodeControl).setValue(splitval[0]);
                this.group.get(this.countryCodeControl).setErrors(null);
                this.group.get(this.phoneNoControl).setValue(splitval[1]);
                this.validatePhoneNumber(splitval[1]);
            }
        }
    }

    /**
     *
     * @param countryCode  -- Validates the country code value
     */
    ValidateCountryCode(countryCode: any) {
        let invalidCountryCode = false;
        let regex1 = /^([+]\d+(\.\d+)?)/;
        if (regex1.test(countryCode) === false || countryCode === undefined) {
            this.countryVal = '';
            this.codeErrorMsg = true;
            invalidCountryCode = true;
            this.validCountryCode = true;
            this.group.get(this.countryCodeControl).setValue('');
        } else {
            this.codeErrorMsg = false;
            invalidCountryCode = false;
            this.validCountryCode = false;
        }
        return invalidCountryCode;
    }
    AddValidCountryCode(data) {
        let regex = /^[0-9]*$/;
        if (regex.test(data) === true) {
            let str1 = '+';
            data = str1.concat(data);
        }
        return data;
    }
    /**
     *
     * @param phoneVal -- Takes the value and sets the value of phone no in the control
     */
    setPhoneNumberVal(phoneVal: any) {
        let val = this.AddValidCountryCode(phoneVal);

        if (this.group.contains(this.phoneNoControl)) {
            this.group.get(this.phoneNoControl).setValue(phoneVal);
        }
        let countryCodeFlag = this.ValidateCountryCode(this.group.get(this.countryCodeControl).value);
        if (!countryCodeFlag) {
            let phoneNo = this.group.get(this.countryCodeControl).value + ' ' + this.group.get(this.phoneNoControl).value;
            this.group.get(this.config['name']).setValue(phoneNo);
            this.isNumberList = true;
            this.group.get(this.countryCodeControl).setErrors(null);
        } else {
            this.group.get(this.config['name']).setValue('');
        }
    }

    validatePhoneData() {
        if (this.isRequired === false) {
            const countryCode = this.group.get(this.countryCodeControl).value;
            const phone = this.group.get(this.phoneNoControl).value;
            if (!phone && !countryCode) {
                this.group.get(this.config['name']).setValue('');
                this.group.get(this.config['name']).setErrors(null);
                this.group.get(this.countryCodeControl).setErrors(null);
                this.group.get(this.phoneNoControl).setErrors(null);
            } else if (!countryCode) {
                this.codeErrorMsg = true;
                this.enableNextButton = false;
                this.countryVal = '';
                this.validCountryCode = true;
                this.group.get(this.countryCodeControl).setErrors({ 'incorrect': true });
            } else  {
                this.errMsg = 'REFERENCE_NUMBER_ERROR_1';
                this.group.get(this.phoneNoControl).setErrors({ 'incorrect': true });
            }
        }

        if(this.isRequired === true){
            const countryCode = this.group.get(this.countryCodeControl).value;
            const phone = this.group.get(this.phoneNoControl).value;
            if (!phone || !countryCode) {
                this.group.get(this.config['name']).setValue('');
            }
        }
    }
}
