<div class="pad_30" [formGroup]="empSubForm" (onGesture)="doSwipe($event,empSubForm.value)" hammer-gestures> 
  <button type="button" class="btn arrowleftctr" attr.data-id="empDataProviderLeftArrow_{{row}}" debounceDirective
    (debounceClick)="getPrevSwipe(empSubForm.value)"><i class="icon-arrow-left" role="img"
        [attr.aria-label]="'SCREEN_BACKWARD_ARROW' | translate"></i></button>
  <div class="anime-clean contetnheight posrel">
    <div class="row" *ngIf="!showBankProvider && !inputCitadelPreferenceEnabled">
      <div class="col-md-12 col-xs-12 col-sm-12">
        <div class="header_txt_white">
          <h3>
            {{ 'EMP_DATA_NO_DATA_PROVIDER_HEADER' | translate }} 
          </h3>
        </div>
      </div>
    </div>

    <div *ngIf="!showCitadelData && !showPlaidData">
      <div class="row" *ngIf="showBankProvider || inputCitadelPreferenceEnabled">
        <div class="col-md-12 col-xs-12 col-sm-12">
          <div class="header_txt_white"> <!-- check gap -->
            <h4>
              {{ 'EMP_DATA_PROVIDER_HEADER_1' | translate }}
              <b> {{ 'EMP_DATA_PROVIDER_HEADER_2' | translate }}</b>
              {{ 'EMP_DATA_PROVIDER_HEADER_3' | translate }}
              <span (click)="openInfoModal()"><i class="fa fa-info-circle"></i></span>
            </h4>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="showBankProvider || inputCitadelPreferenceEnabled">
        <div class="col-xs-12 col-sm-12 col-md-12 custom_ul_li">
          <ul>
            <li>
              <span class="greenCheckIcon">
                <i class="glyphicon glyphicon-ok"></i>
              </span>
              <span>{{ 'EMP_DATA_PROVIDER_LABEL_1' | translate }}</span>
            </li>
            <li>
              <span class="greenCheckIcon">
                <i class="glyphicon glyphicon-ok"></i>
              </span>
              <span>{{ 'EMP_DATA_PROVIDER_LABEL_2' | translate }}</span>
            </li>
            <li>
              <span class="greenCheckIcon">
                <i class="glyphicon glyphicon-ok"></i>
              </span>
              <span>{{ 'EMP_DATA_PROVIDER_LABEL_3' | translate }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="row m-b-20">
        <div class="col-xs-12 col-sm-12 col-md-12" *ngIf="showBankProvider">        
          <label class="radio-inline radioLabelTextColor customRadioButton">
            <input type="radio" name="empDataProvider" formControlName="empDataProvider" value='BANK'>
            <div class="radioHeaderTextColor"> {{ 'EMP_DATA_PROVIDER_BANK' | translate }}
            </div>
            <div class="radioSubHeaderTextColor">{{ 'LABEL_RECOMMENDED' | translate }}</div>
          </label>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12" *ngIf="inputCitadelPreferenceEnabled">        
          <label class="radio-inline radioLabelTextColor customRadioButton">
            <input type="radio" name="empDataProvider" formControlName="empDataProvider" value='PAYROLL'>
            <div class="radioHeaderTextColor"> {{ 'EMP_DATA_PROVIDER_PAYROLL' | translate }}
            </div>
            <div class="radioSubHeaderTextColor">{{ 'LABEL_RECOMMENDED' | translate }}</div>
          </label>
        </div>
      </div>      
    </div>

    <div *ngIf="showCitadelData || showPlaidData" class="row m-t-40">
      <div id="container" class="m-b-20">
        <div class="contents">
          <div class="inlinecardcss adjustCard">
            <div class="addCard">
              <div class="dashboardCard maarright15 atsselect">
                <img src="../../assets/img/briefcase.png" height="50px">
                <p class=" boldcom boldtext_ellipses">{{empSubForm.controls.companyName.value}}</p>
                <span class="boldcom margtop10" *ngIf="showCitadelData">{{empSubForm.controls.jobTitle.value}}</span>
                <p class="duration m-b-10">{{empSubForm.controls.jobFrom.value | timeStampConvert :dateFormat }} - {{
                  'EMPLOYMENT_PRESENT' | translate}} </p>  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row fixedbtn">
      <div class="col-xs-12 col-sm-3 col-md-3 btnpd fixedbtnb" *ngIf="showBankProvider || inputCitadelPreferenceEnabled">
        <button type="button" class="btn button_wbg font_extrabold grow button_wbgSav" [disabled]="!empSubForm.controls.empDataProvider.valid"
                attr.data-id="empDataProviderEnterManually_{{row}}"
                debounceDirective (debounceClick)='onNextClick(empSubForm.value)'>{{ 'COMMONS_NEXT' | translate }}</button>
      </div>
      <div class="col-xs-12 col-sm-3 col-md-3 btnpd fixedbtnb ">
        <button type="button" class="btn button_wbg font_extrabold  grow button_wbgSav"
                attr.data-id="empDataProviderSaveExit_{{row}}"
                debounceDirective (debounceClick)='saveExit(empSubForm.value)'>{{ 'COMMONS_SAVE_AND_EXIT' | translate }}</button>
      </div>
      <div  class="col-xs-12 col-sm-3 col-md-3 btnpd fixedbtnb" *ngIf="!showCitadelData && !showPlaidData">
        <button type="button" class="btn button  font_extrabold sendButton1"
                attr.data-id="empDataProviderBackToReview_{{row}}"
                debounceDirective (debounceClick)="providerOptOut(empSubForm.value)">{{ 'EMP_CITADEL_OPT_OUT_BTN' | translate }}</button>
      </div>
    </div>    
    <div class="clearfix"></div>    
  </div>
  <div class="row m-b-20">
    <div class="col-xs-12 col-sm-12 col-md-12">
      <span [innerHTML]="'EMP_DATA_PROVIDER_FOOTER_NOTE' | translate"></span>
    </div>
  </div>
</div>