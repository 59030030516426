import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

/***
 * new implementation which has ability to resolve dynamically errors
 * from underlying form control. It works with validators.
 *
 * supports automatic translation or ability to override error message.
 * */
@Component({
  selector: 'app-field-error',
  templateUrl: './field-error.component.html'
})
export class FieldErrorComponent implements OnInit {

  tooltipEnabled: boolean = false;

  constructor() {
    // intentional
  }

  @Input() control: AbstractControl;
  @Input() fieldNameKey?: string;
  @Input() patternKey?: string;
  /** overrides automatic message composition, with predefined translation */
  @Input() messageTranslationKey?: string;
  @Input() debug: boolean = false;
  @Input() composeGeneric: 'SIMPLE' | 'ADVANCED' | 'RULE';
  @Input() requireTouchOrDirty: boolean = true;

  /* automatically resolved from underlying form control */
  controlName: string;

  ngOnInit(): void {
    if (!this.control) {
      console.warn('control is not defined');
      return;
    }

    this.controlName = Object.keys(this.control.parent.controls).find(
      name => this.control === this.control.parent.get(name));

    if (!this.fieldNameKey && !this.patternKey && !this.messageTranslationKey && this?.composeGeneric !== 'RULE') {
      console.warn('Error message cannot be generated, please initialize field-error control:', this.controlName);
      this.debug = true;
    }
  }

  isShowError() {
    if (this.requireTouchOrDirty) {
      return !!this.control?.errors && (this.control?.dirty || this.control?.touched);
    }

    return !!this.control?.errors;
  }

  toggleErrorTooltip() {
    this.tooltipEnabled = !this.tooltipEnabled;
  }

  isShowTooltip() {
    if (!this.control.errors) {
      this.tooltipEnabled = false;
    }
    
    return this.tooltipEnabled;
  }

}
