import { Component, OnInit, Input, Output, EventEmitter, Renderer2, AfterViewInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { WorkflowService } from './../../../../framework/workflow.service';
import { TRANSLATION_PROVIDERS, TranslatePipe, TranslateService } from '.../../framework/i18n';

// State
import { Store } from '@ngrx/store';
import * as ACTIONS_INTERFACE from './../../../actions';

declare var $: any;
declare var window: any;
declare var Webcam: any;
let jsPDF = require('jspdf');

@Component({
  selector: 'camera',
  templateUrl: './camera.component.html'
})

export class CameraComponent implements OnInit, OnChanges, OnDestroy {
  @Input() inputCamera: any;
  @Input() inputTakePic: boolean;
  @Input() inputNextClick: boolean;
  @Input() resetList: any;
  @Output() imageListEmit: EventEmitter<any> = new EventEmitter();
  @Output() toggleCamNextButton: EventEmitter<any> = new EventEmitter();
  @Output() cameraError: EventEmitter<any> = new EventEmitter();

  imagesList: any[] = [];
  order: any[] = [];
  temp: any[] = [];

  public SUB_STEP_ID: string;
  public SUB_STEP_INDEX: number;

  isMobileDevice: boolean = false;
  imageSrc: string = '';
  loaded: boolean = false;
  payLoad: Object;
  currentStatus: string = 'VISITED';
  startTime: any;
  PARENT_STAGE_STEP_CONFIG: Object;
  currentIteration: string = '';
  imgFileCount: number = 0;
  isCameraError: boolean = false;

  constructor(private _store: Store<ACTIONS_INTERFACE.AppState>,
    private _workflow: WorkflowService) {
    this.startTime = new Date().getTime();
  }

  ngOnInit() {
    this.checkMobileDevice();
  }

  ngAfterViewInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    this.checkMobileDevice();

    if (changes['inputCamera'] !== undefined && changes['inputCamera'].currentValue !== undefined) {
      if (!this.isMobileDevice) {
        this.initializeCamera();
      }
    }
    if (changes['inputNextClick'] !== undefined && changes['inputNextClick'].currentValue !== undefined) {
      this.imageListEmit.emit({ files: this.imagesList });
    }
    if (changes['resetList'] !== undefined && changes['resetList'].currentValue !== undefined) {
      this.imgFileCount = 0;
      this.imagesList = [];
      Webcam.reset();
      this.toggleCamNextButton.emit({ val: false });
    }
  }

  // This method for Re-arrange Documents
  orderChangeForCamera(orderData) {
    this.temp = [];
    let i = 0;
    for (let key of orderData) {
      this.temp[i] = this.imagesList[key];
      i = i + 1;
    }

    // update Re-arrange & Emitting Array
    this.imagesList = [...this.temp];
    this.order = [...this.temp];
  }

  /* Camera Initialization - Starts */
  initializeCamera() {
    let that = this;
    Webcam.set({
      width: 300,
      height: 200,
      image_format: 'jpeg',
      jpeg_quality: 90,
      enable_flash: false
    });


    Webcam.on('error', function (error) {
      that.isCameraError = true;
      console.log('WebcameJS directive ERROR: ', error);
      that.cameraError.emit({ val: false });
    });

    Webcam.attach('#test_camera');

    if (!this.isCameraError) {
      /*
      * Register WebcamJS events
      */
      Webcam.on('load', function () {
        console.log('library loaded');
      });

      Webcam.on('live', function () {
        console.log('camera live');
      });

      this.cameraError.emit({ val: true });
    }
  }

  checkIfInputDevicesAvailable(): any {
    // Casting to <any> required
    let dev = <any>navigator;
    return !!(dev.getUserMedia || dev.webkitGetUserMedia ||
      dev.mozGetUserMedia || dev.msGetUserMedia);
  }

  checkMobileDevice() {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/.test(userAgent) && !window.MSStream) {
      this.isMobileDevice = true;
    }
  }

  takePic() {
    if (!this.isMobileDevice) {
      this.takeSnapShot();
    } else {
      this.takePictureDevice();
    }

    this.toggleCamNextButton.emit({ val: true });
  }

  takePictureDevice() {
    $('#takePictureField').trigger('click');
  }

  takeSnapShot() {
    let imageData = {};
    let that = this;

    // Take snapshot and get image data
    Webcam.snap(function (data_uri) {
      // Preview image in page
      let imgId = '#preview-' + that.imgFileCount;
      let img = that.convertDataURLtoFile(data_uri, imgId);

      imageData = img;
      imageData['id'] = imgId;
      imageData['src'] = data_uri;

      that.imagesList.push(imageData);
    });

    imageData = {};
    that.imgFileCount = that.imgFileCount + 1;

    // update Re-arrange Array
    this.order = [...that.imagesList];
    this.toggleCamNextButton.emit({ val: true });
  }

  _handleReaderLoaded(e) {
    let imageData = {};
    let reader = e.target;
    this.imageSrc = reader.result;
    this.loaded = true;

    // console.log('Reader result - ' + reader.result);
    let imgId = '#preview-' + this.imgFileCount;
    let img = this.convertDataURLtoFile(reader.result, imgId);

    // console.log('Generated image - ' + img);
    imageData = img;
    imageData['id'] = imgId;
    imageData['src'] = reader.result;
    // console.log('Imge file - ' + imageData);
    this.imagesList.push(imageData);
    imageData = {};
    this.imgFileCount = this.imgFileCount + 1;

    // update Re-arrange Array
    this.order = [...this.imagesList];
  }

  handleInputChange(e) {
    let file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    let pattern = /image-*/;
    let reader = new FileReader();

    if (file === undefined) {
      return;
    }

    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }

    this.loaded = false;

    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);

    this.toggleCamNextButton.emit({ val: true });
  }

  convertDataURLtoFile(dataurl, filename): any {
    let binary = atob(dataurl.split(',')[1]);
    let array = [];

    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }

    let theBlob: any = new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
    theBlob.name = filename + '.jpg';
    theBlob.lastModified = Date.now();

    return <File>theBlob;
  }

  // convertBase64ToPdf(data_uri, file_name): any {
  //   let doc = new jsPDF();

  //   doc.addImage(data_uri, 'JPEG', 5, 5, 160, 120);

  //   let pdf = doc.output('blob');
  //   let file = new File([pdf], file_name + '.pdf', { type: 'application/pdf', lastModified: Date.now() });

  //   return file;
  // }

  onDeleteImg(remoteId) {
    // console.log(remoteId);
    let index;

    for (let ind in this.imagesList) {
      if (this.imagesList[ind]['id'] === remoteId) {
        index = ind;
      }
    }
    this.imagesList.splice(index, 1);
    // console.log(this.imagesList);

    // update Re-arrange Array
    this.order = [...this.imagesList];

    if (this.imagesList.length === 0) {
      this.toggleCamNextButton.emit({ val: false });
    }
  }

  ngOnDestroy() {
    this.imagesList = [];
    Webcam.reset();
  }
}
