<div [formGroup]="group" class="col-sm-12 col-xs-12 col-md-12">
  <label class="radioLabel displayBlock" for="country">
    <!-- {{config.label|translate}} -->
    <span [innerHTML]="config.label|translate"></span>
  </label>
  <div class="col-xs-6 col-sm-2 col-md-2 m-b-20" *ngFor="let option of config.options;let qNo = index">
    <div class="group dynamicGroup">
      <span class="">{{ option['label']  | translate }}</span> <input type="radio" class="dynamicRadioBtn"
        [formControlName]="config.name" [checked]="radioSelection[qNo]" [value]="option['value']"
        (change)="onSelectionChange(config, option['value'])">
    </div>
  </div>
  <div class="row" *ngIf="textArea['name']===textAreaOfSelectedRadio">
    <div class="col-xs-12 col-sm-12 col-md-11 m-b-10 m-t-15">
      <div class="group marbot15">
        <label class="control-labelss">{{textArea['options']['label'] | translate}}</label>
        <textarea class="selfdisclosureTextArea" placeholder="" [formControlName]="textArea['name']"
          [maxlength]="textArea['options']['maxlength']"></textarea>
      </div>
    </div>
  </div>
</div>
