<div class="pad_30" [formGroup]="empSubForm" (onGesture)="doSwipe($event,empSubForm.value)" hammer-gestures>
  <button type="button" class="btn arrowleftctr" attr.data-id="empVerifyConfirmLeftArrow_{{row}}"
    debounceDirective (debounceClick)="getPrevSwipe(empSubForm.value)"><i class="icon-arrow-left" role="img" [attr.aria-label]="'SCREEN_BACKWARD_ARROW' | translate"></i></button>
  <div class="anime-clean contetnheight posrel" *ngIf="(inputSelectedCurrentEmpCountry !== 'IN' || toggleNocontactName || !inputIsIntlPmpComponentAvailable)">
    <div class="row no-form-element">
      <div class="col-md-12 col-xs-12 col-sm-12">
        <div class="header_txt_white">
          <h2>{{'EMPLOYMENT_VERFICIATION_CONFIRM_HEADER'|translate}}</h2>
        </div>
      </div>
    </div>
    <input type="hidden" class="form-control" formControlName="permissionToContact" />
    <div class="row fixedbtn">
      <div class="col-xs-12 col-sm-4 col-md-3 fixedbtnb">
        <button type="button" class="btn button grow font_extrabold" attr.data-id="empVerifyConfirmYesContact_{{row}}"
          debounceDirective (debounceClick)='verificationConfirm(empSubForm.value)'>{{ 'EMPLOYMENT_CONTACT_BTN_1' | translate }}</button>
      </div>
      <div *ngIf="!toggleNocontactName" class="col-xs-12 col-sm-4 col-md-3 fixedbtnb">
        <button type="button" class="btn button_wbg grow font_extrabold"
          attr.data-id="empVerifyConfirmContactLater_{{row}}"
          debounceDirective (debounceClick)='verificationDisagree(empSubForm.value)'>{{ 'EMPLOYMENT_CONTACT_BTN_2' | translate }}</button>
      </div>
      <div *ngIf="toggleNocontactName" class="col-xs-12 col-sm-4 col-md-3 fixedbtnb">
        <button type="button" class="btn button_wbg grow font_extrabold"
          attr.data-id="empVerifyConfirmNoContact_{{row}}"
          debounceDirective (debounceClick)='verificationDisagree(empSubForm.value)'>{{ 'EMPLOYMENT_CONTACT_BTN_3' | translate }}</button>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-3 fixedbtnb">
        <button type="button" class="btn button_wbg grow font_extrabold" attr.data-id="empVerifyConfirmSaveExit_{{row}}"
          debounceDirective (debounceClick)='saveExit(empSubForm.value)'>{{ 'COMMONS_SAVE_AND_EXIT' | translate }}</button>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
  
  <!-- toggleNocontactName is set to true if 1007 is null/undefined/NO, hence use !toggleNocontactName to show the last working day screen -->
  <div class="anime-clean contetnheight posrel" *ngIf="(inputSelectedCurrentEmpCountry === 'IN' && !toggleNocontactName && inputIsIntlPmpComponentAvailable)">
    <div class="row no-form-element">
      <div class="col-md-12 col-xs-12 col-sm-12">
        <div class="header_txt_white">
          <h2>{{ 'EMPLOYMENT_LAST_WORKING_DATE_HEADER' | translate:[ firstName ] }}</h2>
        </div>
      </div>
      <div class="col-md-12 col-xs-12 col-sm-12">
        <div class="header_txt_white">
          <h4>{{ 'EMPLOYMENT_LAST_WORKING_DATE_SUBHEADER' | translate }}</h4>
        </div>
      </div>
    </div>
    <div class="row m-t-20">
      <div class="col-xs-12 col-sm-4 col-md-4 m-b-20">
        <div class="group">
          <label class="control-labelss" for="lastWorkingDateId">{{ 'EMPLOYMENT_LAST_WORKING_DATE_LABEL' | translate }}</label>

          <pa-date-picker id="lastWorkingDateId" picker="today-to-future-date" [setDate]="fromSetDate"
              [maxDate]="lastWorkingMaxDate" appear="bubble" theme="material" placeholder="" 
              (ongetDpValue)="getLastWorkingDateDpValue($event)" (onDateError)="dateErrorHandler($event)">
          </pa-date-picker>
          
          <span class="error_info">
            <i class="fa fa-exclamation error_icon" aria-hidden="true" *ngIf="showDateErr"
              [class.red_error]="showDateErr" [class.cursor_hand_none]="!showDateErr"
              debounceDirective (debounceClick)="showDateErrbutton()"></i>
          </span>
          <div *ngIf="showDateErr">
            <div class="custom-error error_new_ui" *ngIf="showDateErr">
              <div class="left_div">
                <ul>
                  <li class="alert"><i class="icon-warning"></i> OOPS!</li>
                  <li class="message">
                    {{'EMPLOYMENT_CONTACT_MAX_TIME_FRAME_ERROR' | translate:[empLastWorkingDateMaxTimeFrame]}}</li>
                </ul>
              </div>
              <div class="right_div">
                <div class="close" aria-hidden="true" debounceDirective (debounceClick)="showDateErrbutton()">
                  <i class="icon-cancel"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row fixedbtn">
      <div class="col-xs-12 col-sm-4 col-md-3 fixedbtnb">
        <button type="button" class="btn button font_extrabold senButton" attr.data-id="empVerifyConfirmNext_{{row}}"
        [disabled]="!empSubForm.controls.datetocontact.valid" [class.grow]="empSubForm.controls.datetocontact.valid"
          debounceDirective (debounceClick)='onNextClick(empSubForm.value)'>{{ 'COMMONS_NEXT' | translate }}</button>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-3 fixedbtnb">
        <button type="button" class="btn button_wbg grow font_extrabold" attr.data-id="empVerifyConfirmSaveExit_{{row}}"
          debounceDirective (debounceClick)='saveExit(empSubForm.value)'>{{ 'COMMONS_SAVE_AND_EXIT' | translate }}</button>
      </div>
    </div>
  </div>
</div>