export class FormTextAreaConfig {
    static textAreaRowsValue = {
        ACIC_POSITION_TITLE: 1,
        ACIC_POSITION_TITLE_ADDITIONAL_FIELD: 1,
        ACIC_PLACE_OF_WORK_ADDITIONAL_FIELD: 1,
        ACIC_LOCATION_ADDITIONAL_FIELD: 1,
        ACIC_STATE_ADDITIONAL_FIELD: 1,
        DBS_APARTMENT_NUMBER: 1,
        DBS_STREET_NUMBER: 1,
        DBS_SUB_STREET: 1,
        DEFAULT_ROWS: 3
    };
}
