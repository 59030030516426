<div [formGroup]="empSubForm">
  <div class="posrel">
    <div class=" posrel">
      <div class="row">
        <div class="col-md-12 col-xs-12 col-sm-12">
          <div class="header_txt_white">
            <div>
              <h2>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="row m-t-40">
        <div class="col-xs-12 col-sm-6 col-md-6 m-b-20">
          <div class="group">
            <div *ngIf="!option">
              <!--<input type="text" class="form-control" formControlName="fromyear" />-->
              <label class="control-labelss" for="">{{'EMPLOYMENT_DATECONTACT_LABLE'|translate }}</label>
              <input type="hidden" formControlName="datetocontact" aria-required="true" class="form-control">

              <pa-date-picker [attr.id]="datetoconid" picker="datetoContact" [setDate]="fromSetDate" appear="bubble"
                theme="material" placeholder="" (ongetDpValue)="getDpValue($event)">
              </pa-date-picker>
            </div>
            <span class="error_info">
              <i class="fa fa-exclamation error_icon" aria-hidden="true" *ngIf="showDateErr"
                [class.red_error]="showDateErr" [class.cursor_hand_none]="!showDateErr"
                debounceDirective (debounceClick)="showDateErrbutton()"></i>
            </span>
            <div *ngIf="showDateErr">
              <div class="custom-error error_new_ui" *ngIf="showDateErr">
                <div class="left_div">
                  <ul>
                    <li class="alert"><i class="icon-warning"></i> OOPS!</li>
                    <li class="message">
                      {{'EMPLOYMENT_CONTACT_MAX_TIME_FRAME_ERROR' | translate:[empContactMaxTimeFrame]}}</li>
                  </ul>
                </div>
                <div class="right_div">
                  <div class="close" aria-hidden="true" debounceDirective (debounceClick)="showDateErrbutton()">
                    <i class="icon-cancel"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row fixedbtn">
        <div class="col-xs-12 col-sm-4 col-md-3 btnpd">
          <button type="button" debounceDirective (debounceClick)="gotoNext(empSubForm.value)" data-id="empDateToContactNext"
            [disabled]="!empSubForm.controls.datetocontact.valid" [class.grow]="empSubForm.controls.datetocontact.valid"
            class="btn button font_extrabold senButton ssnfrmbtn">{{'COMMONS_NEXT' | translate}}</button>
        </div>

      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</div>