import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProfileConfig } from './../../profile.config';

@Component({
  selector: 'view-profile-alias',
  templateUrl: './view-profile-alias.component.html',
  providers: []
})

export class ViewProfileAliasComponent {
  @Input() viewProfileData: Object;
  profileKey: any;

  constructor() {
  }

  ngOnInit() {
    this.profileKey = ProfileConfig.getViewAliasKey();
  }
}