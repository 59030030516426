import { AfterViewInit, Component, OnInit } from '@angular/core';
import { PreAuthService } from 'app/pre-auth/pre-auth.service';
import { LocalStorageService } from 'framework/localstorage.service';
import { SharedService } from '../shared/services/shared.service';
import { environment } from 'environments/environment';
import { TranslateService } from 'framework/i18n';
import { Router } from '@angular/router';

declare var window: any;

@Component({
  selector: 'app-base-login',
  templateUrl: './base-login.component.html',
  styleUrls: ['./base-login.component.css']
})
export class BaseLoginComponent implements OnInit {
  selectedPage = 'KEYCLOAK';
  isAuthyCallback:boolean = false;
  isAuthyLoggedIn: boolean = false;
  isAuthyTokenExpired: boolean = false;
  authyPolicyType: string = '';

  constructor(private _sharedService: SharedService,
    private localStorage: LocalStorageService,
    private preAuthService: PreAuthService,
    private translate: TranslateService,
    private router: Router) {
    }

  ngOnInit(): void {
    this.isAuthyCallback = this.localStorage.getItem("isAuthyCallback");
    this.isAuthyLoggedIn = this.localStorage.getItem("isAuthyLoggedIn");

    this.localStorage.setItem('migrate_to_okta', false);
    this.localStorage.setItem('user_authenticator', 'KEYCLOAK');
    let systemId = this.localStorage.getItem('system_id');
    let isMFAEnabled = !!this.localStorage.getItem('pa_enable_mfa') ? this.localStorage.getItem('pa_enable_mfa') : '';
    this.isAuthyTokenExpired = this.localStorage.getItem("isAuthyTokenExpired");
    
    if (this.isAuthyCallback && this.isAuthyTokenExpired) {
      this.router.navigate(['expired-link']);
      return;
    }

    let trackObj = {
      stage_name: 'base-login',
      action_value: 'url clicked'
    };

    window.track_event('login attempt', trackObj, true, true);

    if (!!systemId) {// call user auth euth engine when system id available
      this.preAuthService.getUserAuthEngine(systemId).subscribe(res => {
        let trackObj = {
          stage_name: 'base-login',
          action_value: 'get auth engine'
        };
  
        let migrateBackFromOkta = !!res['migrated_back_from_okta'] && !!res.auth_engine && res.auth_engine === 'KEYCLOAK' ? res['migrated_back_from_okta']: false;
  
        if (!!res && !!res.auth_engine && res.auth_engine === 'OKTA') {
          // Sign in into okta
          this.selectedPage = 'OIDC';
          this.localStorage.setItem('user_authenticator', 'OIDC');
  
          window.track_event('okta login redirect', trackObj, true, true);
        } else {
          this.localStorage.setItem('user_authenticator', 'KEYCLOAK');
  
          if (!!isMFAEnabled && isMFAEnabled === 'YES' && !migrateBackFromOkta) {
            // migrate into okta
            this.selectedPage = 'OIDC';
            this.localStorage.setItem('migrate_to_okta', true);
  
            window.track_event('okta login redirect', trackObj, true, true);
          } else {
            // sign in to keycloak
            this.selectedPage = 'KEYCLOAK';
            window.track_event('keycloak login redirect', trackObj, true, true);
          }
        }
      },
      err => {
        this.setAuthEngine(isMFAEnabled);
      });
    } else {
      this.setAuthEngine(isMFAEnabled);
    }    
  }

  setAuthEngine(isMFAEnabled) {
    this.localStorage.setItem('user_authenticator', 'KEYCLOAK');

    let trackObj = {
      stage_name: 'base-login',
      action_value: 'get auth engine'
    };

    if (!!isMFAEnabled && isMFAEnabled === 'YES') {
      // migrate into okta
      this.selectedPage = 'OIDC';
      this.localStorage.setItem('migrate_to_okta', true);
      window.track_event('okta login redirect', trackObj, true, true);
    } else {
      // sign in to keycloak
      this.selectedPage = 'KEYCLOAK';
      window.track_event('keycloak login redirect', trackObj, true, true);
    }
  }
}
