import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { AuthHttp } from 'angular2-jwt';
import { AppConfig } from '../app.config';
import { WorkflowService } from '../../framework/workflow.service';
import { LocalStorageService } from '../../framework/localstorage.service';
import { Observable } from 'rxjs';
import { map, catchError, delay, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class DashboardReportService {
  counter: number = 0;
  workflow_instance_id: any;
  static consentDocBaseID: any;
  headerOptions: any;
  _stages: any;

  constructor(private _http: HttpClient,
    private _workflow: WorkflowService,
    private localStorage: LocalStorageService) {
    this.workflow_instance_id = this.localStorage.getItem('workflow_instance_id');
    this.headerOptions = this._workflow.getHeaderOptions();
  }

  getDashboardDetails(wfiNo: string): Observable<any> {
    return this._http.get(AppConfig.API_ENDPOINT() + '/api/v1/order-status/?wfi_number=' + wfiNo + '&t=' + new Date(), this.headerOptions)
    .pipe(
      map(res => this._extractData(res)),
      map(res => this._doAction(res)),
      catchError(err => this._handleError(err, 'dashboard_get_failed'))
    );
  }

  getAllInvitesOfProfile(profile_id: string): Observable<any> {
    return this._http.get(AppConfig.API_ENDPOINT() + '/api/v1/invite/all/?profile_id=' + profile_id + '&t=' + new Date(), this.headerOptions)
    .pipe(
      map(res => this._extractData(res)),
      map(res => this._doAction(res)),
      delay(1000),
      retry(5),
      catchError(err => this._handleError(err, 'allInvites_profile_get_failed'))
    );
  }

  getData(): Observable<any> {
    return this._http.get(AppConfig.API_ENDPOINT() + '/api/v1/workflow/' + this.workflow_instance_id + '/clipboard' + '/?t=' + new Date(), this.headerOptions)
    .pipe(
      map(res => this._extractData(res)),
      map(res => this._doAction(res)),
      catchError(err => this._handleError(err, 'clipboard_get_failed'))
    );
  }

  getFullProfile(profileid): Observable<any> {
    return this._http.get(AppConfig.API_ENDPOINT() + '/api/v1/profile/' + profileid + '?profile-view=true', this.headerOptions)
    .pipe(
      map(this._extractData),
      map(this._doAction),
      catchError(err => this._handleError(err, 'fullProfile_get_failed'))
    );
  }

  inviteById(inviteId: any): Observable<Object> {
    /*
     * Need to make the HTTP GET API call with Token and will return
     * 410 - expired - > Login
     * 200 - > Set Password
    */

    return this._http
      .get(AppConfig.API_ENDPOINT() + `/api/v1/invite/` + inviteId, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        catchError(err => this._handleError(err, 'invite_byId_get_failed'))
      );
  }

  _extractData(res: any) {
    return res;
  }

  _doAction(response: any) {
    return response;
  }

  _handleError(error: any, eventName) {
    let _body = error || {};
    let errorMessage: string;

    console['server'](eventName, error);

    switch (error.status) {
      case 400:
      case 401:
        //Bad request, Invalid Credentials - login invalid
        errorMessage = _body['error']['error'] || 'Problem in Dashboard Report Service';
        break;
      case 410:
          //Bad request
          errorMessage = 'INVITE EXPIRED';
          break;
      case 404:
        break;
    }
    return throwError(errorMessage);
  }

  getPdfReport(action): Observable<any> {
    return this._http.get(AppConfig.API_ENDPOINT() + action + '/?t=' + new Date(), this.headerOptions)
    .pipe(
      map(res => this._extractNewData(res)),
      catchError(err => this._pdfHandleError(err, 'pdfReport_get_failed'))
    );
  }

  _pdfHandleError(error: any, eventName) {
    let errorMessage: string;
    console['server'](eventName, error);

    switch (error.status) {
      case 400:
      case 401:
        //Bad request, Invalid Credentials - login invalid
        errorMessage = 'Problem in PDF Service';
        break;

      case 404:
        break;
    }
    return throwError(errorMessage);
  }

  _extractNewData(res: any) {
    return res;
  }

  getEAuthPdf(workflowid: any): Observable<any> {
    return this._http.get(AppConfig.API_ENDPOINT() + '/api/v1/workflow/' + workflowid + '/eauth-step' + '/?t=' + new Date(), this.headerOptions)
    .pipe(
      map(res => this._doAction(res)),
      catchError(err => this._handleError(err, 'dashboard_eauth_get_failed'))
    );
  }

  getdashboardWotcData(): Observable<any> {
    let workflowid = this.localStorage.getItem('workflow_instance_id');
    let action = '/api/v1/workflow/' + workflowid + '/dynamic-step';

    return this._http.get(AppConfig.API_ENDPOINT() + action + '/?t=' + new Date(), this.headerOptions)
    .pipe(
      // map((res: any) => res.json()),
      map(res => this._extractData(res)),
      catchError(err => this._handleError(err, 'dashboard_wotc_get_failed'))
    );
  }

  // Method to donwload file
  downloadFile(id): Observable<Blob> {
    console.log('Doc base id' + id);
    let action = '/api/v1/workflow/' + this.workflow_instance_id + '/download/document/';

    return this._http.get(AppConfig.API_ENDPOINT() + action + id, { headers : new HttpHeaders({
      'Authorization': 'Bearer ' + this.localStorage.getItem('access_token')
    }), responseType : 'blob' }).pipe(
      map(res => this.documentDownload(res)), // res => res.blob()
      catchError(err => this._handleError(err, 'dashboard_download_get_failed'))
    );
  }

  downloadBackgroundReport(url){
    let profileId = this.localStorage.getItem('profile_id');
    return this._http.get(url, { headers : new HttpHeaders({
      'Authorization': 'Bearer ' + this.localStorage.getItem('access_token')
    }), responseType : 'blob' }).pipe(
      map(res => this.documentDownload(res)), // res => res.blob()
      catchError(err => this._handleError(err, 'dashboard_download_background_report_get_failed'))
    );
  }

  private documentDownload(response) {
    let res = response;

    return res;
  }

  downloadDocumentByUrl(url){
    return this._http.get(url, { headers : new HttpHeaders({
      'Authorization': 'Bearer ' + this.localStorage.getItem('access_token')
    }), responseType : 'blob' }).pipe(
      map(res => this.documentDownload(res)), 
      catchError(err => this._handleError(err, 'dashboard_download_document_get_failed'))
    );
  }

  getDocumentOtp(url: string): Observable<Object> {
    return this._http.get(url + '/?t=' + new Date(), this.headerOptions)
    .pipe(
      map(res => this._extractData(res)),
      map(res => this._doAction(res)),
      catchError(err => this._handleError(err, 'document_otp_retreival_error'))
    );
  }

  getWorkFlowData(id: string): Observable<Object> {
    return this._http.get(AppConfig.API_ENDPOINT() + '/api/v1/workflow/' + id + '/?t=' + new Date(), this.headerOptions)
    .pipe(
      map(res => this._extractData(res)),
      map(res => this._doAction(res)),
      delay(1000),
      catchError(err => this._handleError(err, 'dashboard_workflow_get_failed'))
    );
  }

   // Dynamic cards
   getAllPageCards(wfiNo: string): Observable<any> {
    // TODO - Change the referenced URL
    return this._http.get(AppConfig.API_ENDPOINT() + '/api/v1/pages/' + wfiNo , this.headerOptions)
    .pipe(
      map(res => this._extractData(res)),
      map(res => this._doAction(res)),
      delay(1000),
      retry(5),
      catchError(err => this._handleError(err, 'allPages_get_failed'))
    );
  }

  /**
   * Service to save candidate survey
   * 
   * @param data 
   * @param wfiId 
   * @returns 
   */
  saveCandidateSurvey(data: any, wfiId: string) {
    return this._http.put(AppConfig.API_ENDPOINT() + '/api/v1/workflow/' + wfiId + '/candidate-rating', data, this.headerOptions)
    .pipe(
      map(res => this._extractData(res)),
      map(res => this._doAction(res)),
      catchError(err => this._handleError(err, 'candidate survey put failed'))
    );

  }

  /**
   * check pref enabled to collect customer feedback
   * 
   * @returns 
   */
  isCustomerFeedbackSurveyEnabled() {
    const pref = this.localStorage.getItem('enable_customer_feedback_survey');
    if (!!pref && pref.toUpperCase() === 'YES') {
      return true;
    }

    return false;
  }

  prepareInviteNotificationDocumentURL(selectedNotificationDoc: any){
    const inviteId = this.localStorage.getItem('inviteId');
    let action = '/api/v1/invite/'+ inviteId +'/notification-document/' + selectedNotificationDoc['id'];
    let url = AppConfig.API_ENDPOINT() + action;
    return url;
  }

  prepareOneTimeNotificationDocumentURL(selectedNotificationDoc: any){
    const inviteId = this.localStorage.getItem('inviteId');
    let action = '/api/v1/invite/'+ inviteId +'/one-time-url/' + selectedNotificationDoc['id'];
    let url = AppConfig.API_ENDPOINT() + action;
    return url;
  }

  prepareOTPDocumentUrl(otp: string){
    const inviteId = this.localStorage.getItem('inviteId');
    let url = AppConfig.API_ENDPOINT() + '/api/web/document/' + otp + '?id=' + inviteId;
    return url;
  }

  getSkippedDocumentsList(): Observable<any> {
    return this._http.get(AppConfig.API_ENDPOINT() + '/api/v1/workflow/' + this.workflow_instance_id + '/skipdocument', this.headerOptions)
    .pipe(
      map(res => this._extractData(res)),
      map(res => this._doAction(res)),
      catchError(err => this._handleError(err, 'get_skipdocuments_failed'))
    );
  }
}
