import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventBusService } from '../../framework/eventbus.service';
import { LocalStorageService } from '../../framework/localstorage.service';
import { SharedService } from 'app/shared/services/shared.service';

@Component({
  selector: 'redirect',
  providers: [],
  templateUrl : './redirect.component.html'
})

export class RedirectComponent implements OnInit {
  constructor (private eventBus: EventBusService,
               private localStorage: LocalStorageService,
               private shareService: SharedService) {
   }

  ngOnInit() { }

  private ngOnDestroy() { }

  navigateToLink(){
    console.log(this.localStorage.getItem('da_url'));
    // window.location.href = localStorage.getItem('da_url');
    window.open(this.localStorage.getItem('da_url'), '_blank')
     this.eventBus.logoutAnnounced({
      dummy: 'logout'
    });
    this.localStorage.clear();
    this.shareService.customFormVariables = {};
  }
}
