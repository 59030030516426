<div *ngIf="!config.isHidden" class="col-xs-12 col-sm-6 col-md-4 m-b-20">
  <div class="group" [formGroup]="group">
    <label *ngIf="config.hint != 'NoLabelRender'" class="placeholder_white custom_label labelEllipsis" [attr.for]="config.id"
      title="{{config.label|translate}}">
      {{config.label|translate}}
    </label>
    <a data-toggle="popover" data-placement="bottom" title="{{config.label|translate}}"
      *ngIf="config.hint != 'NoLabelRender' && config.label && config.label.length > 30">
      <i class="icon-info labelHelpIcon" (click)="showFullLabelText()"></i>
    </a>
    <input type="text" *ngIf="isRequired" class="form-control" aria-required="true" [attr.id]="config.id" [formControlName]="config.name" validateSpaceInput
      [value]="config.value | interpolate | async | mask:'':'MASK': !!config && config['isMaskIdNumber']" [readonly]="readonly" attr.disabled="readonly" (focus)="onFocus()"
      (blur)="onBlur()">

    <input type="text" *ngIf="!isRequired" class="form-control" [attr.id]="config.id" [formControlName]="config.name"
      [value]="config.value | interpolate | async | mask:'':'MASK': !!config && config['isMaskIdNumber']" [readonly]="readonly" attr.disabled="readonly" (focus)="onFocus()"
      (blur)="onBlur()">
    <error *ngIf="isError && config.name === errorFieldDetails['name']" [errorConfig]='errorMsgConfig'
      (openErrorPopUp)="openErrorPopUp()" (closeErrorPopUp)="closeErrorPopUp()"></error>

   <app-field-error composeGeneric="SIMPLE" [messageTranslationKey]="!!config && !!config['validation_data'] && !!config['validation_data']['error_message'] ? config['validation_data']['error_message']:'GENERIC_ERROR_MSG' " [control]="group?.controls[config.name]" [requireTouchOrDirty]="true"></app-field-error>

  </div>
</div>
