import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'profile-criminal',
  templateUrl: './profile-criminal.component.html',
  providers: []
})

export class ProfileCriminalComponent {
  @Input() options: any;
  @Output() onViewProfile: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  openViewProfilePopup(data, key): void {
    data['key'] = key;
    this.onViewProfile.emit(data);
  }
}