export class OCCREG {
    public execute(ruleObj: Object): any {
        // slider action
        if (ruleObj['action'] === 'slider') {
            let response = {};
            if (ruleObj['direction'] === '_right') {
                if (ruleObj['isPkgeExist'] && ruleObj['country'] === 'US') {
                    response['slider'] = ruleObj['slides'][0];
                    response['flag'] = true;
                } else {
                    response['slider'] = ruleObj['slides'][1];
                    response['flag'] = false;
                }
            } else if (ruleObj['direction'] === '_left') {
                let currentRowFlag = ruleObj['currentRowFlag'];
                response['slider'] = (ruleObj['isPkgeExist'] && ruleObj['country'] === 'US' && currentRowFlag) ? ruleObj['slides'][0] : ruleObj['slides'][1];
            }
            return response;
        }

        // Dynamic control action
        if (ruleObj['action'] === 'control') {
        }
    }
}
