<form>
        <!-- <select class="languageSelectorList" (change)="setUpdatedLanguage($event.target.value)" name="selectedLanguage" [(ngModel)]="selectedLanguage">
                <option attr.data-icon="flag-icon flag-icon-squared flag-icon-{{type.flagIconName}}"  *ngFor="let type of languagePickerConfig" [value]="type.langWithCountryCode">{{type.languageName}}</option>
        </select> -->
        <ng-select [items]="languagePickerConfig" [(ngModel)]="selectedLanguage" aria-label="Change Language" bindLabel="languageName" name="selectedLanguage" bindValue="langWithCountryCode"
        (change)="setUpdatedLanguage($event)" [clearable]="false" [clearOnBackspace]="false">
                <ng-template ng-label-tmp let-item="item">
                    <span class="flag-icon flag-icon-squared flag-icon-{{item.flagIconName}}"></span>
                    {{item.languageName}}
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index">
                        <span class="flag-icon flag-icon-squared flag-icon-{{item.flagIconName}}"></span>
                    {{item.languageName}}
                </ng-template>
        </ng-select>
</form>
