import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/fieldconfig.interface';
import { ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'wotcdocument-category',
  templateUrl: './document-category.component.html'
})

export class wotcDocumentCategoryComponent {
    @Input () DocUpload : any;
    @Output() documentOnUpload: EventEmitter<any> = new EventEmitter<any>();
    @Output() documentOnDelete: EventEmitter<any> = new EventEmitter<any>();
    @Output() documentOnDownload: EventEmitter<any> = new EventEmitter<any>();
    @Output() documentOnView: EventEmitter<any> = new EventEmitter<any>();
    @Output() documentOnInstruction: EventEmitter<any> = new EventEmitter<any>();

    onDownloadDocumentClick(event) {
        console.log('DOWNLOAD');
        this.documentOnDownload.emit();
    }

    onUploadDocumentClick() {
        console.log('UPLOAD');
        this.documentOnUpload.emit();
    }

    onDeleteDocumentClick() {
        console.log('DELETE');
        this.documentOnDelete.emit();
    }

    onViewDocumentClick() {
        console.log('VIEW');
        this.documentOnView.emit();
    }

    onInstructionClick() {
        console.log('INSTRUCTION');
        this.documentOnInstruction.emit();
    }
}
