import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'framework/localstorage.service';

@Component({
  selector: 'invalid-invitation',
  templateUrl: './invalid-invitation.component.html'
})

export class InvalidInvitationComponent implements OnInit {
  public isStatus500: string = '';
  public isStatus502: string = '';
  public isInvalid: boolean = false;
  public inviteStatusError: string = '';

  constructor(private localStorage: LocalStorageService) { }

  ngOnInit() {
    this.isStatus500 = this.localStorage.getItem('Status_500');
    if (this.isStatus500) {
      this.isInvalid = true;

    }
    this.isStatus502 = this.localStorage.getItem('Status_502');
    if (this.isStatus502) {
      this.isInvalid = true;
    }

    if (!!this.localStorage.getItem('inviteStatus_400')) {
      this.inviteStatusError = this.localStorage.getItem('inviteStatus_400');
    }
  }

}
