import { Component, OnInit, Inject } from '@angular/core';
import { TRANSLATION_PROVIDERS, TranslatePipe, TranslateService } from 'framework/i18n';
import { LocalStorageService } from '../../framework/localstorage.service';
import { AuthService, LoginComponent } from '../login';
import { DOCUMENT } from "@angular/common";
import { ProfileService } from 'app/profile/profile.service';
import { EventBusService } from 'framework/eventbus.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SharedService } from 'app/shared/services/shared.service';
import { Router } from '@angular/router';
import { WorkflowService } from 'framework/workflow.service';
import { GoogleApiService } from 'app/shared/services/google-api.service';
import { environment } from 'environments/environment';
import { AppConfig } from 'app/app.config';
import { AppConstants } from 'app/app.constants';
import { DynamicScriptLoaderService } from 'app/shared/services/dynamic-script-loader.service';
import { PreAuthService } from 'app/pre-auth/pre-auth.service';

declare var $: any;
declare var window: any;
declare var document: any;

@Component({
  selector: 'app-mfa-callback',
  templateUrl: './mfa-callback.component.html',
  styleUrls: ['./mfa-callback.component.css']
})
export class MfaCallbackComponent implements OnInit {
  response: any;
  isInviteRightId: boolean = false;
  isIE11: boolean;
  errorMessage: string = '';
  isInviteEiaf: boolean = false;
  nextUrl: string;
  isInviteMI: boolean = false;
  trackObj: Object;
  middlename: string;

  constructor(private localStorage: LocalStorageService,
              private authService: AuthService,
              private _translate: TranslateService,
              private _profileSevice: ProfileService,
              private _eventBus: EventBusService,
              private deviceService: DeviceDetectorService,
              private _shareService: SharedService,
              private _service: AuthService,
              private _router: Router,
              private _workflow: WorkflowService,
              private _preAuthService: PreAuthService,
              private googleApiService: GoogleApiService,
              private dynamicScriptLoader: DynamicScriptLoaderService,
              @Inject(DOCUMENT) private document: any) { }

  ngOnInit(): void {
    this.trackObj = {
      stage_name: 'mfa-callback',
      action_value: 'load'
    };
    window.track_event('page loading', this.trackObj, true, true);
    console.log('access_token', this.localStorage.getItem('access_token'));
    console.log('id_token', this.localStorage.getItem('id_token'));
    this.isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    this.localStorage.setItem("isMFACallBackError", false);
    this._eventBus.languageListInInviteReceivedAnnounced({
      availableLangList: []
    });
    this.fetchSessionData();
    window.track_event('page loaded', this.trackObj, true, true);
  }

  private fetchSessionData() {
    let that = this;
    this.isInviteRightId = this._shareService.checkInviteIsRightId();
    this.isInviteEiaf = this._shareService.checkInviteIsEIAF();
    this.isInviteMI = this._shareService.checkInviteIsMI();
    this.trackObj = {
      stage_name: 'mfa-callback',
      action_value: 'fetch'
    };

    console.log('State Id : ', this.localStorage.getItem('stateId'));

    this.authService.fetchSessionData().subscribe(sessionDataResponse => {
      console.log("sessionDataResponse" , sessionDataResponse);
      window.track_event('fetch session successful', this.trackObj, true, true);
      let profile_id = sessionDataResponse['profile_id'];
      let invite_id = sessionDataResponse['invite_id'];

      this.localStorage.setItem('profile_id', profile_id);
      this.localStorage.setItem('user_email', sessionDataResponse['email']);
      this.localStorage.setItem('pa_enable_mfa', sessionDataResponse['enableMfa']);
      this.document.getElementById('brand').innerHTML = sessionDataResponse['custom_styles'];

      this.dynamicScriptLoader.loadScripts();
      
      this._profileSevice.getProfile(profile_id).subscribe(profileResponse => {
        this.saveToLocalUserData(profileResponse);
        window.track_event('get profile successful', this.trackObj, true, true);

        if (profileResponse['has_middle_name']) {
          if (profileResponse['middle_name'] !== undefined) {
            this.middlename = " " + profileResponse['middle_name'] + " ";
          } else {
            this.middlename = " ";
          }
        } else {
          this.middlename = " ";
        }
        this.localStorage.setItem("middlename", this.middlename);

        if (invite_id !== undefined) {
          this._profileSevice.inviteById(invite_id).subscribe(async inviteResponse => {
            window.track_event('get invite by id successful', this.trackObj, true, true);
            this._eventBus.languageListInInviteReceivedAnnounced({
              hideLanguageSelectorInLoginPage: true
            });
            
            await this.processResponse(inviteResponse);
            
            const ridStatus = this.localStorage.getItem('rid_status');
            const subType = this.localStorage.getItem('subType');
            
            if (!(this.isInviteRightId &&  this.deviceService.isDesktop() && (!subType || (subType === AppConfig.RIGHT_ID_ROUTER && ridStatus === 'PENDING')))) {
              this._eventBus.announceMission({
                usename: profileResponse['first_name'] + this.middlename + profileResponse['last_name'],
                isUserInfoShown: true
              });
            }
          },
            error => {
              window.track_event('get invite by id failed', this.trackObj, true, true);
              // Redirect to expired page while login from invite link
              $('#waitOnMfaModal').modal('hide');
              this.errorMessage = <any>error;
              this.localStorage.setItem("isMFACallBackError", true);
              
              if (this.errorMessage === 'INVITE EXPIRED') {
                this._service.logout();
                this._next('/expired');
              } else {
                this._next('/profile/thankyou');
              }
            });
        } else {
          this._profileSevice.inviteByProfile(profile_id).subscribe(async inviteResponse => {
            window.track_event('get invite by profile successful', this.trackObj, true, true);
            
            this._eventBus.languageListInInviteReceivedAnnounced({
              hideLanguageSelectorInLoginPage: true
            });
            
            await this.processResponse(inviteResponse);

            if (!(this.isInviteRightId && this.deviceService.isDesktop())) {
              this._eventBus.announceMission({
                usename: profileResponse['first_name'] + this.middlename + profileResponse['last_name'],
                isUserInfoShown: true
              });
            }
          },
            error => {
              window.track_event('get invite by profile failed', this.trackObj, true, true);
              this.localStorage.setItem("isMFACallBackError", true);
              // Redirect to expired page while login with emailid
              $('#waitOnMfaModal').modal('hide');
              this.errorMessage = <any>error;
              if (this.errorMessage === 'INVITE EXPIRED') {
                this._service.logout();
                this._next('/expired');
              } else {
                this._next('/profile/thankyou');
              }
            });
        }
      }, error => {
        window.track_event('get profile failed', this.trackObj, true, true);
        this.localStorage.setItem("isMFACallBackError", true);
        $('#waitOnMfaModal').modal('hide');
        this.errorMessage = <any>error;
        this._next('/profile/thankyou');
      })
    },
    error => {
      window.track_event('fetch session failed', this.trackObj, true, true);
      this.localStorage.setItem("isMFACallBackError", true);
      $('#waitOnMfaModal').modal('hide');
      console.log("error" , error);
      this._next('/profile/thankyou');
    });
  }

  private _next(url): void {
    this._router.navigate([url]);
  }

  async processResponse(inviteResponse: any): Promise<void> {
    if(!!inviteResponse && !!inviteResponse['key']) {
      this._preAuthService.validateToken(inviteResponse['key']).subscribe(validateTokenResponse => {
        console.log("ValidateTokenResponse::",validateTokenResponse);
        
        this.processPostValidateToken(inviteResponse);
      });
    }
  } 
  
  private async processPostValidateToken(inviteResponse) {
    const placeSearchAPi = inviteResponse['account']['place_search_api'];
    this.localStorage.setItem('place_search_api', placeSearchAPi);
    window.track_event('processing response', { stage_name: 'mfa-callback', action_value: 'process' }, true, true);
    
    if (this._shareService.isGooglePlaceApiEnabled()) {
      // add google api js into script tag for the specific language
      await this.googleApiService.load(inviteResponse['language']);
    }

    if (!!inviteResponse['applicant']) {
      this.setApplicantDataInLocalStorage(inviteResponse['applicant']);
    }

    if (!!inviteResponse['account']) {
      this.localStorage.setItem('SMS_OPTOUT_ENABLED', inviteResponse['account']['SMS_OPTOUT_ENABLED'])
    }

    if (inviteResponse !== undefined && inviteResponse['account'] !== undefined &&
      inviteResponse['account']['is_ea_rightId']) {
      this.localStorage.setItem('is_ea_rightId', inviteResponse['account']['is_ea_rightId']);
    }

    let ridStatus = undefined;
    if (!!inviteResponse['account'] && !!inviteResponse['account']['rid_status']) {
      ridStatus = inviteResponse['account']['rid_status'];
    }

    let importDataDLCaptureStatus = undefined;
    if (!!inviteResponse['account'] && !!inviteResponse['account']['import_data_status']) {
      importDataDLCaptureStatus = inviteResponse['account']['import_data_status'];
    }

    let importDataDLCaptureResult = undefined;
    if (!!inviteResponse['account'] && !!inviteResponse['account']['import_data_result']) {
      importDataDLCaptureResult = inviteResponse['account']['import_data_result'];
    }

    let isDLCapturePreFillEnabled = undefined;
    if (inviteResponse['account'] && inviteResponse['account']['is_capture_dl_prefill']) {
      isDLCapturePreFillEnabled = inviteResponse['account']['is_capture_dl_prefill'];
      this.localStorage.setItem('is_capture_dl_prefill', inviteResponse['account']['is_capture_dl_prefill']);
    }
    
    if (!!inviteResponse && !!inviteResponse['account'] && !!inviteResponse['account']['support_chat_experience']) {
      this.localStorage.setItem('support_chat_experience', inviteResponse['account']['support_chat_experience']);
    }
    if (!!inviteResponse && !!inviteResponse['account'] && !!inviteResponse['account']['credit_card_pay_enabled']) {
      this.localStorage.setItem('credit_card_pay_enabled', inviteResponse['account']['credit_card_pay_enabled']);
    }
    if (!!inviteResponse && !!inviteResponse['account'] && !!inviteResponse['account']['payment_partner_pref_value']) {
      this.localStorage.setItem('payment_partner_pref_value', inviteResponse['account']['payment_partner_pref_value']);
    }
    
    if (!!inviteResponse && !!inviteResponse['account'] && !!inviteResponse['account']['azure_cognitive_search_enabled']) {
      this.localStorage.setItem('azure_cognitive_search_enabled', inviteResponse['account']['azure_cognitive_search_enabled']);
    }
    
    if (!!inviteResponse && !!inviteResponse['account'] && inviteResponse['account']['lookup_service_url']) {
      this.localStorage.setItem('lookup_service_url', inviteResponse['account']['lookup_service_url']);
    }

    if (!!inviteResponse['disable_tos']) {
      this.localStorage.setItem('disable_tos', inviteResponse['disable_tos']);
    }

    let showOptOut = 'YES';
    let retryAttempt = 3;
    
    let capture_mode = this.localStorage.getItem("capture_mode");
    if (capture_mode == null || capture_mode === undefined || capture_mode == '') {
        // console.log("No capture mode preference - set to default " + environment.camerasettings.captureMode);
        capture_mode = environment.camerasettings.captureMode;
        this.localStorage.setItem("capture_mode", capture_mode);
    }
      
    if (inviteResponse != null && inviteResponse['account'] != undefined && inviteResponse['account']['config'] != undefined &&
      inviteResponse['account']['config']['preferences'] != undefined &&
      inviteResponse['account']['config']['preferences'].length > 0) {
      let preferenceList = inviteResponse['account']['config']['preferences'];
      for (var preference of preferenceList) {
        if (preference['name'] == 'WEBSDK_VENDOR' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined) {
          this.localStorage.setItem('websdk_vendor', preference['value']);
          continue;
        }
        if (preference['name'] == 'YOTI_TIMEOUT_IN_MINS' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined) {
          this.localStorage.setItem('yoti_timeout_in_mins', preference['value']);
          continue;
        }
        if (preference['name'] == 'DISABLE_YOTI_DIGITAL_WALLET_EXP' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined) {
          this.localStorage.setItem('disable_yoti_digital_wallet_exp', preference['value']);
          continue;
        }        
        
        if (preference['name'] == 'SHOW_OPT_OUT') {
          showOptOut = preference['value'];
          continue;
        }
        if (preference['name'] == 'CAPTURE_MODE' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined) {
          // console.log(" capture mode = " + preference['value'])
          this.localStorage.setItem('capture_mode', preference['value']);
          continue;
        }
        if (preference['name'] == 'RETRY_ATTEMPT') {
          // console.log(" retry attempt = " + preference['value'])
          retryAttempt = preference['value'];
          continue;
        }

        //Adding to redirect user in case user Opts to try alternate method link shown on Instructions Component
        if (preference['name'] == 'REDIRECT_URL' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined){
           if (!(this.localStorage.getItem('try_alt_methods_uri'))) {
               this.localStorage.setItem('try_alt_methods_uri', preference['value']);
           }
           continue;
        }
       
        if (preference['name'] == 'VERIFICATION_API_VENDOR' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined){
            this.localStorage.setItem('verification_api_vendor', preference['value']);
            continue;
        }
        if (preference['name'] == 'SHOW_ABORT_OPTIONS' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined){
            this.localStorage.setItem('show_abort_options', preference['value']);
            continue;
        }
        if (preference['name'] == 'SHOW_NON_US_FLOW' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined){
            this.localStorage.setItem('show_non_us_flow', preference['value']);
            continue;
        }
        if (preference['name'] == 'MASK_DECISION' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined){
            this.localStorage.setItem('mask_decision', preference['value']);
            continue;
        }
      }
    }
    
    const env = environment;
    
    setTimeout(() => {
      this.localStorage.setItem("chat_exp", "SFC");
    }, 2000);

    let webSdkVendor = this.localStorage.getItem('websdk_vendor');
    let isYoti = (!!webSdkVendor && webSdkVendor === 'YOTI');
    this.localStorage.setItem("is_yoti", isYoti);
    
    this.localStorage.setItem('show_opt_out', showOptOut);
    this.localStorage.setItem('retry_attempt', retryAttempt);

    console.log("LoginComponent webSdkVendor and isYoti----", webSdkVendor, isYoti);
    let subType = inviteResponse['subType'];
    this.localStorage.setItem('subType', subType);
    this.localStorage.setItem('rid_status', ridStatus);
    this.localStorage.setItem('import_data_status', importDataDLCaptureStatus);
    this.localStorage.setItem('import_data_result', importDataDLCaptureResult);

    if (!!inviteResponse["package"]) {
      this.localStorage.setItem('package_name', inviteResponse["package"]['name']);
      if (!!inviteResponse["package"]['distinct_components']) {
        this.localStorage.setItem('package_components', inviteResponse["package"]['distinct_components']);
      }
    }

    // Commented because its set above
    // this.document.getElementById('brand').innerHTML = inviteResponse['custom_styles'];
    let inviteKey = inviteResponse['key'];
    let inviteUrl = this.localStorage.getItem('invite_url');
    this.localStorage.setItem('invite_key', inviteKey);
    this.isInviteRightId = this._shareService.checkInviteIsRightId();

    if (this.isInviteRightId && subType !== AppConfig.RIGHT_ID_ROUTER && !!ridStatus && (ridStatus === 'OPTED_OUT' || ridStatus === 'OPTED_OUT_DEVICE' || ridStatus === 'FRAUD_DETECTED' )) {
      console.log("EARightId Invite routing to thankyou page.");
      this._router.navigate(['/profile/thankyou']);
      return;
    }

    // 8214 / 8514 - Display QR Code, regardless of RightID, if preference is set.
    const isCaptureDLPrefill = this.localStorage.getItem('is_capture_dl_prefill') === 'Y';

    if ((this.isInviteRightId && (!subType || ((subType === AppConfig.RIGHT_ID_ROUTER || subType === AppConstants.INVITE_SUB_TYPE_KYP) && ridStatus === 'PENDING'))) ||
         (isCaptureDLPrefill && importDataDLCaptureStatus === 'PENDING')) {
      if (!isYoti && this.deviceService.isDesktop()) {
        this.errorMessage = <any>Error;
        this.errorMessage['errorMessage'] = 'UNSUPPORTED_DEVICE';
        this._shareService.redirectToUnsupportedDevice(this._service,this._router);
        window.track_event('unsupported device', { stage_name: 'mfa-callback', action_value: 'process' }, true, true);
        return;
      }

      this.trackObj = {
        stage_name: 'mfa-callback',
        action_value: 'save'
      };

      window.track_event('rightid invite, load js', this.trackObj, true, true);
      
      //Adding IDMetricsCaptureFramework.js to head tag if rightID ONLY or RID stages not completed
      let head = this.document.getElementsByTagName('head')[0];
      let script = document.createElement('script');
      script.src = '../../assets/js/IDMetricsCaptureFramework.js';
      head.appendChild(script);
      if (isYoti) {
        let yotiScript = document.createElement('script');
        yotiScript.src = 'https://www.yoti.com/share/client/';
        head.appendChild(yotiScript);
      }
    }
    
    //Adding modernizr-custom.js to head tag if invite is not RIGHT or RID stages are completed or DL prefill is available
    let head = this.document.getElementsByTagName('head')[0];
    let script = document.createElement('script');
    script.src = '../../assets/js/modernizr-custom.js';
    head.appendChild(script);

    this.localStorage.setItem('key', inviteResponse['key']);

    // Check Invite Type is EIAF
    this.localStorage.setItem('inviteType', inviteResponse['type']);

    /*
     * Language setting - Browser takes precedence. Moved the code to shared service
     * for code reuse
    */
    await this._shareService.setLanguage(inviteResponse, this._eventBus);

    if (inviteResponse !== undefined && inviteResponse['account'] !== undefined) {
      if (inviteResponse['account']['is_report_on']) {
        this.localStorage.setItem('is_report_on', inviteResponse['account']['is_report_on']);
      }

      if (inviteResponse['account']['pa_dot_direct_preference']) {
        this.localStorage.setItem('pa_dot_direct_preference', inviteResponse['account']['pa_dot_direct_preference']);
      }
      if (inviteResponse['account']['pa_dot_drug_order_preference']) {
        this.localStorage.setItem('pa_dot_drug_order_preference', inviteResponse['account']['pa_dot_drug_order_preference']);
      }

      if (inviteResponse['account']['pa_dot_limit_employment_verification']) {
        this.localStorage.setItem('pa_dot_limit_employment_verification', inviteResponse['account']['pa_dot_limit_employment_verification']);
      }

      if (inviteResponse['account']['reason_for_leaving_mandatory']) {
        this.localStorage.setItem('reason_for_leaving_mandatory', inviteResponse['account']['reason_for_leaving_mandatory']);
      }

      if (inviteResponse['account']['ea_account_bg_report_completion_eta']) {
        this.localStorage.setItem('ea_account_bg_report_completion_eta', inviteResponse['account']['ea_account_bg_report_completion_eta']);
      }

      if (inviteResponse['account']['pa_lock_pre_population_for_custom_form_fields']) {
        this.localStorage.setItem('pa_lock_pre_population_for_custom_form_fields', inviteResponse['account']['pa_lock_pre_population_for_custom_form_fields']);
      }

      if (inviteResponse['account']['is_candidate_age_based_scope']) {
        this.localStorage.setItem('is_candidate_age_based_scope', inviteResponse['account']['is_candidate_age_based_scope']);
      }

      if (inviteResponse['account']['country_of_residence_pref_value']) {
        this.localStorage.setItem('country_of_residence_pref_value', inviteResponse['account']['country_of_residence_pref_value']);
      }

      if (inviteResponse['account']['pa_lock_down_name']) {
        this.localStorage.setItem('pa_lock_down_name', inviteResponse['account']['pa_lock_down_name']);
      }
      if (inviteResponse['account']['pa_do_not_override_name']) {
        this.localStorage.setItem('pa_do_not_override_name', inviteResponse['account']['pa_do_not_override_name']);
      }

      if (inviteResponse['account']['user_alert_on_error']) {
        this.localStorage.setItem('user_alert_on_error', inviteResponse['account']['user_alert_on_error']);
      }

      if (inviteResponse['account']['use_font_based_signature']) {
        this.localStorage.setItem('use_font_based_signature', inviteResponse['account']['use_font_based_signature']);
      }

      if (inviteResponse['account']['SIGNATURE_FONT']) {
        this.localStorage.setItem('SIGNATURE_FONT', inviteResponse['account']['SIGNATURE_FONT']);
      }

      if (inviteResponse['account']['is_salary_enabled']) {
        this.localStorage.setItem('is_salary_enabled', inviteResponse['account']['is_salary_enabled']);
      }
      
      if (inviteResponse['account']['show_biometric_consent']) {
        this.localStorage.setItem('show_biometric_consent', inviteResponse['account']['show_biometric_consent']);
      }
      
      if (inviteResponse['account']['accent_character_translation_enabled']) {
        this.localStorage.setItem('accent_character_translation_enabled', inviteResponse['account']['accent_character_translation_enabled']);
      }
      
      if (inviteResponse['account']['rid_retry_count']) {
        this.localStorage.setItem('rid_retry_count', parseInt(inviteResponse['account']['rid_retry_count']));
      }
    }

    this.localStorage.setItem('account_id', inviteResponse['account']['id']);

    /**
     * 'Contact Us' is behind a feature flag now. (features.config.ts)
     * false - means off i.e. no 'Contact Us' link
     * true - means on i.e. 'Contact Us' link to be shown if present in invite response object
     */

    this.localStorage.setItem('active_chat', inviteResponse['account']['active_chat']);
    if (this.localStorage.getItem('active_chat') !== undefined && this.localStorage.getItem('active_chat') === 'N') {
      //TODO - shutdown SFC ?
    }

    if (AppConfig.isFeatureAvailable('app-features', 'contact-us')
      && this.localStorage.getItem('active_chat') !== undefined
      && this.localStorage.getItem('active_chat') === 'N') {
      if (inviteResponse['account'] !== undefined && inviteResponse['account']['contactus_text'] !== undefined) {
        this.localStorage.setItem('contactus', inviteResponse['account']['contactus_text']);
        this._eventBus.contactUsAnnounced({
          contactUs: inviteResponse['account']['contactus_text'],
          isContactUsReceived: true
        });
      }
    }

    this.localStorage.setItem('f_name', inviteResponse['applicant']['first_name']);
    if (inviteResponse['applicant']['middle_initial'] === undefined) {
      this.localStorage.setItem('m_name', '');
    } else {
      this.localStorage.setItem('m_name', inviteResponse['applicant']['middle_initial']);
    }
    this.localStorage.setItem('l_name', inviteResponse['applicant']['last_name']);

    if (!!inviteResponse['workflow_instance']['id']) {
      this.localStorage.setItem('workflow_instance_id', inviteResponse['workflow_instance']['id']);
      this.localStorage.setItem('inviteId', inviteResponse['id']);
      this.localStorage.setItem('invite_id', inviteResponse['id']);
      this.localStorage.setItem('wps_number', inviteResponse['applicant']['provider_ref_number']);
    }
      
    if(inviteResponse['applicant'] !== undefined &&  inviteResponse['applicant']['data'] !== undefined
      && inviteResponse['applicant']['data']['APPLICANT_STATUS'] !== undefined ){
      this.localStorage.setItem('review_status', inviteResponse['applicant']['data']['APPLICANT_STATUS']);
    }

    if (!!inviteResponse['account']['name']) {
      this.localStorage.setItem('company_name', inviteResponse['account']['name']);
    }

    if (!!inviteResponse && !!inviteResponse['account'] && inviteResponse['account']['Switch_url']) {
      this.localStorage.setItem('switch_url', inviteResponse['account']['Switch_url']);
    }

    this.localStorage.setItem('is_gender_mandatory', inviteResponse['applicant']['is_gender_mandatory']);

    this._shareService.raisingEventTermsServiceHide(this._eventBus, false);

    if (inviteResponse['applicant']['status'] === 'DA_REDIRECT') {
      // this._next('/da-redirect');
      // DA REDIRECT this._next(response['/da-redirect']);
      this.localStorage.setItem('da_url', inviteResponse['da_url']);
      this._router.navigate(['/profile/redirect']);
    } else {
      let counsentCountry = inviteResponse['current_address_country'];
      this.localStorage.setItem('is_country_based_consent', inviteResponse['is_country_based_consent']);

      // Inform header component to disable language Icon after login only for EIAF.
      if (this.isInviteEiaf) {
        this._eventBus.languageListInInviteReceivedAnnounced({
          availableLangList: []
        });
      }

      let inviteTypeNotification = this._shareService.checkInviteIsNotification();
      if (inviteTypeNotification){
        this.localStorage.setItem('notification_mode','true');
        this._next('/dashboard');
        return;
      }

      // Check invite is RightId or Eiaf or Mi
      const isInviteTypeEnabled = this._shareService.processInviteType();
      const isInviteKYP = this._shareService.checkInviteIsKYP();
      let applicantInstructions = inviteResponse['applicant']['instructions'];
      if (!!applicantInstructions && applicantInstructions.length > 0 &&
        !(inviteResponse['applicant']['status'] === 'SUBMITTED' ||
          inviteResponse['applicant']['status'] === 'INACTIVE' ||
          inviteResponse['applicant']['status'] === 'CONSENT_DECLINE' ||
          inviteResponse['applicant']['status'] === 'INVITE_FAILURE' ||
          inviteResponse['applicant']['status'] === 'ASYNC_SUBMITTED' ||
          inviteResponse['applicant']['status'] === 'PENDING_REVIEW')) {
        this.localStorage.setItem("applicantInstructions", applicantInstructions);
        this._next('/instructions/customerInstructions');
      } else if (inviteResponse['is_country_based_consent'] && counsentCountry === undefined
      	&& (!isInviteTypeEnabled || (this.isInviteRightId && subType === AppConfig.RIGHT_ID_ROUTER
        && (ridStatus && ridStatus === 'OPTED_OUT_DEVICE' || ridStatus && ridStatus === 'OPTED_OUT' || ridStatus && ridStatus === 'DERIVED'))
        || (this.isInviteRightId && isInviteKYP && !!ridStatus && ridStatus !=='PENDING') )) {
        this._next('/country-consent');
      } else {
        $('#waitOnMfacallbackModal').modal('show');
        this._workflow.getWorkFlow(inviteResponse['workflow_instance']['id']).subscribe(response => {
          this.headerNameMasking(inviteResponse['applicant']);
          $('#waitOnMfacallbackModal').modal('hide');
          if (this.isInviteRightId && subType === AppConfig.RIGHT_ID_ROUTER) {
            if (!this.isRightIdStageCompleted()) {
              if (!isYoti && this.deviceService.isDesktop()) {
                this.errorMessage = <any>Error;
                this.errorMessage['errorMessage'] = 'UNSUPPORTED_DEVICE';
                this._shareService.redirectToUnsupportedDevice(this._service,this._router);
                window.track_event('unsupported device', { stage_name: 'mfa-callback', action_value: 'process' }, true, true);
                return;
              }

              this.trackObj = {
                stage_name: 'mfa-callback',
                action_value: 'save'
              };

              window.track_event('rightid invite, load js', this.trackObj, true, true);

              //Adding IDMetricsCaptureFramework.js to head tag if rightID
              let head = this.document.getElementsByTagName('head')[0];
              let script = document.createElement('script');
              script.src = '../../assets/js/IDMetricsCaptureFramework.js';
              head.appendChild(script);
            } else if (inviteResponse['is_country_based_consent'] && counsentCountry === undefined) {
              this._next('/country-consent');
              return;
            }
        }

        if (inviteResponse['applicant']['status'] === 'SUBMITTED' ||
            inviteResponse['applicant']['status'] === 'INACTIVE' ||
            inviteResponse['applicant']['status'] === 'CONSENT_DECLINE' ||
            inviteResponse['applicant']['status'] === 'INVITE_FAILURE' ||
            inviteResponse['applicant']['status'] === 'ASYNC_SUBMITTED' ||
            inviteResponse['applicant']['status'] === 'PENDING_REVIEW') {
            this._nextWithSecurityQuestion('/dashboard');
          } else {
            if (!this.isInviteMI) {
              this._workflow.getDynamicCDConfig(null);
            }

            this._workflow.storeDocBaseUrl();
            this._nextWithSecurityQuestion(response['URL']);
          }
        }, error => {
          window.track_event('get workflow by id failed', this.trackObj, true, true);
          this.localStorage.setItem("isMFACallBackError", true);
          $('#waitOnMfacallbackModal').modal('hide');
          this._next('/profile/thankyou');
        });
      }

      this.getCountryNameByCountryCode(counsentCountry);      
    }
  }

  setApplicantDataInLocalStorage(applicateData) {
    this.localStorage.setItem('first_name', applicateData['first_name']);
    this.localStorage.setItem('applicant_email', applicateData['email']);
    this.localStorage.setItem('last_name', applicateData['last_name']);
    this.localStorage.setItem('mobile', applicateData['mobile']);
  }

  getCountryNameByCountryCode(countryCode) {
    if (countryCode !== undefined) {
      let body = {};
      body['countryParam'] = countryCode;
      this._shareService.countryLookUpServiceByCountryCode(body).subscribe(response => {
        let countryData = response;
        let countryList = countryData['country_list'];

        if (countryList !== undefined && countryList.length === 1) {
          this.localStorage.setItem('consentCountry', JSON.stringify(countryList[0]));
        }
      });
    }
  }

  headerNameMasking(inviteResponse) {
    let nameConfig = this._workflow.getStepConfig('main-data-v1', LoginComponent.NAME_STEP_INDEX);
    let value = this._shareService.getEaPreferenceValueByKey(nameConfig['ea-preferences'], LoginComponent.NAME_MASKING_PREFERNCE_VALUE);
    let firstName = inviteResponse['first_name'];
    let lastName;

    if(value === "YES"){
      lastName = '*****'
    } else {
      lastName = inviteResponse['last_name'];
    }
    this._eventBus.announceMission({
      usename: firstName + this.middlename + lastName,
      isUserInfoShown: true
    });
  }

  _nextWithSecurityQuestion(url) {
    let secretQuestionSet = this.localStorage.getItem('secret_question_set');

    if (secretQuestionSet === 'false') {
      this.nextUrl = url;
      this.openSecurityQuestionModal();
    } else {
      this._next(url);
    }
  }

  private openSecurityQuestionModal() {
    $("#security_question_modal").modal("show");
  }

  isRightIdStageCompleted() {
    const completedMenus = this._workflow.getAllCompletedStageMenu();
    if (!!completedMenus && completedMenus.length > 0)  {
      const menus = completedMenus.filter(menu => menu === 'rightid-data-v1');
      return !!menus && menus.length > 0 ? true : false;
    }

    return false;
  }

  private saveToLocalUserData(profileRes: Object) {
    // Check if first name, middle name and last name exist in local. If yes, override them, else store in local
    // Checking if first name exists
    if (this.localStorage.getItem('first_name') === undefined) {
      this.localStorage.setItem('first_name', profileRes['first_name']);
    }

    // Checking if has_middle_name is true and then storing middle name
    if (profileRes['has_middle_name']) {
      if (this.localStorage.getItem('middle_name') === undefined) {
        this.localStorage.setItem('middle_name', profileRes['middle_name']);
      }
    }

    // Checking if last name exists
    if (this.localStorage.getItem('last_name') === undefined) {
      this.localStorage.setItem('last_name', profileRes['last_name']);
    }

    if (profileRes['name_suffix'] === null || profileRes['name_suffix'] === undefined ||
      profileRes['name_suffix'] === 'undefined') {
      this.localStorage.setItem('suffix_name', '');
    }

    // if (profileRes['mother_maiden_name'] !== null || profileRes['mother_maiden_name'] !== undefined ||
    //   profileRes['mother_maiden_name'] !== 'undefined') {
    //     this.localStorage.setItem('mother_maiden_name', profileRes['mother_maiden_name']);
    // }
  }
}
