<div class="group" [formGroup]="customSelectSubForm">
  <ng-select [items]="items" formControlName="{{controlName}}" [labelForId]="id" aria-required="true"
    [typeahead]="typeahead" [inputAttrs]="{ autocomplete : autocompleteText }"
    [typeToSearchText]="typeToSearchText" bindLabel="bindLabel" bindvalue="bindvalue" [(ngModel)]="selectedValue"
    (change)="trackValueChange($event, bindvalue)" (focus)="onFocus()" (clear)="clearValue()" (blur)="onBlur()" [readonly]="readonly">
    <ng-template *ngIf="!!displayValue" ng-option-tmp let-item="item">
      {{ item[displayValue] }}
    </ng-template>

    <ng-template *ngIf="!!dispValues && dispValues.length>1" ng-option-tmp let-item="item">
      <ng-template ngFor let-dispVal [ngForOf]="dispValues" let-i="index" let-cnt="count">
        {{ item[dispVal] }}<ng-template [ngIf]="i!=cnt-1">,</ng-template>
      </ng-template>
    </ng-template>
  </ng-select>
</div>
