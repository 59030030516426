<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12">
    <p class="black_text">{{ options['title'] }}</p>
    <hr>
  </div>
</div>
<div class="addCard m-b-10">
  <div class="row">
    <div class="col-xs-12 col-sm-4 col-md-3 dp-r-0" *ngFor="let data of options.data">
      <div class="clipCard" (click)="openViewProfilePopup(data,options['key'])">
        <!--<div class="verify">
                  <img src="/assets/img/verifiedimage.png" class="height-102">
              </div>-->
        <img src="/assets/img/house.png" height="30px">
        <p *ngIf="data['is_permanent_address'] && data['is_permanent_address'] === true"
          class="company myaddress_ellipsis" title="Permanent Address">{{'PERMANENT_ADDRESS' | translate}}</p>
        <p *ngIf="data['state_name'] && data['state_name'].length > 0 && data['is_permanent_address'] !== true"
          class="company myaddress_ellipsis" title="{{data['state_name']}}">{{data['state_name']}}</p>
        <p *ngIf="data['is_permanent_address'] === undefined || data['is_permanent_address'] !== true"
          class="text_ellipses">
          <span *ngIf="data['street_address'] && data['street_address'].length > 0">{{data['street_address']}}, </span>
          <span *ngIf="data['country_name'] && data['country_name'].length > 0">{{data['country_name']}}</span>

        </p>
        <p *ngIf="data['is_permanent_address'] !== undefined && data['is_permanent_address'] === true"
          class="text_ellipses">
          <span *ngIf="data['street_address'] && data['street_address'].length > 0">{{data['street_address']}}, </span>

        </p>
        <p *ngIf="data['is_permanent_address'] !== undefined && data['is_permanent_address'] === true"
          class="text_ellipses">
          <span *ngIf="data['city'] && data['city'].length > 0">{{data['city']}}, </span>
          <span *ngIf="data['state_name'] && data['state_name'].length > 0">{{data['state_name']}}, </span>
          <span *ngIf="data['zip'] && data['zip'].length > 0">{{data['zip']}}, </span>
          <span *ngIf="data['country_name'] && data['country_name'].length > 0">{{data['country_name']}}</span>
        </p>

        <span class="duration"
          *ngIf="!data['valid_to'] && !(data['is_permanent_address'] && data['is_permanent_address'] === true)">{{data['valid_from'] | dateFormat}}
          - Present</span>
        <span class="duration"
          *ngIf="data['valid_to']  && !(data['is_permanent_address'] && data['is_permanent_address'] === true)">{{data['valid_from'] | dateFormat}}
          - {{data['valid_to'] | dateFormat}}</span>
      </div>
    </div>
  </div>
</div>
