<div>

  <ul>
    <li *ngIf="!!viewProfileData['first_name']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['first_name'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['first_name']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['middle_name']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['middle_name'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['middle_name']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['last_name']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['last_name'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['last_name']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['suffix']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['suffix'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['suffix']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['is_maiden_name']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['is_maiden_name'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['is_maiden_name']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['start-date'] && viewProfileData['visibility'] ">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['start-date'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['start-date']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['end-date'] && viewProfileData['visibility']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['end-date'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['end-date']}}</div>
      </div>
    </li>
  </ul>
</div>
