import { RuleRegexName, RuleRegexValue } from './model/validation-rules-config';


export class ValidationRegexResolver {


  private static _ruleRegexMap: Map<RuleRegexName, RuleRegexValue> = new Map<RuleRegexName, RuleRegexValue>(
    [
      [RuleRegexName.nameFormat, RuleRegexValue.nameFormat],
      [RuleRegexName.kypAddressFormat, RuleRegexValue.kypAddressFormat],
      [RuleRegexName.ukFormattedZipCode, RuleRegexValue.ukFormattedZipCode]
    ]
  );



  static resolveRegex(regexName: RuleRegexName): RuleRegexValue{
      return ValidationRegexResolver._ruleRegexMap.get(regexName);
  }
}
