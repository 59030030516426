import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProfileConfig } from './../../profile.config';

@Component({
  selector: 'view-profile-personal',
  templateUrl: './view-profile-personal.component.html',
  providers: []
})

export class ViewProfilePersonalComponent {
  @Input() viewProfileData: Object;
  profileKey: string;

  constructor() {
  }

  ngOnInit() {
    this.profileKey = ProfileConfig.getViewPersonalDataKey();
  }
}