<div class="pad0 body_container container m-t-20 mar10 ">
  <div class=" anime-clean signup">
    <div class="row margin0">
      <div class="col-md-6 col-sm-6 hidden-xs dark_bg p-t-50">
        <h3 class="text-center m-t-10 pad5" *ngIf="!isInviteDelete && (inviteType==='BACKGROUND' || inviteType==='CONSENT')">
          {{'EXPIRED_HEADER_1'|translate
          }} <a href="#/login" class="font-large-size">{{'EXPIRED_SUBHEADER_3'|translate }}.</a></h3>
        <h3 class="text-center m-t-10 pad5" *ngIf="!isInviteDelete && inviteType==='MI'">{{'EXPIRED_HEADER_MI_1' | translate}}
        </h3>
        <h3 class="text-center m-t-10 pad5" *ngIf="!isInviteDelete && inviteType==='EIAF'">{{'EXPIRED_HEADER_EIAF_1' |
          translate}}</h3>
        <h3 class="text-center m-t-10 pad5" *ngIf="isInviteDelete">{{'DELETED_HEADER_1'|translate }}</h3>
      </div>
      <div class="pad0 col-xs-12  col-sm-6 col-md-6 signup_form">
        <div class="signup_container">
          <div class="row mobile-header visible-xs">
            <div class="col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 col-xs-12 ">
              <div class="header_txt_white">
                <h2 *ngIf="!isInviteDelete && (inviteType==='BACKGROUND' || inviteType==='CONSENT') ">{{'EXPIRED_HEADER_1'|translate }}</h2>
                <h2 *ngIf="!isInviteDelete && inviteType==='MI'">{{'EXPIRED_HEADER_MI_1' | translate}}
                </h2>
                <h2 *ngIf="!isInviteDelete && inviteType==='EIAF'">{{'EXPIRED_HEADER_EIAF_1' |
                  translate}}</h2>
                <h2 *ngIf="isInviteDelete">{{'DELETED_HEADER_1'|translate }}</h2>
              </div>
            </div>
          </div>
          <div *ngIf="!isInviteDelete">
            <div class="row m-t-40 p-t-50 pad15">
              <div class="col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 col-xs-12 ">
                <div class="header_txt_white">
                  <h2 *ngIf="inviteType==='BACKGROUND' || inviteType==='CONSENT'">{{'EXPIRED_HEADER_2'|translate }}</h2>
                  <h4 class="m-t-10" *ngIf="inviteType==='BACKGROUND' || inviteType==='CONSENT'">{{'EXPIRED_SUBHEADER_1'|translate }}</h4>
                  <h4 class="m-t-10" *ngIf="inviteType==='BACKGROUND' || inviteType==='CONSENT'">{{'EXPIRED_SUBHEADER_2'|translate }} <a
                      href="#/login">{{'EXPIRED_SUBHEADER_3'|translate }}.</a></h4>
                  <h4 class="m-t-10" *ngIf="inviteType==='MI'">{{'EXPIRED_SUBHEADER_MI_EIAF_1' | translate}} <a
                      href="#/login">{{'EXPIRED_SUBHEADER_3'|translate }}.</a></h4>
                  <h4 class="m-t-10 m-b-20" *ngIf="inviteType==='EIAF'">{{'EXPIRED_SUBHEADER_MI_EIAF_1' | translate}} <a
                      href="#/login">{{'EXPIRED_SUBHEADER_3'|translate }}.</a></h4>
                </div>
              </div>
            </div>
            <div class="addCard m-t-40 pad15">
              <div class="row">
                <div class="col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 col-xs-12 m-b-20">
                  <a>
                    <div class="signupCard">
                      <img *ngIf="inviteType==='BACKGROUND' || inviteType==='CONSENT' " src="../../assets/img/running.svg" height="75px">
                      <img *ngIf="inviteType==='MI'" src="../../assets/img/expiry.png" class="expiryImage" width="150px" height="121px">
                      <img *ngIf="inviteType==='EIAF'" src="../../assets/img/expiry.png" class="expiryImage" width="150px" height="121px">
                      
                      <p class="m-t-20 expiryGotoArea" *ngIf="inviteType==='BACKGROUND' || inviteType==='CONSENT'">{{'EXPIRED_CARD_1'|translate }}<a
                          href="#">{{'EXPIRED_CARD_2'|translate }}</a> {{'EXPIRED_CARD_3'|translate }}</p>
                    </div>
                  </a>
                </div>
              </div>
              </div> 
              </div>
              <div *ngIf="isInviteDelete">
                <div class="row m-t-40 mar-top80 pad15">
                  <div class="col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 col-xs-12 ">
                    <div class="header_txt_white">
                      <h4 class="m-t-10">{{'DELETED_SUBHEADER_1'|translate }}</h4>
                    </div>
                  </div>
                </div>
                <div class="row m-t-40 text-center padbot40 pad15 m-b-20">
                  <img src="../../assets/img/error_white.svg" height="125px">
                </div>
              </div>
        </div>
      </div>
    </div>
  </div>
  <div class="clearfix"></div>
</div>