<!-- Header section starts -->
<app-header></app-header>
<!-- Header section ends -->
<!-- Body section starts -->
<main>

  <div class="modal fade custom_modal" id="back-modal" data-backdrop="static" role="dialog" data-keyboard="false">
    <div class="modal-dialog sign-modal">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" (click)="closeModal()" data-dismiss="modal">&times;</button>
        </div>
        <div class="transparentbg modal-body">
          <h4 class="modal-title">{{ 'WARNING_MODAL_HEADER'}}
          </h4>
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="col-xs-12 col-sm-4 col-md-4  p_l_0  p_r_0 m-r-15">
              <button type="button" class="btn button font_extrabold" data-dismiss="modal"
                (click)="closeModal()">CONTINUE</button>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-4 p_l_0  p_r_0 m-r-15">
              <button type="button" class="btn button grow font_extrabold" data-dismiss="modal"
                (click)="doLogout()">LOGOUT</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade custom_modal" id="error-modal" data-backdrop="static" role="dialog" data-keyboard="false">
    <div class="modal-dialog sign-modal">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" (click)="closeModal()" data-dismiss="modal">&times;</button>
        </div>
        <div class="transparentbg modal-body">
          <h4 class="modal-title">'We have encountered an error. Please logout and login or Chat with our representative'
          </h4>
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="col-xs-12 col-sm-4 col-md-4  p_l_0  p_r_0 m-r-15">
              <button type="button" class="btn button font_extrabold" data-dismiss="modal"
                (click)="closeErrorModal()">OKAY</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade custom_modal" id="auth-error-modal" data-backdrop="static" role="dialog" data-keyboard="false">
    <div class="modal-dialog sign-modal">
      <div class="modal-content">
        <div class="modal-header">
        </div>
        <div class="transparentbg modal-body">
          <h4 class="modal-title">{{ authErrorMsg }}
          </h4>
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="col-xs-12 col-sm-4 col-md-4  p_l_0  p_r_0 m-r-15">
              <button type="button" class="btn button font_extrabold" data-dismiss="modal"
                (click)="closeAuthErrorModal()">{{ okButtonText }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="globalModal" class="router-load-indicator">
    <div class="router-load-content">{{ 'WAIT_ON_AGGREMENT_MODAL_TEXT' | translate }}</div>
  </div>

  <router-outlet></router-outlet>
</main>
<!-- Body section ends -->
<!-- Footer section starts -->

<app-footer></app-footer>
<!-- Footer section ends -->