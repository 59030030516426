import { Component, OnInit } from '@angular/core';
import { PreAuthService } from 'app/pre-auth/pre-auth.service';
import { LocalStorageService } from 'framework/localstorage.service';
import { Observable } from 'rxjs';
import {SharedService} from '../shared/services/shared.service';

declare var window: any;

@Component({
  selector: 'app-base-set-password',
  templateUrl: './base-set-password.component.html',
  styleUrls: ['./base-set-password.component.css']
})
export class BaseSetPasswordComponent implements OnInit {
  selectedPage = '';
  isAuthyCallback:boolean = false;
  isAuthyLoggedIn: boolean = false;

  constructor(private _preAuth: PreAuthService,
              private localStorage: LocalStorageService) { }

  ngOnInit(): void {
    let systemId = this.localStorage.getItem('system_id');
    this.isAuthyCallback = this.localStorage.getItem("isAuthyCallback");
    this.isAuthyLoggedIn = this.localStorage.getItem("isAuthyLoggedIn");

    if (!!systemId) {// call user auth euth engine when system id available
      this._preAuth.getUserAuthEngine(systemId).subscribe(res => {
        if (!!res && !!res.auth_engine) {
          this.localStorage.setItem('user_auth_engine', res.auth_engine);
          this.identifyAuthPlatform(res.auth_engine);
        } else {
          this.localStorage.setItem('user_auth_engine', "OKTA");
          this.identifyAuthPlatform("OKTA");
        }
      },
        err => {
          if (!!err) {
            console.log(err);
            this.localStorage.setItem('user_auth_engine', "OKTA");
            this.identifyAuthPlatform("OKTA");
          }
        });
    } else {
      this.localStorage.setItem('user_auth_engine', "OKTA");
      this.identifyAuthPlatform("OKTA");
    }
    
  }

  identifyAuthPlatform(authEngine): void {
    const isMFAEnabled = this.localStorage.getItem('pa_enable_mfa');

    if (!!isMFAEnabled && !!isMFAEnabled.toUpperCase() && 'YES' === isMFAEnabled.toUpperCase()) {
      const authType = this.localStorage.getItem('user_auth_model');

      if (!authType || !authType.toUpperCase()) {
        this.selectedPage = 'KEYCLOAK';
      } else if (authType.toUpperCase() === 'STANDARD') {
        if (!authEngine || !authEngine.toUpperCase() || authEngine.toUpperCase() === 'KEYCLOAK') {
          this.selectedPage = 'KEYCLOAK';
        }
        else {
          this.selectedPage = 'OIDC';
        }
      } else if (authType.toUpperCase() === 'MFA') {
        this.selectedPage = 'OIDC';
      } else {
        this.selectedPage = 'KEYCLOAK';
      }
    }
    else {
      this.selectedPage = 'KEYCLOAK';
    }

    window.track_event( this.selectedPage + ' selected', { stage_name: 'base-set-password', action_value: 'load' }, true, true);
  }
}
