import { Field } from 'framework/dynamicCard/models/field.interface';
import { FieldConfig } from 'framework/dynamicCard/models/fieldconfig.interface';
import { FormGroup } from '@angular/forms';
import { Component } from '@angular/core';
import * as dateUtils from './../../../utils/date.utils';
import { TranslateService } from 'framework/i18n';

@Component({
  selector: 'form-date-range',
  styleUrls: ['form-date-range.component.scss'],
  templateUrl: './form-date-range.component.html'
})
export class FormDateRangeComponent implements Field {
  config: FieldConfig;
  group: FormGroup;
  startDate: any;
  endDate: any;
  isDatePeriodError: boolean = false;
  engMonthNames: any = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  month_names = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  monthNames = this._ts.instant('MONTH_NAMES');
  isStartDateOutOfRange: boolean = false;
  isEndDateOutOfRange: boolean = false;
  sartErrorMessage: string = "";
  endErrorMessage: string = "";
  invalidStartDate: boolean = false;
  invalidEndDate: boolean = false;

  constructor(private _ts: TranslateService) {

  }

  ngOnInit() {

  }

  getStartDpValue(value) {
    this.startDate = new Date(this.getDateEngValue(value));
    // console.log("FormDateRangeComponent getStartDpValue  startDate ", this.startDate);
    this.isStartDateOutOfRange = false;
    this.invalidStartDate = false;
    this.validateDate(this.startDate, this.endDate);
  }

  getEndDpValue(value) {
    this.endDate = new Date(this.getDateEngValue(value));
    // console.log("FormDateRangeComponent getEndDpValue  endDate ", this.endDate);
    this.isEndDateOutOfRange = false;
    this.invalidEndDate = false;
    this.validateDate(this.startDate, this.endDate);
  }

  private getDateEngValue(languageDate) {

    return languageDate === '' ? languageDate : dateUtils.formatEngDateLabel(languageDate, this.monthNames, this.engMonthNames);
  }

  public validateDate(startDate: any, endDate?: any) {
    let daysDiff;
    let formatedStartDate = '';
    let formatedEndDate = '';
    if (this.startDate !== undefined && this.endDate === undefined) {
      formatedStartDate = this.getFormattedDate(startDate);
      if (formatedStartDate !== undefined && formatedStartDate !== null) {
        this.invalidStartDate = false;
        this.isDatePeriodError = false;

      } else {
        this.invalidStartDate = true;
        this.isDatePeriodError = true;
      }
    }

    if (this.startDate !== undefined && this.endDate !== undefined) {
      formatedStartDate = this.getFormattedDate(startDate);
      formatedEndDate = this.getFormattedDate(endDate);
      daysDiff = dateUtils.dateDiffInDays(startDate, endDate);
    }

    if (this.isStartDateOutOfRange) {
      this.invalidStartDate = true;
    }
    if (this.isEndDateOutOfRange) {
      this.invalidEndDate = true;
    } else if (daysDiff < 0) {
      this.invalidEndDate = true;
      this.isDatePeriodError = false;
      this.invalidEndDate = true;
      this.endErrorMessage = 'EMPLOYMENT_JOB_PEROID_ERROR_2';
    }
    else {
      this.isDatePeriodError = true;
      this.invalidEndDate = false;
      this.endErrorMessage = '';
    }

    if (!this.invalidStartDate && !this.invalidEndDate && this.startDate !== undefined && this.endDate !== undefined) {
      let dateRange = formatedStartDate + ' - ' + formatedEndDate;
      // console.log("dateRange ----", dateRange);
      this.group.controls[this.config.name].setValue(dateRange);
    } else {
      this.group.controls[this.config.name].setValue('');
    }

  }

  startDateErrorHandler(event: any) {
    this.isStartDateOutOfRange = event['isInvalidDate'];
    if (this.isStartDateOutOfRange) {
      this.group.controls[this.config.name].setValue('');
      this.sartErrorMessage = event['errorMessage'];
      this.invalidStartDate = true;
    }
  }

  endDateErrorHandler(event: any) {
    this.isEndDateOutOfRange = event['isInvalidDate'];
    if (this.isEndDateOutOfRange) {
      this.group.controls[this.config.name].setValue('');
      this.endErrorMessage = event['errorMessage'];
      this.invalidEndDate = true;
    }
  }

  getFormattedDate(date) {
    var formattedDate = date;
    if (date) {
      var year = date.getFullYear();
      var month = date.getMonth();

      var day = date.getDate();
      if (day < 10) {
        day = '0' + day;
      }
      formattedDate = day + "/" + this.month_names[month] + "/" + year;
    }

    return formattedDate;
  }

  openDatePeriodError() {
    this.isDatePeriodError = !this.isDatePeriodError;
  }

  closeDatePeriodError() {
    this.isDatePeriodError = !this.isDatePeriodError;
  }

}