import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { EventBusService } from '../eventbus.service';
import { DynamicFormService } from './containers/dynamic-form/dynamic-form.service';
import { WotcSharedService } from './shared/shared.service';
import { DynamicHeaderComponent } from './components/dynamic-header/dynamic-header.component';
import { DynamicFormComponent } from './containers/dynamic-form/dynamic-form.component';
import { FormButtonComponent } from './components/form-button/form-button.component';
import { FormInputComponent } from './components/form-input/form-input.component';
import { FormESignatureComponent } from './components/form-esignature/form-esignature.component';
import { FormHiddenComponent } from './components/form-hidden/form-hidden.component';
import { FormSelectComponent } from './components/form-select/form-select.component';
import { FormRadioComponent } from './components/form-radio/form-radio.component';
import { FormRadioExplanationComponent } from './components/form-radio-explanation/form-radio-explanation.component';
import { FormCheckboxComponent } from './components/form-checkbox/form-checkbox.component';
import { FormTextareaComponent } from './components/form-textarea/form-textarea.component';
import { LocationPickerComponent } from './components/location-picker/location-picker.component';
import { FormDateComponent } from './components/form-date-picker/form-date.component';
import { DynamicFieldDirective } from './components/dynamic-field/dynamic-field.directive';
import { wotcDocumentCategoryComponent } from './components/file/document-category.component';
import { FormObjectSelectComponent } from './components/form-object-select/form-object-select.component';
import { FormNumericInputComponent } from './components/form-numeric-input/form-numeric-input.component';
import { FormHtmlLabelComponent } from './components/form-html-label/form-html-label.component';
import { FormCheckboxExtendedComponent } from './components/form-extended-checkbox/form-checkbox-extended.component';
import { PhoneComponent } from './components/phone/phone.component';
import { TranslateService } from '../i18n';
import { SharedModule } from 'app/shared/shared.module';
import { SharedService } from 'app/shared/services/shared.service';
import { FormSSNComponent } from './components/form-ssn/form-ssn.component';
import { FormDateRangeComponent } from './components/form-date-range/form-date-range.component';
import { DynamicDocumentUploadComponent } from './components/document-upload/document-upload.component';
// import { SpaceInputValidator } from '../../framework/form/input-empty-validator.directive';
import { DownloadFileComponent } from './components/download-file/download-file.component';
import { MultipleCustomSelectModule } from './components/multiple-custom-select/multiple-custom-select.module';
import { FormTextContentComponent } from './components/form-text-content/form-text-content.component';
import { DocumentMitigationComponent  } from './components/document-mitigation/document-mitigation.component';
import { FormsModule } from '@angular/forms';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    MultipleCustomSelectModule,
    FormsModule
  ],
  declarations: [
    DynamicHeaderComponent,
    DynamicFieldDirective,
    DynamicFormComponent,
    FormHiddenComponent,
    FormButtonComponent,
    FormInputComponent,
    FormESignatureComponent,
    FormSelectComponent,
    FormCheckboxComponent,
    FormRadioComponent,
    FormRadioExplanationComponent,
    FormTextareaComponent,
    LocationPickerComponent,
    FormDateComponent,
    wotcDocumentCategoryComponent,
    FormObjectSelectComponent,
    FormNumericInputComponent,
    FormHtmlLabelComponent,
    FormCheckboxExtendedComponent,
    PhoneComponent,
    FormSSNComponent,
    FormDateRangeComponent,
    DynamicDocumentUploadComponent,
    DownloadFileComponent,
    FormTextContentComponent,
    DocumentMitigationComponent
  ],
    exports: [
        DynamicHeaderComponent,
        DynamicFormComponent,
        wotcDocumentCategoryComponent,
        DownloadFileComponent,
        DynamicFieldDirective,
        DocumentMitigationComponent
    ],
  providers: [EventBusService, DynamicFormService, WotcSharedService, TranslateService, SharedService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DynamicFormModule { }
