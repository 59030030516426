export class GenderConfig {
    static genderTypes: any = [
        { id: 'Male', name: 'PAGENDER_LIST_1' },
        { id: 'Female' , name: 'PAGENDER_LIST_2' }
    ];

    static genderTypesForClipboard: any = [
        { id: 'Male', name: 'PAGENDER_LIST_1' },
        { id: 'Female' , name: 'PAGENDER_LIST_2' },
        { id: 'Other' , name: 'PAGENDER_LIST_3' }
    ];
}