export class DisclosureConfig {
    static getDisclosureDetails(): Array<Object> {
        let criminalDisclosureConfig = [{
            primaryText: 'Disclosure Information',
            secondaryText: 'Please answer the questions below.',
            fields: [{
                        key: 0,
                        radio: {
                            label: 'Answer01',
                            type: 'radio',
                            required: true,
                            option: [{value: true, label: 'Yes', control : 'enforcement'}, {value: false, label: 'No', control : 'enforcement'}],
                        },
                        textarea: {
                            label: 'Details',
                            type: 'textarea',
                            control: 'enforcementDetails',
                            required: true,
                            maxlength: 2000,
                            rows: 6,
                            isTextareaEnable: false
                        }
                    },
                    {
                        key: 1,
                        radio: {
                            label: 'Answer02',
                            type: 'radio',
                            required: true,
                            option: [{value: true, label: 'Yes', control : 'convicted'}, {value: false, label: 'No', control : 'convicted'}],
                        },
                        textarea: {
                            label: 'Details',
                            type: 'textarea',
                            control: 'convictedDetails',
                            required: true,
                            maxlength: 2000,
                            rows: 6,
                            isTextareaEnable: false
                        }
                    }],
            actions: [{
                        name: 'NEXT',
                        type: 'submit',
                    },
                    {
                        name: 'SAVE & EXIT',
                        type: 'submit'
                    }]
        }];

        return criminalDisclosureConfig;
      }

    static getDisclosureState() {
        let stateConfig = {
                        current_stage: 'criminal-data-v1',
                        current_step: 'selfdisclosure-step-v1',
                        current_step_index: 1,
                        current_sub_step: 'disclosure',
                        current_sub_step_index: 0,
                        current_iteration: 0,
                        current_status: 'PENDING',
                        stage_status: 'PENDING'
                    };
        return stateConfig;
    }
  }
