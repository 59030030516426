import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

declare var jQuery: any;
@Component({
  selector: 'language-picker',
  templateUrl: './language-picker.component.html'
})

export class LanguagePickerComponent implements OnInit {

  @Input() languagePickerConfig: any;
  @Output() onLanguagePick: EventEmitter<any> = new EventEmitter();

  selectedLanguage: String = '';

  constructor() { }

  ngOnInit() {
    // console.log('language List: ' + JSON.stringify(this.languagePickerConfig));
    this.setDefaultLanguage();
  }

  setDefaultLanguage() {
    for(let option of this.languagePickerConfig) {
      if(option['addId'] === 'activeLanguageList') {
        this.selectedLanguage = option['langWithCountryCode'];
      }
    }
  }

  setUpdatedLanguage(language){
    for(let option of this.languagePickerConfig) {
        if(option['langWithCountryCode'] === language['langWithCountryCode']) {
          this.onLanguagePick.emit(option);
        }
    }
  }
}
