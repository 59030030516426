<div class="col-md-4 col-sm-4" style="display:none">
    <input type="file" capture="camera" accept="image/*" id="takePictureField" (change)="handleInputChange($event)">
</div>
<div class="col-md-4 col-sm-4 cameraIcon" *ngIf="!isMobileDevice">
    <div id="test_camera"></div>
    <i class="icon icon-photo-camera hidden-xs hidden-sm" (click)="takePic()"></i>
</div>

<div class="col-sm-8 col-md-8 ListOfUploadedItems">
    <div appSortable (orderChanged)="orderChangeForCamera($event)">
        <div class="col-md-3 col-sm-4 col-xs-6 uploadDocImg" *ngFor="let img of imagesList; let i = index" draggable="true" [attr.data-index]="i" [attr.data-org-index]="i">
            <div class="thumbnail">
                <span class="docDelIcon" (click)="onDeleteImg(img.id)">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </span>
                <img src="{{img.src}}" class="uploadMainImg" id="{{img.id}}" style="width:100%">
                <div class="caption">
                    <p>{{'CAMERA_PREVIEW' | translate:[i] }}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-3 col-sm-4 col-xs-6 uploadArea">
        <div class="thumbnail">
            <label class="uploader" [class.loaded]="loaded" (click)="takePic()"> <span class="addNewText"> {{ 'COMMONS_ADD_NEW' | translate}}</span>
            <!-- <input type="file" name="file" (change)="handleInputChange($event)"> -->
            </label>
        </div>
    </div>
</div>