import { Component, EventEmitter, OnInit } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { SharedService } from "app/shared/services/shared.service";
import { Field } from "framework/dynamicCard/models/field.interface";
import { FieldConfig } from "framework/dynamicCard/models/fieldconfig.interface";
import { UtilService } from "framework/utils/util.service";
import { DynamicDetailsCountry } from "./dynamic-details-country";
import { distinctUntilChanged, switchMap, catchError, filter } from 'rxjs/operators';
import { of } from 'rxjs';
import * as dateUtils from './../../../framework/utils/date.utils';
@Component({
  selector: "dynamic-details-country",
  templateUrl: "./dynamic-details-country.component.html",
  styleUrls: ["./dynamic-details-country.component.css"],
})
export class DynamicDetailsCountryComponent implements Field, OnInit {
  forms: FormArray;
  currentIndex = 0;
  enabled = false;
  countryList: Array<any>;
  countryError: boolean;
  countryName: string;
  isIsoCodeLookup: boolean = false;
  showCountryError: boolean = false;
  selectedCountry: string;
  maximumDate: Date;
  maxValue:string;
  errorMsgConfig: Object = {};
  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private shareService: SharedService,
    private _util: UtilService) { }
  config: FieldConfig = {};
  group: FormGroup;
  countryTypeahead = new EventEmitter<string>();

  ngOnInit(): void {
    let now = new Date();
    this.maximumDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    this.maxValue = dateUtils.globalDateTransform(this.maximumDate, 'YYYY-MMM-DD', this.shareService.getEngMonths());
    this.forms = this.fb.array([]);
    this.group.removeControl(this.config.name);
    this.group.addControl(this.config.name, this.forms);
    this.route.data.subscribe((response: any) => {
      let bankruptcyKnockoutField = response.intlFields.fields.ListOfinputs.filter(input => input[0].name === this.config.name)[0];
      let data = [];
      if (bankruptcyKnockoutField && Array.isArray(bankruptcyKnockoutField[0].value)) {
        data = bankruptcyKnockoutField[0].value;
      } else {
        data = response.intlFields.fields.KnockoutInfoList;
      }

      this.prepopulateData(data.filter(d => d.type === this.config.name));
      this.countryLookUp(null, null);
      this.countryServerSideSearch();
    });
  }

  private prepopulateData(data: DynamicDetailsCountry[]) {
    this.enabled = data.length > 0;
    if (this.enabled) {
      for (const details of data) {
        this.addNewForm(details);
      }
    }
  }

  public addNewForm(data?: DynamicDetailsCountry) {
    this.forms.push(this._initForm(data));
  }

  private _initForm(data?: DynamicDetailsCountry): FormGroup {
    let formGroup =  this.fb.group({
      ID: this.fb.control(data?.ID ? data.ID : null),
      type: this.fb.control(data?.type ? data.type : this.config.name),
      country_name: this.fb.control(data?.country ? data.country : ""),
      details: this.fb.control(data?.details ? data.details : "", [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(4000),
      ]),
      country: this.fb.control(data?.country ? data.country : "", [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(255),
      ]),
    }as Record<string, AbstractControl<any>>);

    if(this.config.name == 'DIRSHIP_CUR_KNOCKOUT') {
      formGroup.addControl('nature_of_business', new FormControl(data?.nature_of_business ? data.nature_of_business : "", [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(200),
      ])as FormControl<string>);
    
      formGroup.addControl('directorship_start_date', new FormControl(data?.directorship_start_date ?  dateUtils.globalDateTransform(new Date(data?.directorship_start_date), 'YYYY-MMM-DD', this.shareService.getEngMonths()) : "", [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(200),
      ])as FormControl<string>);
    }
    return formGroup as FormGroup;
  }

  public removeForm(index: number): void {
    this.forms.removeAt(index);
  }

  getControl(key: number, formControlName: string) {
    if (this.forms.length > key) {
      return this.forms.at(key).get(formControlName);
    }
  }

  setValue(value: string) {
    this.enabled = value === "yes" ? true : false;
    if (value === "yes" && this.forms.length < 1) {
      this.addNewForm();
    }
    if (value === "no") {
      this.forms.clear();
      this.currentIndex = 0;
    }
  }

  countryLookUp(key: number, formControlName: string) {

    let countryNameValue = key && formControlName && !!this.forms.at(key).get(formControlName)['country'] ? this.forms.at(key).get(formControlName)['country'] : '';
    countryNameValue = !!countryNameValue ? countryNameValue : '';
    let queryParam = {
      'countryParam': countryNameValue.trim()
    };
    this.shareService.countryCodeLookupSwitch(queryParam).subscribe(response => {
      let countryData = response;
      this.countryList = countryData['country_list'];
    }, error => {
    });
  }

  trackChange(value, form) {
    this.setCountry(value, form);
  }

  setCountry(countryData: any, form: FormGroup) {
    if (!!countryData) {
      this.countryName = countryData['country_name'];
      form.controls['country'].setValue(countryData['country_name']);
      form.controls['country_name'].setValue(countryData['country_name']);
    } else {
      form.controls['country'].setValue('');
      form.controls['country_name'].setValue('');
    }
  }

  setShowError(flag) {
    this.updateErrorConfig(flag);
    this.showCountryError = flag;
  }

  private updateErrorConfig(isMessagePopUpShow) {
    this.errorMsgConfig['isMessagePopUpShow'] = isMessagePopUpShow;
  }

  prepareCountry(data: any, form: FormGroup) {
    /**
     * If we found country which is entered in the country list.. It will sent the country.
     * else... Data is coming from backend.. we have to bind into the field and proceed further.
     */
    let enteredCountry: string = form.get('country').value;
    if (this.countryList !== undefined && this.countryList.length > 0 && enteredCountry !== undefined) {
      for (let index = 0; index < this.countryList.length; index++) {
        if (enteredCountry.toUpperCase() === this.countryList[index]['country_name'].toUpperCase()) {
          this.setCountry(this.countryList[index], form);
          break;
        }
      }
    } else if (!this._util.isEmpty(data) && this.countryList.length === 0 && enteredCountry) {
      data['country_name'] = data['country_name'].toUpperCase();
      this.setCountry(data, form);
    }
  }
  getSelectedCountry(form: FormGroup) {
    return form.get('country_name').value;
  }

  private countryServerSideSearch() {
    this.countryTypeahead.pipe(
      distinctUntilChanged(),
      filter(term => { return (term !== undefined && term !== null) }),
      switchMap(term => this.shareService.countryCodeLookupSwitch({ countryParam: term })
        .pipe(
          catchError(() => of({ 'country_list': [] }))
        )
      )
    ).subscribe(countryData => {
      this.countryList = countryData['country_list'];
    }, (err) => {
      console.log(err);
      this.countryList = [];
    });
  }

  onClearData() {
    this.countryServerSideSearch();
  }

  getLabel() {
  let label = 'DETAILS';
  if (this.isDirectorShip()){
    return 'COMPANY_NAME';
  } else {
    return label;
  }
  }

  getType(){
    let type = 'textarea';
    if (this.isDirectorShip()){
      return 'input';
    } else {
      return type;
    }

  }

  isDirectorShip() {
    return this.config.name === 'DIRSHIP_CUR_KNOCKOUT' 
    || this.config.name === 'DIRSHIP_PAST_KNOCKOUT' 
    || this.config.name === 'DIRSHIP_SHRHOLDING_KNOCKOUT';
  }

  isCurDirectorShip(){

    return this.config.name === 'DIRSHIP_CUR_KNOCKOUT';
  }


  getDpValue(value, form) {
    //debugger;
    //form.controls['directorship_start_date'].setValue(value);
  }

  isStartDateIsFutureDate: boolean = false;
  getStartDateError(obj) {
    this.isStartDateIsFutureDate = false;
    if (obj !== undefined && obj !== null) {
      if (obj['directorship_start_date']) {
        this.isStartDateIsFutureDate = true;
      }
    }
  }

  

}
