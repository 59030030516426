import { Pipe, PipeTransform } from '@angular/core';
import { SharedService } from 'app/shared/services/shared.service';

/*
 * A Dynamic DataSeet string interpolation plugin.
 *
 * Current supports
 *
 *  1. Custom Form Variable
 *  2. Any POJsO - Plain Old Javascript Object
 *  *
 * Usage:
 *   value | interpolate | async
 *   value | interpolate | async
 *
 * Example:
 *   {{ htmlLabel | interpolate | async }}
 *   {{ htmlLabel | interpolate | async }}
*/
@Pipe({ name: 'interpolate' })
export class InterpolationPipe implements PipeTransform {
  constructor(private _sharedService: SharedService) { }
  transform(value: string): any {
    return this._sharedService.getInterpolationData( value );
  }
}