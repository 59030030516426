import { FieldValidationRule } from './field-validation-rule';
import { RuleFormGroupNames, RuleWorkflow, ValidationRulesConfig } from './validation-rules-config';

export class ValidationRulesFallbackResolver {
//^$|^[a-zA-Z]{1,2}$|^[a-zA-Z]{1}[a-zA-Z"-]*[a-zA-Z]{1}$
  private static _fallbackDefaultMap: Map<RuleFormGroupNames, FieldValidationRule[]> = new Map<RuleFormGroupNames, FieldValidationRule[]>(
    [
      [RuleFormGroupNames.residentialData, ValidationRulesConfig.defaultResidentialDataAddressRules],
      [RuleFormGroupNames.myProfileName, ValidationRulesConfig.defaultMyProfileNameRules],
      [RuleFormGroupNames.myProfileAlias, ValidationRulesConfig.defaultMyProfileAliasRules],
      [RuleFormGroupNames.demoData, ValidationRulesConfig.dynamicTestRules],
    ]
  );
  private static _fallbackKypMap: Map<RuleFormGroupNames, FieldValidationRule[]> = new Map<RuleFormGroupNames, FieldValidationRule[]>(
    [
      [RuleFormGroupNames.residentialData, ValidationRulesConfig.kypResidentialDataAddressRules],
      [RuleFormGroupNames.myProfileName, ValidationRulesConfig.kypMyProfileNameRules],
      [RuleFormGroupNames.myProfileAlias, ValidationRulesConfig.kypMyProfileAliasRules]
    ]
  );


  static resolve(workflow: RuleWorkflow, formGroup: RuleFormGroupNames): FieldValidationRule[] {

    let validationRule: FieldValidationRule[] = undefined;

    switch (workflow) {
      case RuleWorkflow.KYP:
        validationRule = ValidationRulesFallbackResolver._fallbackKypMap.get(formGroup);
        break;
      case RuleWorkflow.DEFAULT:
        validationRule = ValidationRulesFallbackResolver._fallbackDefaultMap.get(formGroup);
        break;
      default:
        throw Error('No rule fallback defined for:' + workflow);
    }

    console.warn('resolved fallback rule for wf:', workflow, validationRule);
    if (validationRule === undefined) {
      throw Error('No fallback rule found for key: ' + formGroup + ', wf: ' + workflow);
    }

    return validationRule;
  } // eof resolver


} // eof class



