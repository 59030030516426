import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {SharedModule} from './../shared/shared.module';
import {MfaVerificationComponent} from './mfa-verification.component';

@NgModule({
  imports: [FormsModule, SharedModule],
  declarations: [MfaVerificationComponent],
  providers: [],
  exports: [MfaVerificationComponent],
})

export class MfaVerificationModule {

}
