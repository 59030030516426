<!-- Template to display Module Specific ATS data NEED TO WORK ON CSS -->
<div class="invite inviteposition" [class.atsStripAlignment]="source == 'education_institution' || source == 'license_type'">
  <div class="atscard">

    <div *ngIf="source == 'mvr_country'">
      <div id="container" class="force-osx-scrollbar m-b-20">
        <div class="contents">
          <div class="addCard inlinecardcss">
            <div class="dashboardCard maarright15 atsselect">
              <img height="50px" src="../../assets/img/add.png">
              <p class="boldcom">{{ 'CLIPBOARD_ADD_NEW' | translate}}</p>
              <span class="boldcom"></span>
              <button type="button" class="btn invitebtn" (click)="addNewData()">{{ 'COMMONS_SELECT' | translate}}</button>
            </div>
          </div>
          <div class="addCard inlinecardcss" *ngFor="let ats of data;  let i = index;">
            <div class="dashboardCard maarright15 atsselect">
              <img src="../../assets/img/driver-license.png" height="50px">
              <p class="boldcom mvrOverflowText">{{ats['State']}}, {{ats['Country']}}</p>
              <span class="boldcom">{{ats['License Number']}}</span>
              <button type="button" class=" btn invitebtn" (click)="updateAtsData(i, ats['delete-link'])">Select</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="source == 'education_institution'">
      <div id="container" class="force-osx-scrollbar m-b-20">
        <div class="contents">
          <div class="inlinecardcss adjustCard">
            <div class="addCard">
              <div class="dashboardCard maarright15 atsselect" [ngClass]="{'activeborder': selectedCardIndex === -1}">
                <img height="50px" src="../../assets/img/add.png">
                <p class="padtop0 boldcom boldtext_ellipses">{{ 'CLIPBOARD_ADD_NEW' | translate}}</p>
                <span class="boldcom margtop10"></span>
                <span class="boldcom"></span>
                <button type="button" class="btn invitebtn" (click)="addNewData()">{{ 'COMMONS_SELECT' | translate}}</button>
              </div>
            </div>
          </div>
         <span *ngFor="let ats of data;  let i = index;">
            <div class="inlinecardcss adjustCard" *ngIf="ats['display_card']" >
            <div class="addCard" >
            <div class="dashboardCard maarright15 atsselect" [ngClass]="{'activeborder': selectedCardIndex === i}" >
              <img src="../../assets/img/mortarboard.png" height="50px">
              <p class="padtop0 boldcom boldtext_ellipses">{{ats['Name']}}</p>
              <span class="boldcom margtop10">{{ats['Degree Type'] | translate}}</span>
              <span class="boldcom">{{ats['Degree Date']}}</span>
              <button type="button" class=" btn invitebtn" (click)="updateAtsData(i, ats['delete-link'])">{{ 'COMMONS_SELECT' | translate}}</button>
            </div>
          </div>
          </div>
          </span>
        </div>
      </div>
    </div>

    <div *ngIf="source == 'employment_company'">
      <div id="container" class="force-osx-scrollbar m-b-20">
        <div class="contents">
          <span *ngFor="let ats of data;  let i = index;">
          <div class="inlinecardcss adjustCard" *ngIf="ats['display_card']" >
            <div class="addCard"> 
            <div class="dashboardCard maarright15 atsselect" [ngClass]="{'activeborder': selectedCardIndex === i}">
              <img src="../../assets/img/briefcase.png" height="50px">
              <p class=" boldcom boldtext_ellipses">{{ats['Name']}}</p>
              <span class="boldcom margtop10">{{ats['Job Title']}}</span>
              <span class="boldcom margtop10" *ngIf="isEmploymentDateToShow">{{ats['End Date']}} - {{ats['Start Date']}}</span>
              <span class="boldcom margtop10">{{ats['type']}}</span>
              <button type="button" class="btn invitebtn" (click)="updateAtsData(i, ats['delete_link'])">{{ 'COMMONS_SELECT' | translate}}</button>
            </div>
            </div>
          </div>
          </span>
        </div>
      </div>
    </div>


    <div *ngIf="source == 'residence_country'">
      <div id="container" class="force-osx-scrollbar m-b-20">
        <div class="contents">
          <div *ngIf="showAddNew" class="addCard inlinecardcss">
              <div class="dashboardCard maarright15 atsselect">
                  <span class="boldcom"></span><img height="50px" src="../../assets/img/add.png">
                  <p class="boldcom boldtext_ellipses">{{ 'CLIPBOARD_ADD_NEW' | translate}}</p>
                  <span class="boldcom"></span>
                  <button type="button" class="btn invitebtn" (click)="addNewData()">{{ 'COMMONS_SELECT' | translate}}</button>
              </div>
          </div>  
          <div class="addCard inlinecardcss" *ngFor="let ats of data;  let i = index;">
            <div class="dashboardCard maarright15 atsselect">
              <!-- <span class="boldcom">{{ats['Source']==='ATS'?ats['Source']:ats['Source'].substring(0,2) }}</span> -->
              <img src="../../assets/img/house.png" height="50px">
              <p class="boldcom boldtext_ellipses">{{ats['State']}}</p>
              <span class="boldcom">{{ats['Street Address']}}, {{ats['Country']}}</span>
              <span class="boldcom">{{ats['Valid_from']}} - {{ats['Valid_to']}}</span>
              <button type="button" class="btn invitebtn" (click)="updateAtsData(i, ats['delete-link'])">{{ 'COMMONS_SELECT' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div *ngIf="source == 'pa-alias'">
      <div id="container" class="force-osx-scrollbar m-b-20">
        <div class="contents">
          <div class="addCard inlinecardcss" *ngFor="let ats of data;  let i = index;">
            <div class="dashboardCard maarright15 atsselect">
              <img src="../../assets/img/users.png" height="50px">
              <p class="boldcom">{{ats['first_name']}}</p>
              <span class="boldcom">{{ats['last_name']}}</span>
              <button type="button" class="btn invitebtn" (click)="updateAtsData(i, ats['delete-link'])">{{ 'COMMONS_SELECT' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="source == 'criminal_country'">
      <div id="container" class="force-osx-scrollbar m-b-20">
        <div class="contents">
          <div class="addCard inlinecardcss" *ngFor="let ats of data;  let i = index;">
            <div class="dashboardCard maarright15 atsselect">
              <img src="../../assets/img/criminal.png" height="50px">
              <p class="boldcom">{{ats['city']}}, {{ats['state_code']}}</p>
              <span class="boldcom">{{ats['country_name']}}</span>
              <button type="button" class="btn invitebtn" (click)="updateAtsData(i, ats['delete-link'])">{{ 'COMMONS_SELECT' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="source == 'reference_type'">
      <div id="container" class="force-osx-scrollbar m-b-20">
        <div class="contents">
          <div class="addCard inlinecardcss">
              <div class="dashboardCard maarright15 atsselect">
                  <img height="50px" src="../../assets/img/add.png">
                  <p class="boldcom boldtext_ellipses">{{ 'CLIPBOARD_ADD_NEW' | translate}}</p>
                  <span class="boldcom"></span>
                  <button type="button" class="btn invitebtn" (click)="addNewData()">{{ 'COMMONS_SELECT' | translate}}</button>
              </div>
          </div> 
          <div class="addCard inlinecardcss" *ngFor="let ats of data;  let i = index;">
            <div class="dashboardCard maarright15 atsselect">
              <img src="../../assets/img/customer.png" height="50px">
              <p class="boldcom boldtext_ellipses">{{ats['reference_firstname']}}</p>
              <span class="boldcom">{{ats['reference_lastname']}}</span>
              <button type="button" class="btn invitebtn" (click)="updateAtsData(i, ats['delete-link'])">{{ 'COMMONS_SELECT' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="source == 'license_type'">
      <div id="container" class="force-osx-scrollbar m-b-20">
        <div class="contents">
          <span *ngFor="let ats of data;  let i = index;">
              <div class="inlinecardcss adjustCard" *ngIf="ats['display_card']" >
                  <div class="addCard">
                      <div class="dashboardCard maarright15 atsselect" [ngClass]="{'activeborder': selectedCardIndex === i}">
                        <img src="../../assets/img/id-card.png" height="50px">
                        <p class="boldcom">{{ats['license_number']}}</p>
                        <span class="boldcom">{{ats['state_name']}}, {{ats['country']}}</span>
                        <button type="button" class="btn invitebtn" (click)="updateAtsData(i, ats['delete-link'])">{{ 'COMMONS_SELECT' | translate}}</button>
                      </div>
                  </div>  
              </div>
          </span> 
        </div>
      </div>
    </div>

  </div>
</div>