<div class="pad0 body_container container m-t-20 mar10 anime-clean">
  <div class="signup" *ngIf="!unsupportedDeviceVersion">
    <div class="row margin0">
      <div class="col-md-6 col-sm-6 hidden-xs dark_bg p-t-50">
        <h3 class="unsupportedDeviceWelcomeText">{{'RID_WELCOME_CONTENT' | translate:[firstName,companyName]}}</h3>
      </div>
     
      <!-- Sign up Form Starts here-->
      <div class="pad0 col-xs-12  col-sm-6 col-md-6 signup_form">
        <div class="signup_container">
          <div class="row mobile-header visible-xs text-center">
            <div class="col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 col-xs-12 ">
              <div class="header_txt_white">
                <h2>{{'RID_WELCOME_CONTENT' | translate:[firstName,companyName]}}</h2>
              </div>
            </div>
          </div>
          <div class="row m-t-40 pad15 unsupportedDevice">
            <div class="col-sm-12 col-md-12 col-xs-12 ">
              <div class="header_txt_white rightIdUnsupported">
                  <h4 *ngIf="invitesubtypeRID">{{'RID_UNSUPPORTED_SUBHEADER_1'|translate:[firstName] }} </h4>
                  <h4 *ngIf="!invitesubtypeRID">{{'RID_ONLY_UNSUPPORTED_SUBHEADER'|translate:[firstName] }} </h4>
              </div>
            </div>
          </div>

          <div class="row m-t-10 pad15">
            <div class="col-xs-12 col-sm-12 col-md-12 text-center">
              <p>{{'RID_UNSUPPORTED_SUBHEADER_2'|translate}}</p>
            </div>
          </div>
          <div class="row m-t-20 pad15">
            <div class="col-xs-12 col-sm-12 col-md-12">
              <div class="scannerQR">
                <qrcode [qrdata]="url" [size]="64" [level]="'M'" usesvg="true"></qrcode>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12">
              <div class="scannerBtn text-center">
                <p><span class="glyphicon glyphicon-phone"></span> {{'RID_SCAN'| translate}}</p>
              </div>
            </div>
          </div>
          
          <div class="row m-t-40 pad15" *ngIf="timer > 0">
            <div class="col-xs-12 col-sm-12 col-md-12 text-center">
              <div class="text-center">
                <div class="unsupported_device_timer_border">
                  <span class="unsupported_device_timer_text">{{timer}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row m-t-40 pad15" *ngIf="showOptOut">
            <div class="col-xs-12 col-sm-12 col-md-12 text-center">
              <p>OR</p>
            </div>
          </div>
          <div class="row m-t-20 pad15">
            <div class="col-xs-12 col-sm-12 col-md-12 text-center">
                <a *ngIf="invitesubtypeRID && showOptOut && !isCaptureDLPrefillEnabled" class="skipContinueDesktop" (click)="optoutRightId()">{{'RID_SKIP_VERIFICATION' | translate}}</a>
                <a *ngIf="!invitesubtypeRID && showOptOut && !isCaptureDLPrefillEnabled" class="skipContinueDesktop" (click)="optoutRightId()">{{'RID_ONLY_SKIP_VERIFICATION' | translate}}</a>
                <a *ngIf="invitesubtypeRID && showOptOut && isCaptureDLPrefillEnabled" class="skipContinueDesktop" (click)="optoutRightId()">{{'IMPORT_DATA_SKIP_ID_SCAN' | translate}}</a>
             </div>
          </div>
        </div>
      </div>
      <!--Sign up From Ends here-->
    </div>
  </div>

  <div class=" anime-clean signup" *ngIf="unsupportedDeviceVersion">
    <div class="row margin0">
      <div class="col-md-6 col-sm-6 dark_bg p-t-50">
        <h3 class="unsupportedDeviceWelcomeText">{{'UNSUPPORTED_DEVICE' | translate:[deviceVersion, device]}}</h3>
      </div>
    </div>
  </div>

  <!--Tab Navigation Slider End-->
  <div class="clearfix"></div>
</div>

<!-- optout put call Wait Modal - starts -->
<div class="modal fade custom_modal" id="consentLoadWaitModal" role="dialog" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
        <!--<button type="button" class="close" data-dismiss="modal" (click)="modalClose()">&times;</button>-->
      </div>
      <div class="transparentbg modal-body">
        <h4 class="modal-title m-t-10" id="consentWaitModalPop">{{'WAIT_ON_AGGREMENT_MODAL_TEXT' | translate }}</h4>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>
<!-- optout put call Wait Modal - ends -->

<!-- Opt out Modal - starts -->
<div class="modal fade custom_modal" id="optoutModal" data-backdrop="static" tabindex="-1" role="Application" aria-labelledby="optOutModalPop" data-keyboard="false">
  <div class="modal-dialog sign-modal" style="width:600px;">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="closeOptOutModal()" data-dismiss="modal">&times;</button>
      </div>
      <div class="transparentbg modal-body">
        <h4 class="modal-title" *ngIf="!isCaptureDLPrefillEnabled" id="optOutModalPop">{{ 'OPTOUT_RIGHTID_HEADER' | translate}}</h4>
        <h4 class="modal-title" *ngIf="isCaptureDLPrefillEnabled" id="optOutModalPop">{{ 'OPTOUT_IMPORT_DATA_HEADER' | translate}}</h4>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="">
            <button type="button" class="btn button grow font_extrabold" data-dismiss="modal"
              (click)="closeOptOutModal()">{{'COMMONS_GOBACK' | translate }}</button>
          </div>
          <div class="">
            <button type="button" class="btn button grow font_extrabold" data-dismiss="modal"
              (click)="processOptOut()">{{'COMMONS_SKIP' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Opt out Modal - ends -->
