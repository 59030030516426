import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { IdentityConfig } from '../../identity-config';
import { LocalStorageService } from './../../../../../../framework/localstorage.service';
import { TrackEventService } from './track-event.service';
import { HotjarService } from 'app/hotjar.service';

declare var window: any;
@Injectable({
  providedIn: 'root'
})

export class SharedService {
  private errorCode: string;
  private errorMessage: string;
  private inviteIdToken: string;
  constructor(private _http: HttpClient,
              private localStorage: LocalStorageService,
              public trackEventService: TrackEventService, 
              private _hotjar: HotjarService) {
    window.track_truid_event = (name: string, payload: any) => {
      this.interactWithAnalytics(name, payload);
    }
  }

  log(body: any): Observable<Object> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    let extendedPath = '';
    let now = new Date();
    let invite_id = this.localStorage.getItem('invite_id');
    if (invite_id == undefined){
      invite_id = this.getInviteIdFromQueryParam();
    }
    extendedPath = 'truid' +':'+ invite_id + ':' + 'weblog';
    body['id'] = now.getTime();

    return this._http
      .put(IdentityConfig.API_ENDPOINT() + '/api/v1/cache/APPEND/' + extendedPath, body, httpOptions).pipe(
        catchError(this._handleError)
      );
  }

  updateInvite(body: any): Observable<Object> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._http
      .put(IdentityConfig.API_ENDPOINT() + `/api/web/invite`, body, httpOptions).pipe(
        catchError(this._handleError)
      );
  }

  consentAccepted(body: any, invite_id: string): Observable<Object> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._http.put(IdentityConfig.API_ENDPOINT() + '/api/web/invite/' + invite_id + '/applicant/consent', body, httpOptions)
      .pipe(catchError(this._handleError));
  }

  updateInviteApplicant(body: any, invite_id: string): Observable<Object> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this._http
      .put(IdentityConfig.API_ENDPOINT() + `/api/web/invite/` + invite_id + '/applicant', body, httpOptions).pipe(
        catchError(this._handleError)
      );
  }

  
  updateInviteStatus(body: any, invite_id: string): Observable<Object> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    
   	return this._http.
       put(IdentityConfig.API_ENDPOINT() + `/api/web/invite/` + invite_id + '/applicant/status', body, httpOptions).pipe(
        catchError(this._handleError)
      );
  }

  putConsentStep(body: any, workflow_id: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    //return this._http.put('http://localhost:8080'+ '/VerifiedId/workflow/' + workflow_id + '/custom-consent-step',body, httpOptions)
    return this._http.put(IdentityConfig.API_ENDPOINT() + '/api/v1/verifiedid/workflow/' + workflow_id + '/custom-consent-step', body, httpOptions)
      .pipe(catchError(this._handleError));
  }

  getWorkflow(workflow_id: string) {
    //return this._http.get( 'http://localhost:8080'+ '/VerifiedId/workflow/' + workflow_id)
    return this._http.get(IdentityConfig.API_ENDPOINT() + '/api/v1/verifiedid/workflow/' + workflow_id)
      .pipe(catchError(this._handleError));
  }

  private _handleError(error: Response | any) {
    let errorMessage: string;
    if (error['_body']) {
      let _body = JSON.parse(error['_body']) || {};
      switch (error.status) {
        case 400:
        case 401:
          // Bad request, Invalid Credentials - login invalid
          errorMessage = _body['error'] || 'Invalid Login';
          break;
        case 404:
          break;
      }
    } else {
      errorMessage = 'Error occurred';
    }

    return throwError({
      'errorMessage': errorMessage,
    });
  }

  setErrorCodeAndMsg(code, msg) {
    this.errorCode = code;
    this.errorMessage = msg;
  }

  setInviteIdToken(invite_id) {
    this.inviteIdToken = invite_id;
  }
  
  getErrorCode() {
    return (this.errorCode) ? this.errorCode : 'BAD_REQUEST';
  }

  getErrorMessage() {
    return (this.errorMessage) ? this.errorMessage : 'Something went wrong';
  }

  getInviteIdToken() {
    return (this.inviteIdToken);
  }

  getInviteIdFromQueryParam(){
    let invite_id = undefined;
    try {
        let params = window.document.location.search.split('?');
        let paramArr = params[1].split('&');
        paramArr.forEach(element => {
          let param = element.split('=');
          if (param[0] === 'invite_id') {
            invite_id = param[1];
          }
        });
    } catch(error){
      console.warn('unable to find inviteid');
    }
    return invite_id;
  }

  interactWithAnalytics(name: string, payload: any) {
    this._hotjar.logHotjarEvent(name, payload);
  }
}
