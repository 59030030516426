<div class="pad0 body_container container loginMobilePad m-t-20 mar10 anime-clean" *ngIf="!isAuthyLoggedIn">
  <div class="signup">
    <div class="row margin0">
      <div class="col-md-6 col-sm-6 hidden-xs dark_bg">
        <!--<img src="../../assets/img/illustration01.png">-->
        <h3 class="text-center m-t-10 pad5 wrap-word-overflow" *ngIf="!isInviteNotification && !isInviteEiaf && isStandaloneWotc===false && !isInviteMI && !isInviteAssessment && !isInviteConsentType">{{'SET_PASSWORD_HEADER_1'|translate:[firstName,companyName] }}</h3>
        <h3 class="text-center m-t-10 pad5 wrap-word-overflow" *ngIf="isInviteConsentType">{{'SET_PASSWORD_CONSENT_TYPE_HEADER_1'|translate:[firstName,companyName] }}</h3>
        <h3 class="text-center m-t-10 pad5 wrap-word-overflow" *ngIf="isInviteEiaf">{{'SET_PASSWORD_EIAF_HEADER_1'|translate:[companyName,companyName] }}</h3>
        <h3 class="text-center m-t-10 pad5 wrap-word-overflow" *ngIf="isStandaloneWotc">{{'STANDALONE_WOTC_REGISTRATION'|translate:[firstName,companyName] }}</h3>
        <h3 class="text-center m-t-10 pad5 wrap-word-overflow" *ngIf="isInviteMI && !isInviteDQMI">{{'LOGIN_MI_HEADER'|translate:[firstName] }}</h3>
        <h3 class="text-center m-t-10 pad5 wrap-word-overflow" *ngIf="isInviteMI && isInviteDQMI">{{'DQ_LOGIN_MI_HEADER'|translate:[firstName] }}</h3>
        <h3 class="text-center m-t-10 pad5 wrap-word-overflow" *ngIf="isInviteAssessment">{{'SET_PASSWORD_ASSESSMENT_HEADER_1'|translate:[firstName,companyName] }}</h3>
        <h3 class="text-center m-t-10 pad5 wrap-word-overflow" *ngIf="isInviteNotification">{{'SET_PASSWORD_NOTIFICATION_HEADER_1'|translate:[firstName] }}</h3>
      </div>
      <div class="pad0 col-xs-12  col-sm-6 col-md-6 signup_form mar26">
        <div class="signup_container">
          <div class="row mobile-header visible-xs text-center" id="loginpaddingAlignment">
            <div class="col-xs-12 ">
              <div class="header_txt_white  wrap-word-overflow">
                <h2 class="wrap-word-overflow" *ngIf="!isInviteNotification && !isInviteEiaf && isStandaloneWotc===false && !isInviteMI && !isInviteAssessment && !isInviteConsentType">{{'SET_PASSWORD_HEADER_1'|translate:[firstName,companyName] }}</h2>
                <h2 class="wrap-word-overflow" *ngIf="isInviteEiaf">{{'SET_PASSWORD_EIAF_HEADER_1'|translate:[companyName,companyName] }}</h2>
                <h2 class="wrap-word-overflow" *ngIf="isInviteConsentType">{{'SET_PASSWORD_CONSENT_TYPE_HEADER_1'|translate:[firstName,companyName] }}</h2>
                <h2 class="wrap-word-overflow" *ngIf="isStandaloneWotc">{{'STANDALONE_WOTC_REGISTRATION'|translate:[firstName,companyName] }}</h2>
                <h2 class="wrap-word-overflow" *ngIf="isInviteMI && !isInviteDQMI">{{'LOGIN_MI_HEADER'|translate:[firstName] }}</h2>
                <h2 class="wrap-word-overflow" *ngIf="isInviteMI && isInviteDQMI">{{'DQ_LOGIN_MI_HEADER'|translate:[firstName] }}</h2>
                <h2 class="wrap-word-overflow" *ngIf="isInviteAssessment">{{'SET_PASSWORD_ASSESSMENT_HEADER_1'|translate:[firstName,companyName] }}</h2>
                <h2 class="wrap-word-overflow" *ngIf="isInviteNotification">{{'SET_PASSWORD_NOTIFICATION_HEADER_1'|translate:[firstName] }}</h2>

                <!--<h4 class="m-t-10">Already have a Profile ? - <a (click)="redirectSignin()">Sign in</a></h4>-->
              </div>
            </div>
          </div>
          <div class="row m-t-40 pad15 ">
            <div class="col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 col-xs-12  ">
              <div class="header_txt_white setPasswordAccount">
                <!--<h2>Set up your Profile</h2>-->
                <h4 class="m-t-10" tabindex="0">{{ 'ALREADY_HAVE_ACCOUNT' | translate}} - </h4>
                <a (click)="redirectSignin()" tabindex="0">{{ 'SET_PASSWORD_SIGNIN' | translate}}</a>
              </div>
            </div>
          </div>
          <form class="m-t-25 pad15" #setPasswordForm="ngForm"  autocomplete="off" data-target="#myModal">
            <div class="row">
              <div class="col-xs-12  col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 dp-r-0  m-b-20">
                <div class="group focused">
                  <label class="placeholder_white custom_label" for="inputEmail">{{ 'EMAIL_LABLE' | translate}}</label>
                  <input type="email" name="email" [ngModel]="email" id="inputEmail" class="validate form-control" disabled/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12  col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 dp-r-0 m-b-20">
                <div class="group">
                  <label class="placeholder_white custom_label" for="inputSetPwd">{{ 'CREATE_PASSWORD_LABLE' | translate}}</label>
                  <input ngModel type="password" name="password" ngModel #password="ngModel" id="inputSetPwd" aria-required="true" class="validate form-control"
                    maxlength="16" title="{{'COMMONS_PASSWORD_ERROR_1'| translate}}" (keyup)="enableNext(setPasswordForm.value.password, setPasswordForm.value.confirmPassword, setPasswordForm.value.secretQuestion, setPasswordForm.value.secretAnswer)"
                    (blur)="validatePwd(setPasswordForm.value.password, setPasswordForm.value.confirmPassword)"
                    autocomplete="off" (focus)="hidePwdError()" autofocus/>
                    <div id="meter" #meter  >
                      <password-meter [password]= "setPasswordForm.value.password" (isValidPassword)='isPasswordValid($event)'>
                      </password-meter>
                    </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 dp-r-0 m-b-20">
                <div class="group">
                  <label class="placeholder_white custom_label" for="inputsCnfrmPwd">{{ 'CONFIRM_PASSWORD_LABLE' | translate}}</label>

                  <input ngModel type="password" id="inputsCnfrmPwd" name="confirmPassword" ngModel #confirmPassword="ngModel" aria-required="true" class="validate form-control"
                    maxlength="16" title="{{'COMMONS_CONFIRM_PASSWORD_ERROR_1'| translate}}"(blur)="validateConfirmPwd(setPasswordForm.value.password, setPasswordForm.value.confirmPassword)"
                    autocomplete="off" (keyup)="enableNext(setPasswordForm.value.password, setPasswordForm.value.confirmPassword, setPasswordForm.value.secretQuestion, setPasswordForm.value.secretAnswer)" (focus)="hideError(setPasswordForm.value.password,setPasswordForm.value.confirmPassword)"/>
                  <div class="error_info">
                      <i class="fa fa-exclamation error_icon" aria-hidden="true" *ngIf="isCnfrmPwdErrorInfo" [class.red_error]="isCnfrmPwdErrorInfo && !isCnfrmPwdError" [class.cursor_hand_none]="isCnfrmPwdError"></i>
                      <div class="error_info_popup error_new_ui">
                        <div class="left_div">
                          <ul>
                            <li class="alert">{{cnfrmPwdErrorMessage | translate}}</li>
                            <!-- <li class="message">{{cnfrmPwdErrorMessage | translate}}</li> -->
                          </ul>
                        </div>
                        <div class="right_div">
                          <div class="close" aria-hidden="true">
                            <i class="icon-cancel"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="custom-error error_new_ui" *ngIf="isCnfrmPwdError">
                      <div class="left_div">
                        <ul>
                          <li class="alert">{{cnfrmPwdErrorMessage | translate}}</li>
                          <!-- <li class="message">{{cnfrmPwdErrorMessage | translate}}</li> -->
                        </ul>
                      </div>
                      <div class="right_div">
                        <div class="close" aria-hidden="true" (click)="closeCnfrmPwdError()">
                          <i class="icon-cancel"></i>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12  col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 dp-r-0 m-b-20">
                  <div class="group">
                    <label class="placeholder_white custom_label" for="setSecretQuestion">{{ 'SECRET_QUESTION_LABLE' | translate}}</label>
                    <select class="form-control paddingsecret" id="setSecretQuestion" aria-required="true" name="secretQuestion" (change)="validateSecretQuestion($event.target.value)" [(ngModel)]="secretQuestion">
                        <option value="">{{ 'COMMONS_SELECT' | translate}}</option>
                        <option [value]="type.id" *ngFor="let type of secretQuestions">{{type.name | translate}}</option>
                      </select>
                    </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12  col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 dp-r-0 m-b-20">
                  <div class="group">
                      <label class="placeholder_white custom_label" for="inputsSecAns">{{ 'SECRET_ANSWER_LABLE' | translate}}</label>
                      <input type="text" name="secretAnswer" id="inputsSecAns" aria-required="true" [(ngModel)]="secretAnswer" (keyup)="enableNext(setPasswordForm.value.password, setPasswordForm.value.confirmPassword, setPasswordForm.value.secretQuestion, setPasswordForm.value.secretAnswer)" (blur)="validateSecretAnswer(setPasswordForm.value.secretAnswer)" class="validate form-control" title="{{'COMMONS_SECRET_ANSWER'| translate}}"autocomplete="off">
                  </div>
              </div>
            </div>
            <div class="col-xs-12  col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 dp-r-0 negmar30 negmar10">
              <!-- Modal -->
              <div id="myModal" tabindex="-1" role="Application" aria-labelledby="setPasswordWaitStatus" class="modal fade terms_modal" >
                <div class="termswidth modal-dialog sign-modal">
                  <!-- Modal content-->
                  <div class="modal-content paddng20">
                    <div class="modal-header">
                      <button type="button" class="close" data-dismiss="modal">
                        <i class="icon-cancel"></i>

                      </button>
                      <h3 class="modal-title" id="setPasswordWaitStatus">{{'TERMS_SERVICE_1' | translate}}</h3>
                    </div>
                    <div class="modal-body" [innerHTML]="termsOfServicesData">


                    </div>

                    <div class="modal-footer text-center">
                      <div class="col-xs-12 col-md-offset-3 col-sm-4 col-md-3 p_l_0  p_r_0 m-r-15">
                        <button class="btn button grow font_extrabold"  (click)="setPassword(setPasswordForm.value)" type ="button">{{'TERMS_SERVICE_BTN_1' | translate}}</button>
                      </div>
                      <div class="col-xs-12 col-sm-4 col-md-3 p_l_0 p_r_0 m-r-15 m-b-20">
                        <button class="btn button_profile grow font_extrabold" (click) = "disagreeTermsAndConditions()" type="button">{{'TERMS_SERVICE_BTN_2' | translate}}</button>
                      </div>

                    </div>
                  </div>

                </div>
              </div>

              <!-- DO NOT DELETE BELOW CODE, MODAL SERVICE IMPLMN-->
              <!--<input type="checkbox" name="isAgreed" [(ngModel)]="isAgreed" required>I agree to the</label> <span class="modal_text cursor_hand" (click)="modService.open(MODAL_DEMO_ID)">terms and conditions</span>-->
              <!--<tb-modal modal-title="Catchy Title Here" blocking="false"  modal-id="{{MODAL_DEMO_ID }}">
                                        <div class="center">Are you sure about this?</div>
                                        <div class="buttons">
                                            <button class="flat" (click)="doTheThing()">Yes</button>
                                            <button (click)="modService.close(MODAL_DEMO_ID)">No</button>
                                        </div>
                                    </tb-modal>-->
              <!--DO NOT DELETE CODE ENDS  -->
            </div>

            <div class="col-xs-12  col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 m-t-5 pad0">
              <button type="button"  (click)="validatePassword(setPasswordForm.value)" class="btn button font_extrabold" name="action" [disabled]="!isCnfrmPwdValid || !isPwdValid || !isSecretQuestionValid || !isSecretAnswerValid  || !isPasswordRulesValid"
                [class.grow]="isCnfrmPwdValid && isPwdValid && isPasswordRulesValid">{{ 'COMMONS_NEXT' | translate}}</button>
              </div>
          </form>
        </div>
      </div>
      <!--Sign up From Ends here-->
    </div>
  </div>
  <div class="clearfix"></div>
</div>

  <div class="modal fade custom_modal" id="plsWaitOnIAgreeModal" tabindex="-1" role="Application" aria-labelledby="setPasswordWaitStatus" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal" role="document">
    <div class="modal-content">
      <div class="transparentbg modal-body m-t-15">
        <h4 class="modal-title" id="setPasswordWaitStatus">{{'WAIT_ON_AGGREMENT_MODAL_TEXT' | translate }}
        </h4>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-xs-12 col-sm-4 col-md-4 p_l_0  p_r_0 m-r-15 ">
           <!-- <button type="button" class="btn button font_extrabold" data-dismiss="modal" data-toggle="resetPassword-modal" (click)="goToSignin()">CONTINUE</button>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Threshold Alert Modal -->
<div class="modal fade custom_modal" id="threshHoldAlertModal" tabindex="-1" role="Application" aria-labelledby="setPasswordNetworkStatus" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="closeTModal()">&times;</button>
      </div>
      <div class="transparentbg modal-body m-t-15">
        <h4 class="modal-title " id="setPasswordNetworkStatus">{{'NETWORK_THRESHOLD_TEXT' | translate }}
        </h4>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-xs-12 col-sm-4 col-md-4 p_l_0  p_r_0 m-r-15 ">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
