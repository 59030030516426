<div>

  <ul>
    <li *ngIf="!!viewProfileData['type']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['type'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['type_description'] | translate}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['reference_firstname']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['reference_firstname'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['reference_firstname']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['reference_middlename']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['reference_middlename'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['reference_middlename']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['reference_lastname']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['reference_lastname'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['reference_lastname']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['title']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['title'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['title']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['employername']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['employername'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['employername']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['mobilenumber']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['mobilenumber'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['mobilenumber']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['alternatemobilenumber']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['alternatemobilenumber'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['alternatemobilenumber']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['relation']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['relation'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['relation']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['country']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['country'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['country']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['state_name']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['state_name'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['state_name']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['city']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['city'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['city']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['detail']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['detail'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['detail'] }}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['is_using_agency']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['is_using_agency'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['is_using_agency'] }}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['license_number']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['license_number'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['license_number'] }}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['email']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['email'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['email'] }}</div>
      </div>
    </li>
  </ul>
</div>
