import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../../app.config';
import { WorkflowService } from '../../../framework/workflow.service';
import { LocalStorageService } from '../../../framework/localstorage.service';
import { UtilService } from '../../../framework/utils/util.service';
import * as dateUtils from '../../../framework/utils/date.utils';

@Injectable()
export class RuleManagerService {
    // Holds the rule List against each Module
    rules: Map<string, Array<Object>> =  new Map();

    // Hold Partcular rule to apply
    ruleToApply: any;

    constructor() {
    }

    addRules(ruleDetails: Object) {
        this.rules.set(ruleDetails['Module'], ruleDetails['RuleList']);
    }

    evaluate(useRuleToApply: Object, adlInfo: any): any {
        if (this.isMatch(useRuleToApply)) {
            return this.ruleToApply.execute(adlInfo);
        }
    }

    getRules(moduleName): Array<Object> {
        return this.rules.get(moduleName);
    }

    isMatch(useRuleToApply: Object) {
        let isMatchFound: boolean = false;
        let particularRuleList: Array<Object> = this.getRules(useRuleToApply['Module']);

        for (let particularRuleObj of particularRuleList) {
            if (particularRuleObj['className'] === useRuleToApply['RuleToApply']) {
                this.ruleToApply = particularRuleObj['objName'];
                isMatchFound = true;
            }
        }

        return isMatchFound;
    }
}
