import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WorkflowService } from '../../framework/workflow.service';
import { EventBusService } from '../../framework/eventbus.service';
import { AppConfig } from '../app.config';
declare var jQuery: any;


interface Statusbar {
  remainingSection: number;
  percentage: number;
  remainingTime: any;
}


@Injectable()
export class StatusbarService {

  static _setstatusname: string;
  static _setstatusdob: string;
  static _setStatusReferences: string;
  static _ssnstatus: string;
  static _consentstatus: string;
  static _disclosurestatus: string;
  static _addressstatus: string;
  static _educationstatus: string;
  static _employementstatus: string;
  static _mvrstatus: string;
  static flag: boolean;
  static _setcrfudfstatus: any;
  static _intlstatus: any;
  static _setStatusLicences: string;
  static _payment: string;
  static _rightId: string;
  static _bandWidthCheckStatus: string;
  static _selfDeclarationFormStatus: string;
  static _instantIdentityId: string;
  static completedmenu: any;
  weightagedata: any;
  allmenu = [];
  totalWeightage = 0;
  statusbar: Statusbar;
  remainingsection: number;
  execludeListFromCompletedMenus:string[] = ['agreement-v1', 'rightId-agreement-data-v1', 'rightid-data-v1'];

  constructor(private status: HttpClient, private _eventBus: EventBusService, private _workflow: WorkflowService) {
    this.findTotalWeightage();
  }

  setStatusbar(obj) {
    this.statusbar.remainingSection = obj.remainingSection;
    this.statusbar.percentage = obj.percentage;
    this.statusbar.remainingTime = obj.remainingTime;

    this._eventBus.statusbardAnnounced({
      remainingSection: obj.remainingSection,
      percentage: obj.percentage,
      remainingTime: obj.remainingTime
    });
  }

  getStatusbar() {
    return this.statusbar;
  }

  setCompletedMenu(value: any): void {
    StatusbarService.completedmenu = value;
    //console.log("completed progress bar", StatusbarService.completedmenu);
  }

  getCompletedMenu() {
    // console.log("****completed progress bar****", StatusbarService.completedmenu);
    return StatusbarService.completedmenu;
  }

  getCompletedMenustatus(value: any) {
    //console.log("0000check progress bar0000", StatusbarService.completedmenu);

    let getcurrentmenu = value;
    if (!StatusbarService.completedmenu) {
      StatusbarService.completedmenu = [];
    }
    var length = StatusbarService.completedmenu.length;

    for (let i = 0; i <= length; i++) {
      if (StatusbarService.completedmenu[i] == getcurrentmenu) {
        return true;
      }
    }

    return false;
  }

  setstatusName(value: any): void {
    StatusbarService.completedmenu.push(value);
    StatusbarService._setstatusname = value;
  }

  setStatusLicences(value: any): void {
    StatusbarService.completedmenu.push(value);
    StatusbarService._setStatusLicences = value;
  }

  setStatusReferences(value: any): void {
    StatusbarService.completedmenu.push(value);
    StatusbarService._setStatusReferences = value;
  }

  setstatusDob(value: any): void {
    StatusbarService.completedmenu.push(value);
    StatusbarService._setstatusdob = value;
  }

  setstatusCrfudf(value: any): void {
    StatusbarService.completedmenu.push(value);
    StatusbarService._setcrfudfstatus = value;
  }

  setstatusIntl(value: any): void {
    StatusbarService.completedmenu.push(value);
    StatusbarService._intlstatus = value;
  }

  setstatusCustomForm(value: any): void {
    StatusbarService.completedmenu.push(value);
  }  

  setstatusSsn(value: any): void {
    StatusbarService.completedmenu.push(value);
    StatusbarService._ssnstatus = value;
  }

  setstatusConsent(value: any): void {
    StatusbarService.completedmenu.push(value);
    StatusbarService._consentstatus = value;
  }

  setstatusAddress(value: any): void {
    StatusbarService.completedmenu.push(value);
    StatusbarService._addressstatus = value;
  }

  setstatusEducation(value: any): void {
    StatusbarService.completedmenu.push(value);
    StatusbarService._educationstatus = value;
  }

  setstatusEmployement(value: any): void {
    StatusbarService.completedmenu.push(value);
    StatusbarService._employementstatus = value;
  }

  setstatusMvr(value: any): void {
    StatusbarService.completedmenu.push(value);
    StatusbarService._mvrstatus = value;
  }

  setStatusPayment(value: any) : void {
    StatusbarService.completedmenu.push(value);
    StatusbarService._payment = value;
  }

  setStatusRightId(value: any) : void {
    StatusbarService.completedmenu.push(value);
    StatusbarService._rightId = value;
  }

  setstatusGroupCustForm(value: any): void {
    StatusbarService.completedmenu.push(value);
  }  

  setStatusBandwithCheck(value: any): void {
    StatusbarService.completedmenu.push(value);
    StatusbarService._bandWidthCheckStatus = value;
  }

  setStatusSelfDeclarationForm(value: any): void {
    StatusbarService.completedmenu.push(value);
    StatusbarService._selfDeclarationFormStatus = value;
  }

  setStatusInstantIdentityId(value: any): void {
    StatusbarService.completedmenu.push(value);
    StatusbarService._instantIdentityId = value;
  }

  convertMinitSecond(value) {
    let seconds = value; // minutes convert to seconds.
    let minutes = Math.floor(seconds / 60);
    seconds = Math.floor(seconds - minutes * 60);

    return minutes + 'm ' + seconds + 's';
  }

  convertSecondtomin(value) {
    let seconds = value; // minutes convert to seconds.
    let minutes = Math.floor(seconds / 60);

    return minutes + 'm ';
  }

  getStatusBarValue(x): Object {
    let getCompletedMenuList = StatusbarService.completedmenu;
    let completedMenu = getCompletedMenuList.length;

    for (let i = 0; i <= getCompletedMenuList.length; i++) {
      if (getCompletedMenuList[i] == "main-name-v1" || getCompletedMenuList[i] == "main-dob-v1") {
        completedMenu = completedMenu - 1;
      }
    }

    if (getCompletedMenuList[0] === 'agreement-v1') {  // Removing the consent tab
      getCompletedMenuList.splice(0, 1);
    }
    //this.currentStage = this._workflow.getCurrentStage();
    let totalMenuCounts = this._workflow.getMenuCount();
    this.execludeListFromCompletedMenus.forEach(menu => {
      const index = this.allmenu.indexOf(menu);
      if (index >= 0 && totalMenuCounts > 0){
        totalMenuCounts--;
      }
    })
    let remainingcount = (totalMenuCounts - completedMenu);
    let remainingSection = remainingcount;
    //let weightagedata = {}
    this.weightagedata = AppConfig.getstatusbarWeigtage();
    // console.log(this.weightagedata['weightagedata']['totalseconds'],"***WEIGHTAGE DATA TOTAL****");
    // console.log(this.weightagedata.weightagedata,"***WEIGHTAGE DATA****");
    let minutes = this.weightagedata.weightagedata.totalseconds;
    //localStorage.setItem('remainingsection', this.remainingSection);
    let remainingTime = this.convertSecondtomin(minutes);
    let totalCountPercentage = 0;
    let panameWeightage = 0;
    let padobWeightage = 0;

    for (let i = 0; i <= getCompletedMenuList.length; i++) {
      if (getCompletedMenuList[i] == "main-name-v1") {
        panameWeightage = this.weightagedata.weightagedata.paname;
        totalCountPercentage = totalCountPercentage + panameWeightage;
      }

      if (getCompletedMenuList[i] == "main-data-v1") {
        totalCountPercentage = 0;
        panameWeightage = this.weightagedata.weightagedata.paname;
        padobWeightage = this.weightagedata.weightagedata.padob;
        totalCountPercentage = padobWeightage + panameWeightage;
      }

      if (getCompletedMenuList[i] == "residential-data-v1") {
        let residentialWeightage = this.weightagedata.weightagedata.residentaldata;
        totalCountPercentage = totalCountPercentage + residentialWeightage;
      }

      if (getCompletedMenuList[i] == "employment-data-v1") {
        let employmentWeightage = this.weightagedata.weightagedata.employment;
        totalCountPercentage = totalCountPercentage + employmentWeightage;
      }

      if (getCompletedMenuList[i] == "education-data-v1") {
        let educationWeightage = this.weightagedata.weightagedata.education;
        totalCountPercentage = totalCountPercentage + educationWeightage;
      }

      if (getCompletedMenuList[i] == "mvr-data-v1") {
        let mvrWeightage = this.weightagedata.weightagedata.mvr;
        totalCountPercentage = totalCountPercentage + mvrWeightage;
      }

      if (getCompletedMenuList[i] == "identification-data-v1") {
        let personaldataWeightage = this.weightagedata.weightagedata.personaldata;
        totalCountPercentage = totalCountPercentage + personaldataWeightage;
      }

      if (getCompletedMenuList[i] == "agreement-v1") {
        let consentslide1Weightage = this.weightagedata.weightagedata.consentslide1;
        totalCountPercentage = totalCountPercentage + consentslide1Weightage;
      }

      if (getCompletedMenuList[i] == "contact-data-v1") {
        let contactWeightage = this.weightagedata.weightagedata.contact;
        totalCountPercentage = totalCountPercentage + contactWeightage;
      }

      if (getCompletedMenuList[i] == "reviewedit-data-v1") {
        let revieweditWeightage = this.weightagedata.weightagedata.reviewedit;
        totalCountPercentage = totalCountPercentage + revieweditWeightage;
      }

      if (getCompletedMenuList[i] == "crfudf-data-v1") {
        let crfudfWeightage = this.weightagedata.weightagedata.reviewedit;
        totalCountPercentage = totalCountPercentage + crfudfWeightage;
      }

      if (getCompletedMenuList[i] == "criminal-data-v1") {
        let criminalWeightage = this.weightagedata.weightagedata.criminal;
        totalCountPercentage = totalCountPercentage + criminalWeightage;
      }

      if (getCompletedMenuList[i] == "reference-data-v1") {
        let referencesWeightage = this.weightagedata.weightagedata.reference;
        totalCountPercentage = totalCountPercentage + referencesWeightage;
      }

      if (getCompletedMenuList[i] == "licence-data-v1") {
        let licencesWeightage = this.weightagedata.weightagedata.licence;
        totalCountPercentage = totalCountPercentage + licencesWeightage;
      }

      if (getCompletedMenuList[i] == "international-data-v1") {
        let intlWeightage = this.weightagedata.weightagedata.intl;
        totalCountPercentage = totalCountPercentage + intlWeightage;
      }

      if (getCompletedMenuList[i] == "documentupload-data-v1") {
        let docUploadWeightage = this.weightagedata.weightagedata.documentUpload;
        totalCountPercentage = totalCountPercentage + docUploadWeightage;
      }

      if (getCompletedMenuList[i] == "eiaf-main-data-v1") {
        let eiafMainDataWeightage = this.weightagedata.weightagedata.eiafMainData;
        totalCountPercentage = totalCountPercentage + eiafMainDataWeightage;
      }

      if (getCompletedMenuList[i] == "eiaf-criminal-data-v1") {
        let eiafCriminalDataWeightage = this.weightagedata.weightagedata.eiafCriminalData;
        totalCountPercentage = totalCountPercentage + eiafCriminalDataWeightage;
      }

      if (getCompletedMenuList[i] == "eiaf-circumstance-data-v1") {
        let eiafCircumstanceDataWeightage = this.weightagedata.weightagedata.eiafCircumstanceData;
        totalCountPercentage = totalCountPercentage + eiafCircumstanceDataWeightage;
      }

      if (getCompletedMenuList[i] == "online-schedule-data-v1") {
        let onlineScheduleDataWeightage = this.weightagedata.weightagedata.onlineScheduleData;
        totalCountPercentage = totalCountPercentage + onlineScheduleDataWeightage;
      }

      if (getCompletedMenuList[i] === 'payment-information-data-v1') {
        let paymentInfoDataWeightage = this.weightagedata.weightagedata.paymentInfoData;
        totalCountPercentage = totalCountPercentage + paymentInfoDataWeightage;
      }

      if (getCompletedMenuList[i] === 'rightid-data-v1') {
        let rightIdDataWeightage = this.weightagedata.weightagedata.rightIdData;
        totalCountPercentage = totalCountPercentage + rightIdDataWeightage;
      }

      if (getCompletedMenuList[i] === 'instant-identity-data-v1') {
        let instantIdentityIdDataWeightage = this.weightagedata.weightagedata.instantIdentityIdData;
        totalCountPercentage = totalCountPercentage + instantIdentityIdDataWeightage;
      }      

      if (getCompletedMenuList[i] == "wotc-personal-data-v1") {
        let eiafMainDataWeightage = this.weightagedata.weightagedata.eiafMainData;
        totalCountPercentage = totalCountPercentage + eiafMainDataWeightage;
      }

      // if (getCompletedMenuList[i] == "payment-information-data-v1") {
      //   let paymentInfoDataWeightage = this.weightagedata.weightagedata.paymentInfoData;
      //   totalCountPercentage = totalCountPercentage + paymentInfoDataWeightage;
      // }
    }
    // this.percentage = Math.round(this.percentage);
    // let padobWeightage=this.weightagedata.weightagedata.padob;
    // let totalWeightage =this.weightagedata.weightagedata.total;
    let totalseconds = this.weightagedata.weightagedata.totalseconds;
    // console.log("this.totalCountPercentage", this.totalCountPercentage)
    let currentpercentage = (totalCountPercentage / this.totalWeightage) * 100;
    let percentage = currentpercentage;
    remainingSection = remainingSection;
    percentage = Math.round(percentage);
    let remainingTimeaftercalculation = this.convertMinitSecond(180 - (percentage / 100) * 180);
    remainingTime = remainingTimeaftercalculation;

    let statusBarConfig = {
      'remainingsection': remainingSection,
      'percentage': percentage,
      'timeremaining': remainingTime,
      'completedMenu': completedMenu
    }
    /*
    let statusBarConfig1 = {

      'nameStatus':
      {
        'remainingsection': '6',
        'percentage': '6',
        'timeremaining': '2m 55s'

      },
      'dobStatus':
      {
        'remainingsection': '5',
        'percentage': '9',
        'timeremaining': '2m 43s'

      },
      'residentialStatus':
      {
        'remainingsection': '4',
        'percentage': '34',
        'timeremaining': '1m 58s'

      },
      'employmentStatus':
      {
        'remainingsection': '5',
        'percentage': '66',
        'timeremaining': '1m 1s'

      },
      'educationStatus':
      {
        'remainingsection': '4',
        'percentage': '78',
        'timeremaining': '0m 39s'

      },
      'mvrStatus':
      {
        'remainingsection': '3',
        'percentage': '81',
        'timeremaining': '0m 34s'

      },
      'identityStatus':
      {
        'remainingsection': '3',
        'percentage': '84',
        'timeremaining': '1m 28s'

      },
      'consentStatus':
      {
        'remainingsection': '1',
        'percentage': '96',
        'timeremaining': '0m 20s'

      },
      'review':
      {
        'remainingsection': '2',
        'percentage': '90',
        'timeremaining': '1m 0s'

      }
    }*/

    return statusBarConfig;
  }

  getTotalWeightage() {
    return this.totalWeightage;
  }

  findTotalWeightage() {
    this.allmenu = this._workflow.getMenu();
    this.weightagedata = AppConfig.getstatusbarWeigtage();

    for (let i = 0; i < this.allmenu.length; i++) {
      if (this.allmenu[i] == "main-data-v1") {
        this.totalWeightage = this.totalWeightage + this.weightagedata.weightagedata.paname + this.weightagedata.weightagedata.padob;
      }

      if (this.allmenu[i] == "residential-data-v1") {
        this.totalWeightage = this.totalWeightage + this.weightagedata.weightagedata.residentaldata;
      }

      if (this.allmenu[i] == "identification-data-v1") {
        this.totalWeightage = this.totalWeightage + this.weightagedata.weightagedata.personaldata;
      }

      if (this.allmenu[i] == "reviewedit-data-v1") {
        this.totalWeightage = this.totalWeightage + this.weightagedata.weightagedata.reviewedit;
      }

      if (this.allmenu[i] == "agreement-v1") {
        this.totalWeightage = this.totalWeightage + this.weightagedata.weightagedata.consentslide1;
      }

      if (this.allmenu[i] == "employment-data-v1") {
        this.totalWeightage = this.totalWeightage + this.weightagedata.weightagedata.employment;
      }

      if (this.allmenu[i] == "education-data-v1") {
        this.totalWeightage = this.totalWeightage + this.weightagedata.weightagedata.education;
      }

      if (this.allmenu[i] == "mvr-data-v1") {
        this.totalWeightage = this.totalWeightage + this.weightagedata.weightagedata.mvr;
      }

      if (this.allmenu[i] == "contact-data-v1") {
        this.totalWeightage = this.totalWeightage + this.weightagedata.weightagedata.contact;
      }

      if (this.allmenu[i] == "crfudf-data-v1") {
        this.totalWeightage = this.totalWeightage + this.weightagedata.weightagedata.crfudf;
      }

      if (this.allmenu[i] == "criminal-data-v1") {
        this.totalWeightage = this.totalWeightage + this.weightagedata.weightagedata.criminal;
      }

      if (this.allmenu[i] == "reference-data-v1") {
        this.totalWeightage = this.totalWeightage + this.weightagedata.weightagedata.reference;
      }

      if (this.allmenu[i] == "licence-data-v1") {
        this.totalWeightage = this.totalWeightage + this.weightagedata.weightagedata.licence;
      }

      if (this.allmenu[i] == "international-data-v1") {
        this.totalWeightage = this.totalWeightage + this.weightagedata.weightagedata.intl;
      }

      if (this.allmenu[i] == "documentupload-data-v1") {
        this.totalWeightage = this.totalWeightage + this.weightagedata.weightagedata.documentUpload;
      }

      if (this.allmenu[i] == "rightid-data-v1") {
        this.totalWeightage = this.totalWeightage + this.weightagedata.weightagedata.rightIdData;
      }
      if (this.allmenu[i] == "instant-identity-data-v1") {
        this.totalWeightage = this.totalWeightage + this.weightagedata.weightagedata.instantIdentityIdData;
      }      
    }
  }
}
