<div [formGroup]="group">
  <div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
    <div class="group  padv-dropdown">
      <label class="control-labelss">{{'PHONE_CARD_COUNTRY_CARD' | translate}}</label>
      <input type="text" class="form-control" [formControlName]="countryCodeControl" (keyup)="keyupCountryCode($event)"
        value="+" (blur)="validatePhoneData()"/>
      <span class="error_info">
        <i class="fa fa-exclamation error_icon" aria-hidden="true" *ngIf="codeErrorMsg"
          [class.red_error]="!codeErrorMsg" [class.cursor_hand_none]="codeErrorMsg"></i>
        <div class="error_info_popup">Country Code Error<i class="fa fa-times" aria-hidden="true"
            (click)="closeCodeError()"></i></div>
      </span>
      <div class="custom-error error_new_ui" *ngIf="codeErrorMsg">
        <div class="left_div">
          <ul>
            <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate }}</li>
            <li class="message">{{'PHONE_CARD_INVALID_COUNTRY_CODE' | translate}}</li>
          </ul>
        </div>
        <div class="right_div">
          <div class="close" aria-hidden="true" (click)="closeCodeError()">
            <i class="icon-cancel"></i>
          </div>
        </div>
      </div>
      <span class="highlight"></span>
      <ul [class.hide]="isNumberList" class="generic-dropdown-list">
        <li *ngFor="let citydata of countrycodelist" (click)="enterCityInput(citydata)">
          <div>
            <span>{{citydata.disp_value}}</span>
          </div>
        </li>
      </ul>
      <span class="bar"></span>
    </div>
  </div>

  <div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
    <div class="group">
      <label *ngIf="!isKyp" class="control-labelss">{{'PHONE_CARD_PHONE_NUMBER' | translate}}</label>
      <label *ngIf="isKyp" class="control-labelss">{{'PHONE_CARD_MOBILE_NUMBER' | translate}}</label>
      <input type="text" onkeypress="if ( isNaN( String.fromCharCode(event.keyCode) )) return false" class="form-control"
        [formControlName]="phoneNoControl"
      (keyup)="keyupnumber($event)" (blur)="validatePhoneData()"/>
      <input type="hidden" class="form-control" [formControlName]="config.name" />
      <span class="error_info">
        <i class="fa fa-exclamation error_icon" aria-hidden="true" *ngIf="!isPhoneNoValid"
          [class.red_error]="isPhoneNoError" [class.cursor_hand_none]="!isPhoneNoError"
          (click)="openPhoneNoError()"></i>
        <div class="error_info_popup">{{ 'REFERENCE_NUMBER_ERROR_1' | translate }}<i class="fa fa-times" aria-hidden="true"
            (click)="closePhoneNoError()"></i></div>
      </span>
      <div class="custom-error error_new_ui" *ngIf="!isPhoneNoValid && !isPhoneNoError">
        <div class="left_div">
          <ul>
            <li class="alert"><i class="icon-warning"></i>{{ 'COMMONS_OOPS' | translate }}</li>
            <li class="message">{{ 'REFERENCE_NUMBER_ERROR_1' | translate }}</li>
          </ul>
        </div>
        <div class="right_div">
          <div class="close" aria-hidden="true" (click)="closePhoneNoError()">
            <i class="icon-cancel"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
