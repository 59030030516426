import { ComplexValidation, FieldValidationRule, Restriction } from './model/field-validation-rule';
import { ValidatorFn, Validators } from '@angular/forms';
import { ValidatorsPA } from './validators-pa';
import { Validator } from './model/validator.enum';
import { ValidatorParamKey, ValidatorParamMap } from './validator-param-builder';
import { ValidationRegexResolver } from './validation-regex-resolver';

/** Defines max allowable characters in Input type=text if not specified by rules. */
export const DEFAULT_INPUT_MAX_LENGTH = 100;

/**
 * Validation Rule Resolver, all processing logic for resolving validators and restrictions from FieldValidationService
 * should be implemented here, without code coupling to any component.
 * */
export class ValidationRuleResolver {

  /**
   * resolve all ValidatorFn from rules, bind parameters as needed (for component driven validators)
   * and compose validators at the end of the process.
   * */
  static resolveValidators(rule: FieldValidationRule, paramMap?: ValidatorParamMap<ValidatorParamKey>): ValidatorFn | null {
    const validators: ValidatorFn[] = [];
    // console.log('< processing rule:>', rule);
    // resolve min max or pattern restrictions
    if (rule?.restrictions && rule.restrictions.length !== 0) {
      rule.restrictions.forEach(restriction => {
        validators.push(this.resolveRestrictionToValidator(restriction));
      });
    }

    const staticAndComponentDrivenValidators: ValidatorFn[] = this.resolveStaticAndComponentDrivenValidators(rule, paramMap);
    if (staticAndComponentDrivenValidators && staticAndComponentDrivenValidators.length > 0) {
      validators.push(...staticAndComponentDrivenValidators);
    }

    const dynamicConfigurableAndTemporalValidators: ValidatorFn[] = this.resolveConfigurableAndTemporalValidators(rule);
    if (dynamicConfigurableAndTemporalValidators && dynamicConfigurableAndTemporalValidators.length > 0) {
      validators.push(...dynamicConfigurableAndTemporalValidators);
    }

    if (rule?.required) {
      validators.push(Validators.required);
    }

    // console.log('== resolved total validators: >>', validators.length);
    return Validators.compose(validators);
  }


  static resolveStaticAndComponentDrivenValidators(rule: FieldValidationRule, paramMap?: ValidatorParamMap<ValidatorParamKey>): ValidatorFn[] | null {
    // console.log('resolving static and component driven validators ...');
    const validators: ValidatorFn[] = [];
    if (rule.validators && rule.validators.length !== 0) {
      rule.validators.forEach(validator => {
        // console.log('resolving static validator from definition:', validator);
        // resolve current parameter for validator
        const param: any = paramMap?.get(new ValidatorParamKey(rule.controlName, validator));
        // implement rule Validator from backend to Real Validator mapping.
        switch (validator) {
          /** no param - standalone validators */
          case Validator.noSpace:
            validators.push(ValidatorsPA.noSpace);
            break;
          case Validator.noBlanks:
            validators.push(ValidatorsPA.noBlanks);
            break;
          /** component driven validator, parameter is coupled to component */
          case Validator.conditionalZip:
            validators.push(ValidatorsPA.conditionalZip(param));
            break;
          case Validator.conditionalUsZip:
            validators.push(ValidatorsPA.conditionalUsZip(param));
            break;
          case Validator.conditionalUkZip:
            validators.push(ValidatorsPA.conditionalUkZip(param));
            break;
          case Validator.allowedValueOrLabels:
            validators.push(ValidatorsPA.allowedValueOrLabels(param));
            break;
          case Validator.requiredIf:
            validators.push(ValidatorsPA.requiredIf(param));
            break;
          case Validator.email:
            validators.push(Validators.email);
            break;
          case Validator.dateIsValid:
            validators.push(ValidatorsPA.dateIsValid(rule.format));
            break;
          case Validator.dateNotInFuture:
            validators.push(ValidatorsPA.dateNotInFuture);
            break;
          case Validator.dateNotInPast:
            validators.push(ValidatorsPA.dateNotInPast);
            break;


          default:
            // new validator come from backend rule, we do not have mapping analyze and implement
            console.warn('! No validator implementation for the following definition:>', validator);
        }

      });
    }
    // console.log('======= static validators result >>', validators.length);
    return validators;
  }


  /***
   * Extension to build list of ValidatorFn used in Dynamic form components. ie Multi-Document, driven from Rules
   * */
  static resolveComplexValidators(complex: ComplexValidation, rootErrorMessage?: string): ValidatorFn[] | null {
    // console.log('resolving validators from complex type... with root message:', rootErrorMessage);
    const validators: ValidatorFn[] = [];

    // initial error message handling, in next versions drive this by rule property messageLevel:VERBOSE
    complex.config.forEach(value => {
      if (!value?.error) {
        console.log('value error is empty, override with root:', rootErrorMessage);
        value.error = rootErrorMessage;
      }
    })

    const ruleConfig: FieldValidationRule = {
      rootError: rootErrorMessage,
      controlName: null,
      format: complex.format,
      configurations: complex.config
    }

    const ruleStatic: FieldValidationRule = {
      rootError: rootErrorMessage,
      controlName: null,
      format: complex.format,
      validators: complex.static
    }

    const staticValidators: ValidatorFn[] = this.resolveStaticAndComponentDrivenValidators(ruleStatic);
    if (staticValidators && staticValidators.length > 0) {
      validators.push(...staticValidators);
    }

    const dynamicConfigurableAndTemporalValidators: ValidatorFn[] = this.resolveConfigurableAndTemporalValidators(ruleConfig);
    if (dynamicConfigurableAndTemporalValidators && dynamicConfigurableAndTemporalValidators.length > 0) {
      validators.push(...dynamicConfigurableAndTemporalValidators);
    }


    return validators;
  }


  /**
   * resolve new advanced validators, which can be configured dynamically using data from backend and specific
   * DB configuration.
   * */
  private static resolveConfigurableAndTemporalValidators(rule: FieldValidationRule) {
    // console.log('resolving configurable and temporal validators ...');
    const validators: ValidatorFn[] = [];

    rule.configurations?.forEach(validatorConfig => {

      // console.log('----> validator type:>', validatorConfig.validator);
      switch (validatorConfig.validator) {

        case Validator.allowedValue:
          validators.push(ValidatorsPA.allowedValue(validatorConfig.parameters));
          break;
        case Validator.fieldMatchAnother:
          validators.push(ValidatorsPA.fieldMatchAnother(validatorConfig.controlRef))
          break;
        case Validator.legalAge:
          validators.push(ValidatorsPA.legalAge(validatorConfig));
          break;
        case Validator.dateAfter:
          validators.push(ValidatorsPA.dateAfter(validatorConfig));
          break;
        case Validator.dateBefore:
          validators.push(ValidatorsPA.dateBefore(validatorConfig));
          break;
        case Validator.dateNotAfter:
          validators.push(ValidatorsPA.dateNotAfter(validatorConfig));
          break;
        case Validator.dateNotBefore:
          validators.push(ValidatorsPA.dateNotBefore(validatorConfig));
          break;
        case Validator.conditionalPassportValidator:
            validators.push(ValidatorsPA.fieldMatchCountryAnother(validatorConfig.controlRef))
            break;
        case Validator.conditionalDrivingLicenseValidator:
            validators.push(ValidatorsPA.fieldMatchDrivingLicense(validatorConfig.controlRef))
            break;
        default:
          console.warn('no validator implemented for config:', validatorConfig);
      }
    });

    // console.log('======= configurable validators result >>', validators.length);
    return validators;
  }


  /** resolve available restrictions to particular ValidatorFn  */
  static resolveRestrictionToValidator(restriction: Restriction): ValidatorFn | null {
    if (restriction?.weight && restriction.weight.max) {
      return Validators.maxLength(restriction.weight.max);
    }

    if (restriction?.weight && restriction.weight.min) {
      return Validators.minLength(restriction.weight.min);
    }

    if (restriction?.regexPattern) {
      return Validators.pattern(restriction.regexPattern);
    }

    if (restriction?.regexName) {
      const pattern = ValidationRegexResolver.resolveRegex(restriction.regexName)

      if (!pattern) {
        return null;
      }

      return Validators.pattern(pattern);
    }


    return null;
  }


  /**
   * resolves HTML Input `maxlength` attribute value for control from rules if exists.
   * Otherwise, returns `DEFAULT_INPUT_MAX_LENGTH=100`
   *
   * Binds to HTML template to limit max chars allowable in input control.
   * */
  static resolveHtmlInputMaxLength(formControlName: string, rules: FieldValidationRule[]): number {
    let inputMaxLength = DEFAULT_INPUT_MAX_LENGTH; // default

    rules?.forEach(rule => {
      if (rule?.controlName === formControlName) {
        inputMaxLength = this.resolveRestrictionToInputFieldRestriction(rule?.restrictions);
      }

    });
    return inputMaxLength;
  }

  /**
   * extracts HTML input restrictions form validation looking for `restriction.weight.max` only
   * */
  static resolveRestrictionToInputFieldRestriction(restrictions?: Restriction[]): number {
    let maxLength = DEFAULT_INPUT_MAX_LENGTH;
    restrictions?.forEach(restriction => {
      if (restriction?.weight && restriction.weight.max) {
        maxLength = restriction.weight.max;
      }
    });

    return maxLength;
  }


}
