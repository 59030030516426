import { Directive, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import * as Hammer from 'hammerjs';

// declare var Hammer: any;

@Directive({
  selector: '[hammer-gestures]'
})
export class HammerGesturesDirective implements AfterViewInit {
  // constant for swipe action: left or right
  SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

  @Output() onGesture: EventEmitter<any> = new EventEmitter();
  constructor(private ele: ElementRef) {
  }

  ngAfterViewInit() {
    let is_touch_device = 'ontouchstart' in document.documentElement;
    //Restrict hammer for Mobile use only
    if (is_touch_device) {
      let hammer = new Hammer(this.ele.nativeElement);
      hammer.on(this.SWIPE_ACTION.LEFT, (event) => {
        this.onGesture.emit(this.SWIPE_ACTION.LEFT);
      });
      hammer.on(this.SWIPE_ACTION.RIGHT, (event) => {
        this.onGesture.emit(this.SWIPE_ACTION.RIGHT);
      });
    }
  }
}
/*
 * How to use it in required component
 *
 * import {Component} from "angular2/core";
   import {HammerGesturesDirective} from "../../framework/hammer";
    @Component({
        selector: "<html-selector>",
        template: `<div (onGesture)="doSwipe($event)" hammer-gestures></div>`,
        directives: [HammerGesturesDirective]
    })
    export class YOUR-COMPONENT {
        constructor() {}
        doSwipe(direction: string) {
            alert(direction);
        }
    }
 *
 * */