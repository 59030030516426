<div class="row col-xs-12 col-sm-12 col-md-12" style="padding-bottom: 12px">
    <div class="col-xs-12 col-sm-12 col-md-12">{{ config?.label | translate}}</div>
    <div class="col-xs-12 col-sm-12 col-md-2">
        <div class="group">
            <label class="radio-inline radioLabelContent">
                {{ 'LABEL_YES' | translate}}<input type="radio" [id]="config.name + '_YES'" class="testingradio" value="YES"
                    (click)="setValue('yes')" [checked]="enabled">
            </label>
        </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-2">
        <div class="group">
            <label class="radio-inline radioLabelContent">
                {{ 'LABEL_NO' | translate}}<input type="radio" [id]="config.name + '_NO'" class="testingradio" value="NO" 
                    (click)="setValue('no')" [checked]="!enabled">
            </label>
        </div>
    </div>
</div>
<ng-container *ngIf="forms.length > 0">
    <ng-container *ngFor="let form of forms.controls | keyvalue; let i=index">
        <div class="row col-xs-12 col-sm-12 col-md-12" 
            style="align-items: baseline; padding-bottom: 12px;"
            [ngStyle]="{'padding-bottom': i === forms.length - 1 ? '0px' : '12px' }">
  
            <div class="row" style="padding-bottom: 10px;padding-left: 15px;"  *ngIf="isCurDirectorShip()">
                <div class="col-xs-6 col-sm-6 col-md-2" style="width: 32%;padding: 10px;">
                    <div class="group">
                        <label class="placeholder_white custom_label labelEllipsis" title="nature_of_business">
                            {{ 'NATURE_OF_BUSINESS' | translate }}
                        </label>
                        <input id="nature_of_business" type="text" class="form-control" placeholder="" maxlength="200"  
                            [formControl]="getControl(i, 'nature_of_business')" style="width: 98%;"/>
                    </div>
                </div>
                <div class="col-xs-6 col-sm-6 col-md-2" style="width: 32%;padding: 10px;">
                    <div class="group">
                        <label class="placeholder_white custom_label labelEllipsis" title="nature_of_business">
                            {{ 'DIRECTORSHIP_START_DATE' | translate }}
                        </label>
                        <!-- <input id="directorship_start_date" type="text" class="form-control" placeholder="" maxlength="200"  
                            [formControl]="getControl(i, 'directorship_start_date')"/> -->
                            <input type="date" id="{{'directorship_start_date' + i}}" aria-required="true"  class="form-control" 
                            [formControl]="getControl(i, 'directorship_start_date')" style="width: 98%;"  [max]="maxValue"/>
                    </div>
                </div>
            </div> 
            <div class="row col-xs-12 col-sm-12 col-md-12">
            <div class="col-xs-12 col-sm-12 col-md-4">
                <div class="group">
                    <label class="placeholder_white custom_label labelEllipsis" title="details">
                        {{ getLabel() | translate }}
                    </label>
                     <div *ngIf="getType() == 'textarea'">   
                    <textarea id="details" type="text" class="form-control custom-text-area" placeholder="" maxlength="4000" rows="20" cols="80"
                        [formControl]="getControl(i, 'details')"></textarea>
                    </div>
                    <div *ngIf="getType() == 'input'">
                    <input id="details" type="text" class="form-control" placeholder="" maxlength="200"  [formControl]="getControl(i, 'details')"/> 
                    </div>    
                </div>
            </div>
           <div class="col-xs-12 col-sm-12 col-md-4" *ngIf="form">
                <div class="group">
                    <label class="placeholder_white custom_label labelEllipsis" title="country">
                        {{ 'COMMONS_COUNTRY' | translate }}
                    </label>
                        <custom-select [items]="countryList"  bindLabel="country_name"
                        bindvalue="country_name"  displayValue="country_name" controlName = "country_name"   [selectedValue]="getSelectedCountry(form.value)" 
                        (valueChange)="trackChange($event, form.value)"  [group] = "form.value"
                        [typeahead]="countryTypeahead" [typeToSearchText]="" (clearData)="onClearData()" 
                        ></custom-select>
                    </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4" *ngIf="forms.length > 1">
                <i class="icon-garbage bin hover-icon" (click)="removeForm(i)"></i>
            </div>
            </div>
        </div>
    </ng-container>
    <div class="row col-xs-12 col-sm-12 col-md-12">
        <div class="col-xs-12 col-sm-12 col-md-4">
            <span>{{ 'CLICK' | translate }} <a (click)="addNewForm()">{{ 'HERE' | translate }}</a> {{ 'ADD_MORE' | translate}}</span>
        </div>
    </div>
</ng-container>