<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12">
    <p class="black_text">{{options['title']}}</p>
    <hr>
  </div>
</div>
<div class="addCard m-b-10">
  <div class="row">
    <div class="col-xs-12 col-sm-4 col-md-3 dp-r-0" *ngFor="let data of options.data">
      <div class="clipCard" (click)="openViewProfilePopup(data,options['key'])" style="cursor: pointer;">
        <img src="../../assets/img/you.png" height="30px">
        <p class="boldcom"> {{'VP_ALIAS_HEADER' | translate }}</p>
        <p class="text_ellipses"> <span>{{ data['first_name'] }} {{ data['middle_name'] }} {{ data['last_name'] }}
            {{ data['suffix'] }}

          </span></p>
        <p *ngIf="options['visibility']">
          {{data['start-date']}} - {{data['end-date']}}
        </p>
        <!--<p class="text_ellipses"> <span>{{ data['last_name'] }}</span></p>
              <p class="text_ellipses"> <span>{{ data['suffix'] }}</span></p>-->
      </div>
    </div>
  </div>
</div>
