import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthyRoutingModule } from './authy-routing.module';
import { AuthyComponent } from './authy.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';

import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalModule, MsalService } from '@azure/msal-angular';
import { IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from 'environments/environment';
import { AuthyService } from './authy.service';



export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(environment.msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: { scopes: [] },
    loginFailedRoute: '/login-failed',
  };
}

// const initialNavigation =
//   !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
//     ? withEnabledBlockingInitialNavigation() // Set to enabledBlocking to use Angular Universal
//     : withDisabledInitialNavigation();

@NgModule({
  declarations: [
    AuthyComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MsalModule,
    AuthyRoutingModule
  ],
  exports: [
    AuthyComponent
  ],
  providers: [
    AuthyService,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
    { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ]
})
export class AuthyModule { }
