import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'profile-personal',
  templateUrl: './profile-personal.component.html',
  providers: []
})

export class ProfilePersonalComponent {
  @Input() options: any;
  @Output() onViewProfile: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  openViewProfilePopup(data, key, childKey): void {
    data['key'] = key;
    data['childKey'] = childKey;
    this.onViewProfile.emit(data);
  }
}