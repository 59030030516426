import { Component, ViewContainerRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/fieldconfig.interface';

declare var $: any;
@Component({
  selector: 'form-hidden',
  styleUrls: ['form-hidden.component.scss'],
  templateUrl: './form-hidden.component.html'
})
export class FormHiddenComponent implements Field {
  config: FieldConfig;
  group: FormGroup;

  showFullLabelText() {
    $('[data-toggle="popover"]').popover();
  }
}
