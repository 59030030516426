<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12">
      <p class="black_text">{{ options['title'] }}</p>
      <hr>
  </div>
</div>
<div class="addCard m-b-10">
  <div class="row">
      <div class="col-xs-12 col-sm-4 col-md-3 dp-r-0" *ngFor="let data of options.data">
          <div class="clipCard" (click)="openViewProfilePopup(data,options['key'])">
              <img src="../../assets/img/id-card.png" height="30px">
              <p class="boldcom">{{ data['type_description'] }}</p>
              <p class="text_ellipses"> <span>{{ !!data['detail'] ? data['detail']+ ',' : '' }} {{ data['is_using_agency'] }}</span></p>
              <p class="text_ellipses"> <span>{{ data['license_number'] }}</span></p>
          </div>
      </div>
  </div>
</div>