import { ProfessionalLicenseRulesConfig } from './rules';
import { ClassList } from './rules/professional-license.rules.config';

export class ProfessionalLicenseConfig {
  static STAGE_ID: string = 'licence-data-v1';
  static calenderYear: any = '';
  static PROFESSIONAL_LICENSE_TYPE = 'PROFESSIONAL_LICENSE_TYPES';

  static PROF_LICENSE_MAX_ELEMENT_PREFERENCE = '1411';
  
  static ProfessionalLicenseSteps: any = [
    'confirm',
    'type',
    'country',
    'number',
    'issueagency',
    'details',
    'reconfirm'
  ];

  static subStepIndexMapping: Object = {
    confirm: {
      subStep: 'confirm',
      subStepIndex: 0
    },
    type: {
      subStep: 'type',
      subStepIndex: 1
    },
    country: {
      subStep: 'country',
      subStepIndex: 2
    },
    state: {
      subStep: 'state',
      subStepIndex: 3
    },
    details: {
      subStep: 'details',
      subStepIndex: 4
    },
    number: {
      subStep: 'number',
      subStepIndex: 5
    },
    issueagency: {
      subStep: 'issueagency',
      subStepIndex: 6
    },
    reconfirm: {
      subStep: 'reconfirm',
      subStepIndex: 7
    }
  };

  static defaultCountry: string = '';
  static defaultCountryShortName: string = '';

  static ats: Object = {
    'id': '',
    'is_license': '',
    'country': '',
    'state_name': '',
    'state_code': '',
    'country_name': '',
    'type': '',
    'detail': '',
    'city': '',
    'license_number': '',
    'full_name': '',
    'source': '',
    'is_using_agency': '',
    'issue_date': '',
    'status': '',
    'state': {
      'step_status': '',
      'stage_status': '',
      'current_iteration': '',
      'current_stage': '',
      'current_step': '',
      'current_step_index': '',
      'current_sub_step': '',
      'current_sub_step_index': '',
      'current_status': ''
    }
  };

  static regular: Object = {
    'id': '',
    'is_license': '',
    'country': '',
    'state_name': '',
    'state_code': '',
    'country_name': '',
    'type': '',
    'detail': '',
    'city': '',
    'license_number': '',
    'full_name': '',
    'source': '',
    'is_using_agency': '',
    'issue_date': '',
    'status': '',
    'state': {
      'step_status': '',
      'stage_status': '',
      'current_iteration': '',
      'current_stage': '',
      'current_step': '',
      'current_step_index': '',
      'current_sub_step': '',
      'current_sub_step_index': '',
      'current_status': ''
    }
  };

  static displayLabel: Object = {
    'id': '',
    'is_license': '',
    'country': '',
    'state_name': '',
    'state_code': '',
    'country_name': '',
    'type': '',
    'detail': '',
    'city': '',
    'license_number': '',
    'full_name': '',
    'source': '',
    'is_using_agency': '',
    'issue_date': '',
    'status': ''
  };

  static defaultCountryOptions(): Object {
    let countryOptions = {};
    countryOptions['defaultCountry'] = ProfessionalLicenseConfig.defaultCountry;
    countryOptions['defaultCountryShortName'] = ProfessionalLicenseConfig.defaultCountryShortName;
    return countryOptions;
  }

  static getSteps(): any {
    return ProfessionalLicenseConfig.ProfessionalLicenseSteps;
  }

  static getProfessionalLicenseType(): Array<Object> {
    let types = [
      { id: 'Certificate', name: 'PROFESSIONAL_LICENSE_TYPE_1' },
      { id: 'FAA', name: 'PROFESSIONAL_LICENSE_TYPE_2' },
      { id: 'Industry Accreditation', name: 'PROFESSIONAL_LICENSE_TYPE_3' },
      { id: 'License', name: 'PROFESSIONAL_LICENSE_TYPE_4' },
      { id: 'Membership', name: 'PROFESSIONAL_LICENSE_TYPE_5' },
      { id: 'Other', name: 'PROFESSIONAL_LICENSE_TYPE_6' },
      { id: 'State Insurance', name: 'PROFESSIONAL_LICENSE_TYPE_7' },
    ];

    return types;
  }

  static getCalenderYear(): any {
    this.calenderYear = 19;
    return this.calenderYear;
  }

  static getRuleList(): any {
    return ProfessionalLicenseRulesConfig.professionalLicenseRules;
  }

  static getRuleClassList(): any {
    return ClassList;
  }

  /**
   * get professional license name for the given type for translation
   *
   * @param type
   */
  static getLicenseType(type: String) {
    let types = this.getProfessionalLicenseType();
    for (let currentType of types) {
      if (currentType['id'].toUpperCase() === type.toUpperCase()) {
        return currentType['name'];
      }
    }

    return null;
  }

}
