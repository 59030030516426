export class LocationPickerConfig {
    // The preference value can be either of consentCountry or dependency to lookup states
    // Dependency is used for Hukou - China
    static STATE_LOOKUP_PREFERENCE = 'dependency';
    static DEPENDENCY = 'dependency';
    static BOTH_PRESENT = 'BOTH_PRESENT';
    static DEPENDENCY_PRESENT = 'DEPENDENCY_PRESENT';
    static CONSENT_COUNTRY_PRESENT = 'CONSENT_COUNTRY_PRESENT';
    static NONE = 'NONE';
}
