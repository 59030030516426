    <div style="margin-left: 5px;" >
        <div >
            <ul class="line">
                <li *ngFor="let item of stateArray; let i=index"
                style="width:13%;" [ngClass]="score >= i+1 ? 'bar-green' : 'bar-white'">
                </li>
            </ul>
        </div >
        <p style="margin-bottom: -5px;margin-top: -4px;font-family: sans-serif;">{{ "PASSWORD_POLICY_HEADER_2" | translate}}
        </p>
        <ul style="text-indent: -8px; margin-left: 16px;" >
            <li style="font-family: sans-serif;" *ngFor="let item of stateArray">
                <i class="fa"  [ngClass]="item.status?'fas fa-check-circle green-check' : 'fa-times-circle  red-check'"></i>
                  {{item.label}}
            </li>
        </ul>      
    </div>
