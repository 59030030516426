
export class DOTConfig {

    static defaultConfig = [
        {
            'name': 'DOT Page',
            'header': 'DOT Information',
            'sub_header': 'DOT Information',
            'swipe_left': false,
            'swipe_right': false,
            'inputs': [
                {
                    name: 'NEXT',
                    label: 'NEXT',
                    type: 'BUTTON',
                    show: true
                },
                {
                    name: 'SAVE AND EXIT',
                    label: 'SAVE AND EXIT',
                    type: 'BUTTON',
                    show: true
                }
            ]
        }
    ];

    static fieldTypes: Array<string> = ['text', 'country', 'state', 'address', 'city',
        'phone', 'select', 'date', 'radio', 'checkbox',
        'objectselect', 'button', 'textarea', 'numeric', 'checkboxex',
        'yesnoquestionwithexplanation','tscheckbox', 'ssn','daterange', 'consent'
    ];
    static customCSSObject = {
        'DBSCONSENTCSS': 'col-md-12 col-sm-12 col-xs-12 m-b-20',
        'CUSTOMFORMCSS': 'col-md-auto col-sm-6 col-xs-12 m-b-20',
        'TOFSCSS': 'col-md-12 col-sm-12 col-xs-12 m-b-20',
    };

    static inactiveButtons = ['SAVE AND EXIT', 'NO'];

    static getDefaultConfig(): Array<any> {
        return DOTConfig.defaultConfig;
    }

    static getFieldTypes(): Array<string> {
        return DOTConfig.fieldTypes;
    }

    static setCustomClass(element) {
        let cssVal = '';
        let name = element['name'];
        switch (name) {
            case 'DBS_CONSENT_IAGREE':
                cssVal = this.customCSSObject['DBSCONSENTCSS'];
                element['checkBoxClass'] = cssVal;
                break;
            case 'TERMS_OF_SERVICE':
                    cssVal = this.customCSSObject['TOFSCSS'];
                    element['checkBoxClass'] = cssVal;
                    break;
            default:
                cssVal = this.customCSSObject['CUSTOMFORMCSS'];
                element['checkBoxClass'] = cssVal;
                break;
        }
    }

}