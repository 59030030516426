<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12">
    <p class="black_text">{{options['title']}}</p>
    <hr>
  </div>
</div>
<div class="addCard m-b-10">
  <div class="row">
    <div class="col-xs-12 col-sm-4 col-md-3 dp-r-0" *ngIf="options['key'] === 'forms'">
      <div class="clipCard intlCard" (click)="openViewProfilePopup(customFormsData,options['key'])">
        <img src="../../assets/img/you.png" height="30px">
        <p>{{ options['title'] }}</p>
        <span class="duration text_ellipses">
          {{ candidateFormFieldValue }}
          <!-- {{customFormsData[0]['value']}} -->
        </span>
      </div>
    </div>
    <div class="col-xs-12 col-sm-4 col-md-3 dp-r-0" *ngIf="options['key'] !== 'forms'">
      <div class="clipCard intlCard" (click)="openViewProfilePopup(data,options['key'])">
        <img src="../../assets/img/you.png" height="30px">
        <!-- <p class="boldcom">  {{'VP_ALIAS_HEADER' | translate }}</p> -->
        <p>{{title}}</p>
        <span class="duration text_ellipses captext">
          {{intlDisplayTitle}}
        </span>
      </div>
    </div>
  </div>
</div>
