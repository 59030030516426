import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AppConfig } from '../app/app.config';
import { LocalStorageService } from './localstorage.service';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { UtilService } from './utils/util.service';

@Injectable()
export class LatencyChecker {
    /**
     *
     * @param _authHttp - private member for invoking http calls
     */
    constructor(private _authHttp: HttpClient, 
        private localStorage: LocalStorageService,
        private _util: UtilService) {
    }

    /**
     * Function to identify the bandwidth available to the end users for any download/get
     * operations
     * returns - JSON object in the below format
     * {durationInSeconds:<<value>>,speedBps:<<value>>,speedKbps:<<value>>,speedMbps:<<value>>}
     */
    getDownloadBandWidth() {
        let downloadUrl: string = AppConfig.API_ENDPOINT() + '/assets/img/logo.png?q=' + this._util.getCryptoRandom();
        //File Size in Bytes * 8 = bits convertion
        let downloadSize: number = 19631 * 8;
        let startTime: number, endTime: number;
        let duration: any, speedBps: any, speedKbps: any, speedMbps: any;
        startTime = (new Date()).getTime();

        return this._authHttp.get(downloadUrl, { responseType: 'text' })
            .pipe(map(x => {
                    endTime = (new Date()).getTime();
                    //Differece is determined in  milliseconds and hence to convert to seconds dividing by 1000;
                    duration = (endTime - startTime) / 1000;
                    speedBps = (downloadSize / duration).toFixed(2);
                    speedKbps = (speedBps / 1024).toFixed(2);
                    speedMbps = (speedKbps / 1024).toFixed(2);
                    return { durationInSeconds: duration, speedBps: speedBps, speedKbps: speedKbps, speedMbps: speedMbps };
                }), catchError(err => this._handleError(err)));
    }

    /**
    * Function to identify the bandwidth available to the end users for any upload operations
    *
    * returns - JSON object in the below format
    * {durationInSeconds:<<value>>,speedBps:<<value>>,speedKbps:<<value>>,speedMbps:<<value>>}
    */
    getUploadBandWidth() {
        //Created an array buffer of length 1 MB, and trying to post to the server
        //to find the upload bandwidth
        let uploadData: ArrayBuffer = new ArrayBuffer(20000);
        let uploadSize: number;
        let startTime: number, endTime: number;
        let duration: any, speedBps: any, speedKbps: any, speedMbps: any;
        startTime = (new Date()).getTime();
        let uploadTestUrl = AppConfig.API_ENDPOINT() + '/api/v1/workflow/uploaddata-dummy';
        let authHeaders = this.getHeaderOptions();

        return this._authHttp.post(uploadTestUrl, uploadData, authHeaders)
            .pipe(map(x => {
                endTime = (new Date()).getTime();
                //Duration is determined in  milliseconds, ms / 1000 gives use seconds.
                duration = (endTime - startTime) / 1000;
                speedBps = (uploadSize / duration).toFixed(2);
                speedKbps = (speedBps / 1024).toFixed(2);
                speedMbps = (speedKbps / 1024).toFixed(2);
                return { durationInSeconds: duration, speedBps: speedBps, speedKbps: speedKbps, speedMbps: speedMbps };
            }), catchError(err => this._handleError(err)));
    }

    /**
     * returns - true, if user has network connectivity, else false
     */
    isUserHasConnection(): boolean {
        return navigator.onLine;
    }

    /**
     * To be called by the methods before making http calls to append the
     * authorization headers
     */
    private getHeaderOptions(): any {
        const authHeaders = new Headers({
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Authorization': 'Bearer ' + this.localStorage.getItem('access_token')
        });

        const OPTIONS = { headers: authHeaders };
        return OPTIONS;
    }

    /**
     *
     * @param error - error received from service calls
     */
    private _handleError(error: any) {
        console.error(error);
        //let _body = JSON.parse(error['_body']) || { };
        let returnvalue = { durationInSeconds: -1, speedBps: -1, speedKbps: -1, speedMbps: -1 }
        //Despite the error we are going to return the same JSON structure with values as -1
        return throwError(returnvalue);
    }
}