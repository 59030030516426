import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'password-expired',
  templateUrl: './password-expired.component.html'
})

export class PasswordExpiredComponent implements OnInit {
  constructor(private _router: Router) { }

  ngOnInit() {
  }

  ngOnDestroy() { }

  redirectForgotPassword(): void {
    this._next('./profile/forgot-password');
  }

  private _next(url): void {
    this._router.navigate([url]);
  }
}
