<div class="body_container container loginMobilePad m-t-20 mar10 mobile_container anime-clean">
  <div class="signin">
    <div class="row neg_mar">
      <div class="col-md-6 col-sm-6 hidden-xs sign_bg p-t-50" *ngIf="!isInviteNotification && !isInviteEiaf && isStandaloneWotc===false && !isInviteMI && !isInviteAssessment && !isInviteConsentType">
        <h3 class="text-center m-t-10 pad5 hide" id="signintext">{{'LOGIN_HEADER_1'|translate:[firstname] }}</h3>
        <h3 class="text-center m-t-10 pad5 hide" id="signintextstatus400">{{'LOGIN_HEADER_3'|translate:[firstname] }}
        </h3>
        <h3 class="text-center m-t-10 pad5 wrap-word-overflow" id="signintextsecond">
          {{'LOGIN_HEADER_2'|translate:[firstname,companyName] }}</h3>
        <h3 class="text-center m-t-10 pad5 hide" id="signintextthird">{{'LOGIN_HEADER_4'|translate }}</h3>
      </div>

      <div class="col-md-6 col-sm-6 hidden-xs sign_bg p-t-50" *ngIf="isInviteConsentType">
        <h3 class="text-center m-t-10 pad5" id="signintext">{{'LOGIN_CONSENT_TYPE_HEADER_1'|translate:[firstname, companyName] }}</h3>
      </div>

      <div class="col-md-6 col-sm-6 hidden-xs sign_bg p-t-50" *ngIf="isInviteEiaf">
        <h3 class="text-center m-t-10 pad5" id="signintext">{{'LOGIN_EIAF_HEADER_1'|translate:[companyName, companyName] }}</h3>
      </div>

      <div class="col-md-6 col-sm-6 hidden-xs sign_bg p-t-50" *ngIf="isStandaloneWotc">
        <h3 class="text-center m-t-10 pad5" id="signintext">{{'STANDALONE_WOTC_LOGIN'|translate:[firstname,companyName] }}</h3>
      </div>

      <div class="col-md-6 col-sm-6 hidden-xs sign_bg p-t-50" *ngIf="isInviteMI && !isInviteDQMI">
        <h3 class="text-center m-t-10 pad5 wrap-word-overflow" id="signintext">{{'LOGIN_MI_HEADER'|translate:[firstname] }}</h3>
      </div>

      <div class="col-md-6 col-sm-6 hidden-xs sign_bg p-t-50" *ngIf="isInviteMI && isInviteDQMI">
        <h3 class="text-center m-t-10 pad5 wrap-word-overflow" id="signintext">{{'DQ_LOGIN_MI_HEADER'|translate:[firstname] }}</h3>
      </div>

      <div class="col-md-6 col-sm-6 hidden-xs sign_bg p-t-50" *ngIf="isInviteAssessment">
        <h3 class="text-center m-t-10 pad5 wrap-word-overflow" id="signintext">{{'LOGIN_ASSESSMENT_HEADER'|translate:[firstname,companyName] }}</h3>
      </div>

      <div class="col-md-6 col-sm-6 hidden-xs sign_bg p-t-50" *ngIf="isInviteNotification">
        <h3 class="text-center m-t-10 pad5 wrap-word-overflow" id="signintextsecond">{{'LOGIN_NOTIFICATION_HEADER'|translate:[firstname] }}</h3>
      </div>

      <!-- Sign in Form Starts here-->
      <div class="pad0 col-xs-12 col-sm-6 col-md-6 signup_form mar65">
        <div class="signup_container">
          <div class="row mobile-header visible-xs" id="loginMobileAlign">
            <div class="col-xs-12 m-t-25">
              <div class="header_txt_white" *ngIf="!isInviteNotification && !isInviteEiaf && isStandaloneWotc===false && !isInviteMI && !isInviteAssessment && !isInviteConsentType">
                <h2 class="text-center m-t-10 pad5 hide" id="signintextsmall">
                  {{'LOGIN_HEADER_1'|translate:[firstname] }}</h2>
                <h3 class="text-center m-t-10 pad5 hide" id="signintextstatus400small">
                  {{'LOGIN_HEADER_3'|translate:[firstname] }}</h3>
                <h2 class="text-center m-t-10 pad5 wrap-word-overflow" id="signintextsecondsmall">
                  {{'LOGIN_HEADER_2'|translate:[firstname,companyName] }}</h2>
                <h3 class="text-center m-t-10 pad5 hide" id="signintextthirdsmall">{{'LOGIN_HEADER_4'|translate }}</h3>
                <!--<h4 class="m-t-10">Already have a Profile ? - <a (click)="redirectSignin()">Sign in</a></h4>-->
              </div>
              <div class="header_txt_white" *ngIf="isInviteEiaf">
                <h2 class="text-center m-t-10 pad5" id="signintextsmall">
                  {{'LOGIN_EIAF_HEADER_1'|translate }}</h2>
              </div>
              <div class="header_txt_white" *ngIf="isInviteConsentType">
                <h2 class="text-center m-t-10 pad5" id="signintextsmall">
                  {{'LOGIN_CONSENT_TYPE_HEADER_1'|translate: [(firstname || ''),(companyName || '')] }}</h2>
              </div>
              <div class="header_txt_white" *ngIf="isStandaloneWotc">
                <h2 class="text-center m-t-10 pad5" id="signintextsmall">
                  {{'STANDALONE_WOTC_LOGIN'|translate:[firstname,companyName] }}</h2>
              </div>
              <div class="header_txt_white" *ngIf="isInviteMI  && !isInviteDQMI">
                <h2 class="text-center m-t-10 pad5 wrap-word-overflow" id="signintextsmall">
                  {{'LOGIN_MI_HEADER'|translate:[firstname] }}</h2>
              </div>
              <div class="header_txt_white" *ngIf="isInviteMI && isInviteDQMI">
                <h2 class="text-center m-t-10 pad5 wrap-word-overflow" id="signintextsmall">
                  {{'DQ_LOGIN_MI_HEADER'|translate:[firstname] }}</h2>
              </div>
              <div class="header_txt_white" *ngIf="isInviteAssessment">
                <h2 class="text-center m-t-10 pad5 wrap-word-overflow" id="signintextsmall">
                  {{'LOGIN_ASSESSMENT_HEADER'|translate:[firstname,companyName] }}</h2>
              </div>
              <div class="header_txt_white" *ngIf="isInviteNotification">
                <h2 class="text-center m-t-10 pad5 wrap-word-overflow" id="signintextsmall">
                  {{'LOGIN_NOTIFICATION_HEADER'|translate:[firstname]}}</h2>
              </div>
            </div>
          </div>
          <div class="row m-t-40 pad15">
            <div class="col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 col-xs-12 ">
              <div class="header_txt_white">
                <p class="signInHeader">{{'SIGN_IN_HEADER'|translate }}</p>
              </div>
            </div>
          </div>
          <form class="m-t-25 pad15" autocomplete="off" #loginForm="ngForm"
            (ngSubmit)="loginOrMigrate(loginForm.value)" novalidate>
            <div class="row">
              <div class="col-xs-12  col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 dp-r-0 m-b-20">
                <div class="group">
                  <label class="placeholder_white custom_label" for="loginInputEmail">{{'EMAIL_LABLE'|translate }}</label>
                  <input type="email" [(ngModel)]="email" name="email" aria-required="true" class="form-control validate" id="loginInputEmail"
                  title="Please enter email" (blur)="validateEmail(loginForm.value.email)" autofocus autocomplete="off"/>
                  <!--<input type="email" name="email" ngModel #email="ngModel" minlength="8" pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$" required>-->
                  <div class="error_info">
                    <i class="fa fa-exclamation error_icon" aria-hidden="true" *ngIf="isEmailErrorInfo"
                      [class.red_error]="isEmailErrorInfo && !isEmailError" [class.cursor_hand_none]="isEmailError"></i>
                    <div class="error_info_popup error_new_ui">
                      <div class="left_div">
                        <ul>
                          <li class="alert"><i class="icon-warning"></i> {{'COMMONS_OOPS' | translate}}</li>
                          <li class="message">{{emailErrorMessage | translate}}</li>
                        </ul>
                      </div>
                      <div class="right_div">
                        <div class="close" aria-hidden="true">
                          <i class="icon-cancel"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="custom-error error_new_ui" *ngIf="isEmailError">
                    <div class="left_div">
                      <ul>
                        <li class="alert"><i class="icon-warning"></i> {{'COMMONS_OOPS' | translate}}</li>
                        <li class="message">{{emailErrorMessage | translate}}</li>
                      </ul>
                    </div>
                    <div class="right_div">
                      <div class="close" aria-hidden="true" (click)="closeEmailError()">
                        <i class="icon-cancel"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 dp-r-0 m-b-20">
                <div class="group">
                  <label class="placeholder_white custom_label" for="loginInputPwd">{{'PASSWORD_LABLE'|translate }}</label>
                  <input type="password" class="form-control validate" id="loginInputPwd" aria-required="true" name="password" ngModel
                    #password="ngModel" maxlength="16" title="Please enter password"
                    (keyup)="validateOnPwd(loginForm.value.email, loginForm.value.password)" autocomplete="off"
                    (focus)="hideError()" />
                  <div class="error_info">
                    <i class="fa fa-exclamation error_icon" aria-hidden="true" *ngIf="isPwdErrorInfo"
                      [class.red_error]="isPwdErrorInfo && !isPwdError" [class.cursor_hand_none]="isPwdError"></i>
                    <div class="error_info_popup error_new_ui">
                      <div class="left_div">
                        <ul>
                          <li class="alert"><i class="icon-warning"></i>{{'COMMONS_OOPS' | translate}}</li>
                          <li class="message">{{pwdErrorMessage}}</li>
                        </ul>
                      </div>
                      <div class="right_div">
                        <div class="close" aria-hidden="true">
                          <i class="icon-cancel"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mandatoryGapError text-center" *ngIf="isError">{{errorMessage |translate}}</div>
                  <div class="custom-error error_new_ui" *ngIf="isPwdError">
                    <div class="left_div">
                      <ul>
                        <li class="alert"><i class="icon-warning"></i> {{'COMMONS_OOPS' | translate}}</li>
                        <li class="message">{{pwdErrorMessage}}</li>
                      </ul>
                    </div>
                    <div class="right_div">
                      <div class="close" aria-hidden="true" (click)="closePwdError()">
                        <i class="icon-cancel"></i>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="custom-error error_new_ui" *ngIf="isError">
                    <div class="left_div">
                      <ul>
                        <li class="alert"><i class="icon-warning"></i> {{'COMMONS_OOPS' | translate}}</li>
                        <li class="message">{{errorMessage |translate:[invalidLoginAttempts]}}</li>
                      </ul>
                    </div>
                    <div class="right_div">
                      <div class="close" aria-hidden="true" (click)="closeServerError()">
                        <i class="icon-cancel"></i>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12  col-sm-offset-2 col-sm-8 col-md-offset-2 forgotmar col-md-8 dp-r-0 m-b-10 mb-b-10">
                <div class="checkbox">
                  <a class="forgotLink" href="/#/profile/forgot-password" role="button" aria-label="Forgot Password Link" aria-pressed="false" 
                    (click)="redirectForgotPassword(loginForm.value)" tabindex="0">{{'FORGOT_PASSWORD_LINK' | translate}}</a>
                </div>
              </div>
            </div>
            <div class="col-xs-12  col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8  m-t-5 loginPad">
              <button type="submit" class="btn button font_extrabold" name="action"
                [disabled]="!isEmailValid || !isPwdValid"
                [class.grow]="isEmailValid && isPwdValid">{{'COMMONS_NEXT' | translate}}</button>
            </div>

            <div id="security_question_modal" class="modal fade custom_modal" role="dialog" data-backdrop="static"
              data-keyboard="false">
              <div class="modal-dialog sign-modal">
                <!-- Modal content-->
                <div class="modal-content">
                  <div class="modal-header">
                    <div class="col-md-12 col-xs-12 col-sm-12">
                      <div class="header_txt_white">
                        <h4 class="m-l-70">{{'SECRET_QUESTION_ANSWER_LABLE'|translate }}</h4>
                      </div>
                    </div>
                  </div>
                  <div>&nbsp;</div>
                  <div class="row">
                    <div class="col-xs-12  col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 dp-r-0 m-b-20">
                      <div class="group">
                        <label class="placeholder_white custom_label" for="loginSecretQuestion">{{ 'SECRET_QUESTION_LABLE' | translate}}</label>
                        <select class="form-control paddingsecret" id="loginSecretQuestion" aria-required="true" name="secretQuestion"
                          (change)="validateSecretQuestion($event.target.value)" [(ngModel)]="secretQuestion">
                          <option value="">{{ 'COMMONS_SELECT' | translate}}</option>
                          <option [value]="type.id" *ngFor="let type of secretQuestions">{{type.name | translate}}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xs-12  col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 dp-r-0 m-b-20">
                      <div class="group">
                        <label class="placeholder_white custom_label"
                          for="loginInputsSecAns">{{ 'SECRET_ANSWER_LABLE' | translate}}</label>
                        <input type="text" name="secretAnswer" id="loginInputsSecAns" aria-required="true" [(ngModel)]="secretAnswer"
                          class="validate form-control" (blur)="validateSecretAnswer(loginForm.value.secretAnswer)"
                          (keyup)="enableButton(loginForm.value.secretQuestion, loginForm.value.secretAnswer)"
                          title="Please enter secret answer" autocomplete="off">
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <div class="row">
                      <div class="col-md-12 col-sm-12 col-xs-12">
                        <div class="additional_data_agree">
                          <button type="button" class="btn button font_extrabold grow security_button_margin"
                            [disabled]="!isSecretQuestionValid || !isSecretAnswerValid"
                            (click)="closeAndSaveSecurityQuestionModel()">{{ 'MULTI_POSITION_EDIT' | translate }}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!--Sign in From Ends here-->
    </div>
  </div>
  <div class="clearfix"></div>
</div>


<!-- Wait Modal -->

<div class="modal fade custom_modal" id="waitOidcLoginModal" tabindex="-1" role="Application" aria-labelledby="loginWaitStatus" data-backdrop="static"
  data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="transparentbg modal-body m-t-15">
        <h4 class="modal-title" id="loginWaitStatus">{{'WAIT_ON_AGGREMENT_MODAL_TEXT' | translate }}
        </h4>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-xs-12 col-sm-4 col-md-4 p_l_0  p_r_0 m-r-15 ">
            <!-- <button type="button" class="btn button font_extrabold" data-dismiss="modal" data-toggle="resetPassword-modal" (click)="goToSignin()">CONTINUE</button>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Threshold Alert Modal -->
<div class="modal fade custom_modal" id="threshHoldAlertModal" role="dialog" data-backdrop="static"
  data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="closeTModal()">&times;</button>
      </div>
      <div class="transparentbg modal-body m-t-15">
        <h4 class="modal-title ">{{'NETWORK_THRESHOLD_TEXT' | translate }}
        </h4>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-xs-12 col-sm-4 col-md-4 p_l_0  p_r_0 m-r-15 ">
            <!-- <button type="button" class="btn button font_extrabold" data-dismiss="modal" data-toggle="resetPassword-modal" (click)="goToSignin()">CONTINUE</button>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Invalid credentials-->
<div class="modal fade custom_modal" id="invalidCredentials" tabindex="-1" role="Application" aria-labelledby="loginfailStatus" data-backdrop="static"
  data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" data-dismiss="modal" class="close">×</button>
      </div>
      <div class="transparentbg modal-body">
        <h4 class="modal-title" id="loginfailStatus" >{{errorMessageModal | translate : [invalidLoginAttempts]}}</h4>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-xs-12 col-sm-4 col-md-4 p_l_0  p_r_0 m-r-15 ">
          <!-- <button type="button" class="btn button font_extrabold" data-dismiss="modal" data-toggle="resetPassword-modal" (click)="goToSignin()">CONTINUE</button>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
