<div class="pad_30">
  <div class="anime-clean contetnheight thankyouPageContent posrel">
    <div class="row">
      <div class="col-md-12 col-xs-12 col-sm-12">
        <div class="header_txt_white">
          <h3> {{msg_key | translate}} </h3>
        </div>
      </div>
    </div>
  </div>
</div>