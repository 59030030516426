<div class="pad_30" [formGroup]="empSubForm" (onGesture)="doSwipe($event,empSubForm.value)" hammer-gestures>
  <button type="button" class="btn arrowrightctr" attr.data-id="empIntlRightArrow_{{row}}" [disabled]="!isValidButton"
    debounceDirective (debounceClick)="INTLAdditional(empSubForm.value)"> <i class="icon-arrow-right" role="img" [attr.aria-label]="'SCREEN_FARWARD_ARROW' | translate"></i></button>
  <button type="button" class="btn arrowleftctr" attr.data-id="empIntlLeftArrow_{{row}}"
    debounceDirective (debounceClick)="getPrevSwipe(empSubForm.value)"><i class="icon-arrow-left" role="img" [attr.aria-label]="'SCREEN_BACKWARD_ARROW' | translate"></i></button>
  <div class="anime-clean contetnheight posrel">
    <div class="row">
      <div class="col-md-12 col-xs-12 col-sm-12">
        <div class="header_txt_white">
          <h2>{{'EMPLOYMENT_INTLADDITIONAL_HEADER_1'|translate : [userDetails.firstname]}}</h2>
          <h4 class="m-t-15"><i class="icon-work-experience"></i> <span
              class="company">{{empSubForm.controls['companyName'].value}}</span>
          </h4>
        </div>
      </div>
    </div>

    <div class="row form-element-section">
      <div *ngIf="selfEmploymentPreferenceEnabled && (empType === 'current' || empType === 'prior')"
        class="col-xs-12 col-sm-6 col-md-5 m-b-20">
        <div class="group">
          <label class="control-labelss empJobTypeLabel" for="empTypeList">{{'EMPLOYMENT_JOB_TYPE_LABLE'|translate }}</label>
          <div class="m-t-20">
            <select class="styled form-control" style="padding-top: 0px;" aria-required="true" name="empJobTypes" id="empTypeList" size="4"
              (change)="selectJobType($event.target.value)">
              <option [value]="currentJobType.id" [selected]="(currentJobType.id === selectedJobType)"
                *ngFor="let currentJobType of jobTypes">{{currentJobType.name | translate}}</option>
            </select>
            <input type="hidden" formControlName="jobType">
          </div>
        </div>
      </div>
    </div>

    <div class="row form-element-section">

      <div class="col-xs-12 col-sm-6 col-md-4 m-b-20" *ngIf="isIntlempID">
        <div class="group">
          <label class="control-labelss" attr.for="empIntlEmpID_{{row}}">{{'EMPLOYMENT_EMPLOYEE_ID_LABLE'|translate }}</label>
          <input type="text" class="form-control" attr.id="empIntlEmpID_{{row}}" aria-required="true" formControlName="employeeId" maxlength="50"
            (keyup)="validateEmployeeId(empSubForm.value)" />
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4 m-b-20" *ngIf="isIntlDept">
        <div class="group">
          <label class="control-labelss" attr.for="empIntlDepartment_{{row}}">{{'EMPLOYMENT_DEPARTMENT_LABLE'|translate }}</label>
          <input type="text" class="form-control" attr.id="empIntlDepartment_{{row}}" formControlName="department" maxlength="50"
            (keyup)="validateDepartment(empSubForm.value)" />
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4 m-b-20" *ngIf="options['countryOptions']['countryShortName'] != 'US' && isIntllocalFlag">
        <div class="group">
          <label class="control-labelss" attr.for="empIntlCompany_{{row}}">{{'EMPLOYMENT_COMPANY_LOCAL_LANG_NAME_LABLE'|translate }}</label>
          <input type="text" class="form-control" attr.id="empIntlCompany_{{row}}" formControlName="companyNameInLocalLang" maxlength="50" aria-required="true"
            (keyup)="validateNameInLocalLang(empSubForm.value)" />
        </div>
      </div>
    </div>

    <div class="row fixedbtn">
      <div class="col-xs-12 col-sm-4 col-md-3 btnpd">
        <button type="button" class="btn button font_extrabold senButton" attr.data-id="empIntlNext_{{row}}"
          [disabled]="!isValidButton"
          debounceDirective (debounceClick)='INTLAdditional(empSubForm.value)'>{{ 'COMMONS_NEXT' | translate }}</button>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-3 btnpd">
        <button type="button" class="btn button_wbg font_extrabold  grow button_wbgSav"
          attr.data-id="empIntlSaveExit_{{row}}" [disabled]="!isValidButton"
          debounceDirective (debounceClick)='saveExit(empSubForm.value)'>{{ 'COMMONS_SAVE_AND_EXIT' | translate }}</button>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</div>