import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlaceHolderFloat } from 'framework/form/placeholder.directive';
import { ErrorInfoComponent } from 'framework/form/errorInfo.directive';
import { LocalStorageService } from 'framework/localstorage.service';
import { ProfileService } from '../profile.service';
import { SharedService } from '../../shared/services/shared.service';
import { AppConstants } from 'app/app.constants';
import { AuthService } from 'app/login';

declare var window: any;
declare var $: any;

@Component({
    selector: 'oidc-forgot-password',
    templateUrl: './oidc-forgot-password.component.html',
    providers: [PlaceHolderFloat, ProfileService, ErrorInfoComponent]
})

export class OidcForgotPasswordComponent implements OnInit {
    body: Object;
    isInvalidEmail = false;
    email: string;
    isEmailError: boolean = false;
    isEmailErrorInfo: boolean = false;
    emailErrorMessage: string = '';
    isEmailValid: boolean = false;
    isValid: boolean;
    isResend: boolean = false;
    isInviteEiaf: boolean = false;

    constructor(private _router: Router,
        private _placeholder: PlaceHolderFloat,
        private localStorage: LocalStorageService,
        private _profileService: ProfileService,
        private _shareService: SharedService,
        private _errorInfo: ErrorInfoComponent,
        private _authService: AuthService) { }

    ngOnInit() {
        this._placeholder.placeholderFloatFunc();
        this.email = this.localStorage.getItem('applicant_email');
        this.isInviteEiaf = this._shareService.checkInviteIsEIAF();

        if (!!this.email) {
            this.enableButton(this.email);
        }
        this._errorInfo.errorInfo();
    }

    forgotPwdLogin(value: Object, valid: boolean): void {
        let emailID = value['email'];
        $('#plsWait-modal').modal('show');
        let profileId = this.localStorage.getItem('profile_id');
        let systemId = this.localStorage.getItem('system_id');

        let trackObj = {
          stage_name: 'oidc-forgot-password',
          action_value: 'clicked'
        };

        window.track_event('forgot password attempt', trackObj, true, true);
        this._authService.getUserAuthEngine(emailID).subscribe(response => {

          this._profileService.getUserApprovedFactorsForPasswordRecovery(systemId).subscribe(response => {
            $('#plsWait-modal').modal('hide');

            if (Object.keys(response).length === 0) {
                $('#forgotPassword-modal').modal('show');
                this.isResend = true;
                window.track_event('forgot password successful', trackObj, true, true);
            } else {
                this.isResend = true;
                this.localStorage.setItem("oidc_lgn_res", response);
                window.track_event('redirect enroll mfa', trackObj, true, true);
                this._router.navigate(['/enroll-mfa'],{queryParams: {fromForgotPassword: true}});
            }
          }, err => {
            $('#plsWait-modal').modal('hide');
            this.isInvalidEmail = true;
            window.track_event('forgot password failed', trackObj, true, true);
          });
        }, error => {
          $('#plsWait-modal').modal('hide');
          this.isInvalidEmail = true;
          window.track_event('invalid email', trackObj, true, true);
        });
    }

    validateEmail(value: string) {
        let inputEmail = value;
        let regexVal = AppConstants.EMAIL_REGEX;
        let emailRegex = new RegExp(regexVal);

        if (inputEmail == "") {
            this.isEmailError = true;
            this.isEmailErrorInfo = true;
            this.isEmailValid = false;
            this.emailErrorMessage = 'LOGIN_EMAIL_ERROR_MSG';
        }
        else if (!!inputEmail && !emailRegex.test(inputEmail)) {
            this.isEmailError = true;
            this.isEmailErrorInfo = true;
            this.isEmailValid = false;
            this.emailErrorMessage = 'FORGOT_EMAIL_FORMAT_ERROR_MSG';
        }
        else {
            this.isEmailError = false;
            this.isEmailErrorInfo = false;
            this.emailErrorMessage = '';
            this.isEmailValid = true;
        }
    }

    enableButton(value: string) {
        let regexVal = AppConstants.EMAIL_REGEX;
        let regex = new RegExp(regexVal);

        if (regex.test(value)) {
            this.isValid = true;
        } else {
            this.isValid = false;
        }
    }

    closeEmailError() {
        this.isEmailError = false;
        //   this.isValid = true;
    }

    closeInvalidEmailError() {
        this.isInvalidEmail = false;
    }

    modalClose() {
        $('#forgotPassword-modal').modal('hide');
    }
}
