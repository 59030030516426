import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
  static localStorageData: Object = {};

  constructor() { }

  setItem(key: any, data: any): void {
    if (LocalStorageService.localStorageData.hasOwnProperty(key)) {
      LocalStorageService.localStorageData[key] = data;
    } else {
      LocalStorageService.localStorageData[key] = data
    }
  }

  getItem(key: any) {
    return LocalStorageService.localStorageData[key];
  }

  removeItem(key: any): void {
    delete LocalStorageService.localStorageData[key];
  }

  clear() {
    LocalStorageService.localStorageData = {};
  }

  getLocalStorage() {
    return LocalStorageService.localStorageData;
  }
}
