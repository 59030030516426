<div class="pad_30" [formGroup]="empSubForm" (onGesture)="doSwipe($event,empSubForm.value)" hammer-gestures>
    <button type="button" class="btn arrowrightctr" attr.data-id="empDetailsRightArrow_{{row}}"
        [disabled]="!empSubForm.controls.jobFrom.valid" debounceDirective
        (debounceClick)="empDetails(empSubForm.value)"> <i class="icon-arrow-right" role="img"
            [attr.aria-label]="'SCREEN_FARWARD_ARROW' | translate"></i></button>
    <button type="button" class="btn arrowleftctr" attr.data-id="empDetailsLeftArrow_{{row}}" debounceDirective
        (debounceClick)="getPrevSwipe(empSubForm.value)"><i class="icon-arrow-left" role="img"
            [attr.aria-label]="'SCREEN_BACKWARD_ARROW' | translate"></i></button>

    <div class="anime-clean contetnheight posrel">
        <div class="row">
            <div class="col-md-12 col-xs-12 col-sm-12">
                <div class="header_txt_white">
                    <h2>{{'EMP_CURRENT_DETAILS'|translate }}</h2>
                </div>
            </div>        
        </div>
        <div class="row form-element-section">
            <div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
                <div class="group">
                    <label class="control-labelss"
                        attr.for="empCompanyName_{{row}}">{{'EMP_CURRENT_COMPANY_NAME'|translate }}</label>
                    <input type="text" class="form-control" attr.id="empCompanyName_{{row}}" aria-required="true"
                        formControlName="companyName" maxlength="50" />
                </div>
            </div>
        </div>

        <div class="row form-element-section">
            <div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
                <div class="group">
                    <label class="control-labelss" [attr.for]="jobFromId">{{'EMPLOYMENT_JOB_PEROID_START_DATE_LABLE'
                        |translate }}</label>
                    <input type="hidden" class="form-control" name="jobFrom" formControlName="jobFrom" />
                    <pa-date-picker [attr.id]="jobFromId" [defaultDate]="fromDefaultDate" [minDate]="fromMinDate" [setDate]="fromSetDate"
                        picker="full-date" appear="bubble" theme="material" placeholder="" (ongetDpValue)="getFromDpValue($event)"
                        (onDateError)="getStartDateError($event)">
                    </pa-date-picker>
                    <div class="error_info" *ngIf="showStartDateRangeErrorMsg">
                        <div class="custom-error error_new_ui">
                            <div class="left_div">
                                <ul>
                                    <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate}}</li>
                                    <li class="message">{{'DATE_OUTOF_RANGE' | translate}}</li>
                                </ul>
                            </div>
                            <div class="right_div">
                                <div class="close" aria-hidden="true" debounceDirective
                                    (debounceClick)="closeStartDateRangeErrorMsg()">
                                    <i class="icon-cancel"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row fixedbtn">
        <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
          <button type="button" class="btn button font_extrabold senButton grow" attr.data-id="empJobPeriodNext_{{row}}"
            [disabled]="(!empSubForm.controls.jobFrom.valid)"
            debounceDirective (debounceClick)="empDetails(empSubForm.value)">{{ 'COMMONS_NEXT' | translate }}</button>
        </div>
  
        <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
          <button type="button" class="btn button_wbg font_extrabold sendButton grow button_wbgSav"
            attr.data-id="empJobPeriodSaveExit_{{row}}" debounceDirective (debounceClick)="saveExit(empSubForm.value)"
            [disabled]="!empSubForm.controls.jobFrom.valid">{{ 'COMMONS_SAVE_AND_EXIT' | translate }}</button>
        </div>
      </div>
      <div class="clearfix"></div>


</div>