import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'download-file',
    templateUrl: './download-file.component.html'
})
export class DownloadFileComponent {
    @Input() fileName: any;
    @Output() downloadInstructionsFile: EventEmitter<any> = new EventEmitter<any>();

    constructor(private _cdr: ChangeDetectorRef) {}

    ngOninit() { }

    ngAfterViewInit() {
        this.fileName = !!this.fileName ? this.fileName : 'Instructions';
        this._cdr.detectChanges();
    }

    downloadFile() {
        this.downloadInstructionsFile.emit();
    }
}
