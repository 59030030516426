import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor (private _auth: AuthService, private _router: Router) {}

  canActivate() {
    if (this.checkLogin()) {
      return true;
    } else {
      this._router.navigate(['/login']);
    }
  }

  checkLogin(): boolean {
    return this._auth.checkLoggedIn();
  }
}
