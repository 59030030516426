import { NgModule, Component, OnInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DocumentUploadService } from './document-upload.service';
import { LocalStorageService } from '../../framework/localstorage.service';
import { LatencyChecker } from '../../framework/latency-checker.service';
import { progressBarStatus } from './../../framework/progressbar.service';
import { EventBusService } from './../../framework/eventbus.service';
import { WorkflowService } from '../../framework/workflow.service';
import { AppConfig } from '../app.config';
import { saveAs as importedSaveAs } from 'file-saver';
import { SharedService } from '../shared/services/shared.service';
import { StatusbarService } from './../status-bar/status-bar.service';
import { Store } from '@ngrx/store';
import * as ACTIONS_INTERFACE from '../actions';
import { DocumentUploadConfig } from './document-upload.config';
import { TranslateService } from 'framework/i18n';
import { BehaviorSubject } from 'rxjs';
import { WotcSharedService } from 'framework/dynamicCard/shared/shared.service';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { UtilService } from 'framework/utils/util.service';

declare var $: any;
declare var window: any;
declare var Webcam: any;
let pdfjs = require('pdfjs-dist');

@Component({
  selector: 'document-upload',
  templateUrl: './document-upload.component.html',
  providers: [WorkflowService, DocumentUploadService, progressBarStatus, StatusbarService]
})

export class DocumentUploadComponent implements OnInit {
  documentUploadForm: FormGroup;
  miDocumentData = new BehaviorSubject<any>([]);
  showSkippedDocSlide: boolean = false;
  docSkipSubmissionPreference: {};
  selectedCardComponentType: string = '';

  @Input()
  set miDocument(value) {
    this.miDocumentData.next(value);
  }
  get miDocument() {
    return this.miDocumentData.getValue();
  }

  @Input() isDynamicDocumentUpload: any;
  @Output() dynamicDocumentUploadSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() dynamicDocumentInstructionEvent: EventEmitter<any> = new EventEmitter<any>();

  static STAGE_ID: string = 'documentupload-data-v1';
  static STEP_ID: string = 'documentupload-step-v1';
  static STEP_INDEX: number = 0;
  static BUTTON_CLICKED: string = 'CLICKED_NEXT_BUTTON';
  SUB_STEP_ID: string;
  SUB_STEP_INDEX: number;
  isMobileDevice: boolean = false;
  currentStatus: string = 'VISITED';
  currentStageStatus: string = 'PENDING';
  PARENT_STAGE_STEP_CONFIG: Object;
  loaded: boolean = false;
  imageSrc: string = '';
  imgFileCount: number = 0;
  imagesList: any[] = [];
  onUploadNextClick: any;
  fileName: any;
  onCameraNextClick: any;
  cameraInput: any;
  documentObj: any[];
  mandatoryGroupData: any[];
  optionalGroupData: any[];
  modalCurrentSlide: string = '';
  modalCurrentSlideWithDirection: string = '';
  prevSlide: string = '';
  firstName: string = '';
  startCamera: any;
  stateObject: any;
  currentDocumentRemoteId: any;
  inputResetFilesList: any;
  obj: any;
  deleteObj: any;
  enableNextButton: boolean = false;
  isDocEmptyList: any;
  nextURL: string;
  nextDisplayname: string;
  resetUploadedFile: any;
  btnEnabled: boolean = false;
  camBtnEnabled: boolean = false;
  canvas: any;
  ctx: any;
  pdfDoc: any;
  pageNum: number = 1;
  pageRendering: boolean = false;
  pageNumPending: any = null;
  scale: number = 0.8;
  showCameraError: boolean = false;
  isIntlGID100P: boolean = false;
  isIntlPRNCrim: boolean = false;
  isIntlAFP100P: boolean = false;
  isIntlRegNzNzpv: boolean = false;
  iNTL100PRemoteid: any;
  iNTLCRIMRemoteid: any;
  iNTLAFPRemoteId: any;
  trackObj: Object;
  timeDiff: any;
  workflowFlavour: any;
  is100pTotalPointsReq: number = 0;
  isCrimTotalPointsReq: number = 0;
  isAFPTotalPointsReq: number = 0;
  isCategoryAFileUploaded: boolean = false;
  isCategoryBFileUploaded: boolean = false;
  isCategoryCFileUploaded: boolean = false;
  selectedObj: any;
  currentSlide;
  currentSlideWithDirection;
  documentUploadSteps: any;
  isOptionalDocsPresent: boolean = false;
  payLoad: Object;
  is100pPrimaryId: boolean = false;
  is100pPhotoId: boolean = false;
  is100pAddressId: boolean = false;
  is100pAdditionalDocument: boolean = false;
  isCrimPhotoId: boolean = false;
  is70points100PEntered: boolean = false;
  is70pointsCrimEntered: boolean = false;
  isCategoryB40PointsUploaded: boolean = false;
  unique100PDocList: {};
  uniqueCrimDocList: {};
  uniqueAFPDocList: {};
  is100PCalculationFeatureAvailable: boolean = false;
  aUCrim100PCalculationAvailable: boolean = false;
  categoryCFileUploadedCount: number = 0;
  categorySecondaryAcicPrnCrim: any = {};
  categorySecondaryAcicPrnCrimCount: number = 0;
  aFP100PCalculationAvailable: boolean = false;
  isAFP100pPrimaryId: boolean = false;
  isAFP100pSecondaryId: boolean = false;
  isAFP100pPhotoId: boolean = false;
  isNzNzpvPrimaryId: boolean = false;
  isNzNzpvSecondaryId: boolean = false;
  isNzNzpvPhotoId: boolean = false;
  isNzNzpvConsent: boolean = false;
  userBandWidth: number;
  minThreshold: number;
  isInviteEiaf: any;
  instructions: any;
  isCustomInstruction: boolean = false;
  eiafileName: string = '';
  uploadedDynamicDocumentName: string;
  instructions_location_code: string = 'US';
  infoIcon = ' <i class="icon icon-info" title="Instruction"></i> ';
  downloadIcon = ' <i class="icon icon-download" title="Download"></i> ';
  uploadIcon = ' <i class="glyphicon glyphicon-paperclip" title="Upload"></i> ';
  isDocUploadCriteriaFulfilled: boolean = false;
  skippedDocumentsGroupData: any;
  isFormInit: boolean;
  stateConfig: Object;
  isSSAFormAvailable: boolean = false;
  isOptionalEmploymentDocuments: boolean = false;
  isFromEnhancedDashboard: boolean = false;
  skippedDocumentsSelectedCategory = '';
  isPendingInfoDueDateAvailable: boolean = false;

  constructor(private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _eventBus: EventBusService,
    private _store: Store<ACTIONS_INTERFACE.AppState>,
    private localStorage: LocalStorageService,
    private latencyChecker: LatencyChecker,
    private _workflow: WorkflowService,
    private _documentUploadService: DocumentUploadService,
    private shareService: SharedService,
    private _progressbarStatus: progressBarStatus,
    private _statusBar: StatusbarService,
    private _ts: TranslateService,
    private _cdr: ChangeDetectorRef,
    private _util: UtilService,
    private _fb: FormBuilder) {
    this.minThreshold = AppConfig.getMinThreshold();
  }

  // OnInit function
  ngOnInit() {
    pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.7.570/pdf.worker.min.js';
    this.docSkipSubmissionPreference = this._documentUploadService.documentSkipSubmissionPreferences();
    this.isFormInit = false;
    this.firstName = this.localStorage.getItem('first_name');
    if (this.isDynamicDocumentUpload) {
      // this.firstName = this.localStorage.getItem('first_name');
      this.currentSlide = 'dynamicDocumentUpload';
    } else {
      this.PARENT_STAGE_STEP_CONFIG = {
        STAGE_ID: DocumentUploadComponent.STAGE_ID,
        STEP_ID: DocumentUploadComponent.STEP_ID,
        STEP_INDEX: DocumentUploadComponent.STEP_INDEX
      };

      // Check Invite is Eiaf
      this.isInviteEiaf = this.shareService.checkInviteIsEIAF();

      // Get next Url from service
      this.nextURL = this._documentUploadService.getNextURL();
      this.nextDisplayname = this._documentUploadService.getNextDisplayName();
      this.documentUploadSteps = DocumentUploadConfig.getDocUploadSteps();
      this.is100PCalculationFeatureAvailable = AppConfig.isFeatureAvailable('documentupload-data-v1', '100point-calculation');
      this.aUCrim100PCalculationAvailable = AppConfig.isFeatureAvailable('documentupload-data-v1', 'AUCrim100point-calculation');
      this.aFP100PCalculationAvailable = AppConfig.isFeatureAvailable('documentupload-data-v1', 'AFP100point-calculation');
 
      this._activatedRoute.queryParams.subscribe(params => {
        if (params['from'] === 'dashboard-skip-docs') {
          this.isFromEnhancedDashboard = true;
          this.skippedDocumentsSelectedCategory = params['type'];
        }
      });
      
      // Get state and file details from service
      if (this.isFromEnhancedDashboard) {
        let body = this.localStorage.getItem('dashboard_skipped_docs');
          
        this._documentUploadService.postSkippedDocs(this.skippedDocumentsSelectedCategory, body).subscribe(response => {
          console.log('enhanced dashboard - skipped docs POST ', response);
          let documentList = response['statewide-criminal-data-lists'];
          
          this.selectedCardComponentType = !!documentList[0] && !!documentList[0]['module_name'] ? documentList[0]['module_name'] : '';
          this.validateUploadSuccess(response);
        })
      } else {
        this.getData();
      }
      this.setState();
    }
    this.checkMobileDevice();
    this.workflowFlavour = WorkflowService.WorkFlowFlavour;
    this._workflow.storeDocBaseUrl();

    this._eventBus.dynamicDocumentActionClickedAnnounced$.subscribe(item => {
      // debugger
      if (!!item && !!item['action'] && item['action'] === 'error') {
        this.currentSlide = 'dynamicDocumentUpload';
      }
    });

    if (this.localStorage.getItem('pending_info_due_date') != undefined) {
      this.isPendingInfoDueDateAvailable = true;
    } 
  }

  ngOnChanges() {
    // debugger
    if (!!this.miDocument && this.miDocument['new']) {
      //console.log(this.isDynamicDocumentUpload);
      this.currentSlide = 'dynamicDocumentUpload';
    }
  }

  // After View Init function
  ngAfterViewInit() {

    setTimeout(() => {
      this.modalCurrentSlide = 'docUpload_options';
      this.modalCurrentSlideWithDirection = 'docUpload_options_right';

      this._cdr.detectChanges();
    }, 500);

    this.canvas = document.getElementById('viewPdfCanvas');
    this.ctx = this.canvas.getContext('2d');

    // We need to check the bandwidth only when the users lands in this page
    // for the first time and not whenever they land. TODO : Need to confirm
    if (AppConfig.isFeatureAvailable('app-features', 'network-latency')) {
      if (!window.sessionStorage.getItem('isUpdBandWidthChecked'))
        this.checkLatency();
    }
  }

  /* -------------------------- Main Page Methods - STARTS --------------------------------- */

  prepareCustomInstruction() {
    this.isCustomInstruction = true;
    this.setCustomInstruction();
  }

  setCustomInstruction() {
    let companyName = this.localStorage.getItem('company_name');
    let sectionName = this.isInviteEiaf ? this._ts.instant('CUSTOM_INSTRUCTION_EIAF') : this._ts.instant('CUSTOM_INSTRUCTION_DOCUMENT_UPLOAD');

    if (!!this.instructions && !!this.isSSAFormAvailable && this.isSSAFormAvailable) {
      this._eventBus.customInstructionAnnounced({
        'section': sectionName,
        'instructions': this._ts.instant(this.instructions, companyName),
        'cbsvInstructions': this._ts.instant('CBSV_PACKAGE')
      });
    }
    else if (!!this.instructions) {
      this._eventBus.customInstructionAnnounced({
        'section': sectionName,
        'instructions': this._ts.instant(this.instructions, companyName),
        'cbsvInstructions': ''
      });
    }
    else if(!!this.isSSAFormAvailable && this.isSSAFormAvailable){
      this._eventBus.customInstructionAnnounced({
        'section': sectionName,
        'instructions': '',
        'cbsvInstructions': this._ts.instant('CBSV_PACKAGE')
      });
    }
  }
  /*
  * To identify the network bandwidth, and
  * notify the users if the available bandwidth is lesser than minimum threshold
  */
  checkLatency() {
    this.latencyChecker.getDownloadBandWidth().subscribe(dldBandwidth => {
      this.userBandWidth = dldBandwidth['speedMbps'];

      if (dldBandwidth['speedMbps'] < this.minThreshold) {
        $('#threshHoldAlertModal').modal('show');
        setTimeout(function () { $('#threshHoldAlertModal').modal('hide'); }, AppConfig.APP_CONSTANTS.threshHoldMsgTimeOut);
      };
    });

    window.sessionStorage.setItem('isUpdBandWidthChecked', 'true');
  }

  closeTModal() {
    $('#threshHoldAlertModal').modal('hide');
  }

  // On upload icon click, trigger open the upload modal
  onUploadDocumentClick() {

    this.trackObj = {
      stage_name: 'document upload',
      action_value: 'upload icon clicked'
    };
    window.track_event('upload documents to db', this.trackObj, true, true);

    this.modalCurrentSlide = 'docUpload_options';
    this.modalCurrentSlideWithDirection = 'docUpload_options_right';
    $('#uploadDocuments_Modal').modal('show');
  }

  // Upload action
  uploadAction(obj) {

    this.obj = obj;

    if (this.isDynamicDocumentUpload) {
      // Open the popUp and the document upload flow
      this.onUploadDocumentClick();
      this.trackObj = {
        stage_name: 'MI',
        action_value: 'upload icon clicked'
      };
      window.track_event('upload documents to db', this.trackObj, true, true);
      return;
    }
    let grpObj;
    grpObj = this.getGroupData();

    if (grpObj[obj['grpIndex']]['statewide-criminal-data-list'][obj['categoryIndex']].hasOwnProperty('status')) {
      if (grpObj[obj['grpIndex']]['statewide-criminal-data-list'][obj['categoryIndex']].status === 'FILLED_FORM') {
        this.onUploadDocumentClick();
      } else {
        $('#upload_Reconfirm_modal').modal('show');
      }
    } else {
      // Open the popUp and the document upload flow
      this.onUploadDocumentClick();
    }
  }

  // On download click, download the file  .. blank form
  downloadAction(obj) {
    let filename;
    let grpObj;
    let blankFormLocationCode = '';
    if (this.isDynamicDocumentUpload) {
      filename = this.miDocument['document']['fileName'];
      blankFormLocationCode = this.miDocument['document']['blankFormLocationCd'];

    } else {
      grpObj = this.getGroupData();

      filename = grpObj[obj['grpIndex']]['statewide-criminal-data-list'][obj['categoryIndex']]['file_name'];
      blankFormLocationCode = (!!grpObj[obj['grpIndex']]['statewide-criminal-data-list'][obj['categoryIndex']]['blankform_location_cd']
        && grpObj[obj['grpIndex']]['statewide-criminal-data-list'][obj['categoryIndex']]['blankform_location_cd'] !== 'US')
        ? grpObj[obj['grpIndex']]['statewide-criminal-data-list'][obj['categoryIndex']]['blankform_location_cd'] : '';
    }

    if (this.isDynamicDocumentUpload) {
      // need to switch to downloadFileByIdAndType later
      this.downloadProcess(obj.remoteId, filename, blankFormLocationCode);
    } else {
      this.downloadFileByIdAndType(obj.id, filename, 'blank_form')
    }
  }

  // Download file process
  downloadProcess(id, filename, locationCode) {

    $('#wait_for_pdf_modal').modal('show');

    if (filename.indexOf('.pdf') < 0)
      filename = filename + '.pdf';

    this._documentUploadService.downloadFile(id, locationCode).subscribe(blob => {
      $('#wait_for_pdf_modal').modal('hide');
      this.trackObj = {
        stage_name: 'document upload',
        action_value: 'download icon clicked'
      };
      window.track_event('download the selected document', this.trackObj, true, false);

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        importedSaveAs(blob, filename);
      }
    }, error => {
      $('#wait_for_pdf_modal').modal('hide');
    });
  }

  // Download instruction guide - on click of (i)
  downloadInstructionGuide(obj) {
    let filename;
    let remoteid;

    if (obj === '100P') {
      filename = this._ts.translate('INSTRUCTIONGUIDE_100P');
      remoteid = this.iNTL100PRemoteid;
    } else if (obj === 'AFP') {
      filename = this._ts.translate('INSTRUCTIONGUIDE_AFP');
      remoteid = this.iNTLAFPRemoteId;
    } else {
      filename = this._ts.translate('INSTRUCTIONGUIDE_AUCRIM');
      remoteid = this.iNTLCRIMRemoteid;
    }

    this.downloadProcess(remoteid, filename, this.instructions_location_code);
  }

  // Delete action
  deleteAction(obj) {
    this.trackObj = {
      stage_name: 'document upload',
      action_value: 'delete icon clicked'
    };
    window.track_event('delete the selected document', this.trackObj, true, false);

    // Open delete confirmation modal
    $('#document_delete_modal').modal('show');

    // Maintain Delete object globally
    this.deleteObj = obj;
  }

  downloadFileByIdAndType(id: string, filename: string, type: string) {
    this.trackObj = {
      stage_name: 'document upload',
      action_value: 'download icon clicked-' + type
    };
    window.track_event('download the selected document', this.trackObj, true, false);




    $('#wait_for_pdf_modal').modal('show');

    if (filename.indexOf('.pdf') < 0)
      filename = filename + '.pdf';

    this._documentUploadService.downloadFileNew(id, type).subscribe(blob => {
      $('#wait_for_pdf_modal').modal('hide');
      this.trackObj = {
        stage_name: 'document upload',
        action_value: 'download icon clicked'
      };
      window.track_event('download the selected document', this.trackObj, true, false);

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        importedSaveAs(blob, filename);
      }
    }, error => {
      $('#wait_for_pdf_modal').modal('hide');
    });




  }

  // View Action
  viewAction(profileDocId) {
    this.trackObj = {
      stage_name: 'document upload',
      action_value: 'view icon clicked'
    };
    window.track_event('view the selected document', this.trackObj, true, false);

    // Open modal to show Documents in pdf
    $('#wait_for_pdf_modal').modal('show');
    this.pageNum = 1;

    let that = this;
    const workflowid = this.localStorage.getItem('workflow_instance_id');
    let url = AppConfig.API_ENDPOINT() + '/api/v1/workflow/' + workflowid + '/download/document/' + profileDocId;

    const loadingTask = pdfjs.getDocument({
      url: url,
      httpHeaders: { Authorization: 'Bearer ' + that.localStorage.getItem('access_token') }
    });

    loadingTask.promise.then(function (doc) {
      that.pdfDoc = doc;
      document.getElementById('page_count').textContent = that.pdfDoc.numPages;

      // Initial/first page rendering
      that.renderPage(that.pageNum);
      $('#wait_for_pdf_modal').modal('hide');
      $('#view_pdf_modal').modal('show');
    }, function (reason) {
      // PDF loading error
      console.error(reason);
      $('#wait_for_pdf_modal').modal('hide');
      $('#noPdfModal').modal('show');
    });
  }

  // Render method to show pages when viewing the uploaded docs
  renderPage(num) {
    this.pageRendering = true;
    let that = this;

    // Using promise to fetch the page
    this.pdfDoc.getPage(num).then(function (page) {
      var viewport = page.getViewport({scale: that.scale});
      that.canvas.height = viewport.height;
      that.canvas.width = viewport.width;

      // Render PDF page into canvas context
      var renderContext = {
        canvasContext: that.ctx,
        viewport: viewport
      };
      var renderTask = page.render(renderContext);

      // Wait for rendering to finish
      renderTask.promise.then(function () {
        that.pageRendering = false;
        if (that.pageNumPending !== null) {
          // New page rendering is pending
          that.renderPage(that.pageNumPending);
          that.pageNumPending = null;
        }
      });
    });

    // Update page counters
    document.getElementById('page_num').textContent = this.pageNum.toString();
  }

  // Queue render page method to view the nex pages of document uploaded
  queueRenderPage(num) {
    if (this.pageRendering) {
      this.pageNumPending = num;
    } else {
      this.renderPage(num);
    }
  }

  // On click previous button load the previous page
  onPrevPage() {
    if (this.pageNum <= 1) {
      return;
    }
    this.pageNum--;
    this.queueRenderPage(this.pageNum);
  }

  // On click next button load the next page
  onNextPage() {
    if (this.pageNum >= this.pdfDoc.numPages) {
      return;
    }
    this.pageNum++;
    this.queueRenderPage(this.pageNum);
  }

  // On Instructions action
  instructionAction(obj) {

    let filename;
    let grpObj;
    let insLocationCode;
    if (this.isDynamicDocumentUpload) {
      filename = this.miDocument['document']['instructionFileName'];
      insLocationCode = this.miDocument['document']['instructionLocationCd'];
    } else {
      grpObj = this.getGroupData();

      filename = grpObj[obj['grpIndex']]['statewide-criminal-data-list'][obj['categoryIndex']]['instructions_filename'];
      insLocationCode = (!!grpObj[obj['grpIndex']]['statewide-criminal-data-list'][obj['categoryIndex']]['instructions_location_cd']
        && grpObj[obj['grpIndex']]['statewide-criminal-data-list'][obj['categoryIndex']]['instructions_location_cd'] !== 'US')
        ? grpObj[obj['grpIndex']]['statewide-criminal-data-list'][obj['categoryIndex']]['instructions_location_cd'] : '';

    }
    if (this.isDynamicDocumentUpload) {
      // need to switch to downloadFileByIdAndType later
      this.downloadProcess(obj.instructionId, filename, insLocationCode);
    } else {
      this.downloadFileByIdAndType(obj.id, filename, 'instruction_form')
    }
  }

  // Refresh the state
  refreshState(id, grpIndex, catIndex, actionType, ack, profileDocID) {
    this.stateProcessing(id, grpIndex, catIndex, actionType, ack, profileDocID);
  }

  // On next click, update the state and go to next STAGE
  onNextClick(obj) {
    if (this.isFromEnhancedDashboard) {
      this.onNextFromEnhancedDashboard();
    } else {
      // Old/existing onNextClick moved to this new method. Doc upload - next click call
      this.onNextClickUpdateState(obj);
    }
  }
   
  onNextClickUpdateState(obj) {
    if (obj['isDynamicDocumentUpload']) {
      this.currentSlide = '';
      this.removeMiAttributes(obj);
      WotcSharedService.SetActionVal('');
      this.dynamicDocumentUploadSubmit.emit(obj);
      return;
    }

    this.prepareSkippedDocumentsList();

    let status;
    let workflowId = this.localStorage.getItem('workflow_instance_id');
    let that = this;
    let currentDocSlide = this.currentSlide.split('_')[0];

    if (currentDocSlide === 'mandatoryDocs') {
      if (this.is100PCalculationFeatureAvailable || this.aUCrim100PCalculationAvailable || this.aFP100PCalculationAvailable) {
        status = this.checkFileUploadMinRequirement();
      } else {
        status = true;
      }
    } else {
      status = true;
    }

    if (status) {
      let body = {};

      if (currentDocSlide === 'mandatoryDocs' && this.isOptionalDocsPresent || currentDocSlide === 'skippedDocs') {
        this.currentStageStatus = 'PENDING';
      } else {
        this._progressbarStatus.setDocumentUpload('true');
        this.currentStageStatus = 'COMPLETED';
      }

      if (currentDocSlide === 'skippedDocs') {
        body['future_date_to_submit_doc'] = obj['futureDateToSubmitDoc'];
        body['reason_to_skip_doc_submission'] = obj['reasonToSkipDocSubmission'];
      }

      body['state'] = {};
      body['state']['current_stage'] = DocumentUploadComponent.STAGE_ID;
      body['state']['current_step'] = DocumentUploadComponent.STEP_ID;
      body['state']['current_step_index'] = DocumentUploadComponent.STEP_INDEX;
      body['state']['current_sub_step'] = obj['COMPONENT_STATE']['subStep'];
      body['state']['current_sub_step_index'] = obj['COMPONENT_STATE']['subStepIndex'];
      body['state']['current_status'] = obj['COMPONENT_STATE']['status'];
      body['state']['stage_status'] = this.currentStageStatus;

      // Set file-upload is false if we send only status insted of file details
      body['file-upload'] = false;

      if (workflowId != null) {
        this._documentUploadService.save(body).subscribe(response => {
          if (response) {
            that.afterSaveProcess(obj);
          }
        });
      }
    } else {
      // $('#docUpload_info_modal').modal('show'); // No longer needed to open as Global custom instuctions added
    }
  }

  // After save process
  afterSaveProcess(obj) {
    let componentName: string = this.currentSlide.split('_')[0];
    this.localStorage.setItem('documentUploadStageStatus', 'PERSIST');
    switch (componentName) {
      case 'mandatoryDocs':
        this.afterMandatoryDocsSave(obj);
        break;
      case 'skippedDocs':
        this.afterSkippedDocsSave(obj);
        break;
      case 'optionalDocs':
        this.afterOptionalDocsSave(obj);
        break;
      default:
        return;
    }
  }

  afterSkippedDocsSave(obj: any) {
    this.trackObj = {
      stage_name: 'document upload',
      action_value: 'next clicked '
    };
    window.track_event('skipped document details save to db', this.trackObj, true, true);

    let direction = 'right';

    if (this.isOptionalDocsPresent) {
      this.currentSlide = this.documentUploadSteps[2] + '_';
      this.currentSlideWithDirection = this.currentSlide + direction;
      if (this.isOptionalEmploymentDocuments)
        this.onOptionalEmploymentModal();
    } else {
      this._progressbarStatus.createDocumentUploadProgressbar(this.nextDisplayname);
      this.statusBarDocumentUploadMenustatusUpdate();
      this._next(this.nextURL);
      this.navigationtopMobile();
    }
  }

  // After mandatory documents save
  afterMandatoryDocsSave(obj) {
    this.trackObj = {
      stage_name: 'document upload',
      action_value: 'next clicked '
    };
    window.track_event('mandatory document save to db', this.trackObj, true, true);

    let direction = 'right';

    if (this.skippedDocumentsGroupData['skipped-documents-list'].length > 0) {
      this.currentSlide = this.documentUploadSteps[1] + '_';
      this.currentSlideWithDirection = this.currentSlide + direction;
    } else if (this.isOptionalDocsPresent) {
      this.currentSlide = this.documentUploadSteps[2] + '_';
      this.currentSlideWithDirection = this.currentSlide + direction;
      if (this.isOptionalEmploymentDocuments)
        this.onOptionalEmploymentModal();
    } else {
      this._progressbarStatus.createDocumentUploadProgressbar(this.nextDisplayname);
      this.statusBarDocumentUploadMenustatusUpdate();
      this._next(this.nextURL);
      this.navigationtopMobile();
    }
  }

  // After optional docs save
  afterOptionalDocsSave(obj) {
    this.trackObj = {
      stage_name: 'document upload',
      action_value: 'next clicked'
    };
    window.track_event('optional document save to db', this.trackObj, true, true);

    this._progressbarStatus.createDocumentUploadProgressbar(this.nextDisplayname);
    this.statusBarDocumentUploadMenustatusUpdate();
    this._next(this.nextURL);
    this.navigationtopMobile();
  }

  // Call order creation if Document upload is last component
  callOrderCreation() {
    this.localStorage.setItem('documentUploadStageStatus', 'PERSIST');
    let that = this;

    this.shareService.orderCreation().subscribe(response => {
      that.localStorage.setItem('isOrderCreated', 'true');
      // Store WPS number
      that.localStorage.setItem('wps_number', response['WPSNumber']);
      this.localStorage.setItem('is_report_on', response['is_report_on']);
      this.trackObj = {
        stage_name: 'document upload',
        time_spent: this.timeDiff,
        action_value: 'next clicked'
      };
      window.track_event('call order creation', this.trackObj, true, true);

      $('#docWait-modal').modal('hide');
      // Need to write a better code -- WOTC
      if (this._workflow.getWOtcStatus()) {
        this._progressbarStatus.createDocumentUploadProgressbar(this.nextDisplayname);
        this.statusBarDocumentUploadMenustatusUpdate();
        this._next('/home/wotc');
      } else {
        this.statusBarDocumentUploadMenustatusUpdate();
        // PPA-13294 - Arun - Trigger post message only if workflow is done.
        window.safeNotifyHost('workflow_completed', response['WPSNumber']);
        this._next('/dashboard');
      }
    },
      error => {
        // To avoid multiple click
        this.enableNextButton = true;

        // Custom error message for order creation fail
        if (error === 412) {
          that.localStorage.setItem('isOrderCreated', 'false');

          this.onOrderCreationError();
        } else {
          $('#docWait-modal').modal('hide');
          // Need to write a better code -- WOTC
          if (this._workflow.getWOtcStatus()) {
            this._progressbarStatus.createDocumentUploadProgressbar(this.nextDisplayname);
            this.statusBarDocumentUploadMenustatusUpdate();
            this._next('/home/wotc');
          } else {
            this.statusBarDocumentUploadMenustatusUpdate();
            // PPA-13294 - Arun - Workflow completed is only when the process completes.
            // Post message
            window.safeNotifyHost('workflow_completed', null);
            this._next('/dashboard');
          }
        }
      });
  }

  // On order creation error
  onOrderCreationError() {
    if (this._workflow.getWOtcStatus()) {
      this._progressbarStatus.createDocumentUploadProgressbar(this.nextDisplayname);
      this.statusBarDocumentUploadMenustatusUpdate();
      $('#docWait-modal').modal('hide');
      this._next('/home/wotc');
    } else {
      // Update/Set state to {} to reset state of document upload stage
      let documentUploadState = {};
      documentUploadState['state'] = {};
      documentUploadState['file-upload'] = false;

      this._documentUploadService.updateDocumentState(documentUploadState).subscribe(res => {
        // console.log(res);
        // Update state to reflect Review and edit i.e current stage is Review & Edit, User has to go through Consent steps again if Order failed
        let data = {};
        data['state'] = {};
        data['state']['current_stage'] = 'reviewedit-data-v1';
        data['state']['current_step'] = 'reviewedit-step-v1';
        data['state']['current_step_index'] = 0;
        data['state']['current_status'] = 'PENDING';
        data['state']['stage_status'] = 'PENDING';

        this.shareService.redirectClipboard(data).subscribe(response => {
          $('#docWait-modal').modal('hide');
          this._router.navigate(['/home/review/show-all']);  // Commented TEST WOTC - NEED TO UNCOMMENT
          this.trackObj = {
            stage_name: 'document upload',
            action_value: 'order creation error'
          };
          window.track_event('back to review edit', this.trackObj, true, true);
        }, er => {
          console.log(er);
        });
      },
        err => {
          console.log(err);
          $('#docWait-modal').modal('hide');
        });
    }
  }

  // On save exit click, save the state and go to dashboard
  onSaveExitClick(obj) {
    let body = {};
    this.currentStageStatus = 'PENDING';

    // PPA-8745 - Auto-generated via regex - Arun, Supreet, Save n Exit Patch
    var re = this._eventBus.logoutAnnounced({ dummy: 'workflow_exit' });
    if (re == undefined) return;
  }

  onSkipNowClick() {
    let obj = {};
    obj['action'] = 'SKIP_FOR_NOW';
    WotcSharedService.SetActionVal('SKIP_FOR_NOW');
    this.dynamicDocumentUploadSubmit.emit(obj);
  }

  // Validation function
  validationEngine() {
    let count = 0, loopBreak, category = '', additionalDocCount = 0, afpSecondaryIdCount = 0, requiredDocsLength = 0, statusCheck, groupCode = '', afpPrimaryIdCount = 0, isAFPBothGroupsUploaded = false, aFPPrimaryId40PointsCheck = false, aFPPrimary70PointsCheck = false, isSkippable = false;

    for (let i = 0; i < this.mandatoryGroupData.length; i++) {
      loopBreak = false;

      for (let j = 0; j < this.mandatoryGroupData[i]['statewide-criminal-data-list'].length; j++) {
        category = this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['category'] !== undefined ? this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['category'].toUpperCase() : '';
        statusCheck = this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['status'] !== undefined && this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['status'] !== '';
        groupCode = this.mandatoryGroupData[i]['group-code'] !== undefined ? this.mandatoryGroupData[i]['group-code'].toUpperCase() : '';
        aFPPrimaryId40PointsCheck = (aFPPrimaryId40PointsCheck === false) ? ((groupCode === 'AF22' || groupCode === 'NOSRHCD') && category !== '' && category === DocumentUploadConfig.intlAFP100PCategory[0] && statusCheck && this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['points_value'] !== undefined && this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['points_value'] === '40' && this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['status'].toUpperCase() === 'SUCCESS') : aFPPrimaryId40PointsCheck;
        aFPPrimary70PointsCheck = (aFPPrimary70PointsCheck === false) ? ((groupCode === 'AF22' || groupCode === 'NOSRHCD') && category !== '' && category === DocumentUploadConfig.intlAFP100PCategory[0] && statusCheck && this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['points_value'] !== undefined && this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['points_value'] === '70' && this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['status'].toUpperCase() === 'SUCCESS') : aFPPrimary70PointsCheck;
        if(this.docSkipSubmissionPreference['no_of_days_future_submission'] == 0){
          isSkippable = false;
        } else {
          isSkippable = (this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['skippable'] !== undefined && this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['skippable'] !== '') ? this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['skippable'] : false;
        }

        if (isSkippable && !loopBreak) {
          count = count + 1;
          loopBreak = true;
        }

        if (statusCheck && !loopBreak) {
          if (this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['status'].toUpperCase() === 'SUCCESS') {
            if ((groupCode !== '' && groupCode === '100P' && category === DocumentUploadConfig.intl100PCategory[3]) || (groupCode !== '' && (groupCode === 'AF22' || groupCode === 'NOSRHCD') && category === DocumentUploadConfig.intlAFP100PCategory[1])) {
              loopBreak = true;
            } else {
              count = count + 1;
              loopBreak = true;
            }
          }
        }

        if (groupCode === '100P' && category !== '' && category === DocumentUploadConfig.intl100PCategory[3] && j === 0) {
          additionalDocCount = additionalDocCount + 1;
        }

        if ((groupCode === 'AF22' || groupCode === 'NOSRHCD') && category !== '' && category === DocumentUploadConfig.intlAFP100PCategory[1] && j === 0) {
          afpSecondaryIdCount = afpSecondaryIdCount + 1;
        }

        if ((groupCode === 'AF22' || groupCode === 'NOSRHCD') && category !== '' && category === DocumentUploadConfig.intlAFP100PCategory[0] && j === 0) {
          afpPrimaryIdCount = afpPrimaryIdCount + 1;
        }

        // Check if PrimaryId with 40 points and 70 points both are uploaded, then count is considered as 1
        if (aFPPrimaryId40PointsCheck && aFPPrimary70PointsCheck) {
          isAFPBothGroupsUploaded = true;
        }
      }

      // Add error message
      if ((loopBreak === false) && (this.mandatoryGroupData[i]['statewide-criminal-data-list'].length > 1) && !(category === DocumentUploadConfig.intl100PCategory[3] || category === DocumentUploadConfig.intlAFP100PCategory[1])) {
        this.mandatoryGroupData[i]['isShowErrorMsg'] = true;
      } else {
        this.mandatoryGroupData[i]['isShowErrorMsg'] = false;
      }
    }

    // Calculate required docs length, i.e remove optional docs from count; mandatoryGroupData length - optional docs length
    if (this.isIntlGID100P) {
      requiredDocsLength = this.mandatoryGroupData.length - additionalDocCount;
    } else {
      requiredDocsLength = this.mandatoryGroupData.length;
    }

    if (this.isIntlAFP100P) {
      if (this.isIntlGID100P) {
        requiredDocsLength = requiredDocsLength - (afpSecondaryIdCount + (afpPrimaryIdCount / 2));
      } else {
        requiredDocsLength = this.mandatoryGroupData.length - (afpSecondaryIdCount + (afpPrimaryIdCount / 2));
      }
      // Code added because PrimaryId has 2 groups for AFP i.e 70 points and 40 points group. We need only 1 count of PrimaryId, hence check if PrimaryId for 70 points and 40 points is uploaded then count divided by 2
      if (isAFPBothGroupsUploaded)
        count = count - (afpPrimaryIdCount / 2);
    }

    this.nextButtonStateObject(count, requiredDocsLength);
  }

  // Method to enable/disable next button based on conditions
  nextButtonStateObject(count, reqDocCount) {
    // Enable Next Button
    if (this.isIntlPRNCrim || this.isIntlGID100P || this.isIntlAFP100P || this.isIntlRegNzNzpv) {
      this.nextButtonEnableDisableCondition(count, reqDocCount);
    } else {
      if (count === this.mandatoryGroupData.length) {
        this.enableNextButton = true;
        this.isDocUploadCriteriaFulfilled = true;
        
        if (!this.isFromEnhancedDashboard)
          $('#upload_complete_modal').modal('show');
      } else {
        this.enableNextButton = false;
      }
    }
  }

  // Next button enable/disable condition method
  nextButtonEnableDisableCondition(count, reqDocCount) {
    let buttonStatusList = [];
    let auCrimWithPointsCheck = this.isCategoryAFileUploaded && this.isCategoryBFileUploaded && this.isCategoryCFileUploaded && (this.categoryCFileUploadedCount > 1 || (this.isIntlPRNCrim && this.categorySecondaryAcicPrnCrimCount > 1)) && this.isCrimPhotoId && this.isCrimTotalPointsReq >= 100 && count >= reqDocCount;
    let auCrimNoPointsCheck = this.isCategoryAFileUploaded && this.isCategoryBFileUploaded && this.isCategoryCFileUploaded && (this.categoryCFileUploadedCount > 1 || (this.isIntlPRNCrim && this.categorySecondaryAcicPrnCrimCount > 1)) && this.isCrimPhotoId && count >= reqDocCount;
    let intlGIDWithPointsCheck = this.is100pTotalPointsReq >= 100 && this.is100pAddressId && this.is100pPhotoId && this.is100pPrimaryId && count >= reqDocCount;

    if (this.isIntlPRNCrim) {
      if (this.aUCrim100PCalculationAvailable) {
        if (auCrimWithPointsCheck) {
          buttonStatusList.push(true);
        } else {
          buttonStatusList.push(false);
        }
      } else {
        if (auCrimNoPointsCheck) {
          buttonStatusList.push(true);
        } else {
          buttonStatusList.push(false);
        }
      }
    }

    if (this.isIntlGID100P && this.is100PCalculationFeatureAvailable) {
      if (intlGIDWithPointsCheck) {
        buttonStatusList.push(true);
      } else {
        buttonStatusList.push(false);
      }
    }

    if (this.isIntlAFP100P) {
      if (this.aFP100PCalculationAvailable) {
        if (this.isAFP100pPrimaryId && this.isAFPTotalPointsReq >= 100 && count >= reqDocCount) {
          buttonStatusList.push(true);
        } else {
          buttonStatusList.push(false);
        }
      } else {
        if (this.isAFP100pPrimaryId && count >= reqDocCount) {
          buttonStatusList.push(true);
        } else {
          buttonStatusList.push(false);
        }
      }
    }

    if (this.isIntlRegNzNzpv) {
      let nzNzpvNoPointsCheck = this.isNzNzpvPrimaryId && this.isNzNzpvSecondaryId && this.isNzNzpvPhotoId && this.isNzNzpvConsent;
      if (nzNzpvNoPointsCheck && (count === this.mandatoryGroupData.length)) {
        buttonStatusList.push(true);
      } else {
        buttonStatusList.push(false);
      }
    }

    this.enableNextButton = (buttonStatusList.indexOf(false) > -1) ? false : true;
    if (this.enableNextButton) {
      this.isDocUploadCriteriaFulfilled = true;
      $('#upload_complete_modal').modal('show');
    }
  }

  /* -------------------------- Main Page Methods - ENDS --------------------------------- */

  /* -------------------------- Modal Methods - STARTS --------------------------------- */

  // FIRST SLIDE EVENTS
  // On Upload click in options i.e first slide, go to upload slide
  optionsUploadClick() {

    this.modalCurrentSlide = 'docUpload_upload';
  }

  // On Take Pic click in options i.e first slide, go to camera slide
  optionsTakePicClick() {

    this.checkMobileDevice();
    // if (!this.isMobileDevice) {
    //   this.showCameraError = false;
    //   this.startCamera = Math.random();
    // } else {
      this.modalCurrentSlide = 'docUpload_camera';
    //}
  }

  // On upload Next Click
  uploadNextClick() {
    this.onUploadNextClick = this._util.getCryptoRandom();
  }

  // On camera next click
  cameraNextClick() {
    this.onCameraNextClick = this._util.getCryptoRandom();
  }

  // On getting files from upload/camera, upload to doc base; if succes then put on the file summary service
  onMultipleFilesOutput(output) {

    let multiFiles = output.files;
    let fileCount: number = multiFiles.length;
    let docbaseID;
    let counter: number = 0;

    if (fileCount === 0) {
      return;
    }

    this.prevSlide = this.modalCurrentSlide;
    this.modalCurrentSlide = 'docUpload_wait';

    this.onUploadSuccess(multiFiles, this.obj.id, this.obj.docTypeId);

  }

  // After upload success of files to doc base, this method is called
  onUploadSuccess(multiFiles, id, docTypeId?) {

    let currentDocSlide = this.isDynamicDocumentUpload ? null : this.currentSlide.split('_')[0];
    // let checkPoints = this.mandatoryGroupData[this.obj.grpIndex]['statewide-criminal-data-list'][this.obj.categoryIndex]['points_value'];
    let pointValues = '';

    // Get story points value from global object
    if (!this.isDocEmptyList && currentDocSlide === 'mandatoryDocs') {
      pointValues = this.mandatoryGroupData[this.obj.grpIndex]['statewide-criminal-data-list'][this.obj.categoryIndex]['points_value'] !== undefined ? this.mandatoryGroupData[this.obj.grpIndex]['statewide-criminal-data-list'][this.obj.categoryIndex]['points_value'] : '';
    }

    // Send summary of files with doc base ID
    this._documentUploadService.uploadFileSummary(multiFiles, id, pointValues, docTypeId, this.selectedCardComponentType, this.isFromEnhancedDashboard).subscribe(response => {
      if (response) {
        let res = response;
        let profileDocId = res['profile-document-id'];
        this.eiafileName = res['document-data-list'][0]['file_name'];

        if (this.isDynamicDocumentUpload) {
          this.uploadedDynamicDocumentName = res['document-data-list'][0]['file_name'];
          this.miDocument['isUpload'] = true;
          this._eventBus.dynamicDocumentActionClickedAnnounced({
            action: 'upload',
            status: 'SUCCESS',
            profileDocumentId: profileDocId,
            //  remoteDocId: res['merged-document-id'],
            remoteFileName: this.uploadedDynamicDocumentName
          });
        } else {
          this.refreshState(this.obj.id, this.obj.grpIndex, this.obj.categoryIndex, this.obj.actionType, true, profileDocId);
        }

        this.trackUploadedFiles(multiFiles, id, profileDocId);

        if (!this.isDocEmptyList && currentDocSlide === 'mandatoryDocs') {
          this.calculateDocumentPoints();
        }

        if (!this.isDocEmptyList && currentDocSlide === 'mandatoryDocs') {
          this.validationEngine();
        }
        this.closeUploadDocumentModal();
      }
    }, error => {
      this.resetUploadedFile = this._util.getCryptoRandom();
      this.modalCurrentSlide = 'docUpload_failed';
    });
  }

  // Check file upload minimum requirement
  checkFileUploadMinRequirement(): boolean {
    let statusList = [];
    let retStatus;

    this.calculateDocumentPoints();

    // Check for 100 points and min file upload req for INTL 100P as well as CRIM
    if (this.isIntlGID100P && this.is100PCalculationFeatureAvailable) {
      if (this.is100pTotalPointsReq >= 100 && this.is100pPrimaryId && this.is100pPhotoId && this.is100pAddressId) {
        statusList.push(true);
      } else {
        statusList.push(false);
      }
    }

    if (this.isIntlPRNCrim && this.aUCrim100PCalculationAvailable) {
      if (this.isCategoryAFileUploaded && this.isCategoryBFileUploaded && this.isCategoryCFileUploaded && this.isCrimPhotoId && (this.categoryCFileUploadedCount > 1 || this.categorySecondaryAcicPrnCrimCount > 1)) {
        statusList.push(true);
      } else {
        statusList.push(false);
      }
    } else {
      if (this.isIntlPRNCrim) {
        if (this.isCategoryAFileUploaded && this.isCategoryBFileUploaded && this.isCategoryCFileUploaded && this.isCrimPhotoId && (this.categoryCFileUploadedCount > 1 || this.categorySecondaryAcicPrnCrimCount > 1)) {
          statusList.push(true);
        } else {
          statusList.push(false);
        }
      } else {
        statusList.push(true);
      }
    }

    if (this.isIntlRegNzNzpv) {
      if (this.isNzNzpvPrimaryId && this.isNzNzpvSecondaryId && this.isNzNzpvPhotoId && this.isNzNzpvConsent) {
        statusList.push(true);
      } else {
        statusList.push(false);
      }
    }

    retStatus = (statusList.indexOf(false) > -1) ? false : true;
    return retStatus;
  }

  // Calculate 100 points for INTL and AU Crim, same files in INTL and AU Crim will be calculated only once
  calculateDocumentPoints(): any {
    let selectedObject;

    if (this.mandatoryGroupData !== undefined)
      selectedObject = JSON.parse(JSON.stringify(this.mandatoryGroupData));
    let unique100PDocStatus;
    let uniqueCrimDocStatus;
    let uniqueAFPDocStatus;

    this.is100pPrimaryId = false;
    this.is100pPhotoId = false;
    this.is100pAddressId = false;
    this.isCrimPhotoId = false;
    this.isAFP100pPhotoId = false;
    this.isAFP100pPrimaryId = false;
    this.isAFP100pSecondaryId = false;
    this.isNzNzpvPhotoId = false;
    this.isNzNzpvPrimaryId = false;
    this.isNzNzpvSecondaryId = false;
    this.isNzNzpvConsent = false;
    this.unique100PDocList = {};
    this.uniqueCrimDocList = {};
    this.uniqueAFPDocList = {};
    this.is100pTotalPointsReq = 0;
    this.isCrimTotalPointsReq = 0;
    this.isAFPTotalPointsReq = 0;
    this.is70points100PEntered = false;
    this.is70pointsCrimEntered = false;
    this.isCategoryB40PointsUploaded = false;
    this.isCategoryAFileUploaded = false;
    this.isCategoryBFileUploaded = false;
    this.isCategoryCFileUploaded = false;
    this.is100pAdditionalDocument = false;
    this.categoryCFileUploadedCount = 0;

    if (this.mandatoryGroupData !== undefined) {
      for (let i = 0; i < selectedObject.length; i++) {
        for (let j = 0; j < selectedObject[i]['statewide-criminal-data-list'].length; j++) {
          let category = selectedObject[i]['statewide-criminal-data-list'][j]['category'] !== undefined ? selectedObject[i]['statewide-criminal-data-list'][j]['category'].toUpperCase() : '';
          let status = selectedObject[i]['statewide-criminal-data-list'][j]['status'] !== undefined ? selectedObject[i]['statewide-criminal-data-list'][j]['status'].toUpperCase() : '';
          let points = selectedObject[i]['statewide-criminal-data-list'][j]['points_value'] !== undefined ? parseInt(selectedObject[i]['statewide-criminal-data-list'][j]['points_value'], 10) : 0;
          let docTypeId = selectedObject[i]['statewide-criminal-data-list'][j]['odrer_doc_type_id'];
          let groupCode = selectedObject[i]['group-code'] !== undefined ? selectedObject[i]['group-code'].toUpperCase() : '';
          let docName = selectedObject[i]['statewide-criminal-data-list'][j]['file_name'] !== undefined ? selectedObject[i]['statewide-criminal-data-list'][j]['file_name'] : '';

          if (this.is100PCalculationFeatureAvailable) {
            // If docTypeId does not exist in list, then the points are not added in list hence value is true.
            if (this.unique100PDocList[docTypeId] === undefined) {
              unique100PDocStatus = true;
            }
            // If doctypeId exists and points value is not present in list then true, else false.
            else if (this.unique100PDocList[docTypeId].length > 0 && this.unique100PDocList[docTypeId].indexOf(points) < 0) {
              unique100PDocStatus = true;
            } else {
              unique100PDocStatus = false;
            }
          }

          if (this.aUCrim100PCalculationAvailable) {
            if (this.uniqueCrimDocList[docTypeId] === undefined) {
              uniqueCrimDocStatus = true;
            } else if (this.uniqueCrimDocList[docTypeId].length > 0 && this.uniqueCrimDocList[docTypeId].indexOf(points) < 0) {
              uniqueCrimDocStatus = true;
            } else {
              uniqueCrimDocStatus = false;
            }
          }

          if (this.aFP100PCalculationAvailable) {
            if (this.uniqueAFPDocList[docTypeId] === undefined) {
              uniqueAFPDocStatus = true;
            } else if (this.uniqueAFPDocList[docTypeId].length > 0 && this.uniqueAFPDocList[docTypeId].indexOf(points) < 0) {
              uniqueAFPDocStatus = true;
            } else {
              uniqueAFPDocStatus = false;
            }
          }

          this.selectedObj = selectedObject[i]['statewide-criminal-data-list'][j];

          if (this.isIntlGID100P && this.is100PCalculationFeatureAvailable && groupCode !== '' && groupCode === '100P') {
            this.calculate100PPoints(category, unique100PDocStatus, docTypeId, points, status);
          }

          if (this.isIntlPRNCrim && groupCode !== '' && groupCode === 'CRIM') {
            let isPhotoId = selectedObject[i]['statewide-criminal-data-list'][j]['photo_id'] !== undefined ? selectedObject[i]['statewide-criminal-data-list'][j]['photo_id'].toUpperCase() : '';

            this.calculateAUCrimPoints(category, points, uniqueCrimDocStatus, docTypeId, isPhotoId, status, i);
          }

          if (this.isIntlAFP100P && groupCode !== '' && (groupCode === 'AF22' || groupCode === 'NOSRHCD')) {
            let isPhotoId = selectedObject[i]['statewide-criminal-data-list'][j]['photo_id'] !== undefined ? selectedObject[i]['statewide-criminal-data-list'][j]['photo_id'].toUpperCase() : '';

            this.calculateAFP100PPoints(category, uniqueAFPDocStatus, docTypeId, points, isPhotoId, status);
          }

          if (this.isIntlRegNzNzpv) {
            let isPhotoId = selectedObject[i]['statewide-criminal-data-list'][j]['photo_id'] !== undefined ? selectedObject[i]['statewide-criminal-data-list'][j]['photo_id'].toUpperCase() : '';
            let mandatoryGroupDataLength = this.mandatoryGroupData.length;

            this.calculateNzNzpvValidationStatus(category, isPhotoId, status, mandatoryGroupDataLength, docTypeId, docName);
          }
        }
      }
    }

    this.disableUploadOn100PointsSuccess();
  }

  // Calculate 100 ponts for INTL GID
  calculate100PPoints(category, unique100PDocStatus, docTypeId, points, status) {
    if (DocumentUploadConfig.intl100PCategory.indexOf(category) > -1 && status === 'SUCCESS') {
      // Check to calculate duplicate document points only once
      if (unique100PDocStatus) {
        if (this.unique100PDocList[docTypeId] !== undefined) {
          this.unique100PDocList[docTypeId].push(points);
        } else {
          this.unique100PDocList[docTypeId] = [points];
        }

        this.calculatePointsFor100P(points);
      }

      if (category === DocumentUploadConfig.intl100PCategory[0]) {
        this.is100pPrimaryId = true;
      } else if (category === DocumentUploadConfig.intl100PCategory[1]) {
        this.is100pPhotoId = true;
      } else if (category === DocumentUploadConfig.intl100PCategory[2]) {
        this.is100pAddressId = true;
      } else if (category === DocumentUploadConfig.intl100PCategory[3]) {
        this.is100pAdditionalDocument = true;
      }
    }
  }

  // Calculate 100 points for AU Crim
  calculateAUCrimPoints(category, points, uniqueCrimDocStatus, docTypeId, isPhotoId, status, grpIndex) {
    // if ((category === DocumentUploadConfig.intlAUCrimCategory[0] || category === DocumentUploadConfig.intlAUCrimCategory[1]) && status === 'SUCCESS') {
    if (DocumentUploadConfig.intlAUCrimCategory.indexOf(category) > -1 && status === 'SUCCESS') {
      if ((category === DocumentUploadConfig.intlAUCrimCategory[2] || category === DocumentUploadConfig.intlAUCrimCategory[3]) && this.aUCrim100PCalculationAvailable)
        this.checkAUCrim40PointsDoc(points);

      // Check to calculate duplicate document points only once
      if (uniqueCrimDocStatus && this.aUCrim100PCalculationAvailable) {
        if (this.uniqueCrimDocList[docTypeId] !== undefined) {
          this.uniqueCrimDocList[docTypeId].push(points);
        } else {
          this.uniqueCrimDocList[docTypeId] = [points];
        }

        this.calculatePointsForAUCrim(points);
      }

      if (category === DocumentUploadConfig.intlAUCrimCategory[0] || category === DocumentUploadConfig.intlAUCrimCategory[1]) {
        this.isCategoryAFileUploaded = true;

        if (isPhotoId === 'YES')
          this.isCrimPhotoId = true;
      } else if (category === DocumentUploadConfig.intlAUCrimCategory[2] || category === DocumentUploadConfig.intlAUCrimCategory[3]) {
        this.isCategoryBFileUploaded = true;

        if (isPhotoId === 'YES')
          this.isCrimPhotoId = true;
      } else if (category === DocumentUploadConfig.intlAUCrimCategory[4] || category === DocumentUploadConfig.intlAUCrimCategory[5]) {
        this.isCategoryCFileUploaded = true;

        if (this.isIntlPRNCrim && category === DocumentUploadConfig.intlAUCrimCategory[5]) {
          if (!this.categorySecondaryAcicPrnCrim[grpIndex]) {
            this.categorySecondaryAcicPrnCrim[grpIndex] = true;
            this.categorySecondaryAcicPrnCrimCount = this.categorySecondaryAcicPrnCrimCount + 1;
          }
        } else {
          // Add count for Category C/Secondary documents uploaded. Minimum 2 documents are required of type Category C/Secondary Documents
          this.categoryCFileUploadedCount = this.categoryCFileUploadedCount + 1;
        }

      }
    }
  }

  // Calculate 100 points for AFP
  calculateAFP100PPoints(category, uniqueAFPDocStatus, docTypeId, points, isPhotoId, status) {
    if (DocumentUploadConfig.intlAFP100PCategory.indexOf(category) > -1 && status === 'SUCCESS') {

      // Check to calculate duplicate document points only once
      if (uniqueAFPDocStatus && this.aFP100PCalculationAvailable) {
        if (this.uniqueAFPDocList[docTypeId] !== undefined) {
          this.uniqueAFPDocList[docTypeId].push(points);
        } else {
          this.uniqueAFPDocList[docTypeId] = [points];
        }

        this.calculatePointsForAFP(points);
      }

      if (category === DocumentUploadConfig.intlAFP100PCategory[0]) {
        this.isAFP100pPrimaryId = true;

        if (isPhotoId === 'YES')
          this.isAFP100pPhotoId = true;
      } else if (category === DocumentUploadConfig.intlAFP100PCategory[1]) {
        this.isAFP100pSecondaryId = true;

        if (isPhotoId === 'YES')
          this.isAFP100pPhotoId = true;
      }
    }
  }

  /**
   * set flag to enable next button based on primary and secondary doc with one photo id for INTLREG-NZ-NZPV
   * @param category
   * @param isPhotoId
   * @param status
   */
  calculateNzNzpvValidationStatus(category, isPhotoId, status, mandatoryGroupDataLength, docTypeId, docName) {
    let fileName = docName.toLowerCase();

    if (DocumentUploadConfig.intlNZNzpvCategory.indexOf(category) > -1 && status === 'SUCCESS') {
      if (category === DocumentUploadConfig.intlNZNzpvCategory[0]) {
        this.isNzNzpvPrimaryId = true;
      } else if (category === DocumentUploadConfig.intlNZNzpvCategory[1]) {
        this.isNzNzpvSecondaryId = true;
      }

      if (isPhotoId === 'YES') {
        this.isNzNzpvPhotoId = true;
      }

      if (mandatoryGroupDataLength === 2) {
        this.isNzNzpvConsent = true;
      }
    } else if (DocumentUploadConfig.intlNZNzpvCategory.indexOf(category) < 0 && status === 'SUCCESS') {
      this.isNzNzpvConsent = true;
    }
  }

  /**
   * Calculate points doc for INTL GID
   * @param points
   */
  calculatePointsFor100P(points) {
    this.is100pTotalPointsReq = this.is100pTotalPointsReq + points;
  }

  /**
   * Calculate points doc for AU Crim
   * @param points
   */
  calculatePointsForAUCrim(points) {
    this.isCrimTotalPointsReq = this.isCrimTotalPointsReq + points;
  }

  /**
   * Calculate points doc for AFP
   * @param points
   */
  calculatePointsForAFP(points) {
    this.isAFPTotalPointsReq = this.isAFPTotalPointsReq + points;
  }

  /**
   * Check for 40 points doc for AU Crim
   * @param points
   */
  checkAUCrim40PointsDoc(points) {
    // Document with category B will have 40 points value on first upload, subsequent upload will have 25 points value
    if (!this.isCategoryB40PointsUploaded) {
      this.isCategoryB40PointsUploaded = true;
      points = points;
    } else if (this.isCategoryB40PointsUploaded)
      points = 25;
  }

  /**
   * Disable upload button on successful completion of providing 100 points
   */
  disableUploadOn100PointsSuccess() {
    let selectedObject;

    if (this.mandatoryGroupData !== undefined) {
      selectedObject = JSON.parse(JSON.stringify(this.mandatoryGroupData));

      for (let i = 0; i < selectedObject.length; i++) {
        for (let j = 0; j < selectedObject[i]['statewide-criminal-data-list'].length; j++) {
          let category = selectedObject[i]['statewide-criminal-data-list'][j]['category'] !== undefined ? selectedObject[i]['statewide-criminal-data-list'][j]['category'].toUpperCase() : '';
          let groupCode = selectedObject[i]['group-code'] !== undefined ? selectedObject[i]['group-code'].toUpperCase() : '';

          // Disable docs associated with 100P on collecting more than 100 points and when all min requirements are satisfied
          if (groupCode !== '' && groupCode === '100P') {
            this.disable100POnMinReqSuccess(category, i, j);
          }
          // Disable docs associated with AU Crim on collecting more than 100 points and when all min requirements are satisfied
          if (groupCode !== '' && groupCode === 'CRIM') {
            this.disableAUCrimOnMinReqSuccess(category, i, j);
          }
          // Disable docs associated with 100P on collecting more than 100 points and when all min requirements are satisfied
          if (groupCode !== '' && (groupCode === 'AF22' || groupCode === 'NOSRHCD')) {
            this.disableAFP100POnMinReqSuccess(category, i, j);
          }
          // Disable docs associated with NZPV when all min requirements are satisfied
          if (this.isIntlRegNzNzpv) {
            this.disableNzNzpvPOnMinReqSuccess(category, i, j);
          }
        }
      }
    }
  }

  /**
   * Disable upload button on successful completion of providing 100 points and specific conditions for INTL GID
   * @param category
   * @param i
   * @param j
   */
  disable100POnMinReqSuccess(category, i, j) {
    // (category === 'PRIMARYID' || category === 'PHOTOID' || category === 'ADDRESSID')
    if (this.isIntlGID100P && DocumentUploadConfig.intl100PCategory.indexOf(category) > -1) {
      if (this.is100pTotalPointsReq >= 100) {
        if (this.is100pPrimaryId && this.is100pPhotoId && this.is100pAddressId) {
          this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['isDisabled'] = true;
        } else {
          this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['isDisabled'] = false;
        }
      } else {
        this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['isDisabled'] = false;
      }
    }
  }

  /**
   * Disable upload button on successful completion of providing 100 points and specific conditions for AU Crim
   * @param category
   * @param i
   * @param j
   */
  disableAUCrimOnMinReqSuccess(category, i, j) {
    if (this.isIntlPRNCrim && DocumentUploadConfig.intlAUCrimCategory.indexOf(category) > -1) {
      if (this.aUCrim100PCalculationAvailable && this.isCrimTotalPointsReq >= 100) {
        if (this.isCategoryAFileUploaded && this.isCategoryBFileUploaded && this.isCategoryCFileUploaded && this.categoryCFileUploadedCount > 1 && this.isCrimPhotoId && DocumentUploadConfig.intlAUCrimCategory.indexOf(category) > -1) {
          this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['isDisabled'] = true;
        } else {
          this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['isDisabled'] = false;
        }
      } else if (!this.aUCrim100PCalculationAvailable) {
        if (this.isCategoryAFileUploaded && this.isCategoryBFileUploaded && this.isCategoryCFileUploaded && this.categoryCFileUploadedCount > 1 && this.isCrimPhotoId && DocumentUploadConfig.intlAUCrimCategory.indexOf(category) > -1) {
          this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['isDisabled'] = true;
        } else {
          this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['isDisabled'] = false;
        }
      } else {
        this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['isDisabled'] = false;
      }

      if ((this.isCategoryCFileUploaded || this.isCategoryAFileUploaded || this.isCategoryBFileUploaded) && (DocumentUploadConfig.intlAUCrimCategory[5] === category || DocumentUploadConfig.intlAUCrimCategory[1] === category || DocumentUploadConfig.intlAUCrimCategory[3] === category) && this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['forceToDisable']) {
        this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['isDisabled'] = true;
      }

    }
  }

  /**
   * Disable upload button on successful completion of providing 100 points and specific conditions for AFP
   * @param category
   * @param i
   * @param j
   */
  disableAFP100POnMinReqSuccess(category, i, j) {
    if (this.isIntlAFP100P && DocumentUploadConfig.intlAFP100PCategory.indexOf(category) > -1) {
      if (this.isAFPTotalPointsReq >= 100) {
        if (this.isAFP100pPrimaryId && this.isAFP100pPhotoId && (category === DocumentUploadConfig.intlAFP100PCategory[0] || category === DocumentUploadConfig.intlAFP100PCategory[1])) {
          this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['isDisabled'] = true;
        } else {
          this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['isDisabled'] = false;
        }
      } else {
        this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['isDisabled'] = false;
      }
    }
  }

  /**
   * Disable upload button on successful completion of providing document for Nz NZPV
   * @param category
   * @param i
   * @param j
   */
  disableNzNzpvPOnMinReqSuccess(category, i, j) {
    if (this.isIntlRegNzNzpv && DocumentUploadConfig.intlNZNzpvCategory.indexOf(category) > -1) {
      if (this.isNzNzpvPrimaryId && this.isNzNzpvPhotoId && category === DocumentUploadConfig.intlNZNzpvCategory[0]) {
        this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['isDisabled'] = true;
      } else if (this.isNzNzpvSecondaryId && this.isNzNzpvPhotoId && category === DocumentUploadConfig.intlNZNzpvCategory[1]) {
        this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['isDisabled'] = true;
      } else {
        this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['isDisabled'] = false;
      }
    } else if (this.isIntlRegNzNzpv && DocumentUploadConfig.intlNZNzpvCategory.indexOf(category) < 0) {
      let statusCheck = this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['status'] !== undefined && this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['status'] !== '';
      if (statusCheck && this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['status'].toUpperCase() === 'SUCCESS') {
        this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['isDisabled'] = true;
      } else {
        this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['isDisabled'] = false;
      }
    }
  }

  // Validation error emit from multiple file upload component for file size/error
  onMultipleFilesValidationError(val) {
    this.prevSlide = this.modalCurrentSlide;

    if (val['fileExtError']) {
      this.modalCurrentSlide = 'docUpload_fileExt_Error';
    } else if (val['fileSizeError']) {
      this.modalCurrentSlide = 'docUpload_fileSize_Error';
    }
  }

  // Close the modal
  closeUploadDocumentModal() {
    this.modalCurrentSlide = 'docUpload_options';
    this.prevSlide = '';
    this.inputResetFilesList = this._util.getCryptoRandom();
    this.resetUploadedFile = this._util.getCryptoRandom();
    this.showCameraError = false;
    $('#uploadDocuments_Modal').modal('hide');
  }

  // Close the delete modal
  closeDocumentDeleteModal() {
    $('#document_delete_modal').modal('hide');
  }

  // Close the uploaded re-confirm modal
  closeUploadReConfirmModal() {
    $('#upload_Reconfirm_modal').modal('hide');
  }

  // On delete confirm
  onDeleteConfirm() {
    // Make DELETE service call
    this.callDeleteService(this.deleteObj);
  }

  // On upload re-confirm modal
  onUploadReConfirm() {
    // Make DELETE service call
    this.callDeleteService(this.obj);
  }

  // On View PDF close modal
  viewPdfModalClose() {
    $('#view_pdf_modal').modal('hide');
  }

  // Make DELETE service call  -  Generic Function used for both onDeleteConfirm and onUploadReConfirm method
  callDeleteService(obj) {

    let currentDocSlide = this.isDynamicDocumentUpload ? null : this.currentSlide.split('_')[0];
    // let process100P;

    this._documentUploadService.deleteFile(obj['id'], this.isFromEnhancedDashboard, obj['profileDocID']).subscribe(res => {
      let actionType = 'delete';

      if (!this.isDynamicDocumentUpload) {
        this.refreshState(obj['id'], obj['grpIndex'], obj['categoryIndex'], actionType, true, null);
      }

      if (obj['actionType'] === 'delete') {
        if (this.isDynamicDocumentUpload) {
          this.uploadedDynamicDocumentName = null;
          this.miDocument['action'] = actionType;
          this.miDocument['status'] = 'SUCCESS';
          this._eventBus.dynamicDocumentActionClickedAnnounced({
            action: 'delete',
            status: 'SUCCESS'
          });
        }
        this.closeDocumentDeleteModal();
      } else if (obj['actionType'] === 'upload') {
        this.closeUploadReConfirmModal();
        this.uploadAction(this.obj);
      }

      if (!this.isDocEmptyList && currentDocSlide === 'mandatoryDocs') {
        this.calculateDocumentPoints();
      }

      if (!this.isDocEmptyList && currentDocSlide === 'mandatoryDocs') {
        this.validationEngine();
      }
    },
      error => {
      });
  }

  // Toggle next button
  onToggleNextButtonOutput(obj) {
    this.btnEnabled = obj.val;
  }

  // Toggle camera next button
  onToggleCamNextButtonOutput(obj) {
    this.camBtnEnabled = obj.val;
  }

  // On camera initialization error
  onCameraInitializeError(obj) {
    if (obj.val) {
      this.modalCurrentSlide = 'docUpload_camera';
    } else {
      this.showCameraError = true;
    }
  }

  // Prev/Back arrow click
  goBackToUploadOptions() {
    this.modalCurrentSlide = 'docUpload_options';
    this.modalCurrentSlideWithDirection = 'docUpload_options_left';
  }

  // Close file extension modal
  closeFileExtModal() {
    this.modalCurrentSlide = this.prevSlide;
  }

  // Close file size modal
  closeFileSizeModal() {
    this.modalCurrentSlide = this.prevSlide;
  }

  // Close upload fail modal
  closeFileUploadFailModal() {
    this.modalCurrentSlide = this.prevSlide;
  }

  // Close document info modal
  docUploadInfoModalClose() {
    $('#docUpload_info_modal').modal('hide');
  }

  /* -------------------------- Modal Methods - ENDS --------------------------------- */

  /* -------------------------- Private Methods - STARTS --------------------------------- */

  // Set initial state
  setState(): void {
    this.payLoad = {
      name: this.PARENT_STAGE_STEP_CONFIG['STAGE_ID'],
      step: this.PARENT_STAGE_STEP_CONFIG['STEP_ID'],
      stepIndex: this.PARENT_STAGE_STEP_CONFIG['STEP_INDEX'],
      subStep: this.SUB_STEP_ID,
      subStepIndex: this.SUB_STEP_INDEX,
      status: this.currentStatus,
      dbRecordPresent: this.PARENT_STAGE_STEP_CONFIG['IS_DB_RECORD_PRESENT']
    };

    this._store.dispatch({ type: 'VISITED', payload: this.payLoad });
  }

  // Navigate to top of device
  navigationtopMobile() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      $('html, body').animate({
        scrollTop: $('.status_bar,.body-bg').offset().top
      }, 2000);
    }
  }

  // Check if mobile device
  checkMobileDevice() {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/.test(userAgent) && !window.MSStream) {
      this.isMobileDevice = true;
    }
  }

  // Return next Url
  _next(url): void {
    this._router.navigate([url]);
  }

  // Call get service
  getData() {
    // this.firstName = this.localStorage.getItem('first_name');
    $('#waitModalForDocUpload').modal('show');

    // Make get call first check the data if the length > 0, no put call
    // if get data length is lesser than zero make put and get call
    this._documentUploadService.getData().subscribe(getResponse => {
      let checkDocumentData = getResponse;

      if (!this.checkForDocuploadPutcall(checkDocumentData)) {
        this.checkForPopupInOptionalDocs(checkDocumentData);
        this.validateUploadSuccess(checkDocumentData);        
      } else {
        this._documentUploadService.putData().subscribe(result => {
          this._documentUploadService.getData().subscribe(response => {
            let refetchDocData = response;
            
            this.checkForPopupInOptionalDocs(refetchDocData);
            this.validateUploadSuccess(refetchDocData);
          },
            error => { });
        },
          error => { });
      }
    },
      error => { });
  }

  // Check for document upload put call
  checkForDocuploadPutcall(checkDocumentDataObj) {
    let mandatoryDocsCheck: boolean = false;
    let optionalDocsCheck: boolean = false;
    let docsCheck: boolean = false;

    mandatoryDocsCheck = checkDocumentDataObj['statewide-criminal-data-lists'].length === 0 ? true : false;
    optionalDocsCheck = checkDocumentDataObj['optional-criminal-data-lists'].length === 0 ? true : false;

    if (!mandatoryDocsCheck) {
      for (let i = 0; i < checkDocumentDataObj['statewide-criminal-data-lists'].length; i++) {
        if (checkDocumentDataObj['statewide-criminal-data-lists'][i]['module_name'] !== undefined
          && checkDocumentDataObj['statewide-criminal-data-lists'][i]['module_name'] === 'Authorization Form') {
          mandatoryDocsCheck = true;
        }
      }
    }

    if (mandatoryDocsCheck && optionalDocsCheck) {
      docsCheck = true;
    } else {
      docsCheck = false;
    }

    return docsCheck;
  }

  // Validate upload success
  validateUploadSuccess(res) {
    let state = res['state'];

    // Validate Mandatory Docs
    this.validateMandatoryDocs(res);

    // Validate Optional Docs
    this.validateOptionalDocs(res);

    // Prepare Skipped docs list on logout and login
    if (!this.isFromEnhancedDashboard) {
      this.prepareSkippedDocumentsList();

      if (this.skippedDocumentsGroupData['skipped-documents-list'].length > 0) {
        this.generateDocumentUploadForm(res);
      }
    }

    // Set slide
    this.setSlide(state);
  }

  // Validate mandatory documents
  validateMandatoryDocs(res) {
    let isInstruction;
    let isRemoteDocId;
    let duplicateAuthFormIndex;
    let isDuplicateAuthForm = false;

    if (res['statewide-criminal-data-lists'] !== undefined && res['statewide-criminal-data-lists'].length > 0) {
      this.isDocEmptyList = false;

      for (let i = 0; i < res['statewide-criminal-data-lists'].length; i++) {
        // Identify index of Duplicate Authorization Form
        if (res['statewide-criminal-data-lists'][i]['module_name'] !== undefined &&
          res['statewide-criminal-data-lists'][i]['module_name'] === 'Authorization Form' &&
          res['statewide-criminal-data-lists'][i]['statewide-criminal-data-list'].length === 0) {
          duplicateAuthFormIndex = i;
          isDuplicateAuthForm = true;
        }

        for (let j = 0; j < res['statewide-criminal-data-lists'][i]['statewide-criminal-data-list'].length; j++) {
          let documentData = res['statewide-criminal-data-lists'][i]['statewide-criminal-data-list'][j];

          // if (documentData['instructions_doc_id'] !== 'null' && documentData['instructions_doc_id'] !== undefined) {
          //   isInstruction = true;
          // } else {
          //   isInstruction = false;
          // }

          // Check remote document id
          if (documentData['remote_document_id'] !== 'null' && documentData['remote_document_id'] !== undefined) {
            isRemoteDocId = true;
          } else {
            isRemoteDocId = false;
          }

          res['statewide-criminal-data-lists'][i]['statewide-criminal-data-list'][j]['has_instruction_form'] = documentData['has_instruction_form'];
          res['statewide-criminal-data-lists'][i]['statewide-criminal-data-list'][j]['has_blank_form'] = documentData['has_blank_form'];

          if (res['statewide-criminal-data-lists'][i]['group-code'] !== undefined && res['statewide-criminal-data-lists'][i]['group-code'] !== '' && res['statewide-criminal-data-lists'][i]['group-code'] === 'CRIM' && !this.aUCrim100PCalculationAvailable) {
            if (documentData['points_value'] !== undefined && documentData['points_value'] !== '') {
              documentData['points_value'] = '';
            }
          }
        }

        // Modify group desc to remove '100P' and 'CRIM' for displaying in UI
        if (res['statewide-criminal-data-lists'][i]['group-code'] !== undefined && res['statewide-criminal-data-lists'][i]['group-code'] !== '') {
          let groupCode = res['statewide-criminal-data-lists'][i]['group-code'].toUpperCase();

          if (groupCode === '100P') {
            this.iNTL100PRemoteid = res['statewide-criminal-data-lists'][i]['instructions_doc_id'];
            this.isIntlGID100P = true;
          } else if (groupCode === 'CRIM') {
            this.iNTLCRIMRemoteid = res['statewide-criminal-data-lists'][i]['instructions_doc_id'];
            this.isIntlPRNCrim = true;
          } else if (groupCode === 'AF22' || groupCode === 'NOSRHCD') {
            this.iNTLAFPRemoteId = res['statewide-criminal-data-lists'][i]['instructions_doc_id'];
            this.isIntlAFP100P = true;
          } else if (groupCode === 'NZPV') {
            this.isIntlRegNzNzpv = true;
          }

          if (groupCode === '100P' || groupCode === 'CRIM' || groupCode === 'AF22' || groupCode === 'NOSRHCD') {
            // Fetch instructions location code - If present then fetch else blank
            let insLocationCode = res['statewide-criminal-data-lists'][i]['doc_instruction_location_cd'];
            this.instructions_location_code = insLocationCode !== undefined ? insLocationCode : 'US';
          }
        }
      }

      this.mandatoryGroupData = res['statewide-criminal-data-lists'];

      this.calculateDocumentPoints();

      // Remove Duplicate Authorization Form
      if (isDuplicateAuthForm) {
        res['statewide-criminal-data-lists'].splice(duplicateAuthFormIndex, 1);
        isDuplicateAuthForm = false;
      }
      this.mandatoryGroupData = res['statewide-criminal-data-lists'];

      // Need to be enable it
      this.validationEngine();

      $('#waitModalForDocUpload').modal('hide');
      // Code commented as no longer needed, Global custom instructions added
      // if (!this.enableNextButton)
      // $('#docUpload_info_modal').modal('show');
    } else {
      this.isDocEmptyList = true;
      this.enableNextButton = true;
      $('#waitModalForDocUpload').modal('hide');
    }

    if (!this.isDocUploadCriteriaFulfilled) {
      this.instructions = this._documentUploadService.getCustomInstructions(this.isInviteEiaf);
       this.isSSAFormAvailable = this.checkSSAForm();
      if (!!this.instructions || this.isSSAFormAvailable) {
        this.prepareCustomInstruction();
      }
    }
  }

  // Validate optional documents
  validateOptionalDocs(res) {
    let isOptionalInstruction;
    let isOptionalRemoteDocId;

    // Re-check this condition after discussion
    if (res['optional-criminal-data-lists'] !== undefined && res['optional-criminal-data-lists'].length > 0) {
      this.isOptionalDocsPresent = true;

      for (let m = 0; m < res['optional-criminal-data-lists'].length; m++) {
        for (let n = 0; n < res['optional-criminal-data-lists'][m]['statewide-criminal-data-list'].length; n++) {
          let docData = res['optional-criminal-data-lists'][m]['statewide-criminal-data-list'][n];

          // if (docData['instructions_doc_id'] !== 'null' && docData['instructions_doc_id'] !== undefined) {
          //   isOptionalInstruction = true;
          // } else {
          //   isOptionalInstruction = false;
          // }
          // Check remote document id
          if (docData['remote_document_id'] !== 'null' && docData['remote_document_id'] !== undefined) {
            isOptionalRemoteDocId = true;
          } else {
            isOptionalRemoteDocId = false;
          }
          res['optional-criminal-data-lists'][m]['statewide-criminal-data-list'][n]['has_instruction_form'] = docData['has_instruction_form'];
          res['optional-criminal-data-lists'][m]['statewide-criminal-data-list'][n]['has_blank_form'] = docData['has_blank_form'];

          if (res['optional-criminal-data-lists'][m]['group-desc'] !== undefined && res['optional-criminal-data-lists'][m]['group-desc'] !== '' && docData['category'] !== undefined && (docData['category'].toUpperCase() === DocumentUploadConfig.intlAUCrimCategory[2] || docData['category'].toUpperCase() === DocumentUploadConfig.intlAUCrimCategory[3] || docData['category'].toUpperCase() === DocumentUploadConfig.intlAFP100PCategory[1])) {
            if (docData['points_value'] !== undefined && docData['points_value'] !== '') {
              docData['points_value'] = '';
            }
          }
        }
      }

      this.optionalGroupData = res['optional-criminal-data-lists'];
    } else {
      this.isOptionalDocsPresent = false;
    }
  }

  // Set slide
  setSlide(state) {
    if (state !== undefined && state['stage_status'] !== undefined && state['stage_status'] === 'PENDING') {
      if (state['current_sub_step'] === 'mandatory' && state['step_status'] === 'PENDING') {
        this.currentSlide = this.documentUploadSteps[0] + '_';
      } else {
        if (this.skippedDocumentsGroupData['skipped-documents-list'].length > 0)
          this.currentSlide = this.documentUploadSteps[1] + '_';
        else {
          this.currentSlide = this.documentUploadSteps[2] + '_';
          if (this.isOptionalEmploymentDocuments)
            this.onOptionalEmploymentModal();
        }
      }
    } else {
      if (!this.isDocEmptyList) {
        this.currentSlide = this.documentUploadSteps[0] + '_';
      } else {
        if (this.skippedDocumentsGroupData['skipped-documents-list'].length > 0)
          this.currentSlide = this.documentUploadSteps[1] + '_';
        else {
          this.currentSlide = this.documentUploadSteps[2] + '_';
          if (this.isOptionalEmploymentDocuments)
            this.onOptionalEmploymentModal();
        }
      }
    }
  }

  // State processing to maintain state
  stateProcessing(id, grpIndex, catIndex, actionType, ack, profileDocID) {
    let selectedObject = [];
    let duplicateObject = [];
    let duplicateObjectOptional = [];
    let orderDocTypeId = '';
    let slide = this.currentSlide.split('_')[0];

    if (slide === 'mandatoryDocs') {
      duplicateObject = JSON.parse(JSON.stringify(this.mandatoryGroupData));
      selectedObject = duplicateObject;
    } else {
      duplicateObjectOptional = JSON.parse(JSON.stringify(this.optionalGroupData));
      selectedObject = duplicateObjectOptional;
    }

    // Get the order doc type id of the file uploaded
    if (selectedObject[grpIndex]['statewide-criminal-data-list'][catIndex]['odrer_doc_type_id'] !== undefined && selectedObject[grpIndex]['statewide-criminal-data-list'][catIndex]['odrer_doc_type_id'] !== '') {
      orderDocTypeId = selectedObject[grpIndex]['statewide-criminal-data-list'][catIndex]['odrer_doc_type_id'];
    }

    for (let i = 0; i < selectedObject.length; i++) {
      for (let j = 0; j < selectedObject[i]['statewide-criminal-data-list'].length; j++) {
        let category = selectedObject[i]['statewide-criminal-data-list'][j].category;
        category = !!category ? category.toUpperCase() : category;
        let moduleName = selectedObject[i]['module_name'];
        let moduleCheck = moduleName !== undefined && DocumentUploadConfig.moduleCheckList.indexOf(moduleName) < 0;
        let orderDocType = selectedObject[i]['statewide-criminal-data-list'][j]['odrer_doc_type_id'];
        let orderDocTypeCheck = orderDocType !== undefined && orderDocType === orderDocTypeId;
        let disableSecondaryRedundantDocuments = this.isIntlPRNCrim && (DocumentUploadConfig.intlAUCrimCategory[5] === category || DocumentUploadConfig.intlAUCrimCategory[1] === category || DocumentUploadConfig.intlAUCrimCategory[3] === category);

        // If condition which will exclude the specified packages
        // If condition which will match the ORD_DOC_TYPE_ID of the element in this.groupobject with the document element being uploaded
        if (moduleCheck) {
          if (orderDocTypeCheck) {
            this.redundantDocsStatusUpdate(actionType, ack, slide, selectedObject, profileDocID, i, j, disableSecondaryRedundantDocuments, grpIndex);
          } else {
            this.nonRedundantDocsStatusUpdate(actionType, ack, slide, selectedObject, grpIndex, catIndex, profileDocID, id);
          }
        } else {
          this.nonRedundantDocsStatusUpdate(actionType, ack, slide, selectedObject, grpIndex, catIndex, profileDocID, id);
        }
      }
    }
  }

  // Update status of redundant/repeating documents
  redundantDocsStatusUpdate(actionType, ack, slide, selectedObject, profileDocID, i, j, disableSecondaryRedundantDocuments, grpIndex) {
    if (actionType === 'upload') {  // Upload success
      if (ack === true) {
        this.uploadStateProcessingRedundantDocs(slide, selectedObject, profileDocID, i, j, disableSecondaryRedundantDocuments, grpIndex);
      } else {
        // Upload fail
      }
    } else if (actionType === 'delete') { // Delete success
      if (ack === true) {
        this.deleteStateProcessingRedundantDocs(slide, selectedObject, i, j);
      } else {
        // Delete fail
      }
    }
  }

  // Update status of non-redundant/not repeating documents
  nonRedundantDocsStatusUpdate(actionType, ack, slide, selectedObject, grpIndex, catIndex, profileDocID, id) {
    if (actionType === 'upload') {  // Upload success
      if (ack === true) {
        if (selectedObject[grpIndex]['statewide-criminal-data-list'][catIndex]['id'] === id) {
          this.uploadStateProcessing(slide, selectedObject, grpIndex, catIndex, profileDocID);
        }
      } else {
        // Upload fail
      }
    } else if (actionType === 'delete') { // Delete success
      if (ack === true) {
        if (selectedObject[grpIndex]['statewide-criminal-data-list'][catIndex]['id'] === id) {
          this.deleteStateProcessing(slide, selectedObject, grpIndex, catIndex);
        }
      } else {
        // Delete fail
      }
    }
  }

  // Upload processing for redundant/repeating documents
  uploadStateProcessingRedundantDocs(slide, selectedObject, profileDocID, i, j, disableSecondaryRedundantDocuments, grpIndex) {
    let replicateMandatoryDocs = [];
    let replicateOptionalDocs = [];

    if (slide === 'mandatoryDocs') {
      if (i !== grpIndex && disableSecondaryRedundantDocuments) {
        selectedObject[i]['statewide-criminal-data-list'][j]['forceToDisable'] = true;
      } else {
        selectedObject[i]['statewide-criminal-data-list'][j]['status'] = 'SUCCESS';
      }
      selectedObject[i]['statewide-criminal-data-list'][j]['profile-document-id'] = profileDocID;

      replicateMandatoryDocs = JSON.parse(JSON.stringify(selectedObject));
      this.mandatoryGroupData = replicateMandatoryDocs;
    } else {
      selectedObject[i]['statewide-criminal-data-list'][j]['status'] = 'SUCCESS';
      selectedObject[i]['statewide-criminal-data-list'][j]['profile-document-id'] = profileDocID;

      replicateOptionalDocs = JSON.parse(JSON.stringify(selectedObject));
      this.optionalGroupData = replicateOptionalDocs;
    }
  }

  // Delete processing for redundant/repeating documents
  deleteStateProcessingRedundantDocs(slide, selectedObject, i, j,) {
    let replicateMandatoryDocs = [];
    let replicateOptionalDocs = [];

    if (slide === 'mandatoryDocs') {
      delete selectedObject[i]['statewide-criminal-data-list'][j]['status'];
      delete selectedObject[i]['statewide-criminal-data-list'][j]['profile-document-id'];
      if (selectedObject[i]['statewide-criminal-data-list'][j]['forceToDisable']) {
        delete selectedObject[i]['statewide-criminal-data-list'][j]['forceToDisable'];
      }

      replicateMandatoryDocs = JSON.parse(JSON.stringify(selectedObject));
      this.mandatoryGroupData = replicateMandatoryDocs;
    } else {
      delete selectedObject[i]['statewide-criminal-data-list'][j]['status'];
      delete selectedObject[i]['statewide-criminal-data-list'][j]['profile-document-id'];

      replicateOptionalDocs = JSON.parse(JSON.stringify(selectedObject));
      this.optionalGroupData = replicateOptionalDocs;
    }
  }

  // Upload state processing
  uploadStateProcessing(slide, selectedObject, grpIndex, catIndex, profileDocID) {
    let replicateMandatoryDocs = [];
    let replicateOptionalDocs = [];

    if (slide === 'mandatoryDocs') {
      selectedObject[grpIndex]['statewide-criminal-data-list'][catIndex]['status'] = 'SUCCESS';
      selectedObject[grpIndex]['statewide-criminal-data-list'][catIndex]['profile-document-id'] = profileDocID;

      replicateMandatoryDocs = JSON.parse(JSON.stringify(selectedObject));
      this.mandatoryGroupData = replicateMandatoryDocs;
    } else {
      selectedObject[grpIndex]['statewide-criminal-data-list'][catIndex]['status'] = 'SUCCESS';
      selectedObject[grpIndex]['statewide-criminal-data-list'][catIndex]['profile-document-id'] = profileDocID;

      if (this.isInviteEiaf) {
        let updatedFileName = this.getEiafFileName(selectedObject[grpIndex]['statewide-criminal-data-list'][catIndex]['file_name']);
        selectedObject[grpIndex]['statewide-criminal-data-list'][catIndex]['file_name'] = updatedFileName;
      }
      replicateOptionalDocs = JSON.parse(JSON.stringify(selectedObject));
      this.optionalGroupData = replicateOptionalDocs;
    }
  }

  // Delete state processing
  deleteStateProcessing(slide, selectedObject, grpIndex, catIndex) {
    let replicateMandatoryDocs = [];
    let replicateOptionalDocs = [];

    if (slide === 'mandatoryDocs') {
      delete selectedObject[grpIndex]['statewide-criminal-data-list'][catIndex]['status'];
      delete selectedObject[grpIndex]['statewide-criminal-data-list'][catIndex]['profile-document-id'];

      replicateMandatoryDocs = JSON.parse(JSON.stringify(selectedObject));
      this.mandatoryGroupData = replicateMandatoryDocs;
    } else {
      delete selectedObject[grpIndex]['statewide-criminal-data-list'][catIndex]['status'];
      delete selectedObject[grpIndex]['statewide-criminal-data-list'][catIndex]['profile-document-id'];

      if (this.isInviteEiaf) {
        let updatedFileName = selectedObject[grpIndex]['statewide-criminal-data-list'][catIndex]['file_desc'];
        selectedObject[grpIndex]['statewide-criminal-data-list'][catIndex]['file_name'] = updatedFileName;
      }
      replicateOptionalDocs = JSON.parse(JSON.stringify(selectedObject));
      this.optionalGroupData = replicateOptionalDocs;
    }
  }

  // Status bar update
  statusBarDocumentUploadMenustatusUpdate() {
    let flagstatus = this._statusBar.getCompletedMenustatus('documentupload-data-v1');

    if (!flagstatus) {
      this._statusBar.setstatusName('documentupload-data-v1');
      let statusBarobject = this._statusBar.getStatusBarValue('documentUpload');
      let remainingSection = statusBarobject['remainingsection'];
      let percentage = statusBarobject['percentage'];
      let remainingTime = statusBarobject['timeremaining'];

      let completedMenu = statusBarobject['completedMenu'];
      this.shareService.setStatusBar(remainingSection, percentage, remainingTime, completedMenu, this._eventBus);
    }
  }

  // Optional slide previous swipe
  onOptionalPrevSwipe() {
    let direction = 'left';

    if (!this.isInviteEiaf) {
      if (this.skippedDocumentsGroupData['skipped-documents-list'].length > 0) {
        this.currentSlide = this.documentUploadSteps[1] + '_';
        this.currentSlideWithDirection = this.currentSlide + direction;
      } else {
        this.currentSlide = this.documentUploadSteps[0] + '_';
        this.currentSlideWithDirection = this.currentSlide + direction;
      }
    } else {
      this._next('/home/eiaf-criminal-data');
    }
  }

  // Get group data based on slides
  getGroupData(): any {
    let slide = this.currentSlide.split('_')[0];
    let selectedGroupData;

    selectedGroupData = (slide === 'mandatoryDocs') ? this.mandatoryGroupData : this.optionalGroupData;

    return selectedGroupData;
  }

  closeUploadCompleteModal() {
    $('#upload_complete_modal').modal('hide');
  }

  noPdfModalClose() {
    $('#noPdfModal').modal('hide');
  }

  doSwipe(direction) {

  }

  getEiafFileName(currentFileName) {
    let newFileName = this.eiafileName.split('.')[0];

    if (!!newFileName) {
      return newFileName;
    }

    return currentFileName;
  }

  removeMiAttributes(obj: any) {
    // remove below propertied from obj. Other wise status give error from MI Service
    if (!!obj['miDocument']['status']) {
      delete obj['miDocument']['status']
    }
    if (!!obj['miDocument']['isUpload']) {
      delete obj['miDocument']['isUpload']
    }
    if (!!obj['miDocument']['action']) {
      delete obj['miDocument']['action']
    }
  }

  setDynamicCustomInstruction() {
    this.dynamicDocumentInstructionEvent.emit({ instruction: this.miDocument['instruction'] });
  }

  // trackUploadedFiles(multiFiles, id, profileDocId) {
  //   this.trackObj = {
  //     stage_name: 'document upload',
  //     action_value: 'uploaded files list'
  //   };

  //   let fileList = [];
  //   for (let i = 0; i < multiFiles.length; i++) {
  //     fileList.push(multiFiles[i]['docBaseId']);
  //   }

  //   window.track_event('id: ' + id + ', profile doc id: ' + profileDocId + ', doc ids: ' + fileList, this.trackObj, true, true);
  // }

  trackUploadedFiles(multiFiles, id, profileDocId) {
    this.trackObj = {
      stage_name: 'document upload',
      action_value: 'uploaded files list'
    };

    let fileList = [];
    for (let i = 0; i < multiFiles.length; i++) {
      fileList.push(multiFiles[i]['docBaseId']);
    }

    window.track_event('id: ' + id + ', profile doc id: ' + profileDocId + ', doc ids: ' + fileList, this.trackObj, true, true);
  }

  //Skipped documents Previous Swipe

  onSkippedPrevSwipe() {
    let direction = 'left';
    this.currentSlide = 'mandatoryDocs_';
    this.currentSlideWithDirection = 'mandatoryDocs' + direction;
  }

  prepareSkippedDocumentsList() {
    let category = '', statusCheck, groupCode = '', isSkippable = false;
    let documentGroupCodeList = ['100P', 'AF22', 'CRIM', 'NOSRHCD', 'NZPV'];

    let skippedDocs = {
      "skipped-documents-list": []
    };

    if (!!this.mandatoryGroupData) {
      for (let i = 0; i < this.mandatoryGroupData.length; i++) {
        for (let j = 0; j < this.mandatoryGroupData[i]['statewide-criminal-data-list'].length; j++) {
          category = this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['category'] !== undefined ? this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['category'].toUpperCase() : '';
          statusCheck = (!!this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['status'] && this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['status'].toUpperCase() === 'SUCCESS') ? true : false;
          groupCode = this.mandatoryGroupData[i]['group-code'] !== undefined ? this.mandatoryGroupData[i]['group-code'].toUpperCase() : '';
          isSkippable = (!!this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['skippable'] && this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['skippable']) ? true : false;

          if (documentGroupCodeList.indexOf(groupCode) < 0) {
            if (isSkippable === true && statusCheck === false) {
              skippedDocs['skipped-documents-list'].push(this.mandatoryGroupData[i]);
            }
          }
        }
      }
    }

    this.skippedDocumentsGroupData = skippedDocs;
    this.showSkippedDocSlide = (!!this.skippedDocumentsGroupData && this.skippedDocumentsGroupData['skipped-documents-list'].length > 0) ? true: false;
  }

  generateDocumentUploadForm(options): void {
    this.stateConfig = options['state'];

    this.documentUploadForm = this._fb.group({
      documentUploadSubForm: this._fb.array([
        this.initDocumentForm(options),
      ])
    });

    this.isFormInit = true;
  }

  initDocumentForm(options) {
    let futureDateToSubmitDoc = '';
    let reasonToSkipDocSubmission: any = '';

    if (options['future_date_to_submit_doc'] !== undefined && !this.isPendingInfoDueDateAvailable) {
      futureDateToSubmitDoc = options['future_date_to_submit_doc'];
    }

    if (options['reason_to_skip_doc_submission'] !== undefined) {
      reasonToSkipDocSubmission = options['reason_to_skip_doc_submission'];
    }

    if (this.isPendingInfoDueDateAvailable) {
      let frmCtrls = {
        reasonToSkipDocSubmission: [reasonToSkipDocSubmission, Validators.required]
      }
      return this._fb.group(frmCtrls);
    } else {
      let frmCtrls = {
        futureDateToSubmitDoc: [futureDateToSubmitDoc, Validators.required],
        reasonToSkipDocSubmission: [reasonToSkipDocSubmission, Validators.required]
      }
      return this._fb.group(frmCtrls);
    }
    
  }

  checkSSAForm(){
    let documentRuleSetId = '';
    const packageComponents = this.localStorage.getItem('package_components');
    let isSsbFormpresent = false;

    if(!!this.mandatoryGroupData && !!packageComponents && packageComponents.indexOf('CBSV') >= 0){
      for (let i = 0; i < this.mandatoryGroupData.length; i++) {
        for (let j = 0; j < this.mandatoryGroupData[i]['statewide-criminal-data-list'].length; j++) {
          documentRuleSetId = (this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['ruleset_id'] !== undefined && this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['ruleset_id'] !== '') ? this.mandatoryGroupData[i]['statewide-criminal-data-list'][j]['ruleset_id'] : '';

          if(documentRuleSetId === DocumentUploadConfig.CBSV_PACKAGE_RULESET_ID){
            isSsbFormpresent = true;
            break;
          }
        }
        if (isSsbFormpresent) {
          break;
        }
      }
    }

   return isSsbFormpresent;
  }

  onOptionalEmploymentModal(){
    $('#optionalDocumentModal').modal('show');
  }

  closeOptionalDocumentModal(){
    $('#optionalDocumentModal').modal('hide');
  }

  checkForPopupInOptionalDocs(res){
    let value = !!res['showOptionalPopup'] ? res['showOptionalPopup'] : '';
    
    if (value === "YES") {
      this.isOptionalEmploymentDocuments = true;
    }
    
    return this.isOptionalEmploymentDocuments;
  }
  
  onNextFromEnhancedDashboard() {
    this._documentUploadService.putEnhancedDashboardDocuments().subscribe(response => {
      if (response.status === 200) {
        this._next('/dashboard');
      }
    }, error => {
      console.log('PUT - next enhanced dashboard docs - failed')
    });
  }
  /* -------------------------- Private Methods - ENDS --------------------------------- */
}
