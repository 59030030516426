<div>
  <ul>
    <li *ngIf="!!viewProfileData['street_address'] && viewProfileData['address_line_2'] == undefined">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['street_address'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['street_address']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['street_address'] && !!viewProfileData['address_line_2']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['address_line_1'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['street_address']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['address_line_2']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['address_line_2'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['address_line_2']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['state_name']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['state_name'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['state_name']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['city']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['city'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['city']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['zip']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['zip'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['zip']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['country_name']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['country_name'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['country_name']}}</div>
      </div>
    </li>

    <li
      *ngIf="!!viewProfileData['valid_from'] && !(viewProfileData['is_permanent_address'] && viewProfileData['is_permanent_address'] === true)">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['valid_from'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['valid_from'] | dateFormat }}</div>
      </div>
    </li>

    <li *ngIf="!(viewProfileData['is_permanent_address'] && viewProfileData['is_permanent_address'] === true)">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type"> {{ profileKey['valid_to'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options" *ngIf="!!viewProfileData['valid_to']">
          {{viewProfileData['valid_to'] | dateFormat}}</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options" *ngIf="!viewProfileData['valid_to']"> Present</div>
      </div>
    </li>
  </ul>
</div>
