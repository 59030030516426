export class AppConstants {
    static DIRECTION_RIGHT = '_right';
    static DIRECTION_LEFT = '_left';
    static DOMESTIC_COUNTRY_CODE = 'US';
    static DEFAULT_ROWS = 'DEFAULT_ROWS';
    static threshHoldMsgTimeOut = 3000;
    static START_WAITING_INDICATION = 'START';
    static STOP_WAITING_INDICATION = 'STOP';
    static LANGUAGE_EN = 'EN';
    static EMAIL_REGEX = '^[_A-Za-z0-9-!@*#$&()=\\-`\'.+,/\]+(\\.[_A-Za-z0-9-!@#*$&()\\-=.+,/\]+)*@[_A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$';
    static INVITE_SUB_TYPE_KYP = 'KYP';
}
