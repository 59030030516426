import { Component, OnInit, Input, Output, EventEmitter, Renderer2, Inject, OnChanges, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { StatusbarService } from '../../../status-bar/status-bar.service';
import { AppConfig } from './../../../../app/app.config';
import { progressBarStatus } from './../../../../framework/progressbar.service';
import { fileExtensionValidation, fileSizeValidation, isUploadedFileImage } from './../../../../framework/utils/files.utils';
import { ImportDataService } from './../../external-data/import/import-data/import-data.service';
import { ViewChild } from '@angular/core';

// State
import { Store } from '@ngrx/store';
import * as ACTIONS_INTERFACE from './../../../actions';

declare var $: any;
let jsPDF = require('jspdf');

@Component({
  selector: 'multiple-file-upload',
  templateUrl: './multiple-file-upload.component.html',
  providers: [ImportDataService],
})


export class MultipleFileUploadComponent {
  static uploadedFilesList: any[] = [];
  static totalFileSize: number = 0;
  @ViewChild('myInput', { static: true }) myInputVariable: any;

  @Input() inputUploadNextClick: boolean;
  @Input() resetList: any;
  @Input() inputResetUploadedFile: any;
  @Output() multipleFileOutputEmit: EventEmitter<any> = new EventEmitter();
  @Output() multipleFileValidationErrorEmit: EventEmitter<any> = new EventEmitter();
  @Output() toggleNextButton: EventEmitter<any> = new EventEmitter();

  uploadImagesList: any[] = [];
  order: any[] = [];
  temp: any[] = [];

  activeColor: string = 'green';
  baseColor: string = '#ccc';
  overlayColor: string = 'rgba(255,255,255,0.5)';
  dragging: boolean = false;
  loaded: boolean = false;
  imgFileCount: number = 0;
  fileExtOutput: boolean;
  fileSizeOutput: boolean;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['inputUploadNextClick'] !== undefined && changes['inputUploadNextClick'].currentValue !== undefined) {
      // this.multipleFileOutputEmit.emit({fileExt: this.fileExtOutput, fileSize: this.fileSizeOutput, files: MultipleFileUploadComponent.uploadedFilesList});
      this.multipleFileOutputEmit.emit({ files: MultipleFileUploadComponent.uploadedFilesList });
      // MultipleFileUploadComponent.totalFileSize = 0;
    } else if (changes['resetList'] !== undefined && changes['resetList'].currentValue !== undefined) {
      this.imgFileCount = 0;
      this.uploadImagesList = [];
      MultipleFileUploadComponent.uploadedFilesList = [];
      MultipleFileUploadComponent.totalFileSize = 0;
      this.toggleNextButton.emit({ val: false });
    }

    if (changes['inputResetUploadedFile'] !== undefined && changes['inputResetUploadedFile'].currentValue !== '') {
      this.reset();
    }
  }

  // This method for Re-arrange Documents
  orderChanged(orderData) {
    this.temp = [];
    let i = 0;
    for (let key of orderData) {
      this.temp[i] = this.uploadImagesList[key];
      i = i + 1;
    }

    // update Re-arrange & Emitting Array
    this.uploadImagesList = [...this.temp];
    MultipleFileUploadComponent.uploadedFilesList = [...this.temp];
    this.order = [...this.temp];
  }

  handleDragEnter() {
    this.dragging = true;
  }

  handleDragLeave() {
    this.dragging = false;
  }

  handleDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange(e);
  }

  // Do not delete function temperory solution image converted into pdf
  /*handleInputChange(e) {
    let files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    let reader = new FileReader();

    if (files === undefined || files.length === 0) {
      return;
    }

    this.fileExtOutput = fileExtensionValidation(files);
    // this.fileSizeOutput = fileSizeValidation(files);
    MultipleFileUploadComponent.totalFileSize = MultipleFileUploadComponent.totalFileSize + files[0].size;

    this.fileSizeOutput = (MultipleFileUploadComponent.totalFileSize < 10000000) ? true : false;

    if (this.fileExtOutput && this.fileSizeOutput) {
      let val = isUploadedFileImage(files[0]);
      let imgId = '#preview-' + this.imgFileCount;
      let processedData;

      if (val) {
        processedData = this.convertImageToPdf(files[0], imgId);
      } else {
        let imageData = files[0];

        imageData['id'] = imgId;
        imageData['src'] = '../../../../assets/img/pdf_sample.jpg';

        this.uploadImagesList.push(imageData);
        MultipleFileUploadComponent.uploadedFilesList.push(imageData);

        imageData = {};
        this.imgFileCount = this.imgFileCount + 1;

        // this.multipleFileOutputEmit.emit({fileExt: fileExtOutput, fileSize: fileSizeOutput, files: files});
        this.loaded = false;
      }
    } else if (!this.fileExtOutput) {
      // $('#file_ext_modal').modal('show');
      MultipleFileUploadComponent.totalFileSize = MultipleFileUploadComponent.totalFileSize - files[0].size;
      this.multipleFileValidationErrorEmit.emit({ fileExtError: true, fileSizeError: false });
    } else if (!this.fileSizeOutput) {
      // $('#file_size_modal').modal('show');
      MultipleFileUploadComponent.totalFileSize = MultipleFileUploadComponent.totalFileSize - files[0].size;
      this.multipleFileValidationErrorEmit.emit({ fileExtError: false, fileSizeError: true });
    }
  } */

  _handleReaderLoaded(e) {
    let reader = e.target;
    this.loaded = true;
  }

  onDeleteFile(remoteId) {
    // console.log(remoteId);
    let index;

    for (let ind in this.uploadImagesList) {
      if (this.uploadImagesList[ind]['id'] === remoteId) {
        index = ind;
      }
    }
    this.uploadImagesList.splice(index, 1);
    // MultipleFileUploadComponent.uploadedFilesList = [];
    MultipleFileUploadComponent.uploadedFilesList.splice(index, 1); // = this.uploadImagesList;

    // update Re-arrange Array
    this.order = [...this.uploadImagesList];

    if (MultipleFileUploadComponent.uploadedFilesList.length === 0) {
      this.toggleNextButton.emit({ val: false });
    }
    this.reset();
  }

  convertImageToPdf(inputFile, file_name): any {
    let doc = new jsPDF();
    let reader = new FileReader();
    let base64Image;
    let imageData;
    let that = this;

    reader.onloadend = function () {
      base64Image = reader.result;

      doc.addImage(base64Image, 'JPEG', 5, 5, 160, 120);
      let pdf = doc.output('blob');
      let file = new File([pdf], inputFile.name, { type: 'application/pdf', lastModified: Date.now() });

      imageData = file;

      imageData['id'] = file_name;
      imageData['src'] = '../../../../assets/img/pdf_sample.jpg';

      that.uploadImagesList.push(imageData);
      MultipleFileUploadComponent.uploadedFilesList.push(imageData);

      imageData = {};
      that.imgFileCount = that.imgFileCount + 1;

      // this.multipleFileOutputEmit.emit({fileExt: fileExtOutput, fileSize: fileSizeOutput, files: files});
      that.loaded = false;
    };

    reader.readAsDataURL(inputFile);
  }

  handleInputChange(e) {
    let files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    // let reader = new FileReader();

    if (files === undefined || files.length === 0) {
      return;
    } else {
      this.toggleNextButton.emit({ val: true });
    }

    this.fileExtOutput = fileExtensionValidation(files);

    if (this.fileExtOutput) {
      this.validateFileMIMEType(files);
    } else {
      this.afterFileValidation(files);
    }
    // this.fileSizeOutput = fileSizeValidation(files);
  }

  reset() {
    this.myInputVariable.nativeElement.value = '';
  }

  ngOnDestroy() {
    MultipleFileUploadComponent.uploadedFilesList = [];
  }
  
  validateFileMIMEType(files: any) {
    var blob = files[0].slice(0, 4);
    var fileReader = new FileReader();
    var header = "";
    
    fileReader.onloadend = e => {
      var arrayBuffer = fileReader.result as ArrayBuffer;
      var uInt = new Uint8Array(arrayBuffer); 
      let bytes = []
      uInt.forEach((byte) => {
          bytes.push(byte.toString(16))
      });
      header = bytes.join('').toLowerCase();
        
      switch (header) {
        case "89504e47":
        case '25504446':
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
          this.fileExtOutput = true;
          break;
        default:
          this.fileExtOutput = false;
          break;
      }
      this.afterFileValidation(files);
    };
    fileReader.readAsArrayBuffer(blob);
  }


  afterFileValidation (files: any) {
    let reader = new FileReader();
    MultipleFileUploadComponent.totalFileSize = MultipleFileUploadComponent.totalFileSize + files[0].size;
    let isUploadedFileAnImage = isUploadedFileImage(files[0]);
    this.fileSizeOutput = MultipleFileUploadComponent.totalFileSize > 10000000 ? false : true;

    if (this.fileExtOutput && this.fileSizeOutput) {
      let imageData = files[0];
      let imgId = '#preview-' + this.imgFileCount;
      let that = this;

      if (isUploadedFileAnImage) {
        reader.onloadend = function () {
          let base64Img = reader.result;

          imageData['id'] = imgId;
          imageData['src'] = base64Img;
          imageData['base64Image'] = base64Img;

          that.uploadImagesList.push(imageData);
          MultipleFileUploadComponent.uploadedFilesList.push(imageData);

          imageData = {};
          that.imgFileCount = that.imgFileCount + 1;

          // update Re-arrange Array
          that.order = [...that.uploadImagesList];

          that.loaded = false;
        };

        reader.readAsDataURL(files[0]);
      } else {
        imageData['id'] = imgId;
        imageData['src'] = '../../../../assets/img/pdf.svg';

        reader.onloadend = function () {
          let base64Img = reader.result;

          imageData['id'] = imgId;
          imageData['base64Image'] = base64Img;

          that.uploadImagesList.push(imageData);
          MultipleFileUploadComponent.uploadedFilesList.push(imageData);

          imageData = {};
          that.imgFileCount = that.imgFileCount + 1;

          // update Re-arrange Array
          that.order = [...that.uploadImagesList];

          that.loaded = false;
        };
        reader.readAsDataURL(files[0]);
      }
    } else if (!this.fileExtOutput) {
      // $('#file_ext_modal').modal('show');
      MultipleFileUploadComponent.totalFileSize = MultipleFileUploadComponent.totalFileSize - files[0].size;
      this.multipleFileValidationErrorEmit.emit({ fileExtError: true, fileSizeError: false });
    } else if (!this.fileSizeOutput) {
      // $('#file_size_modal').modal('show');
      MultipleFileUploadComponent.totalFileSize = MultipleFileUploadComponent.totalFileSize - files[0].size;
      this.multipleFileValidationErrorEmit.emit({ fileExtError: false, fileSizeError: true });
    }
  }

}
