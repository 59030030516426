<div class="modal fade custom_modal" id="plsWaitOnPreAuthModal" tabindex="-1" role="Application" aria-labelledby="preAuthWaitStatus" data-backdrop="static"
  data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="transparentbg modal-body m-t-15">
        <h4 class="modal-title" id="loginWaitStatus">{{'WAIT_ON_AGGREMENT_MODAL_TEXT' | translate }}
        </h4>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-xs-12 col-sm-4 col-md-4 p_l_0  p_r_0 m-r-15 ">
            <!-- <button type="button" class="btn button font_extrabold" data-dismiss="modal" data-toggle="resetPassword-modal" (click)="goToSignin()">CONTINUE</button>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade custom_modal" id="plsWaitOnPreAuthRegModal" tabindex="-1" role="Application" aria-labelledby="preAuthRegWaitStatus" data-backdrop="static"
  data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="transparentbg modal-body m-t-15">
        <h4 class="modal-title" id="loginWaitStatus">{{'WAIT_ON_AGGREMENT_MODAL_TEXT' | translate }}
        </h4>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-xs-12 col-sm-4 col-md-4 p_l_0  p_r_0 m-r-15 ">
            <!-- <button type="button" class="btn button font_extrabold" data-dismiss="modal" data-toggle="resetPassword-modal" (click)="goToSignin()">CONTINUE</button>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
