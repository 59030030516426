export function fileExtensionValidation(files: any): boolean {
    let retVal: boolean = true;

    for (let i = 0; i < files.length; i++) {
        let fileName = files[i].name.split('.').pop().toLowerCase();
        if ($.inArray(fileName, ['png', 'jpg', 'jpeg', 'pdf']) === -1) {
            // This means the input file is not compliant to the above mentioned file extensions
            retVal = false;
        }
    }

    // Return false if any one of the file is not compliant to the mentioned file extensions
    return  retVal;
}

export function fileSizeValidation(files: any): boolean {
    let totalSize: number = 0;

    for (let i = 0; i < files.length; i++) {
        let fileSize = files[i].size;
        totalSize = totalSize + fileSize;
    }

    // Total size of all files cannot exceed 10mb
    return (totalSize >= 10000000) ? false : true;
}

export function isUploadedFileImage(file: any): boolean {
    let retVal: boolean = true;
    let fileName = file.name.split('.').pop().toLowerCase();

    if ($.inArray(fileName, ['png', 'jpg', 'jpeg']) === -1) {
        // This means the input file is not compliant to the above mentioned file extensions
        retVal = false;
    }

    return retVal;
}
