import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../app.config';
import { WorkflowService } from '../../framework/workflow.service';
import { LocalStorageService } from '../../framework/localstorage.service';
import { MyDataService } from './../my-data/my-data.service';
import * as dateUtils from '../../framework/utils/date.utils';
import { SharedService } from '../shared/services/shared.service';
import { Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { SharedConfig } from 'app/shared/shared.config';
import { EmploymentConfig } from 'app/employment/employment.config';
import { ProfessionalLicenseService } from 'app/professional-license/professional-license.service';
import { ProfessionalLicenseConfig } from 'app/professional-license/professional-license.config';

interface moveinandmoveoutdates {
    moveindate: String;
    moveoutdate: String;
};
interface MoveinAndMoveoutGap {
    gapStartDate: String;
    gapEndDate: String;
    gap: number;
}
@Injectable()
export class ClipboardService {
    workflow_instance_id: any;
    residentialGapDataConfig: Object;
    employmentDataConfig: Object;
    referenceScopeConfig: Object;
    professionalLicenseConfig: Object;
    private clipboardMenus: string[] = ['RESIDENCE', 'EMPLOYMENT', 'EDUCATION', 'LICENSE'];
    monthNames: any = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    nextURL: string;
    nextDisplayname: string;

    private moveinandout: moveinandmoveoutdates[] = [];
    private gapLimit: number;
    private noGapEndDate: Date;

    /* addNewButtonNotified = new Subject<any>();
    addNewButtonNotified$ = this.addNewButtonNotified.asObservable();  */
    headerOptions: any;
    isDeleteComplete: boolean = false;
    residentialDataConfig: Object;
    personalDataWorkflowConfig: Object;
    mvrDataConfig: Object;
    educationDatConfig: Object;
    clipboardConfig:Object;
    
    constructor(private _authHttp: HttpClient,
        private _workflow: WorkflowService,
        private localStorage: LocalStorageService,
        private _sharedService: SharedService) {
        this.workflow_instance_id = this.localStorage.getItem('workflow_instance_id');
        this.nextURL = this._workflow.getNextURL('reviewedit-data-v1');
        this.nextDisplayname = this._workflow.getNextMenuDisplayName('reviewedit-data-v1');
        this.residentialGapDataConfig = this._workflow.getStepConfig('residential-data-v1', 0);
        this.referenceScopeConfig = this._workflow.getStepConfig('reference-data-v1', 0);
        this.employmentDataConfig = this._workflow.getStepConfig('employment-data-v1', 0);
        this.professionalLicenseConfig = this._workflow.getStepConfig('licence-data-v1', 0);
        this.headerOptions = this._workflow.getHeaderOptions();
        this.residentialDataConfig = this._workflow.getStepConfig('residential-data-v1', 0);
        this.personalDataWorkflowConfig = this._workflow.getStepConfig('identification-data-v1', 0);
        this.mvrDataConfig = this._workflow.getStepConfig('mvr-data-v1', 0);
        this.educationDatConfig = this._workflow.getStepConfig('education-data-v1', 0);
        this.clipboardConfig = this._workflow.getStepConfig('reviewedit-data-v1', 0);
    }
    howLong: Object = {
        year: 7
    };

    getNextURL(): string {
        return this.nextURL;
    }

    getNextDisplayName() {
        return this.nextDisplayname;
    }

    getUserDetails: any = {
        firstname: "Carl1"
    };

    getClipboardMenus(): string[] {
        return this.clipboardMenus;
    }

    refreshNextUrl(){
        this.nextURL = this._workflow.getNextURL('reviewedit-data-v1');
        this.nextDisplayname = this._workflow.getNextMenuDisplayName('reviewedit-data-v1');
    }
    payLoadUpdate(body, step): Observable<any> {
        return this._authHttp.put(AppConfig.API_ENDPOINT() + '/api/v1/workflow/' + this.workflow_instance_id + '/' + step, body, this.headerOptions)
        .pipe(
            map(res => this._extractData(res)),
            map(res => this._doAction(res)),
            catchError(err => this._handleError(err, 'payload_put_failed'))
        );
    }

    deleteData(card): Observable<any> {
        console.log(card);
        let recId = card['childKey'] == 'alias-data-list' ? card['alias-data-list']['id'] : card['id'];
        let step = card['key'];
        step = step.split("-", 1);

        // formatting so that "name-step" is required for delete api call
        step = (step == "personal" ? "name" : step) + '-step';

        return this._authHttp.delete(AppConfig.API_ENDPOINT() + '/api/v1/workflow/' + this.workflow_instance_id + '/' + step + '/' + recId, this.headerOptions)
        .pipe(
            map(res => this._extractData(res)),
            map(res => this._doAction(res)),
            catchError(err => this._handleError(err, 'clipboard_delete_failed'))
        );
    }

    getData(): Observable<any> {
        return this._authHttp.get(AppConfig.API_ENDPOINT() + '/api/v1/workflow/' + this.workflow_instance_id + '/clipboard' + '/?t=' + new Date(), this.headerOptions)
        .pipe(
            map(res => this._extractData(res)),
            map(res => this._doAction(res)),
            catchError(err => this._handleError(err, 'clipboard_get_failed'))
        );
    }

    getAddressData(): Observable<any> {
        let action = this.residentialDataConfig['action'];

        return this._authHttp.get(AppConfig.API_ENDPOINT() + action + '/?t=' + new Date(), this.headerOptions)
        .pipe(
            map(res => this._extractData(res)),
            map(res => this._doAction(res)),
            catchError(err => this._handleError(err, 'clipboard_address_get_failed'))
        );
    }

    saveClipboard(body): Observable<any> {

        return this._authHttp.put(AppConfig.API_ENDPOINT() + '/api/v1/workflow/' + this.workflow_instance_id + '/clipboard', body, this.headerOptions)
        .pipe(
            map(res => this._extractData(res)),
            map(res => this._doAction(res)),
            catchError(err => this._handleError(err, 'clipboard_put_failed'))
        );
    }

    getEmploymentScope() {
        if (this.employmentDataConfig['scopes'] && this.employmentDataConfig['scopes'][0]) {
            return false;
        } else {
            return true;
        }
    }

    private _extractData(res: any) {
        return res;
    }

    private _doAction(response: any) {
        return response;
    }

    private _handleError(error: any, eventName?) {
        let _body = error || {};
        let errorMessage: string;

        console['server'](eventName, error);

        switch (error.status) {
            case 400:
            case 401:
                //Bad request, Invalid Credentials - login invalid
                errorMessage = _body['error']['error'] || 'Invalid Login';
                break;
            case 404:
                errorMessage = _body['error']['error'];
                break;
            case 412:
                errorMessage = "412";
                break;

        }
        return throwError(errorMessage);
    }

    getDateFormat(date: string) {
        let dte = date.replace(/-/g, '/');
        let dt = new Date(dte);
        let dateformat = this.monthNames[dt.getMonth()] + ' ' + dt.getFullYear();
        return dateformat;
    }

    monthDiffOfDates(date1, date2): number {
        let months: number;
        let d2: any = new Date(date2);
        let d1: any = new Date(date1);
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= parseInt(d1.getMonth(), 10) + 1;
        months += parseInt(d2.getMonth(), 10) + 1;
        console.log(months);
        return months <= 0 ? months : months;
    }

    monthDiff(date) {
        var dateMonthYear = new Date(date.split("-")[0] + "/" + date.split("-")[1] + "/01");
        let months: any;
        let d2: any = new Date();
        let d1: any = new Date(dateMonthYear);
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= parseInt(d1.getMonth(), 10) + 1;
        months += parseInt(d2.getMonth(), 10) + 1;
        console.log(months);
        return months <= 0 ? 0 : months;
    }

    get2D(num) {
        if (num.toString().length < 2) {
            return "0" + num; // Prepend a zero!
        } else {
            return num.toString(); // return string for consistency
        }
    }

    dateFormatYyyyMmDd(date) {
        //let monthNames: any = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        let monthNo = (this.monthNames.indexOf(date.split(" ")[0]) + 1).toString();
        let formatedDate = new Date(date.split(" ")[1] + "/" + monthNo + "/01");
        let dateObj: any = new Date(formatedDate);
        let year: any = dateObj.getFullYear();
        let dd: any = parseInt(dateObj.getDate(), 10);
        let month: any = (parseInt(dateObj.getMonth(), 10) + 1);
        month = (month <= 9) ? "0" + month : month;
        dd = (dd <= 9) ? "0" + dd : dd;
        let toDateFormat = year + "/" + month + "/" + dd;
        return toDateFormat;
    }

    getGapDataReviewedit(): Observable<any> {
        let action = '/api/v1/workflow/' + this.workflow_instance_id + '/address-step';
        let reviewedit = '/?mode=RE';

        return this._authHttp.get(AppConfig.API_ENDPOINT() + action + '/validate' + reviewedit + '&t=' + new Date(), this.headerOptions)
        .pipe(
            map(res => this._extractData(res)),
            map(res => this._doAction(res)),
            catchError(err => this._handleError(err, 'clipboard_address_get_gap_re_failed'))
        );
    }

    getGapDataRevieweditEmployment(): Observable<any> {
        let action = '/api/v1/workflow/' + this.workflow_instance_id + '/employment-step';
        let reviewedit = '/?mode=RE';

        return this._authHttp.get(AppConfig.API_ENDPOINT() + action + '/validate' + reviewedit + '&t=' + new Date(), this.headerOptions)
        .pipe(
            map(res => this._extractData(res)),
            map(res => this._doAction(res)),
            catchError(err => this._handleError(err, 'clipboard_emp_get_gap_re_failed'))
        );
    }

    getCandidateAgeBasedScope(): Observable<any> {
        let action = '/api/v1/workflow/' + this.workflow_instance_id + '/candidate-age-based-scope';
        return this._authHttp.get(AppConfig.API_ENDPOINT() + action + '/?t=' + new Date(), this.headerOptions)
            .pipe(
                map(res => this._extractData(res)),
                map(res => this._doAction(res)),
                catchError(err => this._handleError(err, 'clipboard_emp_dob_get_failed'))
            );
    }

    getGapDataRevieweditAddress(): Observable<any> {
        let action = '/api/v1/workflow/' + this.workflow_instance_id + '/address-step';
        let reviewedit = '/?mode=RE';

        return this._authHttp.get(AppConfig.API_ENDPOINT() + action + '/validate' + reviewedit + '&t=' + new Date(), this.headerOptions)
        .pipe(
            map(res => this._extractData(res)),
            map(res => this._doAction(res)),
            catchError(err => this._handleError(err, 'clipboard_address_get_gap_re_failed'))
        );
    }

    setAliasDataForClipboard() {
        let noAlias: boolean = true;
        this.emptyAliasArray();
        if (this._sharedService.getAliasArray().length == 0) {
            this.getData().subscribe(response => {
                let data: any = response;
                for (let key in data) {
                    let obj = {};
                    if (key == 'alias-data-list' && data[key] && data[key].length > 0) {
                        noAlias = false;
                        data[key].forEach(element => {
                            let obj = {
                                "id": element['id'],
                                "startDate": element['start-date'],
                                "endDate": element['end-date'],
                                "firstname": element['first_name'],
                                "lastname": element['last_name']
                            }
                            this._sharedService.setAliasArray(obj);
                        });
                        break;
                    }
                }
                if (noAlias) {
                    this.emptyAliasArray();
                }
            })
        }
    }

    private emptyAliasArray() {
        let objEmpty = {};
        objEmpty['emptyArray'] = true;
        this._sharedService.setMinDate(objEmpty);
        this._sharedService.setAliasArray(objEmpty);
    }

    getIdentificationData(): Observable<any> {
        return this._authHttp.get(AppConfig.API_ENDPOINT() + '/api/v1/workflow/' + this.workflow_instance_id + '/identification-step' + '/?t=' + new Date(), this.headerOptions)
        .pipe(
            map(this._extractData),
            map(this._doAction),
            catchError(err => this._handleError(err, 'clipboard_identity_get_failed'))
        );
    }

    /**
     * Function to get masking enabled or not
     * 
     * @returns boolean
     */
    isMaskingEnabled(): boolean {
        let value = this._sharedService.getEaPreferenceValueByKey(this.clipboardConfig['ea-preferences'], SharedConfig.DATA_MASK_PREFERENCE);
        if (!!value && value.toUpperCase() == "YES") {
            return true;
        }

        return false;
    }

    /**
   * check employment gap in days is enabled or not
   * 
   * @returns 
   */
   isEmploymentGapInDaysEnabled() {
    let isEmpGapInDaysEnabled = false;
    let value = this._sharedService.getEaPreferenceValueByKey(this.employmentDataConfig['ea-preferences'], EmploymentConfig.EMPLOYMENT_GAP_IN_DAYS);
    var pattern = new RegExp('^[0-9]+$');
    if (!!value && pattern.test(value)) {
      value = Number(value);
      if (!isNaN(value) && value > 0) {
        isEmpGapInDaysEnabled = true;
      }
    }
    return isEmpGapInDaysEnabled;
  }

  /**
   * Function to get maximum elements of professional license
   * 
   * @returns 
   */
  getProfessionalLicenseMaximumLimit() {
    let value = this._sharedService.getEaPreferenceValueByKey(this.professionalLicenseConfig['ea-preferences'], ProfessionalLicenseConfig.PROF_LICENSE_MAX_ELEMENT_PREFERENCE);
    return !value || String(value).toUpperCase() === 'NO' || value <= 0 ? null : value;
  }
    
}
