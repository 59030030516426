import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  FormArray,
  FormBuilder,
  Validators
} from "@angular/forms";
import { EmploymentConfig } from "../employment.config";
import { Store } from "@ngrx/store";
import * as ACTIONS_INTERFACE from '../../actions';
import { BehaviorSubject } from 'rxjs';

import { EventBusService } from '../../../framework/eventbus.service';


declare var google: any;
declare var jQuery: any;

@Component({
  selector: 'intl-employment',
  templateUrl: './intl-employement.component.html',
  styleUrls: ['./intl-employement.component.css']
})
export class IntlEmployementComponent implements OnInit {

  // _data = new BehaviorSubject<any>([]);
  _data = new BehaviorSubject<any>([]);
  _datadept = new BehaviorSubject<any>([]);
  _dataAbaCbc = new BehaviorSubject<any>([]);
  _dataLocal = new BehaviorSubject<any>([]);

  @Input("group") public empSubForm: FormGroup;

  @Input() row: any;
  @Input() userDetails: any;
  @Input() empType: string;
  @Input() configOptions: any;
  @Input() clipboardOptions: any;
  @Input() isReviewAdd: boolean;
  @Input() isReviewEdit: boolean;
  @Input() options: any;
  @Input() selfEmploymentPreferenceEnabled: boolean;
  @Input() jobTypes: Object[];

  // @Input() isINTLEmployeeId: boolean;
  //@Input() isINTLDepartment: boolean;

  @Input()
  set isINTLEmployeeId(value) {
    this._data.next(value);
  };

  get isINTLEmployeeId() {
    return this._data.getValue();
  }

  @Input()
  set isINTLDepartment(value) {
    this._datadept.next(value);
  };

  get isINTLDepartment() {
    return this._datadept.getValue();
  }

  @Input()
  set isINTLCompNameInLocal(value) {
    this._dataLocal.next(value);
  };

  get isINTLCompNameInLocal() {
    return this._dataLocal.getValue();
  }

  @Input()
  set intlAbaCbcArr(value) {
    this._dataAbaCbc.next(value);
  };

  get intlAbaCbcArr() {
    return this._dataAbaCbc.getValue();
  }


  @Output() onGoToProfileBuilder: EventEmitter<any> = new EventEmitter();
  @Output() onINTLAdditional: EventEmitter<any> = new EventEmitter();
  @Output() onINTLAdditionalSwipe: EventEmitter<any> = new EventEmitter();

  isViewInit;

  static STAGE_ID: string;
  static STEP_ID: string;
  static STEP_INDEX: number;
  public SUB_STEP_ID: string;
  public SUB_STEP_INDEX: number;
  PARENT_STAGE_STEP_CONFIG: Object;

  static BUTTON_CLICKED: string = "CLICKED_SAVE_EXIT_BUTTON";
  currentStatus: string = "VISITED";
  payLoad: Object;
  startTime: any;
  isReview: boolean = false;
  isValidEmployeeId: boolean = false;
  isValidDepartment: boolean = false;
  isValidCompNameInLocal: boolean = false;
  isValidButton: boolean = false;
  enableNxtButton: boolean = false;
  isIntlempID: boolean = false;
  isIntlDept: boolean = false;
  isAbaCbcEnabled: boolean = false;
  isIntllocalFlag: boolean = false;
  selectedJobType: string;

  constructor(
    private _eventBus: EventBusService, // Auto Generated PPA-8745 - Arun Supreet for Save n Exit Patch
    private _store: Store<ACTIONS_INTERFACE.AppState>) {
    this.startTime = new Date().getTime();
  }

  ngOnInit(): void {
    this.isViewInit = false;
    if (!!this.isReviewAdd || !!this.isReviewEdit) {
      this.isReview = true;
    }
    this._data.subscribe(x => {
      this.isIntlempID = x;
    });

    this._datadept.subscribe(x => {
      this.isIntlDept = x;
    });

    this._dataLocal.subscribe(x => {
      this.isIntllocalFlag = x;
    });

    this._dataAbaCbc.subscribe(x => {
      this.isAbaCbcEnabled = x;
    });

    this.setFormFields();
  }


  ngAfterViewInit(): void {
    this.processConfig();
    this.isViewInit = true;
    this.setEmpTypeControl();
    // this.validateEmployeeId(this.empSubForm.value);
    // this.validateDepartment(this.empSubForm.value);
    this._data.subscribe(x => {
      this.isIntlempID = x;
    });

    this._datadept.subscribe(x => {
      this.isIntlDept = x;
    });

    this._dataLocal.subscribe(x => {
      this.isIntllocalFlag = x;
    });

    this._dataAbaCbc.subscribe(x => {
      this.isAbaCbcEnabled = x;
    });

    this.setFormFields();
  }

  private processConfig(): void {
    this.PARENT_STAGE_STEP_CONFIG = this.configOptions["PARENT_CONFIG"];
    this.SUB_STEP_ID =
      EmploymentConfig.subStepIndexMapping["intl_emp"][
      "subStep"
      ];
    this.SUB_STEP_INDEX =
      EmploymentConfig.subStepIndexMapping["intl_emp"][
      "subStepIndex"
      ];

    this.setState();
  }

  private setState(): void {
    this.payLoad = {
      name: this.PARENT_STAGE_STEP_CONFIG["STAGE_ID"],
      step: this.PARENT_STAGE_STEP_CONFIG["STEP_ID"],
      stepIndex: this.PARENT_STAGE_STEP_CONFIG["STEP_INDEX"],
      subStep: this.SUB_STEP_ID,
      subStepIndex: this.SUB_STEP_INDEX,
      iteration: this.row,
      status: this.currentStatus,
      dbRecordPresent: this.PARENT_STAGE_STEP_CONFIG["IS_DB_RECORD_PRESENT"]
    };
    this._store.dispatch({ type: this.currentStatus, payload: this.payLoad });
  }

  ngOnChanges() {
    if (this.isViewInit && this.empType === "prior") {
      this.setEmpTypeControl();
    }
    this._data.subscribe(x => {
      this.isIntlempID = x;
    });

    this._datadept.subscribe(x => {
      this.isIntlDept = x;
    });

    this._dataLocal.subscribe(x => {
      this.isIntllocalFlag = x;
    });

    this._dataAbaCbc.subscribe(x => {
      this.isAbaCbcEnabled = x;
    });

    this.setFormFields();
  }

  private setEmpTypeControl() {
    (<FormControl>this.empSubForm.controls["type"]).setValue(this.empType);
  }

  doSwipe(direction: string, data) {
    // alert(direction);
    if (direction === "swiperight") {
      this.getPrevSwipe(data);
    }
    if (direction === "swipeleft") {
      if (!!data["managerName"]) {
        this.INTLAdditional(data);
      }
    }
  }

  getPrevSwipe(data) {
    let indexDetail: Object = {};
    indexDetail["row"] = this.row;
    data["indexDetail"] = indexDetail;
    this.onINTLAdditionalSwipe.emit(data);
  }

  saveExit(obj) {
    // PPA-8745 - Auto-generated via regex - Arun, Supreet, Save n Exit Patch
    var re = this._eventBus.logoutAnnounced({ dummy: "workflow_exit" });
    if (re == undefined) return;
  }

  
  INTLAdditional(obj): void {
    /*
    *
    * User has submitted the form using 'NEXT' button, then current status should be changed to 'COMPLETED'.
    * If user has submitted the form using 'SAVE & EXIT' button, the current status has already been
    * changed to 'PENDING' in saveExit()
    *
    * */
    if (this.currentStatus === "VISITED") {
      this.currentStatus = "COMPLETED";
    }

    //Update the state
    this.setState();

    let indexDetail: Object = {};
    indexDetail["row"] = this.row;
    obj["indexDetail"] = indexDetail;
    //pass the state to server
    obj["COMPONENT_STATE"] = this.payLoad;
    obj["startTime"] = this.startTime;
    this.onINTLAdditional.emit(obj);
  }

  private goToProfileBuilder() {
    this.onGoToProfileBuilder.emit();
  }

  validateEmployeeId(value) {
    let input = value['employeeId'];
    if ((input != '' && input !== undefined) || this.isAbaCbcEnabled) {
      this.isValidEmployeeId = true;
    } else {
      this.isValidEmployeeId = false;
    }

    this.enableNextButton();
  }

  validateDepartment(value) {
    let input = value['department'];
    if (input != '' && input !== undefined) {
      this.isValidDepartment = true;
    } else {
      this.isValidDepartment = false;
    }
    this.enableNextButton();
  }

  validateNameInLocalLang(value) {
    let input = value['companyNameInLocalLang'];
    if (input != '' && input !== undefined) {
      this.isValidCompNameInLocal = true;
    } else {
      this.isValidCompNameInLocal = false;
    }
    this.enableNextButton();
  }

  private enableNextButton() {
    if (this.isIntlempID && this.isIntlDept && this.isIntllocalFlag) {
      if (this.isValidEmployeeId && this.isValidDepartment && this.isValidCompNameInLocal) {
        this.isValidButton = true;
      } else {
        this.isValidButton = false;
      }
    } else if (!this.isIntlempID && this.isIntlDept && this.isIntllocalFlag) {
        if (this.isValidDepartment && this.isValidCompNameInLocal) {
          this.isValidButton = true;
        } else {
          this.isValidButton = false;
        }
    } else if (this.isIntlempID && !this.isIntlDept && this.isIntllocalFlag) {
      if (this.isValidEmployeeId && this.isValidCompNameInLocal) {
        this.isValidButton = true;
      } else {
        this.isValidButton = false;
      }
    }else if (this.isIntlempID && this.isIntlDept && !this.isIntllocalFlag) {
      if (this.isValidEmployeeId && this.isValidDepartment) {
        this.isValidButton = true;
      } else {
        this.isValidButton = false;
      }
    }
    else if (this.isIntlempID && !this.isIntlDept && !this.isIntllocalFlag) {
      if (this.isValidEmployeeId) {
        this.isValidButton = true;
      } else {
        this.isValidButton = false;
      }
    } else if (!this.isIntlempID && this.isIntlDept && !this.isIntllocalFlag) {
      if (this.isValidDepartment) {
        this.isValidButton = true;
      } else {
        this.isValidButton = false;
      }
    } else if (!this.isIntlempID && !this.isIntlDept && this.isIntllocalFlag) {
      if (this.isValidCompNameInLocal) {
        this.isValidButton = true;
      } else {
        this.isValidButton = false;
      }
    } else {
      this.isValidButton = false;
    }

    if (this.isValidButton || (!this.isIntlempID && !this.isIntlDept && !this.isINTLCompNameInLocal)) {
      if (this.selfEmploymentPreferenceEnabled && !!this.empType && (this.empType === 'current' || this.empType === 'prior')) {
        if (!!this.selectedJobType) {
          this.isValidButton = true;
        } else {
          this.isValidButton = false;
        }
      }
    }
  }

  selectJobType(val) {
    this.selectedJobType = val;
    (<FormControl>this.empSubForm.controls['jobType']).setValue(val);
    this._eventBus.jobTypeSelectedAnnounced(val);
    this.enableNextButton();
  }

  /**
   * set and validates form fields
   */
  private setFormFields() {
    if (this.empSubForm.controls['department'] !== undefined) {
      this.validateDepartment(this.empSubForm.value);
    }

    if (this.empSubForm.controls['employeeId'] !== undefined) {
      this.validateEmployeeId(this.empSubForm.value);
    }

    if (this.empSubForm.controls['companyNameInLocalLang'] !== undefined) {
      this.validateNameInLocalLang(this.empSubForm.value);
    }

    if (this.empSubForm.controls['jobType'] !== undefined) {
      this.selectJobType(this.empSubForm.controls['jobType'].value);
    }
  }

}
