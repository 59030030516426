<div class="body_container container  loginMobilePad m-t-20 mar10" *ngIf="isLanguageResourceLoaded">

  <div class="pad0 mobile_container anime-clean signup">
    <div class="row">
      <!-- Image Section Starts here-->
      <div class="col-md-6 col-sm-6 hidden-xs sign_bg p-t-50 dark_bg ">

        <!--<img src="../../assets/img/illustration01.png">-->
        <h3 class="text-center m-t-10 pad5">{{'RESET_HEADER_1' | translate}} {{'RESET_HEADER_3' | translate}}</h3>
      </div>
      <!-- Image Section Ends here-->

      <!-- Sign up Form Starts here-->
      <!-- <div class="pad0 col-xs-12  col-sm-6 col-md-6 signup_form mar26"> -->
      <div class="pad0 col-xs-12  col-sm-6 col-md-6 signup_form mar26">
        <div class="signup_container">
          <div class="row mobile-header visible-xs">
            <div class="col-xs-12 ">
              <div class="header_txt_white">
                <h2>{{'RESET_HEADER_1' | translate}} {{'RESET_HEADER_3' | translate}}</h2>
              </div>
            </div>
          </div>
          <div class="row m-t-40 pad15 ">
            <div class="col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 col-xs-12  ">
              <div class="header_txt_white">
                <h2>{{'RESET_HEADER_4' | translate}}</h2>
              </div>
            </div>
          </div>

          <div class="row m-t-40 pad15">
            <div *ngIf="isPasswordPolicyIssue" class="col-xs-12  col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 m-t-5 text-center alert alert-danger">
              {{'PASSWORD_POLICY_ISSUE_MSG' | translate}}
            </div>
          </div>
          <form class="m-t-25 pad15" autocomplete="off" #setPasswordForm="ngForm"
            (ngSubmit)="setPassword(setPasswordForm.value)">

            <div class="row">
              <div class="col-xs-12  col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 dp-r-0  m-b-20 mb-b-10">
                <div class="group">
                  <label class="placeholder_white custom_label" for="inputEmail">{{'EMAIL_LABLE' | translate}}</label>
                  <input type="email" aria-required="true" name="email" [(ngModel)]="email" id="inputEmail" class="validate form-control"
                    disabled />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12  col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 dp-r-0  m-b-20 mb-b-10">
                <div class="group">
                  <label class="placeholder_white custom_label"
                    for="inputSetPwd">{{'PASSWORD_LABLE' | translate}}</label>
                  <input ngModel type="password" aria-required="true" name="password" ngModel #password="ngModel" id="inputSetPwd"
                  class="validate form-control" maxlength="16" title="Please enter password" autocomplete="off"
                    (blur)="validatePwd(setPasswordForm.value.password, setPasswordForm.value.confirmPassword)"
                    (focus)="hidePwdError()" />                  
                    <div id="meter" #meter  >
                      <password-meter [password]= "setPasswordForm.value.password" (isValidPassword)='isPasswordValid($event)'>                     
                      </password-meter>                    
                    </div> 
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 m-b-20 dp-r-0  mb-b-10">
                <div class="group">
                  <label class="placeholder_white custom_label"
                    for="inputsCnfrmPwd">{{'CONFIRM_PASSWORD_LABLE' | translate}}</label>
                  <input ngModel type="password" aria-required="true" id="inputsCnfrmPwd" name="confirmPassword" ngModel
                    #confirmPassword="ngModel" class="validate form-control" maxlength="16"
                    title="Please enter confirm password"
                    (blur)="validateConfirmPwd(setPasswordForm.value.password, setPasswordForm.value.confirmPassword)"
                    autocomplete="off" (keyup)="enableNext(setPasswordForm.value.password, setPasswordForm.value.confirmPassword)"
                    (focus)="hideError(setPasswordForm.value.confirmPassword)" />
                  <div class="error_info">
                    <i class="fa fa-exclamation error_icon" aria-hidden="true" *ngIf="isCnfrmPwdErrorInfo"
                      [class.red_error]="isCnfrmPwdErrorInfo && !isCnfrmPwdError"
                      [class.cursor_hand_none]="isCnfrmPwdError"></i>
                    <div class="error_info_popup error_new_ui">
                      <div class="left_div">
                        <ul>
                          <li class="alert">{{cnfrmPwdErrorMessage | translate}}</li>
                          <!-- <li class="message">{{cnfrmPwdErrorMessage | translate}}</li> -->
                        </ul>
                      </div>
                      <div class="right_div">
                        <div class="close" aria-hidden="true">
                          <i class="icon-cancel"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="custom-error error_new_ui" *ngIf="isCnfrmPwdError">
                    <div class="left_div">
                      <ul>
                        <li class="alert">{{cnfrmPwdErrorMessage | translate}}</li>
                        <!-- <li class="message">{{cnfrmPwdErrorMessage | translate}}</li> -->
                      </ul>
                    </div>
                    <div class="right_div">
                      <div class="close" aria-hidden="true" (click)="closeCnfrmPwdError()">
                        <i class="icon-cancel"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="dpickerDate" *ngIf="hasSecurityQuestionSet">
              <div class="row">
                <div class="col-xs-12  col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 dp-r-0 m-b-20">
                  <div class="group">
                    <label class="placeholder_white custom_label" for="reSetSecretQuestion">{{ 'SECRET_QUESTION_LABLE' | translate}}</label>
                    <select class="form-control paddingsecret" aria-required="true" id="reSetSecretQuestion" name="secretQuestion" id="inputsSecQuestion"
                      (change)="validateSecretQuestion()" [disabled]="disabledQuestionSelect" [(ngModel)]="secretQuestion">
                      <option value="">{{ 'COMMONS_SELECT' | translate}}</option>
                      <option [value]="type.id" *ngFor="let type of secretQuestions">{{type.name | translate}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12  col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 dp-r-0 m-b-20">
                  <div class="group">
                    <label class="placeholder_white custom_label"
                      for="inputsSecAnswer">{{ 'SECRET_ANSWER_LABLE' | translate}}</label>
                    <input type="text" name="secretAnswer" aria-required="true" id="inputsSecAnswer" [(ngModel)]="secretAnswer"
                      class="validate form-control"
                      (keyup)="enableNext(setPasswordForm.value.password, setPasswordForm.value.confirmPassword, setPasswordForm.value.secretQuestion, setPasswordForm.value.secretAnswer)"
                      (blur)="validateSecretAnswer(setPasswordForm.value.secretAnswer)"
                      title="Please enter secret answer" autocomplete="off">
                    <div class="error_info">
                      <div class="custom-error error_new_ui" *ngIf="isInvalidAnswer">
                        <div class="left_div">
                          <ul>
                            <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate}}</li>
                            <li class="message">{{errorMessage | translate:[resetPasswordAttempts]}}</li>
                          </ul>
                        </div>
                        <div class="right_div">
                          <div class="close" aria-hidden="true" (click)="closeInvalidAnswerError()">
                            <i class="icon-cancel"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xs-12  col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8  m-b-10 pad0 m-t-15">
              <button type="submit" class="btn button font_extrabold" name="action"
                [disabled]="!isCnfrmPwdValid || !isPwdValid || !isSecretQuestionValid || !isSecretAnswerValid || !isPasswordRulesValid"
                [class.grow]="isCnfrmPwdValid && isPwdValid && isPasswordRulesValid">{{'COMMONS_NEXT' | translate}}</button>
            </div>
          </form>
        </div>
      </div>

      <!--Tab Navigation Slider End-->
      <div class="clearfix"></div>
    </div>
  </div>

  <div class="modal fade custom_modal" id="account_locked_modal" role="dialog" data-backdrop="static"
    data-keyboard="false">
    <div class="modal-dialog sign-modal">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" (click)="closeAccountLockedModel()">&times;</button>
        </div>
        <div class="transparentbg modal-body m-t-15 m-b-20">
          <h4 class="modal-title ">{{errorMessage | translate }}
          </h4>
        </div>
        <div class="modal-footer">
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade custom_modal" id="resetPassword-modal" role="dialog" data-backdrop="static"
    data-keyboard="false">
    <div class="modal-dialog sign-modal">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" (click)="resetModalClose()">&times;</button>

        </div>
        <div class="transparentbg modal-body">
          <h4 class="modal-title">{{'RESET_MODAL_TEXT' | translate }}
          </h4>
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="col-xs-12 col-sm-4 col-md-4 marginbot20 mar30 p_l_0  p_r_0 m-r-15  m-b-15">
              <button type="button" class="btn button font_extrabold" data-dismiss="modal"
                data-toggle="resetPassword-modal" (click)="goToSignin()">{{'COMMONS_CONTINUE' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
