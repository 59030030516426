import { Component, OnInit, Inject } from "@angular/core";
import { PreAuthService } from '../../pre-auth/pre-auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from "@angular/common";
import { ProfileService } from '../profile.service';
import { LocalStorageService } from './../../../framework/localstorage.service';
import { environment } from "environments/environment";

declare var window: any;
declare var document: any;

@Component({
    selector: 'unsubscribe',
    templateUrl: './unsubscribe.component.html',
    providers: [PreAuthService, ProfileService],
})
export class UnsubscribeComponent implements OnInit {
    isUnSubscribedFromSms: boolean = true;
    isUnSubscriptionError: boolean = false;
    isResponsed: boolean = true;
    invite_key: string;
    isAlreadyUnsubscribedFromSMS: boolean = false;
    isInitializationCompleted: boolean = false;

    constructor(
        private _preAuthService: PreAuthService,
        private _profileService: ProfileService,
        private _activatedRouter: ActivatedRoute,
        private localStorage: LocalStorageService,
        @Inject(DOCUMENT) private document: any
    ) {
        this.isAlreadyUnsubscribedFromSMS = false;
        this.isInitializationCompleted = false;
    }

    ngOnInit() {
        this.invite_key = this._activatedRouter.snapshot.queryParams['key'];
        this.isUnSubscribedFromSms = false;
        this.isUnSubscriptionError = false;
        this.isResponsed = true;
        this.loadInviteDataByInviteKey();
    }
    unsubscribeFromSms() {
        this.isResponsed = false;
        this.isUnSubscribedFromSms = false;
        this.isUnSubscriptionError = false;

        let trackObj = {
            profile_id: this.localStorage.getItem('profile_id'),
            UNSUBSCRIBED_FROM_SMS: true,
            stage_name: 'unsubscribe',
            action_value: 'sms service'
        }
        
        window.track_event("sms unsubscribed", trackObj, true, true);
        this.isResponsed = true;
        this.isUnSubscribedFromSms = true;
    }

    private initializeUnsubscribeSmsFlag() {
        this.isAlreadyUnsubscribedFromSMS = false;
        this._profileService.getUnsubscribeFromSmsByInviteKey(this.invite_key).subscribe(response => {
            console.log(JSON.stringify(response));
            this.isAlreadyUnsubscribedFromSMS = response['unsubscribed-from-sms'];
            this.isInitializationCompleted = true;
        },
            error => {
                this.isAlreadyUnsubscribedFromSMS = false;
                this.isInitializationCompleted = true;
            });
    }

    private loadInviteDataByInviteKey() {
        this.isAlreadyUnsubscribedFromSMS = false;
        this._preAuthService
            .validateToken(this.invite_key)
            .subscribe(response => {
                console.log(JSON.stringify(response));
                this.document.getElementById('brand').innerHTML = response['custom_styles'];
                this.isInitializationCompleted = false;
                this.initializeUnsubscribeSmsFlag();
            },
                error => {
                    this.isAlreadyUnsubscribedFromSMS = false;
                    this.isInitializationCompleted = true;
                });

    }
}
