import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'profile-name',
  templateUrl: './profile-name.component.html',
  providers: []
})

export class ProfileNameComponent {
  @Input() options1: any;
  @Input() gender: any;
  @Input() contactNumber: string;
  @Output() onViewProfile: EventEmitter<any> = new EventEmitter();

  constructor() {
    console.log(this.contactNumber);
  }

  openViewProfilePopup(data, key): void {
    data['key'] = key;
    if (this.gender !== null && this.gender !== undefined) {
      data['gender'] = this.gender;
    }
    if (!!this.contactNumber) {
      data['contactNumber'] = this.contactNumber;
    }

    this.onViewProfile.emit(data);
  }
}