import { Directive, forwardRef } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[validateSpaceInput][formControlName]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => SpaceInputValidator), multi: true }
    ]
})

export class SpaceInputValidator implements Validator {
    constructor() { }

    validate(c: AbstractControl): { [key: string]: any } {
        // self value
        let v: string = c.value;

        // value is empty i.e. space only given, then return error
        if (typeof v === "string") {
            if (v !== undefined && (v.trim().length === 0)) return {
                validateSpaceInput: false
            }
        }

        return null;
    }
}