import { Component, OnInit, Input, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { TranslatePipe, TranslateService } from './../../framework/i18n';
import * as dateUtils from './../utils/date.utils';
import * as moment from 'moment';
import { UtilService } from 'framework/utils/util.service';
declare var window: any;
declare var mobiscroll: any;
declare var $: any;
declare var Modernizr: any;

const DEFAULT_THEME: string = "material";
const DEFAULT_APPEAR: string = "bottom";
const DEFAULT_PICKER: string = "date";

@Component({
  selector: 'pa-date-picker',
  templateUrl: './pa-date.component.html',
  inputs: ['picker', 'id', 'theme', 'appear', 'placeholder', 'minDate', 'maxDate', 'defaultDate', 'setDate', 'maxYear', 'defaultMinAge'],
  outputs: ['ongetDpValue', 'onDateError'],
  styles: [`.paDatePick{width:270px !important;line-height:42px !important;}`],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PaDateComponent implements OnInit {
  @Input() lockLevelAts: any;
  @Input() shouldDisable: boolean = false;
  @Input() optionalDataMasking: boolean = false;
  @Input() futureGraduationDate: any;
  picker: string;
  id: number;
  wid: string;
  theme: string;
  appear: string;
  placeholder: string;
  minDate: Date;
  maxDate: Date;
  defaultDate: Date;
  setDate: any;
  maxYear: any;
  defaultMinAge: number;
  minYear: any;
  dateWheels: string = undefined;
  dateFormat: string = undefined;
  isDisableField: boolean = false;
  isDateNative: boolean = false;
  isMonthNative: boolean = false;
  isNativePicker: boolean = false;
  minValue: string = "";
  maxValue: string = "";
  defaultValue: string = "";
  errorMessage: string = "";
  isInvalidDate: boolean = false;
  monthNames: any = [];
  enMonthNames: any = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  minimumDate: Date;
  maximumDate: Date;
  mobIns: any;
  isIOSDevice: boolean = false;
  ongetDpValue: EventEmitter<any> = new EventEmitter();

  // Inform DOB Component for now - Max date validation status
  onDateError: EventEmitter<any> = new EventEmitter();
  private isDOBChecked: boolean = false;
  private originalDefaultValue: string = '';

  constructor(private _ts: TranslateService, private _util: UtilService) {

  }

  ngOnInit() {
    this.isIOSDevice = this.isIOS();
    if (!this.id) this.id = Math.floor(this._util.getCryptoRandom() * 999999);
    this.wid = this.id + "-widget";
    this.isDateNative = Modernizr.inputtypes.date && (this.picker === 'UDF_CRF' || this.picker === 'date' || this.picker === 'dob' || this.picker === 'datetoContact' || this.picker === 'criminal' || this.picker === 'license' || this.picker ==='self-schedule' || this.picker ==='online-schedule' || this.picker ==='mi' || this.picker ==='full-date' || this.picker ==='doc-submission-date' || this.picker === 'futureGraduationDate'|| this.picker ==='aliasDateFormat' || this.picker === 'today-to-future-date');
    this.isMonthNative = Modernizr.inputtypes.month && (this.picker === 'fromyear' || this.picker === 'month' || this.picker === 'educationmonth');
    this.isNativePicker = Modernizr.inputtypes.date && Modernizr.inputtypes.month && (this.picker === 'UDF_CRF' || this.picker === 'date' || this.picker === 'dob' || this.picker === 'datetoContact' || this.picker === 'criminal' ||
      this.picker === 'license' || this.picker ==='self-schedule' || this.picker === 'fromyear' || this.picker === 'month' || this.picker === 'educationmonth' || this.picker ==='online-schedule' || this.picker ==='mi' || this.picker ==='full-date' || this.picker ==='doc-submission-date' || this.picker === 'futureGraduationDate'|| this.picker ==='aliasDateFormat' || this.picker === 'today-to-future-date');

    // console.log("DatePicker isNativePicker isDateNative and isMonthNative :" + this.isNativePicker + ":" + this.isDateNative + ":" + this.isMonthNative + ":");
    this.monthNames = this._ts.instant("MONTH_NAMES");
  }

  setDpValue(date) {
    // console.log("date" + date);
    // console.log(this.maxYear);

    // Validate date sanity
    this.validateDate();

    /**
     * Emit only when 'date' is not ""
     */
    if (date !== "") {
      this.ongetDpValue.emit(date);
    }

    if (!this.isNativePicker && this.picker === 'dob' && this.optionalDataMasking) {
      $('#' + this.wid).val('********');
    }
  }

  getFormattedDate(date) {
    var formattedDate = date;
    if (date) {
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      if (month < 10) {
        month = '0' + month;
      }
      var day = date.getDate();
      if (day < 10) {
        day = '0' + day;
      }
      if (this.picker === 'fromyear' || this.picker === 'month' || this.picker === 'educationmonth') {
        formattedDate = year + "-" + month;
      } else {
        formattedDate = year + "-" + month + "-" + day;
      }
    }

    return formattedDate;
  }

  ngOnChanges() {
    // console.log('maxyear', this.maxYear);
    // locklevel check for dob
    this.checkLockLevel();

    if(!!this.shouldDisable) {
      this.isDisableField = true;
    }

    try {
      if (isNaN(Date.parse(this.setDate)) === true) {
        throw new Error(`Invalid date`);
      }
    } catch (e) {
      if (!!this.setDate && this.setDate !== undefined && this.setDate !== null && typeof this.setDate === 'string') {
        this.setDate = this.getDateFormatFromOtherlanguage();
      }
    }

    // Valid Date
    if (isNaN(Date.parse(this.setDate)) === false) {
      // console.log(this.setDate);
      // Get the masking prefernce value here. For mobiscroll; if masking is 'N' or false, then only set value in the below lines
      // If masking is enabled then don't set mobIns.setVal()
      let isDataMaskingEnabled = this.optionalDataMasking;
      let date;
      if (this.picker === 'dob') {
        let momentDate = moment.utc(this.setDate).toObject();
        date = new Date(momentDate.years, momentDate.months, momentDate.date);
      } else {
        date = new Date(this.setDate);
      }
      if (this.mobIns) {
        this.mobIns.setVal(date, true);
        if (!isDataMaskingEnabled) {
          this.mobIns.setVal(date, true);
        } else if (isDataMaskingEnabled && !this.isDOBChecked && this.setDate) {
          this.isDOBChecked = true;
          this.hideData();
        }
      } else {
        this.defaultValue = this.getFormattedDate(date);
        this.handleATSdata(this.defaultValue);
        if (isDataMaskingEnabled && !this.isDOBChecked && this.setDate) {
          this.isDOBChecked = true;
          this.hideData();
        }
      }
    } else {
      if (this.mobIns) {
        this.mobIns.clear();
      } else {
        this.defaultValue = '';
      }
    }
  }

  handleATSdata(value) {
    if(this.picker === 'educationmonth') {
      this.setDateValue(value);
    }
  }

  checkLockLevel() {
    // Disable Field if we have DOBATS in HomeDepot
    if (!!this.lockLevelAts && this.lockLevelAts === 'PII') {
      this.isDisableField = true;
      $('.test').attr('style', 'background-color: #eee !important');
    } else {
      this.isDisableField = false;
      $('.test').removeAttr('style');
    }
  }

  /**
   * This function returns exact date format if the date in multilangauge format
   */
  getDateFormatFromOtherlanguage() {
    // Get month Names
    let index = this.monthNames.indexOf(this.setDate.split(' ')[0]);
    let responseDate = this.enMonthNames[index] + ' ' + this.setDate.split(' ')[1];
    return responseDate;
  }

  ngAfterViewInit() {
    // console.log(this.maxYear);
    var that = this;
    var win = window; // Browser window
    let now = new Date();
    let mobiSettings = {
      onSet: function (event, inst) {
        this.pickedDate = event.valueText;
        // console.log("mobi-onSet pickedDate :" + this.pickedDate);
        let selectedDate = that.getFormattedDate(inst.getVal());
        this.pickedDate = that.getFormattedDateValue(selectedDate);
        // console.log("mobi-onSet getFormattedDateValue :" + this.pickedDate);
        that.setDpValue(this.pickedDate);
      }
    }

    mobiSettings['theme'] = this.theme || DEFAULT_THEME;
    mobiSettings['display'] = this.appear || DEFAULT_APPEAR;
    mobiSettings['lang'] = this._ts.currentLang;
    // mobiSettings['dateFormat'] = 'yyyy-mm-dd';

    if (this.minDate) {
      mobiSettings['minDate'] = this.minDate;
    }

    if (this.maxDate) {
      mobiSettings['maxDate'] = this.maxDate;
    }

    if (this.picker === 'month') {
      mobiSettings['dateWheels'] = 'MM yyyy';
    }

    /*
     * Specific DOB settings for 18 Yrs, but user can select any month (Jan to December) and
     * date in that specific year
    */
    if (this.picker === 'dob') {
      let min = new Date(now.getFullYear() - 100, now.getMonth(), now.getDate());
      let max = new Date(now.getFullYear() - this.defaultMinAge, now.getMonth(), now.getDate());
      mobiSettings['min'] = min;
      mobiSettings['max'] = max;
      /*
       * Profile_DOB calander by default should be should 14 (configurable) years,and
       * should allow user to select years set in EA
      */
      // mobiSettings['defaultValue'] = new Date(now.getFullYear() - 19, now.getMonth(), now.getDate());;
      mobiSettings['defaultValue'] = new Date(now.getFullYear() - (this.defaultMinAge + 1), now.getMonth(), now.getDate());
      mobiSettings['dateFormat'] = 'MM dd,yyyy';
      mobiSettings['dateWheels'] = 'MM dd yyyy';
    }
    if (this.picker === 'datetoContact') {
      let min = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      let max = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate());
      mobiSettings['min'] = min;
      mobiSettings['max'] = max;
      /*
       * Profile_DOB calander by default should be should 18+1 years,and
       * should allow user to select 18 year
      */
      mobiSettings['defaultValue'] = new Date(now.getFullYear(), now.getMonth(), now.getDate());;
      mobiSettings['dateFormat'] = 'MM dd,yyyy';
      mobiSettings['dateWheels'] = 'MM dd yyyy';
    }

    if (this.picker === 'date') {
      console.log("Using a month picker...");
      mobiSettings['dateFormat'] = 'MM dd,yyyy';
      mobiSettings['dateWheels'] = 'dd MM yyyy';
      mobiSettings['min'] = new Date(2000, 7, 14);
      mobiSettings['max'] = new Date(1900, 7, 14);
    }

    if(this.picker === 'self-schedule'){
      console.log("Using a self-schedule picker...");
      mobiSettings['dateFormat'] = 'MM dd,yyyy';
      mobiSettings['dateWheels'] = 'dd MM yyyy';
      if (this.minDate) {
        mobiSettings['min'] = dateUtils.getDateWithoutTimezone(this.minDate);
      }

      if (this.maxDate) {
        mobiSettings['max'] = dateUtils.getDateWithoutTimezone(this.maxDate);
      }
    }

    if(this.picker === 'online-schedule'){
      mobiSettings['dateFormat'] = 'MM dd,yyyy';
      mobiSettings['dateWheels'] = 'dd MM yyyy';
      let minInstance = new Date(this.minDate);
      let min = new Date(minInstance.getFullYear(), minInstance.getMonth(), minInstance.getDate());

      let maxInstance = new Date(this.maxDate);
      let max = new Date(maxInstance.getFullYear(), maxInstance.getMonth(), maxInstance.getDate());
      if (this.minDate) {
        mobiSettings['min'] = min;
      }

      if (this.maxDate) {
        mobiSettings['max'] = max;
      }
    }

    if (this.picker === 'mi') {
      console.log("Using a mi picker...");
      mobiSettings['dateFormat'] = 'MM dd,yyyy';
      mobiSettings['dateWheels'] = 'dd MM yyyy';

      let min = new Date(now.getFullYear() - 100, now.getMonth(), now.getDate());
      let max = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      mobiSettings['min'] = min;
      mobiSettings['max'] = max;

      if (this.minDate) {
        mobiSettings['min'] = dateUtils.getDateWithoutTimezone(this.minDate);
      }

      if (this.maxDate) {
        mobiSettings['max'] = dateUtils.getDateWithoutTimezone(this.maxDate);
      }
    }

    if (this.picker === 'fromyear') {
      let min = new Date(now.getFullYear() - 100, now.getMonth(), now.getDate());
      let max = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
      mobiSettings['dateFormat'] = 'MM, yyyy';
      mobiSettings['dateWheels'] = 'MM yyyy';
    }

    if (this.picker === 'toyear') {
      console.log(this.maxYear, 'toyear dob component');
      let min = new Date(this.maxYear);
      let max = new Date(now.getFullYear());

      mobiSettings['min'] = min;
      mobiSettings['dateFormat'] = 'yyyy';
      mobiSettings['dateWheels'] = 'yyyy';
    }

    if (this.picker === 'full-date') {
      let min = new Date(now.getFullYear() - 100, now.getMonth(), now.getDate());
      let max = new Date(now.getFullYear(), now.getMonth(), now.getDate());

      mobiSettings['min'] = min;
      mobiSettings['max'] = max;
      mobiSettings['dateFormat'] = 'MM dd,yyyy';
      mobiSettings['dateWheels'] = 'MM dd yyyy';
      if (this.minDate) {
        mobiSettings['min'] = this.minDate;
      }

      if (this.defaultDate) {
        mobiSettings['defaultValue'] = this.defaultDate;
      }

      if (this.maxDate) {
        mobiSettings['max'] = this.maxDate;
      }
    }

    if (this.picker === 'doc-submission-date') {
      let min = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
      mobiSettings['min'] = min;
      mobiSettings['dateFormat'] = 'MM dd,yyyy';
      mobiSettings['dateWheels'] = 'MM dd yyyy';

      if (this.maxDate) {
        mobiSettings['max'] = this.maxDate;
      }
    }

    if (this.picker === 'month') {
      let min = new Date(now.getFullYear() - 100, now.getMonth(), now.getDate());
      let max = new Date(now.getFullYear(), now.getMonth(), now.getDate());

      mobiSettings['min'] = min;
      mobiSettings['max'] = max;
      mobiSettings['dateFormat'] = 'MM yyyy';
      mobiSettings['dateWheels'] = 'MM yyyy';
      if (this.minDate) {
        mobiSettings['min'] = this.minDate;
      }

      if (this.defaultDate) {
        mobiSettings['defaultValue'] = this.defaultDate;
      }

      if (this.maxDate) {
        mobiSettings['max'] = this.maxDate;
      }
    }

    if (this.picker === 'educationmonth') {
      let min = new Date(now.getFullYear() - 100, now.getMonth(), now.getDate());
      let max = new Date(now.getFullYear(), now.getMonth(), now.getDate());

      mobiSettings['min'] = min;
      mobiSettings['max'] = max;
      mobiSettings['dateFormat'] = 'MM yyyy';
      mobiSettings['dateWheels'] = 'MM yyyy';
      if (this.minDate) {
        mobiSettings['min'] = this.minDate;
      }

      if (this.defaultDate) {
        mobiSettings['defaultValue'] = this.defaultDate;
      }

      if (this.maxDate) {
        mobiSettings['max'] = this.maxDate;
      }
    }

    // Need to optimize
    if (this.picker === 'criminal' || this.picker === 'license') {
      let min = new Date(now.getFullYear() - 100, now.getMonth(), now.getDate());
      let max = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      mobiSettings['dateFormat'] = 'MM dd,yyyy';
      mobiSettings['dateWheels'] = 'MM dd yyyy';

      mobiSettings['min'] = min;
      mobiSettings['max'] = max;
      mobiSettings['defaultValue'] = max;
    }

    if(this.picker === 'futureGraduationDate'){
      let value = 90;
      if(!!this.futureGraduationDate && this.futureGraduationDate != ""){
        value = Number(this.futureGraduationDate);
      }
      let min = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      let max = new Date(now.getFullYear(), now.getMonth(), now.getDate() + value);

      mobiSettings['min'] = min;
      mobiSettings['max'] = max;
      mobiSettings['dateFormat'] = 'MM dd,yyyy';
      mobiSettings['dateWheels'] = 'MM dd yyyy';
      if (this.minDate) {
        mobiSettings['min'] = this.minDate;
      }

      if (this.defaultDate) {
        mobiSettings['defaultValue'] = this.defaultDate;
      }

      if (this.maxDate) {
        mobiSettings['max'] = this.maxDate;
      }
    }

    if (this.picker === 'aliasDateFormat') {
      let min = new Date(now.getFullYear() - 100, now.getMonth(),now.getDate());
      let max = new Date(now.getFullYear(), now.getMonth(), now.getDate());

      mobiSettings['min'] = min;
      mobiSettings['max'] = max;
     // mobiSettings['defaultValue'] = new Date(now.getFullYear() - (this.defaultMinAge + 1), now.getMonth(), now.getDate());
      mobiSettings['dateFormat'] = 'MM dd, yyyy';
      mobiSettings['dateWheels'] = 'MM dd, yyyy';
      if (this.minDate) {
        mobiSettings['min'] = this.minDate;
      }
      if (this.defaultDate) {
        mobiSettings['defaultValue'] = this.defaultDate;
      }
      if (this.maxDate) {
        mobiSettings['max'] = this.maxDate;
      }
    }

    if (this.picker === 'today-to-future-date') {
      let min = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      mobiSettings['min'] = min;
      mobiSettings['dateFormat'] = 'MM dd,yyyy';
      mobiSettings['dateWheels'] = 'MM dd yyyy';

      if (this.maxDate) {
        mobiSettings['max'] = this.maxDate;
      }
    }

    //this.logProps(mobiSettings);
    if (!this.isNativePicker) {
      let instance = this.mobIns = mobiscroll.date('#' + this.wid, mobiSettings);
    } else {
      let dateFormat = this.getDateFormat();
      if (mobiSettings['min']) {
        this.minValue = this.getFormattedDate(mobiSettings['min']);
        this.minimumDate = mobiSettings['min'];
        if (this.isMonthNative) {
          this.minimumDate.setDate(1);
        }
      } else {
        this.minimumDate = new Date(now.getFullYear() - 100, now.getMonth(), now.getDate());
        this.minValue = this.getFormattedDate(this.minimumDate);
      }
      if (mobiSettings['max']) {
        this.maxValue = this.getFormattedDate(mobiSettings['max']);
        this.maximumDate = mobiSettings['max'];
        if (this.isMonthNative) {
          this.maximumDate.setDate(1);
        }
      } else {
        this.maximumDate = new Date(now.getFullYear() + 2, now.getMonth(), now.getDate());
        this.maxValue = this.getFormattedDate(this.maximumDate);
      }
    }

    if (this.setDate) {
      let date;
      if (this.picker === 'dob') {
        let momentDate = moment.utc(this.setDate).toObject();
        date = new Date(momentDate.years, momentDate.months, momentDate.date);
      } else {
        date = new Date(this.setDate);
      }
      if (this.mobIns) {
        this.mobIns.setVal(date, true);
      } else {
        this.defaultValue = this.getFormattedDate(date);
        //this.setDateValue(this.defaultValue);
      }
    } else {
      this.defaultValue = '';
    }
  }

  private getDateFormat() {
    let dateFormat = "yyyy-MM-dd";
    if (this.picker === 'fromyear' || this.picker === 'month' || this.picker === 'educationmonth') {
      dateFormat = "yyyy-MM";
    }
    return dateFormat;
  }

  getFormattedDateValue(dateValue) {
    // console.log("getFormattedDateValue dateValue :" + dateValue);
    let formattedDate = dateValue;
    if (dateValue) {
      let index = dateValue.indexOf("-");
      if (index != -1) {
        let year = dateValue.substring(0, index);
        let monthDay = dateValue.substring(index + 1);
        let month = '';
        let day = '';
        index = monthDay.indexOf("-");
        if (index != -1) {
          month = monthDay.substring(0, index);
          day = monthDay.substring(index + 1);
          formattedDate = this.monthNames[Number(month) - 1] + " " + day + "," + year;
        } else {
          month = monthDay;
          formattedDate = this.monthNames[Number(month) - 1] + " " + year;
        }
      }
    }

    return formattedDate;
  }

  /*
   * Check if year is between 1900 & 9999
   * Returns true for valid year else an false
   */
  validateYearFormat(yrVal: string) {
    let formatStatus: boolean = false;

    // Year value should not start with 0 and should be of length 4
    let regexString = /^[1-9]\d{3}$/;
    formatStatus = regexString.exec(yrVal) ? true : false;

    return formatStatus;
  }

  setDateValue(dateValue) {
    this.monthNames = this._ts.instant("MONTH_NAMES");
    this.errorMessage = "";

    if (dateValue) {
      let index = dateValue.indexOf("-");
      let date = '';
      if (index != -1) {
        let year = dateValue.substring(0, index);

        /*
         * Check if year value starts with [1-9] and then 3 digits. Receives false for invalid value
         * and true for valid
        */
        if (!this.validateYearFormat(year)) {
          this.closeInvalidDateError();
          return;
        }

        let monthDay = dateValue.substring(index + 1);
        let month;
        let day = 1;
        index = monthDay.indexOf("-");

        if (index != -1) {
          month = monthDay.substring(0, index);
          day = monthDay.substring(index + 1);
          date = this.monthNames[Number(month) - 1] + " " + day + "," + year;

        } else {
          month = monthDay;
          date = this.monthNames[Number(month) - 1] + " " + year;
        }
        let newDate = new Date(year, month - 1, day);
        // console.log("setDateValue newDate is :" + newDate + ":");
        if (+year !== newDate.getFullYear()) {
          this.errorMessage = "DATE_INVALID";
        } else if (this.minimumDate && (newDate.getTime() < this.minimumDate.getTime())) {
          this.errorMessage = "DATE_OUTOF_RANGE";
        } else if (this.maximumDate && (newDate.getTime() > this.maximumDate.getTime())) {
          this.errorMessage = "DATE_OUTOF_RANGE";
        } else {
          this.errorMessage = "";
        }

        if (this.errorMessage !== '') {
          // this.isInvalidDate=true;
          date = "";
        } else {
          // this.isInvalidDate = false;
        }
        // console.log("setDateValue date is :" + date + ":");
        this.setDpValue(date);
      }
    } else {
      this.errorMessage = "DATE_INVALID";
      //this.isInvalidDate=true;
      this.setDpValue("");
    }
  }

  validateDate() {
    if (this.errorMessage !== "") {
      this.isInvalidDate = true;
    } else {
      this.isInvalidDate = false;
    }

    // Inform DOB Component - Max date validation status - Valid/Invalid Date status

    if (this.picker === 'mi') {
      this.onDateError.emit({
        'isInvalidDate': this.isInvalidDate,
        'errorMessage': this.errorMessage
      });
    } else {
      this.onDateError.emit({
        'isInvalidDate': this.isInvalidDate
      });
    }

    if (this.picker === 'today-to-future-date') {
      this.onDateError.emit({
        'isInvalidDate': this.isInvalidDate,
        'errorMessage': this.errorMessage
      });
    } else {
      this.onDateError.emit({
        'isInvalidDate': this.isInvalidDate
      });
    }
  }

  closeInvalidDateError() {
    this.isInvalidDate = false;
    // if(this.picker !=='self-schedule' && this.picker !== 'online-schedule' && this.picker !=='mi' && this.picker !=='criminal'){
    //   // Inform DOB Component - Max date validation status - On Date Picker Focus Disable the NEXT, SAVE & EXIT, RIGHT ARROW button
    //   this.onDateError.emit({
    //     'isInvalidDate': true
    //   });
    // }
  }

  isIOS(): boolean {
    let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    console.log(iOS);
    return iOS;
  }

  unmaskData() {

    if (!this.optionalDataMasking || this.picker !== 'dob'){
      // DO NOTHING
      return;
    }

    this.closeInvalidDateError();

    if (this.isNativePicker && this.isDateNative) {
      $('#' + this.wid).removeAttr("type").attr("type", "date");
      // remove max length attribute when changed to DATE
      $('#' + this.wid).removeAttr("maxLength");
      this.defaultValue = this.originalDefaultValue;
    }

    let date;
    if (this.picker === 'dob') {
      let momentDate = moment.utc(this.setDate).toObject();
      date = new Date(momentDate.years, momentDate.months, momentDate.date);
    } else {
      date = new Date(this.setDate);
    }

    if (!this.isNativePicker && this.mobIns) {
      $('#' + this.wid).val(this.setDate);
      this.mobIns.setVal(date, true);
    }
  }

  hideData() {
    if (!this.optionalDataMasking || this.picker !== 'dob'){
      // DO NOTHING
      return;
    }

    if (this.isNativePicker && this.isDateNative  && !this.defaultValue.includes("*") && this.defaultValue.length > 0) {
      $('#' + this.wid).removeAttr("type").attr("type", "text");
      this.originalDefaultValue = this.defaultValue;
      // SET THE MAX-LENGTH TO THE TEXT FIELD TO DO NOT ALLOW ADDITIONAL TEXT
      const length = this.originalDefaultValue.length;
      $('#' + this.wid).attr("maxLength", length);
      this.defaultValue = this.originalDefaultValue.trim().replace(new RegExp(".", "g"), "*");
    }

    if (!this.isNativePicker && this.mobIns) {
      this.originalDefaultValue = this.setDate;
      const maskedData = this.originalDefaultValue.trim().replace(new RegExp(".", "g"), "*");
      // this.mobIns.clear();
      $('#' + this.wid).val(maskedData);
    }
  }

  // Function handle key press event and call unmaskdata function when keycode = 46 or 8(backspace or delete)
  controlMaskedTextField(event: any) {
    if (!!event) {
      const keyCode = event.keyCode;
      if (!!keyCode && (keyCode === 46 || keyCode === 8)) {
        this.unmaskData();
      }

    }
  }
}
