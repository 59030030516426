import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { WorkflowService } from '../../framework/workflow.service';
import { DocumentUploadConfig } from './document-upload.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { LocalStorageService } from '../../framework/localstorage.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { TranslateService } from 'framework/i18n';

@Injectable()
export class DocumentUploadService {
    static DEFAULT_DOC_TYPE_ID = '347'; //'1566461'
    workflowInstanceId: string;
    nextURL: string;
    nextDisplayname: string;
    headerOptions: any;
    documentUploadDataConfig: Object;
    grpConfig = DocumentUploadConfig.getDocumentUploadConfig();
    monthNames: any = this._ts.instant('MONTH_NAMES');

    constructor(private _http: HttpClient,
        private _authHttp: HttpClient,
        private _workflow: WorkflowService,
        private localStorage: LocalStorageService,
        private _ts: TranslateService) {
        this.workflowInstanceId = this.localStorage.getItem('workflow_instance_id');
        this.nextURL = this._workflow.getNextURL('documentupload-data-v1');
        this.documentUploadDataConfig = this._workflow.getStepConfig('documentupload-data-v1', 0);
        this.nextDisplayname = this._workflow.getNextMenuDisplayName('documentupload-data-v1');
        this.headerOptions = this._workflow.getHeaderOptions();
    }

    // get next Url
    getNextURL(): string {
        return this.nextURL;
    }

    // get next display name
    getNextDisplayName() {
        return this.nextDisplayname;
    }

    // Method to donwload file
    downloadFile(id, locationCode): Observable<Blob> {
        let workflowid = this.localStorage.getItem('workflow_instance_id');
        let action = '/api/v1/workflow/' + workflowid + '/download/';

        return this._authHttp.get(AppConfig.API_ENDPOINT() + action + id + '/?doclocation=' + locationCode, {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.localStorage.getItem('access_token')
            }), responseType: 'blob'
        }).pipe(
            map(res => this.documentDownload(res)),
            catchError(err => this._handleError(err, 'download_file_failed'))
        )
    }

    downloadFileNew(id: string, type: string): Observable<Blob> {
        let workflowid = this.localStorage.getItem('workflow_instance_id');
        let action = '/api/v1/workflow/' + workflowid + '/download/document/' + id + "?doc_type=" + type;

        return this._authHttp.get(AppConfig.API_ENDPOINT() + action, {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.localStorage.getItem('access_token')
            }), responseType: 'blob'
        }).pipe(
            map(res => this.documentDownload(res)),
            catchError(err => this._handleError(err, 'download_file_failed'))
        )
    }

    // Method to get data
    getData(): Observable<any> {
        let action = this.documentUploadDataConfig['action'];

        return this._authHttp.get(AppConfig.API_ENDPOINT() + action, this.headerOptions)
        .pipe(
            map(res => this._extractData(res)),
            catchError(err => this._handleError(err, 'doc_upld_get_failed'))
        );
    }

    // Method to upload files to doc base
    uploadFilesToDocBase(file, docTypeId?) {
        let account = this._workflow.getDocBaseUrl();
        let formData: FormData = new FormData();

        formData.append('file', file);

        // Now assigning all doctypeid's as 347. Backend will assign proper doctypeid after merging and uploading
        // if (docTypeId === undefined || docTypeId === '') {
        //     docTypeId = DocumentUploadService.DEFAULT_DOC_TYPE_ID;
        // }

        // let newHeaders = new HttpHeaders();
        const headerObj: any = {};

        // Check if IE then do not append 'Content-Type' for the other browsers append it
        let isIEBrowser = this.isIE();
        if (!isIEBrowser) {
            //headerObj['Content-Type'] = 'multipart/form-data';
        }

        // For INTL environment - Doc base requires document id
        if (AppConfig.ENVIRONMENT !== undefined && AppConfig.ENVIRONMENT === 'INTL') {
            headerObj['X-DOCBASE-CREATED-BY'] = 'PA';
            headerObj['X-DOCBASE-DOC-TYPE'] = '347';
            headerObj['X-DOCBASE-MD-DOCUMENT-TYPE'] = '347';
            headerObj['X-DOCBASE-MD-FIRST_NAME'] = 'PA_USER';
            headerObj['X-DOCBASE-MD-LAST_NAME'] = 'PA_USER';
        } else {
            headerObj['X-DOCBASE-CREATED-BY'] = 'Test';
            headerObj['X-DOCBASE-DOC-TYPE'] = 'RPTSCHED';
            headerObj['X-DOCBASE-MD-DOCUMENT-TYPE'] = '347';
        }

        let newHeaders = new HttpHeaders(headerObj);

        const options = { headers: newHeaders };

        return this._http.post(account, formData, options)
        .pipe(
            map(res => this._extractData(res)),
            catchError(err => this._handleError(err, 'upld_docBase_put_failed'))
        );
    }

    // Method to upload file summary
    uploadFileSummary(files, id, points, docTypeId?, componentType?, isFromEnhancedDashboard?) {
        let docData = {};
        let docListSummary = [];
        let state = {};
        let allFiles = files;

        // Prepare data for multiple files summary
        // Send files summary with files { fileName, fileType, fileOrder }
        for (let i = 0; i < allFiles.length; i++) {
            let file = allFiles[i];
            
            docData['file_name'] = file.name;
            docData['file_type'] = file.type;
            docData['file_sort_order'] = i;
            docData['file'] = !!file.base64Image ? file.base64Image : file.src;
            // docData['document-id'] = file.docBaseId;        // 31170

            docListSummary.push(docData);
            docData = {};
        }

        let workflowid = this.localStorage.getItem('workflow_instance_id');
        let action = '/api/v1/workflow/' + workflowid + '/documentupload-step';

        let body = {
            'id': id,
            'document-data-list': docListSummary,
            'file-upload': true,
            'state': state,
            'environment': AppConfig.ENVIRONMENT
        };

        // Send points in put call only for 100P/CRIM
        if (points !== undefined && points !== '') {
            body['points_value'] = points;
        }
        
        if (isFromEnhancedDashboard) {
            body['odrer_doc_type_id'] = docTypeId; 
            body['component_type'] = componentType;
            
            action = '/api/v1/workflow/' + workflowid + '/skip-document-upload';
        }

        return this._http.put(AppConfig.API_ENDPOINT() + action, body, this.headerOptions)
        .pipe(
            map(res => this._extractData(res)),
            catchError(err => this._handleError(err, 'upld_fileSummary_put_failed'))
        );
    }

    isIE() {
        let userAgent = navigator.userAgent;
        return userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident/') > -1 || userAgent.indexOf('Edge/') > -1;
    }

    // Method to save
    save(body) {
        let action = this.documentUploadDataConfig['action'];

        //Add AppD trace for doc upload completion
        if(body['state']['stage_status'] === 'COMPLETED'){
          let traces = {};
          traces['action'] = 'DocumentProvided';
          this.headerOptions = this._workflow.getHeaderOptionsWithTraces(traces);
        }

        return this._authHttp.put(AppConfig.API_ENDPOINT() + action, body, this.headerOptions)
        .pipe(
            map(res => this._extractData(res)),
            catchError(err => this._handleError(err, 'doc_upld_put_failed'))
        );
    }

    // Method to save
    putData() {
        let workflowid = this.localStorage.getItem('workflow_instance_id');
        let action = '/api/v1/workflow/' + workflowid + '/upload-doclist';

        return this._authHttp.put(AppConfig.API_ENDPOINT() + action, {}, this.headerOptions)
        .pipe(
            map(res => this._extractData(res)),
            catchError(err => this._handleError(err, 'doc_upld_doclist_put_failed'))
        );
    }

    // Method to delete
    deleteFile(id, fromEnhancedDashboard?, profileDocId?): Observable<any> {
        let workflowid = this.localStorage.getItem('workflow_instance_id');
        let action = '/api/v1/workflow/' + workflowid + '/documentupload-step/' + id;
        
        if (fromEnhancedDashboard) {
            action = '/api/v1/workflow/' + workflowid + '/skip-document-upload/' + profileDocId;
        }

        return this._authHttp.delete(AppConfig.API_ENDPOINT() + action, this.headerOptions)
        .pipe(
            map(res => this._extractData(res)),
            catchError(err => this._handleError(err, 'doc_upld_delete_failed'))
        );
    }

    // update document upload state When order creation is failed
    updateDocumentState(data: Object): Observable<any> {
        this.headerOptions = this._workflow.getHeaderOptions();
        let action = this.documentUploadDataConfig['action'];
        let body = {
            'state': data['state'],
            'file-upload': data['file-upload']
        };
        return this._authHttp.put(AppConfig.API_ENDPOINT() + action, body, this.headerOptions)
        .pipe(
            map(res => this._extractData(res)),
            catchError(err => this._handleError(err, 'docState_put_failed'))
        );
    }

    // Method to extract response
    _extractData(res: any) {
        return res;
    }

    // Method to handle response error
    _handleError(error: any, eventName) {
        let _body = error || {};
        let errorMessage: string;

        console['server'](eventName, error);

        switch (error.status) {
            case 400:
            case 401:
                // Bad request, Invalid Credentials - login invalid
                errorMessage = _body['error']['error'] || 'Invalid Login';
                break;
            case 404:
                errorMessage = _body['error']['error'];
                break;
        }
        return throwError(errorMessage);
    }

    documentDownload(response: any) {
        let res = response;

        return res;
    }

    getCustomInstructions(isInviteEiaf): string {
        let instructions: string;
        if (!isInviteEiaf) {
            if (this.documentUploadDataConfig['forms'] !== undefined &&
                this.documentUploadDataConfig['forms'].length > 0 &&
                this.documentUploadDataConfig['forms'][0]['instructions'] !== undefined) {
                instructions = this.documentUploadDataConfig['forms'][0]['instructions'];
            }
        } else {
            instructions = "CUSTOM_INSTRUCTION_EIAF_1";
        }
        return instructions;
    }

    getEaPreferenceValueByKey(preferences: Array<{}>, key: string): any {
        let value = null;

        if (!!preferences && preferences.length > 0) {
            for (let prefer of preferences) {
                if (prefer['id'] === key) {
                  if (prefer['value'] !== undefined && prefer['value'] !== null && prefer['value'] !== '') {
                    value = prefer['value'];
                  }
                  break;
                }
              }
        }


        return value;
    }

    /**
     * Check Phone and email optional for employer.1440.
     */
    documentSkipSubmissionPreferences() {
        let value = this.getEaPreferenceValueByKey(this.documentUploadDataConfig['ea-preferences'], DocumentUploadConfig.DOCUMENT_SKIP_SUBMISSION_PREF);
        const documentSkipSubmissionData = {};
        documentSkipSubmissionData['no_of_days_future_submission'] = 30;

        if (!!value && value.length > 0) {
            documentSkipSubmissionData['no_of_days_future_submission'] = parseInt(value, 10);
        }

        return documentSkipSubmissionData;
    }
    
    postSkippedDocs(docType, body) {
        return this._http.post(AppConfig.API_ENDPOINT() + '/api/v1/workflow/' + this.workflowInstanceId + '/skip-document-upload/' + docType, body, this.headerOptions)
        .pipe(
            map(res => this._extractData(res)),
            catchError(err => this._handleError(err, 'skip_docs_post_failed'))
        );
    }
    
    putEnhancedDashboardDocuments() {
        let workflowid = this.localStorage.getItem('workflow_instance_id');
        let action = '/api/v1/workflow/' + workflowid + '/skipdocument';
        const authHeaders = new HttpHeaders({
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Authorization': 'Bearer ' + this.localStorage.getItem('access_token')
        });

        return this._authHttp.put(AppConfig.API_ENDPOINT() + action, {}, { headers: authHeaders, observe: 'response', responseType: 'text'})
        .pipe(
            map(res => res),
            catchError(err => this._handleError(err, 'put_next_enhanced_dashboard_failed'))
        );
    }

}
