<div *ngIf="!config.isHidden" [formGroup]="group" class="col-sm-12 col-xs-12 col-md-12">
  <label class="radioLabel displayBlock" for="country" tabindex="0">
    <span [innerHTML]="config.label | interpolate | async | translate"></span>
  </label>

<div *ngIf="!isPostal && config.orientation === 'VERTICAL'" >
  <div class="radioDocument" *ngFor="let option of config.options">
    <label class="radio-inline" >
      <input type="radio" class="testingradio" [formControlName]="config.name" [value]="option['value']" (change)="onSelectionChange(config, option['value'])">
      <span [innerHTML]="option['label'] | translate"></span>
    </label>
  </div>
</div>
<div *ngIf="!isPostal && config.orientation != 'VERTICAL'" >
    <div class="col-xs-6 col-sm-2 col-md-2 m-b-20" *ngFor="let option of config.options">
      <div class="group">
        <label class="radio-inline radioLabelContent">
            {{ option['label']  | translate}}<input type="radio" class="testingradio" [formControlName]="config.name" [value]="option['value']" (change)="onSelectionChange(config, option['value'])">
        </label>
      </div>
    </div>
</div>

<div *ngIf="isPostal">
  <div *ngFor="let option of config.options" class="radioDocument">
    <label class="radio-inline ">
      <input type="radio" [formControlName]="config.name" [value]="option['value']"
        (change)="onSelectionChange(config, option['value'])">
        <b>{{ option['label1'] | translate}}</b><br>
        {{ option['label2'] | translate}}
      <br>
    </label>
  </div>
</div>

</div>
