<div class="row pad_30">
    <div class="col-md-12 col-xs-12 col-sm-12">
        <h4 class="m-t-15 m-b-20">{{config.label}}</h4>
    </div>    
</div>

<div class="row form-element-section pad_30">
    <div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
        <div class="group" [formGroup]="group">
    
        <input type="hidden" [formControlName]="config.name" class="form-control">
        <label class="placeholder_white custom_label" for="startDate">{{ 'START_DATE' | translate }}</label>
        <pa-date-picker id ="startDate" picker="mi" appear="bubble" theme="material" placeholder=""
            (ongetDpValue)="getStartDpValue($event)" (onDateError)="startDateErrorHandler($event)"  >
        </pa-date-picker>
        <div class="error_info" *ngIf="invalidStartDate">
            <i class="fa fa-exclamation error_icon z-ind-2" aria-hidden="true" [class.red_error]="!!isDatePeriodError"
                [class.cursor_hand_none]="!isDatePeriodError" (click)="openDatePeriodError()"></i>
       
            <div class="custom-error error_new_ui" *ngIf="invalidStartDate && !isDatePeriodError">
                <div class="left_div">
                    <ul>
                        <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate}}</li>
                        <li class="message">{{sartErrorMessage|translate }}</li>
                    </ul>
                </div>
                <div class="right_div">
                    <div class="close" aria-hidden="true" (click)="closeDatePeriodError()">
                        <i class="icon-cancel"></i>
                    </div>
                </div>
            </div>
        </div>

        </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
        <div class="group" [formGroup]="group">
            <label class="placeholder_white custom_label"
                for="endDate">{{ 'END_DATE' | translate }}</label>
            <pa-date-picker id="endDate" picker="mi" appear="bubble" theme="material" placeholder=""
                (ongetDpValue)="getEndDpValue($event)" (onDateError)="endDateErrorHandler($event)">
            </pa-date-picker>
            <div class="error_info" *ngIf="invalidEndDate">
                <i class="fa fa-exclamation error_icon z-ind-2" aria-hidden="true" [class.red_error]="!!isDatePeriodError"
                    [class.cursor_hand_none]="!isDatePeriodError" (click)="openDatePeriodError()"></i>
           
                <div class="custom-error error_new_ui" *ngIf="invalidEndDate && !isDatePeriodError">
                    <div class="left_div">
                        <ul>
                            <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate}}</li>
                            <li class="message">{{endErrorMessage|translate }}</li>
                        </ul>
                    </div>
                    <div class="right_div">
                        <div class="close" aria-hidden="true" (click)="closeDatePeriodError()">
                            <i class="icon-cancel"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

