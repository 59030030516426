import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import * as moment from 'moment';

@Directive({
  selector: '[debounceDirective]'
})
export class DebounceDirective implements OnInit, OnDestroy {
  @Output() debounceClick = new EventEmitter();
  private clicks = new Subject();
  private subscription: Subscription;

  @Input() debounceTime = 5000;

  constructor() { }

  ngOnInit() {
    this.subscription = this.clicks.pipe(
      throttleTime(this.debounceTime)
    ).subscribe(e => {
      this.debounceClick.emit(e)
    });
  }

  ngOnDestroy() {
    // console.info('****** debounce ngOnDestroy **********');
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    // console.log('************* event triggered at => ', moment(new Date()).format('dd-MM-yyyy hh:mm:ss'));
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }
}
