import { Directive, Attribute, AfterViewInit } from '@angular/core';
declare var jQuery: any;

@Directive({
    selector: '[placeholderFloat][formControlName],[placeholderFloat][form-control],[placeholderFloat][ngModel]'
})

export class PlaceHolderFloat {
    constructor(@Attribute('placeholderFloat') public placeholderFloat: string) { }

    public placeholderFloatFunc() {
        jQuery('.form-control').on('focus blur', function (e) {
            jQuery(this).parents('.form-group').toggleClass('focused', (e.type === 'focus' || this.value.length > 0));
        }).trigger('blur');
    }
}
