import { Component, OnInit } from '@angular/core';
import { AppConstants } from 'app/app.constants';
import { AuthService } from 'app/login';
import { YotiStatus } from 'app/yoti/yoti-config';
import { LocalStorageService } from 'framework/localstorage.service';

declare let jQuery: any;

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.css']
})
export class ThankyouComponent implements OnInit {

  msg_key = 'RID_SKIP_THANKYOU_MESSAGE';

  constructor(private localStorage: LocalStorageService,private _authService: AuthService) { }

  ngOnInit() {
    let ridStatus = this.localStorage.getItem("rid_status");
    let isYoti = this.localStorage.getItem("is_yoti");
    let isMFACallBackError = this.localStorage.getItem("isMFACallBackError");
    let isInstantIdentity = this.localStorage.getItem("is_instant_identity");
    let isPaymentError =  this.localStorage.getItem("is_payment_error");
    console.log("Thankyou page isYoti isMFACallBackError ", isYoti, ridStatus, isMFACallBackError);
    if (!!isYoti) {
      if (ridStatus === YotiStatus.FRAUD_DETECTED) {
        this.msg_key = 'YOTI_FRAUD_DETECTED_THANKYOU_MESSAGE';
      } else {
        this.msg_key = 'YOTI_ERROR_MSG';
      }
      jQuery('.modal-backdrop').remove();
    } else if (!!isMFACallBackError) {
      this.msg_key = 'MFA_CALLBACK_ERROR_MSG';
    } else if (!!isInstantIdentity) {
      this.msg_key = 'YOTI_ERROR_MSG';

    } else if (!!isPaymentError) {
      this.msg_key = 'PRICING_ERROR_MESSAGE';
    } else {
      this.msg_key = 'RID_SKIP_THANKYOU_MESSAGE';
    }
   // this._authService.logout();
  }

}
