import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from './modal.service';

@Component({
    selector: 'tb-modal',
    templateUrl: './modal.component.html',
    host: { '(document:keyup)': 'keyup($event)' },
    styles: [`
        .modal-overlay{
            background-color: rgba(0, 0, 0, .4);
            bottom: 0;
            left: 0;
            position: fixed;
            right: 0;
            top: 0;
            z-index: 1000;
        }
        .modal {
            background-color: #fff;
            left: 300px;
            max-height: 400px;
            min-height: 200px;
            overflow-y: auto;
            position: fixed;
            top: 30px;
            width: 400px;
            z-index: 1100;
            display: block;
        }
        .title {
            background-color: blue;
        }
        .title .right {
                color: orange;
                cursor: pointer;
            }

        .title .right:hover {
            color: yellow;
        }
        .title
         {
            padding: .75em;
        }
        .body {
            padding: .75em;
        }
        input
         {
            height: auto;
            margin-bottom: 7px;
        }

    select {
        height: auto;
        margin-bottom: 7px;
    }
    .buttons {
        float: right;
        margin: 1em;
        margin-right: 0;


    }
    .buttons .flat {
            background-color: $white;
            color: $color-text;
            line-height: 1.1em;
        }

    `]
})

export class Modal implements OnInit {
    @Input('modal-id') modalId: string;
    @Input('modal-title') modalTitle: string;
    @Input() blocking = false;
    isOpen: boolean = false;

    constructor(private modalService: ModalService) {
    }

    ngOnInit() {
        this.modalService.registerModal(this);
    }

    close(checkBlocking = false): void {
        this.modalService.close(this.modalId, checkBlocking);
    }

    keyup(event: KeyboardEvent): void {
        if (event.keyCode === 27) {
            this.modalService.close(this.modalId, true);
        }
    }
}