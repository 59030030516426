import { Component, Input, Output, EventEmitter, Renderer2, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { EmploymentConfig } from '../employment.config';
import { LocalStorageService } from '../../../framework/localstorage.service';
import * as dateUtils from './../../../framework/utils/date.utils';

//State
import { Store } from '@ngrx/store';
import * as ACTIONS_INTERFACE from '../../actions';
import { TranslateService } from 'framework/i18n';
import { EmploymentService } from '../employment.service';

declare var $: any;

@Component({
  selector: 'date-to-contact',
  templateUrl: './datetocontact.component.html',
  styleUrls: ['./datetocontact.component.css']
})
export class DatetocontactComponent implements OnInit {

  @Input('group')
  public empSubForm: FormGroup;
  @Input() row: any;
  @Input() cmSelectedDate: any;
  @Input() configOptions: any;
  @Input() exiForm: any;
  @Input() empContactMaxTimeFrame: number;
  @Output() onSetDate: EventEmitter<any> = new EventEmitter();
  @Output() onCriminalSetDateSwipe: EventEmitter<any> = new EventEmitter();
  
  inputDate: any;
  datetoconid: any;
  isYearError: boolean = true;
  datepickerOpts: any;
  primaryText: string;
  viewInit: boolean;
  startTime: any;
  countryOptions;
  isValidDate;
  dateErrFlag;
  showDateErr;
  firstName;
  monthNames;
  date: any;
  fromSetDate: Date;
  option: any;

  //SE functionality
  static STAGE_ID: string;
  static STEP_ID: string;
  static STEP_INDEX: number;
  public SUB_STEP_ID: string;
  public SUB_STEP_INDEX: number;
  PARENT_STAGE_STEP_CONFIG: Object;

  //State Maintenance Related Activity
  static BUTTON_CLICKED: string = 'CLICKED_SAVE_EXIT_BUTTON';
  currentStatus: string = 'VISITED';
  payLoad: Object;
  selectedDate: Date;

  constructor(private localStorage: LocalStorageService, private _store: Store<ACTIONS_INTERFACE.AppState>,
    private _ts: TranslateService,
    private _es: EmploymentService) {
    this.startTime = new Date().getTime();
    this.monthNames = this._ts.instant('MONTH_NAMES');
  }

  ngOnInit() {
    let now = new Date();
    this.datetoconid = 'datetocontact_' + this.row;

    this.viewInit = false;
    this.isValidDate = false;
    this.dateErrFlag = false;
    this.showDateErr = false;
    this.firstName = this.localStorage.getItem('first_name');
    this.selectedDate = new Date(this.cmSelectedDate);
    
    if (!!this.cmSelectedDate) {
      this.setDate(this.cmSelectedDate);
    }
  }

  ngAfterViewInit() {
    console.log('----------- ngAfterViewInit -----------------');
    this.viewInit = true;
    this.processConfig();
  }

  private processConfig(): void {
    //Get the Config Details from Parent Component - Education
    //console.log(this.configOptions);

    this.PARENT_STAGE_STEP_CONFIG = this.configOptions['PARENT_CONFIG'];
    this.SUB_STEP_ID = EmploymentConfig.subStepIndexMapping['verification_confirm']['subStep'];
    this.SUB_STEP_INDEX = EmploymentConfig.subStepIndexMapping['verification_confirm']['subStepIndex'];

    this.setState();
  }

  private setState(): void {
    this.payLoad = {
      name: this.PARENT_STAGE_STEP_CONFIG['STAGE_ID'],
      step: this.PARENT_STAGE_STEP_CONFIG['STEP_ID'],
      stepIndex: this.PARENT_STAGE_STEP_CONFIG['STEP_INDEX'],
      subStep: this.SUB_STEP_ID,
      subStepIndex: this.SUB_STEP_INDEX,
      iteration: this.row,
      status: this.currentStatus,
      dbRecordPresent: this.PARENT_STAGE_STEP_CONFIG['IS_DB_RECORD_PRESENT']
    };
  }
  
  gotoNext(obj) {
    let userdate = obj['datetocontact'];

    let todaydate = new Date()
    let laterdate = todaydate.getTime() + (this.empContactMaxTimeFrame * 24 * 60 * 60 * 1000)
    obj['datetocontact'] = dateUtils.formatToLangDate(userdate, this.monthNames, this._es.monthNames);
    (<FormControl>this.empSubForm.controls['datetocontact']).setValue(obj['datetocontact']);
    let contactdate = new Date(obj['datetocontact']).getTime();

    /*
     *
     * User has submitted the form using 'NEXT' button, then current status should be changed to 'COMPLETED'.
     * If user has submitted the form using 'SAVE & EXIT' button, the current status has already been
     * changed to 'PENDING' in saveExit()
     *
     * */
    //Update the state
    let timeDiff = laterdate - contactdate;
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (diffDays > this.empContactMaxTimeFrame || diffDays < 0) {
      this.showDateErr = true;
    }
    else {
      this.showDateErr = false;
      this.isValidDate = true;
      obj['permissionToContact'] = "No";
      let indexDetail: Object = {};
      indexDetail['row'] = this.row;
      obj['indexDetail'] = indexDetail;
      indexDetail['row'] = this.row;
      obj['indexDetail'] = indexDetail;

      if (this.currentStatus === 'VISITED') {
        this.currentStatus = 'COMPLETED'
      }
      this.setState();
      //pass the state to server
      obj['COMPONENT_STATE'] = this.payLoad;

      this.onSetDate.emit(obj);
    }
  }

  getDpValue(value) {
    this.date = value;
    (<FormControl>this.empSubForm.controls['datetocontact']).setValue(value);

    // If date is not empty, Date will bind into the field.
    if (this.date !== null && this.date !== undefined && this.date !== '') {
      this.setDate(this.date);
    }
  }

  showDateErrbutton() {
    this.showDateErr = false;
  }

  closeYearError() {
    this.isYearError = false;
  }

  ngOnDestroy() {
    console.log('Destroying - Criminal - year Component');
  }
  
  getPrevSwipe() {
    this.onCriminalSetDateSwipe.emit();
  }
  
  formatDate(date) {
    var monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];

    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();
    return day + ' ' + monthNames[monthIndex] + ' ' + year;
  }

  private setDate(dtVal) {
    let d = new Date(dateUtils.formatDateMMMMDDYYYY(dtVal, 'MMMM DD,YYYY', this.monthNames));
    this.date = this.formatDate(d);
    this.fromSetDate = d;
  }
}
