<div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
  <div class="group" [formGroup]="group">
    <label class="placeholder_white custom_label">
      {{config.label|translate}}
    </label>
    <input type="hidden" class="form-control" style="margin-top:10px;margin-bottom:10px;"
      [formControlName]="config.name">
    <img class="esignature" [src]="imgSrc" />
  </div>
</div>
