import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { BooleanFn } from './model/boolean-fn';
import { Identifiable } from '../model/identifiable';
import { Translatable } from '../model/translatable';
import { RuleRegexValue , RuleLengths} from './model/validation-rules-config';
import { Inclusive, TemporalCriteria } from './model/field-validation-rule';
import { DateTime } from 'luxon';
import { DurationObjectUnits } from 'luxon/src/duration';


function isEmptyInputValue(value: any): boolean {
  /**
   * Check if the object is a string or array before evaluating the length attribute.
   * This avoids falsely rejecting objects that contain a custom length attribute.
   * For example, the object {id: 1, length: 0, width: 0} should not be returned as empty.
   */
  return value == null ||
    ((typeof value === 'string' || Array.isArray(value)) && value.length === 0);
}

function hasValidLength(value: any): boolean {
  // non-strict comparison is intentional, to check for both `null` and `undefined` values
  return value != null && typeof value.length === 'number';
}

export const TODAY = DateTime.now().startOf('day');

/**
 * Set defaults for Date based validators, if not set, it defaults to ISO
 * https://moment.github.io/luxon/#/formatting?id=table-of-tokens
 * */
export const DEFAULT_FORMAT_ISO = 'yyyy-MM-dd';
export const DEFAULT_DIFF_UNIT = 'day';
export const DEFAULT_RANGE = 'OPEN';
export type Direction = 'BEFORE' | 'AFTER';
export const LOG_VERBOSE = true;

/**
 * Shared reusable PA custom validators.
 * */
export class ValidatorsPA {


  /**
   * @Description
   * Validator that checks does current field has value as another one.
   *
   * @usageNotes
   * specify second control name: string for value reference.
   *
   * @returns
   * a validator function that returns error map `{noMatch: true}` if validation fails otherwise `null`
   * */
  static fieldMatchAnother(secondControl: string): ValidatorFn {
    return fieldMatchAnotherValidator(secondControl);
  }


  static fieldMatchCountryAnother(secondControl: string): ValidatorFn {
    return fieldMatchAnotherCountryValidator(secondControl);
  }
  /**
   * @Description
   * Validator that field is required only if predicate is truthy.
   *
   * @usageNotes
   * based on predicate evaluation it will set 'Validators.required'
   * requiredIf(() => someConfigVar === 'case required')
   *
   * @returns
   * a validator function that returns error map `required` if validation fails otherwise `null`
   * */
  static requiredIf(predicate: BooleanFn): ValidatorFn {
    return requiredIfValidator(predicate);
  }


  /**
   * @Description
   * Validator that requires for ZIP code to be in one of US or Other countries format
   *
   * @usageNotes
   * based on predicate evaluation it will use US based zip validator or other countries zip validator
   * conditionalUsZip(() => someVar === 'US')
   *
   * @returns
   * a validator function that returns error map `invalidZip` if validation fails otherwise `null`
   * */
  static conditionalZip(tuple: [country: () => string, workflow: string]) {
    const [country, workflow] = tuple;
    return conditionalZipValidator(country, workflow);
  }


  /**
   * @Description
   * Validator that requires for ZIP code to be in one of US or Other countries format
   *
   * @usageNotes
   * based on predicate evaluation it will use US based zip validator or other countries zip validator
   * conditionalUsZip(() => someVar === 'US')
   *
   * @returns
   * a validator function that returns error map `invalidZip` if validation fails otherwise `null`
   * */
  static conditionalUsZip(predicate: BooleanFn): ValidatorFn {
    return conditionalUsZipValidator(predicate);
  }

  /**
   * @Description
   * Validator that requires for ZIP code to be in one of UK or Other regex countries format
   *
   * @usageNotes
   * based on predicate evaluation it will use US based zip validator or other countries zip validator
   * conditionalUkZip(() => someVar === 'UK')
   *
   * @returns
   * a validator function that returns error map `invalidZip` if validation fails otherwise `null`
   * */
  static conditionalUkZip(predicate: BooleanFn): ValidatorFn {
    return conditionalUkZipValidator(predicate);
  }


  /**
   * @Description
   * Validator that requires value starts with no white space character
   *
   * @usageNotes
   *
   *
   * @returns
   * a validator function that returns error map `nospace:true` if validation fails otherwise `null`
   * */
  static noSpace(control: AbstractControl): ValidationErrors | null {
    return noSpaceValidator(control);
  }

  /**
   * @Description
   * Validator that requires value do not contain only white space characters
   *
   * @usageNotes
   *
   *
   * @returns
   * a validator function that returns error map `nospace:true` if validation fails otherwise `null`
   * */
  static noBlanks(control: AbstractControl): ValidationErrors | null {
    return noBlanksValidator(control);
  }

  /**
   * @Description
   * Validator that requires value is one from allowed supplied list
   *
   * @usageNotes
   *
   *
   * @returns
   * a validator function that returns error map `valueNotInList: true` if validation fails otherwise `null`
   * */
  static allowedValue(source: Array<any>): ValidatorFn {
    return allowedValueValidator(source);
  }

  /**
   * @Description
   * Validator that requires value as Identifiable is one from allowed supplied list, but searching by translated values
   *
   * @usageNotes
   *
   *
   * @returns
   * a validator function that returns error map `valueNotInList: true` if validation fails otherwise `null`
   * */
  static allowedValueOrLabels(source: Array<Identifiable & Translatable>): ValidatorFn {
    return allowedValueOrLabelValidator(source);
  }


  /**
   * @Description
   * Validator that requires date to be after condition specified in TemporalCriteria
   *
   * Since referential value can be dynamic, we support duration object to specify that point.
   * for example valid date should be 6 years from DOB. or. input date must be after some value. ie. DOB
   * It could be Inclusive or exclusive if not set or set to 'NONE'.
   *
   * Movement in time for this validator is to the RIGHT towards to FUTURE.
   *
   * @usageNotes
   * - it could be configured to be inclusive by boolean or exclusive by default.
   * - it could take controlRef to be matched against existing date control
   * - it could take duration in TemporalCriteria to specify years, months, hours, etc.
   * - it could take value in form of date ISO string or unix timestamp as number (ie. DOB)
   *
   * @returns
   * a validator function that returns error map `dateIsNotAfter: true` if validation fails otherwise `null`
   * */
  static dateAfter(temporal: TemporalCriteria): ValidatorFn {
    return dateBeforeOrAfterValidator(temporal, 'AFTER');
  }

  /**
   * @Description
   * Validator that requires date to be before condition specified in TemporalCriteria
   *
   * @usageNotes
   * - it could be configured to be inclusive or exclusive by default.
   * - it could take controlRef to be matched against existing date control
   * - it could take duration as Duration to specify years, months, hours, etc.
   * - it could take value in form of date ISO string or unix timestamp as number (ie. DOB)
   *
   * @returns
   * a validator function that returns error map `dateIsNotBefore: true` if validation fails otherwise `null`
   * */
  static dateBefore(temporal: TemporalCriteria): ValidatorFn {
    return dateBeforeOrAfterValidator(temporal, 'BEFORE');
  }

  /**
   * @Description
   * Validator that requires date to be NOT before condition specified in TemporalCriteria
   *
   * @usageNotes
   * - it could be configured to be inclusive or exclusive by default.
   * - it could take controlRef to be matched against existing date control
   * - it could take duration as ChronoTuple to specify years, months, hours, etc.
   * - it could take value in form of date ISO string or unix timestamp as number (ie. DOB)
   *
   * @returns
   * a validator function that returns error map `dateNotBefore: true` if validation fails otherwise `null`
   * */
  static dateNotBefore(temporal: TemporalCriteria): ValidatorFn {
    return dateRangeValidator(temporal, 'BEFORE');
  }

  static dateNotAfter(temporal: TemporalCriteria): ValidatorFn {
    return dateRangeValidator(temporal, 'AFTER');
  }

  /**
   * @Description
   * Named Validator that requires date value to be at legal age defined by duration.
   *
   * @usageNotes
   * - it could be configured to be inclusive by specifying START side, it is exclusive by default.
   * - it could take duration to specify years, months, hours, etc. in negative amount from value.
   * - defaults to TODAY if not set
   * ie. legalAge(years:18); will require 18 years + 1 day from today.
   *
   * @returns
   * a validator function that returns error map `dateNotBefore: true` if validation fails otherwise `null`
   * */
  static legalAge(temporal: TemporalCriteria): ValidatorFn {
    return dateBeforeOrAfterValidator(temporal, 'BEFORE');
  }


  /**
   * @Description
   * Is valid date validator to check validity of input.
   *
   * @usageNotes
   * - it just checks does supplied input is correct date.
   * currently it is checking by ISO date pattern if no format specified.
   *
   * @returns
   * a validator function that returns error map `notValidDate: true` if validation fails otherwise `null`
   */
  static dateIsValid(format?: string): ValidatorFn {
    return dateIsValidFormat(format);
  }


  /**
   * @Description
   * Simple validator that checks DATE entered is not in the future. Offset is day.
   *
   * @usageNotes
   * - it just checks does input is not in the future, ie. DOB is not after today.
   *
   * @returns
   * a validator function that returns error map `notValidDate: true` if input cannot be converted to date.
   * and {notValidDateInFuture: true} if date is in future by day.
   * */
  static dateNotInFuture(control: AbstractControl): ValidationErrors | null {
    const now: DateTime = DateTime.utc().startOf(DEFAULT_DIFF_UNIT);

    if (isEmptyInputValue(control.value)) {
      return null;  // don't validate empty values to allow optional validators
    }

    const dateTime = DateTime.fromISO(control.value, {zone: 'utc'}).startOf(DEFAULT_DIFF_UNIT);
    if (!dateTime.isValid) {
      return {notValidDate: true, translationErrorKey: 'IS_NOT_VALID_DATE_VALUE'};
    }

    if (dateTime.diff(now, DEFAULT_DIFF_UNIT).days > 0) {
      return {dateInFuture: true, translationErrorKey: 'DATE_SHOULD_NOT_BE_IN_FUTURE'};
    }

    return null;
  }

  /**
   * @Description
   * Simple validator that checks DATE entered is not in the past. Offset is day.
   *
   * @usageNotes
   * - it just checks does input is not in the future, ie. Driving licence is not expired by today.
   *
   * @returns
   * a validator function that returns error map `notValidDate: true` if input cannot be converted to date.
   * and {notValidDateInPast: true} if date is in past by day.
   * */
  static dateNotInPast(control: AbstractControl): ValidationErrors | null {
    const now: DateTime = DateTime.now().startOf(DEFAULT_DIFF_UNIT);

    if (isEmptyInputValue(control.value)) {
      return null;  // don't validate empty values to allow optional validators
    }

    const dateTime = DateTime.fromISO(control.value);
    if (!dateTime.isValid) {
      return {notValidDate: true, translationErrorKey: 'IS_NOT_VALID_DATE_VALUE'};
    }

    if (dateTime.diff(now, DEFAULT_DIFF_UNIT).days < 0) {
      return {dateInPast: true, translationErrorKey: 'DATE_SHOULD_NOT_BE_IN_PAST'};
    }

    return null;
  }
  
  static fieldMatchDrivingLicense(secondControl: string): ValidatorFn {
    return fieldMatchDrivingLicenseValidator(secondControl);
  }

}


/**
 * Implement validator functions below, take care to not modify already developed and tested Validators, since they are
 * all driven by different configuration combinations. Also, take care to not hardcode business logic here since this
 * prevents usability. For adding new features or configuration properties, take care not to break existing functionality
 * which could be used on some other component already. Try to be DRY (Do not Repeat Yourself), try less copy-paste.
 * */

/**
 * Validator that requires the control value starts with no space character
 * */
export function noSpaceValidator(control: AbstractControl): ValidationErrors | null {
  let re = /^\S/;
  if (control.value && !control.value.match(re)) {
    return {nospace: true};
  }
  return null;

}

/**
 * Validator that requires the control value do not contain only space characters
 * */
export function noBlanksValidator(control: AbstractControl): ValidationErrors | null {
  let re = /\S/;
  let controlValue = ''; // Set it as string as RegEx expects a string value
  controlValue = !!control.value ? control.value : '';
  
  if (!!control.value && typeof control.value !== 'string' && !!control.value['city']) {
    controlValue = control.value['city'];
  }
  
  if (controlValue && !controlValue.match(re)) {
    return {blanks: true};
  }
  return null;

}


/**
 * Validator that requires control value to be in particular regex format.
 * copied form intial implementation, and should not be used. has bugs.
 * @deprecated and subject to removal.
 * */
export function conditionalUsZipValidator(predicate: BooleanFn): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {
    if (isEmptyInputValue(control.value) || typeof control.value !== 'string') {
      return null;  // don't validate empty values or objects
    }

    if (predicate()) {
      const regEx = new RegExp('^[a-zA-Z0-9*]{5}$');
      if (!regEx.test(control.value) || !control.value.match(/^[a-zA-Z0-9-* ]*$/)) {
        return {invalidZip: true};
      }

    } else {
      if (!control.value || control.value.trim() === '' || !control.value.match(/^[a-zA-Z0-9-* ]*$/)) {
        return {invalidZip: true};
      }
    }

    return null;
  };
}


/***
 * New US zip validator which concentrates only on US ZIP validity, without hacking masked value.
 * */
export function usZipValidator(control: AbstractControl, workflow: string): ValidationErrors | null {

  console.debug('US validator workflow:', workflow);
  if (isEmptyInputValue(control.value) || typeof control.value !== 'string') {
    return null;  // don't validate empty values or objects
  }


  const regEx = new RegExp(RuleRegexValue.usFormattedZipCode);
  if (!regEx.test(control.value) || !control.value.match(RuleRegexValue.usFormattedZipCode)) {
    return {invalidZip: true};
  }

  return null;
}


/**
 * Validator that requires control value matches value in another form control.
 * */
export function fieldMatchAnotherValidator(secondControlName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    if (isEmptyInputValue(control.value) || !hasValidLength(control.value)) {
      // don't validate empty values to allow optional controls
      // don't validate values without `length` property
      return null;
    }

    if (!secondControlName) {
      console.warn('fieldMatchValidator not properly configured', secondControlName);
      return null;
    }


    return control.value === control.parent.get(secondControlName).value ? null : {noMatch: true};
  };
}

export function fieldMatchAnotherCountryValidator(secondControlName: string): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {
    if (isEmptyInputValue(control.value) || typeof control.value !== 'string') {
      return null;  // don't validate empty values or objects
    }

    if (!secondControlName) {
      console.warn('fieldMatchValidator not properly configured', secondControlName);
      return null;
    }

    control.root.get(secondControlName)?.valueChanges
    .subscribe(value => {
      control.setValue('');
      control.updateValueAndValidity();
      return  passportValidator(control,secondControlName);
    });

    return  passportValidator(control,secondControlName);
  }  

}
    
/**
 * Validator that requires control value to be present if required.
 * */
export function requiredIfValidator(predicate: BooleanFn): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.parent) {
      return null;
    }

    if (predicate()) {
      return Validators.required(control);
    }
    return null;
  };
}

/**
 * Validator that requires control value to be in particular regex format.
 * @deprecated should use ukZipValidator
 * */
export function conditionalUkZipValidator(predicate: BooleanFn): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {
    if (isEmptyInputValue(control.value) || typeof control.value !== 'string') {
      return null;  // don't validate empty values or objects
    }

    if (predicate()) {
      const regEx = new RegExp(RuleRegexValue.ukFormattedZipCode);
      const controlValue = control.value.replace(' ', '');
      if (!regEx.test(controlValue) || !controlValue.match(RuleRegexValue.ukFormattedZipCode)) {
        return {invalidZip: true};
      }

    } else {

      if (!control.value || control.value?.trim() === '' || !control.value?.match(RuleRegexValue.otherZipFormat)) {
        return {invalidZip: true};
      }
    }

    return null;
  };
}

/**
 * Validator that concentrates on UK ZIP validity only, not others.
 * */
export function ukZipValidator(control: AbstractControl, workflow: string): ValidationErrors | null {

  console.debug('UK validator workflow:', workflow);
  if (isEmptyInputValue(control.value) || typeof control.value !== 'string') {
    return null;  // don't validate empty values or objects
  }

  // if needed different behavior for workflows implement here.
  const regEx = new RegExp(RuleRegexValue.ukFormattedZipCode);
  // const controlValue = control.value.replace(' ', '');

  if (!regEx.test(control.value) || !control.value.match(RuleRegexValue.ukFormattedZipCode)) {
    return {invalidZip: true};
  }

  return null;
}

export function passportValidator(control: AbstractControl, secondControlName: string): ValidationErrors | null {

  if (isEmptyInputValue(control.value) || typeof control.value !== 'string' || !hasValidLength(control.value)) {
    return null;  // don't validate empty values or objects
  }

  let secondControlValue = control.root.get(secondControlName)?.value;
  if ( !!secondControlValue  && (secondControlValue === 'UNITED KINGDOM' || secondControlValue === 'British')) {
    const regEx = new RegExp(RuleRegexValue.ukPassportFormat);
    if (control.value.length <= RuleLengths.maxLengthPassport && control.value.length >= RuleLengths.ukPassportMinLength) {
      return !control.value.match(regEx) ? { invalidPassportNumber: true } : null;
    } else {
      return { invalidPassportNumber: true };
    }
  } else {
    const regEx = new RegExp(RuleRegexValue.otherPassportFormat);
    if (control.value.length <= RuleLengths.maxLengthPassport && control.value.length >= RuleLengths.otherPassportMinLength) {
      return !control.value.match(regEx) ? { invalidPassportNumber: true } : null;
    } else {
      return { invalidPassportNumber: true };
    }
  }


}

/**
 * Updated ZIP validator conditional by selected country.
 * */
export function conditionalZipValidator(country: () => string, workflow: string): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {
    if (isEmptyInputValue(control.value) || typeof control.value !== 'string') {
      return null;  // don't validate empty values or objects
    }

    switch (country()) {
      case 'GB':
        return ukZipValidator(control, workflow);
      case 'US':
        return usZipValidator(control, workflow);
      default:
        return otherZipValidator(control, workflow);
    }
  }
}

export function otherZipValidator(control: AbstractControl, workflow: string): ValidationErrors | null {
  console.debug('Other validator workflow:', workflow);
  if (isEmptyInputValue(control.value) || typeof control.value !== 'string') {
    return null;  // don't validate empty values or objects
  }

  // if needed different behavior for default than set it here. added by story-116636
  if (!control.value || control.value?.trim() === '' || !control.value?.match(RuleRegexValue.otherZipFormat)) {
    return {invalidZip: true};
  }
}


export function allowedValueValidator(source: Array<any>): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {
    if (isEmptyInputValue(control.value)) {
      return null;  // don't validate empty values to allow optional validators
    }

    if (source.length !== 0) {
      if (!source.some(value => {
        return value.toUpperCase() === control.value.toUpperCase()
      })) {
        return {valueNotInList: true};
      }

    }

    return null;
  };
}


export function allowedValueOrLabelValidator(source: Array<Identifiable & Translatable>): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {
    if (isEmptyInputValue(control.value)) {
      return null;  // don't validate empty values to allow optional validators
    }

    if (source.length !== 0) {
      if (!source.some(value => {
        return (<string>value.id) === control.value || (value.translationKey) === control.value
      })) {
        return {valueNotInList: true};
      }

    }

    return null;
  };
}

export function fieldMatchDrivingLicenseValidator(secondControlName: string): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {
    if (isEmptyInputValue(control.value) || typeof control.value !== 'string') {
      return null;  // don't validate empty values or objects
    }

    if (!secondControlName) {
      console.warn('fieldMatchDrivingLicenseValidator not properly configured', secondControlName);
      return null;
    }

    control.root.get(secondControlName)?.valueChanges
    .subscribe(value => {
      control.setValue('');
      control.updateValueAndValidity();
    
      return drivingLicenseValidator(control, secondControlName);
    });

    return drivingLicenseValidator(control, secondControlName);
  }
}

export function drivingLicenseValidator(control: AbstractControl, secondControlName: string): ValidationErrors | null {
  if (isEmptyInputValue(control.value) || typeof control.value !== 'string' || !hasValidLength(control.value)) {
    return null;  // don't validate empty values or objects
  }
  let isUKDVLA = control.root.get(secondControlName)?.value === 'UNITED KINGDOM' && (control.value.length >= RuleLengths.ukdvladrivingLicenseMinLength && control.value.length <= RuleLengths.drivingLicenseMaxLength);

  const regEx = isUKDVLA ? new RegExp(RuleRegexValue.ukDvlaDrivingLicenseNumberFormat) : new RegExp(RuleRegexValue.defaultDrivingLicenseNumberFormat);

  if (control.value.length >= RuleLengths.drivingLicenseMinLength && control.value.length <= RuleLengths.drivingLicenseMaxLength) {
    let dlValue = control.value;
    let isInValidUKDVLALastChars = false;
    if (isUKDVLA) {
      if (dlValue.length > RuleLengths.ukdvladrivingLicenseMinLength) {
        let lastChars = dlValue.substring(RuleLengths.ukdvladrivingLicenseMinLength, dlValue.length);
        const ukdvlaLastCharsRegex = new RegExp(RuleRegexValue.ukDvlaDrivingLicenseNumberLastCharsFormat);
        isInValidUKDVLALastChars = (!ukdvlaLastCharsRegex.test(lastChars) || !lastChars.match(ukdvlaLastCharsRegex));
      }
      dlValue = dlValue.substring(0, RuleLengths.ukdvladrivingLicenseMinLength);
    }
    return (!regEx.test(dlValue) || !dlValue.match(regEx) || isInValidUKDVLALastChars) ? { invalidDrivingLicenseNumber: true } : null;
  } else {
    return { invalidDrivingLicenseNumber: true };
  }

}


/**
 * all duration units must be set as all positive or all negative when set in rules.
 * When duration is not set, it is considered as positive future.
 * */
function positiveDuration(duration: DurationObjectUnits): boolean {

  if(!duration){
    return true;
  }

  for (let durationKey in duration) {
    return duration[durationKey] > 0;
  }
}



/***
 * Closed range for any direction (dateNot After , dateNot Before) should be inside of boundary.
 * Direction does not count here.
 * **/
function isOutsideOfAllowedClosedRange(temporal: TemporalCriteria, startBoundaryDays: number, endBoundaryDays: number): boolean {
  logger('CLOSED RANGE for: Any direction: input value should be inside of range...');
  switch (temporal?.inclusive) {
    case Inclusive.start:
      return positiveDuration(temporal?.duration) ? (startBoundaryDays < 0 || endBoundaryDays >= 0) : (startBoundaryDays > 0 || endBoundaryDays <= 0);
    case Inclusive.end:
      return positiveDuration(temporal?.duration) ? (startBoundaryDays <= 0 || endBoundaryDays > 0) : (startBoundaryDays >= 0 || endBoundaryDays < 0);
    case Inclusive.both:
      return positiveDuration(temporal?.duration) ?
        (startBoundaryDays < 0 || endBoundaryDays > 0) : (startBoundaryDays > 0 || endBoundaryDays < 0);

    default:
    case Inclusive.none:
      return positiveDuration(temporal?.duration) ? (startBoundaryDays <= 0 || endBoundaryDays >= 0) : (startBoundaryDays >= 0 || endBoundaryDays <= 0);
  }
}

/***
 * Open range for any direction (dateNot After , dateNot Before) should be inside of boundary.
 * Direction defines which side is open.
 * **/
function isOutsideOfAllowedOpenRange(temporal: TemporalCriteria, direction: "BEFORE" | "AFTER",
                                     startBoundaryDays: number, endBoundaryDays: number) {

  logger('OPEN RANGE for direction:'+direction+' input value should be inside of range...');
  if (direction === 'BEFORE') {
    return isBeforeAllowedOpenRange(temporal, startBoundaryDays, endBoundaryDays);
  }

  if (direction === 'AFTER') {
    return isAfterOfAllowedOpenRange(temporal, startBoundaryDays, endBoundaryDays);
  }


}

/**
 * Open RANGE means that when we specify RANGE, one side of range is OPEN,
 * OPEN RANGE BEFORE = ERROR if before [DOB +12Months  ---> OPEN ok
 * */
function isBeforeAllowedOpenRange(temporal: TemporalCriteria, startBoundaryDays: number, endBoundaryDays: number): boolean {
  switch (temporal?.inclusive) {
    case Inclusive.start:
      return positiveDuration(temporal?.duration) ? (startBoundaryDays < 0 ) : (startBoundaryDays > 0);
    case Inclusive.end:                             //(startBoundaryDays >= 0 || endBoundaryDays < 0)
      return positiveDuration(temporal?.duration) ? (startBoundaryDays <= 0) : (endBoundaryDays <= 0);
    case Inclusive.both:
      return positiveDuration(temporal?.duration) ?
        (startBoundaryDays < 0 ) : (startBoundaryDays > 0 || endBoundaryDays < 0);

    default:
    case Inclusive.none:
      return positiveDuration(temporal?.duration) ? (startBoundaryDays <= 0) : (endBoundaryDays <= 0);
  }
}

/**
 * Open RANGE means that when we specify RANGE, one side of range is OPEN,
 * OPEN RANGE AFTER = OPEN <---- DOB +12Months ] ERROR when after
 * */
function isAfterOfAllowedOpenRange(temporal: TemporalCriteria, startBoundaryDays: number, endBoundaryDays: number): boolean {
  switch (temporal?.inclusive) {
    case Inclusive.start:
      return positiveDuration(temporal?.duration) ? (endBoundaryDays > 0) : (startBoundaryDays > 0);
    case Inclusive.end:
      return positiveDuration(temporal?.duration) ? (endBoundaryDays >= 0) : (startBoundaryDays >= 0);
    case Inclusive.both:
      return positiveDuration(temporal?.duration) ?
        (endBoundaryDays > 0) : (startBoundaryDays > 0);

    default:
    case Inclusive.none:
      return positiveDuration(temporal?.duration) ? (endBoundaryDays >= 0) : (startBoundaryDays >= 0);
  }
}


/**
 * validate when we want to create boundary for range, and validate that input is not after or before some point
 * ie. next three months from point in time. (default is from today when value not specified), and have an option
 * to lock between boundaries. ie PAST <--- x|< period >|x TODAY | ---> FUTURE
 * Direction is towards TODAY.
 *
 * When direction is set to BEFORE, and Duration months:3, without value it will be translated that 'Input' can be
 * somewhere in last three months before today , IF range is open, than it will towards to the future.
 * If range is closed it will be valid up to day. Inclusive can be set to include acceptable boundaries. (today for example)
 *
 * When value is set, then duration is added to initial value as endBoundary point.
 *  */
export function dateRangeValidator(temporal: TemporalCriteria, direction: Direction): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {


    logger('Trying to resolve range for: ' + direction);


    if (isEmptyInputValue(control.value)) {
      return null;  // don't validate empty values to allow optional validators
    }


    const dateFormat = temporal.format ? temporal.format : DEFAULT_FORMAT_ISO;
    const diffUnit = temporal.diffUnit ? temporal.diffUnit : DEFAULT_DIFF_UNIT;

    let startBoundary: DateTime;
    let endBoundary: DateTime;

    const inputDate = DateTime.fromFormat(control.value, dateFormat, {zone: 'utc'}).startOf(diffUnit);

    if (!inputDate.isValid) {
      return {notValidDate: true};
    }

    let tempDateRef: DateTime;

    if (temporal.controlRef) { // there is work on it. value subscription
      const controlRefValue = control.parent?.get(temporal.controlRef)?.value;
      tempDateRef = DateTime.fromFormat(controlRefValue, dateFormat, {zone: 'utc'}).startOf(diffUnit);
      startBoundary = tempDateRef;
    }

    if (temporal.value) {
      tempDateRef = DateTime.fromFormat(<string>temporal.value, dateFormat, {zone: 'utc'}).startOf(diffUnit);
      startBoundary = tempDateRef;
    }


    if (tempDateRef && !tempDateRef?.isValid) {
      // move out! and check for Backend value ref
      tempDateRef = DateTime.fromISO(<string>temporal.value, {zone: 'utc'}).startOf(diffUnit);

      if (tempDateRef && !tempDateRef?.isValid) {
        console.error('Date before value/control ref not properly defined!', tempDateRef);
        return {configurationError: true, translationErrorKey: 'DATE_VALUE_CONFIG_ERROR'};
      }
    }

    if (!tempDateRef) {
      console.warn('ControlRef or Value not set, initializing start as today.');
      startBoundary = DateTime.utc().startOf(diffUnit);
    }

    /**
     * When having durations specified following cases are possible:
     * Case when no value or control ref is set, startBoundary is from Today.
     * we need to add duration to resolved start Boundary.
     * */
    if (tempDateRef) {
      startBoundary = tempDateRef;
    }

    // If there is no duration defined, start and end are the same.
    endBoundary = temporal.duration ? startBoundary.plus(temporal.duration).startOf(diffUnit) : startBoundary;


    // is date after end date
    const endBoundaryDays: number = inputDate.diff(endBoundary, diffUnit).days;
    const startBoundaryDays: number = inputDate.diff(startBoundary, diffUnit).days;

    logger(endBoundary.toISODate() + 'endBoundaryDays:' + endBoundaryDays + ' vs. ' + startBoundary.toISODate() + 'startdiff:' + startBoundaryDays);

    /**
     * everything outside boundary is error for CLOSED range
     * */
    if (temporal?.range === 'CLOSED' && isOutsideOfAllowedClosedRange(temporal, startBoundaryDays, endBoundaryDays)) {
      return {dateNotInRange: true, translationErrorKey: temporal.error};
    }


    /**
     * everything outside one side of boundary is error
     * depending on direction one side is open
     * - this is default behavior if not set.
     * */
    if(temporal?.range === 'OPEN' && isOutsideOfAllowedOpenRange(temporal, direction, startBoundaryDays, endBoundaryDays)){
      return {dateNotInRange: true, translationErrorKey: temporal.error};
    }

    return null;
  }
}


/***
 * Validator responsible to check if some date is Before Or After Some Point in time.
 * Since referential value can be dynamic, we support duration object to specify that point.
 * for example valid date should be 6 years before DOB. or. input date must be after some value. ie. DOB
 * It could be Inclusive or exclusive if not set or set to 'NONE'.
 *
 * Movement in time for this validator is:
 * - to the LEFT, backwards to PAST when direction is BEFORE
 * - to the RIGHT, towards to the FUTURE when direction is AFTER
 *
 * By setting negative duration periods, we can shift to the left of initial date value.
 * */
export function dateBeforeOrAfterValidator(temporal: TemporalCriteria, direction: Direction): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    if (isEmptyInputValue(control.value)) {
      return null;  // don't validate empty values to allow optional validators
    }

    const dateFormat = temporal.format ? temporal.format : DEFAULT_FORMAT_ISO;
    const diffUnit = temporal.diffUnit ? temporal.diffUnit : DEFAULT_DIFF_UNIT;


    let startBoundary: DateTime;

    const inputDate = DateTime.fromFormat(control.value, dateFormat, {zone: 'utc'}).startOf(diffUnit);

    if (!inputDate.isValid) {
      return {notValidDate: true};
    }

    let tempDateRef: DateTime;

    if (temporal.controlRef) { // there is work on it. value subscription
      const controlRefValue = control.parent?.get(temporal.controlRef)?.value;
      tempDateRef = DateTime.fromFormat(controlRefValue, dateFormat, {zone: 'utc'}).startOf(diffUnit);
      startBoundary = tempDateRef;
    }

    if (temporal.value) {
      tempDateRef = DateTime.fromFormat(<string>temporal.value, dateFormat, {zone: 'utc'}).startOf(diffUnit);
      startBoundary = tempDateRef;
    }

    if (tempDateRef && !tempDateRef?.isValid) {
      // move out! and check for Backend value ref
      tempDateRef = DateTime.fromISO(<string>temporal.value, {zone: 'utc'}).startOf(diffUnit);

      if (tempDateRef && !tempDateRef?.isValid) {
        console.error('Date before value/control ref not properly defined!', tempDateRef);
        return {configurationError: true, translationErrorKey: 'DATE_VALUE_CONFIG_ERROR'};
      }
    }

    if (tempDateRef) {
      startBoundary = tempDateRef;
    }

    if (!tempDateRef) {
      console.warn('ControlRef or Value not set, initializing start as today.');
      startBoundary = DateTime.utc().startOf(diffUnit);
    }


    /** for dynamic values, like 6 months before today we can specify duration */
    if (temporal.duration) {
      startBoundary = startBoundary.plus(temporal.duration).startOf(diffUnit);
    }


    const days: number = inputDate.diff(startBoundary, diffUnit).days;
    logger('input:' + inputDate.toISODate() + ' vs startBoundary:' + startBoundary.toISODate() + ' DIFF is :' + days);


    if (direction === 'BEFORE' && isNotInclusive(temporal) && days >= 0) {
      return {dateIsNotBefore: true, translationErrorKey: temporal.error};
    }

    if (direction === 'BEFORE' && (isInclusive(temporal, 'START') || isInclusive(temporal, 'BOTH')) && days > 0) {
      return {dateIsNotBefore: true, translationErrorKey: temporal.error};
    }


    if (direction === 'AFTER' && isNotInclusive(temporal) && days <= 0) {
      return {dateIsNotAfter: true, translationErrorKey: temporal.error};
    }

    if (direction === 'AFTER' && (isInclusive(temporal, 'START') || isInclusive(temporal, 'BOTH')) && days < 0) {
      return {dateIsNotAfter: true, translationErrorKey: temporal.error};
    }

    return null
  }
}

export function dateIsValidFormat(format?: string) {
  return (control: AbstractControl): ValidationErrors | null => {
    if (isEmptyInputValue(control.value)) {
      return null;  // don't validate empty values to allow optional validators
    }

    const dateFormat = format ? format : DEFAULT_FORMAT_ISO;

    const dateTime = DateTime.fromFormat(control.value, dateFormat, {zone: 'utc'});

    if (!dateTime.isValid) {
      logger('Not valid date: ' + control.value + ' against format: ' + dateFormat)
      return {notValidDate: true, translationErrorKey: 'DATE_IS_NOT_VALID_FORMAT'};
    }
  }
}


export function isInclusive(temporal: TemporalCriteria, which: 'START' | 'END' | 'BOTH'): boolean {
  return temporal?.inclusive === which;
}

export function isAnySideInclusive(temporal: TemporalCriteria): boolean {
  return (temporal?.inclusive && temporal.inclusive !== 'NONE');
}

export function isNotInclusive(temporal: TemporalCriteria): boolean {
  return (!temporal?.inclusive || temporal.inclusive === 'NONE');
}


export function logger(...args) {
  if (LOG_VERBOSE) {
    console.log(args);
  }
}
