<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12">
    <p class="black_text">{{options.title}}</p>
    <hr>
  </div>
</div>
<div class="addCard m-b-10">
  <div class="row">
    <div class="col-xs-12 col-sm-4 col-md-3 dp-r-0" *ngFor="let data of options.data">
      <!-- <pre>
              {{data | json}}
          </pre> -->
      <a>
        <div class="clipCard" (click)="docClick(data['document_name'], data['doc_id'])">
          <div class="imgplaceholder">
            {{ translateDocName(data['document_name']) | slice:0:1 }}
          </div>
          <p class="wrap_word"> {{ translateDocName(data['document_name']) }} </p>
        </div>
      </a>
    </div>
  </div>
</div>
