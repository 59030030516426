import { Injectable } from '@angular/core';
import * as dateUtils from '../../framework/utils/date.utils';
import { TranslateService } from 'framework/i18n';
import { FormGroup } from '@angular/forms';
const enum DateFunctions {
  IsSame,
  IsSameOrAfter,
  IsBefore
}


@Injectable()
export class UtilService {
  private monthNames:any;
  minDate:any;
  constructor(
    private _ts: TranslateService
  ) { 
      let monthNames = this._ts.instant('MONTH_NAMES');
      this.setMonthNames(monthNames);
  }

  /**
   * Validate JSON response returned from Http request and see whether key exists before assigning it to class property.
   * Also checked whether value is not 'null', blank and 'undefined'.
   * @param data Response data which need to validated
   * @param key JSON Key which need to search and validate for value type
   * @param defaultValue If passed will return this value when validatation fails.
   * @returns If defaultValue passed then defaultValue / value of key passed else true / false
   */
  public inJson(data: any, key: string, defaultValue?: any): any {
    let returnData: any;

    const isDefault = (arguments.length === 3);

    if (this.isEmpty(data) || this.isEmpty(key)) {
      returnData = (isDefault) ? defaultValue : false;

      return returnData;
    }

    if (data && data.hasOwnProperty(key)) {
      if (this.isEmpty(data[key])) {
        returnData = (isDefault) ? defaultValue : false;
      } else {
        returnData = (isDefault) ? data[key] : true;
      }
    } else {
      returnData = (isDefault) ? defaultValue : false;
    }

    return returnData;
  }

  public IsPkgExistInWorkflow(arr, key) {
    return (arr.indexOf(key) !== -1) ? true : false;
  }


  /**
   * Validate JSON response returned from Http request and see whether any of Keys exists before assigning it to class property.
   * Also checked whether value is not 'null', blank and 'undefined'.
   * Multiple keys can be passed in array, but returns first available value or true. False or defaultValue is refurn none are found.
   * @param data Response data which need to validated
   * @param key Array of JSON Keys which need to search and validate for value type
   * @param defaultValue If passed will return this value when validatation fails.
   * @returns If defaultValue passed then defaultValue / value of key passed else true / false.
   */
  public inJsonEither(data: any, keys: any[], defaultValue?: any): any {
    let returnData;

    const isDefault = (arguments.length === 3);

    if (this.isEmpty(data) || this.isEmpty(keys)) {
      returnData = (isDefault) ? defaultValue : false;
      return returnData;
    }

    for (let i = 0; i < keys.length; i++) {
      const hasItem = this.inJson(data, keys[i]);

      if (hasItem) {
        returnData = (isDefault) ? this.inJson(data, keys[i], defaultValue) : hasItem;
        break;
      }
    }

    return returnData;
  }

  /**
   * Validate JSON response returned from Http request and see whether any of Keys exists before assigning it to class property.
   * Also checked whether value is not 'null', blank and 'undefined'.
   * Multiple keys can be passed in array, but retunrs first available value or true. False or defaultValue is refurn none are found.
   * @param data Response data which need to validated
   * @param key Array of JSON Keys which need to search and validate for value type
   * @param defaultValues If passed will return this value when validatation fails.
   * @returns If defaultValue passed then defaultValue / value of key passed else true / false.
   */
  public inJsonAll(data: any, keys: any[], defaultValues?: any[]): any {
    const isDefault = (arguments.length === 3);
    const returnData = {};
    const inJsonVal = [];

    if (isDefault) {
      if (keys.length !== defaultValues.length) {
        return null;
      }
    }

    for (let i = 0; i < keys.length; i++) {
      if (isDefault) {
        returnData[keys[i]] = this.inJson(data, keys[i], defaultValues[i]);
      } else {
        inJsonVal.push(this.inJson(data, keys[i]));
      }
    }

    if (isDefault) {
      return returnData;
    } else {
      return (inJsonVal.indexOf(false) < 0);
    }
  }

  /**
   * Get first available value of JSON property from Array of JSON objects.
   * @param data Response data which need to validated
   * @param key JSON Keys which need to search and validate for value type
   * @returns If value available then the value esle null.
   */
  public getFirstValue(data: Array<{}>, key: string): any {
    let returnData = null;

    if (this.isEmpty(data) || this.isEmpty(key)) {
      return returnData;
    }

    for (let i = 0; i < data.length; i++) {
      const item = this.inJson(data[i], key, '');

      if (!this.isEmpty(item)) {
        returnData = item;
        break;
      }
    }

    return returnData;
  }

  /**
   * Check whether object / array / string is empty or not.
   * @param obj Can be an Object or a Array. For other type it will return false.
   * @returns Boolen
   */
  public isEmpty(obj: any): boolean {

    if (typeof obj === 'string') {
      obj = obj.trim();
    }

    if (obj === null || obj === undefined || obj === '') {
      return true;
    }

    if (obj instanceof Array) {
      return (obj.length > 0) ? false : true;
    }

    if (typeof obj === 'object') {

      for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          return false;
        }
      }

      return JSON.stringify(obj) === JSON.stringify({});
    }

    return false;
  }


  /**
   * Clone object
   */
  public cloneObject(obj: any): any {
    return JSON.parse(JSON.stringify(obj));
  }

  public fixDecimalPlaces(value: any, numOfDecimal?: number): any {
    const decimalNum = (numOfDecimal) ? numOfDecimal : 2;
    const convertedVal = Math.round(value * 100) / 100;

    if (numOfDecimal === 0) {
      return parseInt(convertedVal.toString(), 10);
    } else {
      return parseFloat(convertedVal.toString()).toFixed(decimalNum);
    }
  }

 /**
   * Format alias start and end dates for clipboard and profile
   * @param element
   */
  public addAliasStartEndDates(element: Object) {
    let obj: Object = {};
    let startdate = new Date(!!element['from_date'] ? element['from_date'] : element['start-date']);
    let enddate = new Date(!!element['to_date'] ? element['to_date'] : element['end-date']);
    let formattedStartdate = dateUtils.formatMonthDateYear(new Date(startdate));
    let formattedEnddate = dateUtils.formatMonthDateYear(new Date(enddate));
    let fstart = dateUtils.getDateFormatMonthYear(formattedStartdate, this.monthNames);
    let fend = dateUtils.getDateFormatMonthYear(formattedEnddate, this.monthNames);
    obj['fstart'] = formattedStartdate;
    obj['fend'] = formattedEnddate;
    return obj;
  }

  /**
   * Checking validity of alias start date in dob component
   * @param startdate
   * @param date
   */
  public checkDateValidityForSameYears(startdate: any, date: any) {
    let startMonthIndex: number;
    let endMonthIndex: number;
    let obj: Object = {
      "startMonthIndex": startMonthIndex,
      "endMonthIndex": endMonthIndex
    };

    let stdate = dateUtils.getDateFormatMonthYear(startdate, this.monthNames);
    let startMonth = stdate.split(" ")[0];
    let startYear = stdate.split(" ")[1];
    let endMonth = date.split(" ")[0];
    let endYear = date.split(" ")[1];
    this.monthNames.forEach((value, index) => {
      if (value == startMonth) {
        startMonthIndex = index;
      }
      if (value == endMonth) {
        endMonthIndex = index;
      }
    })
    obj['startMonthIndex'] = startMonthIndex;
    obj['endMonthIndex'] = endMonthIndex;
    return obj;
  }

  public setMinDate(alobj:Object[]){
    this.monthNames = this._ts.instant('MONTH_NAMES');

    if(alobj.length==1){
      let date1 = dateUtils.formatMonthDateYear(new Date(alobj[0]['startDate']));
      this.minDate = dateUtils.getDateFormatMonthYear(date1,this.monthNames);
    }else{
      this.traverseAliasDateArrayForMin(alobj);
    }
    return this.minDate;
  }

  traverseAliasDateArrayForMinReview(arr: Object[],obj: Object,strtDt:any){
    let retObj:Object = {};
    let stDateModified:any;
    let firstElementMod:any;
    if(obj!==undefined && obj!==null){
      arr.push(obj);
    }
    if(strtDt!==undefined && strtDt!==null){
      stDateModified = this.getFormattedDateForReview(strtDt);
    }
    this.removeDuplicateFromAliasArray(arr,stDateModified,obj);
    for(let index in arr){
      if (arr.hasOwnProperty(index) && this.isArrayIndex(index)) {
        firstElementMod = this.getFormattedDateForReview(arr[index]['startDate']);
        retObj['mindate'] = firstElementMod;
        break;
      }
    }
   
    arr.forEach((element)=>{
      let stDate = element['startDate'];
      if(typeof(stDate) !== "string") {
        stDate = this.getFormattedDateForReview(stDate);
      }
        let minDate = stDate;
        if(retObj['mindate']!==undefined && retObj['mindate']!=="" && typeof retObj['mindate'] === 'string' && minDate!==null && minDate!==undefined){
          let splitObj = retObj['mindate'].split(" ");
          let minYear:string = minDate.split(" ")[1];
          let minMonth:string = minDate.split(" ")[0];
          let retMinYear:string = splitObj[1];
          let retMinMonth:string = splitObj[0];
          if(retMinYear > minYear){
            retObj['mindate'] = minDate;
          }
          if(retMinYear === minYear){
            let retObj = this.checkMaxForSameYears(retMinMonth,minMonth);
            if(retObj['startMonthIndex'] > retObj['maxMonthIndex']){
              minDate = retMinMonth +" "+retMinYear; 
            }
          }
        }
 
    })
    retObj['updatedArray'] = arr;
    return retObj;
  }

  traverseAliasDateArrayForMin(arr: Object[]){
    arr.forEach((element,index)=>{
      let stDate = element['startDate'];
      if(typeof(stDate) !== "string") {
        stDate = this.getFormattedDateForReview(stDate)     
      }
      if(stDate!== undefined && stDate!== null && this.minDate !== undefined && index!==0){
        this.setAliasDateMin(stDate,element);
      } 
    })
  }

  setMinDateFromReview(alobj:Object[],obj,stDate){
    let retObj = {};
    retObj = this.traverseAliasDateArrayForMinReview(alobj,obj,stDate);
    return retObj;
  }

  public checkMaxForSameYears(month,minMonth){
    let indexes = {
      "startMonthIndex": 0,
      "maxMonthIndex": 0
    }
    let monthNames:any = this._ts.instant('MONTH_NAMES');
    monthNames.forEach((value, index) => {
        if (value == month) {
          indexes["startMonthIndex"] = index;
        }
        if (value == minMonth) {
          indexes["maxMonthIndex"] = index;
        }
      })
      return indexes;
  }

  public checkMinStartDate(obj: Object) {
    let monthNames:any = this._ts.instant('MONTH_NAMES');
    if(obj['start-date']!==undefined && obj['start-date']!==null &&
        obj['end-date']!==undefined && obj['end-date']!==null){
          obj['startDate'] = obj['start-date'];
          obj['endDate'] = obj['end-date'];
    }
    let stDate = obj['startDate'];
    if(stDate!==undefined && typeof stDate !=='string'){
      let formattedStartdate = dateUtils.formatMonthDateYear(new Date(stDate));
      stDate = dateUtils.getDateFormatMonthYear(formattedStartdate,monthNames);
    }
    //case when user enter alias for 1st time 
    if(this.minDate === undefined || this.minDate===''){
      if(typeof(obj['startDate']) !== "string" 
                 && typeof(obj['endDate']) !== "string") {
        let date1 = dateUtils.formatMonthDateYear(new Date(obj['startDate']));
        obj['startDate'] = dateUtils.getDateFormatMonthYear(date1,monthNames);
        this.minDate = obj['startDate'];
      }else{
        this.minDate = obj['startDate'];
      }
    }
    //when user enters subsequent start dates for aliases
    else if(obj['startDate']!==undefined || obj['startDate']!==null && this.minDate !== undefined){
          if(typeof(obj['startDate']) !== "string"
                 && typeof(obj['endDate']) !== "string") {
                  let date1 = dateUtils.formatMonthDateYear(new Date(obj['startDate']));
                  obj['startDate'] = dateUtils.getDateFormatMonthYear(date1,monthNames);         
          }
          let minMonth = this.minDate.split(" ")[0];
          let minYear = this.minDate.split(" ")[1];
          let month = stDate.split(" ")[0];
          let year = stDate.split(" ")[1];
          if(year < minYear){
            this.minDate = obj['startDate'];
          }
          if(year === minYear){
            let retObj = this.checkMaxForSameYears(month,minMonth);
            let startMonthIndex: number;
            let minMonthIndex: number;
            if(startMonthIndex < minMonthIndex){
              this.minDate = month +" "+year;
            }
          }
    }
    return this.minDate;
  }

  /**
   * check first name in alias form
   * @param obj
   */
  public checkFirstName(obj:any){
    let firstNameEntered: boolean;
    if (obj.firstname != "" && obj.firstname !== undefined) {
      firstNameEntered = true;
    } else {
      firstNameEntered = false;
    }
    return firstNameEntered;
  }

  /**
   * check last name in alias form
   * @param obj
   */
  public checkLastName(obj:any){
    let lastNameEntered: boolean;
    if (obj.lastname != "" && obj.lastname !== undefined) {
      lastNameEntered = true;
    } else {
      lastNameEntered = false;
    }
    return lastNameEntered;
  }

  /**
   * check end date in alias form
   * @param obj
   */
  public checkEndDate(obj:any){
    let endDateEntered: boolean;
    if (obj.endDate != "" && obj.endDate !== undefined && !(obj.endDate instanceof Date) && obj.endDate!=null) {
      endDateEntered = true;
    } else {
      endDateEntered = false;
    }
    return endDateEntered;
  }

  /**
   * check start date in alias form
   * @param obj
   */
  public checkStartDate(obj:any){
    let startDateEntered: boolean;
    if (obj.startDate != "" && obj.startDate !== undefined && !(obj.startDate instanceof Date) && obj.startDate!=null &&
    obj.endDate != "" && obj.endDate !== undefined && !(obj.endDate instanceof Date) && obj.endDate!=null) {
      startDateEntered = true;
    } else {
      startDateEntered = false;
    }
    return startDateEntered;
  }

  /**
   * Validation of start and end dates in alias form
   * @param aliasNameSubForm
   * @param dob
   */
public validateDates(aliasNameSubForm: FormGroup,dob){
  let returnObj = {
    "dobCheckCustom": true,
    "isAliasEntered": false,
    "startDateEnteredCustom": true
  };
  let startMonthIndex: number;
  let endMonthIndex: number;
  let startDate: any;
  let endDate: any;
  startDate = aliasNameSubForm.get('startDate').value;
  endDate = aliasNameSubForm.get('endDate').value;
 
  if (!!startDate && !!endDate && typeof (startDate) === 'string' && typeof (endDate) === 'string') {
    startDate = dateUtils.formatToISODate(startDate, this.monthNames);
    let startMonth = startDate.split("-")[1];
    let startYear = startDate.split("-")[0];

    if(dob!==undefined && dob!==null && dob!==""){
      let diff = dateUtils.dateDiffInYearsForDobAliasDates(dob,startDate);
      let dobIndex: number;
      let stDobIndex: number;
      let dobDate  = dateUtils.getDateFormatMonthYear(dob,this.monthNames);
      let endMonthDob = dobDate.split(" ")[0];
      let endYearDob = dobDate.split(" ")[1];
        if(diff<0){
            returnObj['dobCheckCustom'] = false;
            returnObj['isAliasEntered'] = false;
          return returnObj;
        }
        if(diff==0){
          this.monthNames.forEach((value, index) => {
            if (value === startMonth) {
              stDobIndex = index;
            }
            if (value === endMonthDob) {
              dobIndex = index;
            }
          })
          if (stDobIndex < dobIndex) {
              returnObj['dobCheckCustom'] = false;
            return returnObj;
          }
        }
    }

    endDate = dateUtils.formatToISODate(endDate, this.monthNames);
    let endMonth = !!endDate ?  endDate.split("-")[1] : '';
    let endYear = !!endDate ?  endDate.split("-")[0] : '';
    if (!!startYear && !!endYear && startYear > endYear) {
      //show custom message
       returnObj['startDateEnteredCustom'] = false;
       returnObj['isAliasEntered'] = false;
      return returnObj;
    }
    if (!!startYear && !!endYear && startYear === endYear) {
      this.monthNames.forEach((value, index) => {
        if (value === startMonth) {
          startMonthIndex = index;
        }
        if (value === endMonth) {
          endMonthIndex = index;
        }
      })
      if (startMonthIndex > endMonthIndex) {
        returnObj['isAliasEntered'] = false;
        returnObj['startDateEnteredCustom'] = false;
        return returnObj;
      }

    }
  }
  let firstName = aliasNameSubForm.get('firstname').value;
  let lastName =  aliasNameSubForm.get('lastname').value;
  if(startDate == "" || endDate == "" || startDate===null || endDate===null || firstName == "" || lastName==""){
    returnObj['isAliasEntered'] = false;
  }else{
    returnObj['isAliasEntered'] = true;
  }
  return returnObj;
}

  public convertToLowerCase(name) {
    let val = name.toLowerCase();

    return val;
  }


  /**
   * convert form dates to MMM, YYYY format
   * @param aliasNameSubForm
   * @param fst
   * @param fend
   */
  public getFormattedDate(fst,fend){
    let d1 = dateUtils.getDateFormatMonthYear(fst,this.monthNames);
    let d2 = dateUtils.getDateFormatMonthYear(fend,this.monthNames);
    return {
      "str": d1,
      "end": d2
    }
  }

  /**
   * get date in MMM, YYYY format for validation with alias dates
   * @param date
   */
  public getformattedDateForDobValidation(date){
    let date1 = dateUtils.formatMonthDateYear(new Date(date));
    date1 = dateUtils.getDateFormatMonthYear(date1,this.monthNames);
    return date1;
  }

  /**
   * Extract array from json array formatted string
   *
   * @param types
   */
  getArrayFromJsonString(types: string): Array<Object> {
    if (!this.isEmpty(types)) {
      try {
        return JSON.parse(types);
      } catch(error) {
        console.log('Failed to parse from array : ' + types);
      }
    } else {
      return null;
    }
  }

  /**
   * Get value of EA preference by key.
   * @param preferences array if ea preferences which need to validated
   * @param key JSON Keys which need to search and validate for value type
   * @returns If value available then the value else null.
   */
  public getEaPreferenceValueByKey(preferences: Array<{}>, key: string): any {
    let value = null;

    if (this.isEmpty(preferences) || this.isEmpty(key)) {
      return value;
    }

    for (let prefer of preferences) {
      if (prefer['id'] === key) {
        if (!this.isEmpty(prefer['value'])) {
          value = prefer['value'];
        }
        break;
      }
    }
    return value;
  }

  getFormattedDateForReview(date:any){
    let element = dateUtils.formatMonthDateYear(new Date(date));
    let modifiedDate = dateUtils.getDateFormatMonthYear(element,this.monthNames); 
    return modifiedDate;
  }

  setAliasDateMin(stDate,element){
    let minMonth = this.minDate.split(" ")[0];
    let minYear = this.minDate.split(" ")[1];
    let month = stDate.split(" ")[0];
    let year = stDate.split(" ")[1];
    if(year < minYear){
      this.minDate = element['startDate'];
    }
    if(year === minYear){
      let retObj = this.checkMaxForSameYears(month,minMonth);
      if(retObj['startMonthIndex'] > retObj['maxMonthIndex']){
        this.minDate = month +" "+year; 
      }
    }
  }

  setMonthNames(monthNames){
    this.monthNames = monthNames;
  }

  getMonthNames(){
    return this.monthNames;
  }

  isArrayIndex(n) {
    return !isNaN(parseInt(n, 10));
  }

  removeDuplicateFromAliasArray(arr:Array<Object>,stDateModified,obj){
    arr.forEach((element,index)=>{
      let stDate = element['startDate'];
      if(typeof(stDate) !== "string") {
        stDate = this.getFormattedDateForReview(stDate);
      }
      if(stDateModified!==undefined){
        if(stDate === stDateModified && element['id']===obj['id']){
          delete arr[index];
        }
      }
    })
  }

  public isKeyExistInArray(arr, key) {
    let val = -1;
    for (let j = 0; j < arr.length; j++) {
        if (arr[j]['key'] === key) {
          val = j;
          break;
        }
    }
    return val;
  }

  /**
   * This method is used to set the type description to display in clipboard/profile pages.
   * EX, professional license, employment sections are displaying cards with id/key of the type instead of description
   * 
   * @param obj  - Data for professional license, employment and etc..
   * @param type - type to looks up and get array from translation object
   * @param sourceElement - element to find out type id/key from obj
   * @param targetElement - element to set type description
   * @param subTargetIndex - To find the index and bind the value directly to the parent obj
   */
  public setTypeValues(obj: any, type: string, sourceElement: string, targetElement, subTargetIndex?: number) {
    let typesObj = {};
    // get types array from translation object
    let types: any = this.getArrayFromJsonString(this._ts.translate(type));
    // check types is not empty then continue
    if (!!types && types.length > 0) {
      for (let type of types) {
        // continue here if type contains id
        if (!!type.id) {
          // continue here if type has name else continue in else block
          if (!!type.name) {
            typesObj[type.id] = type.name;
          } else if (!!type.value) {
            typesObj[type.id] = type.value;
          } else {
            // set id as value if nothing found
            typesObj[type.id] = type.id;
          }
          
        } 
        // continue here if type contains key
        else if (!!type.key) {
          // continue here if type has name else continue in else block
          if (!!type.name) {
            typesObj[type.key] = type.name;
          } else if (!!type.value) {
            typesObj[type.key] = type.value;
          } else {
            // set id as value if nothing found
            typesObj[type.key] = type.key;
          }
        } else {
          // do nothing
        }
      }
    }

    // add target element with type description
    if (!!obj['data'] && obj['data'].length > 0) {
      if (subTargetIndex === undefined) {
        for (let index = 0; index < obj['data'].length; index++) {
          obj['data'][index][targetElement] = typesObj[obj['data'][index][sourceElement]];
        }
      } else {
        obj['data'][subTargetIndex][targetElement] = typesObj[obj['data'][subTargetIndex][sourceElement]];
      }
    }
  }

  getPaPreferenceValueByKey(preferences, key: string) {
    let value;
    if (preferences && preferences[key]) {
      value = preferences[key];
    }
    return value;
  }

  getCryptoRandom() {
    return window.crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1)
  }
}