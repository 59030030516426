import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'framework/localstorage.service';
import { WorkflowService } from 'framework/workflow.service';
import { Observable } from 'rxjs';
import { AppConfig } from '../../app.config';
import { FieldValidationRule } from './model/field-validation-rule';
import { SharedService } from '../services/shared.service';
import { ValidationRulesFallbackResolver } from './model/validation-rules-fallback-resolver';
import { RuleFormGroupNames, RuleWorkflow } from './model/validation-rules-config';

@Injectable({
  providedIn: 'root'
})
export class FieldValidationService {

  private API_URL: string = `${AppConfig.API_ENDPOINT()}/api/v1/workflow`;

  constructor(
    private httpClient: HttpClient,
    private workflowService: WorkflowService,
    private localStorage: LocalStorageService,
    private sharedService: SharedService) {
    // intentionally left empty
  }

  public resolveValidationRuleFromWorkflow(response: any, formGroup: RuleFormGroupNames): FieldValidationRule[] {
    let data: FieldValidationRule[];
    if (response && response['validations']) {
      data = <Array<FieldValidationRule>>response['validations'][formGroup];
    }

    if (!data) {
      const workflow = this.sharedService.checkInviteIsKYP() ? RuleWorkflow.KYP : RuleWorkflow.DEFAULT;
      data = ValidationRulesFallbackResolver.resolve(workflow, formGroup);
    }

    return data;
  }

  /** fetches validation rule from service or fallback **/
  public fetchValidationRule(formGroup: RuleFormGroupNames, workflow?: RuleWorkflow): FieldValidationRule[] {
    let validationRules: FieldValidationRule[];
    if (!workflow) {
      workflow = this.sharedService.checkInviteIsKYP() ? RuleWorkflow.KYP : RuleWorkflow.DEFAULT;
      console.log('resolved workflow type:', workflow);
    }

    console.log('start fetching');
    this.fetchValidationRules$(formGroup, workflow)

      .subscribe(value => {
        console.log('fetched validation rules from engine', value);
        validationRules = value;
      }, error => {
        console.error('Validation rule service returned error, falling back to static rule definitions');
        validationRules = ValidationRulesFallbackResolver.resolve(workflow, formGroup);
      }, () => {
        if (!validationRules) {
          console.warn('Validation rule service returned empty response, falling back to static rule definitions in complete');
          validationRules = ValidationRulesFallbackResolver.resolve(workflow, formGroup);
        }

      });

    if (validationRules === undefined) {
      console.warn('No validation rules found, fallback to config !!!!');
      validationRules = ValidationRulesFallbackResolver.resolve(workflow, formGroup);
    }


    return validationRules;
  }


  /**
   * Service method that returns list of validation rules for some formGroup.
   * If no workflow is specified, than it is resolved automatically between KYP and DEFAULT.
   *
   * For specifying anything else override workflow with parameter.
   * If calling this method with new workflow: ie. 'TESTWF' than be sure to update ValidationRulesConfig.
   * **/
  public fetchValidationRules$(formGroup: RuleFormGroupNames, workflow?: RuleWorkflow): Observable<FieldValidationRule[]> {

    if (!workflow) {
      workflow = this.sharedService.checkInviteIsKYP() ? RuleWorkflow.KYP : RuleWorkflow.DEFAULT;
    }

    const options = this.workflowService.getHeaderOptions();
    const workflowId = this.localStorage.getItem('workflow_instance_id');

    const url = `${this.API_URL}/${workflowId}/field-validation-rules?type=${workflow}&subType=${formGroup}`;

    return this.httpClient.get<FieldValidationRule[]>(url, {headers: options.headers});
  }

}



