import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
    providers: [],
    template: ''
})

export class ErrorInfoComponent {
    errorInfo() {
        $(".error_info_popup").attr("data-close", false);

        //click on pop-up opener. pop-up is expected to be a child of opener
        $(".error_info").click(function () {
            var $title = $(this).find(".error_info_popup");
            //open if not marked for closing
            if ($title.attr("data-close") === "false") {
                $(this).find(".error_info_popup").css('display', 'table');
                $title.show();
            }
            //reset popup
            $title.attr("data-close", false);
        });

        //mark pop-up for closing if clicked - on close is initiated by document.mouseup, marker will stop opener from re-opening it
        $(".error_info_popup").click(function () {
            $(this).attr("data-close", true);
        });

        $(".error_info_popup .close").click(function () {
            $(".error_info_popup").hide();
        });

        $(".error_info_popup .fa").click(function () {
            $(".error_info_popup").hide();
        });

        //hide all pop-ups
        //$(document).mouseup(function () {
        //    $(".error_info_popup").hide();
        //});

        //show on rollover if mouse is used
        /* $(".error_info").mouseenter(function () {
            var $title = $(this).find(".error_info_popup");
            $title.show();
        });*/

        //hide on roll-out
        //$(".error_info").mouseleave(function () {
        //    var $title = $(this).find(".error_info_popup");
        //    $title.hide();
        //});
    }

}