import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'disagree-terms',
  templateUrl: './disagree-terms.component.html',
})

export class DisagreeTermsComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
