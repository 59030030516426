import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'profile-header',
  templateUrl: './profile-header.component.html',
  providers: []
})

export class ProfileHeaderComponent {
  @Input() userFirstName: string;

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }
}