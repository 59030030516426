import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LocalStorageService } from 'framework/localstorage.service';
import { EventBusService } from 'framework/eventbus.service';

declare var jQuery: any;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private localStorage: LocalStorageService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let authHeader = request.headers.get('Authorization');
    let authReq = request;
    if (!!authHeader && authHeader !== 'Bearer undefined') {
      let newToken = 'Bearer ' + this.localStorage.getItem('access_token');
      if (newToken !== authHeader) {
        authReq = request.clone({
          headers: request.headers.set('Authorization', newToken)
        });
      }
    }

    return next.handle(authReq).pipe(
      map(res => { return res; }),
      catchError(err => this._handleError(err))
    );
  }

  private isMFAError(error: {}) {
    if(!!error && !!error['error']) {
      error = error['error'];
    }
    return !!(!!error && !!error['additionalProperties'] && !!error['additionalProperties']['additional_error_detail'] && !!error['additionalProperties']['additional_error_detail']['action'] && error['additionalProperties']['additional_error_detail']['action']==='INVALID_PASSWORD_UP');
  }

  private _handleError(error: any) {
    if (!!error && error.status === 401) {
      let errorMessge = '';
      let _body = error || {};
      if (!!_body['error']) {
        errorMessge = _body['error']['error'];
      }
      const isMFAError = this.isMFAError(_body);
      if (!!isMFAError || (!!errorMessge && (errorMessge.startsWith("INVALID_LOGIN#") || errorMessge.startsWith("Invalid Username") || errorMessge.startsWith("SECRET_QUESTION_ANSWER_MISMATCH#")))) {
        //Invalid login or MFA Error scenario to be handled by OIDC Error handler
      } else {
        if (!!errorMessge && (errorMessge.startsWith("Invalid Authorization"))) {
          EventBusService.sessionInvalidReceivedAnnounced({
            isSessionValid: true
          });
        }
        let isAuthModelOpened = jQuery('#auth-error-modal').hasClass('in');
        if (!isAuthModelOpened) {
          // closes all active pop ups.
          jQuery('.modal').modal('hide');
          jQuery('.modal-backdrop').remove();
        }
        //show auth error popup.
        jQuery('#auth-error-modal').modal('show');
        
        return of(null);
      }
    }
    return throwError(error);
  }
}
