export class LanguageConstants {
    static diplayLanguageList = {
        'bg': {languageName: 'Bulgarian', flagIconName: 'bg'},
        'cs': {languageName: 'Czech', flagIconName: 'cz'},
        'de': {languageName: 'Deutsch', flagIconName: 'de'},
        'en': {languageName: 'English', flagIconName: 'us'},
        'en-gb': {languageName: 'English', flagIconName: 'gb'},
        'es': {languageName: 'Español', flagIconName: 'es'},
        'es-es': {languageName: 'Español', flagIconName: 'es'},
        'es-419': {languageName: 'Español LatAm', flagIconName: 'mx'},
        'fr': {languageName: 'Français', flagIconName: 'fr'},
        'fr-ca': {languageName: 'Français canadien', flagIconName: 'ca'},
        'hu' : {languageName: 'Magyar', flagIconName: 'hu'},
        'id': {languageName: 'Indonesian', flagIconName: 'id'},
        'it': {languageName: 'Italiano', flagIconName: 'it'},
        'ja': {languageName: '日本語', flagIconName: 'jp'},
        'ko': {languageName: 'Korean', flagIconName: 'kr'},
        'nl': {languageName: 'Dutch', flagIconName: 'nl'},
        'pl': {languageName: 'Polski', flagIconName: 'pl'},
        'pt' : {languageName: 'Português', flagIconName: 'pt'},
        'pt-br' : {languageName: 'Português LatAm', flagIconName: 'br'},
        'ro': {languageName: 'Romanian', flagIconName: 'ro'},
        'sk': {languageName: 'Slovak', flagIconName: 'sk'},
        'sv-se': {languageName: 'Swedish', flagIconName: 'se'},
        'th': {languageName: 'Thai', flagIconName: 'th'},
        'tr': {languageName: 'Turkish', flagIconName: 'tr'},
        'vi': {languageName: 'Vietnamese', flagIconName: 'vn'},
        'zh-cn': {languageName: '简体中文', flagIconName: 'cn'},
        'zh-hk': {languageName: '繁體中文', flagIconName: 'hk'},
        'zh-sg': {languageName: '简体中文 Singapore', flagIconName: 'cn'},
        'zh-my': {languageName: '简体中文 Malaysia', flagIconName: 'cn'},
        'zh-tw': {languageName: '繁體中文-台灣', flagIconName: 'tw'},
    };
}