export class EmploymentConfig {
    static MinYear: number = 7;
    static GapLimit: number = 2; // Months
    static EMPLOYMENT_CATEGORIES ="EMPLOYMENT_CATEGORIES";
    static EMPLOYMENT_CATEGORIES_CURRENT ="EMPLOYMENT_CATEGORIES_CURRENT";
    static EMP_CONTACT_DEFAULT_TIME_FRAME: number = 30;
    static EMP_FUTURE_CONTACT_KEY = '1007';
    static EMP_FUTURE_CONTACT_TIME_FRAME_KEY = '1117';
    static SELF_EMPLOYMENT_CHECK_REQUIRED_KEY = '1204';
    static FAA_SAFETY_CHECK_REQUIRED_KEY = '1373';
    static DOT_LICENCE_PREFERENCE_KEY = '1086';
    static EMPLOYMENT_JOB_TYPES = 'JOB_TYPES';
    static EMPLOYMENT_MULTI_POSITION = 'allow_multi_position';
    static EMPLOYMENT_GAP_IN_DAYS = '1438';
    static EMPLOYMENT_CATEGORY_NEVER_EMPLOYED = '1441';
    static EMPLOYER_PHONE_EMAIL_OPTIONAL_PREF = '1440';
    static EMPLOYER_PHONE_OPTIONAL_PREF_VALUE = 'EMPLOYER_PHONENUMBER';
    static EMPLOYER_EMAIL_OPTIONAL_PREF_VALUE = 'EMPLOYER_EMAILID';
    static PRESENT_EMP_AUTO_FULFILMENT = '1462';
    static PRESENT_EMP_PLAID_PREF = '1552';
    static EMP_SOURCE_CITADEL = 'CITADEL';
    static EMP_SOURCE_PLAID = 'PLAID';
    static REPORTING_MANAGER_SKIP = '1459';
    static AGENCY_NAME = 'Agency Name';

    static empSteps: any = [
        'verification',
        'category',
        'country',
        'city-state',
        'verification-confirm',
        'company-name',
        'reporting-manager-details',
        'position',
        'intl-emp',
        'job-period',
        'job-title',        
        'reconfirm'      
    ];

    static employmentCategories = [
        {key: 'prior', value : 'EMPLOYMENT_TYPE_2'},
        {key: 'selfEmployed', value : 'EMPLOYMENT_TYPE_3'},
        {key: 'unemployed', value : 'EMPLOYMENT_TYPE_4'},
        {key: 'inSchool', value : 'EMPLOYMENT_TYPE_5'},
        {key: 'internship', value : 'EMPLOYMENT_TYPE_6'}
    ];
    static employmentCategoriescurrent =[
         {key: 'current', value : 'EMPLOYMENT_TYPE_1'},
         {key: 'selfEmployed', value : 'EMPLOYMENT_TYPE_3'},
        {key: 'unemployed', value : 'EMPLOYMENT_TYPE_4'},
        {key: 'inSchool', value : 'EMPLOYMENT_TYPE_5'},
        {key: 'internship', value : 'EMPLOYMENT_TYPE_6'}
    ]

    static defaultCountry: string = '';
    static defaultCountryShortName: string = '';
    static subStepIndexMapping: Object = {
        verification: {
            subStep: 'verification',
            subStepIndex: 0
        },
        category: {
            subStep: 'category',
            subStepIndex: 1
        },
        country: {
            subStep: 'country',
            subStepIndex: 2
        },
        city_state: {
            subStep: 'city-state',
            subStepIndex: 3
        },
        verification_confirm: {
            subStep: 'verification-confirm',
            subStepIndex: 4
        },
        company_name: {
            subStep: 'company-name',
            subStepIndex: 5
        },
        reporting_manager_details: {
            subStep: 'reporting-manager-details',
            subStepIndex: 6
        },
        emp_position: {
            subStep: 'position',
            subStepIndex: 7
        },
        intl_emp: {
            subStep: 'intl-emp',
            subStepIndex: 8
        },
        job_period: {
            subStep: 'job-period',
            subStepIndex: 9
        },  
        job_title: {
            subStep: 'job-title',
            subStepIndex: 10
        },    
        reconfirm: {
            subStep: 'reconfirm',
            subStepIndex: 11
        },
    };
   
    static headerSubheaders = [{
        'header': 'Employment Information',
        'sub_header': 'Employment Information'
    },
    {
        'header': 'Employment Information',
        'sub_header': 'Employment Information'
    }];

    static getHeaderSubheaders(): any {
        return EmploymentConfig.headerSubheaders;
    }       

    static defaultCountryOptions(): Object {
        let countryOptions = {};
        countryOptions['defaultCountry'] = EmploymentConfig.defaultCountry;
        countryOptions['defaultCountryShortName'] = EmploymentConfig.defaultCountryShortName;
        return countryOptions;
    }
    

    static getSteps(): any {
        return EmploymentConfig.empSteps;
    }

    static getMin(): number {
        return EmploymentConfig.MinYear;
    }

    static getGapLimit(): number {
        return EmploymentConfig.GapLimit;
    }

    static addNewStepAndIndexMapping(stepName: string) {
        let len = this.empSteps.length;
        this.empSteps.splice(len - 1, 0, stepName);

        this.subStepIndexMapping[stepName] = {
            subStep: stepName,
            subStepIndex: len - 1
        };
        this.subStepIndexMapping['reconfirm']['subStepIndex'] = this.subStepIndexMapping['reconfirm']['subStepIndex'] + 1;
    }

    static resetStepAndIndexMapping(stepName: string) {
        let index = this.empSteps.indexOf(stepName);
        if (index != -1) {
            this.empSteps.splice(index, 1);
        }
        if (this.subStepIndexMapping[stepName]) {
            delete this.subStepIndexMapping[stepName];
            this.subStepIndexMapping['reconfirm']['subStepIndex'] = this.subStepIndexMapping['reconfirm']['subStepIndex'] - 1;
        }
    }
    
    static addNewStepAndIndexMappingAtIndex(stepName: string, index: number) {
        let len = this.empSteps.length;
        this.empSteps.splice(index, 0, stepName);
        //update the substepIndex for the remaining substeps
        this.updateIndexMappingFromIndex(index, 1);
        let indexName = stepName.replace('-', '_');
        this.subStepIndexMapping[indexName] = {
            subStep: stepName,
            subStepIndex: index
        };
    }

    static deleteStepAndIndexMapping(stepName: string) {
        let index = this.empSteps.indexOf(stepName);
        if (index != -1) {
            this.empSteps.splice(index, 1);
        }
        let indexName = stepName.replace('-', '_');
        if (this.subStepIndexMapping[indexName]) {
            delete this.subStepIndexMapping[indexName];
            //update the substepIndex for the remaining substeps
            this.updateIndexMappingFromIndex(index, -1);
        }
    }

    static updateIndexMappingFromIndex(index: number, updateValue: number) {
        let subStepKeys = Object.keys(this.subStepIndexMapping);
        let keyLen = subStepKeys.length;
        for (let i = 0; i < keyLen; i++) {
            let key = subStepKeys[i];
            let obj = this.subStepIndexMapping[key];
            let substepIndex = obj['subStepIndex'];
            if (substepIndex >= index) {
                obj['subStepIndex'] = obj['subStepIndex'] + updateValue;
            }
        }
    }

    static ats: Object = {
        'id' : '',
        'type' : '',
        'name' : '',
        'contact' : '',
        'contact-number' : '',
        'permission-to-contact' : '',
        'when-to-contact' : '',
        'country' : '',
        'same_country' : '',
        'state_code' : '',
        'city' : '',
        'job-title' : '',
        'has_driver_duties' : '',
        'reason_for_leaving' : '',
        'start-date' : '',
        'end-date' : '',
        'zip' : '',
        'status' : '',
        'country_name' : '',
        'state_name' : '',
        'has_employment' : '',
        'confirm' : '',
        'is_current' : '',
        'reconfirm' : '',
        'verification' : '',
        'current_employment_confirm' : '',
        'prior_employment_confirm' : '',
        'company_name_in_local_lang' : '',
        'company_address' : '',
        'manager_name' : '',
        'manager_position' : '',
        'manager_country_code' : '',
        'manager_phone_number' : '',
        'manager_email' : '',
        'source' : '',
        'employment_position_list' : [],
        'employee_id' : '',
        'department' : '',
        'form' : '',
        'pa_sync_id': undefined,
        'state' : {
            'step_status' : '', 
            'stage_status' : '', 
            'current_iteration' : '', 
            'current_stage' : '', 
            'current_step' : '', 
            'current_step_index' : '', 
            'current_sub_step' : '', 
            'current_sub_step_index' : '', 
            'current_status' : ''
        }
    };

    static regular: Object = {
        'id' : '',
        'type' : '',
        'name' : '',
        'contact' : '',
        'contact-number' : '',
        'permission-to-contact' : '',
        'when-to-contact' : '',
        'country' : '',
        'same_country' : '',
        'state_code' : '',
        'city' : '',
        'job-title' : '',
        'has_driver_duties' : '',
        'reason_for_leaving' : '',
        'start-date' : '',
        'end-date' : '',
        'zip' : '',
        'status' : '',
        'country_name' : '',
        'state_name' : '',
        'has_employment' : '',
        'confirm' : '',
        'is_current' : '',
        'reconfirm' : '',
        'verification' : '',
        'current_employment_confirm' : '',
        'prior_employment_confirm' : '',
        'company_name_in_local_lang' : '',
        'company_address' : '',
        'manager_name' : '',
        'manager_position' : '',
        'manager_country_code' : '',
        'manager_phone_number' : '',
        'manager_email' : '',
        'source' : '',
        'employment_position_list' : [],
        'employee_id' : '',
        'department' : '',
        'form' : '',
        'pa_sync_id': undefined, //for review and edit
        'state':{
            'step_status' : '', 
            'stage_status' : '', 
            'current_iteration' : '', 
            'current_stage' : '', 
            'current_step' : '', 
            'current_step_index' : '', 
            'current_sub_step' : '', 
            'current_sub_step_index' : '', 
            'current_status' : ''
        }
    };

    static displayLabel: Object = {
      'City':'',
      'type':'',
      'Country':'',
      'Start Date':'',
      'Source':'',
      'End Date':'',
      'State':'',
      'Job Title':'',
      'Name'  :  '',
      'delete_link' : '',
      'when-to-contact':'',
      'employee_id' : '',
      'department' : '',
      'is_current' : '',
      'display_card': true,
    };
    static finalEmpCardList = [ 'position',
    'intl-emp',
    'job-period',
    'job-title'];

    /**
     * get employment categories
     */
    static getEmploymentCategories() {
        return [
            {key: 'current', value : 'EMPLOYMENT_TYPE_1'},
            {key: 'prior', value : 'EMPLOYMENT_TYPE_2'},
            {key: 'selfEmployed', value : 'EMPLOYMENT_TYPE_3'},
            {key: 'unemployed', value : 'EMPLOYMENT_TYPE_4'},
            {key: 'inSchool', value : 'EMPLOYMENT_TYPE_5'},
            {key: 'internship', value : 'EMPLOYMENT_TYPE_6'}
        ];
    }

    /**
     * get employment category by given gategory
     * 
     * @param category 
     */
    static getEmploymentCategory(category: string) {
        let categories = this.getEmploymentCategories();
        for (let currentCategory of categories) {
            if (currentCategory['key'].toUpperCase() === category.toUpperCase()) {
                return currentCategory['value'];
            }
        }

        return null;
    }
    
    static employmentPlaidCountries = ['US'];
    
    static employmentPlaidProduct = ["auth"];
}
