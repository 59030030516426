<div class="body_container container m-t-20 mar10 mobile_container">
  <div class="anime-clean signup">
    <div class="row neg_mar">
      <!-- Image Section Starts here-->
      <div class="col-md-6 col-sm-6 hidden-xs forgot_bg">
        <!--<img src="../../assets/img/illustration01.png">-->
        <h3 *ngIf="!isInviteEiaf" class="text-center m-t-10 pad5">{{'FORGOT_HEADER_1'|translate }}</h3>
        <h3 *ngIf="isInviteEiaf" class="text-center m-t-10 pad5">{{'FORGOT_EIAF_HEADER_2'|translate }}</h3>
      </div>
      <!-- Image Section Ends here-->
      <!-- Sign up Form Starts here-->
      <div class="col-xs-12 col-sm-6 col-md-6 signup_form pad30 pad0">
        <div class="row mobile-header visible-xs">
          <div class="col-xs-12 ">
            <div class="header_txt_white">
              <h2 *ngIf="!isInviteEiaf">{{'FORGOT_HEADER_1'|translate }}</h2>
              <h2 *ngIf="isInviteEiaf">{{'FORGOT_EIAF_HEADER_1'|translate }}</h2>
              <!--<h4 class="m-t-10">Already have a Profile ? - <a (click)="redirectSignin()">Sign in</a></h4>-->
            </div>
          </div>
        </div>
        <div class="row m-t-40 pad15">
          <div class="col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 col-xs-12">
            <div class="header_txt_white">
              <h2 *ngIf="!isInviteEiaf">{{'FORGOT_HEADER_2'|translate }}</h2>
              <h2 *ngIf="isInviteEiaf">{{'FORGOT_EIAF_HEADER_2'|translate }}</h2>
            </div>
          </div>
        </div>
        <form class="m-t-40 pad15" autocomplete="off" #forgtPwdForm="ngForm"
          (ngSubmit)="forgotPwdLogin(forgtPwdForm.value, forgtPwdForm.valid)">
          <div class="row">
            <div class="col-xs-12 col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 dp-r-0 m-b-20">
              <div class="group">
                <label class="placeholder_white custom_label" for="inputEmail">{{'EMAIL_LABLE'|translate }}</label>
                <input type="email" aria-required="true" [(ngModel)]="email" name="email" class="form-control validate" id="inputEmail"
                  title="Please enter email" (blur)="validateEmail(forgtPwdForm.value.email)" autocomplete="off"
                  (keyup)="enableButton(forgtPwdForm.value.email)" />
                <div class="error_info">
                  <i class="fa fa-exclamation error_icon" aria-hidden="true" *ngIf="isEmailErrorInfo"
                    [class.red_error]="isEmailErrorInfo && !isEmailError" [class.cursor_hand_none]="isEmailError"></i>
                  <div class="error_info_popup error_new_ui">
                    <div class="left_div">
                      <ul>
                        <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate}}</li>
                        <li class="message">{{emailErrorMessage | translate}}</li>
                      </ul>
                    </div>
                    <div class="right_div">
                      <div class="close" aria-hidden="true" (click)="closeEmailError()">
                        <i class="icon-cancel"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="custom-error error_new_ui" *ngIf="isEmailError">
                  <div class="left_div">
                    <ul>
                      <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate}}</li>
                      <li class="message">{{emailErrorMessage | translate}}</li>
                    </ul>
                  </div>
                  <div class="right_div">
                    <div class="close" aria-hidden="true" (click)="closeEmailError()">
                      <i class="icon-cancel"></i>
                    </div>
                  </div>
                </div>
                <div class="custom-error error_new_ui" *ngIf="isInvalidEmail">
                  <div class="left_div">
                    <ul>
                      <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate}}</li>
                      <li class="message">{{'FORGOT_EMAIL_ERROR' | translate}}</li>
                    </ul>
                  </div>
                  <div class="right_div">
                    <div class="close" aria-hidden="true" (click)="closeInvalidEmailError()">
                      <i class="icon-cancel"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 pad0  " *ngIf="!isResend">
            <button type="submit" class="btn button font_extrabold sendPasswordBtn" name="action" [disabled]="!isValid"
              [class.grow]="isValid ">{{'FORGOT_SEND_INSTRUCTIONS'|translate }}</button>
          </div>
          <div class="col-xs-12 col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 pad0  " *ngIf="isResend">
            <button type="submit" class="btn button font_extrabold sendPasswordBtn" name="action" [disabled]="!isValid"
              [class.grow]="isValid ">{{'FORGOT_RESEND_INSTRUCTIONS'|translate }}</button>
          </div>
        </form>
      </div>
      <!--Sign up From Ends here-->
    </div>
  </div>
  <!--Tab Navigation Slider End-->
  <div class="clearfix"></div>
</div>

<div class="modal fade custom_modal" id="forgotPassword-modal" tabindex="-1" role="Application" aria-labelledby="ForgotModalTextStatus" data-backdrop="static"
  data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="modalClose()">&times;</button>
      </div>
      <div class="transparentbg modal-body">
        <h4 class="modal-title" id="ForgotModalTextStatus">{{'FORGOT_MODAL_TEXT' | translate : [email] }}
        </h4>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>

<div class="modal fade custom_modal" id="plsWait-modal" tabindex="-1" role="Application" aria-labelledby="resetPasswordForgotWaitStatus" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
      </div>
      <div class="transparentbg modal-body">
        <h4 class="modal-title" id="resetPasswordForgotWaitStatus">{{'FORGOT_WAIT_MODAL' | translate }}
        </h4>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>

<div class="modal fade custom_modal" id="oidc_plsWaitmodal" tabindex="-1" role="Application" aria-labelledby="resetPasswordForgotWaitStatus" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
      </div>
      <div class="transparentbg modal-body">
        <h4 class="modal-title" id="resetPasswordForgotWaitStatus">{{'PLEASE_WAIT_FOR_A_MOMENT' | translate }}
        </h4>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>
