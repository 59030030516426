
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { WorkflowService } from 'framework/workflow.service';
import { EventBusService } from 'framework/eventbus.service';
import { Modal, ModalService } from 'framework/modal/index';
import { PlaceHolderFloat } from 'framework/form/placeholder.directive';
import { AuthService } from '../../login/auth.service';
import { ErrorInfoComponent } from 'framework/form/errorInfo.directive';
import { LocalStorageService } from 'framework/localstorage.service';
import { SharedService } from '../../shared/services/shared.service';
import { ProfileService } from '../profile.service';
import { LoginComponent } from '../../login';
import { TRANSLATION_PROVIDERS, TranslatePipe, TranslateService } from './../../../framework/i18n';
import { AppConstants } from 'app/app.constants';


declare var window: any;
declare var $: any;
declare var window: any;

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  providers: [ProfileService,
    ModalService,
    PlaceHolderFloat,
    WorkflowService,
    LoginComponent,
    AuthService,
    ErrorInfoComponent],
    styles: [`.error_new_ui {
        background-color: #F1F0EC;
        box-shadow: 0px 2px 5px rgba(0,0,0,0.3);
        }
        .error_new_ui .left_div {
          background-color: #F1F0EC;
        }
        .error_new_ui .left_div .alert {
          color: #f26c4f;
          background-color: #F1F0EC;
        }
        .error_new_ui .left_div .message {
          color: #333;
        }
        .error_new_ui .right_div {
          background-color: #f26c4f;
        }
        .error_new_ui .right_div .close i {
          color: #fff;
        }
  `],
})
export class ResetPasswordComponent implements OnInit {
  errorMessage: string = '';
  isError: boolean = false;
  email: string = '';
  MODAL_DEMO_ID: string;
  mService: ModalService;
  firstName: string = '';
  isCnfrmPwdError: boolean;
  isCnfrmPwdErrorInfo: boolean;
  cnfrmPwdErrorMessage: string;
  isCnfrmPwdValid: boolean;
  isPwdError: boolean;
  isPwdErrorInfo: boolean;
  pwdErrorMessage: string;
  isPwdValid: boolean;
  isEmailError: boolean;
  isEmailErrorInfo: boolean;
  isEmailValid: boolean;
  emailErrorMessage: string;
  trackObj: Object;
  secretQuestions: any;
  secretQuestion: string;
  disabledQuestionSelect = true;
  secretAnswer: string;
  isSecretQuestionValid: boolean = false;
  isSecretAnswerValid: boolean = false;
  isInvalidAnswer: boolean = false;
  hasSecurityQuestionSet: boolean = false;
  resetPasswordAttempts: number;
  isPasswordPolicyIssue: boolean = false;
  isLanguageResourceLoaded: boolean = false;
  isPasswordRulesValid: boolean = false;

  constructor(private _router: Router,
    private _workflow: WorkflowService,
    private localStorage: LocalStorageService,
    private _profileService: ProfileService,
    private _eventBus: EventBusService,
    private _modService: ModalService,
    private _placeholder: PlaceHolderFloat,
    private _loginComponent: LoginComponent,
    private _errorInfo: ErrorInfoComponent,
    private _authService: AuthService,
    private _activatedRouter: ActivatedRoute,
    private shareService: SharedService) {
    this.MODAL_DEMO_ID = 'someUniqueName';
    this.mService = _modService;
  }

  async ngOnInit() {

    // Handle multiple-language
    await this.setLanguageBasedOnUrl();
    this.isLanguageResourceLoaded = true;

    this._placeholder.placeholderFloatFunc();
    // this.email = localStorage.getItem('email');

    if (this.localStorage.getItem('first_name') != undefined)
      this.firstName = this.localStorage.getItem('first_name');

    this.trackObj = {
      stage_name: 'reset-password',
      action_value: 'forgot password'
    };
    this._errorInfo.errorInfo();
    window.track_event('forgot password attempted', this.trackObj, true, true);

    this._profileService.validateOtp(this._activatedRouter.snapshot.queryParams['key']).subscribe(response => {
      this.email = this.localStorage.getItem('applicant_email');
      this.getSecurityInfo(this.email);
      this.trackObj = {
        stage_name: 'reset-password',
        action_value: 'forgot password'
        };

      window.track_event('forgot password success', this.trackObj, true, true);
      this.secretQuestions = ProfileService.getSecurityQuestions();
    }, error => {
      this.trackObj = {
        stage_name: 'reset-password',
        action_value: 'forgot password'
      };

      window.track_event('forgot password failure', this.trackObj, true, true);

      this._next('./profile/invalid-otp');
    });
  }

  private modalService(val: any) {
    this.mService.open(val);
  }

  private async setLanguageBasedOnUrl() {

    let urlLanguageValue = this._activatedRouter.snapshot.queryParams['lang'];

    if (!!urlLanguageValue) {
      let setLangObj = {};
      setLangObj['languageWithCountryCode'] = urlLanguageValue;
      setLangObj['isLangCodeWithCountryCode'] = true;

      // Set language
      await this.shareService.setLanguage(setLangObj, this._eventBus);
    }
  }

  setPassword(value: Object): void {
    value['email'] = this.email;
    value['secretQuestion'] = this.secretQuestion;

    this.trackObj = {
      stage_name: 'reset-password',
      action_value: 'forgot password'
      };
    this.isPasswordPolicyIssue = false;
    this.isPwdErrorInfo = false;

    window.track_event('reset password attempted', this.trackObj, true, true);
    let that = this;
    this._profileService.resetPassword(value).subscribe(response => {
      $('#resetPassword-modal').modal('show');
      this.trackObj = {
        stage_name: 'reset-password',
        action_value: 'forgot password'
      };

      window.track_event('reset password success', this.trackObj, true, true);
    }, error => {
      this.trackObj = {
        stage_name: 'reset-password',
        action_value: 'forgot password'
      };

      window.track_event('reset password failure', this.trackObj, true, true);
      that.errorMessage = <any>error;
      console.log("errorMessage :" + that.errorMessage + ":");

      if (that.errorMessage && that.errorMessage.startsWith("401") && that.errorMessage.indexOf("MISMATCH") != -1) {
        that.isInvalidAnswer = true;
        that.errorMessage = that.errorMessage.substring("401:".length);
        let index = that.errorMessage.indexOf("#");
        that.resetPasswordAttempts = parseInt(that.errorMessage.substring(index + 1));
        that.errorMessage = that.errorMessage.substring(0, index);
      } else if (that.errorMessage && that.errorMessage == 'ACCOUNT_LOCKED') {
        console.log("Account locked");
        that.openAccountLockedModal();
      } else if (that.errorMessage && that.errorMessage.startsWith("400")) {
        console.log("PASSWORD_POLICY_NOT_MET");
        this.isPasswordPolicyIssue=true;
        this.isPwdErrorInfo = true;
        this.pwdErrorMessage = 'PASSWORD_POLICY_ISSUE_MSG';
      }else {
        that._next('/invalid');
      }
    });
  }

  getSecurityInfo(email: string): void {
    let that = this;
    this._profileService.getSecurityInfo(email).subscribe(response => {
      that.secretQuestion = that.localStorage.getItem("secret_question");
      if (that.secretQuestion !== null && that.secretQuestion.length<1) {
        that.disabledQuestionSelect = false;
      }
      console.log("securityQuestion is ------:" + that.secretQuestion + ":");
      if (that.secretQuestion === '') {
        that.hasSecurityQuestionSet = false;
      } else {
        that.hasSecurityQuestionSet = true;
      }
    }, error => {
      that.secretQuestion = "";
    });
  }

  redirectSignin(): void {
    this._next('./login');
  }

  resetModalClose() {
    $('#resetPassword-modal').modal('hide');
  }

  goToSignin() {
    this.redirectSignin();
  }

  validatePwd(pwdValue: string, cnfrmPwdValue: string) {
    if (pwdValue == "") {
      this.isPwdError = true;
      this.isPwdErrorInfo = true;
      this.pwdErrorMessage = 'COMMONS_PASSWORD_ERROR_1';
      this.isPwdValid = false;
    }
    else if (pwdValue.length < 6) {
      this.isPwdError = true;
      this.isPwdErrorInfo = true;
      this.pwdErrorMessage = 'COMMONS_PASSWORD_ERROR_2';
      this.isPwdValid = false;
    }
    else if (pwdValue.length > 5 && cnfrmPwdValue.length > 5) {
      this.isPwdError = false;
      this.isPwdErrorInfo = false;
      this.pwdErrorMessage = '';
      this.isPwdValid = true;
      this.isCnfrmPwdValid = true;
    }
    else {
      this.isPwdError = false;
      this.isPwdErrorInfo = false;
      this.pwdErrorMessage = '';
    }
  }

  closePwdError() {
    this.isPwdError = false;
  }

  closeEmailError() {
    this.isEmailError = false;
    //   this.isValid = true;
  }

  enableNext(pwdValue: string, cnfrmPwdValue: string, secretQuestion?: string, secretAnswer?: string) {
    if (pwdValue == cnfrmPwdValue && pwdValue.length > 5 && cnfrmPwdValue.length > 5) {
      this.isCnfrmPwdValid = true;
      this.isPwdValid = true;
    } else {
      this.isCnfrmPwdValid = false;
      this.isPwdValid = false;
    }
    this.validateSecretQuestion();
    this.validateSecretAnswer(secretAnswer);
  }

  validateConfirmPwd(pwdValue: string, confrmPwdvalue: string) {
    if (pwdValue.length > 5 && confrmPwdvalue == "") {
      this.isCnfrmPwdError = true;
      this.isCnfrmPwdErrorInfo = true;
      this.cnfrmPwdErrorMessage = 'COMMONS_CONFIRM_PASSWORD_ERROR_1';
      this.isCnfrmPwdValid = false;
    }
    else if (pwdValue != "" && confrmPwdvalue != "") {
      if (pwdValue.length == confrmPwdvalue.length && pwdValue != confrmPwdvalue) {
        this.isCnfrmPwdError = true;
        this.isCnfrmPwdErrorInfo = true;
        this.cnfrmPwdErrorMessage = 'COMMONS_CONFIRM_PASSWORD_ERROR_2';
        this.isCnfrmPwdValid = false;
      }
      else if (pwdValue.length != confrmPwdvalue.length) {
        this.isCnfrmPwdError = true;
        this.isCnfrmPwdErrorInfo = true;
        this.cnfrmPwdErrorMessage = 'COMMONS_CONFIRM_PASSWORD_ERROR_3';
        this.isCnfrmPwdValid = false;
      }
      else {
        this.isCnfrmPwdError = false;
        this.isCnfrmPwdErrorInfo = false;
        this.cnfrmPwdErrorMessage = '';
        this.isCnfrmPwdValid = true;
      }
    }
    else {
      this.isCnfrmPwdError = false;
      this.isCnfrmPwdErrorInfo = false;
      this.cnfrmPwdErrorMessage = '';
      this.isCnfrmPwdValid = true;
    }
  }

  validateSecretQuestion() {
    if (!this.hasSecurityQuestionSet || (this.secretQuestion && this.secretQuestion.length > 0)) {
      this.isSecretQuestionValid = true;
    } else {
      this.isSecretQuestionValid = false;
    }
  }

  validateSecretAnswer(secretAnswer: string) {
    if (!this.hasSecurityQuestionSet || (secretAnswer && secretAnswer.length > 0)) {
      this.isSecretAnswerValid = true;
    } else {
      this.isSecretAnswerValid = false;
    }
  }

  private openAccountLockedModal() {
    $("#account_locked_modal").modal("show");
  }

  closeAccountLockedModel() {
    $("#account_locked_modal").modal("hide");
  }

  closeInvalidAnswerError() {
    this.isInvalidAnswer = false;
  }

  closeCnfrmPwdError() {
    this.isCnfrmPwdError = false;
  }

  private _next(url): void {
    this._router.navigate([url]);
  }

  hideError(value: string) {
    if (value.length > 0) {
      this.isCnfrmPwdError = false;
      this.isCnfrmPwdErrorInfo = false;
      this.isCnfrmPwdValid = true;
    }
    else {
      this.isCnfrmPwdError = false;
      this.isCnfrmPwdErrorInfo = false;
    }
  }

  hidePwdError() {
    this.isPwdError = false;
    this.isPwdErrorInfo = false;
  }

  validateEmail(value: string) {
    let inputEmail = value;
    let regexVal = AppConstants.EMAIL_REGEX;
    let emailRegex = new RegExp(regexVal);

    if (inputEmail == "") {
      this.isEmailError = true;
      this.isEmailErrorInfo = true;
      this.isEmailValid = false;
      this.emailErrorMessage = 'LOGIN_EMAIL_ERROR_MSG';
    }
    else if (!emailRegex.test(inputEmail)) {
      this.isEmailError = true;
      this.isEmailErrorInfo = true;
      this.isEmailValid = false;
      this.emailErrorMessage = 'FORGOT_EMAIL_FORMAT_ERROR_MSG';
    }
    else {
      this.isEmailError = false;
      this.isEmailErrorInfo = false;
      this.emailErrorMessage = '';
      this.isEmailValid = true;
    }
  }

  setErrorTimeout() {
    setTimeout(function () {
      $('.error.custom-error').delay(5000).fadeOut('slow');
    }, 500);
  }

  isPasswordValid(isValid: boolean){
    this.isPasswordRulesValid = isValid;
  }
}
