<div [ngClass]="checkboxClass">
  <div class="group" [formGroup]="group">
    <div class="dynamicCheckboxContainer">
      <input type="checkbox" (change)="enableSign()" class="dynamicCheckboxControl"
        [formControlName]="config.name" [(ngModel)]="currentSelection" [attr.id]="checkBoxExId"> 
        <label class="checkboxLabel" [attr.for]="checkBoxExId" [innerHTML]="config.label | translate"></label>
      <div [style.display]="hideElement === true ? 'none' : 'block'">
        <canvas #acicSign width="200" height="100">
        </canvas>
      </div>
    </div>
  </div>
</div>
