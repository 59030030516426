<div>
  <ul>
    <li *ngIf="viewProfileData['childKey'] === 'ssn'">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['ssn'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['data']['ssn'] | ssnMask}}</div>
      </div>
    </li>
    <li *ngIf="viewProfileData['childKey'] === 'dob'">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['dob'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['data']['dob'] | dOBDateFormat }}</div>
      </div>
    </li>
  </ul>
</div>
