
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from '../profile.service';
import { PlaceHolderFloat } from 'framework/form/placeholder.directive';

@Component({
  selector: 'different-email',
  templateUrl: './different-email.component.html',
  providers: [PlaceHolderFloat]
})
export class DifferentEmailComponent implements OnInit {
  isInvalidEmail = false;

  constructor(private _router: Router,
    private _placeholder: PlaceHolderFloat,
    private _profileService: ProfileService) { }

  ngOnInit() {
    this._placeholder.placeholderFloatFunc();
  }

  emailLogin(value: Object, valid: boolean): void {
    /*  let email = value['email'];
          this._profileService.forgotPassword(email).subscribe(response => {
        //  this._next('./reset-password');
      }, error => {
          this.isInvalidEmail = true;
      });*/
  }

  private _next(url): void {
    this._router.navigate([url]);
  }
}
