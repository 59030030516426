import { Injectable } from '@angular/core';
import { AppConfig } from 'app/app.config';

interface Scripts {
  name: string,
  src: string;
  type: string;
  integrity: string;
  crossorigin: string;
}

const mobiScrollFiles = [
  'mobiscroll-css',
  'mobiscroll',
  'mobiscrollit',
  'mobiscrollja',
  'mobiscrollko',
  'mobiscrollnl',
  'mobiscrollprbr',
  'mobiscrollpreu',
  'mobiscrolltr',
  'mobiscrollzh',
  'mobiscrollde',
  'mobiscrollfr',
  'mobiscrollfrca',
  'mobiscrolles',
  'mobiscrollpl',
  'mobiscrollhu',
  'mobiscrollpt',
  'mobiscrollvi',
  'mobiscrollth'
];

const generalFiles = [
  'openlayers-css',
  'openlayers-js',
  // 'signaturpad',
  // 'slickIndicator',
  // 'webcam',
  'card',
  'blobjs'
  // 'weakset'
]

export const ScriptStore: Scripts[] = [
  { name: 'mobiscroll-css', src: './assets/mobiscroll/css/mobiscroll.css', type: 'text/css', integrity: '', crossorigin: ''}, 
  { name: 'mobiscroll', src: './assets/mobiscroll/js/mobiscroll.js', type: 'text/javascript', integrity: '', crossorigin: ''}, 
  { name: 'mobiscrollit', src: './assets/mobiscroll/js/mobiscroll.i18n.it.js', type: 'text/javascript', integrity: '', crossorigin: ''}, 
  { name: 'mobiscrollja', src: './assets/mobiscroll/js/mobiscroll.i18n.ja.js', type: 'text/javascript', integrity: '', crossorigin: ''}, 
  { name: 'mobiscrollko', src: './assets/mobiscroll/js/mobiscroll.i18n.ko.js', type: 'text/javascript', integrity: '', crossorigin: ''}, 
  { name: 'mobiscrollnl', src: './assets/mobiscroll/js/mobiscroll.i18n.nl.js', type: 'text/javascript', integrity: '', crossorigin: ''}, 
  { name: 'mobiscrollprbr', src: './assets/mobiscroll/js/mobiscroll.i18n.pr-br.js', type: 'text/javascript', integrity: '', crossorigin: ''}, 
  { name: 'mobiscrollpreu', src: './assets/mobiscroll/js/mobiscroll.i18n.pr-eu.js', type: 'text/javascript', integrity: '', crossorigin: ''}, 
  { name: 'mobiscrolltr', src: './assets/mobiscroll/js/mobiscroll.i18n.tr.js', type: 'text/javascript', integrity: '', crossorigin: ''}, 
  { name: 'mobiscrollzh', src: './assets/mobiscroll/js/mobiscroll.i18n.zh.js', type: 'text/javascript', integrity: '', crossorigin: ''}, 
  { name: 'mobiscrollde', src: './assets/mobiscroll/js/mobiscroll.i18n.de.js', type: 'text/javascript', integrity: '', crossorigin: ''}, 
  { name: 'mobiscrollfr', src: './assets/mobiscroll/js/mobiscroll.i18n.fr.js', type: 'text/javascript', integrity: '', crossorigin: ''}, 
  { name: 'mobiscrollfrca', src: './assets/mobiscroll/js/mobiscroll.i18n.fr-ca.js', type: 'text/javascript', integrity: '', crossorigin: ''}, 
  { name: 'mobiscrolles', src: './assets/mobiscroll/js/mobiscroll.i18n.es.js', type: 'text/javascript', integrity: '', crossorigin: ''}, 
  { name: 'mobiscrollpl', src: './assets/mobiscroll/js/mobiscroll.i18n.pl.js', type: 'text/javascript', integrity: '', crossorigin: ''}, 
  { name: 'mobiscrollhu', src: './assets/mobiscroll/js/mobiscroll.i18n.hu.js', type: 'text/javascript', integrity: '', crossorigin: ''}, 
  { name: 'mobiscrollpt', src: './assets/mobiscroll/js/mobiscroll.i18n.pt.js', type: 'text/javascript', integrity: '', crossorigin: ''}, 
  { name: 'mobiscrollvi', src: './assets/mobiscroll/js/mobiscroll.i18n.vi.js', type: 'text/javascript', integrity: '', crossorigin: ''}, 
  { name: 'mobiscrollth', src: './assets/mobiscroll/js/mobiscroll.i18n.th.js', type: 'text/javascript', integrity: '', crossorigin: ''}, 
  { name: 'openlayers-css', src: 'https://openlayers.org/en/v4.6.5/css/ol.css', type: 'text/css', integrity: 'sha384-iYkPgcf1wF1oXt3CosKxNMQJgQUC60vjUJM+HANnq9ULJXg8DLX/ywPXMZHto90Q', crossorigin: 'anonymous'}, 
  { name: 'openlayers-js', src: 'https://openlayers.org/en/v4.6.5/build/ol.js', type: 'text/javascript', integrity: 'sha384-/UhW2uuxuN/GcGRUL3CJA5ftmLinDAWEV5khyWrOKJ04xesUihAW/UNXf4VMvAuS', crossorigin: 'anonymous'}, 
  { name: 'blobjs', src: 'https://cdn.rawgit.com/eligrey/Blob.js/0cef2746414269b16834878a8abc52eb9d53e6bd/Blob.js', type: 'text/javascript', integrity: 'sha384-AmRz0lg1qXYxEYtQLsA0E4MRNvj2XTvuX+zlLfx2/EGAn16+/upVQ533oJtXXDbI', crossorigin: 'anonymous'}, 
  // { name: '', src: './assets/css/slider/slick-theme.css' , type:'text/css'},
  // { name: '', src: './assets/css/slider/slick.css' , type:'text/css'},
  // { name: 'signaturpad', src: './assets/js/signature-pad.js' , type:'text/javascript'},
  // { name: 'slickIndicator', src: './assets/js/slick-indicators.js' , type:'text/javascript'},
  // { name: 'webcam', src: './assets/js/webcamjs/webcam.js' , type:'text/javascript'},
  { name: 'card', src: 'https://unpkg.com/card@2.3.0/dist/card.js', type: 'text/javascript', integrity: 'sha384-AoFLw9evVX0wCbroF69qqWPkNGbqMkpmmZnTRalLZIO62FKdZYJBpDIV3KIM3jZ+', crossorigin: 'anonymous'}, 
  // { name: 'weakset', src: 'https://unpkg.com/@ungap/weakset', type:'text/javascript'},
];

declare var document: any;

@Injectable()
export class DynamicScriptLoaderService {
  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        name: '',
        src: script.src,
        type: script.type
      };
    });
  }

  load(scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
     
      // console.log('Loading -> ' + name);
      if (!this.scripts[name].loaded) {
        //load script
        let script = document.createElement('script');
        if (this.scripts[name].type === 'text/css') {
          script = document.createElement('link')
          script.href = this.scripts[name].src;
          script.rel = 'stylesheet';
        } else {
          script.src = this.scripts[name].src;
        }

        script.type = this.scripts[name].type;

        if (!!this.scripts[name].integrity) {
          script.integrity = this.scripts[name].integrity;
        }

        if (!!this.scripts[name].crossorigin) {
          script.crossorigin = this.scripts[name].crossorigin;
        }

        if (script.readyState) {  //IE
          script.onreadystatechange = () => {
            if (script.readyState === "loaded" || script.readyState === "complete") {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {  //Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            // console.log(JSON.stringify(this.scripts[name]) + ' => Loaded');
            resolve({ script: name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = (error: any) => {
          console.log(error);
          // console.log(JSON.stringify(this.scripts[name]) + ' => failed to Loaded');
          resolve({ script: name, loaded: false, status: 'Loaded' });
        }
       
        if (!!script) {
          // console.log('appending into html -> ' + JSON.stringify(script));
          document.getElementsByTagName('head')[0].appendChild(script);
        } else {
          // console.log('Script empty -> ');
        }
      } else {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      }
    });
  }

  loadScripts() {
    let scripts: string[] = generalFiles;
    // You can load multiple scripts by just providing the key as argument into load method of the service
    this.load(scripts).then(data => {
      // Script Loaded Successfully
      // console.log('script loaded successfully -> ' + data);
    }).catch(error => console.log(error));

    const isSafari = AppConfig.currentBrowser('SAFARI');
    const isFireFox = AppConfig.currentBrowser('FIREFOX');
    const isIE11 = AppConfig.currentBrowser('IE');

    if (isSafari || isFireFox || isIE11) {
      scripts = mobiScrollFiles;
      // You can load multiple scripts by just providing the key as argument into load method of the service
      this.load(scripts).then(data => {
        // Script Loaded Successfully
        // console.log('script loaded successfully -> ' + data);
      }).catch(error => console.log(error));
    }
  }
}