<div>
  <div *ngIf="dobError" class="row m-t-40 pad15">
    <div class="col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 col-xs-12 ">
      <div class="header_txt_white">
        <h2>{{'DOB_ISSUE'|translate }}</h2>
       </div>
    </div>
  </div>
  <div *ngIf="candidateAgeBasedScopeError" class="row m-t-40 pad15">
    <div class="col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 col-xs-12 ">
      <div class="header_txt_white">
        <h2>{{'CANDIDATE_AGE_BASED_SCOPE_ISSUE'|translate }}</h2>
       </div>
    </div>
  </div>
  <form [formGroup]="employmentForm" class="pad30 overflhide" *ngIf="(isFormInit)"
    (keydown)="disableEnterButton($event)">
    <i class="icon-info globalCustomInstructions" tabindex="0" *ngIf="isCustomInstruction" debounceDirective (debounceClick)="setCustomInstruction()"></i>
    <div formArrayName="employmentSubForm">
      <div *ngFor="let employmentSubForm of employmentForm.get('employmentSubForm')['controls']; let i=index">
        <div *ngIf="!this.isScopeAvailable" class="mobile_container animated pad20 posrel"
          [class.slideInRight]="(currentSlideWithDirection  === 'verification_'+i+'_right') ? true:false"
          [class.slideInLeft]="(currentSlideWithDirection  === 'verification_'+i+'_left') ? true:false"
          [class.hide]="(currentSlide  !== 'verification_'+i) ? true:false">
          <verification [group]="employmentForm.get('employmentSubForm')['controls'][i]" [row]="i"
            [verificationFlag]="verificationFlags[i]" [userDetails]="userDetails" [accountName]="accountName"
            [configOptions]="{PARENT_CONFIG: PARENT_STAGE_STEP_CONFIG}" (onVerification)="empVerification($event)">
          </verification>
        </div>

        <div class="mobile_container animated pad20" [class.slideInRight]="(currentSlide  === 'category') ? true:false"
          [class.slideInRight]="(currentSlideWithDirection  === 'category_'+i+'_right') ? true:false"
          [class.slideInLeft]="(currentSlideWithDirection  === 'category_'+i+'_left') ? true:false"
          [class.hide]="(currentSlide  !== 'category_'+i) ? true:false">
          <category [group]="employmentForm.get('employmentSubForm')['controls'][i]" [gapOption]="gapOptions[i]"
            [row]="i" [empType]="empTypes[i]" [empCategories]="empCategories" [userDetails]="userDetails"
            [configOptions]="{PARENT_CONFIG: PARENT_STAGE_STEP_CONFIG}" [isReviewAdd]="isReviewAdd"
            [isReviewEdit]="isReviewEdit" [clipboardOptions]="cbOptions"
            [isDotLicencePreferenceEnabled]="isDotLicencePreferenceEnabled" (onCategory)="empCategory($event)"
            [isReview]="isReview" [activeGap]="activeGap" (onCategorySwipe)="empCategorySwipe($event)"
            (onSetATSData)="setATSData($event)" [showATSdata]="ATSData['showATSdata']"
            [atsData]="ATSData['display-data-list']" (onGoToProfileBuilder)="empGoToProfileBuilder()"
            [remainingYearMonthMsg]="remainingYearMonthMsg[i]" [isEmploymentGapInDaysEnabled]="isEmploymentGapInDaysEnabled"
            [isNeverEmployedEnabled]="isCategoryNeverEmployedPreferenceEnabled" [isCurrentEmp]="currentJob" (onNeverEmployedSelect)="empNeverEmployedSelect()"
            [isScopeCompleted]="isScopeCompleted" [isAdditionalEmpPrefEnabled]="isAdditionalEmpPrefEnabled"></category>
        </div>

        <div *ngIf="isPlaidPreferenceEnabled && empTypes[i] === 'current'" class="mobile_container animated pad20"
          [class.slideInRight]="(currentSlideWithDirection  === 'emp-details_'+i+'_right') ? true:false"
          [class.slideInLeft]="(currentSlideWithDirection  === 'emp-details_'+i+'_left') ? true:false"
          [class.hide]="(currentSlide  !== 'emp-details_'+i) ? true:false">
          <employment-details [group]="employmentForm.get('employmentSubForm')['controls'][i]" [dateOptions]="dateConfigs[i]"
            (onEmpDetails)="employmentDetails($event)" (onEmpDetailsSwipe)="employmentDetailsSwipe($event)" [row]="i"
            [configOptions]="{PARENT_CONFIG: PARENT_STAGE_STEP_CONFIG}">
          </employment-details>
        </div>

        <div *ngIf="isPlaidPreferenceEnabled && empTypes[i] === 'current'" class="mobile_container animated pad20"
          [class.slideInRight]="(currentSlideWithDirection === 'data-provider_'+i+'_right')"
          [class.slideInLeft]="(currentSlideWithDirection === 'data-provider_'+i+'_left')"
          [class.hide]="(currentSlide !== 'data-provider_'+i) ? true: false">
          <data-provider-selection [row]="i" [group]="employmentForm.get('employmentSubForm')['controls'][i]" [showCitadelData]="showCitadelData[i]"
          [configOptions]="{PARENT_CONFIG: PARENT_STAGE_STEP_CONFIG}" [inputPlaidPreferenceEnabled]="isPlaidPreferenceEnabled" [inputCitadelPreferenceEnabled]="isPresentEmpAutoFulfilment"
          [inputSelectedEmpDate]="selectedEmpDetailsDate" [showPlaidData]="showPlaidData[i]" (onEmpDataProviderModalOpen)="empDataProviderModalOpen()"
          (onEmpDataProvider)="employmentDataProviderSelect($event)" (onEmpDataProviderSwipe)="employmentDataProviderSwipe($event)" (onProviderOptOut) = "onProviderOptOut($event)"
          ></data-provider-selection>
        </div>

        <div *ngIf="isPresentEmpAutoFulfilment && empTypes[i] === 'current'" class="mobile_container animated pad20" [class.slideInRight]="(currentSlide  === 'payroll-lookup') ? true:false"
          [class.hide]="(currentSlide  !== 'payroll-lookup'+i) ? true:false"
          [class.slideInRight]="(currentSlideWithDirection  === 'payroll-lookup_'+i+'_right') ? true:false"
          [class.slideInLeft]="(currentSlideWithDirection  === 'payroll-lookup_'+i+'_left') ? true:false"
          [class.hide]="(currentSlide  !== 'payroll-lookup_'+i) ? true:false">
          <payroll-lookup [group]="employmentForm.get('employmentSubForm')['controls'][i]"
            [row]="i" [configOptions]="{PARENT_CONFIG: PARENT_STAGE_STEP_CONFIG}" [showCitadelData]="showCitadelData[i]" (onPayrollLookup)="onPayrollLookup($event)"
            (onPayrollOptOut)="onPayrollOptOut($event)" (onPayrollLookupPrevSwipe)="onPayrollLookupPrevSwipe($event)">
          </payroll-lookup>
        </div>
        <div *ngIf="((empTypes[i] === 'current' || isCheckSelfEmploymentEnabled[i]) && !!selectedCurrentEmpCountry)"
          class="mobile_container animated pad20 posrel"
          [class.slideInRight]="(currentSlideWithDirection  === 'verification-confirm_'+i+'_right') ? true:false"
          [class.slideInLeft]="(currentSlideWithDirection  === 'verification-confirm_'+i+'_left') ? true:false"
          [class.hide]="(currentSlide  !== 'verification-confirm_'+i) ? true:false">
          <verification-confirm [group]="employmentForm.get('employmentSubForm')['controls'][i]" [row]="i"
            [verificationFlag]="verificationFlags[i]" [toggleNocontactName]="toggleNocontactName"
            [userDetails]="userDetails" [accountName]="accountName" [inputSelectedCurrentEmpCountry]="selectedCurrentEmpCountry"
            [configOptions]="{PARENT_CONFIG: PARENT_STAGE_STEP_CONFIG}"
            [inputSelectedDate]="selectedDates[i]" [inputIsIntlPmpComponentAvailable]="isIntlPmpComponentAvailable"
            (onVerificationConfirm)="empVerificationConfirm($event)"
            (onVerificationConfirmDisaggreeOpen)="empVerificationConfirmDisaggreeOpen($event)"
            (onVerificationConfirmSwipe)="empVerificationConfirmSwipe($event)"
            (onSetDate)="datetocontactnext($event)"></verification-confirm>
        </div>

        <div
          *ngIf="empTypes[i] === 'current' || empTypes[i] ===  'prior' || (empTypes[i] ===  'selfEmployed' && (isPaDOTDirectEnabled || selfEmploymentPreferenceEnabled))"
          class="mobile_container animated pad20 posrel"
          [class.slideInRight]="(currentSlideWithDirection  === 'country_'+i+'_right') ? true:false"
          [class.slideInLeft]="(currentSlideWithDirection  === 'country_'+i+'_left') ? true:false"
          [class.hide]="(currentSlide   !== 'country_'+i) ? true:false">
          <country [group]="employmentForm.get('employmentSubForm')['controls'][i]" [row]="i" [empType]="empTypes[i]"
            [options]="options[i]" [userDetails]="userDetails"
            [configOptions]="{PARENT_CONFIG: PARENT_STAGE_STEP_CONFIG}" [isReviewEdit]="isReviewEdit"
            [isConsentCountry]="isConsentCountry" (onCountry)="empCountry($event)"
            (onCountrySwipe)="empCountrySwipe($event)"></country>
        </div>

        <div
          *ngIf="empTypes[i] === 'current' || empTypes[i] ===  'prior' || (empTypes[i] ===  'selfEmployed' && (isPaDOTDirectEnabled || selfEmploymentPreferenceEnabled))"
          class="mobile_container animated pad20 posrel"
          [class.slideInRight]="(currentSlideWithDirection  === 'city-state_'+i+'_right') ? true:false"
          [class.slideInLeft]="(currentSlideWithDirection  === 'city-state_'+i+'_left') ? true:false"
          [class.hide]="(currentSlide   !== 'city-state_'+i) ? true:false">

          <city-state [group]="employmentForm.get('employmentSubForm')['controls'][i]" [row]="i" [options]="options[i]"
            [empType]="empTypes[i]" [userDetails]="userDetails"
            [configOptions]="{PARENT_CONFIG: PARENT_STAGE_STEP_CONFIG}" [isReviewEdit]="isReviewEdit"
            (onCityState)="empCityState($event)" (onCityStateSwipe)="empCityStateSwipe($event)" [isATSData]="isATSData"
            [isPaDOTDirectEnabled]="isPaDOTDirectEnabled" [zipCodeError]="zipCodeError"></city-state>
        </div>

        <div *ngIf="empTypes[i] === 'current' || empTypes[i] ===  'prior' || isCheckSelfEmploymentEnabled[i] || (empTypes[i] ===  'selfEmployed' && isPaDOTDirectEnabled)"
          class="mobile_container animated pad20 posrel"
          [class.slideInRight]="(currentSlideWithDirection  === 'company-name_'+i+'_right') ? true:false"
          [class.slideInLeft]="(currentSlideWithDirection  === 'company-name_'+i+'_left') ? true:false"
          [class.hide]="(currentSlide   !=='company-name_'+i) ? true:false">
          <company-name [group]="employmentForm.get('employmentSubForm')['controls'][i]" [row]="i"
            [empType]="empTypes[i]" [userDetails]="userDetails"
            [configOptions]="{PARENT_CONFIG: PARENT_STAGE_STEP_CONFIG}" [isReviewAdd]="isReviewAdd"
            [isReviewEdit]="isReviewEdit" [clipboardOptions]="cbOptions" (onCompanyName)="empCompanyName($event)"
            (onCompanyNameSwipe)="empCompanyNameSwipe($event)" (onGoToProfileBuilder)="empGoToProfileBuilder()"
            [options]="options[i]" [isCheckSelfEmploymentEnabled]="isCheckSelfEmploymentEnabled[i]"
            [companyPrefillConfig]="companyPrefillConfig[i]" [isPaDOTDirectEnabled]="isPaDOTDirectEnabled"></company-name>
        </div>

        <div
          *ngIf="(empTypes[i] === 'current' || empTypes[i] ===  'prior' || isCheckSelfEmploymentEnabled[i] || (empTypes[i] ===  'selfEmployed' && isPaDOTDirectEnabled)) && (options[i]['countryOptions']['countryShortName'] != DOMESTIC_COUNTRY_CODE || isPaDOTDirectEnabled)"
          class="mobile_container animated pad20 posrel"
          [class.slideInRight]="(currentSlideWithDirection  === 'reporting-manager-details_'+i+'_right') ? true:false"
          [class.slideInLeft]="(currentSlideWithDirection  === 'reporting-manager-details_'+i+'_left') ? true:false"
          [class.hide]="(currentSlide !=='reporting-manager-details_'+i) ? true:false">
          <reporting-manager-details [group]="employmentForm.get('employmentSubForm')['controls'][i]" [row]="i"
            [empType]="empTypes[i]" [userDetails]="userDetails"
            [configOptions]="{PARENT_CONFIG: PARENT_STAGE_STEP_CONFIG}" [isReviewAdd]="isReviewAdd"
            [isReviewEdit]="isReviewEdit" [clipboardOptions]="cbOptions" (onManagerDetails)="empManagerDetails($event)"
            (onManagerDetailsSwipe)="empManagerDetailsSwipe($event)"
            (onGoToProfileBuilder)="empGoToProfileBuilder()" [options]="options[i]" [inputErrorCode]="ErrorCode"
            [isCheckSelfEmploymentEnabled]="isCheckSelfEmploymentEnabled[i]"
            [isPaDOTDirectEnabled]="isPaDOTDirectEnabled"
            [checkEmployerPhoneEmailOptional]="employerPhoneEmailOptional"></reporting-manager-details>
        </div>

        <div
          *ngIf="(empTypes[i] === 'current' || empTypes[i] ===  'prior') && (options[i]['countryOptions']['countryShortName'] != DOMESTIC_COUNTRY_CODE)"
          class="mobile_container animated pad20 posrel"
          [class.slideInRight]="(currentSlideWithDirection  === 'position_'+i+'_right') ? true:false"
          [class.slideInLeft]="(currentSlideWithDirection  === 'position_'+i+'_left') ? true:false"
          [class.hide]="(currentSlide !=='position_'+i) ? true:false">
          <position [group]="employmentForm.get('employmentSubForm')['controls'][i]" [row]="i" [empType]="empTypes[i]"
            [userDetails]="userDetails" [configOptions]="{PARENT_CONFIG: PARENT_STAGE_STEP_CONFIG}"
            [isReviewAdd]="isReviewAdd" [isReviewEdit]="isReviewEdit" [clipboardOptions]="cbOptions"
            (onAddEmpPosition)="empAddEmpPosition($event)" (onAddEmpPositionSwipe)="empAddEmpPositionSwipe($event)"
            (onGoToProfileBuilder)="empGoToProfileBuilder()" [options]="options[i]"
            (onSkipEmpPosition)="empSkipEmpPosition($event)" (onSaveAndExitRecord)="empPositionSaveAncExit($event)"
            [activeGapOption]="activeGapOptions[i]" (onEditRecord)="editRecord($event)"
            (onDeleteRecord)="deleteRecord($event)" [dateOptions]="dateConfigs[i]" [positionchange]="positionchange"
            [addMorePositions]="addMorePositions" [currentRowPositionList]="employeePositionList[i]" [isEmploymentGapInDaysEnabled]="isEmploymentGapInDaysEnabled" [showEndDate]="showEndDate"></position>
        </div>

        <div
          *ngIf="(empTypes[i] === 'current' || empTypes[i] ===  'prior' || isCheckSelfEmploymentEnabled[i]) && (isINTLDepartmentArr[i] || isINTLEmployeeIdArr[i] || isINTLCompNameInLocalArr || ((empTypes[i] === 'current' || empTypes[i] ===  'prior') && selfEmploymentPreferenceEnabled))"
          class="mobile_container animated pad20 posrel"
          [class.slideInRight]="(currentSlideWithDirection  === 'intl-emp_'+i+'_right') ? true:false"
          [class.slideInLeft]="(currentSlideWithDirection  === 'intl-emp_'+i+'_left') ? true:false"
          [class.hide]="(currentSlide !=='intl-emp_'+i) ? true:false">
          <intl-employment [group]="employmentForm.get('employmentSubForm')['controls'][i]" [row]="i"
            [empType]="empTypes[i]" [userDetails]="userDetails"
            [configOptions]="{PARENT_CONFIG: PARENT_STAGE_STEP_CONFIG}" [isReviewAdd]="isReviewAdd"
            [isReviewEdit]="isReviewEdit" [clipboardOptions]="cbOptions" (onINTLAdditional)="empINTLAdditional($event)"
            (onINTLAdditionalSwipe)="empINTLAdditionalSwipe($event)"
            (onGoToProfileBuilder)="empGoToProfileBuilder()" [options]="options[i]"
            [intlAbaCbcArr]="intlAbaCbcArr[i]" [isINTLEmployeeId]="isINTLEmployeeIdArr[i]"
            [isINTLDepartment]="isINTLDepartmentArr[i]" [isINTLCompNameInLocal]="isINTLCompNameInLocalArr[i]"
            [selfEmploymentPreferenceEnabled]="selfEmploymentPreferenceEnabled" [jobTypes]="jobTypes"></intl-employment>
        </div>

        <div *ngIf="empTypes[i] !== null" class="mobile_container animated pad20 posrel"
          [class.slideInRight]="(currentSlideWithDirection  === 'job-period_'+i+'_right') ? true:false"
          [class.slideInLeft]="(currentSlideWithDirection  === 'job-period_'+i+'_left') ? true:false"
          [class.hide]="(currentSlide   !== 'job-period_'+i) ? true:false">
          <job-period [group]="employmentForm.get('employmentSubForm')['controls'][i]" [row]="i"
            [dateOptions]="dateConfigs[i]" [empType]="empTypes[i]" [isReviewAdd]="isReviewAdd" [options]="options[i]"
            [currentSlide]="currentSlide" [userDetails]="userDetails" [currentJob]="currentJob" [isCitadelData]="isCitadelData[i]"
            [activeGapOption]="activeGapOptions[i]" [inputGapCheck]="setGapValueRandom" [showPlaidData]="showPlaidData[i]" 
            [configOptions]="{PARENT_CONFIG: PARENT_STAGE_STEP_CONFIG}" [isReviewEdit]="isReviewEdit"
            [isReview]="isReview" [isJobfromValid]="isJobfromValid" (onJobPeriod)="empJobPeriod($event)"
            (onJobPeriodSwipe)="empJobPeriodSwipe($event)" [futureDateFlags] = "futureDateFlags"
            [isCheckSelfEmploymentEnabled]="isCheckSelfEmploymentEnabled[i]" [isEmploymentGapInDaysEnabled]="isEmploymentGapInDaysEnabled"></job-period>
        </div>


        <div
          *ngIf="empTypes[i] === 'current' || empTypes[i] ===  'prior' || isCheckSelfEmploymentEnabled[i] || (empTypes[i] ===  'selfEmployed' && isPaDOTDirectEnabled) || (empTypes[i] ===  'selfEmployed' && faaSafetyCheckPreferenceEnabled && selfEmploymentPreferenceEnabled)"
          class="mobile_container animated pad20 posrel"
          [class.slideInRight]="(currentSlideWithDirection  === 'job-title_'+i+'_right') ? true:false"
          [class.slideInLeft]="(currentSlideWithDirection  === 'job-title_'+i+'_left') ? true:false"
          [class.hide]="(currentSlide   !== 'job-title_'+i) ? true:false">
          <job-title [group]="employmentForm.get('employmentSubForm')['controls'][i]" [row]="i"
            [userDetails]="userDetails" [empType]="empTypes[i]"
            [configOptions]="{PARENT_CONFIG: PARENT_STAGE_STEP_CONFIG}" (onJobTitle)="empJobTitle($event)"
            (onJobTitleSwipe)="empJobTitleSwipe($event)"
            [isCheckSelfEmploymentEnabled]="isCheckSelfEmploymentEnabled[i]"
            [isDotLicencePreferenceEnabled]="isDotLicencePreferenceEnabled"
            [dotLimitEmploymentVerification]="dotLimitEmploymentVerification"
            [faaSafetyCheckPreferenceEnabled]="faaSafetyCheckPreferenceEnabled"
            [selfEmploymentPreferenceEnabled]="selfEmploymentPreferenceEnabled"
            [isPaDOTDirectEnabled]="isPaDOTDirectEnabled" [isReasonForLeavingMandatory]="isReasonForLeavingMandatory"
            [isMultiPositionEnabled]="isMultiPositionEnabled" [companyPrefillConfig]="companyPrefillConfig[i]"></job-title>
        </div>

        <div
          *ngIf="isStandardFormExtension && (empTypes[i] === 'current' || empTypes[i] ===  'prior' || empTypes[i] ===  'selfEmployed')"
          class="mobile_container animated pad20 posrel"
          [class.slideInRight]="(currentSlideWithDirection  === 'custform_'+i+'_right') ? true:false"
          [class.slideInLeft]="(currentSlideWithDirection  === 'custform_'+i+'_left') ? true:false"
          [class.hide]="(currentSlide   !== 'custform_'+i) ? true:false">
          <employment-custform [group]="employmentForm.get('employmentSubForm')['controls'][i]" [row]="i"
            [empType]="empTypes[i]" [isFormDeleted]="isFormDeleted[i]"
            [configOptions]="{PARENT_CONFIG: PARENT_STAGE_STEP_CONFIG}" (onCustomForm)="empCustomData($event)"
            (onCustomFormSwipe)="empCustomDataSwipe($event)" [clipboardOptions]="cbOptions"></employment-custform>
        </div>

        <div *ngIf="(!this.isScopeAvailable || isAdditionalEmpPrefEnabled) && !isReview" class="mobile_container animated pad20 posrel"
          [class.slideInRight]="(currentSlideWithDirection  === 'reconfirm_'+i+'_right') ? true:false"
          [class.slideInLeft]="(currentSlideWithDirection  === 'reconfirm_'+i+'_left') ? true:false"
          [class.hide]="(currentSlide  !== 'reconfirm_'+i) ? true:false">
          <reconfirm [group]="employmentForm.get('employmentSubForm')['controls'][i]" [row]="i"
            [verificationFlag]="verificationFlags[i]" [userDetails]="userDetails" [accountName]="accountName"
            [configOptions]="{PARENT_CONFIG: PARENT_STAGE_STEP_CONFIG}" (onreconfirm)="empReconfirm($event)"
            (onnoreconfirm)="noReconfirm($event)" (onReconfirmSwipe)="empReconfirmSwipe($event)"></reconfirm>
        </div>


        <div [attr.id]="'disagreeModel_' + i" class="modal fade custom_modal" tabindex="-1" role="Application" [attr.aria-labelledby]="'disagreeModelHeader_' + i" data-backdrop="static"
          data-keyboard="false">
          <div class="modal-dialog sign-modal">
            <!-- Modal content-->
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" data-id="empDisagreeModalIcon">&times;</button>
              </div>
              <div class="transparentbg modal-body">
                <h4 class="modal-title" [attr.id]="'disagreeModelHeader_' + i">
                  {{'EMPLOYMENT_DISAGREE_SUBHEADER'|translate:[userDetails.firstname, empContactMaxTimeFrame] }}
                </h4>
                <date-to-contact [group]="employmentForm.get('employmentSubForm')['controls'][i]" [row]="i"
                  [cmSelectedDate]="selectedDates[i]" [exiForm]="existingform"
                  [configOptions]="{PARENT_CONFIG: PARENT_STAGE_STEP_CONFIG}"
                  [empContactMaxTimeFrame]="empContactMaxTimeFrame" (onSetDate)="datetocontactnext($event)">
                </date-to-contact>

              </div>
            </div>
          </div>
        </div>

        <div [attr.id]="'empPositionModel_' + i" class="modal fade custom_modal " tabindex="-1" role="Application" aria-labelledby="empConfirmContact" data-backdrop="static"
          data-keyboard="false">
          <div class="modal-dialog positionModalSize sign-modal">
            <!-- Modal content-->
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal"
                  data-id="empMutipositionCloseIcon">&times;</button>
              </div>
              <div class="transparentbg modal-body">
                <h4 class="modal-title">

                </h4>
                <multiple-position [group]="employmentForm.get('employmentSubForm')['controls'][i]" [row]="i"
                  [cmSelectedDate]="selectedDates[i]" [exiForm]="existingform"
                  [configOptions]="{PARENT_CONFIG: PARENT_STAGE_STEP_CONFIG}" (onAddPosition)="empAddPosition($event)"
                  [empType]="empTypes[i]" [editInd]="editInd" (onEditPosition)="empEditPosition($event)"
                  [updateMultiPositionModal]="updateMultiPositionModal"
                  [currentEditableObj]="currentEditableObj" [showEndDate]="showEndDate"
                  [currentRowPositionList]="employeePositionList[i]" [allPositionList]="employeePositionList"
                  [addNewPosition]="addNewPosition" [isEmploymentGapInDaysEnabled]="isEmploymentGapInDaysEnabled">
                </multiple-position>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </form>
</div>

<!-- Blank screen -->
<div class="mobile_container animated pad20 padding20" [class.hide]="(!showBlankPage)">
  <div class="pad_30">
    <h4 id="blankEmpScreenModalHeader" class="modal-title m-t-10">
      {{ 'BLANK_SCREEN_ERROR_MESSAGE' | translate : [applicantID]}}
    </h4>
  </div>
</div>

<!-- Modal -->
<div id="tcModal" class="modal fade custom_modal" tabindex="-1" role="Application" aria-labelledby="empConfirmContact" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" data-id="empTermsCloseIcon">&times;</button>

      </div>
      <div class="transparentbg modal-body">
        <h4 class="modal-title" id="empConfirmContact">{{'EMPLOYMENT_TERMS_SUBHEADER'|translate:[userDetails.firstname] }}</h4>
      </div>
      <div class="modal-footer">
        <div class="col-xs-12 col-sm-4 col-md-3 p_l_0  p_r_0 m-r-15 ">
          <button type="button" class="btn button grow font_extrabold" data-id="empTermsAgree"
            debounceDirective (debounceClick)="agree('#tcModal')">{{ 'COMMONS_AGREE' | translate}}</button>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-4 p_l_0 p_r_0 m-r-15 ">
          <button type="button" class="btn button_wbg grow font_extrabold" data-id="empTermsDisagree"
            debounceDirective (debounceClick)="changeMyMind('#tcModal')">{{ 'EMPLOYMENT_TERMS_CHANGE_MIND' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div id="scopeModal" class="modal fade custom_modal" tabindex="-1" role="Application" aria-labelledby="empAddScopeModalHeader" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" data-id="empScopeModalCloseIcon">&times;</button>

      </div>
      <div class="transparentbg modal-body">
        <h4 class="modal-title" id="empAddScopeModalHeader">{{'EMPLOYMENT_SCOPE_HEADER'|translate:[userDetails.firstname] }}</h4>
      </div>
      <div class="modal-footer">
        <div class="col-xs-12 col-sm-4 col-md-3 p_l_0  p_r_0 m-r-15 ">
          <button type="button" class="btn button grow font_extrabold" data-id="empScopeYes"
            >Yes</button>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-4 p_l_0 p_r_0 m-r-15 ">
          <button type="button" class="btn button_wbg grow font_extrabold" data-id="empScopeNo"
            debounceDirective (debounceClick)="noScopeNo('#scopeModal')">No</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade custom_modal" id="empWaitModal" tabindex="-1" role="Application" aria-labelledby="empWaitModalHeader" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
      </div>
      <div class="transparentbg modal-body">
        <h4 id="empWaitModalHeader" class="modal-title m-t-10">{{'WAIT_ON_AGGREMENT_MODAL_TEXT' | translate }}</h4>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>

<div class="modal fade custom_modal" id="empCategoryChangeModal" tabindex="-1" role="Application" aria-labelledby="empCategoryChangeModalHeader" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
      </div>
      <div class="transparentbg modal-body">
        <h4 *ngIf="isReview && !isCurrentEmpNeverEmployed"  id="empCategoryChangeModalHeader" class="modal-title m-t-10">{{'EMPLOYMENT_CHANGE_CATEGORY_MODAL_TEXT' | translate }}</h4>
        <h4 *ngIf="!isReview || isCurrentEmpNeverEmployed" id="empCategoryChangeModalHeader" class="modal-title m-t-10">{{'EMPLOYMENT_NEVEREMPLOYED_CATEGORY_MODAL_TEXT' | translate }}</h4>
      </div>
      <div class="modal-footer">
        <div class="col-xs-12 col-sm-4 col-md-4 p_l_0 p_r_0">
          <button type="button" class="btn button_wbg grow font_extrabold" data-id="empCategoryChangeBack"
            debounceDirective (debounceClick)="declineCategoryChange()">{{ 'COMMONS_CANCEL' | translate }}</button>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-4 p_l_0  p_r_0">
          <button type="button" class="btn button grow font_extrabold" data-id="empCategoryChangeConfirm"
            debounceDirective (debounceClick)="confirmCategoryChange()">{{ 'COMMONS_CONTINUE' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade custom_modal" id="empCitadelErrorModal" tabindex="-1" role="Application" aria-labelledby="empCitadelErrorModalHeader" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
      </div>
      <div class="transparentbg modal-body">
        <h4 id="empCitadelErrorModalHeader" class="modal-title m-t-10">{{(isInvalidCitadelData? 'EMP_CITADEL_DATA_INVALID' : 'EMP_CITADEL_ERROR') | translate }}</h4>
      </div>
      <div class="modal-footer">
        <div class="col-xs-12 col-sm-5 col-md-5 p_l_0_imp fixedbtnb">
          <button type="button" class="btn button grow font_extrabold"
            (click)="empCitadelErrorClose()">{{'COMMONS_CONTINUE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade custom_modal" id="empDataProviderInfoModal" tabindex="-1" role="Application" aria-labelledby="empDataProviderInfoModal" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="empDataProviderModalClose()">&times;</button>
      </div>
      <div class="transparentbg modal-body">
        <h3 class="modal-title listItemHeader">
          <div>{{ 'EMP_DATA_PROVIDER_INFO_MODAL_HEADER' | translate }}</div>
        </h3>
        <h3 class="modal-title listItemHeader">
          <div>{{ 'EMP_DATA_PROVIDER_INFO_MODAL_LOGIN_COUNT' | translate }}</div>
        </h3>
        <div class="listItemHeader m-b-10">{{ 'EMP_DATA_PROVIDER_INFO_MODAL_LOGIN_LABEL' | translate }}</div>
        
        <h3 class="modal-title listItemHeader">
          <div>{{ 'EMP_DATA_PROVIDER_INFO_MODAL_INSTITUTION_COUNT' | translate }}</div>
        </h3>        
        <div class="listItemHeader m-b-10">{{ 'EMP_DATA_PROVIDER_INFO_MODAL_INSTITUTION_LABEL' | translate }}</div>
        
        <div class="listItemHeader m-b-10">{{ 'EMP_DATA_PROVIDER_INFO_MODAL_SUBHEADER_1' | translate }}</div>
        <div class="listItemHeader custom_div_modal m-b-10">{{ 'EMP_DATA_PROVIDER_INFO_MODAL_SUBHEADER_2' | translate }}</div>
        
        <div class="custom_ul_li custom_div_modal m-b-10">
          <ul>
            <li>
              <span class="greenCheckIcon">
                <i class="glyphicon glyphicon-ok"></i>
              </span>
              <span>{{ 'EMP_DATA_PROVIDER_LABEL_1' | translate }}</span>
            </li>
            <li>
              <span class="greenCheckIcon">
                <i class="glyphicon glyphicon-ok"></i>
              </span>
              <span>{{ 'EMP_DATA_PROVIDER_LABEL_2' | translate }}</span>
            </li>
            <li>
              <span class="greenCheckIcon">
                <i class="glyphicon glyphicon-ok"></i>
              </span>
              <span>{{ 'EMP_DATA_PROVIDER_LABEL_3' | translate }}</span>
            </li>
          </ul>
        </div>        
        <div class="listItemHeader custom_div_modal">{{ 'EMP_DATA_PROVIDER_INFO_MODAL_CONTENT' | translate }}</div>
      </div>
      <div class="modal-footer">
        <div class="row pad_30">
          <div class="col-xs-12 col-sm-4 col-sm-offset-4 col-md-offset-4 col-md-4">
            <button type="button" class="btn button_wbg font_extrabold grow sendButton button_wbgSav" (click)="empDataProviderModalClose()">{{ 'COMMONS_OKAY' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
