import { ViewEncapsulation, Component } from "@angular/core";
import { TranslateService } from "framework/i18n";
import { UtilService } from "framework/utils/util.service";
import { Store } from "@ngrx/store";
import * as ACTIONS_INTERFACE from '../../actions';
import { Validators } from "@angular/forms";
import { EventBusService } from "framework/eventbus.service";
import * as dateUtils from '../../../framework/utils/date.utils';
import { DOTService } from "./dot.service";
import { DOTConfig } from "./dot.config";
import { FieldConfig } from "framework/dynamicCard/models/fieldconfig.interface";

@Component({
    selector: 'dot-custform',
    templateUrl: './dot-customform.component.html',
    encapsulation: ViewEncapsulation.None
})
export class DotCustomFormComponent {

    // Custom Forms
    customFormData: any;
    config: FieldConfig[];
    counter: number;
    actionvalue: string;
    decideButtonclass: any = [];
    finalSourceInputs = [];
    dummyFinalInputData: any;
    currentStatus: string = 'VISITED';
    applyWotcclass: boolean = false;
    monthNames:any;

    constructor(
        public _ds: DOTService,
        public _eventBus: EventBusService,
        public _ts: TranslateService,
        public _util: UtilService,
        public _store: Store<ACTIONS_INTERFACE.AppState>
    ) {
    }

    ngOnInit() {
        console.log("DotCustomFormComponent ngOnInit");
        this.counter = 0;
        this.monthNames = this._ts.instant("MONTH_NAMES");
    }

    prepareHeaderText(_data: any, headersSubHeaders) {
        return (_data['header'] !== undefined) ? _data['header'] : (_data['name'] !== undefined) ? _data['name'] : headersSubHeaders[0]['header'];
    }

    prepareButtonList(inputData) {
        let finalButtonList;

        const commonButtons = [
            {
                name: 'BACK TO REVIEW',
                label: this._ts.instant('COMMONS_BACK_TO_REVIEW'),
                type: 'BUTTON',
                show: false
            },
            {
                name: 'SAVE & EXIT',
                label: this._ts.instant('COMMONS_SAVE_AND_EXIT'),
                type: 'BUTTON',
                show: true
            }
        ];

        const regularButtons = [
            {
                name: 'NEXT',
                label: this._ts.instant('COMMONS_NEXT'),
                type: 'BUTTON',
                show: true
            }
        ];

        finalButtonList = regularButtons.concat(commonButtons);

        return finalButtonList;

    }

    prepareCardData(data: any, headersSubHeaders: any, extFormName: string): any {
        let _data = data;
        let finalData: any = [];
        let i = this.counter;
        // Contains ListOfinputs array of control objects
        let tempInput;

        // Contains Header and Subheader info
        let tempInfo;

        // Button List
        let buttonInputs;

        let processedData: any;
        let headerTxt: string;
        let subHeaderTxt: string = '';
        let section;
        let showInstruction: boolean = true;
        let acicSign: boolean = false;
        acicSign = this.getAcicSignature(_data);
        // Decide tempInput data
        tempInput = this.getTempInput(_data);
        // Prepare Header
        headerTxt = this.prepareHeaderText(_data, headersSubHeaders);
        // headerTxt = (_data['header'] !== undefined) ? _data['header'] : headersSubHeaders[0]['header'];
        subHeaderTxt = (_data['subheader'] !== undefined) ? _data['subheader'] : '';
        let instructions = _data['instructions'] !== undefined ? _data['instructions'] : '';
        let popupMsg = this.getPopupVal(_data);
        if (!!popupMsg['popUpMsg']) {
            let option = {};
            option['instructions'] = popupMsg['popUpMsg'];
            option['section'] = '';
            option['cpic'] = popupMsg['cpic'];
            this.setCustomInstructions(option);
            showInstruction = false;
        }

        // Buttons List Preparation
        buttonInputs = this.prepareButtonList(_data)
        processedData = {
            'name': extFormName + i,
            'header': this._ts.instant(headerTxt),
            'sub_header': subHeaderTxt,
            'body': subHeaderTxt,
            'section': section,
            'instructionFlag': showInstruction,
            'instructions': instructions,
            'swipe_left': true,
            'swipe_right': true,
            'arrow_enabled': true,
            'inputs': buttonInputs
        };

        let tempValues = processedData['inputs'];

        // Move the below code to a separate shared method
        for (let j = 0; j < tempInput.length; j++) {
            let tempValidation = [];
            let fieldType = (tempInput[j]['type']).toLowerCase();

            // If name does not come
            if (tempInput[j]['name'] === undefined) {
                tempInput[j]['name'] = tempInput[j]['id'];
            }

            // Validator setting - Text field
            if (DOTConfig.getFieldTypes().indexOf(fieldType) > -1) {
                this.setCustomCSS(fieldType, tempInput[j]);
                if (!tempInput[j]['validation_data']) {
                    tempInput[j]['validation_data'] = tempInput[j]['validation'];
                } else {
                    tempInput[j]['validation'] = tempInput[j]['validation_data'];
                }

                tempValidation = this.setValidations(tempInput[j]['validation']);
                tempInput[j]['validation'] = tempValidation;
            }

            if ((tempInput[j]['type']).toLowerCase() === 'checkbox') {
                tempValidation.push(Validators.pattern('true'));
            }

            if ((tempInput[j]['type']).toLowerCase() === 'checkboxex') {
                tempValidation.push(Validators.pattern('true'));
                if (acicSign) {
                    tempInput[j]['acicSign'] = _data['signature_img_data'];
                }
            }

            tempValues.push(tempInput[j]);
        }

        processedData['inputs'] = tempValues;
        finalData.push(processedData);
        return finalData;
    }

    setValidations(validation) {
        let tempValidation = [];

        if (validation['allow_blank'] && validation['allow_blank'] === false) {
            tempValidation.push(Validators.required);
        }

        if (validation['max_length']) {
            tempValidation.push(Validators.maxLength(validation['max_length']));
        }

        if (validation['min_length']) {
            tempValidation.push(Validators.minLength(validation['min_length']));
        }

        // Custom Forms - (different structure, need to stream line here)
        if (validation && validation['is_required']) {
            tempValidation.push(Validators.required);
        }

        if (validation && validation['maximum_size']) {
            tempValidation.push(Validators.maxLength(validation['maximum_size']));
        }

        return tempValidation;
    }

    setStateValues() {
        if (this.actionvalue === this._ts.instant('COMMONS_NEXT') ||
            this.actionvalue === this._ts.instant('COMMONS_SUBMIT') ||
            this.actionvalue === 'YES' ||
            this.actionvalue === 'NO') {
            this.currentStatus = 'COMPLETED';
        } else if (this.actionvalue === this._ts.instant('COMMONS_SAVE_AND_EXIT')) {
            this.currentStatus = 'PENDING';
        }
    }

    changeDateFormat(value) {
        if (value != null) {
            return dateUtils.formatDateYYYYMMDD(value,  this.monthNames)
        }
        return value;
    }

    getTempInput(_data) {
        let value;
        if (_data['candidate_form_fields_list'] !== undefined) {
            value = _data['candidate_form_fields_list'];
        }
        else if (_data['list_of_acic_inputs'] !== undefined) {
            value = _data['list_of_acic_inputs'];
        } else {
            value = _data;
        }


        return value;
    }

    prepareCustomFormsInputs(formInputs) {
        for (let i = 0; i < formInputs.length; i++) {
            if (formInputs[i]['candidate_form_fields_list'] !== undefined &&
                formInputs[i]['candidate_form_fields_list'].length > 0) {
                // Call Field Mapper Service to keep field type consistent
                this.finalSourceInputs.push(this._ds.fieldMapper(formInputs[i], 'candidate_form_fields_list'));
            }
        }
    }

    prepareSubmitData(submittedData) {
        let p = this.counter;
        let x = this.dummyFinalInputData[p];
        if (x['candidate_form_fields_list']) {
            this.prepareSpecificSubmitData(p, x, submittedData, 'Custom Form');
        }
    }

    prepareSpecificSubmitData(p, x, submittedData, feature) {
        let propName = this._ds.getFeatureInputCollectionPropName(feature); //'candidate_form_fields_list';
        let fieldsCollection = (x[propName] !== undefined) ? x[propName] : x;

        for (let k = 0; k < fieldsCollection.length; k++) {
            for (let key in submittedData) {
                let value = submittedData[key];

                if (this.dummyFinalInputData[this.counter][propName] !== undefined) {
                    if (this.dummyFinalInputData[this.counter][propName][k]['name'] === key) {
                        if (this.dummyFinalInputData[this.counter][propName][k]['type'] == 'DATE') {
                            value = this.changeDateFormat(value);
                        }

                        // Type - "YesNoQuestionWithExplanation"
                        if (this.dummyFinalInputData[this.counter][propName][k]['type'] == 'YesNoQuestionWithExplanation') {
                            // Need to check why every value is getting concatenated
                            value = value.split('|')[0];
                            value += (submittedData[key + '_textarea']) ? ('|' + submittedData[key + '_textarea']) : '';
                        }

                        this.dummyFinalInputData[this.counter][propName][k]['value'] = value;
                        this.dummyFinalInputData[this.counter][propName][k]['validation'] =
                            this.dummyFinalInputData[this.counter][propName][k]['validation_data'];

                        delete this.dummyFinalInputData[this.counter][propName][k]['validation_data'];
                    }
                } else {
                    if (this.dummyFinalInputData[this.counter][k]['name'] === key) {
                        if (this.dummyFinalInputData[this.counter][k]['type'] == 'DATE') {
                            value = this.changeDateFormat(value);
                        }

                        // Type - "YesNoQuestionWithExplanation"
                        if (this.dummyFinalInputData[this.counter][propName][k]['type'] == 'YesNoQuestionWithExplanation') {
                            value = value.split('|')[0];
                            value += (submittedData[key + '_textarea']) ? ('|' + submittedData[key + '_textarea']) : '';
                        }

                        this.dummyFinalInputData[this.counter][k]['value'] = value;
                        this.dummyFinalInputData[this.counter][k]['validation'] =
                            this.dummyFinalInputData[this.counter][k]['validation_data'];

                        delete this.dummyFinalInputData[this.counter][k]['validation_data'];
                    }
                }
            }
        }
    }

    getPopupVal(option) {
        let popUpMsg: string = (option['instructions'] !== undefined) ? option['instructions'] : '';
        let checkVal: boolean = (option['resultStatus'] !== undefined && option['retryRequired'] !== undefined);
        let isInstructionFirstTime: boolean = (option['resultStatus'] === 'UNTRACKED' && option['retryRequired']);
        let errorCheck: boolean = option['resultStatus'] === 'ERROR';
        let isNextAttempt: boolean = (option['resultStatus'] === 'PASS' ||
            option['resultStatus'] === 'FAIL') && (!option['retryRequired'] || option['retryRequired']);

        if (checkVal && isInstructionFirstTime) {
            // show only instructions in popup
            popUpMsg = option['instructions'];
        } else if (checkVal && isNextAttempt) {
            popUpMsg = option['result_message'];
        } else if (checkVal && errorCheck) {
            popUpMsg = option['result_message'];
        } else {
            popUpMsg = option['instructions'];
        }

        return {
            'popUpMsg': popUpMsg,
            'cpic': true
        };
    }

    setCustomCSS(fieldType, element) {
        switch (fieldType.toUpperCase()) {
            case "CHECKBOX": {
                DOTConfig.setCustomClass(element);
                break;
            }
            case "TSCHECKBOX": {
                DOTConfig.setCustomClass(element);
                break;
            }
        }
    }

    addCSSToButtons(config) {
        if (!!config) {
            let len = config[0]['inputs'].length;
            let inputs = config[0];
            let inactiveButtons = DOTConfig.inactiveButtons;
            for (let i = 0; i < len; i++) {
                if (inactiveButtons.indexOf(inputs['inputs'][i]['label']) > -1) {
                    this.decideButtonclass[i] = 'btn' + ' ' + 'button_wbg' + ' ' + 'font_extrabold' + ' ' + 'grow';
                } else {
                    this.decideButtonclass[i] = 'btn' + ' ' + 'button' + ' ' + 'font_extrabold' + ' ' + 'sendButton' + ' ' + 'grow';
                }
            }
        }
    }

    setCustomInstructions(option) {
        let hideHeader: boolean = false;
        hideHeader = (option['cpic'] !== undefined && option['cpic']) ? true : false;
        this._eventBus.customInstructionAnnounced({
            'section': this._ts.instant(option['section']),
            'instructions': option['instructions'],
            'hideHeader': hideHeader
        });
    }

    getAcicSignature(_data) {
        let signParam: boolean;
        return signParam = _data['signature_img_data'] !== undefined && !this._util.isEmpty(_data['signature_img_data']) ? true : false;
    }

    // Copy form and field id from the savedForm to the form
    copyFormIdAndFieldId(form, savedForm) {
        if (form['form_id'] == undefined && savedForm) {
            //Coping the form id and field id to avoid creating duplicate records when
            //moving back and forward.
            form['form_id'] = savedForm['form_id'];
            let fields: any[] = savedForm['candidate_form_fields_list'];
            let customFields = form['candidate_form_fields_list'];
            for (let i = 0; i < fields.length; i++) {
                for (let j = 0; j < customFields.length; j++) {
                    let field = fields[i];
                    let customField = customFields[j];
                    if (field['attribute_id'] === customField['attribute_id']) {
                        customField['field_id'] = field['field_id'];
                    }
                }
            }
        }
    }
}