
export class UtilConfig {

    // list of regex format
    static regexObj: Object = {
        'YYYY-MM-DD': /^\d{4}\-\d{1,2}\-\d{1,2}$/
    };

    // return regex based on the input format.
    static getRegex(format) {
        return this.regexObj[format];
    }
}

