import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../../framework/localstorage.service';
import { EventBusService } from './../../framework/eventbus.service';
import { AppConfig } from '../app.config';
import { TRANSLATION_PROVIDERS, TranslatePipe, TranslateService } from '../../framework/i18n';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedService } from 'app/shared/services/shared.service';
declare const jQuery: any;

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html'
})

export class FooterComponent implements OnInit {
    isContactUsAvailable: boolean = false;
    instruction: any;
    isLanguageReceived: boolean = false;
    termsOfServicesData:any;
    termsOfServiceHeader:any;
    isShowTermsServices: boolean = true;
    isEARightId: boolean = false;
    isInviteAssessment: boolean = false;
    isShowTOS: boolean = false;
    termsOfServiceTitle: string = '';
    policy: string = '';
    contactUs: string = '';

    constructor(private localStorage: LocalStorageService,
        private _eventBus: EventBusService,
        private _translate: TranslateService,
        private _sanitizer: DomSanitizer,
        private _shareService: SharedService,
        ) {
    }

    ngOnInit() {
        /**
         * Footer links will reflect the multilingual language only after
         * user is logged in.
         *
         * TODO: Need to check if both of these can be converged into
         * a single EVENT raise.
         */
        let that = this;
        this.setTermsOfServiceData();
        this.doTranslations();
        this._eventBus.languageInInviteReceived$.subscribe(item =>  {
            that.isEARightId = that._shareService.checkInviteIsRightId();
            // console.log("footer component isEARightId ", that.isEARightId);

            that.isShowTOS = that._shareService.checkToShowTOS();
            // console.log("footer Component isShowTOS : ", that.isShowTOS);

            if (!that.isEARightId) {
                this.isLanguageReceived = item['isLanguageReceived'];
            }

            if (!that.isEARightId && !that.isShowTOS) {
                if (this.isLanguageReceived) {
                    this.setTermsOfServiceData();
                }
            }
            if (!!item && item['translationsLanguage']) {
                if (that._translate.currentLang !== item['translationsLanguage']) {
                    that._translate.use(item['translationsLanguage']);
                }
                that.doTranslations();
            }
        });

        /** Hide Terms & Service Link when for terms and service page opened from authy or any third party */
        this._eventBus.forceNotToShowTosReceived.subscribe(item => {
            if (!!item && !item['show_tos']) {
                that.isShowTOS = true;
            }
        });

        /** Hide Terms of service after login and set password */
        this._eventBus.announceTermsServicesHide$.subscribe(item => {
            this.isShowTermsServices = item['status'];
        });

        /**
         * 'Contact Us' is behind a feature flag now. (features.config.ts)
         * false - means off i.e. no 'Contact Us' link
         * true - means on i.e. 'Contact Us' link to be shown if present in response object
         */
        if (AppConfig.isFeatureAvailable('app-features', 'contact-us')) {
            this._eventBus.contactUsAnnounced$.subscribe(item => {
                this.instruction = item.contactUs;
                this.isContactUsAvailable = item.isContactUsReceived;
            });
        }
    }

    setTermsOfServiceData(){ 
        const termsOfServiceKey = this.localStorage.getItem('isIntlPreferenceOn') ? 'TERMS_AND_CONDITIONS_CONTENT_INTL' : 'TERMS_AND_CONDITIONS_CONTENT';
        this.termsOfServicesData = this._sanitizer.bypassSecurityTrustHtml(this._translate.instant(termsOfServiceKey));         
        this.termsOfServiceHeader = this._translate.instant('TERMS_SERVICE_1');
    }

    openContactUsModal() {
        jQuery('#contactUsModal').modal('show');
    }

    openTermsModal(event){
        event.preventDefault();
        this.isInviteAssessment = this._shareService.checkInviteIsAssessment();
        if(this.isInviteAssessment){
            this.termsOfServicesData = this._sanitizer.bypassSecurityTrustHtml(this._translate.instant('TERMS_AND_CONDITIONS_ASSESSMENT_CONTENT'));
        }else{
            const termsOfServiceKey = this.localStorage.getItem('isIntlPreferenceOn') ? 'TERMS_AND_CONDITIONS_CONTENT_INTL' : 'TERMS_AND_CONDITIONS_CONTENT';
            this.termsOfServicesData = this._sanitizer.bypassSecurityTrustHtml(this._translate.instant(termsOfServiceKey));
        }
        jQuery('#termsServices').modal('show');
    }

    doTranslations() {
        this.policy = this._translate.translate("FOOTER_PRIVACY");
        this.contactUs = this._translate.translate("FOOTER_CONTACTUS");
        this.termsOfServiceTitle = this._translate.translate("TERMS_SERVICE_TITLE");
    }
}
