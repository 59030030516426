import { Component, Output, EventEmitter, Inject } from '@angular/core';
import { ActivatedRoute, Router, Event as NavigationEvent } from '@angular/router';
import { EventBusService } from '../../framework/eventbus.service';
import { Subscription } from 'rxjs';
import { pairwise } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as ACTIONS_INTERFACE from '../actions';
import { WorkflowService } from '../../framework/workflow.service';
import { LocalStorageService } from '../../framework/localstorage.service';
import { AuthService } from './../login/auth.service';
import { AppConfig } from '../app.config';
import { SharedService } from '../shared/services/shared.service';
import { TRANSLATION_PROVIDERS, TranslatePipe, TranslateService } from './../../framework/i18n';
import { environment } from 'environments/environment';
import { DOCUMENT } from "@angular/common";

declare let window: any;
declare let jQuery: any;
declare var document: any;
@Component({
  selector: 'app-header',
  providers: [AuthService, AppConfig],
  templateUrl: './header.component.html'
})

export class HeaderComponent {
  isUserInfoShown: boolean = false;
  isConsentMenu: boolean = false;
  hideHeaderMenu: boolean = false;
  loggedInUser: String = null;
  loginSubscription: Subscription;
  reviewMenuFlag: boolean = false;
  url: string = '';
  isShownPopup;
  firstName;
  eventStateStore: any;
  displayLanguage: any[] = [];
  isShowLangIcon: boolean = false;
  updateLanguageText: boolean = false;
  changedMind: String;
  understandContent: String;
  hideLanguageSelectorInLoginPage: boolean = false;
  isCustomerInstructions: boolean = false;
  welcomeText: string = '';
  dashboardText: string = '';
  settingsText: string = '';
  profileHeaderText: string = '';
  signoutText: string = '';
  headerModelText: string = '';
  logoutHeaderModel1Text: string = '';
  logoutHeaderModel2Text: string = '';
  continueBtnText: string = '';
  backBtnText: string = '';
  waitModelText: string = '';
  @Output() onSetMydata: EventEmitter<any> = new EventEmitter();

  constructor(private route: ActivatedRoute,
    private eventBus: EventBusService,
    private router: Router,
    private localStorage: LocalStorageService,
    private _workflow: WorkflowService,
    private _ts: TranslateService,
    private shareService: SharedService,
    private _service: AuthService,
    @Inject(DOCUMENT) private document: any,) {
    this.eventBus.logInAnnounced$.subscribe(item => {
      this.isUserInfoShown = true;
      this.loggedInUser = item.usename;
    });

    this.eventBus.logOutAnnounced$.subscribe(item => {
      window.track_event("show confirmation modal", { stage_name: 'sub menu', action_value: 'logout clicked' }, true, true);
      this.eventStateStore = item['dummy'];
      setTimeout(jQuery('#logout_confirmation_modal').modal('show'), 1000);
    });

    this.eventBus.hideHeaderAnnounced$.subscribe(item => {
      this.isUserInfoShown = item['userInfoShownValue'];
      this.loggedInUser = '';
    })

    // Consent MENU CODE -- START
    this.eventBus.consentAnnounced$.subscribe(item => {
      this.isConsentMenu = item.isConsent;
    });

    this.eventBus.instructions$.subscribe(item => {
      this.isCustomerInstructions = item.customerInstructions;
    });
    this.eventBus.hideHeaderMenuAnnounced$.subscribe(item => {
      this.hideHeaderMenu = item.hideHeaderMenu;
    });
    let that = this;
    this.eventBus.languageInInviteReceived$.subscribe(item => {
      if (!!item && item['translationsLanguage']) {
        if (this._ts.currentLang !== item['translationsLanguage']) {
          this._ts.use(item['translationsLanguage']);
        }
        that.doTranslations();
      }
    });
  }

  _doAction(userData: Object): void {
    this.isUserInfoShown = true;
    this.loggedInUser = userData['username'];
  }

  ngOnInit() {
    this.router.events.forEach((event: NavigationEvent) => {
      this.router.events.pipe(pairwise())
        .subscribe((e) => {
          this.url = e[1]['urlAfterRedirects'];
        });

      if (this.url === '/view-profile') {
        this.isShownPopup = false;
      } else {
        this.isShownPopup = true;
      }
    });


    // Decide whether to show the language list or not
    this.eventBus.languageListInInviteReceivedAnnounced$.subscribe(item => {
      this.hideLanguageSelectorInLoginPage = !!item && item.hideLanguageSelectorInLoginPage === true ? true : false;
      this.userDefinedLangSetting(item['availableLangList']);
    });

    this.firstName = this.localStorage.getItem('first_name') || null;
    this.doTranslations();
  }

  showPopup(id) {
    if (this.isShownPopup) {
      this.firstName = (!this.firstName || !!this.firstName) ? this.localStorage.getItem('first_name') : this.firstName;
      this.changedMind = this._ts.instant('REVIEW_MODAL_BTN_CHANGE');
      this.understandContent = this._ts.instant('COMMONS_UNDERSTAND');
      jQuery(id).modal('show');
    } else {
      this._next('/dashboard');
    }
  }

  redirectToSetting() {
    this._next('/settings');
  }

  ngOnDestroy() {
  }

  logOut(): void {
    /*
     * Set the isUserInfoShown to false
     * Raise the logout notification to be listened by login component to do logout
    */

    this.eventBus.logoutAnnounced({
      dummy: 'logout'
    });
  }

  goToDashboard() {
    this.eventBus.saveexitAnnounced({
      dummy: 'saveexit'
    });
  }

  _next(url): void {
    this.router.navigate([url]);
  }

  redirectProfile() {
    this._next('/view-profile');
  }

  redirectToDashboard() {
    this._next('/dashboard');
  }

  checkRoutingPath() {
    // Get URL routing path
    let url = window.location.hash;
    let urlBlockList;
    let routingPath;

    urlBlockList = AppConfig.getUrlsBlockList();

    if (url.split('/')[2] !== undefined) {
      // #/home/login -- Triggers when we change routing path.
      routingPath = url.split('/')[2];
    } else if (url.split('/')[1] !== undefined) {
      // #/login -- Triggers when we copy and paste the signup URL while user is already loggedin/active.
      routingPath = url.split('/')[1];
    }
    return (urlBlockList.indexOf(routingPath) >= 0) ? true : false;
  }

  closeLogoutConfrmnModal() {
    // Decide if it is login we need clear session else hide the modal
    if (this.checkRoutingPath()) {
      this.logoutApp();
    } else {
      jQuery('#logout_confirmation_modal').modal('hide');
      jQuery('.modal-backdrop').remove();
    }
  }

  logoutApp() {
    window.track_event("logout and delete session", { stage_name: 'sub menu', action_value: 'continue clicked, logout confirmed' }, true, true);
    let state = this.eventStateStore;
    this.isUserInfoShown = false;
    this.loggedInUser = '';
    this.shareService.raisingEventTermsServiceHide(this.eventBus, true);
    this._service.logout();
    
    // TODO - Add shutdown SFC and boot SFC
    
    if (state === 'workflow_exit') {
      if (window.hasOwnProperty('safeNotifyHost')) window.safeNotifyHost('workflow_exited', null);
    } else if (state === 'logout') {
      if (window.hasOwnProperty('safeNotifyHost')) window.safeNotifyHost('signed_out', null);
    } else if (state === undefined) {
      jQuery('.modal-backdrop').remove();
    }
    
    this._next('./login');
  }

  userDefinedLangSetting(items) {
    // if available language list is empty hide the icon, else show the icon with language list
    if (items !== undefined && items.length > 0) {
      const isInviteTypeEnabled =  this.shareService.processInviteType();
      const isRidInvite = this.shareService.checkInviteIsRightId();

      this.isShowLangIcon = (isInviteTypeEnabled && !isRidInvite) || this.hideLanguageSelectorInLoginPage ? false : true;
      //this.isShowLangIcon = isInviteTypeEnabled && !isRidInvite ? false : true;

      // Get appropriate language name based on the language code
      let languageConfig = AppConfig.getDisplayLanguageList();
      let langWithCountryCode;
      let languageOnInvite = this.localStorage.getItem('language');
      let languageObj: any;
      let removeLanguageBasedOnPrefernce: any;
      this.displayLanguage = [];

      // Switch language based on the preference (EN-US or EN-GB)
      // If isIntlPrefence is ON, we have to show only EN-GB, else we have to show EN-US
      removeLanguageBasedOnPrefernce = this.localStorage.getItem('isIntlPreferenceOn') ? 'en' : 'en-gb';
      delete languageConfig[removeLanguageBasedOnPrefernce];

      // Make the available language list with language name
      for (let value of items) {
        langWithCountryCode = this.shareService.validateAndReturnLanguageCode(this.shareService.prepareLanguage(value));
        if (languageConfig.hasOwnProperty(langWithCountryCode)) {
          languageObj = {};
          languageObj = value;
          languageObj['languageName'] = languageConfig[langWithCountryCode]['languageName'];
          languageObj['flagIconName'] = languageConfig[langWithCountryCode]['flagIconName'];
          languageObj['langWithCountryCode'] = langWithCountryCode;
          languageObj['addId'] = (langWithCountryCode === languageOnInvite) ? 'activeLanguageList' : '';
          this.displayLanguage.push(languageObj);
        }
      }
    } else {
      this.isShowLangIcon = false;
    }
  }

  updateLanguage(data) {
    // Check langauge is similar to invite language
    if (this.isLanguagePreSelected(data['langWithCountryCode'])) {
      // Show modal wait popup
      this.updateLanguageText = true;
      jQuery('#plsWaitAMoment').modal('show');

      // Prepare an object to make put call
      let obj = {};
      obj['language'] = data['language'];
      obj['language_country_code'] = data['language_country_code'];
      obj['key'] = this.localStorage.getItem('key');

      window.track_event("language changed to " + data['language'], { stage_name: 'sub menu', action_value: 'language selector clicked' }, true, false);
      // Make put call - response we have to reload
      this.shareService.updateLanguageOnServer(obj).subscribe(response => {
        // Update footer text as per the user selected language from the language drop down
        this.eventBus.languageInInviteReceivedAnnounced({
          isLanguageReceived: false
        });

        this.eventBus.contactUsAnnounced({
          contactUs: '',
          isContactUsReceived: false
        });

        // Hide modal wait popup
        jQuery('#plsWaitAMoment').modal('hide');
        this.updateLanguageText = false;

        // Reload Application
        window.location = AppConfig.API_ENDPOINT() + '/#/invite/?key=' + obj['key'];
      },
        error => {
        });
    }
  }

  // Avoid selecting same language multiple times
  isLanguagePreSelected(selectedLangCode) {
    let languageOnInvite = this.localStorage.getItem('language');
    return (languageOnInvite !== selectedLangCode) ? true : false;
  }

  doTranslations() {
    this.firstName = this.localStorage.getItem('first_name') || null;
    this.welcomeText = this._ts.translate("HEADER_WELCOME");
    this.dashboardText = this._ts.translate("HEADER_DROPDOWN_DASHBOARD");
    this.settingsText = this._ts.translate("HEADER_DROPDOWN_SETTING");
    this.profileHeaderText = this._ts.translate("HEADER_DROPDOWN_PROFILE");
    this.signoutText = this._ts.translate("HEADER_DROPDOWN_SIGNOUT");
    this.headerModelText = this._ts.instant("HEADER_MODAL_TEXT", this.firstName);
    this.logoutHeaderModel1Text = this._ts.translate("LOGOUT_MODAL_HEADER_1");
    this.logoutHeaderModel2Text = this._ts.translate("LOGOUT_MODAL_HEADER_2");
    this.continueBtnText = this._ts.translate("COMMONS_CONTINUE");
    this.backBtnText = this._ts.translate("COMMONS_GOBACK");
    this.waitModelText = this._ts.translate("WAIT_ON_AGGREMENT_MODAL_TEXT");
  }
}
