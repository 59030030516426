<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12">
    <p class="black_text">{{options['title']}}</p>
    <hr>
  </div>
</div>
<div class="addCard m-b-10">
  <div class="row">
    <div class="col-xs-12 col-sm-4 col-md-3 dp-r-0" *ngFor="let data of options.data">
      <div class="clipCard" (click)="openViewProfilePopup(data,options['key'])">
        <div class="imgplaceholder">
          R
        </div>
        <p class="text_ellipses">{{data.account.name}}</p>
        <p>
          <span class="duration"> {{'VP_REQUEST_INVITE_DATE' | translate}}: {{data.invite_date | timeStampConvert}}
          </span></p>
        <p class="padtop0"> <span class="duration"> {{'VP_REQUEST_EXPIRATION_DATE' | translate}}:
            {{data.expiry_date | timeStampConvert}} </span></p>
      </div>
    </div>
  </div>
</div>
