import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, OnChanges, Renderer2, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { EmploymentConfig } from '../employment.config';
import { EmploymentService } from '../employment.service';
import { SharedService } from '../../shared/services/shared.service';

import { EventBusService } from 'framework/eventbus.service';
import { UtilService } from '../../../framework/utils/util.service';
import { debounceTime, distinctUntilChanged, switchMap,tap, catchError, filter } from 'rxjs/operators';

// State
import { Store } from '@ngrx/store';
import * as ACTIONS_INTERFACE from '../../actions';
import { CustomSelectComponent } from 'framework/customSelect';
import { of } from 'rxjs';


declare let google: any;
declare let jQuery: any;


@Component({
  selector: 'country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.css']
})
export class CountryComponent implements OnInit, AfterViewInit, OnChanges {
  // SE functionality
  static STAGE_ID: string;
  static STEP_ID: string;
  static STEP_INDEX: number;
  // State Maintenance Related Activity
  static BUTTON_CLICKED: string = 'CLICKED_SAVE_EXIT_BUTTON';
  @Input('group')
  public empSubForm: FormGroup;
  @Input() row: any;
  @Input() options: any;
  @Input() userDetails: any;
  @Input() empType: string;
  @Input() configOptions: any;
  @Input() isReviewEdit: boolean;
  @Input() isConsentCountry: boolean = false;
  @Output() onCountry: EventEmitter<any> = new EventEmitter();
  @Output() onCountrySwipe: EventEmitter<any> = new EventEmitter();
  // @ViewChild(CustomSelectComponent) customselect: CustomSelectComponent;

  countryTypeahead = new EventEmitter<string>();
  // selectedCountryCode: any;

  public SUB_STEP_ID: string;
  public SUB_STEP_INDEX: number;

  countryList: any = [];

  isCountryListToShow: boolean = false;
  isCountryChanged: boolean = false;

  countryOptions: Object;
  countryShortName:any;
  isCountryValid;
  latestCountryLnamFromApi;
  autocomplete;
  input;
  isShowCountryValidErr;
  countryId;
  isOpenGoogleSuggestion: boolean;
  isCountryError: boolean = false;
  isCountryErrorInfo: boolean = false;
  isViewInit;
  errMsg: string;
  currentCountry;
  PARENT_STAGE_STEP_CONFIG: Object;
  currentStatus: string = 'VISITED';
  payLoad: Object;
  startTime: any;
  ValidCountryFlag: boolean = true;
  errorMsgShow: boolean = false;
  selectedCountry: string;

  constructor(private _es: EmploymentService,
    private _eventBus: EventBusService, // Auto Generated PPA-8745 - Arun Supreet for Save n Exit Patch
    private _util: UtilService,
    private shareService: SharedService,
    private _store: Store<ACTIONS_INTERFACE.AppState>,
    private _cdr: ChangeDetectorRef) {
    this.startTime = new Date().getTime();
  }

  ngOnInit(): void {
    this.countryOptions = this.options['countryOptions'];
    // setTimeout(() => {
      this.countryId = 'empCountry_' + this.row;
      this.isCountryValid = true;
      this.isShowCountryValidErr = false;
      this.isOpenGoogleSuggestion = false;
    // }, 200);

    /**
     * Employment component - setATSData() will send notification
     */
    this.shareService.countryNotified$.subscribe(data => {
      this.selectedCountry = data['country_name'];
      this.ValidateCountry(this.empSubForm.controls['countryName'].value);
    });

    this.selectedCountry = this.empSubForm.controls['countryName'].value;
    this.countryServerSideSearch();
  }

  ngOnChanges() {
    if (this.isViewInit && this.empType === 'prior') {
      let defaultCountryOptions = EmploymentConfig.defaultCountryOptions();
      this.countryShortName = defaultCountryOptions['defaultCountryShortName'];
      this.isCountryValid = true;
    }
  }

  ngOnDestroy(): void {
    if (this.empSubForm.controls['countryName'] !== undefined) {
      this.ValidateCountry(this.empSubForm.controls['countryName'].value);
    }
  }

  ngAfterViewInit(): void {
    // console.log('----------- ngAfterViewInit -----------------');
    this.processConfig();
    this.isViewInit = true;
    let defaultCountryOptions = EmploymentConfig.defaultCountryOptions();

    if (this.row === 0) {
      this.countryShortName = defaultCountryOptions['defaultCountryShortName'];
      this.isCountryValid = true;

      let countryName = <FormControl>this.empSubForm.controls['countryName'].value || '';
      let countryCode = <FormControl>this.empSubForm.controls['countryCode'].value || '';

      if (!!countryCode) {
        this.countryShortName = countryCode || defaultCountryOptions['defaultCountryShortName'];
      }

      if (!this.isReviewEdit) {
        this.selectedCountry = countryName || defaultCountryOptions['defaultCountry'];

        (<FormControl>this.empSubForm.controls['countryName']).setValue(countryName || defaultCountryOptions['defaultCountry']);
        (<FormControl>this.empSubForm.controls['countryCode']).setValue(countryCode || this.countryShortName);
      }

      // this.ValidateCountry(this.empSubForm.controls['countryName'].value);
    }

    if (this.countryOptions['country'] !== null && this.countryOptions['country'] !== undefined && this.countryOptions['country'] !== '') {
      this.countryShortName = this.countryOptions['countryShortName'];
    
      this.selectedCountry = this.countryOptions['country'];
      (<FormControl>this.empSubForm.controls['countryName']).setValue(this.countryOptions['country']);
      (<FormControl>this.empSubForm.controls['countryCode']).setValue(this.countryOptions['countryShortName']);
    } else {
      this.countryShortName = defaultCountryOptions['defaultCountryShortName'];

      this.selectedCountry = defaultCountryOptions['defaultCountry'];
      (<FormControl>this.empSubForm.controls['countryName']).setValue(defaultCountryOptions['defaultCountry']);
      (<FormControl>this.empSubForm.controls['countryCode']).setValue(defaultCountryOptions['defaultCountryShortName']);
    }

    this.ValidateCountry(this.empSubForm.controls['countryName'].value);
    this.latestCountryLnamFromApi = (<FormControl>this.empSubForm.controls['countryName']).value;
    // debugger;
    let options = this._es.geoOption['country'];

    this._cdr.detectChanges();
  }

  // get Country Method
  countryLookUp() {
    let countryNameValue = (<FormControl>this.empSubForm.controls['countryName']).value;
    
    if(countryNameValue === undefined || countryNameValue === ''){
      let cnty = !!(<FormControl>this.empSubForm.controls['countryName']).value ? (<FormControl>this.empSubForm.controls['countryName']).value: '';
      
      if(!cnty){
        this.shareService.countryCodeLookupSwitch({countryParam: cnty}).subscribe(countryData => {
        this.countryList =  countryData['country_list'];
        if (this.countryList.length > 0) {
            this.isCountryError = true;
            this.errorMsgShow = false;
        } else {
            this.isCountryError = false;
            this.errorMsgShow = true;
        }
      }, (err) => {
          console.log(err);
          this.countryList = [];
      });
      }
    }
  }

  setFieldValues(values): void {
    (<FormControl>this.empSubForm.controls['countryName']).setValue(values['country_name']);
    (<FormControl>this.empSubForm.controls['countryCode']).setValue(values['country_code']);
    this.latestCountryLnamFromApi = values['country_name'];
    this.countryShortName = values['country_code'];
    
    if(values !== ''){
      this.isCountryValid = true;
      // this.ValidCountryFlag = true;
      this.isCountryError = false;
    }else{
      this.isCountryValid = false;
      // this.ValidCountryFlag = false;
      this.isCountryError = true;
    }
  }

  private processConfig(): void {
    // Get the Config Details from Parent Component - Education
    // console.log(this.configOptions);

    this.PARENT_STAGE_STEP_CONFIG = this.configOptions['PARENT_CONFIG'];
    this.SUB_STEP_ID = EmploymentConfig.subStepIndexMapping['country']['subStep'];
    this.SUB_STEP_INDEX = EmploymentConfig.subStepIndexMapping['country']['subStepIndex'];

    this.setState();
  }

  private setState(): void {
    this.payLoad = {
      name: this.PARENT_STAGE_STEP_CONFIG['STAGE_ID'],
      step: this.PARENT_STAGE_STEP_CONFIG['STEP_ID'],
      stepIndex: this.PARENT_STAGE_STEP_CONFIG['STEP_INDEX'],
      subStep: this.SUB_STEP_ID,
      subStepIndex: this.SUB_STEP_INDEX,
      iteration: this.row,
      status: this.currentStatus,
      dbRecordPresent: this.PARENT_STAGE_STEP_CONFIG['IS_DB_RECORD_PRESENT']
    };

    // console.log('EMP - country - setState');
    // console.log(this.payLoad);

    this._store.dispatch({ type: this.currentStatus, payload: this.payLoad });
  }

  doSwipe(direction: string, data) {
    // alert(direction);
    if (direction === 'swiperight') {
      this.getPrevSwipe(data);
    }
    if (direction === 'swipeleft') {
      if (!!data['countryName'] && !!data['countryCode']) {
        this.country(data);
      }


    }
  }

  getPrevSwipe(obj) {
    this.onCountrySwipe.emit(obj);
  }

  saveExit(obj) {
    // PPA-8745 - Auto-generated via regex - Arun, Supreet, Save n Exit Patch
    var re = this._eventBus.logoutAnnounced({ dummy: "workflow_exit" });
    if (re == undefined) return;
  }

  
  country(obj): void {
    this.isCountryListToShow = false;
    let indexDetail: Object = {};
    indexDetail['row'] = this.row;
    obj['indexDetail'] = indexDetail;
    obj['countryShortName'] = this.countryShortName = obj['countryCode'];
    let country = obj['countryName'];
    let defaultCountryOptions = EmploymentConfig.defaultCountryOptions();

    if (this.latestCountryLnamFromApi && (country.toLocaleLowerCase() === this.latestCountryLnamFromApi.toLowerCase()) && this.countryShortName.length > 0) {
      this.isCountryValid = true;
      this.isCountryError = true;
      // this.ValidCountryFlag = true;
    } else if (country.toLowerCase() === defaultCountryOptions['defaultCountry'].toLowerCase() && this.countryShortName.length > 0) {
      this.isCountryValid = true;
      this.isCountryError = true;
      // this.ValidCountryFlag = true;
    } else {
      this.isCountryValid = false;
      this.isCountryError = false;
      // this.ValidCountryFlag = false;
      this.errMsg = 'Please enter valid Country';
    }


    if (this.currentStatus === 'VISITED') {
      this.currentStatus = 'COMPLETED';
    }

    // Update the state
    this.setState();
    obj['COMPONENT_STATE'] = this.payLoad;
    obj['startTime'] = this.startTime;

    // check if decision changed
    if (this.isCountryChanged) {
      obj['isCountryDecisionChanged'] = true;
    }
    this.isCountryChanged = false;

    this.isCountryExist(obj);
    // this.onCountry.emit(obj);
  }


  closeCountryError() {
    this.isCountryError = !this.isCountryError;
    this.errorMsgShow = !this.errorMsgShow;
  }


  openCountryError() {
    this.isCountryError = false;
    this.errorMsgShow = true;
  }

  private countryValidator(obj) {
    let country = obj['countryName'];
    let defaultCountryOptions = EmploymentConfig.defaultCountryOptions();

    if (this.latestCountryLnamFromApi && (country.toLocaleLowerCase() === this.latestCountryLnamFromApi.toLowerCase()) && obj['countryCode'].length > 0) {
      return true;
    } else if (country.toLowerCase() === defaultCountryOptions['defaultCountry'].toLowerCase() && obj['countryCode'].length > 0) {
      return true;
    } else {
      this.isCountryValid = false;
      this.isCountryError = false;
      // this.ValidCountryFlag = false;
      this.errMsg = 'Please enter valid Country';
      return false;
    }
  }

  ValidateCountry(countryValue) {
    if (countryValue !== undefined && countryValue !== '' && countryValue !== null) {
      this.ValidCountryFlag = true;
      this.errorMsgShow = false;
    } else {
      this.ValidCountryFlag = false;
      this.errorMsgShow = true;
    }
  }


  // Check the country is existing in DB or not
  private isCountryExist(obj) {
    let country = this.empSubForm.controls['countryName'].value;

    this.shareService.lookUpService(country).subscribe(response => {
      let countryData = response;
      this.validate('SUCCESS-RESP', countryData, obj);
    }, error => {
      this.isCountryListToShow = false;
      this.validate('SET-ERROR-FLAG', true, obj);
    });
  }

  // Validate country
  private validate(option, data, obj) {
    let response = {};
    switch (option) {
      case 'ERROR-RESP':
        if (data === '400 REQUEST') {
          response['status'] = false;
        }
        break;
      case 'SUCCESS-RESP':
        if (data !== null && data !== undefined && !this._util.isEmpty(data)) {
          response['status'] = true;
          response['data'] = this.prepareCountry(data);
        } else {
          response['status'] = false;
        }
        break;
      case 'SET-ERROR-FLAG':
        response['status'] = false;
        break;
      default:
    }

    this.saveCountry(obj, response);
  }

  private prepareCountry(data) {

    /**
     * If we found country which is entered in the country list.. It will sent the country.
     * else... Data is coming from backend.. we have to bind into the field and proceed further.
     */
    let enteredCountry: string = this.empSubForm.get('countryName').value;
    if (this.countryList !== undefined && this.countryList.length > 0 && enteredCountry !== undefined) {
      for (let index = 0; index < this.countryList.length; index++) {
        if (enteredCountry.toUpperCase() === this.countryList[index]['country_name'].toUpperCase()) {
          return this.countryList[index];
        }
      }
    } else if (!this._util.isEmpty(data) && this.countryList.length === 0 && enteredCountry) {
      return data;
    }
  }

  // Emit to parent component
  private saveCountry(obj, response) {
    this.isCountryListToShow = false;
    if (response['status']) {
      obj['countryName'] = response['data']['country_name'].toUpperCase();
      obj['countryCode'] = response['data']['country_code'];
      obj['countryShortName'] = response['data']['country_code'];
      (<FormControl>this.empSubForm.controls['countryName']).setValue(obj['countryName']);
      this.onCountry.emit(obj);
    } else {
      this.onCountry.emit(obj);
    }
  }

  private countryServerSideSearch() {
    this.countryTypeahead.pipe(
      distinctUntilChanged(),
      filter(term => {  return (term !== undefined && term !== null) }),
      switchMap(term => this.shareService.countryCodeLookupSwitch({ countryParam: term })
      .pipe(
        catchError(() => of({ 'country_list': [] }))
        )
      )
    ).subscribe(countryData => {
      this.countryList =  countryData['country_list'];
      if (this.countryList.length > 0) {
          this.isCountryError = true;
          this.errorMsgShow = false;
      } else {
        let countryName = this.empSubForm.controls['countryName'].value;
        if (countryName === '') {
          this.isCountryError = false;
          this.errorMsgShow = true;
        }
      }
    }, (err) => {
        console.log(err);
        this.countryList = [];
    });
  }

  trackChange(value) {
    let selectedData = !!value ? value : '';
    let selectedCountry = (<FormControl>this.empSubForm.controls['countryCode']).value;
    if (selectedCountry !== selectedData['country_code']) {
      this.isCountryChanged = true;
    } else {
      this.isCountryChanged = false;
    }
    this.ValidateCountry(selectedData['country_name']);
    this.setFieldValues(selectedData);
  }

  onClearData(){ 
    (<FormControl>this.empSubForm.controls['countryName']).setValue('');
    this.countryLookUp();
  }
}
