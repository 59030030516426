<div class="row" id="letters_section">
  <div class="col-xs-12 col-md-12 col-sm-12">
    <p class="black_text">{{options['title']}}</p>
    <hr>
  </div>
</div>
<div class="addCard m-b-10">
  <div class="row">
    <div class="col-xs-12 col-sm-4 col-md-3 dp-r-0" *ngFor="let data of options.data">
      <div class="clipCard" (click)="openViewNotificationInviteType(data)">
        <img src="../../assets/img/messages1.png" height="30px">
        <p class="text_ellipses" title="{{getTitle(data.notification.type)}}">{{getTitle(data.notification.type)}}</p>
        <p></p>
        <p class="text_ellipses" title="{{data.account.name}}"><span>{{data.account.name}}</span></p>
        <p> <span class="duration">
            {{data.invite_date | timeStampConvert}} </span></p>
      </div>
    </div>
  </div>
</div>
