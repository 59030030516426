<div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
  <div class="group" [formGroup]="group">
    <label class="placeholder_white custom_label" for="country" [class.labelEllipsis]="config.label && config.label.length > 30">
      {{config.label|translate}}
    </label>
    <a data-toggle="popover" data-placement="bottom" title="{{config.label|translate}}"
      *ngIf="config.hint != 'NoLabelRender' && config.label && config.label.length > 30">
      <i class="icon-info labelHelpIcon" (click)="showFullLabelText()"></i>
    </a>
    <select class="form-control dynamicSelectControl" [formControlName]="config.name" [value]="config.value">
      <!-- <option value="">{{ config.placeholder }}</option> -->
      <option *ngFor="let option of config.listOfCpicObject" [value]="option.answerId">
        {{ option.answerLabel }}
      </option>
    </select>
  </div>
</div>