export class ProfileIntlConfig {
    static DATA_KEY_LIST: Array<string> = [
        'MOTHERS_LAST_NAME',
        'FATHERS_LAST_NAME',
        'MAIDEN_NAME',
        'SCHOOL_NAME_IN_LOCAL_LANGUAGE',
        'NAME_IN_LOCAL_LANGUAGE',
        'EMPLOYEE_ID',
        'EMP_NAME_IN_LOCAL_LANGUAGE',
        'DEPARTMENT',
        'MOTHERS_MAIDEN_NAME',
        'BIRTH_COUNTRY',
        'BIRTH_STATE',
        'BIRTH_CITY',
        'POSITION_OFFERED',
        'INDUSTRY',
        'PHONE',
        'DBS_PASSPORT_NATIONALITY',
        'DBS_DRIVER_LICENSE_TYPE',
        'DBS_DRIVER_LICENSE_VALID_FROM',
        'DBS_PASSPORT_ISSUE_DATE',
        'HUKOU_ADDRESS',
        'HUKOU_CITY',
        'HUKOU_REGION'
    ];
}
