import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'unsupported-browsing',
  templateUrl: './unsupported-browsing.component.html'
})

export class UnsupportedBrowsingComponent implements OnInit {
  constructor(private deviceService: DeviceDetectorService) { }
 
   browser:string = this.deviceService.getDeviceInfo().browser;
   browserDetails:string[] = this.deviceService.getDeviceInfo().browser_version.replace('_', '.').split('.');
   browserVersion = this.browserDetails[0] + '.' + this.browserDetails[1];


  ngOnInit() {  
  }
}
