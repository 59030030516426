import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import {
  FormGroup,
  FormControl,
  FormArray,
  FormBuilder,
  Validators,
  ControlValueAccessor
} from '@angular/forms';

@Injectable()
export class WotcSharedService {
  static actionVal: string;
  static hiddenVal: any;
  static direction: string = 'right';
  static optOut: boolean = false;
  public flag: boolean = false;

  static SetActionVal(actionVal: string) {
    WotcSharedService.actionVal = actionVal;
  }

  static GetActionVal(): string {
    return WotcSharedService.actionVal;
  }

  setDirection(direction: string) {
    WotcSharedService.direction = direction;
  }

  getDirection(): string {
    return WotcSharedService.direction;
  }

  public SetHiddenVal(actionVal: string, flag?) {
    if (!!actionVal) {
      if (actionVal.toLowerCase() === 'yes') {
        WotcSharedService.hiddenVal = 1;
      } else if (actionVal.toLowerCase() === 'unsure') {
        WotcSharedService.hiddenVal = 3;
      } else if (actionVal.toLowerCase() === 'continue') {
        WotcSharedService.hiddenVal = 1;
      } else if (actionVal === '95') {
        WotcSharedService.hiddenVal = 'Consent Signed';
      } else if (actionVal.toLowerCase() === 'skip and continue') {
        WotcSharedService.hiddenVal = '';
      } else if (flag) {
        WotcSharedService.hiddenVal = actionVal;
      } else {
        WotcSharedService.hiddenVal = 0;
      }
    }
  }

  public GetHiddenVal(): number {
    return WotcSharedService.hiddenVal;
  }
  public setOptoutvalue(optval: boolean) {
    WotcSharedService.optOut = optval;
  }
  public getOptoutvalue() {
    return WotcSharedService.optOut;
  }
  public componentConfigChanges(config, item, key) {
    if (!!config[key]['inputs'] && config[key]['inputs'].length > 0) {
      config[key]['inputs'].forEach((field) => {
        return field['componentInfo'] = item.name; // linking to component
      });
    }
  }
}

