import { Injectable } from '@angular/core';

@Injectable()
export class arrowService {
    static nextcomponent = [];

    setarrowarray(componenetarray) {
        arrowService.nextcomponent = componenetarray;
    }

    makearrayEmpty() {
        arrowService.nextcomponent = [];
    }

    getnextarrowarray(currentcomponent, currentiteration) {
        for (let i = 0; i < arrowService.nextcomponent.length; i++) {
            if (currentcomponent == arrowService.nextcomponent[i]) {
                let nextcomponenet = arrowService.nextcomponent[i + 1] + '_' + currentiteration;
                return nextcomponenet;
            }
        }
    }

    getprevarrowarray(currentcomponent, currentiteration) {
        for (let i = 0; i < arrowService.nextcomponent.length; i++) {
            if (currentcomponent == arrowService.nextcomponent[i]) {
                let prevcomponenet = arrowService.nextcomponent[i - 1] + '_' + currentiteration;
                
                return prevcomponenet;
            }
        }
    }
}
