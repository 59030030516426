import { Injectable } from "@angular/core";
import Hotjar from "@hotjar/browser";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class HotjarService {
  private _isInitialized = false;

  public init(): void {
    if (
      !this._isInitialized &&
      typeof environment["hotjar_id"] === "number" &&
      environment["hotjar_id"] > 0
    ) {
      Hotjar.init(environment["hotjar_id"], 6);
      this._isInitialized = true;
    }
  }

  public identify({
    profile_id,
    email,
    first_name,
    last_name,
    account_name,
    invite_key,
    account_id,
    package_name,
    package_code,
    package_components,
    invite_type,
    language
  }: {
    profile_id: string;
    email: string;
    first_name?: string;
    last_name?: string;
    account_name?: string;
    invite_key?: string;
    account_id?: string,
    package_name?: string,
    package_code?: string,
    package_components?: string,
    invite_type?: string,
    language?: string
  }): void {
    if (!this._isInitialized) {
      this.init();
    }
    
    Hotjar.identify(invite_key, {
      email,
      first_name,
      last_name,
      account_name,
      environment: environment.name ?? 'pa2',
      invite_key,
      profile_id,
      account_id,
      package_name,
      package_code,
      package_components,
      invite_type,
      language
    });
  }
  
  public logHotjarEvent(name, payload) {
    try { 
      if (this._isInitialized) {
        let eventName = payload['stage_name'] + ' - ' + payload['action_value'] + ' - ' + name;
        Hotjar.event(eventName);
      }
    } catch(e) {
      console.error('Hotjar event tracking failed');
    }
  }
}
