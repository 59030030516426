import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

declare var $: any;
declare var window: any;

@Component({
  selector: 'app-mfa-logout',
  templateUrl: './mfa-logout.component.html',
  styleUrls: ['./mfa-logout.component.css']
})

export class MfaLogoutComponent {
  trackObj: Object;
  constructor(private _router: Router, private route: ActivatedRoute) {
    this.trackObj = {
      stage_name: 'mfa-logout',
      action_value: 'load'
    };
    window.track_event('page loaded', this.trackObj, true, true);

    const inviteKey = this.route.snapshot.params.key;
    this._router.navigate(['/invite/'+inviteKey],{queryParams: {key:inviteKey}});
  }
}