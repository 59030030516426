import { Component, ElementRef, OnChanges, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/fieldconfig.interface';
import { DomSanitizer } from '@angular/platform-browser';
import { InternationalConfig } from 'app/international/international.config';

@Component({
  selector: 'form-checkbox-extended',
  styleUrls: ['form-checkbox-extended.scss'],
  templateUrl: './form-checkbox-extended.html'
})
export class FormCheckboxExtendedComponent implements Field {
  config: FieldConfig;
  group: FormGroup;
  checkboxClass: string;
  sign: any;
  hideElement: boolean = true;
  myImage: any;
  public context: CanvasRenderingContext2D;
  checkBoxExId: any;
  currentSelection: boolean = false;
  @ViewChild('acicSign', { static: true }) acicSign: ElementRef;
  constructor(private _cdr: ChangeDetectorRef,
    public _DomSanitizer: DomSanitizer) { }

  ngOnInit() {
    if (this.config['checkBoxClass'] !== undefined) {
      this.checkboxClass = this.config['checkBoxClass'];
    }

    this.currentSelection = (this.config['value'] === "false") ? false: true;
  }

  ngAfterViewInit(): void {
    if (this.config['acicSign'] !== undefined) {
      this.context = (<HTMLCanvasElement>this.acicSign.nativeElement).getContext('2d');
      let stringifiedSign = JSON.parse(JSON.stringify(this.config['acicSign']));
      let baseString = InternationalConfig.getbase64ImageAppend() + stringifiedSign;
      this.myImage = new Image();
      this.myImage.src = baseString;
      if (this.getConfigCheckBoxValue()) {
        this.hideElement = false;
        setTimeout(() => {
          this.loadImage(this.myImage);
        }, 200);        
      } else {
        this.hideElement = true;
        setTimeout(() => {
          this.loadImage(this.myImage);
        }, 200);
      }
    }

    this._cdr.detectChanges();
  }

  enableSign() {
    if (this.hideElement) {
      this.hideElement = false;
      if (this.myImage !== undefined) {
        this.loadImage(this.myImage);
      }
    } else {
      this.hideElement = true;
    }
  }

  loadImage(myImage: HTMLImageElement) {
    this.context.drawImage(myImage, 0, 0);
  }

  getConfigCheckBoxValue() {
    let checboxValCheck: boolean;
    checboxValCheck = this.config['value'] === 'TRUE' || this.config['value'] === true || this.config['value'] === 'true';
    return checboxValCheck === true ? true : false;
  }
}
