<div class="pad_30" [formGroup]="empSubForm" (onGesture)="doSwipe($event,empSubForm.value)" hammer-gestures>
  <button type="button" class="btn arrowrightctr" attr.data-id="empReportManagerRightArrow_{{row}}"
    [disabled]="!isValidEmail || !isValidPhone || !fieldValidate || !enableNextButton"
    debounceDirective (debounceClick)="managerDetails(empSubForm.value)"> <i class="icon-arrow-right" role="img" [attr.aria-label]="'SCREEN_FARWARD_ARROW' | translate"></i></button>
  <button type="button" class="btn arrowleftctr" attr.data-id="empReportManagerLeftArrow_{{row}}"
    debounceDirective (debounceClick)="getPrevSwipe(empSubForm.value)"><i class="icon-arrow-left" role="img" [attr.aria-label]="'SCREEN_BACKWARD_ARROW' | translate"></i></button>
  <div class="anime-clean contetnheight posrel">
    <div class="row">
      <div class="col-md-12 col-xs-12 col-sm-12">
        <div class="header_txt_white">
          <h2 *ngIf='isCurrentHeaderText && !isCheckSelfEmploymentEnabled'>
            {{'EMPLOYMENT_REPORTING_HEADER_CURRENT'|translate : [userDetails.firstname]}}</h2>
          <h2 *ngIf='!isCurrentHeaderText && !isCheckSelfEmploymentEnabled'>
            {{'EMPLOYMENT_REPORTING_HEADER_PRIOR'|translate : [userDetails.firstname]}}</h2>
          <h2 *ngIf='isCheckSelfEmploymentEnabled'>{{'SELF_EMPLOYMENT_REPORT_MANAGER_HEADER'|translate}}</h2>
          <!-- <h4>{{'EMPLOYMENT_REPORTING_SUBHEADER_1'|translate }}</h4> -->
          <h4><i class="fa fa-info-circle" aria-hidden="true"></i>{{'EMPLOYMENT_REPORTING_SUB_HEADER'|translate}}</h4>
          <h4 class="m-t-15"><i class="icon-work-experience"></i> <span
              class="company">{{empSubForm.controls['companyName'].value}}</span>
          </h4>
        </div>
        <!--  <div class="header_txt_white" *ngIf="empType === 'prior'">
                   <h2>{{'EMPLOYMENT_COMPANY_NAME_HEADER_2'|translate:[userDetails.firstname] }}</h2>
                   <h4>{{'EMPLOYMENT_COMPANY_NAME_SUBHEADER_2'|translate }}</h4>
               </div> -->
      </div>
    </div>
    <div class="row form-element-section">
      <div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
        <div class="group">
          <label class="control-labelss" attr.for="empManagerName_{{row}}">{{'EMPLOYMENT_MANAGER_NAME_LABLE'|translate }}</label>
          <input type="text" class="form-control" attr.id="empManagerName_{{row}}" aria-required="true" formControlName="managerName" maxlength="50"
            (keyup)="validateField()" />
          <!-- <input type="hidden" name="type" formControlName="type"> -->
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
        <div class="group">
          <label class="control-labelss" for="empPositionName_{{row}">{{'EMPLOYMENT_MANAGER_POSITION_LABLE'|translate }}</label>
          <input type="text" class="form-control" id="empPositionName_{{row}" aria-required="true" formControlName="position" maxlength="50" (keyup)="validateField()" />
        </div>
      </div>

      <!-- <div class="col-xs-12 col-sm-6 col-md-4 m-b-20" *ngIf = "options['countryOptions']['countryShortName'] != 'US'">
              <div class="group">
                  <label class="control-labelss" for="">{{'EMPLOYMENT_MANAGER_EMAIL_LABLE'|translate }}</label>
                  <input type="text" class="form-control" formControlName="managerEmail" maxlength="50" />
              </div>
          </div> -->
      <div class="col-xs-12 col-sm-6 col-md-4 m-b-20"
        *ngIf="options['countryOptions']['countryShortName'] != 'US' || isPaDOTDirectEnabled"> 
        <div class="group">
          <label class="control-labelss" attr.for="empManagerEmail_{{row}}"
            *ngIf="!isEmployerEmailOptional">{{'EMPLOYMENT_MANAGER_EMAIL_LABLE'|translate }}</label>
          <label class="control-labelss" attr.for="empManagerEmail_{{row}}"
            *ngIf="isEmployerEmailOptional">{{'EMPLOYMENT_MANAGER_EMAIL_LABLE_OPTIONAL'|translate }}</label>
          <input type="text" class="form-control" attr.id="empManagerEmail_{{row}}" aria-required="true" formControlName="managerEmail" maxlength="50"
            (keyup)="validateEmail(empSubForm.value)" (blur)="validateEmailFormat(empSubForm.value)" />
          <span class="error_info" *ngIf="!isValidEmailShow">
            <i class="fa fa-exclamation error_icon" aria-hidden="true" [class.red_error]="" [class.cursor_hand_none]=""
              debounceDirective (debounceClick)="openEmailError()"></i>
          </span>
          <div *ngIf="!isValidEmailShow">
            <div class="validate custom-error error_new_ui">
              <div class="left_div">
                <ul>
                  <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate }}</li>
                  <li class="message">{{ 'EMPLOYMENT_MANAGER_EMAIL_ERROR' | translate }}</li>
                </ul>
              </div>
              <div class="right_div">
                <div class="close" aria-hidden="true" debounceDirective (debounceClick)="closeEmailError()">
                  <i class="icon-cancel"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
        <div class="group padv-dropdown">
          <label class="control-labelss" attr.for="empManagerCountryCode_{{row}}">{{'EMPLOYMENT_MANAGER_COUNTRY_CODE_LABLE'|translate }}</label>
          <!-- <input type="text" class="form-control" attr.id="empManagerCountryCode_{{row}}" aria-required="true" formControlName="managerCountryCode" maxlength="50"
            (keyup)="keyupCountryCode(empSubForm.value.managerCountryCode,$event)" value="+"
            (focusout)="focusOutFunction(empSubForm.value.managerCountryCode)" /> -->

            <custom-select [items]="countrycodelist" [typeahead]="managerCountryCodeTypeahead" [typeToSearchText]=""
                            bindLabel="code" bindvalue="code" id="empManagerCountryCode_{{row}}"
                            displayValue ="disp_value" (valueChange)="trackChange($event)" [group] ="empSubForm" (clearData)="onClearData()"
                            controlName = "managerCountryCode" [selectedValue]="selectedManagerCountryCode"></custom-select>


          <span class="error_info">
            <i class="fa fa-exclamation error_icon" aria-hidden="true" *ngIf="codeErrorMsg"
              [class.red_error]="!codeErrorMsg" [class.cursor_hand_none]="codeErrorMsg"></i>
            <div class="error_info_popup">{{ 'REFERENCES_COUNTRYCODE_ERROR_SUBHEADER' | translate }}<i
                class="fa fa-times" aria-hidden="true" debounceDirective (debounceClick)="closeCodeError()"></i></div>
          </span>
          <div class="custom-error error_new_ui" *ngIf="codeErrorMsg">
            <div class="left_div">
              <ul>
                <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate }}</li>
                <li class="message">{{ 'REFERENCES_COUNTRYCODE_ERROR_SUBHEADER' | translate }}</li>
              </ul>
            </div>
            <div class="right_div">
              <div class="close" aria-hidden="true" debounceDirective (debounceClick)="closeCodeError()">
                <i class="icon-cancel"></i>
              </div>
            </div>
          </div>
          <span class="highlight"></span>
          <!-- <ul [class.hide]="isNumberList" class="generic-dropdown-list">
            <li *ngFor="let citydata of countrycodelist" debounceDirective (debounceClick)="enterCityInput(citydata)">
              <div>
                <span>{{citydata.disp_value}}</span>
              </div>
            </li>
          </ul> -->
          <span class="bar"></span>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
        <div class="group">
          <label class="control-labelss" attr.for="empManagerPhoneNum_{{row}}"
            *ngIf="!isEmployerPhoneOptional">{{'EMPLOYMENT_MANAGER_PHONE_NUMBER_LABLE'|translate }}</label>
          <label class="control-labelss" attr.for="empManagerPhoneNum_{{row}}"
            *ngIf="isEmployerPhoneOptional">{{'EMPLOYMENT_MANAGER_PHONE_NUMBER_LABLE_OPTIONAL'|translate }}</label>
          <input class="form-control" type='text' attr.id="empManagerPhoneNum_{{row}}" formControlName="phoneNumber" aria-required="true" maxlength="50"
            pattern="^(?=.*[0-9])[- ()0-9]+$" (keyup)="validatePhone(empSubForm.value)" (keyup)="keyupEmpnumber()" />
          <span class="error_info">
            <i class="fa fa-exclamation error_icon" aria-hidden="true" *ngIf="!isPhoneNoValid"
              [class.red_error]="isPhoneNoError" [class.cursor_hand_none]="!isPhoneNoError"
              debounceDirective (debounceClick)="openPhoneNoError()"></i>
            <div class="error_info_popup">{{errMsg  | translate}}<i class="fa fa-times" aria-hidden="true"
                debounceDirective (debounceClick)="closePhoneNoError()"></i></div>
          </span>
          <div class="custom-error error_new_ui" *ngIf="!isPhoneNoValid && !isPhoneNoError">
            <div class="left_div">
              <ul>
                <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate }}</li>
                <li class="message">{{errMsg  | translate}}</li>
              </ul>
            </div>
            <div class="right_div">
              <div class="close" aria-hidden="true" debounceDirective (debounceClick)="closePhoneNoError()">
                <i class="icon-cancel"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row fixedbtn">
      <div class="col-xs-12 col-sm-4 col-md-3 btnpd">
        <button type="button" class="btn button font_extrabold senButton" attr.data-id="empReportManagerNext_{{row}}"
          [class.grow]="false" [disabled]="!isValidEmail || !isValidPhone || !fieldValidate || !enableNextButton"
          debounceDirective (debounceClick)='managerDetails(empSubForm.value)'>{{ 'COMMONS_NEXT' | translate }}</button>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-3 btnpd">
        <button type="button" class="btn button_wbg font_extrabold  grow button_wbgSav"
          attr.data-id="empReportManagerSaveExit_{{row}}"
          [disabled]="!isValidEmail || !isValidPhone || !enableNextButton"
          debounceDirective (debounceClick)='saveExit(empSubForm.value)'>{{ 'COMMONS_SAVE_AND_EXIT' | translate }}</button>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</div>