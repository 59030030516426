import { Pipe, PipeTransform } from '@angular/core'
import { TranslateService } from '../../framework/i18n';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat'
})

export class DateFormatPipe implements PipeTransform {
  //monthNames: any = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  constructor(private translateService: TranslateService) {
  }
  monthNames: any = this.translateService.instant('MONTH_NAMES');
  transform(date: any, args?: any): any {
    if (date) {
      if (date.indexOf("-") > -1) {
        let dte = date.replace(/-/g, '/');
        let dt = new Date(dte);
        // console.log(dt);
        // console.log(dt.getMonth());
        // console.log("pppppppppppppppp",dt.getFullYear());
        let dateformat = this.monthNames[dt.getMonth()] + ' ' + dt.getFullYear();
        return dateformat;
      }

      //let d = new Date(date)
      //return moment(d).format('DD/MM/YYYY')
    }
  }
}
@Pipe({
  name: 'dOBDateFormat'
})

export class DOBDateFormat implements PipeTransform {
  // monthNames: any = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  constructor(private translateService: TranslateService) {
  }
  monthNames: any = this.translateService.instant('MONTH_NAMES');
  transform(date: any, args?: any): any {
    if (date) {
      let dte = date.replace(/-/g, '/');
      let dt = new Date(dte);
      let dateformat = this.monthNames[dt.getMonth()] + ' ' + dt.getDate() + ', ' + dt.getFullYear();
      return dateformat;
    }
  }
}
@Pipe({
  name: 'timeStampConvert'
})

export class TimeStampDateFormat implements PipeTransform {
  constructor(private translateService: TranslateService) {
  }

  /**
   * We have a similar function in dateutil - globalDateTransform, Utilize that function for global date transformation.
   * Enlarge that function as our instead of writting new function for convert date format
   */
  transform(date: any, format?: any, forceToConvertInEnglish?: boolean): any {
    let monthNames: any = this.translateService.instant('MONTH_NAMES');
    let dateObj: any;
    if (forceToConvertInEnglish) {
      monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    }
    if (date) {
      let momentDate = moment.utc(date).toObject();
      dateObj = new Date(momentDate.years, momentDate.months, momentDate.date);
      if (!format) {
        let dateformat = monthNames[dateObj.getMonth()] + ' ' + dateObj.getDate() + ', ' + dateObj.getFullYear();
        return dateformat;
      }

      if (format === 'MMMM YYYY') {
        let dateformat = monthNames[dateObj.getMonth()] + ' ' + dateObj.getFullYear();
        return dateformat;
      }

      if (format === 'dd MMMM YYYY') {
        let dateformat = monthNames[dateObj.getMonth()] + ' ' + dateObj.getDate() + ' ' + dateObj.getFullYear();
        return dateformat;

      }

      if (format === 'MMMM dd, YYYY') {
        let dateformat = monthNames[dateObj.getMonth()] + ' ' + dateObj.getDate() + ', ' + dateObj.getFullYear();
        return dateformat;

      }
      if (format === 'MMMM dd,YYYY') {
        let dateformat = monthNames[dateObj.getMonth()] + ' ' + dateObj.getDate() + ', ' + dateObj.getFullYear();
        return dateformat;
      }
      if (format === 'MMMM DD,YYYY') {
        let dateformat = monthNames[dateObj.getMonth()] + ' ' + dateObj.getDate() + ',' + dateObj.getFullYear();
        return dateformat;
      }
      if (format === 'YYYY-MM-DD') {
        let dateformat = monthNames[dateObj.getMonth()] + ' ' + dateObj.getDate() + ', ' + dateObj.getFullYear();
        return dateformat;
      }
    }
  }
}
@Pipe({
  name: 'yearFormat'
})

export class YearFormat implements PipeTransform {
  transform(date: any): any {
    let dateObj: any;
    if (date) {
      dateObj = new Date(date);
      let dateformat = dateObj.getFullYear();
      return dateformat;
    }
  }
}
