import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { DashboardReportService } from './../dashboard/dashboard.report.service';
import { ProfileConfig } from './profile.config';
import { LocalStorageService } from '../../framework/localstorage.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { TRANSLATION_PROVIDERS, TranslatePipe, TranslateService } from '../../framework/i18n';
import { UtilService } from '../../framework/utils/util.service';
import { TimeStampDateFormat } from '../pipes/dateformat.pipe';
import * as dateUtils from '../../framework/utils/date.utils';
import { WorkflowService } from 'framework/workflow.service';
import { EmploymentConfig } from '../employment/employment.config';
import { ProfessionalLicenseConfig } from '../professional-license/professional-license.config'
import { ReferencesConfig } from '../references/references.config';
import { CriminalConfig } from '../criminal/criminal.config';
import { EducationConfig } from '../education/education.config';
import { GenderConfig } from '../my-data/step/pricr/gender.config';
import { SharedService } from '../shared/services/shared.service';
import { EmploymentService } from 'app/employment';
import { PreAuthService } from '../pre-auth/pre-auth.service';
import { Router } from '@angular/router';

declare var $: any;
let pdfjs = require('pdfjs-dist');

declare global {
  interface Navigator {
    msSaveOrOpenBlob: (blobOrBase64: Blob | string, filename: string) => void
  }
}

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  providers: [DashboardReportService, LocalStorageService, UtilService]
})

export class ViewProfileComponent implements OnInit, AfterViewInit {
  pdfDoc: any;
  pageNum: number = 1;
  pageRendering: boolean = false;
  pageNumPending: any = null;
  scale: number = 1;
  canvas: any;
  ctx: any;
  profileCollection: Object[];
  profileItems;
  personalData;
  residentialDataList: any;
  referencesDataList: any;
  licenseDataList: any;
  requestDataList: any;
  notificationRequestDataList: any;
  criminalDataList: any;  // Criminal Data List -- PPA6211
  documentsList: any;
  isProfileCollection: boolean = false;
  firstName: string = '';
  viewProfileData: Object;
  isViewProfile: boolean = false;
  aliasDataList: any;
  nameDataList: any = [];
  gender: string = null;
  intlDataList: any = [];
  isIframe: boolean = false;
  name: any;
  value: any;
  intlData: Object;
  monthNames: any;
  datesVisibility: boolean = false;
  isAdditionalDataAvailable: boolean = false;
  contactNumber: string;
  isInviteEiaf: boolean = false;

  constructor(private _dashboardService: DashboardReportService,
    private localStorage: LocalStorageService,
    private _ts: TranslateService,
    private _utilService: UtilService,
    private _lservice: LocalStorageService,
    private _tsPipe: TimeStampDateFormat,
    private _workflow: WorkflowService,
    private _shareService: SharedService,
    private _es: EmploymentService,
    private _preAuthService: PreAuthService,
    private _router: Router) {
  }

  ngOnInit() {
    this.profileCollection = [];
    this.profileItems = ProfileConfig.getProfileItems();
    this.personalData = [];
    this.monthNames = this._ts.instant('MONTH_NAMES');
    this.isInviteEiaf = this._shareService.checkInviteIsEIAF(); // TODOEIAF
    if (!this.isInviteEiaf) {
      this.getAliasPrefernce();
    }
    pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.7.570/pdf.worker.min.js';
    this.intlData = {};
    this.getData();
  }

  getAliasPrefernce() {
    let steps = this._workflow.getSteps('main-data-v1');
    let index: number;
    if (steps !== undefined) {
      for (let i = 0; i < steps.length; i++) {
        if (steps[i]['name'] === 'name-step-v1')
          index = i;
      }
    }
    let nameStepConfig = this._workflow.getStepConfig('main-data-v1', index);
    if (nameStepConfig !== undefined) {
      if (nameStepConfig['preferences'] != undefined && nameStepConfig['preferences']['capture_date'] != undefined) {
        this.datesVisibility = nameStepConfig['preferences']['capture_date'];
      }
    }
  }

  ngAfterViewInit() {
    this.canvas = document.getElementById('the-canvas');
    this.ctx = this.canvas.getContext('2d');
  }

  viewProfile(data): void {
    this.isViewProfile = true;
    this.viewProfileData = data;
    if (!!this.isViewProfile && this.viewProfileData) {
      $('#viewProfile').modal('show');
    }
  }

  custom_sort(a, b) {
    if (a.valid_from) {
      a = a.valid_from.replace(/-/g, '/')
    }
    if (b.valid_from) {
      b = b.valid_from.replace(/-/g, '/')
    }

    return new Date(b).getTime() - new Date(a).getTime();
  }

  getData(): void {
    this.profileCollection = [];
    this.personalData = [];
    this.residentialDataList = [];
    this.referencesDataList = [];
    this.licenseDataList = [];
    this.requestDataList = [];
    this.notificationRequestDataList = [];

    // this.profileCollection = [];
    this.documentsList = [];
    this.aliasDataList = [];
    this.nameDataList = [];
    this.intlDataList = [];
    let profileid = this.localStorage.getItem('profile_id');

    this._dashboardService.getFullProfile(profileid).subscribe(response => {
      let profileData: any = response;
      // profileData['contactDetails'] = "+91 8722261122";
      for (let key in profileData) {
        let obj = {};

        if (key === 'first_name' || key === 'middle_name' || key === 'last_name' || key === "spi_data") {
          if (key == 'first_name') {
            this.firstName = profileData[key];
          }
        }
        else {
          obj['key'] = key;
          obj['data'] = profileData[key];
          if (key === "alias-data-list") {
            let len = obj['data'].length;
            for (let i = 0; i < len; i++) {
              if (obj['data'][i].hasOwnProperty('is_maiden_name')) {
                if (obj['data'][i]['is_maiden_name']) {
                  obj['data'][i]['is_maiden_name'] = this._ts.instant('ALIASE_BTN_YES');
                }
                else {
                  obj['data'][i]['is_maiden_name'] = this._ts.instant('ALIASE_BTN_NO');
                }
              }
            }
          }
          if (key == 'address-data-list') {
            obj['data'].sort(this.custom_sort);
            obj['title'] = this._ts.instant('VP_NAME_HEADER_1');

            let dataList = obj['data'];
            if (!this._utilService.isEmpty(dataList)) {
              let permanentAddress = {};
              for (let i = 0; i < dataList.length; i++) {
                let listItem = dataList[i];
                if (listItem['is_permanent_address']) {
                  Object.assign(permanentAddress, listItem);
                  dataList.splice(i, 1);
                  dataList.splice(0, 0, permanentAddress);
                  break;
                }
              }
            }
            this.residentialDataList.push(obj);
          }
          else if (key == 'employment-data-list') {
            if (!!obj['data'] && obj['data'].length > 0) {
              // set type description for employment types
              this._utilService.setTypeValues(obj, EmploymentConfig.EMPLOYMENT_CATEGORIES, 'type', 'type_description');

              let jobTypes = this._utilService.getArrayFromJsonString(this._ts.translate(EmploymentConfig.EMPLOYMENT_JOB_TYPES));
              for (var i = 0; i < obj['data'].length; i++) {
                if (profileData[key] !== undefined
                  && profileData[key][i] !== undefined
                  && profileData[key][i]['type'] !== undefined) {
                  // if (profileData[key][i]['type'] == 'inSchool') {
                  //     obj['data'][i]['type'] = 'In school';
                  // } else if (profileData[key][i]['type'] == 'selfEmployed') {
                  //     obj['data'][i]['type'] = 'Self-employed';
                  // } else if (profileData[key][i]['type'] == 'unemployed') {
                  //     obj['data'][i]['type'] = 'Unemployed';
                  // } else if (profileData[key][i]['type'] == 'internship') {
                  //     obj['data'][i]['type'] = 'Internship';
                  // } else {
                  //     obj['data'][i]['type'] = profileData[key][i]['type'];
                  // }

                  if (profileData[key][i]['type'].toUpperCase() == 'SELF-EMPLOYED') {
                    obj['data'][i]['accountant_name'] = profileData[key][i]['manager_name'];
                    obj['data'][i]['accountant_position'] = profileData[key][i]['manager_position'];
                    obj['data'][i]['accountant_phone_number'] = profileData[key][i]['manager_phone_number'];
                    obj['data'][i]['accountant_country_code'] = profileData[key][i]['manager_country_code'];
                    obj['data'][i]['accountant_email'] = profileData[key][i]['manager_email'];
                  }
                }

                this.setTypeDescription(obj, i, 'job_type', jobTypes);
              }
              this._es.useFromAndToDate(obj['data']);
            }
            if (!!obj['data'] && obj['data'].length === 1) {
              obj['data'] = (!!obj['data'][0]["type"]) ? obj['data'] : [];
            } else {
              obj['data'] = obj['data'];
            }
            // obj['title'] = 'My Employment';
            obj['title'] = this._ts.instant('VP_NAME_HEADER_7');

          }
          else if (key == 'education-data-list') {
            // set type description for education types
            this._utilService.setTypeValues(obj, EducationConfig.EDUCATION_TYPES, 'type', 'type_description');
            for (let index = 0; index < obj['data'].length; index++) {
              /**
               * Set degree type description for education types
               * Passing optional parameter - 'index', If we pass 'index'. We don't need to loop again,
               * instead we can bind the value directly to the parent obj
               * Below scenario we are passing only in (object). so it is easy to bind.
               */
              this._utilService.setTypeValues(obj, EducationConfig.getQualificationType(obj['data'][index]['type']), 'degree-type', 'degree_type_description', index);
            }
            // obj['title'] = 'My Education';
            obj['title'] = this._ts.instant('VP_NAME_HEADER_2');
          }
          else if (key == 'mvr-data-list') {
            // obj['title'] = 'My Driver License';
            obj['title'] = this._ts.instant('VP_NAME_HEADER_8');
          }
          else if (key == 'criminal-data-list') {  // PPA6211
            let offenceTypes = CriminalConfig.getOffenseType();
            for (let i = 0; i < obj['data'].length; i++) {
              obj['data'][i]['pkge'] = {};
              obj['data'][i]['pkge'] = 'standardCriminal';
              this.setTypeDescription(obj, i, 'type', offenceTypes);
            }
            // obj['title'] = 'Criminal';
            obj['title'] = this._ts.instant('VP_NAME_HEADER_9');
          }
          else if (key == 'disclosure-data') {
            if (obj['data'].length > 0) {
              let disclosureConfig = this._workflow.getStepConfig('criminal-data-v1', 1);
              let criminalIndex = this._utilService.isKeyExistInArray(this.profileCollection, 'criminal-data-list');
              if (disclosureConfig['show']) {
                // show self-disclosure
                this.prepareSelfdisclosureData(obj, obj['data'][0], criminalIndex);
              } else if (!disclosureConfig['show'] && !!disclosureConfig['forms']
                && disclosureConfig['forms'].length > 0) {
                // show self-disclosure Extension
                this.prepareSelfDisclosureExtData(obj, obj['data'][0], criminalIndex);
              }
            }
          }
          else if (key == 'request') {
            // obj['title'] = 'My Requests';
            obj['title'] = this._ts.instant('VP_NAME_HEADER_3');
            let notification_data = [];
            let other_req_data = [];
            
            obj['data'].forEach(element => {
              if (element['type'] == 'NOTIFICATION') {
                notification_data.push(element);
              } else {
                other_req_data.push(element);
              }
            });
            obj['data'] = other_req_data
            this.requestDataList.push(obj);
            if (notification_data && notification_data.length > 0) {
              let n_obj = {};
              n_obj['title'] =  this._ts.instant('VP_NAME_HEADER_15');
              n_obj['data'] = notification_data;
              n_obj['key'] = 'notifications'
              this.notificationRequestDataList.push(n_obj);
              if (!this._utilService.isEmpty(n_obj)) {
                this.profileCollection.push(n_obj);
              }
            }
          }
          else if (key == 'document') {
            // obj['title'] = 'My Documents';
            obj['title'] = this._ts.instant('VP_NAME_HEADER_4');
            this.documentsList.push(obj);
          }
          else if (key == 'spi-data') {
            // obj['title'] = 'My Data';
            obj['title'] = this._ts.instant('VP_NAME_HEADER_10');
            this.personalData.push(obj);
          }
          else if (key == 'licence-data-list') {
            // obj['title'] = 'My Credential';
            obj['title'] = this._ts.instant('VP_NAME_HEADER_11');

            if (!this._utilService.isEmpty(obj) && !this._utilService.isEmpty(obj['data']) && !this._utilService.isEmpty(obj['data'][0]) && !this._utilService.isEmpty(obj['data'][0]['issue_date'])) {
              obj['data'][0]['issue_date'] = this._tsPipe.transform(obj['data'][0]['issue_date'], 'MMMM dd, YYYY');
              try {
                var date = new Date(dateUtils.formatDOBLabeltoYyyyMmDd(obj['data'][0]['issue_date'], this.monthNames));
                let getMonth = dateUtils.get2D(date.getMonth() + 1);
                let getDate = dateUtils.get2D(date.getDate())
                obj['data'][0]['issue_date'] = date.getFullYear() + '-' + getMonth + '-' + getDate;
              } catch (error) {
                console.error('Error during date parse on license : ' + error);
              }
            }
            // set type description for professional license types
            this._utilService.setTypeValues(obj, ProfessionalLicenseConfig.PROFESSIONAL_LICENSE_TYPE, 'type', 'type_description');

            this.licenseDataList.push(obj);
          }
          else if (key == 'reference-data-list') {

            let referenceTypes = ReferencesConfig.getReferencesType();
            let referenceTitles = ReferencesConfig.getReferencesTitle();

            // Handling Multi-lingual display of TITLE & TYPE
            for (let i = 0; i < obj['data'].length; i++) {
              // Type Description will be language specific for TYPE
              for (let k = 0; k < referenceTypes.length; k++) {
                if (referenceTypes[k]['id'] === obj['data'][i]['type']) {
                  obj['data'][i]['type_description'] = this._ts.instant(referenceTypes[k]['name']);
                }
              }

              // Title Description will be language specific for TITLE
              for (let k = 0; k < referenceTitles.length && obj['data'][i]['title'] !== undefined; k++) {
                if (referenceTitles[k]['id'] === obj['data'][i]['title']) {
                  obj['data'][i]['title_description'] = this._ts.instant(referenceTitles[k]['name']);
                }
              }
            }
            // obj['title'] = 'My References';
            obj['title'] = this._ts.instant('VP_NAME_HEADER_12');
            let refTypes = ReferencesConfig.getReferencesType();
            for (var i = 0; i < obj['data'].length; i++) {
              this.setTypeDescription(obj, i, 'type', refTypes);
            }
            this.referencesDataList.push(obj);
          }
          else if (key == 'alias-data-list') {
            // obj['title'] = 'My Alias';
            let aliasData: Array<Object> = profileData['alias-data-list'];

            if (this.datesVisibility) {
              aliasData.forEach(element => {
                if (element['start-date'] && element['end-date']) {
                  let el: Object = this._utilService.addAliasStartEndDates(element);
                  element['start-date'] = el['fstart'];
                  element['end-date'] = el['fend'];
                }
              });
            }

            obj['title'] = this._ts.instant('VP_NAME_HEADER_13');
            obj['visibility'] = this.datesVisibility;
            // obj['title'] = 'My Alias';
            this.aliasDataList.push(obj);
          }
          else if (key == 'name-data') {
            // obj['title'] = 'Name';
            obj['title'] = this._ts.instant('VP_NAME_HEADER_14');
            this.nameDataList.push(obj);
            //console.log(this.nameDataList, 'this.nameDataList');
          } else if (key === 'gender') {
            for (let option of GenderConfig.genderTypes) {
              if (option['id'] === profileData[key]) {
                this.gender = this._ts.instant(option['name']);
              }
            }
          } else if (key === 'contact-number') {
            this.contactNumber = profileData[key];
          } else if (key === 'intl-addl-field-list') {
            this.intlDataList = [];
            this.intlData = {};
            // if(!this.isAdditionalDataAvailable) {
            obj['title'] = this._ts.instant('VP_NAME_HEADER_6');
            let len = profileData[key].length;

            for (let i = 0; i < len; i++) {
              this.name = profileData[key][i]['name'];
              this.intlData[this.name] = profileData[key][i]['value'];
            }

            profileData[key] = this.intlData;

            if (len > 0) {
              this.intlDataList.push(this.intlData)
              obj['data'] = this.intlDataList;
            }

            // this.isAdditionalDataAvailable = true;
            // }

          } else if (key === 'forms') {
            this.intlDataList = [];
            this.intlData = {};
            obj['title'] = this._ts.instant('VP_NAME_HEADER_6');
            let formLen = profileData[key].length > 0 ? profileData[key].length : 0;

            if (formLen === 1 && profileData[key][0]['name'].indexOf('AFP') > -1)
              obj['title'] = profileData[key][0]['name'];

            for (let j = 0; j < formLen; j++) {
              let profileForm = profileData[key][j];

              let len = (profileForm['candidate_form_fields_list'] !== undefined) ? profileForm['candidate_form_fields_list'].length : 0;

              for (let i = 0; i < len; i++) {
                this.name = profileForm['candidate_form_fields_list'][i]['id'];
                // this.intlData[this.name]=  profileData[key][0]['candidate_form_fields_list'][i]['value'] ;

                this.intlData[this.name] = {
                  'label': profileForm['candidate_form_fields_list'][i]['label'] !== undefined ? profileForm['candidate_form_fields_list'][i]['label'] : '',
                  'value': profileForm['candidate_form_fields_list'][i]['value'] !== undefined ? profileForm['candidate_form_fields_list'][i]['value'] : ''
                };
              }

              if (len > 0) {
                this.intlDataList.push(this.intlData);
                obj['data'] = this.intlDataList;
              }
          }
            profileData[key] = this.intlData;
          }

          if (!this._utilService.isEmpty(obj)) {
            this.profileCollection.push(obj);
          }
        }
      }
      this.isProfileCollection = true;
    }, error => {
    });
  }

  prepareSelfdisclosureData(obj, data, index) {
    if (index !== -1) {
      obj['data'][0]['pkge'] = {};
      obj['data'][0]['pkge'] = 'standardDisclosure';
      obj['data'][0]['type'] = this._ts.instant('VP_DISCLOSURE_TITLE');
      this.profileCollection[index]['data'].push(data);
      obj = {};
    }
  }

  prepareSelfDisclosureExtData(obj, data, index) {
    let extObj = {};
    let formList = [];
    let formData = obj['data'][0]['form']['candidate_form_fields_list'];
    if (index !== -1) {
      extObj['pkge'] = 'standardDisclosureExtension';
      extObj['type'] = this._ts.instant('VP_DISCLOSURE_TITLE');

      for (let k = 0; k < formData.length; k++) {
        if (formData[k]['value'] !== undefined) {
          formList.push({
            'label': formData[k]['label'],
            'value': formData[k]['value']
          });
        }
      }
      extObj['disclosure-data'] = formList;
      this.profileCollection[index]['data'].push(extObj);
      obj = {};
    }
  }

  displayPdf(obj: Object) {
    $('#plsWaitAMoment').modal('show');
    let pdfurl = obj['data'];
    this.pageNum = 1;
    let $iframe = $('#profilePdfView');
    let that = this;

    if (obj['component'] === 'eauth') {
      this.isIframe = false;
      const loadingTask = pdfjs.getDocument({
        url: pdfurl,
        httpHeaders: { Authorization: 'Bearer ' + that.localStorage.getItem('access_token') }
      });
      
      loadingTask.promise.then(function (doc) {
        that.pdfDoc = doc;
        document.getElementById('page_count').textContent = that.pdfDoc.numPages;
        // Initial/first page rendering
        that.renderPage(that.pageNum);
        $('#plsWaitAMoment').modal('hide');
        $('#pdfmodal').modal('show');
      }, function (reason) {
        // PDF loading error
        that.isIframe = true;
        $iframe.ready(function () {
          $iframe.contents().find('body').append(obj['html']);
          $('#plsWaitAMoment').modal('hide');
          $('#pdfmodal').modal('show');
        });
      });
    } else if (obj['component'] === 'others') {
      this.isIframe = false;
      const loadingTask = pdfjs.getDocument({
        url: pdfurl,
        httpHeaders: { Authorization: 'Bearer ' + that.localStorage.getItem('access_token') }
      });
      
      loadingTask.promise.then(function (doc) {
        that.pdfDoc = doc;
        document.getElementById('page_count').textContent = that.pdfDoc.numPages;
        // Initial/first page rendering
        that.renderPage(that.pageNum);
        $('#plsWaitAMoment').modal('hide');
        $('#pdfmodal').modal('show');
      }, function (reason) {
        // PDF loading error
        console.error(reason);
        $('#plsWaitAMoment').modal('hide');
        $('#noPdfFoundModal').modal('show');
      });
    } else {
      $('#plsWaitAMoment').modal('hide');
      $('#noPdfFoundModal').modal('show');
    }
  }

  renderPage(num) {
    this.pageRendering = true;
    let that = this;
    // Using promise to fetch the page
    this.pdfDoc.getPage(num).then(function (page) {
      var viewport = page.getViewport({scale: that.scale});

      that.canvas.height = 792; // viewport.height;
      that.canvas.width = 612; // viewport.width;

      that.canvas = document.getElementById('the-canvas');
      that.ctx = that.canvas.getContext('2d');

      // Render PDF page into canvas context
      var renderContext = {
        canvasContext: that.ctx,
        viewport: viewport
      };
      var renderTask = page.render(renderContext);

      // Wait for rendering to finish
      renderTask.promise.then(function () {
        that.pageRendering = false;
        if (that.pageNumPending !== null) {
          // New page rendering is pending
          that.renderPage(that.pageNumPending);
          that.pageNumPending = null;
        }
      });
    });

    // Update page counters
    document.getElementById('page_num').textContent = this.pageNum.toString();
  }

  queueRenderPage(num) {
    if (this.pageRendering) {
      this.pageNumPending = num;
    } else {
      this.renderPage(num);
    }
  }

  onPrevPage() {
    if (this.pageNum <= 1) {
      return;
    }
    this.pageNum--;
    this.queueRenderPage(this.pageNum);
  }

  onNextPage() {
    if (this.pageNum >= this.pdfDoc.numPages) {
      return;
    }
    this.pageNum++;
    this.queueRenderPage(this.pageNum);
  }

  modalClose() {
    $('#profilePdfView').attr('src', 'about:blank');
    $('#pdfmodal').modal('hide');
  }

  noPdfModalClose() {
    $('#noPdfFoundModal').modal('hide');
  }

  // On download click, download the file
  downloadAction(obj) {
    // debugger;
    // Download document
    $('#wait_for_pdf_modal').modal('show');
    let filename;
    filename = obj.file_name.toString();
    if (filename.indexOf('.pdf') < 0)
      filename = filename + '.pdf';
    this._dashboardService.downloadFile(obj.id).subscribe(blob => {
      $('#wait_for_pdf_modal').modal('hide');
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        importedSaveAs(blob, filename);
      }
    }, error => {
      $('#wait_for_pdf_modal').modal('hide');
    });
  }

  setTypeDescription(obj: any, index: number, key: string, jobTypes: any) {
    if (!!obj['data'][index][key]) {
      if (!!jobTypes) {
        for (let jobType of jobTypes) {
          if (jobType['id'] === obj['data'][index][key]) {
            obj['data'][index][key] = jobType['name'];
          }
        }
      }
    }
  }

  onViewNotificationInviteType(obj: any) {
    console.log("View Notificaiton - " + obj);
    $('#wait_for_pdf_modal').modal('show');
    let inviteKey = obj['key'];
    this._preAuthService
      .validateToken(inviteKey)
      .subscribe(inviteResponse => {
        this.localStorage.setItem('notification_mode', 'true');
        this._router.navigate(['/dashboard']);
        $('#wait_for_pdf_modal').modal('hide');
        return;
      },
        async error => {
          console.error(error);
          $('#wait_for_pdf_modal').modal('hide');
          $('#errorModal').modal('show');
        });
  }

  errorModalClose(){
    $('#errorModal').modal('hide');
  }
}
