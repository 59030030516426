import { Component, ViewEncapsulation, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators, ControlValueAccessor } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Subscription } from 'rxjs';
import { EventBusService } from '../../../../framework/eventbus.service';
import { DynamicFormService } from './dynamic-form.service';
import { FieldConfig, SlideConfig } from '../../models/fieldconfig.interface';
import { ServerClientValidatorKeyMap } from '../validator-config';
import { WotcSharedService } from '../../shared/shared.service';
import { TranslateService } from '../../../i18n';
import { LocalStorageService } from 'framework/localstorage.service';

declare var $:any;

@Component({
  exportAs: 'dynamicForm',
  selector: 'dynamic-wotc',
  styleUrls: ['./dynamic-form.component.scss'],
  templateUrl: './dynamic-form.template.html',
  encapsulation: ViewEncapsulation.None
})

export class DynamicFormComponent implements OnChanges, OnInit {
  @Input() config: FieldConfig[] = [];
  @Input() decisionFlag: boolean = false;
  @Input() firstName: string;
  @Input() decideButtonclass: any;
  @Input() applyWotcclass: boolean;
  @Output() submit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSetCardData: EventEmitter<any> = new EventEmitter();
  @Output() onWotcSwipe: EventEmitter<any> = new EventEmitter();
  @Output() onWotcDocdownload: EventEmitter<any> = new EventEmitter();
  @Output() instructionEvent: EventEmitter<any> = new EventEmitter();
  @Output() onInstrnsFileDownload: EventEmitter<any> = new EventEmitter();

  private _data = new BehaviorSubject<any>([]);
  @Input()
  set formDataChanged(value) {
    this._data.next(value);
  };

  get formDataChanged() {
    return this._data.getValue();
  }

  form: FormGroup;
  public currentSlide: string;
  public currentSlideWithDirection: string;
  public isDebug: boolean;
  public subscription: Subscription;
  public decisionWotcval: String;
  public isValid: boolean = false;
  public btnDefaultclass: any;
  public instructionsAvailable: boolean = false;
  public isMvrPsp: boolean = false;
  isDCConsentForm: boolean = false;
  isDataCorrectionInvite: boolean = false;

  constructor(private fb: FormBuilder,
    private _ds: DynamicFormService,
    private _eventBus: EventBusService,
    private _dynamicform: DynamicFormService,
    private _wSc: WotcSharedService,
    private localStorage: LocalStorageService) {
    this.btnDefaultclass = 'btn' + ' ' + 'button' + ' ' + 'font_extrabold' + ' ' + 'sendButton';
  }

  get validatorConfig() {
    return ServerClientValidatorKeyMap.getKeyMap();
  }

  get controls() {
    let component = this.config[0]['name'];
    this.isMvrPsp = component === 'MVR PSP';
    if (!!this.config[0] && !!this.config[0]['instructions']) {
      // 2 instrn icons shown in MVR - dditional Detaiils, remove icon from dynamic when partof name matches MVR
      if (!!this.config[0]['name'] && (this.config[0]['name'].indexOf('MVR Standard Extension') > -1 || this.config[0]['name'].indexOf('Employment Standard Extension') > -1 || this.config[0]['name'].indexOf('Education Standard Extension') > -1)) {
        this.instructionsAvailable = false;
      } else {
        this.instructionsAvailable = true;
      }

      if (this.config[0]['instructionFlag']) {
        this.setCustomInstruction();
      }
    }
    if (this._wSc.getDirection() === 'left') {
      this.getLeftSlider(component);
    } else {
      this.getSlider(component);
    }

    // this.getSlider(component);
    let fieldsConfig: any[] = [];

    this.config.filter(obj => {
      obj['inputs'].forEach((item) => {
        fieldsConfig.push(item);
      });
    });

    let cont = fieldsConfig.filter(({ type }) => type);

    return cont;
  }

  get changes() { return this.form.valueChanges; }
  get valid() { return this.form.valid; }
  get value() { return this.form.value; }

  ngOnInit() {
    this.isDebug = this._ds.isDebug;
    let isCreate = true;

    this.isDataCorrectionInvite = !!this.localStorage.getItem('subType') && this.localStorage.getItem('subType') === 'DC' ? true : false;

    this.form = this.createUpdateGroup(isCreate);
    this.monitorComponentChanges();
    this.subscription = this._dynamicform.wotcdecision$
      .subscribe(decisionWotcval => this.decisionWotcval = decisionWotcval);

    this._eventBus.dynamicFormCheckBoxGenericAnnounced$.subscribe(item => {
      this.dynamicFormCheckboxGenericClicked(item);
    });
  }

  ngOnChanges() {
    this.isDCConsentForm = false;
    this.isDataCorrectionConsentForm();
    this.instructionsAvailable = false;
    // console.log(this.formDataChanged);
    if (this.formDataChanged && this.formDataChanged == true) {
      this.form = this.createUpdateGroup(true);
    }
    if (this.form && this.config && this.config.length > 0) {
      const controls: any = Object.keys(this.form.controls);
      let updateControls = this.controls;
      const configControls: any = updateControls.map((item) => item.name);

      controls
        .filter((control) => !((configControls.indexOf(control) >= 0) ? true : false))     // .filter((control) => !configControls.includes(control))
        .forEach((control) => this.form.removeControl(control));

      configControls
        .filter((control) => !((controls.indexOf(control) >= 0) ? true : false))           // .filter((control) => !controls.includes(control))
        .forEach((name) => {
          const config = this.config[0]['inputs'].find((control) => control.name === name);
          if (config !== undefined) {
            this.form.addControl(name, this.createControl(config));
          }
        });

      //Adding controls for the control keys #1427
      updateControls.forEach((control => {
        if (!!control.keys) {
          for (let i = 0; i < control.keys.length; i++) {
            this.form.addControl(control.keys[i], this.createControl(control));
          }
        }
      }));
    }
  }

  isDataCorrectionConsentForm() {
    if (!!this.config && this.config.length > 0 && !!this.config[0] 
      && !!this.config[0]['inputs'] && this.config[0]['inputs'].length > 0 ) {
      const items = this.config[0]['inputs'].filter(item => !!item['attribute_type'] && 'CONSENT' === item['attribute_type'].toUpperCase());
      if (!!items && items.length > 0) {
        this.isDCConsentForm = true;
      }
    }
  }

  SetFlagVal(wotcFlag: string) {
    this._dynamicform.SetFlagVal(wotcFlag);
    this.onSetCardData.emit(wotcFlag);
  }

  wotcDocdownload(event) {
    this.onWotcDocdownload.emit(this.value);
  }

  createUpdateGroup(isCreate, updateControls?: any[]) {
    const group = (!!isCreate) ? this.fb.group({}) : <FormGroup>this.form;
    let controls = (!!isCreate) ? this.controls : updateControls;

    controls.forEach((control => {
      //#116313: Need to make this generic, it was causing issue with YES/NO selections flow without this if condition.
      if (control.name === 'DBS_ISSUE_COUNTRY' || control.name === 'DBS_ISSUE_DATE') {
        group.removeControl(control.name);
      }
      group.addControl(control.name, this.createControl(control));
      if (!!control.keys) {
        for (let i = 0; i < control.keys.length; i++) {
          group.addControl(control.keys[i], this.createControl(control));
        }
      }
    }));

    return group;
  }

  createControl(config: FieldConfig) {
    const { disabled, validation, value } = config;
    return this.fb.control({ disabled, value }, validation);
  }

  handleSubmit(val: Object, event: Event, actionVal: string) {
    event.preventDefault();
    event.stopPropagation();
    WotcSharedService.SetActionVal(actionVal);
    this.submit.emit(val);
  }

  handleConsent(event: Event, actionVal: string) {
    event.preventDefault();
    event.stopPropagation();
    let val = {};
    if ( actionVal === 'AGREE') {
      val['CONSENT'] = 'AGREE';
    } else {
      val['CONSENT'] = 'DECLINED';
    }

    this.handleSubmit(val, event, actionVal);
  }

  setDisabled(name: string, disable: boolean) {
    if (this.form.controls[name]) {
      const method = disable ? 'disable' : 'enable';
      this.form.controls[name][method]();
      return;
    }

    this.config = this.config.map((item) => {
      if (item.name === name) {
        item.disabled = disable;
      }
      return item;
    });
  }

  setValue(name: string, value: any) {
    this.form.controls[name].setValue(value, { emitEvent: true });
  }

  public validationCheck(fields): boolean {
    let fieldValidArr = [];
    fields.forEach((field) => {
      const control = field['name'];
      fieldValidArr.push(this.form['controls'][control]['valid']);

    });
    let validLength = fieldValidArr.filter(function (value) { return value === true; }).length;
    let res = (validLength === fields.length) ? false : true;
    return res;
  }

  public getSlider(component) {
    let { currentSlide, currentSlideWithDirection }: SlideConfig = this._ds.setSlider(component, 'right');
    this.currentSlide = currentSlide;
    this.currentSlideWithDirection = currentSlideWithDirection;
  }

  public getLeftSlider(component) {
    let { currentSlide, currentSlideWithDirection }: SlideConfig = this._ds.setSlider(component, 'left');
    this.currentSlide = currentSlide;
    this.currentSlideWithDirection = currentSlideWithDirection;
  }

  public goNext(val: Object, event: Event, actionVal: string) {
    this.handleSubmit(val, event, 'NEXT');
  }

  public getPrevSwipe(event) {
    if (this.isDataCorrectionInvite) {
      this.handleSubmit(this.form.value, event, 'BACK');
    } else {
      this.onWotcSwipe.emit();
    }
  }

  public bindDyanamicComponent(configItem) {
    let isCreate = false;
    this.createUpdateGroup(isCreate, configItem['inputs']);
    let currentComponent = configItem['name'];

    if (this._wSc.getDirection() === 'left') {
      this.getLeftSlider(currentComponent);
    } else {
      this.getSlider(currentComponent);
    }
  }

  public monitorComponentChanges() {
    this._eventBus.dynamicFormConfigAnnounced$.subscribe(config => {
      this.bindDyanamicComponent(config);
    });
  }

  public setCustomInstruction() {
    this.instructionEvent.emit(this.config[0]);
  }

  onInstructionsFileDownload() {
    this.onInstrnsFileDownload.emit()
  }

  dynamicFormCheckboxGenericClicked (item) {
    var ctrlValue = item['selectedObj']['value'];

    if (ctrlValue)
      $('#esignature_control').removeClass("hide");
    else
      $('#esignature_control').addClass("hide");
  }
}
