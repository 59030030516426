import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthyComponent } from './authy.component';

const routes: Routes = [
  {
    path: 'authentication',
    children: [
      { path: '', component: AuthyComponent }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthyRoutingModule { }
