<div *ngIf="viewProfileData['pkge'] === 'standardCriminal'">
  <ul>
    <li *ngIf="!!viewProfileData['type']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['type'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['type'] | translate}}</div>
      </div>
    </li>
    <li *ngIf="!!viewProfileData['category']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['category'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['category'] | translate}}</div>
      </div>
    </li>
    <li *ngIf="!!viewProfileData['city']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['city'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['city']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['state_name']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['state_name'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['state_name']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['country_name']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['country_name'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['country_name']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['county']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['county'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['county']}}</div>
      </div>
    </li>

  </ul>
</div>

<div *ngIf="viewProfileData['pkge'] === 'standardDisclosure'">
  <ul>
    <li>
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ viewProfileData['enforcement_question']}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options" *ngIf="viewProfileData['is_enforcement']">
          {{profileKey['yes'] | translate}}</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options" *ngIf="!viewProfileData['is_enforcement']">
          {{profileKey['no'] | translate}}</div>
      </div>
    </li>
    <li *ngIf="!!viewProfileData['enforcement_detail']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['description'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['enforcement_detail']}}</div>
      </div>
    </li>
    <li>
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{viewProfileData['convicted_question']}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options" *ngIf="viewProfileData['is_convicted']">
          {{profileKey['yes'] | translate}}</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options" *ngIf="!viewProfileData['is_convicted']">
          {{profileKey['no'] | translate}}</div>
      </div>
    </li>
    <li *ngIf="!!viewProfileData['convicted_detail']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['description'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['convicted_detail']}}</div>
      </div>
    </li>
  </ul>
</div>

<ul *ngIf="viewProfileData['pkge'] === 'standardDisclosureExtension'">
  <li *ngFor="let profile of viewProfileData['disclosure-data']">
    <div class="row">
      <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profile['label'] }} </div>
      <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
      <div class="col-md-6 col-xs-5 col-sm-6 options"> {{ profile['value'] }}</div>
    </div>
  </li>
</ul>
