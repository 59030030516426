<ng-container *ngIf="control">
<span class="error_info" *ngIf="isShowError()">
                <i class="fa fa-exclamation error_icon" aria-hidden="true"
                   [class.red_error]="true"
                   [class.cursor_hand_none]="false"
                   (click)="toggleErrorTooltip()"></i>
            </span>
  <div *ngIf="isShowTooltip()" class="custom-error error_new_ui">
    <div class="left_div">
      <ul>
        <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate }}</li>

        <li *ngIf="messageTranslationKey" class="message" [innerHTML]=" messageTranslationKey | translate  "></li>

        <!-- pattern key -->
        <li class="message"
            *ngIf="!messageTranslationKey && control?.errors && control?.errors?.pattern && patternKey">
          {{'COMMONS_PATTERN_' + patternKey |translate}}
        </li>

        <li class="message" *ngIf="composeGeneric ==='RULE' && control?.errors && control?.errors['translationErrorKey']">
          {{ control?.errors['translationErrorKey'] | translate}}
        </li>

        <li class="message" *ngIf="composeGeneric ==='RULE' && control?.errors && !control?.errors['translationErrorKey']">
          {{control?.errors |json}}
        </li>


        <li class="message" *ngIf="composeGeneric ==='ADVANCED' && !messageTranslationKey && control?.errors">
          <!-- switch case for control.errors keys -->
          {{'FIELD_'+(fieldNameKey ? fieldNameKey : controlName) | uppercase | translate}} {{'fieldError.required' | translate}}
        </li>


        <li class="message" *ngIf="composeGeneric ==='SIMPLE' && !messageTranslationKey && control?.errors">
          <!-- generated simple require valid message for form control name for all errors -->
          {{'PLEASE_ENTER_VALID' | translate}} {{'FIELD_'+(fieldNameKey ? fieldNameKey : controlName) | uppercase | translate}}.
        </li>
        <li class="message" *ngIf="!composeGeneric && debug && control?.errors">
          {{control?.errors |json}}
        </li>

      </ul>
    </div>
    <div class="right_div">
      <div class="close" aria-hidden="true"
           (click)="toggleErrorTooltip()">
        <i class="icon-cancel"></i>
      </div>
    </div>
  </div>
</ng-container>
