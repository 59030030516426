import { Component, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/fieldconfig.interface';
import { SharedService } from '../../../../app/shared/services/shared.service';
import { LocalStorageService } from '../../../localstorage.service';
import { AppConfig } from 'app/app.config';
import { SharedConfig } from './../../../../app/shared/shared.config';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'form-esignature',
  styleUrls: ['form-esignature.component.scss'],
  templateUrl: './form-esignature.component.html'
})

export class FormESignatureComponent implements Field {
  config: FieldConfig;
  group: FormGroup;
  imgSrc: any;

  constructor(private _sharedService: SharedService,
    private localStorage: LocalStorageService,
    private sanitizer: DomSanitizer) {
    this.imgSrc = '';
    this.prepareSignature();
  }

  private prepareSignature() {
    let wfId: any = this.localStorage.getItem("workflow_instance_id");
    this._sharedService.getSignatureByWorkflowId(wfId).subscribe(response => {
      this.imgSrc = this.sanitizer.bypassSecurityTrustResourceUrl(SharedConfig.getbase64ImageAppend() + response);
    }, error => {
      console.error("Image load error!!!");
    });
  }

}
