import { Component, Inject, OnInit } from '@angular/core';
import { LocalStorageService } from 'framework/localstorage.service';
import { Router } from '@angular/router';
import { AuthService } from '../login';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environments/environment';
import { EventBusService } from 'framework/eventbus.service';
import { TranslateService } from 'framework/i18n';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedService } from 'app/shared/services/shared.service';

declare var $: any;
declare var window: any;

@Component({
  selector: 'mfa-verification',
  templateUrl: './mfa-verification.component.html',
  providers: [AuthService]
})

export class MfaVerificationComponent implements OnInit {
  public errorMessage: string;
  trackObj: Object;
  public enrData: Object;
  public passcode: string;
  oktaSecurityKey: string;
  oktaClientId: string;
  oktaBaseUrl: string;
  isError: boolean = false;
  isErrorInfo: boolean = false;
  isRedirectForgotPassword: boolean = false;
  resendButtonText: string = '';
  resendCodeSetTime = 0;
  timeLeftInSeconds = 0;
  isDisableResendButton: boolean = true;
  isInviteAssessment: boolean = false;
  termsOfServicesData: any;
  mfaResponseSessionToken: string = '';

  constructor(private localStorage: LocalStorageService,
              private _service: AuthService,
              private _router: Router,
              private _eventBus: EventBusService,
              private _translate: TranslateService,
              private _sanitizer: DomSanitizer,
              private _shareService: SharedService,
              @Inject(DOCUMENT) private document: Document
  ) {
    this.oktaSecurityKey = environment.okta_security_key;
    this.oktaClientId = environment.okta_client_id;
    this.resendCodeSetTime = environment.okta_resend_code_seconds;
    this.timeLeftInSeconds = environment.okta_resend_code_seconds;
    this.oktaBaseUrl = environment.okta_base_url;
   }

  ngOnInit(): void {
    this.errorMessage = null;
    this.passcode = null;

    this.isRedirectForgotPassword = window.location.href.indexOf('fromForgotPassword') > 0;
    this.resendButtonText = this._translate.instant('RESEND_CODE_TEXT', this.timeLeftInSeconds.toString());
    const enrollData = this.localStorage.getItem('oidc_enroll');

    if(!this.isRedirectForgotPassword && (!enrollData || !enrollData['id'] || !enrollData['stateToken'])) {
      console.error('No enroll factor info found');
      this._next('/invalid');
    }
    else {
      this.enrData = enrollData;
    }

    this._eventBus.languageListInInviteReceivedAnnounced({
      availableLangList: []
    });

    this.isInviteAssessment = this._shareService.checkInviteIsAssessment();
    this.setTermsOfServiceData();

    this.startTimer();
  }

  public goBack() {
    this._next('/login');
  }

  public submitSelection() {
    let that = this;
    $('#waitOnMfaModal').modal('show');
    this.trackObj = {
      stage_name: 'mfa-verify',
      action_value: 'activate'
    };
    this.isError = false;

    if (this.isRedirectForgotPassword) {
      this.localStorage.setItem('forgot_pwd_recovery_code', this.passcode);
      let requestBody = {};

      if (this.enrData['factorType'] === 'EMAIL') {
        requestBody = {
          "factorType": "EMAIL",
          "token": this.passcode,
          "otp": this.passcode
        }
      } else {
        requestBody = {
          "factorType": this.enrData['factorType'],
          "otp": this.passcode,
          "token": this.enrData['stateToken']
        }
      }

      this._service.validateForgotPasswordOTP(requestBody).subscribe(response => {
        $('#waitOnMfaModal').modal('hide');
        this._next('/oidc-reset-password');
        window.track_event('reset otp validate success', this.trackObj, true, true);
      }, error => {
        $('#waitOnMfaModal').modal('hide');
        window.track_event('reset otp validate failure', this.trackObj, true, true);

        this.errorMessage = this._translate.instant(error);
        this.isError = true;
        if (error === 'OKTA_ERROR_E0000011') {
          this.passcode = null;
          this.isDisableResendButton = true;
        }
      });
    } else {
      this._service.activateMfa(this.passcode, this.enrData).subscribe(response => {
        $('#waitOnMfaModal').modal('hide');
        if (!response) {
          this.errorMessage = this._translate.instant('ERROR_OKTA_INVALID_STATE');
        }

        window.track_event('mfa activated', this.trackObj, true, true);

        this.mfaResponseSessionToken = response['sessionToken'];

        let isMfaSignUp = !!this.localStorage.getItem('mfa_signup') ? this.localStorage.getItem('mfa_signup') : false;
        window.track_event("mfa verification", { stage_name: 'mfa-verify', action_value: 'isMfaSignUp ' + isMfaSignUp }, true, true);
        if (isMfaSignUp) {
          $('#termsOfServiceModal').modal('show');
        } else {
          // Adding timeout so that log audit call 'mfa activated' is not cancelled
          setTimeout(() => {
            this.agreeTermsOfService();
          }, 1000);
        }
      },
      error => {
        $('#waitOnMfaModal').modal('hide');
        window.track_event('mfa activate failed', this.trackObj, true, true);
        this.errorMessage = this._translate.instant(error);
        this.isError = true;
        if (error === 'OKTA_ERROR_E0000011' || error === 'OKTA_ERROR_E0000047') {
          this.passcode = null;
          this.isDisableResendButton = true;
        }
      });
    }
  }

  private _next(url): void {
    this._router.navigate([url]);
  }

  resendCode() {
    if(!!this.isDisableResendButton) {
      console.log("OTP being resent");
      return;
    }
    this.isDisableResendButton = true;
    let applicantEmail = this.localStorage.getItem('applicant_email')
    this.trackObj = {
      stage_name: 'mfa-verify',
      action_value: 'resend'
    };

    window.track_event('resend code attempt', this.trackObj, true, true);

    if(this.isRedirectForgotPassword) {
      this._service.oidcForgotPasswordFlowResend(applicantEmail, this.enrData['factorType']).subscribe({
        next: (response) => {
          window.track_event('forgot pwd resend code successful', this.trackObj, true, true);
          this.isDisableResendButton = true;
          this.timeLeftInSeconds = this.resendCodeSetTime;
          this.resendButtonText = this._translate.instant('RESEND_CODE_TEXT', this.timeLeftInSeconds.toString());
          this.startTimer();
        },
        error: (errMessage) => {
          window.track_event('forgot pwd resend code failed', this.trackObj, true, true);
          this.errorMessage = this._translate.instant(errMessage);
          this.isError = true;
          this.isDisableResendButton = false;
        }
      });
    } else { // Login / sign up MFA flow
      this._service.oidcFactorResend(this.enrData).subscribe({
        next: (response) => {
          window.track_event('resend code successful', this.trackObj, true, true);
          this.isDisableResendButton = true;
          this.timeLeftInSeconds = this.resendCodeSetTime;
          this.resendButtonText = this._translate.instant('RESEND_CODE_TEXT', this.timeLeftInSeconds.toString());
          this.startTimer();
        },
        error: (errMessage) => {
          window.track_event('resend code failed', this.trackObj, true, true);
          this.errorMessage = this._translate.instant(errMessage);
          this.isError = true;
          this.isDisableResendButton = false;
        }
      });
    }
  }

  startTimer() {
    let intervalId = setInterval(() => {
      this.timeLeftInSeconds = this.timeLeftInSeconds - 1;
      this.resendButtonText = this._translate.instant('RESEND_CODE_TEXT', this.timeLeftInSeconds.toString());

      if (this.timeLeftInSeconds === 0) {
        clearInterval(intervalId)
        this.resendButtonText = this._translate.instant('RESEND_CODE');
        this.isDisableResendButton = false;
      }
    }, 1000);
  }

  hideError() {
    this.isError = false;
  }

  private setTermsOfServiceData() {
    if(this.isInviteAssessment){
      this.termsOfServicesData = this._sanitizer.bypassSecurityTrustHtml(this._translate.instant('TERMS_AND_CONDITIONS_ASSESSMENT_CONTENT'));
    }else{
      const termsOfServiceKey = this.localStorage.getItem('isIntlPreferenceOn') ? 'TERMS_AND_CONDITIONS_CONTENT_INTL' : 'TERMS_AND_CONDITIONS_CONTENT';
      this.termsOfServicesData = this._sanitizer.bypassSecurityTrustHtml(this._translate.instant(termsOfServiceKey));
    }
  }

  agreeTermsOfService() {
    window.track_event("mfa verification", { stage_name: 'mfa-verify', action_value: 'terms of service'}, true, true);
    const localUrl = new URL(window.location.href);
    const hostName = localUrl.origin;

    let oktaCallBackUrl = this.oktaBaseUrl + "/oauth2/" + this.oktaSecurityKey + "/v1/authorize?client_id=" + this.oktaClientId + "&response_type=id_token%20token&response_mode=fragment&prompt=none&scope=openid&redirect_uri="+ hostName +"&state=" + this.localStorage.getItem('stateId') + "&nonce=" + this.localStorage.getItem('stateId') +"&sessionToken=" + this.mfaResponseSessionToken;

    window.location.href = oktaCallBackUrl;
  }

  disagreeTermsOfService() {
    $('#termsOfServiceModal').modal('hide');
    this._next('./profile/disagreeterms');
  }
}
