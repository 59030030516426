<div  *ngIf="config.inviteType === undefined && !config.isHidden ">
  <div class="col-xs-12 col-sm-6 col-md-4 m-b-20" *ngIf="config.list!=null && config.list.length>0">
    <div class="group" [formGroup]="group">
      <label class="placeholder_white custom_label" [attr.for]="config.id" [class.labelEllipsis]="config.label && config.label.length > 30">
        {{config.label|translate}}
      </label>
      <a data-toggle="popover" data-placement="bottom" title="{{config.label|translate}}"
        *ngIf="config.hint != 'NoLabelRender' && config.label && config.label.length > 30">
        <i class="icon-info labelHelpIcon" (click)="showFullLabelText()"></i>
      </a>
      <select class="form-control dynamicSelectControl" [formControlName]="config.name" [attr.id]="config.id" [value]="config.value">

        <option selected disabled value="undefined">{{'COMMONS_SELECT' | translate}}</option>
        <option *ngFor="let option of config.list" [value]="option.id ? option.id : option">
          {{ (option.id ? option.id : option) | translate}}
        </option>

      </select>
    </div>
  </div>
  <div class="col-xs-12 col-sm-6 col-md-4 m-b-20" *ngIf="config.options!=null && config.options.length>0">
    <div class="group" [formGroup]="group">
      <label class="placeholder_white custom_label" [attr.for]="config.id" [class.labelEllipsis]="config.label && config.label.length > 30">
        {{config.label|translate}}
      </label>
      <a data-toggle="popover" data-placement="bottom" title="{{config.label|translate}}"
        *ngIf="config.hint != 'NoLabelRender' && config.label && config.label.length > 30">
        <i class="icon-info labelHelpIcon" (click)="showFullLabelText()"></i>
      </a>

      <select class="form-control dynamicSelectControl" [formControlName]="config.name" [attr.id]="config.id" [value]="config.value">

        <option selected disabled value="undefined">{{'COMMONS_SELECT' | translate}}</option>
        <option *ngFor="let option of config.options" [value]="option.label">
          {{ option.label | translate }}
        </option>

      </select>
    </div>
  </div>
  <div *ngIf="config.listOfCpicObject!=null && config.listOfCpicObject.length>0">
    <div class="col-md-12 col-sm-12 col-xs-12">
      <h4> {{config.label|translate}}</h4>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
      <div class="group" [formGroup]="group">
        <select class="form-control dynamicSelectControl" [formControlName]="config.name" [attr.id]="config.id" [value]="config.value">
          <option selected disabled value="undefined">{{'COMMONS_SELECT' | translate}}</option>
          <option *ngFor="let option of config.listOfCpicObject" [value]="option.answerId">
            {{ option.answerLabel | translate }}
          </option>
        </select>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!!config.inviteType && config.inviteType === 'MI'">
  <div class="col-xs-12 col-sm-6 col-md-4 m-b-20"
    *ngIf="config.options!=null && config.options.length > 0 && config.options.length <= 4">
    <div class="group listBoxGroup" [formGroup]="group">
      <label class="placeholder_white custom_label" for="country" [class.labelEllipsis]="config.label && config.label.length > 30">
        {{config.label|translate}}
      </label>
      <a data-toggle="popover" data-placement="bottom" title="{{config.label|translate}}"
        *ngIf="config.hint != 'NoLabelRender' && config.label && config.label.length > 30">
        <i class="icon-info labelHelpIcon" (click)="showFullLabelText()"></i>
      </a>
      <select style="background:#fff !important; color:#555 !important; height:inherit !important; overflow-y: auto; border-radius: 4px;" class="selectListDropdown form-control styled" [formControlName]="config.name" [value]="config.value"
        size="2">
        <option style="padding:3px !important; margin-top:inherit !important;" *ngFor="let option of config.options" [value]="option.label">
          {{ option.label | translate }}
        </option>
      </select>
    </div>
  </div>

  <div class="col-xs-12 col-sm-6 col-md-4 m-b-20" *ngIf="config.options!=null && config.options.length > 4">
    <div class="group" [formGroup]="group">
      <label class="placeholder_white custom_label" for="country" [class.labelEllipsis]="config.label && config.label.length > 30">
        {{config.label|translate}}
      </label>
      <a data-toggle="popover" data-placement="bottom" title="{{config.label|translate}}"
        *ngIf="config.hint != 'NoLabelRender' && config.label && config.label.length > 30">
        <i class="icon-info labelHelpIcon" (click)="showFullLabelText()"></i>
      </a>
      <select class="form-control dynamicSelectControl" [formControlName]="config.name" [value]="config.value">
        <option selected disabled value="undefined">{{'COMMONS_SELECT' | translate}}</option>
        <option *ngFor="let option of config.options" [value]="option.label">
          {{ option.label | translate }}
        </option>
      </select>
    </div>
  </div>
</div>