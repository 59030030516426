
<div appSortable (orderChanged)="orderChanged($event)">
        <div *ngFor="let img of uploadImagesList; let i = index" style="margin: 4px !important;" class="col-md-2 col-sm-4 col-xs-6 uploadDocImg" draggable="true" [attr.data-index]="i" [attr.data-org-index]="i">
            <div class="thumbnail">
                <span class="docDelIcon" (click)="onDeleteFile(img.id)">
                    <i class="fa fa-times" aria-hidden="true" tabindex="0"></i>
                </span>
                <img src="{{img.src}}" class="uploadMainImg" id="{{img.id}}" style="width:100%">
                <div class="caption">
                    <p>{{img.name}}</p>
                </div>
            </div>
        </div>
</div>

<div class="col-md-2 col-sm-4 col-xs-6 uploadArea">
    <div class="thumbnail">
        <label class="uploader" ondragover="return false;" [class.loaded]="loaded" [style.outlineColor]="dragging ? activeColor : baseColor"
        (dragenter)="handleDragEnter()" (dragleave)="handleDragLeave()" (drop)="handleDrop($event)">  <span class="addNewText">{{ 'COMMONS_ADD_NEW' | translate }}</span>
            <input #myInput type="file" name="file" aria-required="true" (change)="handleInputChange($event)">
        </label>
    </div>
</div>
