<div *ngIf="DocUpload" class="col-md-4 col-xs-12 docUploadCategory">
  <i class="icon icon-circular-check-button"></i>
</div>
<div class="col-md-4 col-xs-12 docUploadIcons">
  <span><i class="icon icon-info"></i></span>
  <span><i class="icon icon-download" (click)="onDownloadDocumentClick($event)"></i></span>
  <!-- <span><i class="icon icon-upload"></i></span> -->
  <span><i class="glyphicon glyphicon-paperclip"></i></span>
  <span><i class="icon icon-eye-scan"></i></span>
  <span><i class="icon icon-garbage"></i></span>
</div>
