<div class="pad_30" [formGroup]="empSubForm" (onGesture)="doSwipe($event,empSubForm.value)" hammer-gestures>
  <!--<button type="button" class="btn arrowrightctr" debounceDirective (debounceClick)="verification(true)"> <i class="icon-arrow-right"   ></i></button>-->
  <div class="anime-clean contetnheight posrel">
    <div class="row no-form-element">
      <div class="col-md-12 col-xs-12 col-sm-12">
        <div class="header_txt_white">
          <!-- <h2>Do you want to provide employment history?</h2> -->
          <h2>{{ 'EMPLOYMENT_VERFICIATION_HEADER'  | translate : [userDetails.firstname] }}</h2>
          <h4 class="m-t-15">
            {{ 'EMPLOYMENT_VERFICIATION_SUBHEADER'  | translate  }}
          </h4>
        </div>
      </div>
    </div>
    <div class="row form-element-section">
      <div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
        <div class="group">
          <input type="hidden" class="form-control" formControlName="confirm" maxlength="50" />
        </div>
      </div>
    </div>
    <div class="row fixedbtn">
      <div class="col-xs-12 col-sm-4 col-md-3 fixedbtnb">
        <button type="button" class="btn button grow font_extrabold" debounceDirective (debounceClick)='verification(true)'
          attr.data-id="empVerificationNext_{{row}}">{{ 'COMMONS_NEXT' | translate }}</button>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-3 fixedbtnb">
        <button type="button" class="btn button_wbg grow font_extrabold" debounceDirective (debounceClick)='verification(false)'
          attr.data-id="empVerificationSkip_{{row}}">{{ 'EMP_VERFICIATION_BTN_SKIP' | translate }}</button>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-3 fixedbtnb">
        <button type="button" class="btn button_wbg grow font_extrabold" debounceDirective (debounceClick)='saveExit(true)'
          attr.data-id="empVerificationSaveExit_{{row}}">{{ 'COMMONS_SAVE_AND_EXIT' | translate }}</button>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</div>