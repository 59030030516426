import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProfileConfig } from './../../profile.config';

@Component({
  selector: 'view-profile-education',
  templateUrl: './view-profile-education.component.html',
  providers: []
})

export class ViewProfileEducationComponent {
  @Input() viewProfileData: Object;
  profileKey: string;

  constructor() {
  }

  ngOnInit() {
    this.profileKey = ProfileConfig.getViewProfileEducationKey();
  }
}