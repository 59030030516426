import { NgModule, Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from '../../../framework/localstorage.service';
import { WorkflowService } from '../../../framework/workflow.service';
import { Store } from '@ngrx/store';
import * as ACTIONS_INTERFACE from '../../actions';
import { DocumentUploadConfig } from './../document-upload.config';
import { TranslateService } from 'framework/i18n';
import { BehaviorSubject } from 'rxjs';
import { EventBusService } from 'framework/eventbus.service';
import { SharedService } from './../../../app/shared/services/shared.service';

@Component({
  selector: 'mandatory-documents',
  templateUrl: './mandatory-documents-upload.component.html',
  providers: [WorkflowService]
})

export class MandatoryDocumentUploadComponent implements OnInit {
  static STAGE_ID: string;
  static STEP_ID: string;
  static STEP_INDEX: number;
  optionalDocsPresent = new BehaviorSubject<any>([]);
  miDocumentData = new BehaviorSubject<any>([]);

  @Input()
  set inputIsOptionalDocsPresent(value) {
    this.optionalDocsPresent.next(value);
  };

  get inputIsOptionalDocsPresent() {
    return this.optionalDocsPresent.getValue();
  }

  @Input() 
  set miDocument(value) {
    this.miDocumentData.next(value);
  }
  get miDocument(){
    return this.miDocumentData.getValue();
  }

  @Input() isDynamicDocumentUpload: any;
  @Input() inputGroupObject: any;
  @Input() inputIsDocEmptyList: any;
  @Input() inputEnableNextButton: boolean;
  @Input() checkIsIntlGID100P;
  @Input() checkIsIntlPRNCrim;
  @Input() checkIsIntlRegNzNzpv;
  @Input() checkIsAFP100P;
  @Input() inputIs100pTotalPointsReq;
  @Input() inputIsCrimTotalPointsReq;
  @Input() inputIsAFPTotalPointsReq;
  @Input() configOptions: any;
  // @Input() inputIsOptionalDocsPresent: boolean;
  @Input() inputIs100PFeatureAvailable: boolean;
  @Input() inputAUCrim100PCalculationAvailable: boolean;
  @Input() inputAFP100PCalculationAvailable: boolean;
  @Input() inputFromEnhancedDashboard: boolean;
  @Output() outputUploadAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() outputDocumentOnDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() outputDocumentOnDownload: EventEmitter<any> = new EventEmitter<any>();
  @Output() outputDocumentOnView: EventEmitter<any> = new EventEmitter<any>();
  @Output() outputDocumentOnInstruction: EventEmitter<any> = new EventEmitter<any>();
  @Output() outputDownloadInstructionGuide: EventEmitter<any> = new EventEmitter<any>();
  @Output() outputOnNextClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() outputOnSaveExitClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() outputUploadAction1: EventEmitter<any> = new EventEmitter<any>();
  @Output() outputOnSkipNowClick: EventEmitter<any> = new EventEmitter<any>();

  firstName: string = '';
  SUB_STEP_ID: string;
  SUB_STEP_INDEX: number;
  PARENT_STAGE_STEP_CONFIG: Object;
  payLoad: Object;
  currentStatus: string = 'VISITED';
  startTime: any;
  buttonText: string = '';
  isOptionalDocsPresent: boolean = false;
  enableNextButtonForDynamicUpload: boolean = false;
  hideSkipForNow: boolean = false;
  isInviteAssessment: boolean = false;

  constructor(private localStorage: LocalStorageService,
    private _store: Store<ACTIONS_INTERFACE.AppState>,
    private _ts: TranslateService,
    private _eventBus: EventBusService,
    private _sharedService: SharedService) {
    this.startTime = new Date().getTime();
  }

  ngOnInit() {
    this.isInviteAssessment = this._sharedService.checkInviteIsAssessment();
    this.buttonText = this._ts.translate('COMMONS_NEXT');

    this.miDocumentData.subscribe(res => {
      if (!res || !res['action']) {
        this.enableNextButtonForDynamicUpload = false;
      }
      if (!!res) {
        this.hideSkipForNow = res['hide_skip_for_now'];
      }
    });

    this._eventBus.dynamicDocumentActionClickedAnnounced$.subscribe(item => {
      if (!!item) {
       if (!!item['action']) {
         if (item['action'] === 'delete' && item['status'] === 'SUCCESS') {
          this.enableNextButtonForDynamicUpload = false;
         } else if (item['action'] === 'upload' && item['status'] === 'SUCCESS') {
          this.enableNextButtonForDynamicUpload = true;
         }
       }
     }
    });
    
    this.optionalDocsPresent.subscribe(res => {
      this.isOptionalDocsPresent = res;
      // if (res) {
      //   this.buttonText = this._ts.translate('COMMONS_NEXT');
      // } else {
      //   this.buttonText = this._ts.translate('COMMONS_SUBMITPROFILE');
      // }
    });
  }

  // After View Init function
  ngAfterViewInit() {    
    this.firstName = this.localStorage.getItem('first_name');
    
    if (!this.isDynamicDocumentUpload) {
      this.processConfig();
    }
  }

  processConfig(): void {
    this.PARENT_STAGE_STEP_CONFIG = this.configOptions['PARENT_CONFIG'];
    this.SUB_STEP_ID = DocumentUploadConfig.subStepIndexMapping['mandatory']['subStep'];
    this.SUB_STEP_INDEX = DocumentUploadConfig.subStepIndexMapping['mandatory']['subStepIndex'];

    this.setState();
  }

  setState(): void {
    this.payLoad = {
      name: this.PARENT_STAGE_STEP_CONFIG['STAGE_ID'],
      step: this.PARENT_STAGE_STEP_CONFIG['STEP_ID'],
      stepIndex: this.PARENT_STAGE_STEP_CONFIG['STEP_INDEX'],
      subStep: this.SUB_STEP_ID,
      subStepIndex: this.SUB_STEP_INDEX,
      status: this.currentStatus,
      dbRecordPresent: this.PARENT_STAGE_STEP_CONFIG['IS_DB_RECORD_PRESENT']
    };

    this._store.dispatch({ type: this.currentStatus, payload: this.payLoad });
  }

  uploadAction(obj) {    
    obj['slide'] = 'mandatory';
    this.outputUploadAction.emit(obj);
  }

  deleteAction(obj) {
    this.outputDocumentOnDelete.emit(obj);
  }

  downloadAction(obj) {
    obj['slide'] = 'mandatory';
    this.outputDocumentOnDownload.emit(obj);
  }

  viewAction(obj) {
    this.outputDocumentOnView.emit(obj);
  }

  instructionAction(obj) {
    this.outputDocumentOnInstruction.emit(obj);
  }

  downloadInstructionGuide(val) {
    this.outputDownloadInstructionGuide.emit(val);
  }

  onNextClick() {    
    let obj = {};
    
    if (this.isDynamicDocumentUpload) {
      obj['isDynamicDocumentUpload'] = true;
      obj['miDocument'] = this.miDocument;
    } else {
      if (this.currentStatus === 'VISITED') {
        this.currentStatus = 'COMPLETED';
      }

      this.setState();

      obj['COMPONENT_STATE'] = this.payLoad;
      obj['startTime'] = this.startTime;
    }

    this.outputOnNextClick.emit(obj);
  }

  onSaveExitClick() {
    let obj = {};
    this.currentStatus = 'PENDING';
    if (!this.isDynamicDocumentUpload)
      this.setState();

    obj['COMPONENT_STATE'] = this.payLoad;
    obj['startTime'] = this.startTime;

    this.outputOnSaveExitClick.emit(obj);
    this.currentStatus = 'VISITED';
  }

  onSkipNowClick() {
    this.outputOnSkipNowClick.emit();
  }

  doSwipe(direction: string) {
    if (direction === 'swiperight') {
      // Do Nothing
    }
    if (direction === 'swipeleft') {
      if ((this.inputEnableNextButton && !this.inputIsDocEmptyList) || (this.inputIsDocEmptyList))
        this.onNextClick();
    }
  }
}
