import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { FieldConfig } from '../../models/fieldconfig.interface';
import { TranslateService } from '../../../../framework/i18n';
import * as dateUtils from '../../../../framework/utils/date.utils';
import { SharedService } from 'app/shared/services/shared.service';
import { LocalStorageService } from 'framework/localstorage.service';

declare var window: any;
declare var mobiscroll: any;
declare var $: any;
declare var Modernizr: any;


const DEFAULT_THEME: string = 'material';
const DEFAULT_APPEAR: string = 'bubble';
const DEFAULT_PICKER: string = 'date';

@Component({
  selector: 'form-date-picker',
  templateUrl: './form-date.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FormDateComponent implements OnInit {
  config: FieldConfig;
  group: FormGroup;
  minYear: any;
  dateWheels: string = undefined;
  dateFormat: string = undefined;
  wid: string;

  private picker: string;
  private theme: string;
  private appear: string;
  private minDate: Date;
  private maxDate: Date;
  private defaultDate: Date;
  private setDate: any;
  private maxYear: any;
  private id: string;
  private mobIns: any;
  private monthNames: any;
  minValue: string = "";
  maxValue: string = "";
  defaultValue: string = "";
  errorMessage: string = "";
  isInvalidDate: boolean = false;
  isMonthNative: boolean = false;
  isDateNative: boolean = false;
  isNativePicker: boolean = false;
  private minimumDate: Date;
  private maximumDate: Date;
  isDisableField: boolean = false;
  isInviteMI: boolean = false;
  isWeekend: boolean = false;

  constructor(private _ts: TranslateService, private _sharedService: SharedService,
    private localStorage: LocalStorageService, private _cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.id = `${this.config['name']}${this.config['id']}`;
    this.wid = this.id + '-widget';
    this.decidePicker();

    this.monthNames = this._ts.instant('MONTH_NAMES');
    this.isDateNative = Modernizr.inputtypes.date && (this.picker === 'UDF_CRF' || this.picker === 'date' || this.picker === 'dob' || this.picker === 'datetoContact' || this.picker === 'criminal' || this.picker === 'additionalinfo');
    this.isMonthNative = Modernizr.inputtypes.month && (this.picker === 'fromyear' || this.picker === 'month' || this.picker === 'educationmonth');
    this.isNativePicker = Modernizr.inputtypes.date && Modernizr.inputtypes.month && (this.picker === 'UDF_CRF' || this.picker === 'date' || this.picker === 'dob' || this.picker === 'datetoContact' || this.picker === 'criminal' ||
      this.picker === 'fromyear' || this.picker === 'month' || this.picker === 'educationmonth' || this.picker === 'additionalinfo');
    this.isDisableField = false;

    console.log('Date picker resolved as:', this.picker);

    if (this.isNativePicker) {
      //Added the temporary control for DOB display issue with interpolation.
      let widControl: FormControl = new FormControl('');
      this.group.addControl(this.wid, widControl);
    }
    if (this.config.value) {
      if (this.config.value !== '') {
        let lockPrePopulatedFields = (this.localStorage.getItem('pa_lock_pre_population_for_custom_form_fields') == 'Y' ? true : false);
        let isReadOnly = this.config.readonly;
        if (lockPrePopulatedFields || !!isReadOnly) {
          let fieldValue = this.config.field_value;
          if (fieldValue && fieldValue.startsWith("${")) {
            this.isDisableField = true;
          }
        }

        if (this.config.value === '${today}' || this.config.value === '${eSignDate}') {
          (<FormControl>this.group.controls[this.config.name]).setValue('');
          let date = new Date();
          this.defaultValue = this.getFormattedDate(date);
          this.setDate = this.defaultValue + 'T00:00:00';
        } else {
          let value = this.config.value;
          if (value && value.startsWith("${")) {
            (<FormControl>this.group.controls[this.config.name]).setValue('');
            let that = this;
            this._sharedService.getInterpolationData(this.config.value).then(function (resp) {
              // console.log("form-input resp :" + resp + ":");
              if (resp && resp != '') {
                let index = String(resp).indexOf("T");
                if (index == -1) {
                  resp += 'T00:00:00';
                }
                that.setDate = resp;
              } else {
                that.isDisableField = false;
              }
              // console.log(that.setDate);
              if (that.setDate) {
                let date = new Date(that.setDate);
                that.defaultValue = that.getFormattedDate(date);
                if (that.mobIns) {
                  that.mobIns.setVal(date, true);
                } else if (!that.isNativePicker) {
                  that.isDisableField = false;
                }
                if (that.isNativePicker) {
                  that.group.controls[that.wid].setValue(that.defaultValue);
                }
                that.setDateValue(that.defaultValue);
                that._cdr.markForCheck();
              }
              // console.log("form-input resp that.config.value :" + that.config.value + ":" + that.defaultValue + ":");
            }
            );
          } else {
            this.setDate = value;
            let index = String(value).indexOf("T");
            if (index == -1) {
              this.setDate += 'T00:00:00';
            }
            if (isNaN(Date.parse(this.setDate)) === false) {
              let date = new Date(this.setDate);
              this.defaultValue = this.getFormattedDate(date);
            }
          }
        }
      }
    }
  }

  decidePicker() {
    this.picker = 'additionalinfo';
    this.isInviteMI = this._sharedService.checkInviteIsMI(); // TODOEIAF

    if (this.isInviteMI && !!this.config
      && !!this.config['data_type'] && this.config['data_type'].toLowerCase() === 'dob') {
      this.picker = 'dob'
    }
  }

  ngOnChanges() {
    console.log('maxyear', this.maxYear);
    /*    if (this.setDate && this.mobIns) {
          console.log(this.setDate);
          let date = new Date(this.setDate);
          this.mobIns.setVal(date, true);
        }*/

    // Valid Date
    if (isNaN(Date.parse(this.setDate)) === false) {
      console.log(this.setDate);
      let date = new Date(this.setDate);
      if (this.mobIns) {
        this.mobIns.setVal(date, true);
      } else {
        this.defaultValue = this.getFormattedDate(date);
      }
    } else {
      if (this.mobIns) {
        this.mobIns.clear();
      }
    }
  }

  ngAfterViewInit() {
    console.log(this.maxYear);
    let that = this;
    let now = new Date();


    let mobiSettings = {
      onSet: function (event, inst) {
        this.pickedDate = event.valueText;
        that.updateFormControlValue(this.pickedDate);
      }
    }

    mobiSettings['theme'] = this.theme || DEFAULT_THEME;
    mobiSettings['display'] = this.appear || DEFAULT_APPEAR;

    if (this.minDate) {
      mobiSettings['minDate'] = this.minDate;
    }

    if (this.maxDate) {
      mobiSettings['maxDate'] = this.maxDate;
    }

    if (this.picker === 'month') {
      mobiSettings['dateWheels'] = 'MM yyyy';
    }

    if (this.picker === 'additionalinfo') {
      let max;
      let min;
      //Applied condition for max length as part of PPA-14149
      if (this.config['validation_data'] && this.config['validation_data']['max_date']) {
        max = this.config['validation_data']['max_date'];
      } else {
        //Modified max setting as part of PPA-14118 fix.
        max = new Date(now.getFullYear() + 100, now.getMonth(), now.getDate());
      }
      if (this.config['validation_data'] && this.config['validation_data']['min_date']) {
        min = this.config['validation_data']['min_date'];
        mobiSettings['min'] = min;
      }
      if(this.config['name'] == 'APPOINTMENT_DATE_1' || this.config['name'] == 'APPOINTMENT_DATE_2' || this.config['name'] == 'APPOINTMENT_DATE_3'){
        min = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        max = new Date(now.getFullYear(), now.getMonth(), now.getDate()+14);
        mobiSettings['min'] = min;
      }
      mobiSettings['max'] = max;

      mobiSettings['defaultValue'] = new Date();
      mobiSettings['dateFormat'] = 'MM dd,yyyy';
      mobiSettings['dateWheels'] = 'MM dd yyyy';
    }

    /*
     * Specific DOB settings for 18 Yrs, but user can select any month (Jan to December) and
     * date in that specific year
    */
    if (this.picker === 'dob') {
      let min = new Date(now.getFullYear() - 100, now.getMonth(), now.getDate());
      let max = new Date(now.getFullYear() - 18, now.getMonth(), now.getDate());

      if (this.isInviteMI) {
        this._sharedService.checkMinimumAllowedAge();
        if (this.localStorage.getItem('minimum_age') !== undefined) {
          let minimumAge = parseInt(this.localStorage.getItem('minimum_age'));
          max = new Date(now.getFullYear() - minimumAge, now.getMonth(), now.getDate());
        }
      }
      mobiSettings['min'] = min;
      mobiSettings['max'] = max;
      /*
       * Profile_DOB calander by default should be should 18+1 years,and
       * should allow user to select 18 year
      */
      mobiSettings['defaultValue'] = new Date(now.getFullYear() - 19, now.getMonth(), now.getDate());;
      mobiSettings['dateFormat'] = 'MM dd,yyyy';
      mobiSettings['dateWheels'] = 'MM dd yyyy';
    }
    if (this.picker === 'datetoContact') {
      let min = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      let max = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate());
      mobiSettings['min'] = min;
      mobiSettings['max'] = max;
      /*
       * Profile_DOB calander by default should be should 18+1 years,and
       * should allow user to select 18 year
      */
      mobiSettings['defaultValue'] = new Date(now.getFullYear(), now.getMonth(), now.getDate());;
      mobiSettings['dateFormat'] = 'MM dd,yyyy';
      mobiSettings['dateWheels'] = 'MM dd yyyy';
    }

    if (this.picker === 'date') {
      console.log('Using a month picker...');
      mobiSettings['dateFormat'] = 'MM dd,yyyy';
      mobiSettings['dateWheels'] = 'dd MM yyyy';
      mobiSettings['min'] = new Date(2000, 7, 14);
      mobiSettings['max'] = new Date(1900, 7, 14);
    }

    if (this.picker === 'fromyear') {
      let min = new Date(now.getFullYear() - 100, now.getMonth(), now.getDate());
      let max = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
      mobiSettings['dateFormat'] = 'MM, yyyy';
      mobiSettings['dateWheels'] = 'MM yyyy';
    }

    if (this.picker === 'toyear') {
      console.log(this.maxYear, 'toyear dob component');
      let min = new Date(this.maxYear);
      let max = new Date(now.getFullYear());

      mobiSettings['min'] = min;
      mobiSettings['dateFormat'] = 'yyyy';
      mobiSettings['dateWheels'] = 'yyyy';
    }

    if (this.picker === 'month') {
      let min = new Date(now.getFullYear() - 100, now.getMonth(), now.getDate());
      let max = new Date(now.getFullYear(), now.getMonth(), now.getDate());

      mobiSettings['min'] = min;
      mobiSettings['max'] = max;
      mobiSettings['dateWheels'] = mobiSettings['dateFormat'] = 'MM yyyy';
      if (this.minDate) {
        mobiSettings['min'] = this.minDate;
      }

      if (this.defaultDate) {
        mobiSettings['defaultValue'] = this.defaultDate;
      }

      if (this.maxDate) {
        mobiSettings['max'] = this.maxDate;
      }
    }

    if (this.picker === 'educationmonth') {
      let min = new Date(now.getFullYear() - 100, now.getMonth(), now.getDate());
      let max = new Date(now.getFullYear(), now.getMonth(), now.getDate());

      mobiSettings['min'] = min;
      mobiSettings['max'] = max;
      mobiSettings['dateWheels'] = mobiSettings['dateFormat'] = 'MM yyyy';
      if (this.minDate) {
        mobiSettings['min'] = this.minDate;
      }

      if (this.defaultDate) {
        mobiSettings['defaultValue'] = this.defaultDate;
      }

      if (this.maxDate) {
        mobiSettings['max'] = this.maxDate;
      }
    }

    // this.logProps(mobiSettings);
    // debugger;
    // let instance = this.mobIns = mobiscroll.date('#' + this.wid, mobiSettings);
    if (!this.isNativePicker) {
      let instance = this.mobIns = mobiscroll.date('#' + this.wid, mobiSettings);
    } else {
      if (mobiSettings['min']) {
        this.minValue = this.getFormattedDate(mobiSettings['min']);
        this.minimumDate = mobiSettings['min'];
        if (this.isMonthNative) {
          this.minimumDate.setDate(1);
        }
      } else {
        this.minimumDate = new Date(now.getFullYear() - 100, now.getMonth(), now.getDate());
        this.minValue = this.getFormattedDate(this.minimumDate);
      }
      if (mobiSettings['max']) {
        this.maxValue = this.getFormattedDate(mobiSettings['max']);
        this.maximumDate = mobiSettings['max'];
        if (this.isMonthNative) {
          this.maximumDate.setDate(1);
        }
      } else {
        this.maximumDate = new Date(now.getFullYear() + 2, now.getMonth(), now.getDate());
        this.maxValue = this.getFormattedDate(this.maximumDate);
      }

    }

    if (this.setDate) {
      let date = new Date(this.setDate);
      this.defaultValue = this.getFormattedDate(date);

      if (this.mobIns) {
        this.mobIns.setVal(date, true);
      }

      this.setDateValue(this.defaultValue);
    } else {
      this.defaultValue = '';
    }
    this._cdr.detectChanges();
  }


  setDateValue(dateValue) {
    console.log("setDateValue dateValue :" + dateValue);
    this.errorMessage = '';

    if (dateValue) { // TODO: TH: refactor completely using luxon and validators
      let index = dateValue.indexOf("-");
      let date = '';
      if (index != -1) {
        let year = dateValue.substring(0, index);

        /*
         * Check if year value starts with [1-9] and then 3 digits. Receives false for invalid value
         * and true for valid
        */
        if (!this.validateYearFormat(year)) {
          // this.closeInvalidDateError(); //<< this could leave control in bad shape without setting errors
          return;
        }

        let monthDay = dateValue.substring(index + 1);
        let month;
        let day = 1;
        index = monthDay.indexOf("-");
        if (index != -1) {
          month = monthDay.substring(0, index);
          day = monthDay.substring(index + 1);
          date = this.monthNames[Number(month) - 1] + " " + day + "," + year;
        } else {
          month = monthDay;
          date = this.monthNames[Number(month) - 1] + " " + year;
        }
        let newDate = new Date(year, month - 1, day);
        console.log("setDateValue newDate is :" + newDate + ":");
        //TODO: this should be rewritten to use DateValidators, currently we need to see how to extend EA_PA_INPUT_CONFIG rules.
        if(this.config.name == 'APPOINTMENT_DATE_1' || this.config.name == 'APPOINTMENT_DATE_2' || this.config.name == 'APPOINTMENT_DATE_3'){
          this.isDateWeekend(newDate);
        }
        if (+year !== newDate.getFullYear()) {
          this.errorMessage = "DATE_INVALID";
        } else if (this.minimumDate && (newDate.getTime() < this.minimumDate.getTime())) {
          this.errorMessage = "DATE_OUTOF_RANGE";
        } else if (this.maximumDate && (newDate.getTime() > this.maximumDate.getTime())) {
          this.errorMessage = "DATE_OUTOF_RANGE";
        } else if(this.isWeekend){
          this.errorMessage = "WEEKEND_DATES";
        } else {
          this.errorMessage = "";
        }

        // check this as well, since it clears date value if some of these errors exits.
        if (this.errorMessage !== '') {
          // this.isInvalidDate = true;
          date = "";
        } else {
          // this.isInvalidDate = false;
        }
        console.log("setDateValue date is :" + date + ":");
        // TODO: remove commented code next iteration after QC approve changes.
        // this.setDpValue(date); causing to set date value as ie. October 2, 2001 -> 2001-08-02
        // this.setDpValue(dateValue);
        /**
         * FIX. for validators to work with ISO dates emitted from date picker.
         * If having issue with this approach, please notify me and discuss your requirement.
         * When we set any value including the 'wrong value' to formControl, Angular Framework will run assigned
         * validators and check for value validity, thus making current formControl and whole formGroup holding the
         * control as INVALID.
         *
         * Preventing submit/next button will be than checked by is FormGroup Valid thus disabling wrong data.
         *
         * If we 'EAT' bad data, then validators will never know that data is invalid and won't prevent such submission,
         * forcing us to code more custom business logic inside components which should be agnostic by nature.
         * */
        this.updateFormControlValue(dateValue);
      }

    } else {
      // no date value check why is this needed! Is this initial blank value in picker?
      if ((this.config.name == 'APPOINTMENT_DATE_2' || this.config.name == 'APPOINTMENT_DATE_3') && !dateValue) {
        this.errorMessage = '';
      } else {
        this.errorMessage = "DATE_INVALID";
      }
      this.updateFormControlValue('');
    }
  }

  // expecting the ISO date string when working with dates and validators.
  private updateFormControlValue(pickedDate: string): void {
    console.log('Setting pickedDate [' + pickedDate + '] to form control!');

    const formControl: AbstractControl = (<FormControl>this.group.controls[this.config.name]);

    formControl.setValue(pickedDate);
    formControl.markAsTouched();
    formControl.markAsDirty();

    // backward compatible to hardcoded validations until we figure how to migrate to Validation Framework.
    if (this.errorMessage) {
      console.log('building dynamic error!');
      formControl.setErrors({
        invalidDate: true,
        translationErrorKey: this.errorMessage
      });
      return;
    }

    formControl.updateValueAndValidity();
  }

// this could be refactored to use luxon. DateTime .. toFormat(your format)
  getFormattedDate(date) {
    var formattedDate = date;
    if (date) {
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      if (month < 10) {
        month = '0' + month;
      }
      var day = date.getDate();
      if (day < 10) {
        day = '0' + day;
      }
      if (this.picker === 'fromyear' || this.picker === 'month' || this.picker === 'educationmonth') {
        formattedDate = year + "-" + month;
      } else {
        formattedDate = year + "-" + month + "-" + day;
      }
    }
    return formattedDate;
  }

  formatDate(date) {
    const dateObj = new Date(date);
    return dateUtils.formatMonthDateYear(dateObj);
  }

  closeInvalidDateError() {
    this.isInvalidDate = false;
  }

  showFullLabelText() {
    $('[data-toggle="popover"]').popover();
  }

  /*
   * Check if year is between 1900 & 9999
   * Returns true for valid year else an false
   */
  validateYearFormat(yrVal: string) {
    let formatStatus: boolean = false;

    // Year value should not start with 0 and should be of length 4
    let regexString = /^[1-9]\d{3}$/;
    formatStatus = regexString.exec(yrVal) ? true : false;

    return formatStatus;
  }
  /*
   * Un Comment it if required while
  logProps(obj: Object) {
      console.log("Props", {
        "id": obj['wid'],
        "picker": obj['picker'],
        "theme": obj['theme'],
        "appear": obj['appear'],
        "placeholder": obj['placeholder'],
        "minDate": obj['minDate'],
        "maxDate": obj['maxDate'],
        "maxYear": obj['maxYear'],
        "minYear": obj['minYear']
      });
    }*/

    isDateWeekend(date){
      var day = new Date(date).getDay();
      if([6,0].includes(day)){
          this.isWeekend = true;
      } else {
        this.isWeekend = false;
      }
    };
}
