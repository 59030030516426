import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { EmploymentConfig } from '../employment.config';
import { BehaviorSubject } from 'rxjs';

import { EventBusService } from 'framework/eventbus.service';
import { SharedService } from '../../shared/services/shared.service';
import { AppConstants } from './../../app.constants';
import { debounceTime, distinctUntilChanged, switchMap,tap, catchError, filter } from 'rxjs/operators';

//State
import { Store } from '@ngrx/store';
import * as ACTIONS_INTERFACE from '../../actions';
import { CustomSelectComponent } from 'framework/customSelect';
import { of } from 'rxjs';


declare var google: any;
declare var jQuery: any;

@Component({
  selector: 'company-name',
  templateUrl: './company-name.component.html',
  styleUrls: ['./company-name.component.css']
})
export class CompanyNameComponent implements OnInit {
  _preFillData = new BehaviorSubject<any>([]);
  @Input('group')
  public empSubForm: FormGroup;
  @Input() row: any;
  @Input() userDetails: any;
  @Input() empType: string;
  @Input() configOptions: any;
  @Input() clipboardOptions: any;
  @Input() isReviewAdd: boolean;
  @Input() isReviewEdit: boolean;
  @Input() options: any;
  @Input() isCheckSelfEmploymentEnabled: boolean;
  @Input() isPaDOTDirectEnabled: boolean;

  @Input()
  set companyPrefillConfig(value) {
    this._preFillData.next(value);
  };

  companyNameTypeahead = new EventEmitter<string>();

  //@Input() atsData: any;
  @Output() onGoToProfileBuilder: EventEmitter<any> = new EventEmitter();
  @Output() onCompanyName: EventEmitter<any> = new EventEmitter();
  @Output() onCompanyNameSwipe: EventEmitter<any> = new EventEmitter();
  //@Output() onSetATSData: EventEmitter<any> = new EventEmitter();

  isViewInit;

  //SE functionality
  static STAGE_ID: string;
  static STEP_ID: string;
  static STEP_INDEX: number;
  public SUB_STEP_ID: string;
  public SUB_STEP_INDEX: number;
  PARENT_STAGE_STEP_CONFIG: Object;

  //State Maintenance Related Activity
  static BUTTON_CLICKED: string = 'CLICKED_SAVE_EXIT_BUTTON';
  currentStatus: string = 'VISITED';
  payLoad: Object;
  startTime: any;
  //public atsSource: string = "employment_company";
  isReview: boolean = false;
  isValidCompAdd: boolean = true;
  //isEnableNextButton: boolean = false;
  prefillConfigObj: Object = {};
  companyList: [] = [];
  isCompanyListToShow: boolean = false;
  selectedCompanyList: Object = {};
  isCompanyChanged: boolean = false;
  selectedCompany: string;
  isCompNameOnlySpace: boolean = false;
  companyStreetAddress: any;
  isCompanyAddressChanged: boolean = false;

  constructor(
    private _eventBus: EventBusService, // Auto Generated PPA-8745 - Arun Supreet for Save n Exit Patch
    private _store: Store<ACTIONS_INTERFACE.AppState>,
    private shareService: SharedService) {
    this.startTime = new Date().getTime();
  }

  ngOnInit(): void {
    console.log('options: ' + JSON.stringify(this.options));
    this.isViewInit = false;
    if (!!this.isReviewAdd || !!this.isReviewEdit) {
      this.isReview = true;
    }

    this._preFillData.subscribe(x => {
      this.prefillConfigObj = (x !== undefined) ? x : '';
    })

    this._eventBus.companyNameSelected$.subscribe(data => {
      this.selectedCompany = data['companyName'];
    });
    this.selectedCompany = this.empSubForm.controls['companyName'].value;
    this.companyServerSideSearch();
  }

  ngAfterViewInit(): void {
    //console.log('----------- ngAfterViewInit -----------------');
    this.processConfig();
    this.isViewInit = true;
    this.setEmpTypeControl();
    // this.validateToenableNextButton(this.empSubForm.value);
  }

  private processConfig(): void {
    //Get the Config Details from Parent Component - Education
    //console.log(this.configOptions);
    this.PARENT_STAGE_STEP_CONFIG = this.configOptions['PARENT_CONFIG'];
    this.SUB_STEP_ID = EmploymentConfig.subStepIndexMapping['company_name']['subStep'];
    this.SUB_STEP_INDEX = EmploymentConfig.subStepIndexMapping['company_name']['subStepIndex'];

    this.setState();
  }

  private setState(): void {
    this.payLoad = {
      name: this.PARENT_STAGE_STEP_CONFIG['STAGE_ID'],
      step: this.PARENT_STAGE_STEP_CONFIG['STEP_ID'],
      stepIndex: this.PARENT_STAGE_STEP_CONFIG['STEP_INDEX'],
      subStep: this.SUB_STEP_ID,
      subStepIndex: this.SUB_STEP_INDEX,
      iteration: this.row,
      status: this.currentStatus,
      dbRecordPresent: this.PARENT_STAGE_STEP_CONFIG['IS_DB_RECORD_PRESENT']
    };

    // console.log('EMP - company_name - setState');
    // console.log(this.payLoad);

    this._store.dispatch({ type: this.currentStatus, payload: this.payLoad });
  }

  ngOnChanges() {
    if (this.isViewInit && this.empType === 'prior') {
      this.setEmpTypeControl();
    }
  }

  private setEmpTypeControl() {
    (<FormControl>this.empSubForm.controls['type']).setValue(this.empType);
  }

  doSwipe(direction: string, data) {
    // alert(direction);
    if (direction === 'swiperight') {
      this.getPrevSwipe(data);
    }
    if (direction === 'swipeleft') {
      if (!!data['companyName']) {
        this.companyName(data);
      }
    }
  }


  getPrevSwipe(data) {
    let indexDetail: Object = {};
    indexDetail['row'] = this.row;
    data['indexDetail'] = indexDetail;
    this.onCompanyNameSwipe.emit(data);
  }

  saveExit(obj) {
    // PPA-8745 - Auto-generated via regex - Arun, Supreet, Save n Exit Patch
    var re = this._eventBus.logoutAnnounced({ dummy: "workflow_exit" });
    if (re == undefined) return;
  }

  
  companyName(obj): void {
    /*
    *
    * User  has submitted the form using 'NEXT' button, then current status should be changed to 'COMPLETED'.
    * If user has submitted the form using 'SAVE & EXIT' button, the current status has already been
    * changed to 'PENDING' in saveExit()
    *
    * */
   
    if (!!obj['companyName']) {
      let spaceCheck = obj['companyName'].trim();
      if (spaceCheck === undefined || spaceCheck == '') {
        this.isCompNameOnlySpace = true;
      } else {
        this.isCompNameOnlySpace = false;
      }
    } else {
      this.isCompNameOnlySpace = false;
    }
    
    if (this.currentStatus === 'VISITED') {
      this.currentStatus = 'COMPLETED'
    }

    //Update the state
    this.setState();

    let indexDetail: Object = {};
    indexDetail['row'] = this.row;
    obj['indexDetail'] = indexDetail;
    //pass the state to server
    obj['COMPONENT_STATE'] = this.payLoad;
    obj['startTime'] = this.startTime;

    if (obj['countryCode'] != 'US') {
      if (obj['companyAddress'] == '' || obj['companyAddress'] === undefined) {
        this.isValidCompAdd = false;
      } else {
        this.isValidCompAdd = true;
      }
    } else {
      this.isValidCompAdd = true;
    }

    // check if decision changed
    obj['isCompanyDecisionChanged'] = this.isCompanyChanged || this.isCompanyAddressChanged;

    if (this.isCompanyAddressChanged) {
      this.selectedCompanyList['id'] = '';
    }

    obj['selectedCompanyList'] = this.selectedCompanyList;

    this.isCompanyChanged = false;
    this.isCompanyAddressChanged = false;
    
    if (!!obj['companyName'] && this.isValidCompAdd && !this.isCompNameOnlySpace) {
      this.onCompanyName.emit(obj);
    }
  }

  private goToProfileBuilder() {
    this.onGoToProfileBuilder.emit();
  }

  /* updateAtsData(index, delete_link) {
  console.log('----------------- : updateAtsData() : ---------------');
  this.onSetATSData.emit(index);
  } */

  //   private validateToenableNextButton(value) {
  //       let inputCompNameInLocal = value['companyNameInLocalLang'];
  //       let inputCompAddress = value['companyAddress'];
  //       if (inputCompNameInLocal != '' && inputCompNameInLocal !== undefined
  //             && inputCompAddress != '' && inputCompAddress !== undefined) {
  //         this.isEnableNextButton = true;
  //       } else if (inputCompNameInLocal != '' && inputCompNameInLocal !== undefined
  //             && (inputCompAddress === undefined || inputCompAddress == '' )) {
  //         this.isEnableNextButton = true;
  //       } else if (inputCompAddress != '' && inputCompAddress !== undefined
  //             && (inputCompNameInLocal === undefined || inputCompNameInLocal == '')) {
  //         this.isEnableNextButton = true;
  //       } else if((inputCompNameInLocal == '' || inputCompNameInLocal === undefined)
  //             && (inputCompAddress == '' || inputCompAddress === undefined)
  //             && !this.isINTLCompNameInLocal) {
  //         this.isEnableNextButton = true;
  //       } else {
  //         this.isEnableNextButton = false;
  //       }
  //   }

  closeCompAdd() {
    this.isValidCompAdd = true;
  }

  // get company Method
  companyLookUp(data, event) {
    if (data.length > 2) {
      let queryParam = {
        'company': data.trim(),
        'country': this.prefillConfigObj['country'],
        'state': this.prefillConfigObj['state'],
        'type': this.prefillConfigObj['type']
      };

      // call shared service to get state datas
      this.shareService.companyLookUpService(queryParam).subscribe(response => {
        let companyData = response;
        console.log('country Data: ' + JSON.stringify(companyData));
        this.companyList = companyData['institution-data-list'];
        if (this.companyList.length > 0) {
          this.isCompanyListToShow = true;
        } else {
          this.selectedCompanyList = {};
          this.isCompanyListToShow = false;
          this.isCompanyChanged = true;
        }
      }, error => {
      });
    }
  }

  entercompanyInput(data) {
    let selectedCompany = (<FormControl>this.empSubForm.controls['companyName']).value;
    if (selectedCompany !== data['name']) {
      this.isCompanyChanged = true;
    } else {
      this.isCompanyChanged = false;
    }

    this.isCompanyListToShow = false;
    (<FormControl>this.empSubForm.controls['companyName']).setValue(data['name']);
    if (this.prefillConfigObj['country'] !== AppConstants.DOMESTIC_COUNTRY_CODE) {
      (<FormControl>this.empSubForm.controls['companyAddress']).setValue(data['street_address']);
    }
    this.selectedCompanyList = data;
    this.companyList = [];
  }

  private companyServerSideSearch() {
    this.companyNameTypeahead.pipe(
      distinctUntilChanged(),
      filter(term => {  return (term !== undefined && term !== null) }),
      tap(term => (<FormControl>this.empSubForm.controls['companyName']).setValue(term)),
      tap(term => {if(term.length < 2) {this.companyList = [];}}),
      filter(term => term.length > 1),
      switchMap(term => this.shareService.companyLookUpService({ 'company': term,'country': this.prefillConfigObj['country'],'state': this.prefillConfigObj['state'],'type': this.prefillConfigObj['type']})
      .pipe(
        catchError(() => of({ 'institution-data-list': [] }))
        )
      )
    ).subscribe(companyData => {
      this.companyList =  companyData['institution-data-list'];
      console.log("Company List" + this.companyList);
    
    }, (err) => {
        console.log(err);
        this.companyList = [];
    });
  }

  trackChange(value) {
    let selectedData = !!value ? value : '';
    let selectedCompany = (<FormControl>this.empSubForm.controls['companyName']).value;

    if (selectedCompany !== selectedData['name']) {
      this.isCompanyChanged = true;
    } else {
      this.isCompanyChanged = false;
    }
    (<FormControl>this.empSubForm.controls['companyName']).setValue(selectedData['name']);
    if (this.prefillConfigObj['country'] !== AppConstants.DOMESTIC_COUNTRY_CODE) {
      (<FormControl>this.empSubForm.controls['companyAddress']).setValue(selectedData['street_address']);
      this.companyStreetAddress = selectedData['street_address'];
    }

    this.selectedCompanyList = selectedData === '' ? {} : selectedData;
    this.companyList = [];
  }

  onClearData(){
    (<FormControl>this.empSubForm.controls['companyName']).setValue('');
  }

  onCompanyAddressChange() {
    let enteredCompanyAddress = (<FormControl>this.empSubForm.controls['companyAddress']).value;
    if (this.prefillConfigObj['country'] !== AppConstants.DOMESTIC_COUNTRY_CODE) {
      if (enteredCompanyAddress!= undefined && this.companyStreetAddress !== enteredCompanyAddress) {
        this.isCompanyAddressChanged = true;
      } else {
        this.isCompanyAddressChanged = false;
      }
    } 
  }
}
