import { NgModule } from '@angular/core';
import { Location } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { PreAuthComponent } from './pre-auth/pre-auth.component';
import { ResetPasswordComponent } from './profile';
import { ExpiredInviteComponent, InvalidInvitationComponent } from './pre-auth';
import { BaseSetPasswordComponent } from './base-set-password/base-set-password.component';
import { BaseLoginComponent } from './base-login/base-login.component';
import { AuthGuard } from './login';
import { MfaSelectionComponent } from './mfa-selection';
import { MfaVerificationComponent } from './mfa-verification';
import { MfaCallbackComponent } from './mfa-callback/mfa-callback.component';
import { OidcResetPasswordComponent } from './profile/oidc-reset-password/oidc-reset-password.component';
import { LoaderOnlyComponent } from './loader-only/loader-only.component';
import { MfaLogoutComponent } from './mfa-logout/mfa-logout.component';

export const routes: Routes = [
  { path: '', component: BaseLoginComponent },
  { path: 'login', component: BaseLoginComponent },
  { path: 'invite/:token', component: PreAuthComponent },
  { path: 'set-password', component: BaseSetPasswordComponent },
  { path: 'oidc-reset-password', component: OidcResetPasswordComponent },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  { path: 'expired', component: ExpiredInviteComponent },
  { path: 'invalid', component: InvalidInvitationComponent },
  { path: 'enroll-mfa', component: MfaSelectionComponent },
  { path: 'load-data/:type', component: LoaderOnlyComponent },
  { path: 'activate-mfa', component: MfaVerificationComponent },
  { path: 'mfa-callback', component: MfaCallbackComponent },
  { path: 'mfa-logout/:key', component: MfaLogoutComponent },
  { path: 'id_token/:token', component: MfaCallbackComponent },
  { 
    path: 'expired-link',
    loadChildren: () => import('./pre-auth/expired-link/expired-link.module').then(m => m.ExpiredLinkModule)
  },
  { 
    path: 'tos',
    loadChildren: () => import('./terms-and-services/terms-and-services.module').then(m => m.TermsAndServicesModule)
   },
  {
    path: 'instructions',
    canActivate: [AuthGuard],
    loadChildren: () => import('./instructions/instructions.module').then(m => m.InstructionsModule)
  },
  {
    path: 'language-selection',
    canActivate: [AuthGuard],
    loadChildren: () => import('./language-selection/language-selection.module').then(m => m.LanguageSelectionModule)
  },  
  {
    path: 'country-consent',
    canActivate: [AuthGuard],
    loadChildren: () => import('./country-consent/country-consent.module').then(m => m.CountryConsentModule)
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'identity',
    loadChildren: () => import('./identity/identity.module').then(m => m.IdentityModule),
  },
  {
    path: 'yoti',
    loadChildren: () => import('./yoti/yoti.module').then(m => m.YotiModule),
  },
  { 
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'pages',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/page-master-form/page-master-form.module').then(m => m.PageMasterFormModule),
  },
  {
    path: 'view-profile',
    canActivate: [AuthGuard],
    loadChildren: () => import('./view-profile/view-profile.module').then(m => m.ViewProfileModule),
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
  },
  {
    path: 'authy',
    loadChildren: () => import('./authy/authy.module').then(m => m.AuthyModule)
  },
  {
    path: 'terms-of-service',
    loadChildren: () => import('./terms-of-service/terms-of-service.module').then(m => m.TermsOfServiceModule)
  },
  { 
    path: 'external', 
    redirectTo: '/www.google.com', 
    pathMatch: 'full'
  },
];

const trailingSlash = /\/$|\/(?=\?)|\/(?=#)/g;
Location.stripTrailingSlash = (url: string) => url.replace(trailingSlash, '/');
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' }),
  ],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
