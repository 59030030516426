import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { TranslateService } from 'framework/i18n';
import { ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
  selector: 'profile-notification-requests',
  templateUrl: './profile-notification-requests.component.html',
  providers: []
})

export class ProfileNotificationRequestsComponent  implements OnInit, AfterViewInit {
  @Input() options: any;
  @Output() onViewNotificationInviteType: EventEmitter<any> = new EventEmitter();


  constructor(  private _ts: TranslateService, private _activatedRouter: ActivatedRoute) {

  }

  ngOnInit(): void {
  
  }

  ngAfterViewInit() {
    let focus_section = this._activatedRouter.snapshot.queryParams['focus_section'];
    if (focus_section !== undefined && focus_section === 'letters'){
      console.log($('#letters_section'));
      $('html, body').animate({
        scrollTop: ($('#letters_section').offset().top)
      }, 1000);
    }
  }

  openViewNotificationInviteType(data): void {
    this.onViewNotificationInviteType.emit(data);
  }

  getTitle(type: string){
    let value = type;
    if (type === 'Pre-AA'){
      value = this._ts.instant('NOTIFICATION_TYPE_PRE_AA');
    } else if (type === 'AA') {
      value = this._ts.instant('NOTIFICATION_TYPE_AA');
    } else if (type === 'FCRA-613') {
      value = this._ts.instant('NOTIFICATION_TYPE_FCRA_613');
    } else if (type === 'MISC') {
      value = this._ts.instant('NOTIFICATION_TYPE_MISC');
    } else if (type === 'ARC') {
      value = this._ts.instant('NOTIFICATION_TYPE_ARC');
    } else if (type === 'SNLET') {
      value = this._ts.instant('NOTIFICATION_TYPE_SNLET');
    } else if (type === 'DTC') {
      value = this._ts.instant('NOTIFICATION_TYPE_DTC');
    } 
    return value;
  }
}