import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslatePipe, TranslateService } from 'framework/i18n';
import { LocalStorageService } from 'framework/localstorage.service';
import { textSpanIntersectsWithTextSpan } from 'typescript';
import { ProfileService } from '../profile.service';

@Component({
  selector: 'password-meter',
  templateUrl: './password-meter.component.html'
})
export class PasswordMeterComponent implements OnChanges{

  score: number = 0;
  stateArray: Array<any> = [];
  minLength: number = 8;
  isApplicantIdValid: boolean = false;

  @Input() password: string = '';
  @Output() isValidPassword: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private localStorage: LocalStorageService,
    private _translate: TranslateService,
    private _profileService: ProfileService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.password) {
      this.checkPass();
    }
  }

  async checkPass() {
    this.stateArray = []; //Making array as empty on every onChange
    let minimumThreeScore = 0;
    this.isApplicantIdValid = false;

    const isUpperCaseValid = this.isUppercase();
    const isLowerCaseValid = this.isLowerCase();
    const isDigitValid = this.isDigit();
    const isSpecialCharValid = this.isSpecialChar();
    const isMinLengthValid = this.isMinLength();
    const isThreeSuccessionValid = this.isThreeSuccession();    

    await this.isVerifyApplicantId();  

    this.score = 0;       //Initialising to 0 
       
    this.score = isUpperCaseValid ? this.score + 1  : this.score;
    this.score = isLowerCaseValid ? this.score + 1  : this.score;
    this.score = isDigitValid ? this.score + 1  : this.score;
    this.score = isSpecialCharValid ? this.score + 1 : this.score;
    this.score = isMinLengthValid ? this.score + 1 : this.score;
    this.score = this.isApplicantIdValid ? this.score + 1 : this.score;
    this.score = isThreeSuccessionValid ? this.score + 1 : this.score;

    //any 3 out of below 4 rules should be  satisfied 
    minimumThreeScore = isUpperCaseValid ? minimumThreeScore + 1  : minimumThreeScore;
    minimumThreeScore = isLowerCaseValid ? minimumThreeScore+ 1  : minimumThreeScore;
    minimumThreeScore = isDigitValid ? minimumThreeScore+ 1  : minimumThreeScore;
    minimumThreeScore = isSpecialCharValid ? minimumThreeScore+ 1  : minimumThreeScore;

    if (minimumThreeScore >= 3 && this.isApplicantIdValid && isThreeSuccessionValid && isMinLengthValid) {
      this.isValidPassword.emit(true);
    } else {
      this.isValidPassword.emit(false);
    } 
  }
  
  /**
   * function to check if password contains atleast one uppercase alphabet(A-Z)
   * 
   * @returns 
   */
  isUppercase() {
    let isValid: boolean = false;

    if (!this.password || this.password.length <= 0) {
      isValid = false;
    } else {
      isValid = (/[A-Z]/).test(this.password);
    }

    this.stateArray.push({ 'label': this._translate.instant('PASSWORD_POLICY_1'), status: isValid });

    return isValid;
  }

  /**
   * function to check if password contains atleast one lowercase alphabet(a-z)
   * 
   * @returns 
   */
  isLowerCase() {
    let isValid: boolean = false;

    if (!this.password || this.password.length <= 0) {
      isValid = false;
    } else {
      isValid = (/[a-z]/).test(this.password);
    }

    this.stateArray.push({ 'label': this._translate.instant('PASSWORD_POLICY_2'), status: isValid });

    return isValid;
  }

  /**
   * function to check if password contains atleast one number (0-9)
   * 
   * @returns 
   */
  isDigit() {
    let isValid: boolean = false;

    if (!this.password || this.password.length <= 0) {
      isValid = false;
    } else {
      isValid = (/[0-9]/).test(this.password);
    }

    this.stateArray.push({ 'label': this._translate.instant('PASSWORD_POLICY_3'), status: isValid });

    return isValid;
  }

  /**
   * function to check if password contains atleast one special character (!@#$%^&*()_+)
   * 
   * @returns 
   */
  isSpecialChar() {
    let isValid: boolean = false;

    if (!this.password || this.password.length <= 0) {
      isValid = false;
    } else {
      isValid = (/[!@#$%^&*()_+]/).test(this.password);
    }

    this.stateArray.push({ 'label': this._translate.instant('PASSWORD_POLICY_4'), status: isValid });

    return isValid;
  }

  /**
   * Function to validate given password is matching with invite key
   * 
   * @returns 
   */
  isApplicantId() {
    let isValid: boolean = false;

    if (!this.password || this.password.length <= 0) {
      isValid = false;
    } else {
      const currentPassword = this.password.toLowerCase();
      let invitekey = this.localStorage.getItem('key').toLowerCase();
      isValid = !currentPassword.includes(invitekey);
    }

    this.stateArray.push({ 'label': this._translate.instant('PASSWORD_POLICY_5'), status: isValid });

    return isValid;
  }

  /**
   * Function to check if password includes three character in succession (aaa/AAA/111)
   * 
   * @returns 
   * 
   */
  isThreeSuccession() {
    let isValid: boolean = false;

    if (!this.password || this.password.length < 3) {
      isValid = false;
    } else {
      let modifiedPwd = this.password.toLowerCase();
      isValid = (/^(?!.*(.)\1\1.*).*$/).test(modifiedPwd);
    }

    this.stateArray.push({ 'label': this._translate.instant('PASSWORD_POLICY_6'), status: isValid });

    return isValid;
  }

  /**
   * function to check minimum password length (8)
   * 
   * @returns 
   */
  isMinLength() {
    let isValid: boolean = false;

    if (!this.password || this.password.length <= 0) {
      isValid = false;
    } else {
      isValid = this.password.length >= this.minLength;
    }

    this.stateArray.push({ 'label': this._translate.instant('PASSWORD_POLICY_HEADER_1'), status: isValid });

    return isValid;
  }

  /**
    * Function to validate given password is matching with invite key
    * 
    * @returns 
    */
    async isVerifyApplicantId(): Promise<void> {
    let isValid: boolean = false;
    let that = this;
    this.stateArray.push({ 'label': this._translate.instant('PASSWORD_POLICY_5'), status: isValid, 'key': 'applicantid' });

    if (!this.password || this.password.length <= 0) {
      isValid = false;

      this.isApplicantIdValid = isValid;
    } else {
      // PUT call on the email and password for backend validation
      let applicantEmail = (this.localStorage.getItem('applicant_email') != null) ? this.localStorage.getItem('applicant_email') : this.localStorage.getItem('user_email');
      await this._profileService.validatePasswordLogic(applicantEmail, this.password).then(res => {
        isValid = true;
       
        that.stateArray.pop();     
        that.stateArray.push({ 'label': this._translate.instant('PASSWORD_POLICY_5'), status: isValid });
       
        this.isApplicantIdValid = isValid;
      }, error => {
        if (error === 'INVALID_PASSWORD') {
          isValid = false;
        }

        that.stateArray.pop();     
        that.stateArray.push({ 'label': this._translate.instant('PASSWORD_POLICY_5'), status: isValid });

        this.isApplicantIdValid = isValid;
      });
    }
  }
}
