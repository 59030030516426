import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultipleCustomSelectComponent } from './multiple-custom-select.component';
import { NgSelectModule } from "@ng-select/ng-select";
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        NgSelectModule
    ],
    exports: [
        MultipleCustomSelectComponent
    ],
    providers: [  ],
    declarations: [
        MultipleCustomSelectComponent
    ]
})
export class MultipleCustomSelectModule {
}
