<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12">
    <p class="black_text">{{options['title']}}</p>
    <hr>
  </div>
</div>
<div class="addCard m-b-10">
  <div class="row">
    <div class="col-xs-12 col-sm-4 col-md-3 dp-r-0" *ngIf="options.data['ssn'] != null">
      <div class="clipCard" (click)="openViewProfilePopup(options,options['key'],'ssn')">
        <img src="../../assets/img/fingerprint-with-password-entry.png" height="50px">
        <p> {{ 'SSN_LABEL_1' | translate}} </p>
        <span *ngIf="options.data['ssn'] && options.data['ssn'].length > 0">{{options.data['ssn'] | ssnMask }} </span>
      </div>
    </div>
    <div class="col-xs-12 col-sm-4 col-md-3 dp-r-0">
      <div class="clipCard" (click)="openViewProfilePopup(options,options['key'],'dob')">
        <img src="../../assets/img/calendar.png" height="50px">
        <p>{{ 'SSN_LABEL_2' | translate}}</p>
        <span *ngIf="options.data['dob'] && options.data['dob'] != null">
          {{options.data['dob'] | dOBDateFormat }}</span>
      </div>
    </div>
  </div>
</div>
