import { Injectable } from '@angular/core';
/*
  A Node consists of (prev | data | next)
  -- prev: pointer to previous component
  -- data: contains info about loaded component (Need to decide structure of data object)
  -- next: pointer to next component
*/
interface Node {
  value: any;
  prev: Node;
  next: Node;
}

@Injectable()
export class DynamicSubStepMapperService {
  private _length = 0;
  private _key = 0;
  private _head: Node = null;
  private _tail: Node = null;
  private _current: Node = null;

  public add(index: any, value: any): void {
    if (index < 0 || index >= this._length) {
      throw new Error('Out of bounds');
    }

    let i = 0;
    let current = this._head;
    while (i < index) {
      current = current.next;
      i++;
    }

    current.value = value;
  }

  public pop(): any {
    if (this._length === 0) {
      throw new Error("Can't pop from an empty data structure");
    }

    let value = this._tail.value;

    this._tail = this._tail.prev;
    if (this._tail) {
      delete this._tail.next;
      this._tail.next = null;
    }

    this._length--;

    if (this._length === 0) {
      delete this._head;
      this._head = null;
    }

    return value;
  }

  public shift(): any {
    if (this._length === 0) {
      throw new Error("Can't shift from an empty data structure");
    }

    let value = this._head.value;

    this._head = this._head.next;
    if (this._head) {
      delete this._head.prev;
      this._head.prev = null;
    }

    this._length--;

    return value;
  }

  public push(value: any): void {
    // allocate new node
    let node: Node = {
      value: value,
      prev: this._tail,
      next: null
    };

    if (this._length === 0) {
      this._head = this._tail = node;
    } else {
      this._tail.next = node;
      this._tail = this._tail.next;
    }

    this._length++;
  }

  public unshift(value: any): void {
    // allocate new node
    let node: Node = {
      value: value,
      prev: null,
      next: this._head
    };

    if (this._length === 0) {
      this._head = this._tail = node;
    } else {
      this._head.prev = node;
      this._head = this._head.prev;
    }

    this._length++;
  }

  public top(): any {
    if (this._tail) {
      return this._tail.value;
    }
  }

  public bottom(): any {
    if (this._head) {
      return this._head.value;
    }
  }

  public count(): number {
    return this._length;
  }

  public isEmpty(): boolean {
    return (this._length === 0);
  }

  public rewind(): void {
    this._key = 0;
    this._current = this._head;
  }

  public current(): any {
    if (this._current) {
      return this._current.value;
    }
    return null;
  }

  public key(): any {
    return this._key;
  }

  public next(): void {
    this._current = this._current.next;
    this._key++;
  }

  public prev(): void {
    this._current = this._current.prev;
    this._key--;
  }

  public valid(): boolean {
    return (this._key >= 0 && this._key < this._length);
  }

  public toArray(): Array<any> {
    let list = [];
    let current = this._head;
    while (current) {
      list.push(current.value);
      current = current.next;
    }
    return list;
  }

  public toString(): string {
    return '{' + this.toArray().join('->') + '}';
  }
}
