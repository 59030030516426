export class FeatureConfig {
    static readonly employmentFeaturesList = {
        'multi-position': true
    };

    static readonly documentUploadFeaturesList = {
        '100point-calculation': true,
        'AUCrim100point-calculation': false,
        'AFP100point-calculation': true
    };

    static readonly appFeaturesList = {
        'network-latency': true,
        'contact-us': true
    };

    /*
     *
     * STAGE NAME: Features' List
    */
    static readonly modulesList = {
        'employment-data-v1': FeatureConfig.employmentFeaturesList,
        'documentupload-data-v1': FeatureConfig.documentUploadFeaturesList,
        'app-features' : FeatureConfig.appFeaturesList
    };
}
