export class ServerClientValidatorKeyMap {

    static keyMap: Object = {
        allow_blank: {
            key: 'required',
            type: 'property'
        },
        max_length: {
            key: 'maxLength',
            type: 'method'
        },
        min_length: {
            key: 'minLength',
            type: 'method'
        }
    };


    static getKeyMap(): Object {
        return ServerClientValidatorKeyMap.keyMap;
    }

}