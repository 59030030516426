import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup ,Validators } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/fieldconfig.interface';
import { EventBusService } from 'framework/eventbus.service';
import { InternationalConfig } from 'app/international/international.config';


declare var $: any;
@Component({
  selector: 'form-select',
  styleUrls: ['form-select.component.scss'],
  templateUrl: './form-select.component.html'
})
export class FormSelectComponent implements Field , OnInit{

  
  config: FieldConfig;
  group: FormGroup;

  constructor(
    private _eventBus: EventBusService) {  
  }
  ngOnInit(): void {
    if (this.config.name == 'NAME_DOCUMENT_TYPE') {
      let hasRnfNumber = this.group.controls['DO_YOU_HAVE_RNF_NUMBER'].value;
      this.updateRNFFields(hasRnfNumber); 
      let that = this;
      this._eventBus.dynamicRadioClickedAnnounced$.subscribe(item => {
        if(item['selectedObj']['groupName'] == 'DO_YOU_HAVE_RNF_NUMBER'){
          that.updateRNFFields(item['selectedObj']['option']);
       }
      });      

    } else if (this.config.name == 'RIGHT_TO_WORK_STATUS_OPTIONS') {
      let isRegisteredNewCode = this.group.controls['DO_YOU_HAVE_UK_GOVT_SHARE_CODE'].value;
      if (isRegisteredNewCode === 'TRUE') {
        this.updateRTWShareCodeFields(undefined); 
      } else {
        isRegisteredNewCode = this.group.controls['REGISTER_NEW_SHARE_CODE'].value;
        this.updateRTWShareCodeFields(isRegisteredNewCode); 
      }
      
      let that = this;
      this._eventBus.dynamicRadioClickedAnnounced$.subscribe(item => {
        if(item['selectedObj']['groupName'] == 'DO_YOU_HAVE_UK_GOVT_SHARE_CODE'){
          that.updateRTWShareCodeFields('TRUE');
        }
        if(item['selectedObj']['groupName'] == 'REGISTER_NEW_SHARE_CODE'){
          that.updateRTWShareCodeFields(item['selectedObj']['option']);
       }
      });      

    }

  }

  private updateRNFFields(hasRnfNumber) {

    if (this.config.name === 'NAME_DOCUMENT_TYPE') {
      if (hasRnfNumber === 'TRUE') {
        this.group.controls[this.config.name].clearValidators();
        this.group.controls[this.config.name].setValue('');
        this.config.value = '';
        this.config['isHidden'] = true;
      } else {
        this.group.controls[this.config.name].setValidators(Validators.compose([Validators.required]));
        this.config['isHidden'] = false;
      }
      this.group.controls[this.config.name].updateValueAndValidity();
    }
    
    if(hasRnfNumber === undefined){
      this.config['isHidden']=true;
    }
  }

  private updateRTWShareCodeFields(isRegisteredNewCode) {

    if (this.config.name === 'RIGHT_TO_WORK_STATUS_OPTIONS') {
      if(!!isRegisteredNewCode){
        if (isRegisteredNewCode === 'TRUE') {
          this.group.controls[this.config.name].clearValidators();
          this.group.controls[this.config.name].setValue('');
          this.config.value = '';
          this.config['isHidden'] = true;
        } else {
          this.group.controls[this.config.name].setValidators(Validators.compose([Validators.required]));
          this.config['isHidden'] = false;
        }
        this.group.controls[this.config.name].updateValueAndValidity();
      }
    }
    
    if(isRegisteredNewCode === undefined){
      this.config['isHidden']=true;
    }
  }

 

  showFullLabelText() {
    $('[data-toggle="popover"]').popover();
  }
}
