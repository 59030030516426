import { Injectable } from "@angular/core";
import { SharedConfig, FieldMap } from "../shared.config";

@Injectable()
export class DOTService {
    fieldMap: FieldMap = SharedConfig.fieldMap;

    fieldMapper(fieldList, searchKey) {
        for (let i = 0; i < fieldList[searchKey].length; i++) {
            if (this.fieldMap[fieldList[searchKey][i]['type']] !== undefined) {
                fieldList[searchKey][i]['type'] = this.fieldMap[fieldList[searchKey][i]['type']];
            }
        }
        return fieldList;
    }

    featureInputCollectionPropMap: Object = {
        'Custom Form': 'candidate_form_fields_list',
        'DBS': 'ListOfinputs',
        'MI': 'inputs'
    };

    getFeatureInputCollectionPropName(feature) {
        return this.featureInputCollectionPropMap[feature];
    }

    deepCopy(target: any): any {
        if (target === null) {
            return target;
        }
        if (target instanceof Date) {
            return new Date(target.getTime());
        }
        if (target instanceof Array) {
            const cp = [];
            target.forEach((v) => { cp.push(v); });
            return cp.map((n: any) => this.deepCopy(n));
        }
        if (typeof target === 'object' && target !== {}) {
            // const cp = { ...target };
            const cp = Object.assign({}, target);
            Object.keys(cp).forEach(k => {
                cp[k] = this.deepCopy(cp[k]);
            });
            return cp;
        }
        return target;
    };    
}