import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { EmploymentConfig } from '../employment.config';
import { recordidService } from '../employment-recordid.service';
import * as dateUtils from './../../../framework/utils/date.utils';
import { SharedService } from '../../shared/services/shared.service';
import { EventBusService } from 'framework/eventbus.service';
import { TranslateService } from 'framework/i18n';

// State
import { Store } from '@ngrx/store';
import * as ACTIONS_INTERFACE from '../../actions';


declare var google: any;
declare var jQuery: any;

@Component({
  selector: 'category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  // SE functionality
  static STAGE_ID: string;
  static STEP_ID: string;
  static STEP_INDEX: number;
  // State Maintenance Related Activity
  static BUTTON_CLICKED: string = 'CLICKED_SAVE_EXIT_BUTTON';

  @Input('group')
  public empSubForm: FormGroup;
  @Input() row: any;
  @Input() options: any;
  @Input() empType: any;
  @Input() userDetails: any;
  @Input() isReview: any;
  @Input() configOptions: any;
  @Input() clipboardOptions: any;
  @Input() isReviewAdd: boolean;
  @Input() isReviewEdit: boolean;
  @Input() showATSdata: any;
  @Input() isATSData: any;
  @Input() empCategories: Object[];
  @Input() atsData: any;
  @Input() remainingYearMonthMsg: any;
  @Input() IsSaveSuccess: boolean;
  @Output() onCategory: EventEmitter<any> = new EventEmitter();
  @Input() gapOption: any;
  @Input() activeGap: any;
  @Input() isDotLicencePreferenceEnabled: any;
  @Input() isNeverEmployedEnabled: boolean;
  @Input() isEmploymentGapInDaysEnabled: boolean;
  @Input() isCurrentEmp: boolean;
  @Input() isScopeCompleted: boolean;
  @Input() isAdditionalEmpPrefEnabled: boolean;
  @Output() onCategorySwipe: EventEmitter<any> = new EventEmitter();
  @Output() onSetATSData: EventEmitter<any> = new EventEmitter();
  @Output() onGoToProfileBuilder: EventEmitter<any> = new EventEmitter();
  @Output() onNeverEmployedSelect: EventEmitter<any> = new EventEmitter();

  public SUB_STEP_ID: string;
  public SUB_STEP_INDEX: number;
  public atsSource: string = "employment_company";
  PARENT_STAGE_STEP_CONFIG: Object;
  public checkCategoryClick: boolean = true;

  currentStatus: string = 'VISITED';
  payLoad: Object;
  startTime: any;
  selectedCategory: string;
  isGap: boolean = false;
  isScopeAvailable: boolean = false;
  public backReviewdisable: boolean;
  public gapOptionLabel: Object = {};
  selectedCardIndex: number;

  constructor(private _store: Store<ACTIONS_INTERFACE.AppState>,
    private _recordidservice: recordidService,
    private _eventBus: EventBusService,
    private _ts: TranslateService,
    private _sharedService: SharedService) {  // Auto Generated PPA-8745 - Arun Supreet for Save n Exit Patch
    this.startTime = new Date().getTime();
  }

  ngOnInit() {
    this.isGap = (this.userDetails['isGap'] == undefined) ? false : this.userDetails['isGap'];
    console.log(this.gapOption);
    console.log(this.activeGap);
    this.checkCategoryClick = true;
    this.isScopeAvailable = (this.userDetails['isScopeAvailable'] == undefined) ? false : this.userDetails['isScopeAvailable'];
    this.selectedCategory = this.empType;
    this.backReviewdisable = this._recordidservice.getprofileBuilderbutton();
  }

  ngAfterViewInit(): void {
    // console.log('----------- ngAfterViewInit -----------------');
    this.backReviewdisable = this._recordidservice.getprofileBuilderbutton();
    this.processConfig();
  }

  doSwipe(direction: string, data) {
    // alert(direction);
    if (direction === 'swiperight') {
      this.getPrevSwipe(data);
    }
    if (direction === 'swipeleft') {
      if (!!data['type']) {
        this.category(data);
      }
    }
  }

  getPrevSwipe(obj) {
    if (this.showATSdata['display']) {
      this.showATSdata['display'] = !this.showATSdata['display'];
      return;
    }
    this.onCategorySwipe.emit(obj);
  }

  ngOnDestroy() {

  }

  private processConfig(): void {
    // Get the Config Details from Parent Component - Education
    console.log(this.configOptions);

    this.PARENT_STAGE_STEP_CONFIG = this.configOptions['PARENT_CONFIG'];
    this.SUB_STEP_ID = EmploymentConfig.subStepIndexMapping['category']['subStep'];
    this.SUB_STEP_INDEX = EmploymentConfig.subStepIndexMapping['category']['subStepIndex'];

    this.setState();
  }

  selectEmpCategory(val) {
    this.selectedCategory = val;
    (<FormControl>this.empSubForm.controls['type']).setValue(val);
  }

  ngOnChanges() {
    console.log(this.activeGap);
    this.userDetails = this.userDetails;
    this.isGap = (this.userDetails['isGap'] == undefined) ? false : this.userDetails['isGap'];
    if (this.backReviewdisable !== recordidService.profilebutton) {
      this.backReviewdisable = this._recordidservice.getprofileBuilderbutton();
    }

    let currentLang = this._ts.instant("MONTH_NAMES");
    let engLang = this._sharedService.getEngMonths();
    if (this.gapOption) {
      // convert date to respective language for display label
      if (this.isEmploymentGapInDaysEnabled) {
        this.gapOptionLabel['fromDate'] = dateUtils.convertFullDateToRespLanguage(this.gapOption['from']['date'], currentLang, engLang);
        this.gapOptionLabel['toDate'] = dateUtils.convertFullDateToRespLanguage(this.gapOption['to']['date'], currentLang, engLang);
      } else {
        this.gapOptionLabel['fromDate'] = dateUtils.convertDateToRespLanguage(this.gapOption['from']['date'], currentLang, engLang);
        this.gapOptionLabel['toDate'] = dateUtils.convertDateToRespLanguage(this.gapOption['to']['date'], currentLang, engLang);
      }
    }
  }

  private setState(): void {
    this.payLoad = {
      name: this.PARENT_STAGE_STEP_CONFIG['STAGE_ID'],
      step: this.PARENT_STAGE_STEP_CONFIG['STEP_ID'],
      stepIndex: this.PARENT_STAGE_STEP_CONFIG['STEP_INDEX'],
      subStep: this.SUB_STEP_ID,
      subStepIndex: this.SUB_STEP_INDEX,
      iteration: this.row,
      status: this.currentStatus,
      dbRecordPresent: this.PARENT_STAGE_STEP_CONFIG['IS_DB_RECORD_PRESENT'] || null
    };
    this._store.dispatch({ type: this.currentStatus, payload: this.payLoad });
  }

  saveExit(obj) {
    // PPA-8745 - Auto-generated via regex - Arun, Supreet, Save n Exit Patch
    var re = this._eventBus.logoutAnnounced({ dummy: "workflow_exit" });
    if (re == undefined) return;
  }

  
  category(obj): void {
    if (this.isReviewEdit) {
      this.backReviewdisable = false;
    }

    if (!!obj['type']) {
      /*
      *
      * User has submitted the form using 'NEXT' button, then current status should be changed to 'COMPLETED'.
      * If user has submitted the form using 'SAVE & EXIT' button, the current status has already been
      * changed to 'PENDING' in saveExit()
      *
      * */

      if (this.currentStatus === 'VISITED') {
        this.currentStatus = 'COMPLETED';
      }

      // Update the state
      this.setState();
      // pass the state to server
      obj['COMPONENT_STATE'] = this.payLoad;
      obj['startTime'] = this.startTime;
      let indexDetail: Object = {};
      indexDetail['row'] = this.row;
      obj['indexDetail'] = indexDetail;
      this.onCategory.emit(obj);
    }
  }

  goToProfileBuilder() {
    this.onGoToProfileBuilder.emit();
  }

  updateAtsData(index) {
    this.selectedCardIndex = index['index'];
    console.log('----------------- : updateAtsData() : ---------------');
    if (index !== undefined && index !== '') {
      let atsArrayDataIndex = index['index'];
      index['selectedCategory'] = this.getAtsDataType(atsArrayDataIndex);
    }

    this.onSetATSData.emit(index);
  }

  getAtsDataType(atsArrayDataIndex): string {
    if (this.atsData[atsArrayDataIndex]['type'] === undefined || this.atsData[atsArrayDataIndex]['type'] === '') {
      return this.selectedCategory;
    }
    return this.atsData[atsArrayDataIndex]['type'];
  }

  //@Debounce(1000)
  categoryNeverEmployed(obj) {
    this.onNeverEmployedSelect.emit();
  }
}
