<div class="row">
  <div class="col-xs-12 col-md-12 col-sm-12">
    <p class="black_text">{{ options['title'] }}</p>
    <hr>
  </div>
</div>
<div class="addCard m-b-10">
  <div class="row">
    <div *ngFor="let data of options.data">
      <div class="col-xs-12 col-sm-4 col-md-3 dp-r-0" *ngIf="data['name'] != undefined">
        <div class="clipCard" (click)="openViewProfilePopup(data,options['key'])">
          <img src="/assets/img/mortarboard.png" height="30px">
          <p class="boldcom text_ellipses" *ngIf="!!data['name']">{{data['name']}}</p>
          <p class="text_ellipses"><span
              *ngIf="data['type'] !== 'HIGH_SCHOOL' && data['type'] !== 'GED' && data['degree-type'] && data['qualification']">
              {{data["degree_type_description"]}} in {{data.qualification}}
            </span>
            <span *ngIf="!!data['type'] && data['type'] !== 'highschool'">
              {{data['type_description']}}
            </span>
            <span *ngIf="!!data['type'] && data['type'] === 'highschool'">
              {{data['degree_type_description']}}
            </span>

          </p>
          <p class="duration" *ngIf="!data['current_study']">{{data['degree-date'] | yearFormat }}</p>
          <p class="duration" *ngIf="data['current_study']">{{data['start-date'] | yearFormat }}</p>
          <!--  <p class="duration" *ngIf="!data['current_study']">{{data['end-date'] | yearFormat }}</p>-->
        </div>
      </div>
    </div>
  </div>
</div>
