<div class="pad0 body_container container m-t-20 mar10 ">
  <div class=" anime-clean signup">
    <div class="row margin0">
      <!-- Image Section Starts here-->
      <div class="col-md-6 col-sm-6 hidden-xs dark_bg p-t-50">
        <!--<img src="../../assets/img/illustration02_1.png">-->
        <h3 class="text-center m-t-10 pad5">{{'PRIVATE_SECTION'|translate }}</h3>
      </div>
      <!-- Image Section Ends here-->
      <!-- Sign up Form Starts here-->
      <div class="pad0 col-xs-12  col-sm-6 col-md-6 signup_form">
        <div class="signup_container">
          <div class="row mobile-header visible-xs text-center">
            <div class="col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 col-xs-12 ">
              <div class="header_txt_white">
                <h2>{{'PRIVATE_SECTION'|translate }}</h2>
              </div>
            </div>
          </div>
          <div class="row m-t-40 mar-top80 pad15">
            <div class="col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 col-xs-12 ">
              <div class="header_txt_white">
                <h2>{{'PRIVATE_HEADER'|translate }}</h2>
                <h4 class="m-t-10">{{'PRIVATE_SUBHEADER'|translate }}</h4>
              </div>
            </div>
          </div>
          <div class="row m-t-40 text-center padbot40 pad15 m-b-20">
            <img src="../../assets/img/error_white.svg" height="125px">
          </div>
          <!--<div class="addCard m-t-40">
                        <div class="row">
                            <div class="col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 col-xs-12 m-b-20">
                                <a>
                                    <div class="signupCard">
                                        <img src="../../assets/img/error.svg" height="75px">
                                        <p class="m-t-20">Go to <a href="#">My Profile Advantage</a> now</p>  
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>-->
        </div>
      </div>
      <!--Sign up From Ends here-->
    </div>
  </div>
  <!--Tab Navigation Slider End-->
  <div class="clearfix"></div>
</div>
