import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../../app/app.config';

declare var jQuery: any;

@Injectable()
export class recordidService {
  static recordidarray = [];
  static formcreated: any;
  static statesaveexit: any;
  static stateShortname: any;
  static profilebutton: boolean;

  setRecordid(iteration, recordid) {
    var x = {};
    x[iteration] = recordid;
    recordidService.recordidarray.push(x);

  }
  makeRecordidEmpty() {
    recordidService.recordidarray = [];
  }

  getRecordid(currentiteration) {
    let key = currentiteration;
    var recordId;
    let dbconfigLength = recordidService.recordidarray.length;
    if (dbconfigLength == 0) {
      return null;
    }

    for (let i = 0; i < dbconfigLength; i++) {
      var oldkey = Object.keys(recordidService.recordidarray[i])
      if (oldkey == key) {
        recordId = recordidService.recordidarray[i][key];
        return recordId;
      }

    }

  }
  setFormCreated(currentslide) {
    recordidService.formcreated = currentslide;

  }

  getFormCreated(): any {
    return recordidService.formcreated;
  }
  createmodalboxr(row) {
    var id = '#disagreeModel_' + row;
    jQuery(id).modal('show');
  }


  createModalForMultiplePositions(row) {
    var id = '#empPositionModel_' + row;
    jQuery(id).modal('show');
  }

  setprofileBuilderbutton(data) {
    recordidService.profilebutton = data;
  }
  getprofileBuilderbutton(): boolean {
    return recordidService.profilebutton;
  }
}


