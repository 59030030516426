<div class="body-main">
  <div class="body-bg pad0 container tab-height">
    <div class="pad_30">
      <div class="anime-clean contetnheight posrel">
        <div class="row text-center">
          <div class="col-sm-12 col-md-12 col-xs-12">
            <div class="col-sm-12 col-md-12 col-xs-12">
              <div class="header_txt_white">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
