import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalStorageService } from 'framework/localstorage.service';
import { ModalService } from 'framework/modal/index';
import { ErrorInfoComponent } from 'framework/form/errorInfo.directive';
import { TranslateService } from 'framework/i18n';
import { UtilService } from 'framework/utils/util.service';
import { SharedService } from '../../shared/services/shared.service';
import { AppConfig } from '../../app.config';
import { ProfileService } from '../profile.service';
import { PreAuthService } from '../../pre-auth/pre-auth.service';
import { EventBusService } from 'framework/eventbus.service';


declare var window: any;
declare var $: any;
declare var document: any;

@Component({
  selector: 'app-oidc-reset-password',
  templateUrl: './oidc-reset-password.component.html',
  providers: [ProfileService, ModalService, LocalStorageService, ErrorInfoComponent, PreAuthService],
})
export class OidcResetPasswordComponent implements OnInit {
  forgotPassRes: object;

  constructor(private _router: Router,
    private localStorage: LocalStorageService,
    private _preAuthService: PreAuthService,
    private _profileService: ProfileService,
    private _modService: ModalService,
    private _errorInfo: ErrorInfoComponent,
    private _eventBus: EventBusService,
    private _translate: TranslateService,
    private _util: UtilService,
    @Inject(DOCUMENT) private document: any,
    private _shareService: SharedService,
    private _sanitizer: DomSanitizer) {
      this.MODAL_DEMO_ID = 'someUniqueName';
      this.mService = _modService;
      this.startTime = new Date().getTime();
      this.minThreshold = AppConfig.getMinThreshold();
      this.selectedLanguageCode = this._translate.currentLang;
    }
    errorMessage = '';
    isError = false;
    MODAL_DEMO_ID: string;
    mService: ModalService;
    isConfirmPwdError = false;
    isConfirmPwdErrorInfo = false;
    confirmPwdErrorMessage = '';
    isPwdError = false;
    isPwdErrorInfo = false;
    pwdErrorMessage = '';
    isConfirmPwdValid = false;
    isPwdValid = false;
    isGoneThroughTerms = false;
    startTime: any;
    endTime: any;
    timeDiff: any;
    minThreshold: number;
    trackObj: object;
    isEnglishLangCode = false;
    selectedLanguageCode = 'en-gb';
    termsOfServicesData: any;
    isPasswordPolicyIssue = false;
    isShowTOS = false;
    isPasswordRulesValid = false;

  ngOnInit() {
    console.log('Running OIDC reset password...');
    this.forgotPassRes = this.localStorage.getItem('oidc_forgot_password_init');

    this.trackObj = {
      stage_name: 'oidc-reset-password',
      action_value: 'loaded'
    };
    this._errorInfo.errorInfo();
    window.track_event('page loaded', this.trackObj, true, true);


    if(!this.forgotPassRes) {
      console.log('Invalid access to Reset Password...');
      this._next('/profile/forgot-password');
    }

    this.setTermsOfServiceData();
    this._errorInfo.errorInfo();
    // Get language code and identify whether its is English/UK English or other language
    this.identifyLanguage();
  }

  closeTModal() {
    $('#threshHoldAlertModal').modal('hide');
  }

  validatePassword(value: object) {
    // debugger;
    const that = this;
    this.isPasswordPolicyIssue = false;
    this.isPwdErrorInfo = false;
    this._profileService.validatePassword(value).subscribe(response => {

      window.track_event('validate password', {
        stage_name: 'oidc-reset-password', action_value: 'clicked'
      }, true, true);
      that.setPassword(value);
    }, error => {
      this.errorMessage = error.message;
      this.isError = true;
      console.log('error while validate password ', this.errorMessage);
    });

  }

  setPassword(value: object): void {
    // debugger;
    $('#waitOIDCModal').modal('show');
    this.isGoneThroughTerms = true;

    if (this.isGoneThroughTerms) {
      this.endTime = new Date().getTime();
      this.timeDiff = (this.endTime - this.startTime) / 1000;
      this.timeDiff = Math.floor(this.timeDiff);

      this._profileService.oidcResetPassword(value).subscribe(response => {
        if (response) {
          this.trackObj = {
            stage_name: 'oidc-reset-password',
            action_value: 'clicked'
          };

          window.track_event('oidc reset password success', this.trackObj, true, true);
          $('#waitOIDCModal').modal('hide');
          $('#oidcResetPasswordModal').modal('show');
        }
      },
      error => {
        $('#waitOIDCModal').modal('hide');
        this.trackObj = {
          stage_name: 'oidc-reset-password',
          time_spent: this.timeDiff,
          action_value: 'clicked'
        };

        window.track_event('oidc reset password failed', this.trackObj, true, true);

        this.errorMessage = this._translate.instant(error);
        this.isError = true;
      });
    }
  }

  redirectSignin(): void {
    this._next('./login');
  }

  private _next(url): void {
    this._router.navigate([url]);
  }

  validatePwd(pwdValue: string, confirmPwdValue: string) {
    this.document.getElementById('meter').style.display = 'none';
    if (pwdValue === '') {
      this.isPwdError = true;
      this.isPwdErrorInfo = true;
      this.pwdErrorMessage = 'PASSWORD_ERROR_MSG_1';
      this.isPwdValid = false;
    }
    else if (pwdValue.length < 8) {
      this.isPwdError = true;
      this.isPwdErrorInfo = true;
      this.pwdErrorMessage = 'PASSWORD_ERROR_MSG_2';
      this.isPwdValid = false;
    }
    else if (pwdValue.length > 5 && confirmPwdValue.length > 5 && pwdValue === confirmPwdValue) {
      this.isPwdError = false;
      this.isPwdErrorInfo = false;
      this.pwdErrorMessage = '';
      this.isPwdValid = true;
      this.isConfirmPwdValid = true;
    }
    else {
      this.isPwdError = false;
      this.isPwdErrorInfo = false;
      this.pwdErrorMessage = '';
    }
  }

  validateConfirmPwd(pwdValue: string, confrmPwdvalue: string) {
    if (pwdValue.length > 5 && !confrmPwdvalue) {
      this.isConfirmPwdError = true;
      this.isConfirmPwdErrorInfo = true;
      this.confirmPwdErrorMessage = 'PASSWORD_ERROR_MSG_3';
      this.isConfirmPwdValid = false;
    } else if (!!pwdValue && !!confrmPwdvalue) {
      if (pwdValue.length === confrmPwdvalue.length && pwdValue !== confrmPwdvalue) {
        this.isConfirmPwdError = true;
        this.isConfirmPwdErrorInfo = true;
        this.confirmPwdErrorMessage = 'PASSWORD_ERROR_MSG_4';
        this.isConfirmPwdValid = false;
      }
      else if (pwdValue.length !== confrmPwdvalue.length) {
        this.isConfirmPwdError = true;
        this.isConfirmPwdErrorInfo = true;
        this.confirmPwdErrorMessage = 'PASSWORD_ERROR_MSG_4';
        this.isConfirmPwdValid = false;
      }
      else {
        this.isConfirmPwdError = false;
        this.isConfirmPwdErrorInfo = false;
        this.confirmPwdErrorMessage = '';
        this.isConfirmPwdValid = true;
      }
    } else {
      this.isConfirmPwdError = false;
      this.isConfirmPwdErrorInfo = false;
      this.confirmPwdErrorMessage = '';
      this.isConfirmPwdValid = true;
    }
  }

  closeConfirmPwdError() {
    this.isConfirmPwdError = false;
  }

  enableNext(pwdValue: string, confirmPwdValue: string) {
    if (pwdValue.length > 5 && confirmPwdValue.length > 5 && pwdValue === confirmPwdValue) {
      this.isConfirmPwdValid = true;
      this.isConfirmPwdError = false;
      this.isConfirmPwdErrorInfo = false;
      this.isPwdValid = true;
    } else {
      this.isConfirmPwdValid = false;
      this.isPwdValid = false;
    }
  }

  hideError(password: string, confirmPass: string) {
    this.isError = false;
    if (confirmPass.length > 0) {
      this.isConfirmPwdValid = (password === confirmPass);
      this.isConfirmPwdError = false;
      this.isConfirmPwdErrorInfo = false;
    }
    else {
      this.isConfirmPwdError = false;
      this.isConfirmPwdErrorInfo = false;
      this.isConfirmPwdValid = false;
    }
  }

  hidePwdError() {
    this.document.getElementById('meter').style.display = 'block';
    this.isPwdError = false;
    this.isPwdErrorInfo = false;
    this.isError = false;
  }

  private identifyLanguage() {
    const langCode = this.localStorage.getItem('language');
    if (langCode === 'en' || langCode === 'en-gb') {
      this.isEnglishLangCode = true;
    }
  }

  private setTermsOfServiceData() {
      const termsOfServiceKey = this.localStorage.getItem('isIntlPreferenceOn') ? 'TERMS_AND_CONDITIONS_CONTENT_INTL' : 'TERMS_AND_CONDITIONS_CONTENT';
      this.termsOfServicesData = this._sanitizer.bypassSecurityTrustHtml(this._translate.instant(termsOfServiceKey));
  }

  isPasswordValid(isValid: boolean){
    this.isPasswordRulesValid = isValid;
  }

  goToSignin() {
    $('#oidcResetPasswordModal').modal('hide');
    this._next('./login');
  }
}
