<div [ngClass]="(config.label.length < 30) ? 'col-md-4 ' + checkboxClass : 'col-md-12 '+ checkboxClass">
  <div class="group" [formGroup]="group">
    <div class="dynamicCheckboxContainer">
      <input type="checkbox" class="dynamicCheckboxControl customRadioBtn" [formControlName]="config.name"
        [(ngModel)]="currentSelection"
        [value]="config.value" [attr.id]="config.id" (change)="onSelectionChange(config)"> 
        <!-- {{config.label|translate}} -->
        <label class="checkboxLabel" [attr.for]="config.id" [innerHTML]="config.label | translate"></label>
    </div>
  </div>
</div>
