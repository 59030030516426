import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { LocalStorageService } from 'framework/localstorage.service';
import { TranslateService } from 'framework/i18n';
import { DocumentUploadConfig } from './../document-upload.config';
import { Store } from '@ngrx/store';
import * as ACTIONS_INTERFACE from '../../actions';
import { EventBusService } from 'framework/eventbus.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as dateUtils from 'framework/utils/date.utils';
import { DocumentUploadService } from '../document-upload.service';

declare var window: any;

@Component({
  selector: 'skipped-documents-details',
  templateUrl: './skipped-documents-details.component.html',
  styleUrls: ['./skipped-documents-details.component.css']
})

export class SkippedDocumentsDetailsComponent implements OnInit { 
  static STAGE_ID: string;
  static STEP_ID: string;
  static STEP_INDEX: number;

  @Input('group')
  public documentUploadSubForm: FormGroup;
  @Input() configOptions: any;
  @Input() inputSkippedDocumentsData: any;
  @Input() maxNoOfDaysSkippable: any;
  @Output() onDocSubmissionDetails: EventEmitter<any> = new EventEmitter();
  @Output() outputSkippedLeftSwipe: EventEmitter<any> = new EventEmitter<any>();
  
  SUB_STEP_ID: string;
  SUB_STEP_INDEX: number;
  PARENT_STAGE_STEP_CONFIG: Object;
  payLoad: Object;
  currentStatus: string = 'VISITED';
  startTime: any;
  firstName: string;
  showFutureDateToSubmitDocErrorMsg: boolean = false;
  monthNames = this._ts.instant('MONTH_NAMES');
  futureSubmissionSetDate: Date;
  futureSubmissionMaxDate: Date;
  showReasonAndFutureDate: boolean = false;
  trackObj: Object;
  isPendingInfoDueDateAvailable: boolean = false;
  pendingInfoDueDate: any;
  month_names = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
 
  constructor(private localStorage: LocalStorageService,
              private _ts: TranslateService,
              private _store: Store<ACTIONS_INTERFACE.AppState>,
              private _eventBus: EventBusService,
              private _ds: DocumentUploadService) {
  }

  ngOnInit(): void {
    this.firstName = this.localStorage.getItem('first_name');

    let now = new Date();
    now.setDate(now.getDate() + this.maxNoOfDaysSkippable['no_of_days_future_submission']);
    this.futureSubmissionMaxDate = now;

    this.addFutureSubmissionDate();
    this.checkPendingInfoDueDate();

    this.trackObj = {
      stage_name: 'document upload',
      action_value: 'on load'
    };

    window.track_event("skipped documents loaded", this.trackObj, true, true);
  }

  ngAfterViewInit() {
    this.processConfig();
  }

  getPrevSwipe() {
    this.outputSkippedLeftSwipe.emit();
  }

  private setState(): void {
    this.payLoad = {
      name: this.PARENT_STAGE_STEP_CONFIG['STAGE_ID'],
      step: this.PARENT_STAGE_STEP_CONFIG['STEP_ID'],
      stepIndex: this.PARENT_STAGE_STEP_CONFIG['STEP_INDEX'],
      subStep: this.SUB_STEP_ID,
      subStepIndex: this.SUB_STEP_INDEX,
      status: this.currentStatus,
      dbRecordPresent: this.PARENT_STAGE_STEP_CONFIG['IS_DB_RECORD_PRESENT']
    };

    this._store.dispatch({ type: this.currentStatus, payload: this.payLoad });
  }

  private processConfig(): void {
    this.PARENT_STAGE_STEP_CONFIG = this.configOptions['PARENT_CONFIG'];
    this.SUB_STEP_ID = DocumentUploadConfig.subStepIndexMapping['skippedDocs']['subStep'];
    this.SUB_STEP_INDEX = DocumentUploadConfig.subStepIndexMapping['skippedDocs']['subStepIndex'];

    this.setState();
  }  

  doSwipe(direction: string, data) {
    if (direction === 'swiperight') {
      this.getPrevSwipe();
    }
    if (direction === 'swipeleft') {
      this.docSubmissionDetails(data);
    }
  }

  saveExit(obj) {
    var re = this._eventBus.logoutAnnounced({ dummy: "workflow_exit" });
    if (re == undefined) return;
  }

  getFutureDateToSubmitDocError(obj) {
    if (this.documentUploadSubForm.controls['futureDateToSubmitDoc'] != undefined) {
      console.log("getFutureDateToSubmitDocError ", obj);
      (<FormControl>this.documentUploadSubForm.controls['futureDateToSubmitDoc']).setValue('');
    }
  }

  closeFutureDateToSubmitDocErrorMsg() {
    this.showFutureDateToSubmitDocErrorMsg = false;
  }

  private getDateEngValue(languageDate) {
    return languageDate === '' ? languageDate : dateUtils.formatEngFullDateLabel(languageDate, this.monthNames, this._ds.monthNames);
  }

  getFutureDateDpValue(value): void {
    console.log("getFutureDateDpValue ", value);
    let fromEngDate = this.getDateEngValue(value);
    let d = new Date(fromEngDate);
    let future_date = dateUtils.getDateFormatYYYYMMDD(d.getTime());
    console.log("getFutureDateDpValue values ", fromEngDate, future_date);
    if (this.documentUploadSubForm.controls['futureDateToSubmitDoc'] != undefined) {
      (<FormControl>this.documentUploadSubForm.controls['futureDateToSubmitDoc']).setValue(future_date);
    }

    this.trackObj = {
      stage_name: 'document upload',
      action_value: 'date clicked'
    };

    window.track_event("future submission date selected", this.trackObj, true, true);
  }

  docSubmissionDetails(obj): void {
    this.trackObj = {
      stage_name: 'document upload',
      action_value: 'next clicked'
    };

    window.track_event("future submission data - save to db", this.trackObj, true, true);

    if (this.currentStatus === 'VISITED') {
      this.currentStatus = 'COMPLETED'
    }
    //Update the state
    this.setState();

    let indexDetail: Object = {};
    obj['indexDetail'] = indexDetail;
    obj['COMPONENT_STATE'] = this.payLoad;

    this.onDocSubmissionDetails.emit(obj);
  }

  isValidFutureSubmissionDate(futureDate: Date): boolean {
    let isValidFutureSubmissionDate = false;
    let now = new Date();
    let nextDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);

    if (futureDate.getTime() >= nextDate.getTime()) {
      isValidFutureSubmissionDate = true;
    }

    return isValidFutureSubmissionDate;
  }

  addFutureSubmissionDate(){
    if (this.documentUploadSubForm.controls['futureDateToSubmitDoc'] != undefined) {
      let futureDateToSubmitDoc = this.documentUploadSubForm.controls['futureDateToSubmitDoc'].value;
    
      if (!!futureDateToSubmitDoc) {
        let futureDate = dateUtils.getDateFromYYYYMMDD(futureDateToSubmitDoc);

        if (this.isValidFutureSubmissionDate(futureDate)) {
          this.futureSubmissionSetDate = futureDate;
        }
      }  
    }
  }

  reasonToSkipData() {
    this.trackObj = {
      stage_name: 'document upload',
      action_value: 'textarea clicked'
    };

    window.track_event("future submission reason entered", this.trackObj, true, true);
  }

  checkPendingInfoDueDate() {
    if (this.localStorage.getItem('pending_info_due_date') != undefined) {
      let rawPendingInfoDueDate = this.localStorage.getItem('pending_info_due_date');
      this.pendingInfoDueDate = this.getFormattedDate(rawPendingInfoDueDate);
      this.isPendingInfoDueDateAvailable = true;
    }
  }

  getFormattedDate(date) {
    var formattedDate = date;
    var mon = 0;
    var tempDate = date.split('-');
    if (tempDate) {
      var year = tempDate[0];
      var month = tempDate[1];
      var day = tempDate[2];
      if (month != undefined) {
        mon = parseInt(month);
      }
      var displayDay = '';
      if (day != undefined && day.charAt(0) != 0 && parseInt(day) < 10) {
        displayDay = '0' + day;
      } else {
        displayDay = '' + day;
      }
      
      formattedDate = displayDay + "-" + this.month_names[mon - 1] + "-" + year;
    }
    return formattedDate;
  }
}
