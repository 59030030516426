<div>
  <ul>
    <li *ngIf="!!viewProfileData['type']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['type'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> : </div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{viewProfileData['type_description']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['degree-type']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['degree-type'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> : </div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{viewProfileData['degree_type_description']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['name']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['name'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> : </div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{viewProfileData['name']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['name_in_local_language']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['name_in_local_language'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> : </div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{viewProfileData['name_in_local_language']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['address']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['address'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> : </div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{viewProfileData['address']}}</div>
      </div>
    </li>


    <li *ngIf="!!viewProfileData['qualification']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['qualification'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> : </div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{viewProfileData['qualification']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['country_name']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['country_name'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> : </div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{viewProfileData['country_name']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['state_name']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['state_name'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> : </div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{viewProfileData['state_name']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['city']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['city'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> : </div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{viewProfileData['city']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['degree-date']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['degree-date'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> : </div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">
          {{viewProfileData['degree-date'] | timeStampConvert :'MMMM YYYY'}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['future_gradutation_date']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['future_gradutation_date'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> : </div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">
          {{viewProfileData['future_gradutation_date']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['start-date']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['start-date'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> : </div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">
          {{viewProfileData['start-date'] | timeStampConvert :'MMMM YYYY'}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['end-date']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['end-date'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> : </div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{ viewProfileData['end-date'] | timeStampConvert :'MMMM YYYY'}}
        </div>
      </div>
    </li>
    <li *ngIf="!!viewProfileData['grade_point_average']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['grade_point_average'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> : </div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{ viewProfileData['grade_point_average']}}
        </div>
      </div>
    </li>
  </ul>
</div>
