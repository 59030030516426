import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { ActivatedRoute, Router } from '@angular/router';
import { PreAuthService } from './pre-auth.service';
import { ProfileService } from '../profile/profile.service';
import { LocalStorageService } from '../../framework/localstorage.service';
import { EventBusService } from './../../framework/eventbus.service';
import { TRANSLATION_PROVIDERS, TranslatePipe, TranslateService } from '../../framework/i18n';
import { SharedService } from '../shared/services/shared.service';
import { AppConfig } from '../app.config';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ApplicantionConstants, Result, VENDOR, ApplicantStatus } from '../../truid/identity/src/lib/identity-config';
import { WorkflowService } from 'framework/workflow.service';
import { environment } from 'environments/environment';
import { AppConstants } from 'app/app.constants';
import { AuthService } from 'app/login';

declare var window: any;
declare var jQuery: any;
declare var document: any;

@Component({
  selector: 'pre-auth',
  templateUrl: './pre-auth.component.html',
  providers: [PreAuthService, ProfileService, DeviceDetectorService]
})

export class PreAuthComponent implements OnInit {
  errorMessage: string = '';
  invite_id: string;
  skip_consent: boolean = false;
  isAuthy: boolean = false;
  isAuthyCallback: boolean = false;
 
  constructor(private _preAuthService: PreAuthService,
    private _profileSevice: ProfileService,
    private _activatedRouter: ActivatedRoute,
    private _router: Router,
    private localStorage: LocalStorageService,
    private _translate: TranslateService,
    private shareService: SharedService,
    private _eventbus: EventBusService,
    private deviceService: DeviceDetectorService,
    private _wf: WorkflowService,
    private _service: AuthService,
    @Inject(DOCUMENT) private document: any) { }

  ngOnInit() {
    window.track_event("PA app loaded via url", { stage_name: 'pre auth', action_value: 'application loaded via url' }, true, true);
    let inviteKey = this._activatedRouter.snapshot.queryParams['key'];
    this.isAuthyCallback = this.localStorage.getItem("isAuthyCallback");
    if (this.isAuthyCallback) {
      inviteKey = this.localStorage.getItem("extension_invite");
      this.fetchInviteDetails(inviteKey);
    } else {
      let that = this;
      this._preAuthService.getInviteAuthEngine(inviteKey)
        .subscribe(response => {
          that.isAuthy =  !!response['auth_engine'] && response['auth_engine'] === 'AUTHY';
          window.track_event('engine-' + response['auth_engine'] , { stage_name: 'pre auth', action_value: 'loaded' }, true, true);
          let isInviteRid = !!response['addl_auth_required'] && response['addl_auth_required'];
          if (that.isAuthy && !isInviteRid) {
            that.localStorage.setItem('invite_key', response['key']);
            that._router.navigate(['/authy/authentication']);
            return;
          } else {
            that.fetchInviteDetails(inviteKey);
          }
        } , 
         async error => {
          this.handleErrorScreens(error);
    });    
        
    }
  }

  fetchInviteDetails(inviteKey: any){
    this.invite_id = inviteKey;
    window.track_event("PA app loaded via url", { stage_name: 'pre auth', action_value: 'application loaded via url' }, true, true);
    this.showModal('#plsWaitOnPreAuthModal');

    this._preAuthService
      .validateToken(inviteKey)
      .subscribe(response => this.processResponse(response),
        async error => {
          /**
            * Error object structure returned from validateToken() method
            * {
            *     'language': language,
            *     'languageCountryCode': languageCountryCode,
            *     'errorMessage': errorMessage
            *  }
            */
          this.closeModal('#plsWaitOnPreAuthModal');
          this.errorMessage = <any>error;
          if (this.errorMessage['errorMessage'] === 'EXPIRED') {
            /**
              * Set the language for the expiry page based on
              * invite language
              * #TODO - Hard Coded value to be replaced based on API
              * response
              */
            await this.shareService.setLanguage({
              'language': this.errorMessage['language'],
              'language_country_code': this.errorMessage['languageCountryCode']
            }, this._eventbus);

            this.localStorage.setItem('key', this.invite_id);

            this.localStorage.setItem('inviteType', this.errorMessage['inviteType']);

            window.track_event('error: ' + this.errorMessage['errorMessage'].toLowerCase(), {
              stage_name: 'pre auth', action_value: 'validating token in url'
            }, true, true);

            this._router.navigate(['/expired']);
          } else if (this.errorMessage['errorMessage'] === 'DELETED') {
            await this.shareService.setLanguage({
              'language': this.errorMessage['language'],
              'language_country_code': this.errorMessage['languageCountryCode']
            }, this._eventbus);

            window.track_event('error: ' + this.errorMessage['errorMessage'].toLowerCase(), {
              stage_name: 'pre auth', action_value: 'validating token in url'
            }, true, true);

            this._router.navigate(['/expired']);
          } else if (this.errorMessage['errorMessage'] === 'INVALID') {
            window.track_event('error: ' + this.errorMessage['errorMessage'].toLowerCase(), {
              stage_name: 'pre auth', action_value: 'validating token in url'
            }, true, true);

            this._router.navigate(['./invalid']);
          }
        });    
  }

  processAccountInfo(accountResponse: Object, inviteResponse: Object): void {
    //Default values when account-api has no entry for the given system and accountId
    let customMessage: String = 'ERROR_MSG_DESKTOP_TRUE';
    let customOptOutMessage: String = 'ERROR_MSG_OPT_OUT';

    // this.hidePleaseWait();

    if (accountResponse != null && accountResponse['messages'] != undefined &&
      accountResponse['messages'].length > 0) {

      let messages = accountResponse['messages'];

      if (messages != undefined && messages.length > 0) {
        for (var message of messages) {
          // if value is not availble for the key then we will not consider this message.
          if (message['value'] === undefined || message['value'] === '' || message['value'] == null) {
            continue;
          }
          switch (message['key']) {
            case 'CONTINUE_ON_MOBILE_MSG':
              customMessage = message['value'];
              break;
            case 'OPT_OUT_POPUP_MSG':
              this.localStorage.setItem('opt_out_popup_msg', message['value']);
              break;
            case 'OPT_OUT_MSG':
              customOptOutMessage = message['value'];
              break;
            case 'OPT_OUT_LINK_LABEL':
              this.localStorage.setItem('opt_out_link_label', message['value']);
              break;
            case 'ID_SELECT_ABORT_POPUP_MSG':
              this.localStorage.setItem('id_select_abort_popup_msg', message['value']);
              break;
            case 'ID_SELECT_ABORT_LINK_LABEL':
              this.localStorage.setItem('id_select_abort_link_label', message['value']);
              break;
            case 'RESULT_CONFIRMATION_ABORT_POPUP_MSG':
              this.localStorage.setItem('result_confirmation_abort_popup_msg', message['value']);
              break;
            case 'RESULT_CONFIRMATION_ABORT_LINK_LABEL':
              this.localStorage.setItem('result_confirmation_abort_link_label', message['value']);
              break;
            case 'LANDING_WELCOME_TEXT':
              this.localStorage.setItem('landing_welcome_text', message['value']);
              break;
            case 'LANDING_WELCOME_TO_TEXT':
              this.localStorage.setItem('landing_welcome_to_text', message['value']);
              break;
            case 'LANDING_TEXT':
              this.localStorage.setItem('landing_text', message['value']);
              break;
            case 'LANDING_CHOOSE_ID_TYPE_TEXT':
              this.localStorage.setItem('landing_choose_id_type_text', message['value']);
              break;
            case 'LANDING_ID_TEXT':
              this.localStorage.setItem('landing_id_text', message['value']);
              break;
            case 'LANDING_SELFIE_TEXT':
              this.localStorage.setItem('landing_selfie_text', message['value']);
              break;
            case 'LANDING_THATS_IT_TEXT':
              this.localStorage.setItem('landing_thats_it_text', message['value']);
              break;
            case 'LANDING_BUTTON_NAME':
              this.localStorage.setItem('landing_button_name', message['value']);
              break;
            case 'DECLINE_ESIGNATURE_CONFIRM_TEXT':
              this.localStorage.setItem('decline_esignature_confirm_text', message['value']);
              break;
            case 'DECLINE_ESIGNATURE_TEXT':
              this.localStorage.setItem('decline_esignature_text', message['value']);
              break;
            case 'DISPATCH_SELFIE_MSG':
              this.localStorage.setItem('dispatch_selfie_msg', message['value']);
          }
        }
      }
    }

    this.localStorage.setItem('opt_out_message', customOptOutMessage);
  }

  async processResponse(response: Object): Promise<void> {
    this.closeModal('#plsWaitOnPreAuthModal');
    this.document.getElementById('brand').innerHTML = response['custom_styles'];
    this.localStorage.setItem('invite_key', response['key']);
    // show error message if it is desktop or laptop devices for Right ID invites.
    let isEARightId = this.shareService.checkInviteIsRightId();
    const ridStatus = this.localStorage.getItem('rid_status');
    const importDataDLCaptureStatus = this.localStorage.getItem('import_data_status');
    const subType = this.localStorage.getItem('subType');
    const isRegistered = response['profile'] && response['profile']['registered'];
    
    this.localStorage.setItem("isProfileRegistered", isRegistered); // saving isRegistered flag status before authy updateSession api call 

    if (!!this.isAuthyCallback) {
      await this.updateAuthySession();
    }

    setTimeout(() => {
      this.localStorage.setItem("chat_exp", "SFC");
    }, 2000);
    
    /*
    * Language setting - Browser takes precedence. Moved the code to shared service
    * for code reuse
    */
    await this.shareService.setLanguage(response, this._eventbus);
    
    if (window.location.href.indexOf('key') >= 0) {
      try {

        if (!this.shareService.isSupportedMobileOs()) {
          this._router.navigate(['/unsupported-device'], { queryParams: { unsupportedDeviceVersion: true } });
          return;
        }

        if (!this.shareService.isSupportedBrowserVersion()) {
          this._router.navigate(['unsupported-browsing']);
          return;
        }

      } catch (error) {
        console.log('failed find device details => ' + error);
      }
    }

    // Set available language list
    this.shareService.setAvilableLanguageList(response, this._eventbus);
    this.localStorage.setItem('first_access', response['first_access']);

    // 8214 / 8514 - Display QR Code, regardless of RightID, if preference is set.
    const isCaptureDLPrefill = (response['account']['is_capture_dl_prefill'] === 'Y') ? 'Y' : 'N';
    this.localStorage.setItem('is_capture_dl_prefill', isCaptureDLPrefill);

    if (response['account']['name'] !== undefined) {
      //  let recruiterName = response['account']['name'].toLowerCase();
      //  let capitalizeRecruiterName = recruiterName.replace(/\b\w/g, l => l.toUpperCase());
      this.localStorage.setItem('Recruiter_Name', response['account']['name']);
    }

    if (isEARightId && subType !== AppConfig.RIGHT_ID_ROUTER && !!ridStatus && (ridStatus === 'OPTED_OUT' || ridStatus === 'OPTED_OUT_DEVICE' || ridStatus === 'FRAUD_DETECTED')) {
      console.log("EARightId Invite routing to thankyou page.");
      this._router.navigate(['/profile/thankyou']);
      return;
    }

    let capture_mode = this.localStorage.getItem("capture_mode");
    if (capture_mode == null || capture_mode === undefined || capture_mode == '') {
        // console.log("No capture mode preference - set to default " + environment.camerasettings.captureMode);
        capture_mode = environment.camerasettings.captureMode;
        this.localStorage.setItem("capture_mode", capture_mode);
    }

    let webSdkVendor = this.localStorage.getItem("websdk_vendor");
    if (webSdkVendor === null || webSdkVendor === undefined || webSdkVendor === '') {
      webSdkVendor = environment.websdk_vendor;
      this.localStorage.setItem("websdk_vendor", webSdkVendor);
    }
    if (response != null && response['account'] != undefined && response['account']['config'] != undefined &&
      response['account']['config']['preferences'] != undefined &&
      response['account']['config']['preferences'].length > 0) {
      let preferenceList = response['account']['config']['preferences'];
      for (var preference of preferenceList) {
        if (preference['name'] == 'WEBSDK_VENDOR' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined) {
          this.localStorage.setItem('websdk_vendor', preference['value']);
          continue;
        }
        if (preference['name'] == 'YOTI_TIMEOUT_IN_MINS' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined) {
          this.localStorage.setItem('yoti_timeout_in_mins', preference['value']);
          continue;
        }
        if (preference['name'] == 'DISABLE_YOTI_DIGITAL_WALLET_EXP' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined) {
          this.localStorage.setItem('disable_yoti_digital_wallet_exp', preference['value']);
          continue;
        }              
      }
    }
    let schemeExists = false;
    if (!!response['package'] && !!response['package']['scheme']) {
      schemeExists = true;
    }
    if (
        (isEARightId && 
            (!subType || ((subType === AppConfig.RIGHT_ID_ROUTER || subType === AppConstants.INVITE_SUB_TYPE_KYP) && (ridStatus === 'PENDING' || ridStatus==='DERIVED')))) ||(isCaptureDLPrefill === 'Y' && importDataDLCaptureStatus === 'PENDING') || schemeExists ) {

      let showOptOut = 'YES';
      let retryAttempt = 3;
       //1. Check if the account has preferences.
      if (response != null && response['account'] != undefined && response['account']['config'] != undefined &&
      response['account']['config']['preferences'] != undefined &&
      response['account']['config']['preferences'].length > 0)
      {
        let preferenceList = response['account']['config']['preferences'];
        //2. Check if the preference for opt-out is set. Iterate the response object and store it.
        for (var preference of preferenceList) {
          if (preference['name'] == 'SHOW_OPT_OUT') {
            showOptOut = preference['value'];
            continue;
          }
          if (preference['name'] == 'CAPTURE_MODE' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined) {
            // console.log(" capture mode = " + preference['value'])
            this.localStorage.setItem('capture_mode', preference['value']);
            continue;
          }
          if (preference['name'] == 'RETRY_ATTEMPT') {
            // console.log(" retry attempt = " + preference['value'])
            retryAttempt = preference['value'];
            continue;
          }

          //Adding to redirect user in case user Opts to try alternate method link shown on Instructions Component
          if (preference['name'] == 'REDIRECT_URL' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined){
             if (!(this.localStorage.getItem('try_alt_methods_uri'))) {
                 this.localStorage.setItem('try_alt_methods_uri', preference['value']);
             }
             continue;
          }
          // Commented because we are not redirecting to resident page as in case of stand alone
          // if (preference['name'] == 'REDIRECT_URL' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined){
          //   if ( this.localStorage.getItem('redirect_url') == undefined  || this.localStorage.getItem('redirect_url') == ''){
          //       this.localStorage.setItem('redirect_url', preference['value']);
          //   }
          //   continue;
          // }
         /* if (preference['name'] == 'WEBSDK_VENDOR' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined){
            this.localStorage.setItem('websdk_vendor', preference['value']);
            continue;
          }*/
          if (preference['name'] == 'VERIFICATION_API_VENDOR' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined){
              this.localStorage.setItem('verification_api_vendor', preference['value']);
              continue;
          }
          if (preference['name'] == 'SHOW_ABORT_OPTIONS' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined){
              this.localStorage.setItem('show_abort_options', preference['value']);
              continue;
          }
          if (preference['name'] == 'SHOW_NON_US_FLOW' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined){
              this.localStorage.setItem('show_non_us_flow', preference['value']);
              continue;
          }
          if (preference['name'] == 'MASK_DECISION' && preference['value'] != '' && preference['value'] != null && preference['value'] != undefined){
              this.localStorage.setItem('mask_decision', preference['value']);
              continue;
          }
        }
      }
      let webSdkVendor = this.localStorage.getItem('websdk_vendor');
      let isYoti = (!!webSdkVendor && webSdkVendor === 'YOTI');
      this.localStorage.setItem("is_yoti", isYoti);
      console.log("PreAuthComponent webSdkVendor and isYoti----", webSdkVendor, isYoti);

      this.localStorage.setItem('show_opt_out', showOptOut);
      this.localStorage.setItem('retry_attempt', retryAttempt);
      if (!isYoti && this.deviceService.isDesktop()  && ridStatus!='DERIVED') {
        this.errorMessage = <any>Error;
        this.errorMessage['errorMessage'] = 'UNSUPPORTED_DEVICE';
        this._router.navigate(['/profile/unsupported-device']);

        window.track_event('error: unsupported device', {
          stage_name: 'pre auth', action_value: 'validating token in url'
        }, true, true);

        return;
      }

      // let capture_mode = this.localStorage.getItem("capture_mode");
      // if (capture_mode == null || capture_mode === undefined || capture_mode == '') {
      //     // console.log("No capture mode preference - set to default " + environment.camerasettings.captureMode);
      //     capture_mode = environment.camerasettings.captureMode;
      //     this.localStorage.setItem("capture_mode", capture_mode);
      // }

      let result = !!response['account']['rid_status'] ? response['account']['rid_status'] : '';
      if (result !== '' && result === Result.Verified) {
        this.shareService.setErrorCodeAndMsg('VERIFIED_PASSED', 'Verified and passed.');
        this._router.navigate(['/app-error']);
        return;
      }

      window.track_event('rightid invite', { stage_name: 'pre auth', action_value: 'load' }, true, true);
      
      //Adding IDMetricsCaptureFramework.js to head tag if rightID
      let head = this.document.getElementsByTagName('head')[0];
      let script = document.createElement('script');
      script.src = '../../assets/js/IDMetricsCaptureFramework.js';
      head.appendChild(script);
      if (isYoti) {
        let yotiScript = document.createElement('script');
        yotiScript.src = 'https://www.yoti.com/share/client/';
        head.appendChild(yotiScript);
      }

      window.track_event('right id invite', {
        stage_name: 'pre auth', action_value: 'validating token in url'
      }, true, true);

      this.skip_consent = this._activatedRouter.snapshot.queryParams['skip_consent'];

      let inviteType = 'PA';
      let accountId = !!response['account']['id'] ? response['account']['id'].substring(0, 6) : '';
      let accountTag = !!response['account']['id'] ? response['account']['id'].substring(6, 9) : null;
      let inviteSource = 'EA';
      let countryCode = !!response['language_country_code'] ? response['language_country_code'] : 'US';
      let langCode = !!response['language'] ? response['language'] : 'en';

      // Invoke account-api service, add invite type, account id, account tag,
      this._preAuthService.getAccountInfo(inviteType, accountId, accountTag, inviteSource, countryCode, langCode)
        .subscribe(accountResponse => {
          this.processAccountInfo(accountResponse, response);
          this.processLoadPage(response);
          },
          error => {
            // this.hidePleaseWait();
            if (error.errorCode === 404) {
              this.processAccountInfo(null, response);
              this.processLoadPage(response)
            } else {
              this.shareService.setErrorCodeAndMsg(error.errorCode, error.errorMessage);
              console.log('**************ERROR PAGE*********************');
              console.log("IdentityPreCheckComponent errorCode and errorMessage", error.errorCode, error.errorMessage);
              this._router.navigate(['/identity/app-error']);
              return;
            }
          }
        );
    } else {
      this.processLoadPage(response);
    }
    
    // Adding modernizr-custom.js to head tag if invite is not RIGHT or RID stages are completed
    let head = this.document.getElementsByTagName('head')[0];
    let script = document.createElement('script');
    script.src = '../../assets/js/modernizr-custom.js';
    head.appendChild(script);
  }

  processLoadPage(response) {
      // Set preference value 1101
      this.localStorage.setItem('isIntlPreferenceOn', response['account']['is_intl']);

      this.localStorage.setItem('instance', response['instance']);

      /**
       * 'Contact Us' is behind a feature flag now. (features.config.ts)
       * false - means off i.e. no 'Contact Us' link
       * true - means on i.e. 'Contact Us' link to be shown if present in response object
       *
       * if app-feature for contact-us is enabled & if chat is not avialabe, then contact
       *  us link would be available.
       */

    this.localStorage.setItem('active_chat', response['account']['active_chat']);
    if (this.localStorage.getItem('active_chat') !== undefined && this.localStorage.getItem('active_chat') === 'N') {
      //TODO - shutdown SFC ?
    }

      // 6565 / 6567 - Dispatch selfie preference.
      let dispatchSelfie = 'N';
      if (response['account']['is_dispatch_rightid_selfie']) {
        dispatchSelfie = 'Y';
      }
      this.localStorage.setItem('is_dispatch_rightid_selfie', dispatchSelfie);

      // 6522 / 6701 - SSN Mandatory flag account level for ATS
      let ssnMandatory = 'N';
      if (response['account']['is_ssn_mandatory'] === 'Y') {
        ssnMandatory = 'Y'
      }
      this.localStorage.setItem('is_ssn_mandatory', ssnMandatory);

      // 6565 / 6567 Check for dispatch selfie msg.
      // TODO: Needs to be revisted. Only falls through to language file entry for now.
      let dispatchMsg = response['account']['dispatch_selfie_msg'];
      if (dispatchMsg) {
        this.localStorage.setItem('dispatch_selfie_msg', dispatchMsg);
      } else {
        dispatchMsg = this._translate.instant('DISPATCH_SELFIE_MSG');
        this.localStorage.setItem('dispatch_selfie_msg', dispatchMsg);
      }

      if (AppConfig.isFeatureAvailable('app-features', 'contact-us')
        && this.localStorage.getItem('active_chat') !== undefined
        && this.localStorage.getItem('active_chat') === 'N') {
        if (response['account'] !== undefined && response['account']['contactus_text'] !== undefined) {
          this.localStorage.setItem('contactus', response['account']['contactus_text']);

          this._eventbus.contactUsAnnounced({
            contactUs: response['account']['contactus_text'],
            isContactUsReceived: true
          });
        }
      }


      const invitekey = this.localStorage.getItem('invite_key');
      this.localStorage.setItem('instance', response['instance']);

      // DO IT HERE
      if (this.localStorage.getItem('applicant_status') === 'DA_REDIRECT') {
        // this._router.navigate(['/da-redirect']);
        // DA REDIRECT this._next(response['/da-redirect']);
        this.localStorage.setItem('da_url', response['da_url'])
        this._router.navigate(['/profile/redirect']);
      } else {
        // get the profile data but should be using email not profile id
        let profile_id = this.localStorage.getItem('profile_id');
        // let email = this.localStorage.getItem('user_email');
        let that = this;
        const isRegistered = this.localStorage.getItem('isProfileRegistered');
        
        if (!!this.isAuthyCallback) {
          let inviteAuthType = this.localStorage.getItem("inviteAuthType");
            if (!isRegistered || (inviteAuthType === 'register')) {
              that._router.navigate(['/set-password']);
            } else {
              that._router.navigate(['/login']);
            }
            that.closeModal('#plsWaitOnPreAuthRegModal'); 
        } else if (!!response['account']['auth_engine'] && response['account']['auth_engine'] == 'AUTHY') {
          this._router.navigate(['/authy/authentication']);
        } else if (isRegistered) {
         this._router.navigate(['/login']);
        } else {
          this._router.navigate(['/set-password']);
        }
      }

      let packageName = response["package"] !== undefined ? response['package']['name'] : '';

      this.localStorage.setItem('package_name', packageName);
  }

  private showModal(id) {
    jQuery(id).modal('show');
  }

  private closeModal(id) {
    jQuery(id).modal('hide');
  }

  private async handleErrorScreens(error: any) {
    debugger
    this.errorMessage = <any>error;
    if (this.errorMessage['errorMessage'] === 'EXPIRED') {
    
      await this.shareService.setLanguage({
        'language': this.errorMessage['language'],
        'language_country_code': this.errorMessage['languageCountryCode']
      }, this._eventbus);


      this.localStorage.setItem('inviteType', this.errorMessage['inviteType']);

      window.track_event('error: ' + this.errorMessage['errorMessage'].toLowerCase(), {
        stage_name: 'pre auth', action_value: 'invite is expired'
      }, true, true);

      this._router.navigate(['/expired']);
    } else if (this.errorMessage['errorMessage'] === 'DELETED') {
      await this.shareService.setLanguage({
        'language': this.errorMessage['language'],
        'language_country_code': this.errorMessage['languageCountryCode']
      }, this._eventbus);

      window.track_event('error: ' + this.errorMessage['errorMessage'].toLowerCase(), {
        stage_name: 'pre auth', action_value: 'invite is deleted'
      }, true, true);

      this._router.navigate(['/expired']);
    } else if (this.errorMessage['errorMessage'] === 'INVALID') {
      window.track_event('error: ' + this.errorMessage['errorMessage'].toLowerCase(), {
        stage_name: 'pre auth', action_value: 'invite is invalid'
      }, true, true);

      this._router.navigate(['./invalid']);
    }
  }

   async updateAuthySession() {
    let inviteKey = this.localStorage.getItem("extension_invite");
    return new Promise((resolve, reject) => {
      this._service.updateAuthyRegisteredAndSession(inviteKey).subscribe((response) => {
        console.log('updateAuthyRegisteredAndSession response', response.toString());
        resolve(response);
      }, error => {
        console.log("Error while updating the authy session state.", error);
      });
    });

  }

}
