import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JobModel } from './job-model';
import { AppConfig } from '../app.config';
import { WorkflowService } from '../../framework/workflow.service';
import { TimeStampDateFormat } from '../pipes/dateformat.pipe';
import { LocalStorageService } from '../../framework/localstorage.service';
import { TranslateService } from 'framework/i18n';
import { EmploymentConfig } from './employment.config';
import { UtilService } from '../../framework/utils/util.service';
import { DOTService } from 'app/shared/dot/dot.service';
import * as dateUtils from './../../framework/utils/date.utils';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { PlaidSignInToken } from './plaid-flow/plaid-model';


@Injectable()
export class EmploymentService {

  static employmentCategories: Object = {};

  //workflow_instance_id: any;
  static stateShortname: any;
  workflow_instance_id: string;
  employmentDataConfig: Object;
  private action: string;
  jobs: JobModel[] = [];
  recordIdObj = {};
  recordIdGapArr = [];
  currentJobObj: Object = null;
  private now: Date = new Date();
  public jobFromDateCollection: any[] = [];
  public jobToDateCollection: any[] = [];
  public minYear = 7;
  public gapLimit = 2;
  private serverData: Object[];
  nextURL: string;
  nextIntlURL: string;
  nextDisplayname: string;
  headerOptions: any;
  tempGapRecordId: string = null;
  private langMonthNames;
  private ageBasedScope = null;

  constructor(private _authHttp: HttpClient,
    private _workflow: WorkflowService,
    private _tsPipe: TimeStampDateFormat, private localStorage: LocalStorageService,
    private _ts: TranslateService,
    private _util: UtilService, private _ds: DOTService) {
    this.workflow_instance_id = this.localStorage.getItem('workflow_instance_id');
    this.employmentDataConfig = this._workflow.getStepConfig('employment-data-v1', 0);
    this.nextURL = this._workflow.getNextURL('employment-data-v1');

    if (this._workflow.getSteps('international-data-v1') !== undefined) {
      this.nextIntlURL = this._workflow.getMenuLink('international-data-v1');
    }

    this.nextDisplayname = this._workflow.getNextMenuDisplayName('employment-data-v1');
    this.langMonthNames = this._ts.instant('MONTH_NAMES');
  }

  //Cache issue avoid of workflow id
  updateWorkflowId(): boolean {
    //console.log("before wfId:" +this.workflow_instance_id );
    //this.workflow_instance_id = this.workflow_instance_id = localStorage.getItem('workflow_instance_id') || null;
    //console.log("after wfId:" +this.workflow_instance_id );
    //this.workflow_instance_id = localStorage.getItem('workflow_instance_id') || null;
    this.employmentDataConfig = this._workflow.getStepConfig('employment-data-v1', 0);
    this.action = this.employmentDataConfig['action'];
    this.headerOptions = this._workflow.getHeaderOptions();
    if (!!this.employmentDataConfig['scopes'] && !!this.employmentDataConfig['scopes'][0]) {
      this.minYear = this.getMinimumScopeInMonths('YEARS') || 0;
      this.gapLimit = this.employmentDataConfig['scopes'][0]['max_gap_allowed'] || 0;
    } else {
      this.minYear = 0;
      this.gapLimit = 0;
    }
    return true;
  }

  getCustomInstructions(): string {
    let instructions: string;
    if (this.employmentDataConfig['forms'] !== undefined &&
      this.employmentDataConfig['forms'].length > 0 &&
      this.employmentDataConfig['forms'][0]['instructions'] !== undefined) {
      instructions = this.employmentDataConfig['forms'][0]['instructions'];
    }
    return instructions;
  }

  isStandardFormExtension(): boolean {
    if (this.employmentDataConfig['forms'] !== undefined &&
      this.employmentDataConfig['forms'].length > 0 &&
      this.employmentDataConfig['forms'][0]['is_customform'] !== undefined) {
      return this.employmentDataConfig['forms'][0]['is_customform'];
    }
    return false;
  }

  isAgencyExtensionForm(): boolean {
    let isAgencyExtForm = false;
    if (this.isStandardFormExtension()) {
      if (this.employmentDataConfig['forms'].length > 0) {
        for (let i in this.employmentDataConfig['forms']) {
          if (this.employmentDataConfig['forms'][i]['candidate_form_fields_list'] &&
            this.employmentDataConfig['forms'][i]['candidate_form_fields_list'].length > 0) {
            let formFieldList = this.employmentDataConfig['forms'][i]['candidate_form_fields_list'];
            for (let i in formFieldList) {
              if (formFieldList[i]['label'] === EmploymentConfig.AGENCY_NAME) {
                isAgencyExtForm = true;
                break;
              }
            }
          }
        }
      }
    }
    return isAgencyExtForm;
  }

  getCustomFormData() {
    let formFieldList = [];
    /*
     * there is a chance that 'forms' key can have 'candidate_form_fields_list' key & in that case
     * value for 'candidate_form_fields_list' key will be returned
    */
    if (this.employmentDataConfig['forms'].length > 0) {
      for (let i in this.employmentDataConfig['forms']) {
        if (this.employmentDataConfig['forms'][i]['candidate_form_fields_list'] &&
          this.employmentDataConfig['forms'][i]['candidate_form_fields_list'].length > 0) {
          formFieldList.push(this._ds.deepCopy(this.employmentDataConfig['forms'][i]));
        }
      }
    }
    return (formFieldList === undefined) ? this.employmentDataConfig['forms'] : formFieldList;
  }

  monthNames: any = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  geoOption: Object = {
    country: {},
    address: {
      types: ['address'],
      componentRestrictions: { country: "US" }
    },
    state: {
      types: ['(regions)'],
      componentRestrictions: { country: "US" }
    },
    city: {
      types: ['(cities)'],
      componentRestrictions: { country: "US" }
    }
  };

  getUserDetails: any = {
    firstname: "Carl1"
  };

  createNoPriorNoCurrentPayload(currentObj): Object {
    let obj = {};
    obj['type'] = currentObj['type'];
    obj['countryCode'] = currentObj['countryCode'];
    obj['countryName'] = currentObj['countryName'];
    obj['priorEmploymentConfirm'] = currentObj['priorEmploymentConfirm'];
    obj['COMPONENT_STATE'] = currentObj['COMPONENT_STATE'];
    return obj;
  }

  fromDateOptions(date?): Object {
    let now = this.now;
    let defaultDate = now;
    if (!!date) {
      if (typeof date !== "number" && date.indexOf(" ") > -1) { // Firefox hack
        date = this.formatDate(date);
      }
      defaultDate = new Date(date);
    }

    let from: Object = {};
    from['minDate'] = new Date(now.getFullYear() - 100, now.getMonth(), now.getDate());
    from['maxDate'] = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate());
    from['defaultDate'] = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate());
    // from['setDate'] = null;
    from['setDate'] = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate());
    return from;
  }


  toDateOptions(date?): Object {
    let now = this.now;
    let defaultDate = now;
    if (!!date) {
      if (typeof date !== "number" && date.indexOf(" ") > -1) { // Firefox hack
        date = this.formatDate(date);
      }
      defaultDate = new Date(date);
    }
    let to: Object = {};
    to['minDate'] = new Date(now.getFullYear() - 100, now.getMonth(), now.getDate());
    to['maxDate'] = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate());
    to['defaultDate'] = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate());
    to['setDate'] = new Date(defaultDate.getFullYear(), defaultDate.getMonth(), defaultDate.getDate());
    return to;
  }

  getMinimumScopeInMonths(requriedUnit?: string): number {
    let min = 0;
    let unit = 'YEARS';
    let minimumYearInMonths: number;
    let minimumYears: number;

    let isCandidateAgeBasedScope = (this.localStorage.getItem('is_candidate_age_based_scope') == 'Y' ? true : false);
    let validateScope = !!this.employmentDataConfig['scopes'] && !!this.employmentDataConfig['scopes'][0];

    if (validateScope && isCandidateAgeBasedScope && this.ageBasedScope !== null) {
      let years = this.ageBasedScope['years'];
      let months = this.ageBasedScope['months'];
      if (years <= 0 && months <= 0) {
        minimumYearInMonths = 0;
      } else {
        minimumYearInMonths = years * 12 + months;
      }
      return minimumYearInMonths;
    } else {
      if (validateScope) {
        min = this.employmentDataConfig['scopes'][0]['min'] || min;
        unit = this.employmentDataConfig['scopes'][0]['unit'] || unit;
      }

      if (unit === 'MONTHS') {
        minimumYearInMonths = min;
        minimumYears = min / 12;
      } else {
        minimumYearInMonths = min * 12;
        minimumYears = min;
      }
      if (requriedUnit !== undefined && requriedUnit === 'YEARS') {
        return minimumYears;
      } else {
        return minimumYearInMonths;
      }
    }
  }

  monthDiff(date): number {
    let months: number;
    let d2: any = new Date();
    let d1: any = new Date(date);
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= parseInt(d1.getMonth(), 10) + 1;
    months += parseInt(d2.getMonth(), 10) + 1;
    //console.log(months);
    return months <= 0 ? months : months;
  }


  monthDiffOfDates(date1, date2): number {
    let months: number;
    let d2: any = new Date(date2);
    let d1: any = new Date(date1);
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= parseInt(d1.getMonth(), 10) + 1;
    months += parseInt(d2.getMonth(), 10) + 1;
    //console.log(months);
    return months <= 0 ? months : months;
  }

  formatDate(d): any {
    if (d !== undefined || d !== null) {
      if (d.indexOf(' ') > -1) {
        let monthYear = d.split(' ');
        let month = (this.monthNames.indexOf(monthYear[0]) + 1).toString();
        let year: string;
        if (monthYear.length === 3) {
          year = monthYear[1] + monthYear[2];
        } else {
          year = monthYear[1];
        }
        let date = '15';
        if (year.indexOf(",") !== -1) {
          date = year.split(",")[0];
          year = year.split(",")[1];
        }
        let formatedDate = `${year}/${month}/${date}`;
        return formatedDate;
      }
    }
  }

  getLabelDate(date) {
    if (date) {
      let dte = date.replace(/-/g, '/');
      let dt = new Date(dte);
      let dateformat = this.monthNames[dt.getMonth()] + ' ' + dt.getFullYear();
      return dateformat;

    }
  }

  getLangDateToTs(date): number {
    let dateArr: any[];
    let monthNo: any;
    let year: any;
    let dateStr: any;
    let ts: number;

    if (date) {
      if (date.indexOf(" ")) {
        dateArr = date.split(" ");
        monthNo = (this.langMonthNames.indexOf((date.split(" ")[0])) + 1).toString();
        if (monthNo <= 0) {
          monthNo = (this.monthNames.indexOf((date.split(" ")[0])) + 1).toString();
        }
        if (dateArr.length == 3) {
          year = dateArr[1] + dateArr[2];
        } else {
          year = date.split(" ")[1];
        }
        let day = 15;
        if (year.indexOf(",") !== -1) {
          day = year.split(",")[0];
          year = year.split(",")[1];
        }
        dateStr = `${year}/${monthNo}/${day}`;
      }
    }
    ts = new Date(dateStr).getTime();
    //  console.log("time stamp conversion:");
    //  console.log(ts);
    //  debugger;
    return ts;
  }

  getDateToTs(date): number {
    let dateArr: any[];
    let monthNo: any;
    let year: any;
    let dateStr: any;
    let ts: number;
    if (date) {
      if (date.indexOf(" ")) {
        dateArr = date.split(" ");
        monthNo = (this.langMonthNames.indexOf((date.split(" ")[0])) + 1).toString();
        if (monthNo <= 0) {
          monthNo = (this.monthNames.indexOf((date.split(" ")[0])) + 1).toString();
        }
        if (dateArr.length == 3) {
          year = dateArr[1] + dateArr[2];
        } else {
          year = date.split(" ")[1];
        }
        let day = 15;
        if (year.indexOf(",") !== -1) {
          day = year.split(",")[0];
          year = year.split(",")[1];
        }
        dateStr = `${year}/${monthNo}/${day}`;
      }
    }
    ts = new Date(dateStr).getTime();
    //  console.log("time stamp conversion:");
    //  console.log(ts);
    //  debugger;
    return ts;
  }

  dateFormatYyyyMmDd(date) {
    let monthNames: any = this.monthNames;
    let monthNo = (monthNames.indexOf(date.split(" ")[0]) + 1).toString();
    let formatedDate = new Date(date.split(" ")[1] + "/" + monthNo + "/01");
    let dateObj: any = new Date(formatedDate);
    let year: any = dateObj.getFullYear();
    let dd: any = parseInt(dateObj.getDate(), 10);
    let month: any = (parseInt(dateObj.getMonth(), 10) + 1);
    month = (month <= 9) ? "0" + month : month;
    dd = (dd <= 9) ? "0" + dd : dd;
    let toDateFormat = year + "-" + month + "-" + dd;
    return toDateFormat;
  }

  getNextURL(): string {
    return this.nextURL;
  }

  getNextIntlURL(): string {
    return this.nextIntlURL;
  }

  getNextDisplayName() {
    return this.nextDisplayname;
  }

  getData(fetchFromProfile: boolean): Observable<any> {
    //let action = this.employmentDataConfig['action'];
    //console.log("************************ "+AppConfig.API_ENDPOINT() + action);
    let actionURL = this.action;

    if (fetchFromProfile) {
      actionURL = actionURL + '-all';
    }
    return this._authHttp.get(AppConfig.API_ENDPOINT() + actionURL + '/?t=' + new Date(), this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this._handleError(err, 'emp_get_failed'))
      );
  }

  checkRuleSetForIntlCountry(obj): Observable<any> {
    let emp_id = obj['emp_id'];
    let actionURL = '/api/v1/workflow/' + this.workflow_instance_id + '/international-step/' + emp_id + '/employment-step';
    return this._authHttp.get(AppConfig.API_ENDPOINT() + actionURL + '/?t=' + new Date(), this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this._handleError(err, 'emp_ruleSet_get_failed'))
      );
  }

  getIntlAdditionalFields(obj): Observable<any> {
    let actionURL = '/api/v1/workflow/' + this.workflow_instance_id + '/' + obj['country'] + '/EMPLOYMENT' + '/international-step' + '/?recordid=' + obj['id'];
    return this._authHttp.get(AppConfig.API_ENDPOINT() + actionURL + '&t=' + new Date(), this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this._handleError(err, 'emp_intlAddl_fields_get_failed'))
      );
  }

  getGapdata(): Observable<any> {
    //let action = this.employmentDataConfig['action'];
    //console.log("************************ "+AppConfig.API_ENDPOINT() + action);
    // let reviewedit = revieweditdata;

    return this._authHttp.get(AppConfig.API_ENDPOINT() + this.action + '/validate' + '/?t=' + new Date(), this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this._handleError(err, 'emp_gap_get_failed'))
      );
  }

  getGapDataReviewedit(): Observable<any> {
    //let action = this.employmentDataConfig['action'];
    //console.log("************************ "+AppConfig.API_ENDPOINT() + action);
    let reviewedit = '/?mode=RE';

    return this._authHttp.get(AppConfig.API_ENDPOINT() + this.action + '/validate' + reviewedit + '&t=' + new Date(), this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this._handleError(err, 'emp_gap_reviewEdit_get_failed'))
      );
  }

  deleteData(id): Observable<any> {
    //let action = this.employmentDataConfig['action'];
    return this._authHttp.delete(AppConfig.API_ENDPOINT() + this.action + '/' + id, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this._handleError(err, 'emp_delete_failed'))
      );
  }

  save(body): Observable<any> {

    //Add EMPLOYMENT COMPLETED Header for AppD trace
    if(body['employment-data-list'][0]['state'] !== undefined
      && body['employment-data-list'][0]['state']['current_stage'] === 'employment-data-v1'
      && body['employment-data-list'][0]['state']['stage_status'] === 'COMPLETED'){
      let traces = {};
      traces['action'] = 'EmploymentCompleted';
      this.headerOptions = this._workflow.getHeaderOptionsWithTraces(traces);
    }

    //let action = this.employmentDataConfig['action'];
    return this._authHttp.put(AppConfig.API_ENDPOINT() + this.action, body, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this._handleError(err, 'emp_put_failed'))
      );
  }

  softDeleteDataItemOnATSSelection(id: string) {
    console.log(AppConfig.API_ENDPOINT() + '/api/v1/workflow/' + id);

    return this._authHttp.delete(AppConfig.API_ENDPOINT() + this.action + '/' + id, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this._handleError(err, 'emp_deleteOn_atsSelection_failed'))
      );
  }

  getAllCurrentJob(data: Object[]) {
    //console.log('employmentDataListDummy');
    let serverData = (JSON.parse(JSON.stringify(data)));
    let currentItemArr: Object[] = [];
    currentItemArr = serverData.filter(obj => {
      return obj['type'] == "current";
    });
    //console.log(currentItemArr);
    return currentItemArr;
  }

  getCandidateAgeBasedScope(): Observable<any> {
    let action = '/api/v1/workflow/' + this.workflow_instance_id + '/candidate-age-based-scope';

    return this._authHttp.get(AppConfig.API_ENDPOINT() + action + '/?t=' + new Date(), this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this._handleError(err, 'emp_dob_get_failed'))
      );
  }

  getAllPriorJob(data: Object[]) {
    //console.log('employmentDataListDummy');
    let serverData = (JSON.parse(JSON.stringify(data)));
    let currentItemArr: Object[] = [];
    currentItemArr = serverData.filter(obj => {
      return obj['type'] == "prior";
    });
    //console.log(currentItemArr);
    return currentItemArr;
  }

  findMinimumPriorJobObj(data: Object[]): Object {
    let serverData = (JSON.parse(JSON.stringify(data)));
    let minimumPriorJob: Object = null;
    let onlyPriorJobs: Object[] = this.getAllPriorJob(serverData);

    if (onlyPriorJobs.length > 0) {
      onlyPriorJobs.sort(this.sortDataByStartDate);
      minimumPriorJob = onlyPriorJobs[0];
      minimumPriorJob['jobFrom'] = this.getFormatedDate(minimumPriorJob['start-date'], this.getDateFormat());
      minimumPriorJob['jobTo'] = this.getFormatedDate(minimumPriorJob['end-date'], this.getDateFormat());
    }

    return minimumPriorJob;
  }

  findMinimumJob(data: Object[]): Object {
    let serverData = (JSON.parse(JSON.stringify(data)));
    let minimumJob: Object = null;
    serverData.sort(this.sortDataByStartDate);
    minimumJob = serverData[0];
    minimumJob['jobFrom'] = this.getFormatedDate(minimumJob['start-date'], this.getDateFormat());

    if (!!minimumJob['end-date']) {
      minimumJob['jobTo'] = this.getFormatedDate(minimumJob['end-date'], this.getDateFormat());
    }

    return minimumJob;
  }

  findMinimumCurrentJobObj(data: Object[]): Object {
    let minimumCurrentJob: Object = null;
    let onlyCurrentJobs: Object[] = this.getAllCurrentJob(data);

    if (onlyCurrentJobs.length > 0) {
      onlyCurrentJobs.sort(this.sortDataByStartDate);
      minimumCurrentJob = onlyCurrentJobs[0];
      minimumCurrentJob['jobFrom'] = this.getFormatedDate(minimumCurrentJob['start-date'], this.getDateFormat());
    }

    return minimumCurrentJob;
  }

  sortDataByStartDate(a, b) {
    return a['start-date'] - b['start-date'];
  }

  getGeoOptions(): Object {
    return this.geoOption;
  }

  setstateShortname(data) {
    EmploymentService.stateShortname = data;
  }

  getstateShortname() {
    return EmploymentService.stateShortname;
  }

  getEmpContactEaPreference() {
    return this.getEaPreferenceValueByKey(this.employmentDataConfig['ea-preferences'], EmploymentConfig.EMP_FUTURE_CONTACT_KEY);
  }

  getEmpContactTimeFrame() {
    let value = this.getEaPreferenceValueByKey(this.employmentDataConfig['ea-preferences'], EmploymentConfig.EMP_FUTURE_CONTACT_TIME_FRAME_KEY);

    return value = (value !== undefined && value !== null) ? value : EmploymentConfig.EMP_CONTACT_DEFAULT_TIME_FRAME;
  }

  getMultiPositionPreference() {
    let value = this._util.getPaPreferenceValueByKey(this.employmentDataConfig['preferences'], EmploymentConfig.EMPLOYMENT_MULTI_POSITION);
    let isMultiPositionEnabled: boolean = false;
    if (value) {
      isMultiPositionEnabled = JSON.parse(value);
    }
    return isMultiPositionEnabled;
  }


  getEaPreferenceValueByKey(preferences: Array<{}>, key: string): any {
    let value = null;

    for (let prefer of preferences) {
      if (prefer['id'] === key) {
        if (prefer['value'] !== undefined && prefer['value'] !== null && prefer['value'] !== '') {
          value = prefer['value'];
        }
        break;
      }
    }
    return value;
  }

  private _extractData(res: any) {
    //debugger;
    return res;
  }

  private _doAction(response: any) {
    //debugger;
    return response;
  }

  private _handleError(error: any, eventName) {
    let _body = error || {};
    let errorMessage: string;

    console['server'](eventName, error);

    switch (error.status) {
      case 400:
      case 401:
        //Bad request, Invalid Credentials - login invalid
        errorMessage = _body['error']['error'] || 'Invalid Login';
        break;
      case 404:
        break;
      case 412:
        errorMessage = "412";
        break;
    }
    return throwError(errorMessage);
  }

  mapToOption(obj: Object): Object {
    let options = {};
    if (!!obj) {
      options = obj;
      options['countryName'] = obj['country_name'] || options['countryName'] || "";
      options['companyName'] = obj['name'] || obj['companyName'] || "";
      options['countryCode'] = options['countryShortCode'] = obj['country'] || obj['countryCode'] || "";

      options['stateName'] = obj['state_name'] || obj['stateName'] || "";
      options['stateCode'] = obj['state_code'] || obj['stateCode'] || "";
      options['zipCode'] = obj['zip'] || options['zipCode'] || "";
      //debugger;
      if (!!obj['start-date'] && !obj['jobFrom']) {
        options['jobFrom'] = this.getFormatedDate(obj['start-date'], this.getDateFormat());
      }

      if (!!obj['end-date'] && !obj['jobTo']) {
        options['jobTo'] = this.getFormatedDate(obj['end-date'], this.getDateFormat());
      }

      if (!!obj['permission-to-contact'] && !obj['permissionToContact']) {
        options['permissionToContact'] = obj['permission-to-contact'];
      }

      if (!!obj['job-title'] && !obj['jobTitle']) {
        options['jobTitle'] = obj['job-title'];
      }
    }

    //debugger;
    return options;
  }

  setServerData(data) {
    this.serverData = data;
  }

  getServerData(): Object[] {
    return this.serverData;
  }

  setAgeBasedScope(data) {
    this.ageBasedScope = data;
  }

  getAgeBasedScope(): Object {
    return this.ageBasedScope;
  }

  filterObjById(id): any {
    let serverData = this.serverData;
    let currentItemArr = serverData.filter(obj => {
      return obj['id'] == id;
    });
    return currentItemArr[0] || null;
  }

  setRecordIdCollection(key, recordId) {
    this.recordIdObj[key] = recordId;
  }

  setCurrentJobObjCollection(key, obj) {
    if (!this.currentJobObj) {
      this.currentJobObj = {};
    }
    this.currentJobObj[key] = obj;
  }

  afterSaveUpdateServerData(item) {

    let serverData = this.serverData;
    let serverDataLen = serverData.length;

    if (serverDataLen > 0) {
      let curObjectId = item['id'];

      let currentItemArr = serverData.filter(obj => {
        return obj['id'] == curObjectId || null;
      });

      if (currentItemArr && currentItemArr.length > 0) {
        let currentItem = currentItemArr[0];
        let currentItemIndex = serverData.indexOf(currentItem);
        serverData[currentItemIndex] = item;
        this.setServerData(serverData);
      } else {
        serverData.push(item);
        this.setServerData(serverData);
      }
    } else {
      serverData.push(item);
      this.setServerData(serverData);
    }
  }

  private convertCamelCase(str) {
    let DEFAULT_REGEX = /[-_]+(.)?/g;
    function toUpper(match, group1) {
      return group1 ? group1.toUpperCase() : '';
    }

    return function () {
      return str.replace(DEFAULT_REGEX, toUpper);
    };
  }

  getAfterSaveFunctionName(component: string): string {
    let transformedName: string = "";
    component = `after--${component}-save`;
    transformedName = this.convertCamelCase(component)();
    return transformedName;
  }

  /**
   * validate is self employment check is enabled or not.
   *
   * selected country should be noo-us
   * ea-preference should contains the code of Self-Employment Check Required (1204) - YES
   */
  isSelfEmploymentCheckRequired(empType: string, countryCode: string): boolean {
    let value = this.getEaPreferenceValueByKey(this.employmentDataConfig['ea-preferences'], EmploymentConfig.SELF_EMPLOYMENT_CHECK_REQUIRED_KEY);
    if (!this._util.isEmpty(empType) && empType === 'selfEmployed'
      && !this._util.isEmpty(countryCode) && countryCode !== AppConfig.APP_CONSTANTS.DOMESTIC_COUNTRY_CODE
      && !this._util.isEmpty(value) && value === 'YES') {
      return true;
    }

    return false;
  }

  /**
   * check self employment preference is enabled or not.
   */
  isSelfEmploymentCheckEnabled() {
    let value = this.getEaPreferenceValueByKey(this.employmentDataConfig['ea-preferences'], EmploymentConfig.SELF_EMPLOYMENT_CHECK_REQUIRED_KEY);
    if (!!value && value === 'YES') {
      return true;
    } else {
      return false;
    }
  }

  isFaaSafetyCheckEnabled() {
    let value = this.getEaPreferenceValueByKey(this.employmentDataConfig['ea-preferences'], EmploymentConfig.FAA_SAFETY_CHECK_REQUIRED_KEY);
    if (!!value && value === 'YES') {
      return true;
    } else {
      return false;
    }
  }

  /**
 * check DOT LIcense preference is enabled or not.
 */
  isDotLicensePreferenceEnabled() {
    let value = this.getEaPreferenceValueByKey(this.employmentDataConfig['ea-preferences'], EmploymentConfig.DOT_LICENCE_PREFERENCE_KEY);
    if (!!value && value === 'YES') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * check employment gap in days is enabled or not
   *
   * @returns
   */
  isEmploymentGapInDaysEnabled() {
    let isEmpGapInDaysEnabled = false;
    let value = this.getEaPreferenceValueByKey(this.employmentDataConfig['ea-preferences'], EmploymentConfig.EMPLOYMENT_GAP_IN_DAYS);
    var pattern = new RegExp('^[0-9]+$');
    if (!!value && pattern.test(value)) {
      value = Number(value);
      if (!isNaN(value) && value > 0) {
        isEmpGapInDaysEnabled = true;
      }
    }
    return isEmpGapInDaysEnabled;
  }

  isPresentEmpAutoFulfilment() {
    let value = this.getEaPreferenceValueByKey(this.employmentDataConfig['ea-preferences'], EmploymentConfig.PRESENT_EMP_AUTO_FULFILMENT);
    if (!!value && value === 'YES') {
      return true;
    } else {
      return false;
    }
  }

  isPlaidPreferenceEnabled() {
    let value = this.getEaPreferenceValueByKey(this.employmentDataConfig['ea-preferences'], EmploymentConfig.PRESENT_EMP_PLAID_PREF);
    if (!!value && value === 'YES') {
      return true;
    } else {
      return false;
    }
  }

  public getLangFormatedDate(date, format?) {
    if (date) {

      let dateObj = new Date(date);
      if (!format) {
        let dateformat = this.langMonthNames[dateObj.getMonth()] + ' ' + dateObj.getDate() + ', ' + dateObj.getFullYear();
        return dateformat;
      }

      if (format === 'MMMM YYYY') {
        let dateformat = this.langMonthNames[dateObj.getMonth()] + ' ' + dateObj.getFullYear();
        return dateformat;
      }

      if (format === 'dd MMMM YYYY') {
        let dateformat = this.langMonthNames[dateObj.getMonth()] + ' ' + dateObj.getDate() + ' ' + dateObj.getFullYear();
        return dateformat;
      }

      if (format === 'MMMM dd, YYYY') {
        let dateformat = this.langMonthNames[dateObj.getMonth()] + ' ' + dateObj.getDate() + ' , ' + dateObj.getFullYear();
        return dateformat;
      }

      if (format === 'MMMM dd,YYYY') {
        let dateformat = this.langMonthNames[dateObj.getMonth()] + ' ' + dateObj.getDate() + ', ' + dateObj.getFullYear();
        return dateformat;
      }
    }
  }

  public getFormatedDate(date, format?) {
    if (date) {
      let dateObj = new Date(date);
      if (!format) {
        let dateformat = this.monthNames[dateObj.getMonth()] + ' ' + dateObj.getDate() + ', ' + dateObj.getFullYear();
        return dateformat;
      }

      if (format === 'MMMM YYYY') {
        let dateformat = this.monthNames[dateObj.getMonth()] + ' ' + dateObj.getFullYear();
        return dateformat;
      }

      if (format === 'dd MMMM YYYY') {
        let dateformat = this.monthNames[dateObj.getMonth()] + ' ' + dateObj.getDate() + ' ' + dateObj.getFullYear();
        return dateformat;
      }

      if (format === 'MMMM dd, YYYY') {
        let dateformat = this.monthNames[dateObj.getMonth()] + ' ' + dateObj.getDate() + ' , ' + dateObj.getFullYear();
        return dateformat;
      }
      if (format === 'MMMM dd,YYYY') {
        let dateformat = this.monthNames[dateObj.getMonth()] + ' ' + dateObj.getDate() + ',' + dateObj.getFullYear();
        return dateformat;
      }
    }
  }

  /**
   * Set employment categories kay and value pair
   *
   * @param categories - array of professional types
   */
  setCategories(categories: any) {
    if (!!categories && categories.length > 0) {
      for (let category of categories) {
        EmploymentService.employmentCategories[category.key] = category.value;
      }
    }
  }

  /**
   * Get description of given employment category
   * Returns given key if description not found.
   *
   * @param category
   */
  getCategory(category: string) {
    if (EmploymentService.employmentCategories.hasOwnProperty(category)) {
      return EmploymentService.employmentCategories[category];
    }
    return category;
  }

  getDateFormat() {
    if (this.isEmploymentGapInDaysEnabled()) {
      return 'MMMM dd,YYYY';
    } else {
      return 'MMMM YYYY';
    }
  }

  /**
  * Check Is Never employed preference enabled or not.
  */
  isCategoryNeverEmployedPreferenceEnabled() {
    let value = this.getEaPreferenceValueByKey(this.employmentDataConfig['ea-preferences'], EmploymentConfig.EMPLOYMENT_CATEGORY_NEVER_EMPLOYED);
    if (!!value && value === 'YES') {
      return true;
    } else {
      return false;
    }
  }

  /**
  * Check Phone and email optional for employer.1440.
 */
  employerPhoneEmailOptionalPreferences() {
    let value = this.getEaPreferenceValueByKey(this.employmentDataConfig['ea-preferences'], EmploymentConfig.EMPLOYER_PHONE_EMAIL_OPTIONAL_PREF);
    const employerPhoneEmailOptional = {};
    if (!!value && value.length > 0) {
      if (value.indexOf(EmploymentConfig.EMPLOYER_PHONE_OPTIONAL_PREF_VALUE) >= 0) {
        employerPhoneEmailOptional['employer_phone_optional'] = true;
      }
      if (value.indexOf(EmploymentConfig.EMPLOYER_EMAIL_OPTIONAL_PREF_VALUE) >= 0) {
        employerPhoneEmailOptional['employer_email_optional'] = true;
      }
    }
    return employerPhoneEmailOptional;
  }

  //Replaces start-date and end-date with dates build with from_date and to_date.
  useFromAndToDate(employmentList: any) {
    if (!!employmentList) {
      for (let i = 0; i < employmentList.length; i++) {
        let emp = employmentList[i];
        let from_date = emp['from_date'];
        let to_date = emp['to_date'];
        if (!!from_date) {
          emp['start-date'] = dateUtils.getDateFromYYYYMMDD(from_date).getTime();
        }
        if (!!to_date) {
          emp['end-date'] = dateUtils.getDateFromYYYYMMDD(to_date).getTime();
        }
        let postionList = emp['employment_position_list'];
        if (!!postionList && postionList.length > 0) {
          this.useFromAndToDate(postionList);
        }
      }
    }
  }

  reportingManagerSkip(){
    let value = this.getEaPreferenceValueByKey(this.employmentDataConfig['ea-preferences'],EmploymentConfig.REPORTING_MANAGER_SKIP);
    let reportingManagerSkip
    if(!!value && value.length >0 && value.toUpperCase() == "YES"){
        reportingManagerSkip = true;
      }
      else{
        reportingManagerSkip = false;
      }
    return reportingManagerSkip;
  }
  
  public fetchPlaidLinkToken(value): Observable<PlaidSignInToken> {
    let body = {
      "invite_key": value["inviteKey"],
      "invite_id": value["inviteId"],
      "request_id": value['requestId']
    };
    
    return this._authHttp.post(AppConfig.API_ENDPOINT() + '/api/v1/workflow/' + this.workflow_instance_id + '/employment-step/plaid/initialize', body, this.headerOptions)
      .pipe(
        map(res => this._extractData(res)),
        map(res => this._doAction(res)),
        catchError(err => this._handleError(err, 'emp_get_link_token_failed'))
      );      
  }
  
  public updatePublicToken(body): Observable<any> {
    return this._authHttp.put(AppConfig.API_ENDPOINT() + '/api/v1/workflow/' + this.workflow_instance_id + '/employment-step/plaid/submit', body, this.headerOptions)
    .pipe(
      map(res => this._extractData(res)),
      map(res => this._doAction(res)),
      catchError(err => this._handleError(err, 'emp_put_data_provider_failed'))
    ); 
  }
}
