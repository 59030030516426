import { Component, ViewContainerRef, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/fieldconfig.interface';
import { SharedService } from 'app/shared/services/shared.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { LocalStorageService } from 'framework/localstorage.service';
import { InternationalService } from 'app/international/international.service';

declare var window: any;


@Component({
  selector: 'form-html-label',
  styleUrls: ['form-html-label.component.scss'],
  templateUrl: './form-html-label.component.html'
})
export class FormHtmlLabelComponent implements Field, OnInit {
  config: FieldConfig;
  group: FormGroup;
  displayLabel: string;
  remoteId: string;
  fileName: string;
  location: string;

  constructor(private _sharedService: SharedService,
    private _intlService: InternationalService,
    private localStorage: LocalStorageService) {
  }

  ngOnInit() {
    let auMedDoc = this.localStorage.getItem('au_doc_details');
    if(auMedDoc !== undefined && auMedDoc['auRemoteId'] !== undefined && auMedDoc['auFileName'] !== undefined && auMedDoc['auLocation'] !== undefined){
      this.remoteId = auMedDoc['auRemoteId'];
      this.fileName = auMedDoc['auFileName'];
      this.location = auMedDoc['auLocation'];
    }
    let input: string = (this.config !== undefined && this.config['text'] !== undefined) ? this.config['text'] : this.config['label'];
    if (this.config !== undefined && this.config['label'] !== undefined && (this.config['label'] === 'EMPTY_FIELD_1' || this.config['label'] === 'EMPTY_FIELD_2' || this.config['label'] === 'CONFIRMATION_TEXT' )) {
      input = '';
    }
    this.displayLabel = input;
  }

  public downloadProcess(id, filename, locationCode) {
    if(id !== undefined && filename !== undefined && locationCode !== undefined){
    if (filename.indexOf('.pdf') < 0)
      filename = filename + '.pdf';

    this._intlService.downloadFile(id, locationCode).subscribe(blob => {

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        importedSaveAs(blob, filename);
      }
    }, error => {
    });
  }
  }

}
