import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from './../../app/app.config';
import { WorkflowService } from '../../framework/workflow.service';
import { LocalStorageService } from '../../framework/localstorage.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from './../../environments/environment';


declare var jQuery: any;
declare var window: any;

@Injectable()
export class ExceptionService {
  headerOptions: any;

  constructor(private _authHttp: HttpClient,
    private _workflow: WorkflowService,
    private localStorage: LocalStorageService) {
    this.headerOptions = this._workflow.getHeaderOptions();

    var console = window.console;

    console.server = (eventName, error, optionalData?) => {
      this.logError(eventName, error, optionalData);
    };
    // console.log('Console.server attached');
  }

  public logError(eventName, error: any, optionalData?): void {
    optionalData = !!optionalData ? optionalData : '';
    let inviteId = this.localStorage.getItem('inviteId');
    console.log(error);

    this.sendToServer(eventName, error, optionalData).subscribe(res => {
    });

    // // Only show modal if debug flag is set and only for QCA
    // if (environment.global_error_show && inviteId !== undefined && inviteId !== '')
    //   jQuery('#error-modal').modal('show');
  }

  private sendToConsole(error: any): void {
    if (console && console.group && console.error) {
      console.group('Error Log Service');
      console.error(error);

      if (error !== undefined) {
        console.error(error.message);
        console.error(error.stack);
      }
      console.groupEnd();
    }
  }

  public sendToServer(eventName, error: any, optionalData): Observable<Object> {
    let workflowId = this.localStorage.getItem('workflow_instance_id');
    let profileId = this.localStorage.getItem('profile_id');
    let inviteId = this.localStorage.getItem('inviteId');
    let moduleName = eventName;
    let errorMessage = error !== undefined ? error.message : "";
    let errorStack = ( error && error.stack ) ? error.stack : "";
    let localStorageData = this.localStorage.getLocalStorage();
    let modifiedLocalStorageData = {};

    for (let key in localStorageData) {
      modifiedLocalStorageData[key] = localStorageData[key];
    }

    if (!!modifiedLocalStorageData) {
      if(!!modifiedLocalStorageData['access_token']) {
        delete modifiedLocalStorageData['access_token'];
      }

      if (!!modifiedLocalStorageData['availableLanguageList']) {
        delete modifiedLocalStorageData['availableLanguageList']
      }
    }

    let requestPayload = {
      'InviteId': inviteId,
      'Stack': errorStack,
      'localStorage': modifiedLocalStorageData,
      'optionalData': optionalData
    }

    let body = {
      'request-payload': JSON.stringify(requestPayload),
      'module': moduleName,
      'workflow-instance-id': workflowId,
      'profile-id': profileId
    };

    this.headerOptions = this._workflow.getHeaderOptions();

    let endUrl = AppConfig.API_ENDPOINT() + AppConfig.LOG_API_ENDPOINT();

    return this._authHttp.put(endUrl, body)
      .pipe(
        map(res => this._extractData),
        map(data => this._doAction),
        catchError(this._handleError)
      );
  }

  private _extractData(res: any) {
    return res;
  }

  private _doAction(response: any) {
    return response;
  }

  private _handleError(error: any) {
    // Unable to send to server only console.log
    let _body = error || {};
    let errorMessage: string;

    if (error !== undefined) {
      switch (error.status) {
        case 400:
        case 401:
          errorMessage = _body['error']['error'] || 'Invalid track error request';
          break;
        case 404:
          break;
        default:
          break;
      }
    }

    return throwError(errorMessage);
  }

  // declare and attach in toString, trackevent attached to window.trackevent
}
