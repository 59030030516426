import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { OidcLoginComponent } from './oidc-login.component';
import { SharedModule } from './../shared/shared.module';
import {LoginModule} from '../login/login.module';

@NgModule({
  imports: [FormsModule, SharedModule, LoginModule],
  declarations: [OidcLoginComponent],
  providers: [],
  exports: [OidcLoginComponent],
})

export class OidcLoginModule {

}
