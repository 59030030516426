/*
 * This service will have two tasks -
 *  1. Collecting external data from External Sources like ATS
 *  2. Processing that data to create a GLOBAL Data object to be consumed by any module/component
*/

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AppConfig } from './../../../../../app/app.config';
import { WorkflowService } from './../../../../../framework/workflow.service';
import { LocalStorageService } from '../../../../../framework/localstorage.service';
import { throwError } from 'rxjs';

@Injectable()
export class ImportDataService {
    headerOptions: any;
    importDataConfig: Object;
    nameDataConfig: Object;
    barcodeTimeout: number;

    constructor(private _authHttp: HttpClient,
        private _workflow: WorkflowService,
        private localStorage: LocalStorageService) {
        this.headerOptions = this._workflow.getHeaderOptions();
    }

    dynamicsteploading(currentslide: any) {
        this.importDataConfig = this._workflow.getStepConfig('main-data-v1', currentslide);
        // this.nameDataConfig = this._workflow.getStepConfig('main-data-v1', 2);
    }

    barcodeAPITimeout(stepName: string) {
        let stepObject;
        stepObject = this._workflow.getSteps(stepName);

        for (var i = 0; i < stepObject.length; i++) {
            if (stepObject[i]['name'] == 'auto-mvr-step-v1') {
                let preferenceObject;
                preferenceObject = stepObject[i]['preferences'];
                if (preferenceObject != null) {
                    this.barcodeTimeout = preferenceObject.timeout_in_seconds;
                }
            }
        }
    }

    submitData(base64Img): Observable<any> {
        const formData = new FormData();
        formData.append('image', base64Img);
        formData.append('types', 'drvlic');
        this.barcodeAPITimeout('main-data-v1');

        return this._authHttp.post(AppConfig.BARCODE_API_ENDPOINT(), formData, this.headerOptions)
        .pipe(
            map(res => this._extractData(res)),
            catchError(this._handleError)
            // timeout(this.barcodeTimeout * 1000); //Converted Second to Millisecond
        );
    }

    submitImage(imageData): Observable<any> {
        const formData = new FormData();
        formData.append('file[]', imageData, imageData.name);
        formData.append('types', 'drvlic');
        this.barcodeAPITimeout('main-data-v1');

        return this._authHttp.post(AppConfig.BARCODE_API_ENDPOINT(), formData, this.headerOptions)
        .pipe(
            map(res => this._extractData(res)),
            catchError(this._handleError)
            // .timeout(this.barcodeTimeout * 1000); //Converted Second to Millisecond
        );
    }

    private _extractData(response: any) {
        let res = response;

        if (res.Barcodes.length === 0) {
            return "No barcodes found";
        }

        return res;
    }

    private _handleError(error: any) {
        let _body = error || {};
        let errorMessage: string;

        switch (error.status) {
            case 400:
            case 401:
                //Bad request, Invalid Credentials - login invalid
                errorMessage = _body['error']['error'] || 'Problem in Consent Service';
                break;
            case 404:
                errorMessage = _body['error']['error'] || 'Not found';
                break;
        }
        return throwError(errorMessage);
    }

    navigationtopMobile() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            jQuery('html, body').animate({
                scrollTop: jQuery(' .status_bar, .body-bg').offset().top
            }, 2000);
        }
    }

    saveImportData(data): Observable<any> {
        let body = {};
        body["state"] = {};

        body["state"]["current_stage"] = data['name'];
        body["state"]["current_step"] = data['step'];
        body["state"]["current_step_index"] = data['stepIndex'];
        body["state"]["current_sub_step"] = data['subStep'];
        body["state"]["current_sub_step_index"] = data['subStepIndex'];
        body["state"]["current_status"] = data['status'];
        body["state"]["current_iteration"] = data['currentIteration'];
        body["state"]["dbRecordPresent"] = data['dbRecordPresent'];
        body["state"]["stage_status"] = data['stage_status'];

        let action = this.importDataConfig['action'];
        let workflowid = this.localStorage.getItem('workflow_instance_id');
        return this._authHttp.put(AppConfig.API_ENDPOINT() + '/api/v1/workflow/' + workflowid + '/auto-mvr-step', body, this.headerOptions)
            .pipe(
                map(res => this._extractJSONData(res)),
                map(res => this._doAction(res)),
                catchError(this._handleError)
            )
    }

    private _extractJSONData(res: any) {
        return res;
    }

    private _doAction(response: any) {
        return response;
    }

    save(body) {
        let workflowid = this.localStorage.getItem('workflow_instance_id');
        return this._authHttp.put(AppConfig.API_ENDPOINT() + '/api/v1/workflow/' + workflowid + '/auto-mvr-step', body, this.headerOptions)
            .pipe(
                map(res => this._extractNewData(res)),
                map(res => this._doNewAction(res)),
                catchError(this._handleNewError)
            )
    }

    private _extractNewData(res: any) {
        return res;
    }

    private _doNewAction(response: any) {
        return response;
    }

    private _handleNewError(error: any) {
        let _body = error || {};
        let errorMessage: string;

        switch (error.status) {
            case 400:
            case 401:
                //Bad request, Invalid Credentials - login invalid
                errorMessage = _body['error']['error'] || 'Invalid Login';
                break;
            case 404:
                break;
        }
        return throwError(errorMessage);
    }
}
