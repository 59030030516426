import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class EventBusService {
  // Observable string sources
  public static waitingIndicationEvent = new Subject<any>();
  private userLoggedIn = new Subject<any>();
  private userLoggedOut = new Subject<any>();
  private consentSigned = new Subject<any>();
  private statusbar = new Subject<any>();
  private savexit = new Subject<any>();
  private saveexitPaName = new Subject<any>();
  private nameSlideCalled = new Subject<any>();
  private arrowClicked = new Subject<any>();
  private consentDataFetched = new Subject<any>();
  private importdataclicked = new Subject<any>();
  private referencefirstname = new Subject<any>();
  private licenceTypeName = new Subject<any>();
  private mvrdecisionchange = new Subject<any>();
  private mvrdriverlicensevalidation = new Subject<any>();
  private resicountry = new Subject<any>();

  private dynamicComponentClicked = new Subject<any>();
  private customInstruction = new Subject<any>();
  private cmldecisionchange = new Subject<any>();  // PPA6270
  private dynamicFormConfig = new Subject<any>();  // WOTC -- PPA6423
  private wotcMenu = new Subject<any>(); // WOTC

  private consentMenu = new Subject<any>(); // Consent Menu
  private consentDisagree = new Subject<any>(); // Consent Disagree
  private locationPickerError = new Subject<any>();
  private wotcConsentclearSign = new Subject<any>();
  private wotcConsentSigned = new Subject<any>();
  private contactUsEvent = new Subject<any>();
  private dynamicFormRadioClicked = new Subject<any>();
  private dynamicFormCheckboxClicked = new Subject<any>();
  private dynamicDocumentActionClicked = new Subject<any>();

  private languageInInviteReceived = new Subject<any>();
  private languageListInInviteReceived = new Subject<any>();
  private hideTermsServices = new Subject<any>();
  private dobConfirmed = new Subject<any>();
  private atPaymentStage = new Subject<any>();
  private atConfirmStage = new Subject<any>();
  private dynamicFormCheckboxGenericClicked = new Subject<any>();
  private idSelectOptoutClicked = new Subject<any>();
  private tryAltMethodClicked = new Subject<any>();
  hideHeaderClicked = new Subject<any>();
  private phoneNumberValidationError = new Subject<any>();
  public static sessionInvalidReceived = new Subject<any>();
  private conditionalRadioClicked = new Subject<any>();
  private dynamicRadioClicked = new Subject<any>();
  private jobTypeSelected = new Subject<any>();
  private instructions = new Subject<any>();
  private formInputError = new Subject<any>();
  private hideHeaderMenu = new Subject<any>(); // Header Menu

  signatureModal = new Subject<any>();
  signatureModal$ = this.signatureModal.asObservable();

  signatureSaved = new Subject<any>();
  signatureSaved$ = this.signatureSaved.asObservable();
  companyNameSelected = new Subject<any>();
  companyNameSelected$ = this.companyNameSelected.asObservable();

  forceNotToShowTosReceived = new Subject<any>();
  forceNotToShowTosReceivedAnnounced$ = this.forceNotToShowTosReceived.asObservable();

  // Observable string streams
  logInAnnounced$ = this.userLoggedIn.asObservable();
  logOutAnnounced$ = this.userLoggedOut.asObservable();
  consentSignedAnnounced$ = this.consentSigned.asObservable();
  statusbardAnnounced$ = this.statusbar.asObservable();
  importAnnounced$ = this.importdataclicked.asObservable();
  referenceAnnounced$ = this.referencefirstname.asObservable();
  saveexitAnnounced$ = this.savexit.asObservable();
  saveexitPaNameAnnounced$ = this.saveexitPaName.asObservable();
  arrowClickedAnnounced$ = this.arrowClicked.asObservable();
  nameSlideCalledAnnounced$ = this.nameSlideCalled.asObservable();
  licenceAnnounced$ = this.licenceTypeName.asObservable();
  decisionchangeAnnounced$ = this.mvrdecisionchange.asObservable();
  mvrlicensevalidationAnnounced$ = this.mvrdriverlicensevalidation.asObservable();
  resicountryAnnounced$ = this.resicountry.asObservable();
  dynamicComponentAnnounced$ = this.dynamicComponentClicked.asObservable();
  customInstructionAnnounced$ = this.customInstruction.asObservable();
  waitingIndicationEventAnnounced$ = EventBusService.waitingIndicationEvent.asObservable();
  cmldecisionchangeAnnounced$ = this.cmldecisionchange.asObservable();  // PPA6270
  dynamicFormConfigAnnounced$ = this.dynamicFormConfig.asObservable();  // WOTC -- PPA6423
  wotcAnnounced$ = this.wotcMenu.asObservable(); // WOTC
  consentDisagreeAnnounced$ = this.consentDisagree.asObservable(); // Consent DisAgree
  wotcConsentclearSignAnnounced$ = this.wotcConsentclearSign.asObservable();
  wotcConsentSignedAnnounced$ = this.wotcConsentSigned.asObservable();
  locationPickerErrorAnnounced$ = this.locationPickerError.asObservable();
  consentAnnounced$ = this.consentMenu.asObservable();
  contactUsAnnounced$ = this.contactUsEvent.asObservable();
  dynamicFormRadioAnnounced$ = this.dynamicFormRadioClicked.asObservable();
  dynamicFormCheckboxAnnounced$ = this.dynamicFormCheckboxClicked.asObservable();
  dynamicDocumentActionClickedAnnounced$ = this.dynamicDocumentActionClicked.asObservable();
  languageInInviteReceived$ = this.languageInInviteReceived.asObservable();
  languageListInInviteReceivedAnnounced$ = this.languageListInInviteReceived.asObservable();
  announceTermsServicesHide$ = this.hideTermsServices.asObservable();
  announcedobConfirmed$ = this.dobConfirmed.asObservable();
  announcePaymentStage$ = this.atPaymentStage.asObservable();
  announceConfirmStage$ = this.atConfirmStage.asObservable();
  dynamicFormCheckBoxGenericAnnounced$ = this.dynamicFormCheckboxGenericClicked.asObservable();
  idselectOptoutAnnounced$ = this.idSelectOptoutClicked.asObservable();
  tryAltMethodAnnounced$ = this.tryAltMethodClicked.asObservable();
  hideHeaderAnnounced$ =  this.hideHeaderClicked.asObservable();
  phoneNumberValidationErrorAnnounced$ =  this.phoneNumberValidationError.asObservable();
  sessionInvalidReceivedAnnounced$ = EventBusService.sessionInvalidReceived.asObservable();
  conditionalRadioAnnounced$ = this.conditionalRadioClicked.asObservable();
  dynamicRadioClickedAnnounced$ = this.dynamicRadioClicked.asObservable();
  jobTypeSelectedAnnounced$ = this.jobTypeSelected.asObservable();
  instructions$ = this.instructions.asObservable();
  formInputErrorAnnounced$ = this.formInputError.asObservable();
  hideHeaderMenuAnnounced$ = this.hideHeaderMenu.asObservable();

  public static waitingIndicationEventAnnounced(mission: any) {
    EventBusService.waitingIndicationEvent.next(mission);
  }

  // Service message commands
  announceMission(mission: any) {
    this.userLoggedIn.next(mission);
  }

  logoutAnnounced(mission: any) {
    this.userLoggedOut.next(mission);
  }

  consentSignedAnnounced(mission: any) {
    this.consentSigned.next(mission);
  }

  statusbardAnnounced(mission: any) {
    this.statusbar.next(mission);
  }
  referenceAnnounced(mission: any) {
    this.referencefirstname.next(mission);
  }

  importAnnounced(mission: any) {
    this.importdataclicked.next(mission);
  }

  saveexitAnnounced(mission: any) {
    this.savexit.next(mission);
  }

  saveexitPaNameAnnounced(mission: any) {
    this.saveexitPaName.next(mission);
  }

  arrowClickedAnnounced(mission: any) {
    this.arrowClicked.next(mission);
  }

  nameSlideCalledAnnounced(mission: any) {
    this.nameSlideCalled.next(mission);
  }

  licenceAnnounced(mission: any) {
    this.licenceTypeName.next(mission);
  }

  // PPA6270---- start
  cmldecisionchangeAnnounced(mission: any) {
    this.cmldecisionchange.next(mission);
  } // PPA6270 -- end

  decisionchangeAnnounced(mission: any) {
    this.mvrdecisionchange.next(mission);
  }

  mvrlicensevalidationAnnounced(mission: any) {
    this.mvrdriverlicensevalidation.next(mission);
  }

  resicountryAnnounced(mission: any) {
    this.resicountry.next(mission);
  }

  dynamicComponentAnnounced(mission: any) {
    this.dynamicComponentClicked.next(mission);
  }

  customInstructionAnnounced(mission: any) {
    this.customInstruction.next(mission);
  }

  // WOTC-- PPA6423
  dynamicFormConfigAnnounced(mission: any) {
    this.dynamicFormConfig.next(mission);
  }

  // WOTC
  wotcAnnounced(mission: any) {
    this.wotcMenu.next(mission);
  }

  //Consent
  consentAnnounced(mission: any) {
    this.consentMenu.next(mission);
  }

  consentDisagreeAnnounced(mission: any) {
    this.consentDisagree.next(mission);
  }

  locationPickerErrorAnnounced(mission: any) {
    this.locationPickerError.next(mission);
  }

  wotcConsentclearSignAnnounced(mission: any) {
    this.wotcConsentclearSign.next(mission);
  }

  wotcConsentSignedAnnounced(mission: any) {
    this.wotcConsentSigned.next(mission);
  }

  contactUsAnnounced(mission: any) {
    this.contactUsEvent.next(mission);
  }

  dynamicFormRadioAnnounced(mission: any) {
    this.dynamicFormRadioClicked.next(mission);
  }

  dynamicFormCheckboxAnnounced(mission: any) {
    this.dynamicFormCheckboxClicked.next(mission);
  }

  dynamicDocumentActionClickedAnnounced(mission: any) {
    this.dynamicDocumentActionClicked.next(mission);
  }

  languageInInviteReceivedAnnounced(mission: any) {
    this.languageInInviteReceived.next(mission);
  }

  languageListInInviteReceivedAnnounced(mission: any) {
    this.languageListInInviteReceived.next(mission);
  }

  publishSignatureModal(data: any) {
    this.signatureModal.next(data);
  }

  publishSignatureSaved(data: any) {
    this.signatureSaved.next(data);
  }

  announceTermsServicesHide(mission: any) {
    this.hideTermsServices.next(mission);
  }

  announcedobConfirmed(mission: any){
    this.dobConfirmed.next(mission);
  }

  announcePaymentStage(mission: any) {
    this.atPaymentStage.next(mission);
  }

  announceConfirmStage(mission: any) {
    this.atConfirmStage.next(mission);
  }

  dynamicFormCheckBoxGenericAnnounced(mission: any) {
    this.dynamicFormCheckboxGenericClicked.next(mission);
  }

  tryAltMethodAnnounced(mission: any) {
    this.tryAltMethodClicked.next(mission);
  }

  idselectOptoutAnnounced(mission: any) {
    this.idSelectOptoutClicked.next(mission);
  }

  hideHeaderAnnounced(mission: any) {
    this.hideHeaderClicked.next(mission);
  }

  phoneNumberValidationErrorAnnounced(mission: any) {
    this.phoneNumberValidationError.next(mission);
  }

  public static sessionInvalidReceivedAnnounced(mission: any) {
    EventBusService.sessionInvalidReceived.next(mission);
  }

  conditionalRadioAnnounced(mission: any) {
    this.conditionalRadioClicked.next(mission);
  }

  dynamicRadioClickedAnnounced(mission: any){
    this.dynamicRadioClicked.next(mission);
  }

  jobTypeSelectedAnnounced(mission: any){
    this.jobTypeSelected.next(mission);
  }

  companyNameSelectedAnnounced(mission: any) {
    this.companyNameSelected.next(mission);
  }

  instructionsAnnounced(mission: any) {
    this.instructions.next(mission);
  }
  formInputErrorAnnounced(mission: any) {
    this.formInputError.next(mission);
  }

  hideHeaderMenuAnnounced(mission: any) {
    this.hideHeaderMenu.next(mission);
  }

  forceNotToShowTosReceivedAnnounced(mission: any) {
    this.forceNotToShowTosReceived.next(mission);
  }
}
