import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'environments/environment';
import { LocalStorageService } from 'framework/localstorage.service';

@Component({
  selector: 'app-expired-link',
  templateUrl: './expired-link.component.html',
  styleUrls: ['./expired-link.component.css']
})
export class ExpiredLinkComponent implements OnInit {
  formType: string;
  authyPolicyType: string = '';
  authorityMap: { [key: string]: string };
  constructor(@Inject(MsalService) private authService: MsalService , private route : ActivatedRoute , private localStorage: LocalStorageService) {
    this.authorityMap = {
      'signin': environment.policies.authorities.signIn.authority,
      'password-reset': environment.policies.authorities.resetPassword.authority,
    };
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.formType = params['policyType'];
    });
  }

  
  onStartAgainClick() {
    this.authyPolicyType = this.localStorage.getItem("authyPolicyType");
    const authority = this.authorityMap[this.authyPolicyType];
    if (authority) {
      this.authService.loginRedirect({ authority: authority, scopes: [] });
    } else {
      console.error('Invalid form type');
    }
  }
}
