import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'profile-communication',
  styles: [`.expired-card
            {
                opacity: .5;
                pointer-events: none;
            }
        `],
  templateUrl: './profile-communication.component.html',
  providers: []
})

export class ProfileCommunicationComponent {
  constructor() {
  }
}