import { Injectable, Inject } from '@angular/core';
import { TRANSLATIONS } from './translations'; // import our opaque token
// import { TRANSLATIONS_DB } from './translations'; // import our opaque token
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app/app.config';
import { WorkflowService } from '../workflow.service';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { LocalStorageService } from './../localstorage.service';
@Injectable()
export class TranslateService {
	private _currentLang: string;
	private originalLanguageCode: string; // this is to maintain for crowdin version
	headerOptions: any;

	// inject our translations
	constructor(@Inject(TRANSLATIONS) private _translations: any,
		private _http: HttpClient,
		private _workflow: WorkflowService,
		private _localStorage: LocalStorageService,
		) {
		this.headerOptions = this._workflow.getHeaderOptions();
	}

	public get currentLang() {
		// After set translations, current language is undefined, hence check added
		if (!!this._localStorage.getItem('language') && this._currentLang  === undefined)
			this._currentLang = this._localStorage.getItem('language');
		return this._currentLang;
	}

	/**
	 * set original language before switching to zulu for crowdin version
	 * to load translations from db
	 * @param languageCode
	 */
	public setOriginalLanguageCode(languageCode: string) {
		this.originalLanguageCode = languageCode;
	}

	public use(lang: string): void {
		// set current language
		this._currentLang = lang;
	}

	// public setTranslations(translationData) {
	// 	this._localStorage.setItem('translationsFromDB', translationData);
	// }

	public translate(key: string): string {
		let translation = key;

		if (this._translations[this.currentLang] && this._translations[this.currentLang][key]) {
			translation = this._translations[this.currentLang][key];
		// } else {
		// 	// Check added as lazy loading of modules creates new instance of services for every module
		// 	//  and need to reassign translations
		// 	let translationsData = this._localStorage.getItem('translationsFromDB');

		// 	if (!!translationsData && !!translationsData[key]) {
		// 		translation = translationsData[key];

		// 		if (key === 'PROFESSIONAL_LICENSE_TYPE')
		// 			this._localStorage.setItem('prof_license_types', translationsData[key]);
		// 	} else if (this._translations[this.currentLang] && this._translations[this.currentLang][key]) {
		// 		translation = this._translations[this.currentLang][key];
		// 	}
		}

		return translation;
	}

	public instant(key: string, words?: string | string[]) { // add optional parameter
		const translation: string = this.translate(key);
		if (!words) return translation;

		return this.replace(translation, words); // call replace function
	}

	public replace(word: string = '', words: string | string[] = '') {
		let translation: string = word;
		let PLACEHOLDER = '%';
		const values: string[] = [].concat(words);
		values.forEach((e, i) => {
			translation = translation.replace(PLACEHOLDER.concat(<any>i), e);
		});

		return translation;
	}

	// public loadTranslations(workflowId: any): Observable<Object> {
	// 	/*
	// 	* Need to make the HTTP GET API call with Token and will return
	// 	* 410 - expired - > Login
	// 	* 200 - > Set Password
	// 	*/
	// 	this.headerOptions = this._workflow.getHeaderOptions();
	// 	return this._http
	// 		.get(AppConfig.API_ENDPOINT() + `/api/v1/workflow/` + workflowId + '/translations/US/' + this.originalLanguageCode, this.headerOptions)
	// 		.pipe(
	// 			map(res => this._extractData(res)),
	// 			catchError(this._handleError));
	// }

	private _extractData(res: any) {
		return res;
	}

	private _handleError(error: any) {
		let _body = error || {};
		let errorMessage: string;

		switch (error.status) {
			case 400:
			case 409:
				errorMessage = '409_ERROR';
				break;
			case 500:
				errorMessage = 'HEAVY LOAD';
				break;
			case 401:
			case 410:
				//Bad request
				errorMessage = 'INVITE EXPIRED';
				break;

			case 404:
				break;

		}
		return throwError(errorMessage);
	}

	setLanguageBundle(lang, obj) {
		this._translations[lang] = obj;
	}

	hasLanguageBundle(lang) {
		if (!!this._translations[lang]) {
			return true;
		}
		return false;
	}
}
