import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './login.component';

import { SharedModule } from './../shared/shared.module';
/*
 * Import validation service
*/
import { EmailValidator, PasswordValidator } from '../../framework/form.validator';
// import { SetPasswordComponent } from '../Profile';

@NgModule({
  imports: [FormsModule, SharedModule],
  declarations: [LoginComponent, EmailValidator, PasswordValidator],
  providers: [],
  exports: [LoginComponent],
})

export class LoginModule {

}

//https://github.com/leonardohjines/angular2-login
//https://auth0.com/blog/creating-your-first-real-world-angular-2-app-from-authentication-to-calling-an-api-and-everything-in-between/
