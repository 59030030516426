import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SharedService } from 'app/shared/services/shared.service';

declare var jQuery: any;
@Component({
    selector: 'custom-select',
    templateUrl: './custom-select.component.html'
})

export class CustomSelectComponent implements OnInit {
    @Input('group')
    public customSelectSubForm: FormGroup;
    // ng select inputs
    @Input() items: any;
    @Input() typeahead: any;
    @Input() typeToSearchText: any;
    @Input() bindLabel: any;
    @Input() bindvalue: any;

    // Custom Inputs
    @Input() controlName: any;
    @Input() id: any;
    @Input() displayValue: any;
    @Input() selectedValue: any;
    @Input() dispValues: any = [];
    @Input() readonly : boolean;
    @Output() valueChange = new EventEmitter<string>();
    @Output() clearData = new EventEmitter<string>();
    @Output() onFocusValue = new EventEmitter<string>();
    @Output() onBlurValue = new EventEmitter<string>();
    autocompleteText: string = '';
    
    constructor(private _sharedService: SharedService) {

    }
    ngOnInit() {
        // this.group = new FormGroup({
        //     controlName: new FormControl()
        // });
        this.autocompleteText = 'new' + this.controlName;
        
        console.log("*********************************************************");
        console.log(this.selectedValue);
        console.log("*********************************************************");
    }

    // controlName parameter is the name of the control to which the value needs to be set
    trackValueChange(value, controlName) {
        this.valueChange.emit(value);
    }

    clearValue() {
        this.clearData.emit();
    }

    onFocus() {
        this.onFocusValue.emit();
    }

    onBlur() {
        this.onBlurValue.emit();
    }
}