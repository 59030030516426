<div class="body-main">
  <div class="body-bg pad0 container tab-height">
    <div class="pad_30">
      <div class="anime-clean contetnheight posrel">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-xs-12">
            <div class="header_txt_white">
              <h2 class="m-t-10 pad5">{{'VERIFICATION_CODE' | translate}}</h2>
              <h4 class="m-t-10 pad5">{{'VERIFICATION_CODE_SUBHEADER' | translate}}</h4>
            </div>
            <!-- <div>
              <h4 class="m-t-10 pad5" id="errorMessage" *ngIf="!!errorMessage">{{errorMessage | translate}}</h4>
            </div> -->
          </div>
        </div>
        <div class="row form-element-section btn_center">
          <div class="col-xs-12 col-sm-6 col-md-3 m-b-20">
            <div class="group">
              <input type="text" name="passcode-field" id="passcode-field" aria-required="true" [(ngModel)]="passcode"
                          class="validate form-control" title="{{ 'OKTA_PASSCODE' | translate }}" autocomplete="off" (focus)="hideError()">
              <div class="mandatoryGapError text-center" *ngIf="isError">{{errorMessage |translate}}</div>
              <!-- <div class=" error_info">
                <i class="fa fa-exclamation error_icon" (click)="showError()" aria-hidden="true" *ngIf="isErrorInfo"
                  [class.red_error]="isErrorInfo && !isError" [class.cursor_hand_none]="isError"></i>

                <div class="custom-error error_new_ui" *ngIf="isError">
                  <div class="left_div">
                    <ul>
                      <li class="alert"><i class="icon-warning"></i>{{ 'COMMONS_OOPS' | translate }}</li>
                      <li class="message">{{ errorMessage }}</li>
                    </ul>
                  </div>
                  <div class="right_div">
                    <div class="close" aria-hidden="true" (click)="closeError()">
                      <i class="icon-cancel"></i>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="row fixedbtn">
          <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
            <button type="submit" class="btn button font_extrabold" name="action"
                    [disabled]="(!passcode)"
                    [class.grow]="(!!passcode)" (click)="submitSelection()">{{'COMMONS_SUBMIT' | translate}}</button>
          </div>
          <!-- <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
            <button type="submit" class="btn button_wbg font_extrabold" (click)="goBack()" name="action">{{'CONSENT_BACK_BTN' | translate}}</button>
          </div> -->
          <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
            <button type="submit" class="btn button_wbg font_extrabold" (click)="resendCode()" name="action" [disabled]="isDisableResendButton">{{ resendButtonText }}</button>
          </div>
        </div>
        <!-- <div class="col-xs-12 col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 dp-r-0 m-b-20 text-center">
          <h4 class="text-center m-t-10 pad5">{{'DIDNT_GET_CODE' | translate}}</h4>
        </div> -->
      </div>
    </div>
  </div>
  <div class="clearfix"></div>
</div>

<div class="modal fade custom_modal" id="waitOnMfaModal" tabindex="-1" role="Application" aria-labelledby="loginWaitStatus" data-backdrop="static"
     data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="transparentbg modal-body m-t-15">
        <h4 class="modal-title" id="loginWaitStatus">{{'WAIT_ON_AGGREMENT_MODAL_TEXT' | translate }}
        </h4>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-xs-12 col-sm-4 col-md-4 p_l_0  p_r_0 m-r-15 ">
            <!-- <button type="button" class="btn button font_extrabold" data-dismiss="modal" data-toggle="resetPassword-modal" (click)="goToSignin()">CONTINUE</button>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="termsOfServiceModal" tabindex="-1" role="Application" aria-labelledby="setPasswordWaitStatus" class="modal fade terms_modal" >
  <div class="termswidth modal-dialog sign-modal">
    <div class="modal-content paddng20">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <i class="icon-cancel"></i>
        </button>
        <h3 class="modal-title" id="setPasswordWaitStatus">{{'TERMS_SERVICE_1' | translate}}</h3>
      </div>
      <div class="modal-body" [innerHTML]="termsOfServicesData"> </div>
      <div class="modal-footer text-center">
        <div class="col-xs-12 col-md-offset-3 col-sm-4 col-md-3 p_l_0  p_r_0 m-r-15">
          <button class="btn button grow font_extrabold"  (click)="agreeTermsOfService()" type ="button">{{'TERMS_SERVICE_BTN_1' | translate}}</button>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-3 p_l_0 p_r_0 m-r-15 m-b-20">
          <button class="btn button_profile grow font_extrabold" (click)="disagreeTermsOfService()" type="button">{{'TERMS_SERVICE_BTN_2' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
