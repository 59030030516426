<div *ngIf="!isDynamicDocumentUpload"  class="panel-group" id="accordion">
  <div class="panel panel-default">
    <div class="panel-heading">
      <span class="groupIcon" [class.headingGreen]="this.groupDataObj['groupIcon']">
        <i class="glyphicon glyphicon-ok" *ngIf="this.groupDataObj['groupIcon']"></i>
      </span>
      <span class="fontWeight-700 preUploadIcon" *ngIf="!this.groupDataObj['groupIcon']">&nbsp;</span>
      <a class="" data-toggle="collapse" data-parent="#accordion" href="#collapse{{accordionId}}"
        class="accordionTitlePadding">
        <h4 class="panel-title">
          <!-- <span class="groupIcon"><i class="glyphicon glyphicon-ok" *ngIf="this.groupDataObj['groupIcon']"></i></span> -->
          <a>{{ accordionTitle }}</a>
        </h4>
      </a>
    </div>
    <div id="collapse{{accordionId}}" class="panel-collapse collapse in">
      <!-- <pre>{{groupDataObj | json }}</pre> -->
      <p class="docWarningMessage" *ngIf="groupDataObj['isShowErrorMsg']">{{'DOCUMENT_CATEGORY_1' | translate}}</p>
      <div class="skipForNowBtn"
        *ngIf="groupDataObj['statewide-criminal-data-list'][0]['skippable']">
        <p class="skipForNowContainer" *ngIf="documnetSkippable && !inputIsFromEnhancedDashboard">{{ 'DOCUMENT_CATEGORY_SKIPPABLE' | translate }} </p>
        <p class="skipForNowContainer" *ngIf="documnetSkippable && inputIsFromEnhancedDashboard">{{ 'DOCUMENT_CATEGORY_SKIPPABLE_ENHANCED_DASHBOARD' | translate }} </p>
      </div>      
      <!-- <p class="docWarningMessage docuploadOptMsg" *ngIf="groupDataObj['statewide-criminal-data-list'][0]['skippable']">{{
        'DOCUMENT_CATEGORY_2' | translate }}</p> -->

      <div class="col-md-12 accordionPaddingOverride"
        *ngFor="let option of groupDataObj['statewide-criminal-data-list']; let i = index;">
        <div class="col-md-7 col-xs-12 docUploadCategory">
          <i class="glyphicon glyphicon-ok" *ngIf="option['isUpload'] || option['alreadyUploaded']"></i>
          <h4 class="docUploadFont" *ngIf="!isInviteEiaf">{{option.file_desc | translate}}</h4>
          <h4 class="docUploadFont" *ngIf="isInviteEiaf">{{option.file_name | translate}}</h4>
        </div>
        <div class="col-md-5 col-xs-12 docUploadIcons">
          <span>
            <i *ngIf="option['photo_id'] != undefined && option['photo_id'] == 'Yes'" class="fa fa-id-card-o"
              title="Photo Document"></i>
            <i *ngIf="option['photo_id'] == undefined || option['photo_id'] == 'No'" style="visibility:hidden"
              class="fa fa-id-card-o" title="Photo Document"></i>
          </span>
          <span class="docUploadPoints" *ngIf="option['points_value']">
            {{ option['points_value'] }}pts
          </span>
          <!-- Instruction document -->
          <span [class.disabledarrow]="!option.has_instruction_form"
            (click)="onInstructionClick(option.id, grpIndex, i, option.instructions_doc_id, option.has_instruction_form)">
            <i class="icon icon-info" title="Instruction"></i>
          </span>
          <!-- Blankform document -->
          <span [class.disabledarrow]="!option.has_blank_form"
            (click)="onDownloadDocumentClick(option.id, grpIndex, i, option.remote_document_id, option.has_blank_form)">
            <i class="icon icon-download" title="Download"></i>
          </span>
          <span [class.disabledarrow]="option['isDisabled'] == true || option['isFilledFormDisabled'] == true"
            (click)="onUploadDocumentClick(option.id, grpIndex, i, option.odrer_doc_type_id, option['profile-document-id'])">
            <i class="glyphicon glyphicon-paperclip" title="Upload"></i>
          </span>
          <!-- Preview of uploaded doucment -->
          <span *ngIf="option['isUpload']" (click)="onViewDocumentClick(option['profile-document-id'])">
            <i class="icon icon-eye-scan" title="Preview"></i>
          </span>
          <span [class.disabledarrow]="option['isFilledFormDisabled'] == true" *ngIf="option['isUpload']"
            (click)="onDeleteDocumentClick(option.id, grpIndex, i, option['profile-document-id'])">
            <i class="icon icon-garbage" title="Delete"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isDynamicDocumentUpload && !!miDocument && !!miDocument['document']" class="panel-group" id="accordion">
    <div class="panel panel-default">
      <div class="panel-heading">
        <span class="groupIcon" [class.headingGreen]="!!this.groupDataObj && this.groupDataObj['groupIcon']">
          <i class="glyphicon glyphicon-ok" *ngIf="!!this.groupDataObj && this.groupDataObj['groupIcon']"></i>
        </span>
        <span class="fontWeight-700 preUploadIcon" *ngIf="!this.groupDataObj || this.groupDataObj['groupIcon'] ">&nbsp;</span>
        <a class="" data-toggle="collapse" data-parent="#accordion" href="#collapse{{accordionId}}"
          class="accordionTitlePadding">
          <h4 class="panel-title">
            <a>{{ miDocument['group_name'] }}</a>
          </h4>
        </a>
      </div>
      <div id="collapse{{accordionId}}" class="panel-collapse collapse in">
        <div class="col-md-12 accordionPaddingOverride">
          <div class="col-md-7 col-xs-12 docUploadCategory">
            <i *ngIf="!!groupDataObj && groupDataObj['isUpload']" class="glyphicon glyphicon-ok" ></i>
            <h4 class="docUploadFont" >{{miDocument.label | translate}}</h4>
          </div>
          <div class="col-md-5 col-xs-12 docUploadIcons">
            <span>
              <i *ngIf="!!miDocument && miDocument['document'] != undefined && miDocument['document'].photoId === 'Yes'" class="fa fa-id-card-o"
                title="Photo Document"></i>
              <i *ngIf="!miDocument || !miDocument['document'] || miDocument['document'].photoId === 'No'" style="visibility:hidden"
                class="fa fa-id-card-o" title="Photo Document"></i>
            </span>
            
            <!-- Instruction document -->
            <span [class.disabledarrow]="!miDocument['document'].isInstruction"
              (click)="onInstructionClick(miDocument['document'].id, grpIndex, 0, miDocument['document'].instructionDocId, miDocument['document'].isInstruction)">
              <i class="icon icon-info" title="Instruction"></i>
            </span>
            <!-- Blankform document -->
            <span [class.disabledarrow]="!miDocument['document'].isRemoteDocId"
              (click)="onDownloadDocumentClick(miDocument['document'].id, grpIndex, 0, miDocument['document'].remoteDocId, miDocument['document'].isRemoteDocId)">
              <i class="icon icon-download" title="Download"></i>
            </span>
            <span [class.disabledarrow]="miDocument['document'].isDisabled == true || miDocument['document'].isFilledFormDisabled == true"
              (click)="onUploadDocumentClick(miDocument['document'].id, grpIndex, 0, miDocument['document'].orderDocTypeId)">
              <i class="glyphicon glyphicon-paperclip" title="Upload"></i>
            </span>
            <!-- Preview of uploaded doucment -->
            <span *ngIf="!!groupDataObj && groupDataObj['isUpload']" (click)="onViewDocumentClick(miDocument['document']['profileDocId'])">
              <i class="icon icon-eye-scan" title="Preview"></i>
            </span>
            <span [class.disabledarrow]="miDocument.isFilledFormDisabled == true" *ngIf="!!groupDataObj && groupDataObj['isUpload']"
              (click)="onDeleteDocumentClick(miDocument['document'].id, grpIndex, 0)">
              <i class="icon icon-garbage" title="Delete"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
</div>