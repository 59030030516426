<div>
  <ul *ngIf="viewProfileData['key'] === 'intl-addl-field-list'">
    <li *ngIf="!!viewProfileData['MOTHERS_LAST_NAME']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['MOTHERS_LAST_NAME'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['MOTHERS_LAST_NAME']}}</div>
      </div>
    </li>
    <li *ngIf="!!viewProfileData['FATHERS_LAST_NAME']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['FATHERS_LAST_NAME'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['FATHERS_LAST_NAME']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['MAIDEN_NAME']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['MAIDEN_NAME'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['MAIDEN_NAME']}}</div>
      </div>
    </li>
    <li *ngIf="!!viewProfileData['BIRTH_COUNTRY']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['BIRTH_COUNTRY'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['BIRTH_COUNTRY']}}</div>
      </div>
    </li>
    <li *ngIf="!!viewProfileData['BIRTH_STATE']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['BIRTH_STATE'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['BIRTH_STATE']}}</div>
      </div>
    </li>
    <li *ngIf="!!viewProfileData['BIRTH_CITY']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['BIRTH_CITY'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['BIRTH_CITY']}}</div>
      </div>
    </li>
    <li *ngIf="!!viewProfileData['SCHOOL_NAME_IN_LOCAL_LANGUAGE']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['SCHOOL_NAME_IN_LOCAL_LANGUAGE'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['SCHOOL_NAME_IN_LOCAL_LANGUAGE']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['NAME_IN_LOCAL_LANGUAGE']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['NAME_IN_LOCAL_LANGUAGE'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['NAME_IN_LOCAL_LANGUAGE']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['EMPLOYEE_ID']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['EMPLOYEE_ID'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['EMPLOYEE_ID']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['EMP_NAME_IN_LOCAL_LANGUAGE']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['EMP_NAME_IN_LOCAL_LANGUAGE'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['EMP_NAME_IN_LOCAL_LANGUAGE']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['DEPARTMENT']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['DEPARTMENT'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['DEPARTMENT']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['MOTHERS_MAIDEN_NAME']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['MOTHERS_MAIDEN_NAME'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['MOTHERS_MAIDEN_NAME']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['POSITION_OFFERED']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['POSITION_OFFERED'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['POSITION_OFFERED']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['INDUSTRY']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['INDUSTRY'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['INDUSTRY']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['PHONE']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['PHONE'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['PHONE']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['DBS_PASSPORT_NATIONALITY']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['DBS_PASSPORT_NATIONALITY'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['DBS_PASSPORT_NATIONALITY']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['DBS_DRIVER_LICENSE_TYPE']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['DBS_DRIVER_LICENSE_TYPE'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['DBS_DRIVER_LICENSE_TYPE']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['DBS_DRIVER_LICENSE_VALID_FROM']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['DBS_DRIVER_LICENSE_VALID_FROM'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['DBS_DRIVER_LICENSE_VALID_FROM']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['DBS_PASSPORT_ISSUE_DATE']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['DBS_PASSPORT_ISSUE_DATE'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['DBS_PASSPORT_ISSUE_DATE']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['HUKOU_ADDRESS']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['HUKOU_ADDRESS'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['HUKOU_ADDRESS']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['HUKOU_CITY']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['HUKOU_CITY'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['HUKOU_CITY']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['HUKOU_REGION']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['HUKOU_REGION'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['HUKOU_REGION']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['WWC_STATE']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['WWC_STATE'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['WWC_STATE']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['WWC_NUMBER']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['WWC_NUMBER'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['WWC_NUMBER']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['COUNTRY_OF_CITIZENSHIP'] && showCitizenShipCountry">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['COUNTRY_OF_CITIZENSHIP'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['COUNTRY_OF_CITIZENSHIP']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['ARE_YOU_MACAO_ID_HOLDER']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['ARE_YOU_MACAO_ID_HOLDER'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div *ngIf="viewProfileData['ARE_YOU_MACAO_ID_HOLDER'] === 'TRUE'" class="col-md-6 col-xs-5 col-sm-6 options"> {{'COMMONS_YES' | translate}}</div>
        <div *ngIf="viewProfileData['ARE_YOU_MACAO_ID_HOLDER'] !== 'TRUE'" class="col-md-6 col-xs-5 col-sm-6 options"> {{'COMMONS_NO' | translate}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['LEGAL_NAME_IN_CHINESE']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['LEGAL_NAME_IN_CHINESE'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['LEGAL_NAME_IN_CHINESE']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['LEGAL_NAME']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['LEGAL_NAME'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['LEGAL_NAME']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['VP_GENDER']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{'CLIPBOARD_GENDER' | translate }} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['VP_GENDER'] | translate}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['DO_YOU_HAVE_RNF_NUMBER']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['DO_YOU_HAVE_RNF_NUMBER'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div *ngIf="viewProfileData['DO_YOU_HAVE_RNF_NUMBER'] === 'TRUE'" class="col-md-6 col-xs-5 col-sm-6 options"> {{'COMMONS_YES' | translate}}</div>
        <div *ngIf="viewProfileData['DO_YOU_HAVE_RNF_NUMBER'] !== 'TRUE'" class="col-md-6 col-xs-5 col-sm-6 options"> {{'COMMONS_NO' | translate}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['RNF_NUMBER']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['RNF_NUMBER'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['RNF_NUMBER']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['NAME_DOCUMENT_TYPE']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['NAME_DOCUMENT_TYPE'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['NAME_DOCUMENT_TYPE']}}</div>
      </div>
    </li>
    
    <li *ngIf="!!viewProfileData['NAME_AS_PER_DOCUMENT']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['NAME_AS_PER_DOCUMENT'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['NAME_AS_PER_DOCUMENT']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['HAS_UAN_NUMBER']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['HAS_UAN_NUMBER'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div *ngIf="viewProfileData['HAS_UAN_NUMBER'] === 'TRUE'" class="col-md-6 col-xs-5 col-sm-6 options"> {{'COMMONS_YES' | translate}}</div>
        <div *ngIf="viewProfileData['HAS_UAN_NUMBER'] !== 'TRUE'" class="col-md-6 col-xs-5 col-sm-6 options"> {{'COMMONS_NO' | translate}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['UAN_NUMBER']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['UAN_NUMBER'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['UAN_NUMBER']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['APPLICANT_REPORT_QUESTION']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['APPLICANT_REPORT_QUESTION'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div *ngIf="viewProfileData['APPLICANT_REPORT_QUESTION'] === 'TRUE'" class="col-md-6 col-xs-5 col-sm-6 options"> {{'COMMONS_YES' | translate}}</div>
        <div *ngIf="viewProfileData['APPLICANT_REPORT_QUESTION'] !== 'TRUE'" class="col-md-6 col-xs-5 col-sm-6 options"> {{'COMMONS_NO' | translate}}</div>
      </div>
    </li>

  </ul>

  <ul *ngIf="viewProfileData['key'] === 'forms'" class="additionalInformationViewProfile">
    <li *ngFor="let profile of viewProfileData">
      <div class="row">
        <div class="col-md-12 col-xs-5 col-sm-5 type">{{ profile['label'] }}</div>
        <div class="col-md-12 col-xs-5 col-sm-6 options">{{ profile['value'] }}</div>
        <div class="col-md-12 col-xs-5 col-sm-6 options" *ngIf="profile['description']">{{profile['description']}}</div>
      </div>
    </li>
  </ul>
</div>
