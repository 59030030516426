import { Validator } from './model/validator.enum';

/**
 * Typed data structure to be used for Field validation rule reslover.
 * */
export class ValidatorParamMap<K extends ValidatorParamKey, V = {}> {
  data: V;

  constructor() {
    this.data = {} as V;
  }

  set(key:K, value:V){
    this.data[key.toKey()] = value;
  }

  get(key:K){
    return this.data[key.toKey()];
  }

}

/**
 * Used to generate specifying unique key to support multiple parameters for specific validator type.
 * */
export class ValidatorParamKey {
  private readonly _controlName: string;
  private readonly _validator: Validator;

  constructor(controlName: string, validator: Validator) {
    this._controlName = controlName;
    this._validator = validator;
  }

  public toKey(): string {
    return this._controlName + '-' + this._validator;
  }
}

/**
 * Builder responsible to properly construct parameters for dynamic Field Validation validators.
 * When we have dynamic validation rules specified, but configuration of specific Validator is dependent on
 * client/component internal variables or configurations, than client is responsible to pass parameters to
 * decoupled validators.
 * */
export class ValidatorParamBuilder {

  private readonly _validatorParamMap: ValidatorParamMap<ValidatorParamKey>;

  constructor() {
    this._validatorParamMap = new ValidatorParamMap<ValidatorParamKey>();
  }

  public add(formControlName: string, validator: Validator, parameter: any): ValidatorParamBuilder {
    this._validatorParamMap.set(new ValidatorParamKey(formControlName, validator), parameter);
    return this;
  }

  public build(): ValidatorParamMap<ValidatorParamKey> {
    return this._validatorParamMap;
  }

}
