import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ViewProfileComponent } from './profile.component';
import { ViewProfileRoutingModule } from './view-profile-routing.module';
import {
    ProfileHeaderComponent,
    ProfileCommunicationComponent,
    ProfileRequestsComponent,
    ProfileNotificationRequestsComponent,
    ProfileAddressComponent,
    ProfileEmploymentComponent,
    ProfileDriverLicenseComponent,
    ProfileEducationComponent,
    ProfileAssessmentsComponent,
    ProfileDocumentsComponent,
    ProfilePersonalComponent,
    ProfileLicenseComponent,
    ProfileReferencesComponent,
    ProfileCriminalComponent,
    ProfileAliasComponent,
    ProfileNameComponent,
    ProfileIntlComponent
} from './../view-profile/cards'

import {
    ViewProfileAddressComponent,
    ViewProfileEmploymentComponent,
    ViewProfileEducationComponent,
    ViewProfileDriverLicenseComponent,
    ViewProfilePersonalComponent,
    ViewProfileProfessionalLicenseComponent,
    ViewProfileReferencesComponent,
    ViewProfileCriminalComponent,
    ViewProfileAliasComponent,
    ViewProfileNameComponent,
    ViewProfileIntlComponent
} from './../view-profile/popups';
import { SharedModule } from 'app/shared/shared.module';
import { EmploymentService } from 'app/employment';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ViewProfileRoutingModule,
        SharedModule
    ],
    declarations: [ViewProfileComponent,
        ProfileHeaderComponent,
        ProfileCommunicationComponent,
        ProfileRequestsComponent,
        ProfileNotificationRequestsComponent,
        ProfileAddressComponent,
        ProfileEmploymentComponent,
        ProfileDriverLicenseComponent,
        ProfileEducationComponent,
        ProfileAssessmentsComponent,
        ProfileDocumentsComponent,
        ProfilePersonalComponent,
        ProfileReferencesComponent,
        ProfileLicenseComponent,
        ProfileAliasComponent,
        ProfileNameComponent,
        ProfileIntlComponent,
        ViewProfileAddressComponent,
        ViewProfileEmploymentComponent,
        ViewProfileEducationComponent,
        ViewProfileDriverLicenseComponent,
        ViewProfilePersonalComponent,
        ViewProfileProfessionalLicenseComponent,
        ViewProfileReferencesComponent,
        ProfileCriminalComponent,
        ViewProfileCriminalComponent,
        ViewProfileAliasComponent,
        ViewProfileNameComponent,
        ViewProfileIntlComponent],
    providers: [EmploymentService],
    exports: [],
})

export class ViewProfileModule {

}
