import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DifferentEmailComponent } from './different-email/different-email.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { DisagreeTermsComponent, PrivateBrowsingComponent, UnsupportedBrowsingComponent } from '../pre-auth';
import { PasswordExpiredComponent } from 'app/pre-auth/password-expired/password-expired.component';
import { RedirectComponent } from 'app/redirect/redirect.component';
import { UnsupportedDeviceComponent } from 'app/pre-auth/unsupported-device/unsupported-device.component';
import { ThankyouComponent } from 'app/thankyou/thankyou.component';
import {BaseForgotPasswordComponent} from './base-forgot-password/base-forgot-password.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: 'diff-email', component: DifferentEmailComponent },
      { path: 'forgot-password', component: BaseForgotPasswordComponent },
      { path: 'unsubscribe/:token', component: UnsubscribeComponent },
      { path: 'invalid-otp', component: PasswordExpiredComponent },
      { path: 'redirect', component: RedirectComponent },
      { path: 'disagreeterms', component: DisagreeTermsComponent },
      { path: 'private-browsing', component: PrivateBrowsingComponent },
      { path: 'unsupported-browsing', component: UnsupportedBrowsingComponent },
      { path: 'unsupported-device', component: UnsupportedDeviceComponent },
      { path: 'thankyou', component: ThankyouComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }
