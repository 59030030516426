export class DocumentUploadConfig {
  static DOCUMENT_SKIP_SUBMISSION_PREF = '1449';
  static CBSV_PACKAGE_RULESET_ID = '2168';
  static ENABLE_OPTIONAL_DOCUMENT_COLLECTION = '1551'

  static documentConfig = [
    {
      'moduleName': 'Education',
      'degreeType': 'GED',
      'school': 'VIT UNIVERSITY',
      'country': 'India',
    },
    {
      'moduleName': 'Employment',
      'degreeType': 'Associates',
      'school': 'CHRIST UNIVERSITY',
      'country': 'India',
    },
    {
      'moduleName': 'Education',
      'degreeType': 'VIT',
      'school': 'JAIN UNIVERSITY',
      'country': 'India',
    }
  ];

  static documentUploadSteps: any = [
    'mandatoryDocs',
    'skippedDocs',
    'optionalDocs'
  ];

  static subStepIndexMapping: Object = {
    mandatory: {
      subStep: 'mandatory',
      subStepIndex: 0
    },
    skippedDocs: {
      subStep: 'skippedDocs',
      subStepIndex: 1
    },
    optional: {
      subStep: 'optional',
      subStepIndex: 2
    }
  };

  static moduleCheckList: any = [
    'INTLPMP',
    'INTLFMP',
    'INTLED',
    'INTLLIC',
    'INTLMVR',
    'INTLPRC'
  ];

  static intl100PCategory: any = [
    'PRIMARYID',
    'PHOTOID',
    'ADDRESSID',
    'ADDITIONALID'
  ];

  static intlAUCrimCategory: any = [
    'CATEGORY A',
    'COMMENCEMENTID',
    'CATEGORY B',
    'PRIMARYID',
    'CATEGORY C',
    'SECONDARYID'
  ];

  static intlNZNzpvCategory: any = [
    'PRIMARYID',
    'SECONDARYID',
  ];

  static intlAFP100PCategory: any = [
    'PRIMARYID',
    'SECONDARYID'
  ];

  static getDocumentUploadConfig(): Array<any> {
    return DocumentUploadConfig.documentConfig;
  }

  static getDocUploadSteps(): any {
    return DocumentUploadConfig.documentUploadSteps;
  }

}
