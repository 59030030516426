import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'private-browsing',
  templateUrl: './private-browsing.component.html'
})

export class PrivateBrowsingComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
