import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DashboardReportService } from './../../../dashboard/dashboard.report.service';
import { LocalStorageService } from './../../../../framework/localstorage.service';
import { AppConfig } from './../../../../app/app.config';
import { TranslateService } from './../../../../framework/i18n';

@Component({
  selector: 'profile-documents',
  templateUrl: './profile-documents.component.html',
  providers: [DashboardReportService]
})

export class ProfileDocumentsComponent {
  @Input() options: any;
  @Output() pdfUrlEmit: EventEmitter<any> = new EventEmitter();
  docName: string = '';
  eauthId: string = '';

  constructor(private _dashboardService: DashboardReportService,
    private localStorage: LocalStorageService,
    private _ts: TranslateService) {
  }

  ngOnInit() {
  }

  docClick(docName: string, docId: any) {
    if (docName.toUpperCase().indexOf('EAUTH') > -1) {
      this.eauthClick(docId, docName);
    } else {
      this.otherDocsClick(docId, docName);
    }
  }

  eauthClick(docId, docName) {
    let obj = {};
    let pdfData = `<div style="font-family: Open Sans, sans-serif; background-color:white; font-size: 13px; padding:10px"><span>Right to Entering into Agreement in Non-Electronic Form:</span>
            <p>I understand that I have the right to agree to the Authorization Form on paper and to execute the Authorization
            Form by handwritten signature. In order to provide it in written form, I can print and upload the form in the Document
            Upload section upon completing my profile.</p>

            <span>Withdrawing My Consent and Updating Information:</span>
            <p>I understand that I have the right to withdraw my consent to use electronic signature and the right to update
            my contact information at any time by calling (800)845-6004. If I decide at any point to withdraw my consent, I
            understand that the withdrawal is only prospective and shall not impact the legal effectiveness, validity or enforceability
            of the Authorization Form prior to the implementation of my request withdrawal. The effectiveness of the withdrawal
            will be within a reasonable period of time after receipt of the withdrawal. If I withdraw during the period where
            a screening service is in the process of being fulfilled or ordered, it may impact my application for employment
            or contract work. For further information you can call (800)845-6004.</p>

            <p>If I decide at any point to withdraw my consent, I must provide a signed letter via mail to the mailing address
            in bold below to notify First Advantage that I wish to withdraw my consent to enter an agreement in electronic
            form.</p>
            <table>
                <tr>
                    <td width="20px"></td>
                    <td style="vertical-align:top"><strong>Mail:</strong>&nbsp;</td>
                    <td> <strong>First Advantage Background Services Corp.</strong>
                        <br><strong>Consumer Center</strong>
                        <br><strong>P.O. Box 105292</strong>
                        <br><strong>Atlanta, GA  30348</strong></td>
                </tr>
            </table>
            <span>Hardware and Software Requirements:</span>
            <p>User must use Internet Explorer 6 or later, Firefox 3 or later, Chrome 36 or later, Safari 3.5 or later. I
            will be notified if there are any material changes to the hardware or software described above. I hereby represent
            that I have the minimum requirements necessary as described above.
            </p>
            <span>Requesting a Paper Copy of My Electronic Record:</span>
            <p>Upon request, I may obtain a paper copy of the Authorization Form by clicking print before submitting. Additionally,
            I may mail a request to the mailing address shown above or call (800)845-6004 for assistance.
            </p>
            <span>Consent to Use Electronic Signature:</span>
            <p>By selecting the "Yes" option below, I hereby consent to the use of my electronic signature to execute the
            Authorization Form. I understand that my electronic signature will have the same legal effect, validity and enforceability
            as if I were to execute by handwritten signature. If I do not wish to have my electronic signature to have a binding
            effect, I must select "No" option below.</p>
            </div>`;

    //let workflowid = this.localStorage.getItem('workflow_instance_id');
    const workflowid = this.localStorage.getItem('workflow_instance_id');
    obj['component'] = 'eauth';
    obj['data'] = AppConfig.API_ENDPOINT() + '/api/v1/workflow/' + workflowid + '/download/document/' + docId;
    obj['html'] = pdfData;
    let dwobj = {};
    obj['id'] = docId;
    obj['file_name'] = docName;
    this.pdfView(obj);
  }

  otherDocsClick(docId, docName) {
    let obj = {};
    const workflowid = this.localStorage.getItem('workflow_instance_id');
    obj['component'] = 'others';
    obj['data'] = AppConfig.API_ENDPOINT() + '/api/v1/workflow/' + workflowid + '/download/document/' + docId;
    let dwobj = {};
    obj['id'] = docId;
    obj['file_name'] = this.translateDocName(docName);
    this.pdfView(obj);
  }

  pdfView(dataObj: Object) {
    this.pdfUrlEmit.emit(dataObj);
  }

  translateDocName(docName) {
    if (docName.indexOf('|')) {
      let docNameArr = docName.split("|");
      return this._ts.instant(docNameArr[0], docNameArr[1]);
    } else {
      return this._ts.translate(docName);
    }
  }

}