import { Pipe, PipeTransform } from '@angular/core'
import { TranslateService } from 'framework/i18n';
import { UtilService } from 'framework/utils/util.service';


@Pipe({
  name: 'accordionTitleFormat'
})
export class AccordionTitleFormat implements PipeTransform {
    static EDUCATION_TYPES = 'EDUCATION_TYPES';

    constructor(private _util: UtilService,
        private _ts: TranslateService) {}

    transform(value) : any {
        let country = '';
        let groupDescription = '';
        let fileDescription = '';
        let title = '';

        if (value['group-desc'] === undefined) {
            fileDescription = this.titleCase(value['statewide-criminal-data-list'][0]['file_desc']);

            title = fileDescription;

            return title;
        } else {
            country = this.capitalizeWord(value['country']);
            groupDescription = this.titleCase(value['group-desc']);
            title = this.formatGroupDescription(groupDescription, country);

            return title;
        }
    }

    titleCase(str) {
        if (str !== undefined && str !== '') {
        // let duplicateStr = '';
        // let index = 0;

        if (str.indexOf('.Optional') > -1) {
            str = str.replace('.Optional', '');
        } else if (str.indexOf(':Optional') > -1) {
            str = str.replace(':Optional', '');
        }

        return str;
        } else {
        return '';
        }
    }

    isFirstCharacterAlphabet(ch) {
        return /^[A-Za-z]{1,1}$/.test(ch);
    }

    capitalizeWord(str) {
        if (str !== undefined && str !== '') {
        let splitStr = str.toLowerCase().split(' ');

        for (let i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            let testCharacter = this.isFirstCharacterAlphabet(splitStr[i].charAt(0).toUpperCase());
            if (testCharacter)
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
            else
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].charAt(1).toUpperCase() + splitStr[i].substring(2);
        }
        // Directly return the joined string
        return splitStr.join(' ');
        } else {
        return '';
        }
    }

    /**
     * Format and translate the group header of upload document
     * @param groupDescription
     * @param country
     */
    formatGroupDescription(groupDescription, country) {
        let title: string = '';

        if (groupDescription) {
            if (groupDescription.indexOf(';') > -1) {
                groupDescription = groupDescription.replace(';', '');
            }
            // For multilingual support..
            if (groupDescription.indexOf('||') > -1) {
                let splitStr = groupDescription.split('||');
                groupDescription = this._ts.translate(splitStr[0]) + " " + this.getTraslationFromEducationTypes(splitStr[1]);
            } else {
                groupDescription = this._ts.translate(groupDescription);
            }
            // concat country name if available
            if (country) {
                let index = groupDescription.indexOf(':');
                title = (index > -1) ? this.formGroupTitle(index, groupDescription, country) : groupDescription + '; ' + country;
                // title = groupDescription + '; ' + country;
            } else {
                title = groupDescription;
            }
        }
        return title;
    }

    // Form group title
    formGroupTitle(index, groupDescription, country) {
        let groupTitle = groupDescription.slice(0, index).trim();
        groupTitle = groupTitle + ' : ' + country;
        return groupTitle;
    }

    getTraslationFromEducationTypes(key: string) {
        if (!!key) {
            let educationTypesList = this._util.getArrayFromJsonString(this._ts.translate(AccordionTitleFormat.EDUCATION_TYPES));
            for (let educationType of educationTypesList) {
                if (educationType['id'].toUpperCase() === key.toUpperCase()) {
                    return educationType['name'];
                }
            }
            return key;
        }
        return '';
    }
}
