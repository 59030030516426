import { NgModule } from '@angular/core';
import { PreAuthComponent }  from './pre-auth.component';
import { SharedModule } from './../shared/shared.module';

@NgModule({
  imports: [SharedModule],
  exports: [],
  declarations: [
    PreAuthComponent
  ],
  providers: [],
})

export class PreAuthModule { }
