import { Observable, throwError } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AppConfig } from 'app/app.config';
import { LocalStorageService } from 'framework/localstorage.service';


declare var window: any;

@Injectable()
export class AuthyService {

  constructor( private _http: HttpClient) {}

  getInviteIdToken(key: string): Observable<object>{

    let trackObj = {
      stage_name: 'login',
      action_value: 'authy login'
    };
    window.track_event('getting authy id token', trackObj, true, true);

    return this._http.get(AppConfig.API_ENDPOINT() + `/api/v1/authn/fetch-authy-token/?key=${key}`)
      .pipe(
        map(this._extractServerResponse),
        catchError(err => this._handleError('Fetch authy id token error.', err))
      );

  }

  
  private _extractServerResponse(res: any) {
    return res;
  }

  private _handleError(eventName, error: any) {

    let trackObj = {
      stage_name: 'authy-login',
      action_value: 'authy failed'
    };
    window.track_event(eventName, trackObj, true, true);

    let errorMessage: string;

    console['server'](eventName, error);

    switch (error.status) {
      case 400:
      case 401:
      case 405:
      case 403:
        //Bad request, Invalid Credentials - login invalid
        let _body = error|| {};
        errorMessage = _body['error']['error'] || 'Invalid Login';
        break;

      case 404:
        break;
      case 423:
        errorMessage = 'ACCOUNT_LOCKED';
        break;
    }
    return throwError(errorMessage);
  }


}
