import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/fieldconfig.interface';

declare var $: any;
@Component({
  selector: 'form-object-select',
  styleUrls: ['form-object-select.component.scss'],
  templateUrl: './form-object-select.component.html'
})
export class FormObjectSelectComponent implements Field {
  config: FieldConfig;
  group: FormGroup;

  showFullLabelText() {
    $('[data-toggle="popover"]').popover();
  }
}
