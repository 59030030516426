import { Injectable } from '@angular/core';

declare var window: any;

@Injectable()
export class HooksService {
    constructor() {
        // console.log('Initializing Hooks Service...');
        window.safeNotifyHost = this.safeNotifyHost;
    }

    safeNotifyHost(message: string, data: any) {
        const output: any = {
            message,
            data
        };

        // Check if site is whitelisted. - TODO
        // console.log('Potential targets for notification', window.opener, window.parent, window.top);
        // console.log("Checking:", window);
        var content = window;
        if (content.opener) {
            // Opened using window.open by a host app
            // window.postMessage(message, window.opener, data);

            content.opener.postMessage(message, '*');
            
        } else if (content.parent) {
            // Opened via iframe
            // window.postMessage(message, (window.parent || window.top), data);

            content.parent.postMessage(message, '*');
            // console.log('Post message fired for parent/top', window.top, window.parent);
        }
    }
}