<div class="pad0 body_container container loginMobilePad m-t-20 mar10">
  <div class="anime-clean signup">
    <div class="row margin0">
      <div class="col-md-6 col-sm-6 hidden-xs dark_bg">
        <h3 class="text-center m-t-10 pad5 wrap-word-overflow">{{'COMPONENT_TITLE_6'|translate }}</h3>
      </div>
      <div class="pad0 col-xs-12  col-sm-6 col-md-6 signup_form mar26">
        <div class="signup_container">
          <div class="row mobile-header visible-xs text-center" id="loginpaddingAlignment">
            <div class="col-xs-12 ">
              <div class="header_txt_white  wrap-word-overflow">
                <h2 class="wrap-word-overflow">{{'COMPONENT_TITLE_6'|translate }}</h2>
              </div>
            </div>
          </div>
          <div class="row m-t-40 pad15 ">
            <div class="col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 col-xs-12  ">
              <div class="header_txt_white">
                <!-- <h4 class="m-t-10" tabindex="0">{{ 'OTP_SENT_AT' | translate:[forgotPassRes['factorType']]}} - </h4> -->
                <h3>{{'RESET_HEADER_4' | translate}}</h3>
              </div>
            </div>
          </div>
          <form class="m-t-25 pad15" #resetPasswordForm="ngForm"  autocomplete="off" data-target="#myModal">
            <div class="row">
              <div class="col-xs-12  col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 dp-r-0 m-b-20">
                <div class="group">
                  <label class="placeholder_white custom_label" for="inputSetPwd">{{ 'CREATE_PASSWORD_LABLE' | translate}}</label>
                  <input ngModel type="password" name="password" ngModel #password="ngModel" id="inputSetPwd" aria-required="true" class="validate form-control"
                    maxlength="16" title="{{'COMMONS_PASSWORD_ERROR_1'| translate}}" (keyup)="enableNext(resetPasswordForm.value.password, resetPasswordForm.value.confirmPassword)"
                    (blur)="validatePwd(resetPasswordForm.value.password, resetPasswordForm.value.confirmPassword)"
                    autocomplete="off" (focus)="hidePwdError()"/>
                    <div id="meter" #meter>
                      <password-meter [password]= "resetPasswordForm.value.password" (isValidPassword)='isPasswordValid($event)'>
                      </password-meter>
                    </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 dp-r-0 m-b-20">
                <div class="group">
                  <label class="placeholder_white custom_label" for="inputsConfirmPwd">{{ 'CONFIRM_PASSWORD_LABLE' | translate}}</label>

                  <input ngModel type="password" id="inputsConfirmPwd" name="confirmPassword" ngModel #confirmPassword="ngModel" aria-required="true" class="validate form-control"
                    maxlength="16" title="{{'COMMONS_CONFIRM_PASSWORD_ERROR_1'| translate}}"(blur)="validateConfirmPwd(resetPasswordForm.value.password, resetPasswordForm.value.confirmPassword)"
                    autocomplete="off" (keyup)="enableNext(resetPasswordForm.value.password, resetPasswordForm.value.confirmPassword)" (focus)="hideError(resetPasswordForm.value.password,resetPasswordForm.value.confirmPassword)"/>
                  <div class="error_info">
                      <i class="fa fa-exclamation error_icon" aria-hidden="true" *ngIf="isConfirmPwdErrorInfo" [class.red_error]="isConfirmPwdErrorInfo && !isConfirmPwdError" [class.cursor_hand_none]="isConfirmPwdError"></i>
                      <div class="error_info_popup error_new_ui">
                        <div class="left_div">
                          <ul>
                            <li class="alert">{{confirmPwdErrorMessage | translate}}</li>
                            <!-- <li class="message">{{confirmPwdErrorMessage | translate}}</li> -->
                          </ul>
                        </div>
                        <div class="right_div">
                          <div class="close" aria-hidden="true">
                            <i class="icon-cancel"></i>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="custom-error error_new_ui" *ngIf="isConfirmPwdError">
                    <div class="left_div">
                      <ul>
                        <li class="alert">{{confirmPwdErrorMessage | translate}}</li>
                        <!-- <li class="message">{{confirmPwdErrorMessage | translate}}</li> -->
                      </ul>
                    </div>
                    <div class="right_div">
                      <div class="close" aria-hidden="true" (click)="closeConfirmPwdError()">
                        <i class="icon-cancel"></i>
                      </div>
                    </div>
                  </div>
                  <div class="mandatoryGapError" *ngIf="isError">{{ errorMessage }}</div>
                </div>
              </div>
            </div>

            <div class="col-xs-12  col-sm-offset-2 col-sm-8 col-md-offset-2 col-md-8 m-t-5 pad0">
              <button type="button"  (click)="validatePassword(resetPasswordForm.value)" class="btn button font_extrabold" name="action" [disabled]="!isConfirmPwdValid || !isPwdValid || !isPasswordRulesValid"
                [class.grow]="isConfirmPwdValid && isPwdValid && isPasswordRulesValid">{{ 'COMMONS_NEXT' | translate}}</button>
            </div>

          </form>
        </div>
      </div>
      <!--Sign up From Ends here-->
    </div>
  </div>
  <div class="clearfix"></div>
</div>

<div class="modal fade custom_modal" id="waitOIDCModal" tabindex="-1" role="Application" aria-labelledby="setPasswordWaitStatus" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal" role="document">
    <div class="modal-content">
      <div class="transparentbg modal-body m-t-15">
        <h4 class="modal-title" id="setPasswordWaitStatus">{{'WAIT_ON_AGGREMENT_MODAL_TEXT' | translate }}
        </h4>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-xs-12 col-sm-4 col-md-4 p_l_0  p_r_0 m-r-15 ">
           <!-- <button type="button" class="btn button font_extrabold" data-dismiss="modal" data-toggle="resetPassword-modal" (click)="goToSignin()">CONTINUE</button>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade custom_modal" id="oidcResetPasswordModal" role="dialog" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
        &nbsp;
      </div>
      <div class="transparentbg modal-body">
        <h4 class="modal-title">{{'RESET_MODAL_TEXT' | translate }}
        </h4>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-xs-12 col-sm-4 col-md-4 marginbot20 mar30 p_l_0  p_r_0 m-r-15  m-b-15">
            <button type="button" class="btn button font_extrabold" data-dismiss="modal"
              data-toggle="resetPassword-modal" (click)="goToSignin()">{{'COMMONS_CONTINUE' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
