export class JobModel {
    constructor(
        public id:any = null,
        public type: string = "",
        public permissionToContact: string = "",
        public companyName: string = "",
        public jobTitle: string = "",
        public jobFrom: string = "",
        public jobTo: string = "",
        public countryName: string = "",
        public countryCode: string = "",
        public city: string = "",
        public stateName: string = "",
        public stateCode: string = "",
        public zipCode: string = "",
        public contactName: string = "",
        public contactNumber: string = ""

    ) { }
}

