import { BackendDataRef, FieldValidationRule, Inclusive } from './field-validation-rule';
import { Validator } from './validator.enum';


export enum RuleRegexName {
  ukFormattedZipCode = 'UK_FORMATTED_ZIP_CODE',
  kypAddressFormat = 'KYP_ADDRESS_FORMAT',
  nameFormat = 'NAME_FORMAT',
  usFormattedZipCode = 'US_FORMATTED_ZIP_CODE',
  otherZipFormat = 'OTHER_FORMATTED_ZIP_CODE'
}

export enum RuleRegexValue {
  usFormattedZipCode = '^[a-zA-Z0-9*]{5}$',
  ukFullFormatCode = '^([A-Z]([A-Z]?\\d\\d?\\s|[A-Z]?\\d[A-Z]\\s)?\\d[A-Z]{2})|([A-Z]([A-Z]?\\d\\d?|[A-Z]?\\d[A-Z]) ?\\d[A-Z]{2})$',
  ukFormattedZipCode = '^(([gG][iI][rR] {0,}0[aA]{2})|(([aA][sS][cC][nN]|[sS][tT][hH][lL]|[tT][dD][cC][uU]|[bB][bB][nN][dD]|[bB][iI][qQ][qQ]|[fF][iI][qQ][qQ]|[pP][cC][rR][nN]|[sS][iI][qQ][qQ]|[iT][kK][cC][aA]) {0,}1[zZ]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yxA-HK-XY]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$',
  kypAddressFormat = '^[a-zA-Z0-9&\'\\/ \\-,\\.\\(\\)\\[\\]\\!\\?\\@\\+]*$',
  otherZipFormat = '^[A-Z0-9 \\-]*$',
  otherZipFormatAz = '^[a-zA-Z0-9 \\-]*$',
  oldPaZipFormat = '^[a-zA-Z0-9-* ]*$',
  /** START_END_AZ_DASH_AND_SPACE_SINGLE_QUOTE_ALLOWED_IN_MIDDLE */
  nameFormat = '^$|^[a-zA-Z]{1,2}$|^[a-zA-Z]{1}[a-zA-Z\' -]*[a-zA-Z]{1}$',
  ukPassportFormat = '^[0-9]*$',
  otherPassportFormat = '^[a-zA-Z0-9]*$',
  ukDvlaDrivingLicenseNumberFormat = '^(?=.{16}$)[A-Za-z]{1,5}9{0,4}[0-9](?:[05][1-9]|[16][0-2])(?:[0][1-9]|[12][0-9]|3[01])[0-9](?:99|[A-Za-z][A-Za-z9])(?![IOQYZioqyz01_])\\w[A-Za-z]{2}',
  defaultDrivingLicenseNumberFormat = "^(?!\\s)[a-zA-Z 0-9\\(\\)\\-/'&]+(?<!\\s)$",
  ukDvlaDrivingLicenseNumberLastCharsFormat = "^[A-Za-z0-9]{1,2}$"

}

export enum RuleLengths{
  ukPassportMinLength = 9,
  otherPassportMinLength = 0,
  maxLengthPassport = 11,
  drivingLicenseMaxLength = 18,
  drivingLicenseMinLength = 0,
  ukdvladrivingLicenseMinLength = 16
}


export enum RuleFormGroupNames {
  myProfileName = 'my-profile-name',
  myProfileAlias = 'my-profile-alias',
  residentialData = 'residential-data-address',
  internationalData = 'international-data',
  demoData = 'demo-data'
}

export enum RuleWorkflow {
  KYP = 'KYP',
  DEFAULT = 'DEFAULT'
}


/**
 * static rule definitions, need to be synced with RULE db.
 * These static rules are used for fallback if live service is down.
 *  **/
export class ValidationRulesConfig {


  /**
   * Demo data for PoC dynamic validators.
   * */
  static dynamicTestRules: Array<FieldValidationRule> = [

    {
      controlName: 'full_address',
      required: true,
      restrictions: [
        {
          weight: {
            max: 60
          }
        },
        {
          regexName: RuleRegexName.kypAddressFormat
        }
      ]
    },
    {
      controlName: 'date_of_birth',
      required: true,
      validators: [Validator.dateIsValid, Validator.dateNotInFuture],
      configurations: [{
        validator: Validator.dateNotBefore,
        duration: {days: 0},
        format: 'yyyy-MM-dd',
        value: '2022-05-01',
        inclusive: Inclusive.both,
        range: 'CLOSED'
      }]
    },
    {
      controlName: 'driving_licence_validity',
      required: true,
      validators: [Validator.dateIsValid, Validator.dateNotInFuture],
      configurations: [
        {
          validator: Validator.dateNotBefore,
          // duration: {years:1, months: 1, days:0},
          value: '2022-05-01',
          valueRef: BackendDataRef.dob,
          //controlRef: 'date_of_birth',
          format: 'yyyy-MM-dd',
          inclusive: Inclusive.none
        }
      ]
    },
    {
      controlName: 'bank_statement',
      required: true,
      format:'yyyy-MM-dd', // parent format, will be used for static validators
      validators: [Validator.dateIsValid, Validator.dateNotInFuture],
      configurations: [
        {
          validator: Validator.dateNotBefore,
          format: 'yyyy-MM-dd',
          //value: '2022-05-01', // this can be static, control or supplied on backend
          duration: {months: -3},// not before/ not older last three months
          inclusive: Inclusive.both, // it could be today or -3 months.
          range: 'CLOSED' // when OPEN, then it could go towards the future
        }
      ]
    },
    {
      controlName: 'employment_letter',
      required: true,
      format: 'yyyy-MM-dd',
      validators: [Validator.dateIsValid, Validator.dateNotInFuture],
      configurations: [
        {
          validator: Validator.dateAfter, // date must be after point in time
          format:'yyyy-MM-dd',
          // controlRef: '1, October, 2022'
          duration: {months:2}, // it will add 2 months to supplied value
          value: '2022-05-01',
          valueRef: BackendDataRef.dob,
          // inclusive: Inclusive.start
        }
      ]
    },
    {
      controlName: 'mother_dob',
      required: true,
      format: 'yyyy-MM-dd',
      validators: [Validator.dateIsValid, Validator.dateNotInFuture],
      configurations: [
        {
          validator: Validator.dateBefore, // date must be after point in time
          format:'yyyy-MM-dd',
          // controlRef: '1, October, 2022'
          duration: {months:-2}, // it will add 2 months before to supplied value
          value: '2022-05-01',
          valueRef: BackendDataRef.dob,
          // inclusive: Inclusive.start
        }
      ]
    },
    {
      controlName: 'exam_date', // want to describe range in future
      required: true,
      format:'yyyy-MM-dd',
      validators:[Validator.dateIsValid, Validator.dateNotInPast],
      configurations: [
        {
          validator: Validator.dateNotAfter,
          duration: {months:3},
          value: '2023-02-01',
          range: null,
          inclusive: null
        }
      ]
    },
    {
      controlName: 'email',
      required: true,
      validators: [Validator.email]
    },
    {
      controlName: 'email-confirm',
      required: true,
      validators: [Validator.email],
      configurations: [
        {
          validator: Validator.fieldMatchAnother,
          controlRef: 'email'
        }
      ]
    },
    {
      controlName: 'dynamic_date',
      required: true,
      // format: 'MMM dd.YYYY',
      validators: [Validator.dateIsValid, Validator.dateNotInFuture],
      configurations: [
        {
          validator: Validator.dateNotBefore,
          duration: {months: 3},
          inclusive: Inclusive.both
        }
      ]
    },
    {
      controlName: 'dynamic_date_ISO_DATE',
      required: true,
      // format: 'MMM dd.YYYY',
      validators: [Validator.dateIsValid, Validator.dateNotInFuture],
      configurations: [
        {
          validator: Validator.dateNotBefore,
          duration: {months: 3},
          inclusive: Inclusive.both
        }
      ]
    },
    {
      controlName: 'dob', // submit value
      //format: 'October 2, 2022'
      required: true,
      //validators: [Validator.dateIsValid, Validator.dateNotInFuture],
    },
    {
      controlName: 'dob_ISO_DATE', // date picker value
      required: true,
      validators: [Validator.dateIsValid, Validator.dateNotInFuture],
      configurations:[{
        validator: Validator.legalAge,
        duration: {years:-16},
        inclusive: Inclusive.start
      }]
    }
    // ,
    // {
    //   controlName:'birth_certificate', // submit value
    //   //format: 'October 2, 2022'
    //   validators: [Validator.dateIsValid, Validator.dateNotInPast],
    //   configurations: [{
    //     validator: Validator.dateAfter,
    //     valueRef: BackendDataRef.dob,
    //     duration:{years:1}
    //   }
    //   ]
    // },


  ];


  /**
   * KYP Residential Data Address form
   * **/
  static kypResidentialDataAddressRules: Array<FieldValidationRule> = [
    {
      controlName: 'full_address',
      required: true,
      restrictions: [
        {
          weight: {
            max: 60
          }
        },
        {
          regexName: RuleRegexName.kypAddressFormat
        }
      ]
    },
    {
      controlName: 'street_address',
      required: true,
      restrictions: [
        {
          weight: {
            max: 60
          }
        },
        {
          regexName: RuleRegexName.kypAddressFormat
        }
      ]
    },
    {
      controlName: 'zip',
      restrictions: [
        {
          weight: {
            max: 8
          }
        }
      ],
      validators: [Validator.conditionalZip, Validator.requiredIf]
    },
    {
      controlName: 'city',
      required: true,
      restrictions: [
        {
          weight: {
            max: 60
          }
        },
        {
          regexName: RuleRegexName.kypAddressFormat
        }
      ]
    },
    {
      controlName: 'hiddenCity',
      valueKeyOverride: 'city',
      restrictions: [
        {
          weight: {
            max: 60
          }
        },
        {
          regexName: RuleRegexName.kypAddressFormat
        }
      ], validators: [Validator.requiredIf]
    },
    {
      controlName: 'state_code',
      valueKeyOverride: 'state_name'
    },
    {
      controlName: 'hiddenState',
      valueKeyOverride: 'state_name',
      validators: [Validator.requiredIf]
    },
    {
      controlName: 'state_short_code',
      required: false
    },
    {
      controlName: 'state_name',
      valueKeyOverride: 'state_name',
      required: true,
      restrictions: [
        {
          weight: {
            max: 32
          }
        },
        {
          regexName: RuleRegexName.kypAddressFormat
        }
      ]
    },
    {
      controlName: 'valid_from',
      required: true
    },
    {
      controlName: 'permanentAddress',
      required: false
    },
    {
      controlName: 'hiddenPASyncId',
      valueKeyOverride: 'pa_sync_id',
      required: false
    },
    {
      controlName: 'hiddenZip',
      restrictions: [
        {
          weight: {
            max: 8
          }
        }
      ],
      validators: [Validator.conditionalZip, Validator.requiredIf]
    },
    {
      controlName: 'hiddenAddressLine2',
      valueKeyOverride: 'address_line_2',
      required: false,
      restrictions: [
        {
          weight: {
            max: 60
          }
        }, {
          regexName: RuleRegexName.kypAddressFormat
        }
      ]
    },
    {
      controlName: 'address_line_2',
      required: false,
      restrictions: [
        {
          weight: {
            max: 60
          }
        },
        {
          regexName: RuleRegexName.kypAddressFormat
        }
      ]
    },
    {
      controlName: 'address_appartment',
      required: true,
      restrictions: [
        {
          weight: {
            max: 60
          }
        },
        {
          regexName: RuleRegexName.kypAddressFormat
        }
      ]
    },
    {
      controlName: 'land_mark',
      restrictions: [
        {
          weight: {
            max: 60
          }
        },
        {
          regexName: RuleRegexName.kypAddressFormat
        }
      ]
    },
    {
      controlName: 'valid_to',
      required: true
    },
    {
      controlName: 'address_area',
      required: false,
      restrictions: [
        {
          weight: {
            max: 32
          }
        },
        {
          regexName: RuleRegexName.kypAddressFormat
        }
      ]
    }
  ];


  /**
   * Default Residential Data Address form
   * **/
  static defaultResidentialDataAddressRules: Array<FieldValidationRule> = [
    {
      controlName: 'full_address',
      required: true
    },
    {
      controlName: 'street_address',
      required: true,
      restrictions: [{weight: {max: 100}}],
      validators: [Validator.noSpace]
    },
    {
      controlName: 'zip',
      required: true,
      validators: [Validator.conditionalZip],
    },
    {
      controlName: 'city',
      required: true,
      validators: [Validator.noBlanks]
    },
    {
      controlName: 'hiddenCity',
      valueKeyOverride: 'city',
      required: true,
      validators: [Validator.noBlanks]
    },
    {
      controlName: 'state_code',
      valueKeyOverride: 'state_name',
      required:true
    },
    {
      controlName: 'hiddenState',
      valueKeyOverride: 'state_name',
      required: true,
      validators: [Validator.noBlanks, Validator.requiredIf]
    },
    {
      controlName: 'state_short_code',
      required: false
    },
    {
      controlName: 'state_name',
      valueKeyOverride: 'state_name',
      required: false
    },
    {
      controlName: 'valid_from',
      required: true,
    },
    {
      controlName: 'permanentAddress',
      required: false
    },
    {
      controlName: 'hiddenPASyncId',
      valueKeyOverride: 'pa_sync_id',
      required: false
    },
    {
      controlName: 'hiddenZip',
      required: false,
      validators: [Validator.conditionalZip, Validator.requiredIf]
    },
    {
      controlName: 'hiddenAddressLine2',
      valueKeyOverride: 'address_line_2',
      required:true
    },
    {
      controlName: 'address_line_2',
      restrictions: [{weight: {max: 100}}],
      required:true
    },
    {
      controlName: 'address_appartment',
      restrictions: [{weight: {max: 100}}],
      required:true
    },
    {
      controlName: 'land_mark',
      restrictions: [{weight: {max: 100}}],
      required:true
    },
    {
      controlName: 'valid_to',
      required: true
    },
    {
      controlName: 'address_area',
      required: false,
      restrictions: [
        {
          weight: {
            max: 32
          }
        },
        {
          regexName: RuleRegexName.kypAddressFormat
        }
      ]
    }
  ];


  /**
   * Default My Profile Alias form
   * **/
  static defaultMyProfileNameRules: Array<FieldValidationRule> = [
    {
      controlName: 'title',
      validators: [Validator.requiredIf, Validator.allowedValueOrLabels]
    },
    {
      controlName: 'firstname',
      required: true,
      restrictions: [{weight: {max: 50}}],
      validators: [Validator.noBlanks]
    },
    {
      controlName: 'middlename',
      required: false,
      restrictions: [{weight: {max: 100}}]
    },
    {
      controlName: 'middlename2',
      valueKeyOverride: 'middle_name_2',
      required: false,
      restrictions: [{weight: {max: 100}}]
    },
    {
      controlName: 'middlename3',
      valueKeyOverride: 'middle_name_3',
      required: false,
      restrictions: [{weight: {max: 100}}]
    },
    {
      controlName: 'lastname',
      required: true,
      restrictions: [{weight: {max: 50}}],
      validators: [Validator.noBlanks],
    },
    {
      controlName: 'suffix',
      required: false,
      restrictions: [{weight: {max: 3}}],
    },
    {
      controlName: 'confirmName',
      required: false,
      validators: [Validator.requiredIf]
    }
  ];

  /**
   * KYP My Profile Name form
   * **/
  static kypMyProfileNameRules: Array<FieldValidationRule> = [
    {
      controlName: 'title',
      required: true,
      validators: [Validator.requiredIf, Validator.allowedValueOrLabels]
    },
    {
      controlName: 'firstname',
      required: true,
      restrictions: [{weight: {max: 60}},
        {
          regexName: RuleRegexName.nameFormat
        }]

    },
    {
      controlName: 'middlename',
      required: false,
      restrictions: [{weight: {max: 30}}, {
        regexName: RuleRegexName.nameFormat
      }]
    },
    {
      controlName: 'middlename2',
      valueKeyOverride: 'middle_name_2',
      required: false,
      restrictions: [{weight: {max: 30}},
        {
          regexName: RuleRegexName.nameFormat
        }]
    },
    {
      controlName: 'middlename3',
      valueKeyOverride: 'middle_name_3',
      required: false,
      restrictions: [{weight: {max: 30}},
        {
          regexName: RuleRegexName.nameFormat
        }]
    },
    {
      controlName: 'lastname',
      required: true,
      restrictions: [{weight: {max: 60}}, {
        regexName: RuleRegexName.nameFormat
      }],
    },
    {
      controlName: 'suffix',
      required: false,
      restrictions: [{weight: {max: 3}}],
    },
    {
      controlName: 'confirmName',
      required: false,
      validators: [Validator.requiredIf]
    }
  ];

  /**
   * Default My Profile Alias form
   * **/
  static defaultMyProfileAliasRules: Array<FieldValidationRule> = [
    {
      controlName: 'firstname',
      valueKeyOverride: 'first_name',
      required: true
    },
    {
      controlName: 'middlename',
      valueKeyOverride: 'middle_name',
      required: false,
      restrictions: [{weight: {max: 50}}]
    },
    {
      controlName: 'middlename2',
      valueKeyOverride: 'middle_name_2',
      required: false,
      restrictions: [{weight: {max: 100}}]
    },
    {
      controlName: 'middlename3',
      valueKeyOverride: 'middle_name_3',
      required: false,
      restrictions: [{weight: {max: 100}}]
    },

    {
      controlName: 'lastname',
      valueKeyOverride: 'last_name',
      required: true,
      restrictions: [{weight: {max: 50}}]
    },
    {
      controlName: 'storedLastName',
      valueKeyOverride: 'last_name',
      required: false
    },
    {
      controlName: 'suffix',
      required: false,
      restrictions: [{weight: {max: 3}}],
    },
    {
      controlName: 'startDate',
      valueKeyOverride: 'start-date',
      required: false
    },
    {
      controlName: 'endDate',
      valueKeyOverride: 'end-date',
      required: false
    },
    {
      controlName: 'pa_sync_id',
      required: false
    }
  ];

  /**
   * KYP My Profile Alias form
   * **/
  static kypMyProfileAliasRules: Array<FieldValidationRule> = [
    {
      controlName: 'firstname',
      valueKeyOverride: 'first_name',
      required: true,
      restrictions: [{weight: {max: 60}}, {
        regexName: RuleRegexName.nameFormat
      }]
    },
    {
      controlName: 'middlename',
      valueKeyOverride: 'middle_name',
      required: false,
      restrictions: [{weight: {max: 30}}, {
        regexName: RuleRegexName.nameFormat
      }]
    },
    {
      controlName: 'middlename2',
      valueKeyOverride: 'middle_name_2',
      required: false,
      restrictions: [{weight: {max: 100}}, {
        regexName: RuleRegexName.nameFormat
      }]
    },
    {
      controlName: 'middlename3',
      valueKeyOverride: 'middle_name_3',
      required: false,
      restrictions: [{weight: {max: 100}}, {
        regexName: RuleRegexName.nameFormat
      }]
    },
    {
      controlName: 'lastname',
      valueKeyOverride: 'last_name',
      required: true,
      restrictions: [{weight: {max: 60}}, {
        regexName: RuleRegexName.nameFormat
      }]
    },
    {
      controlName: 'storedLastName',
      valueKeyOverride: 'last_name',
      required: false
    },
    {
      controlName: 'suffix',
      required: false,
      restrictions: [{weight: {max: 3}}],
    },
    {
      controlName: 'startDate',
      valueKeyOverride: 'start-date',
      required: false
    },
    {
      controlName: 'endDate',
      valueKeyOverride: 'end-date',
      required: false
    },
    {
      controlName: 'pa_sync_id',
      required: false
    }
  ];

}
