<div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
  <div class="group" [formGroup]="group">
    <label class="placeholder_white custom_label" [attr.for]="config.id" [class.labelEllipsis]="config.label && config.label.length > 30">
      {{config.label|translate}}
    </label>
    <a data-toggle="popover" data-placement="bottom" title="{{config.label|translate}}"
      *ngIf="config.hint != 'NoLabelRender' && config.label && config.label.length > 30">
      <i class="icon-info labelHelpIcon" (click)="showFullLabelText()"></i>
    </a>
    <input type="number" class="form-control " [formControlName]="config.name" validateSpaceInput
      [value]="config.value | interpolate | async" [attr.id]="config.id" [readonly]="readonly">
  </div>
</div>