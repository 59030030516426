<div class="col-xs-12 col-md-12 m-b-20" [ngClass]="{'col-md-4': config.hint=='blank'}">
  <div class="group" [formGroup]="group">
    <label class="radioLabel displayBlock" for="config.name">
      <!-- {{displayLabel}} -->
      <span [innerHTML]="displayLabel | interpolate | async"></span>
      <!-- <span *ngIf="config['label']=='CONFIRMATION_TEXT'">
        {{'COMMONS_PLEASE' | translate}}
        <a (click)="downloadProcess(remoteId,fileName,location)">{{'COMMONS_CLICK_HERE' | translate}}</a>
        {{'CONFIRMATION_TEXT' | translate}}
      </span> -->
    </label>
  </div>
</div>
