import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'framework/localstorage.service';

@Component({
  selector: 'expired-invite',
  templateUrl: './expired-invite.component.html'
})

export class ExpiredInviteComponent implements OnInit {
  isInviteDelete: boolean = false;
  inviteType: string = '';
  constructor(private localStorage: LocalStorageService) { }

  ngOnInit() {
    this.inviteType = !!this.localStorage.getItem('inviteType') ? this.localStorage.getItem('inviteType').toUpperCase() : '';

    if (this.localStorage.getItem('key') === undefined || this.localStorage.getItem('key') === null || this.localStorage.getItem('key') === "") {
      this.isInviteDelete = true;
    }

  }
}
