import { Injectable } from '@angular/core';
import * as dateutils from './utils/date.utils';

interface Datefromanddatetodates {
    datefrom: String;
    dateto: String;
    id: any;
};

interface DatefromAndDatetoGap {
    gapStartDate: String;
    gapEndDate: String;
    gap: number;
    startId: any;
    endId: any;
    gapstatus: string;
}

@Injectable()
export class GapFindService {
    residentialDataList: any;
    monthNames: any = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    private gapFromAndTo: any[] = [];
    private gapLimitDays: number;
    private gapLimitMonth: number;
    private gapLimitYears: number;
    private gapLimit: number;
    private noGapEndDate: Date;
    private currentDate: any;
    private configObj: any;
    private isPresentAdd: boolean = true;
    private completedMonths: number = 0;

    constructor() {
        let curDt = new Date();
        let curYr = curDt.getFullYear();
        let curMonth: any = curDt.getMonth() + 1;
        curMonth = (curMonth < 10) ? "0" + curMonth : curMonth;
        let curDay = "01";
        this.currentDate = curYr + "-" + curMonth + "-" + curDay;
    }

    gapFind(objArr, configObjVal) {
        let dtobj: any;
        dtobj = objArr;
        this.configObj = configObjVal;
        this.gapLimit = this.configObj['max_gap_allowed'];
        let module = this.configObj['for'] || null;

        for (var i = 0; i < dtobj.length; i++) {
            if (dtobj[i].valid_from !== undefined) {
                dtobj[i].valid_from = this.getChangeFormatToYMD(dtobj[i].valid_from);
            }
            if (dtobj[i].valid_to !== undefined) {
                dtobj[i].valid_to = this.getChangeFormatToYMD(dtobj[i].valid_to);
            }
        }

        let dateObj: any = dtobj;
        dateObj.sort(this.custom_sort);
        dateObj.sort(this.custom_sort_to);
        dateObj.sort(this.custom_sort);

        this.gapFromAndTo = [];
        let isPreAddFlagArr = [];
        let isPresentAddress;  //obj
        let minFromDt = [];
        let reformatobj = [];

        for (var i = 0; i < dateObj.length; i++) {
            if (dateObj[i].valid_from && dateObj[i].valid_to == undefined) {
                minFromDt.push(dateObj[i]);
            } else {
                reformatobj.push(dateObj[i]);
            }
        }

        if (minFromDt.length > 0) {
            reformatobj.splice(0, 0, { "valid_from": minFromDt[minFromDt.length - 1].valid_from, "id": minFromDt[minFromDt.length - 1].id })
        }

        for (var i = 0; i < reformatobj.length; i++) {
            if (this.configObj['mode'] == 'EDIT') {
                if (reformatobj[i].valid_from && reformatobj[i].valid_to != undefined && reformatobj[i].valid_to) {
                    isPreAddFlagArr.push(true);
                } else {
                    isPreAddFlagArr.push(false);
                }
            }

            let datefromobj = new Date(reformatobj[i].valid_from);
            let datetoobj = new Date(reformatobj[i].valid_to);

            if (reformatobj[i].valid_to == undefined || datefromobj <= datetoobj) {
                this.pushToGapfromAndTo(reformatobj[i]);
            }
        }

        var datefromAnddatetoGap = [];
        if (this.gapFromAndTo.length > 1) {
            let monthDiff = false;
            let daysDiff = false;
            let yearsDiff = false;

            if (this.configObj['gap_unit'] == "MONTHS" || this.configObj['gap_unit'] == "month") {
                monthDiff = true;
            }
            datefromAnddatetoGap = this.findGaps(daysDiff, monthDiff, yearsDiff);
            // console.log(moveinAndMoveoutGap,"....GAP ARRAY....");
        }

        let maxYrCompletedDt = dateutils.getMinYearCompleteDate(this.configObj);

        if (isPreAddFlagArr.length > 0) {
            isPresentAddress = this.isInArray(isPreAddFlagArr, false);

            if (!isPresentAddress) {
                let todate = this.currentDate;
                let monthDifference = dateutils.monthDiffOfDates(dateObj[0].valid_to, todate);
                if (monthDifference > this.gapLimit) {
                    datefromAnddatetoGap.splice(0, 0, { "gapStartDate": dateObj[0].valid_to, "gapEndDate": this.currentDate, "gap": monthDifference, "gapstatus": "head", startId: dateObj[0].id, endId: null });
                }
            }

            if (maxYrCompletedDt < dateutils.getDateobj(dateObj[dateObj.length - 1].valid_from)) {
                let month: any = maxYrCompletedDt.getMonth() + 1;
                month = (month <= 9) ? "0" + month.toString() : month.toString();
                let maxyrDt = maxYrCompletedDt.getFullYear() + "-" + month + '-' + '01';
                let monthDifference = dateutils.monthDiffOfDates(maxyrDt, dateObj[dateObj.length - 1].valid_from);
                if (monthDifference > 0) {
                    datefromAnddatetoGap.splice(datefromAnddatetoGap.length, 0, { "gapStartDate": maxyrDt, "gapEndDate": dateObj[dateObj.length - 1].valid_from, "gap": monthDifference, "gapstatus": "tail", startId: null, endId: dateObj[dateObj.length - 1].id });
                }
            }
        }

        if (this.configObj['for'] == 'ADDRESSES') { //date formmat to MMM YYYY eg: MARCH 2018
            for (var i = 0; i < dtobj.length; i++) {
                dtobj[i].valid_from = dateutils.getDateFormatMonthYear(dtobj[i].valid_from, this.monthNames);
                if (dtobj[i].valid_to != undefined) {
                    dtobj[i].valid_to = dateutils.getDateFormatMonthYear(dtobj[i].valid_to, this.monthNames);
                }
            }
        }

        return datefromAnddatetoGap;
    }

    isInArray(array, search) {
        return array.indexOf(search) >= 0;
    }

    findGaps(dateDiffFlag, monthDiffFlag, yearDiffFlag) {
        var datefromAnddatetoGaps: DatefromAndDatetoGap[] = [];

        if (this.gapFromAndTo.length > 1) {
            for (var i = 1; i < this.gapFromAndTo.length; i++) {
                //let monthDiff = this.monthDiffOfDates(this.moveinandout[i].moveoutdate,this.moveinandout[i - 1].moveindate);
                if (dateDiffFlag) {
                    let monthDiff = dateutils.dateDiffInDays(this.gapFromAndTo[i].dateto, this.gapFromAndTo[i - 1].datefrom);
                    if (monthDiff > this.gapLimit) {
                        let datefromAnddatetoGap: DatefromAndDatetoGap = { startId: this.gapFromAndTo[i - 1].id, endId: this.gapFromAndTo[i].id, gapStartDate: this.gapFromAndTo[i].dateto, gapEndDate: this.gapFromAndTo[i - 1].datefrom, gap: monthDiff, gapstatus: "between" }
                        if (datefromAnddatetoGaps.length === 0) {
                            datefromAnddatetoGaps.push(datefromAnddatetoGap);
                        } else {
                            datefromAnddatetoGaps[datefromAnddatetoGaps.length] = datefromAnddatetoGap;
                        }
                    }
                }
                if (monthDiffFlag) {
                    let monthDiff = dateutils.monthDiffOfDates(this.gapFromAndTo[i].dateto, this.gapFromAndTo[i - 1].datefrom);
                    if (monthDiff > this.gapLimit) {
                        let datefromAnddatetoGap: DatefromAndDatetoGap = { startId: this.gapFromAndTo[i - 1].id, endId: this.gapFromAndTo[i].id, gapStartDate: this.gapFromAndTo[i].dateto, gapEndDate: this.gapFromAndTo[i - 1].datefrom, gap: monthDiff, gapstatus: "between" }
                        if (datefromAnddatetoGaps.length === 0) {
                            datefromAnddatetoGaps.push(datefromAnddatetoGap);
                        } else {
                            datefromAnddatetoGaps[datefromAnddatetoGaps.length] = datefromAnddatetoGap;
                        }
                    }
                }

                if (yearDiffFlag) {
                    let monthDiff = dateutils.dateDiffInYears(this.gapFromAndTo[i].dateto, this.gapFromAndTo[i - 1].datefrom);
                    if (monthDiff > this.gapLimit) {
                        let datefromAnddatetoGap: DatefromAndDatetoGap = { startId: this.gapFromAndTo[i - 1].id, endId: this.gapFromAndTo[i].id, gapStartDate: this.gapFromAndTo[i].dateto, gapEndDate: this.gapFromAndTo[i - 1].datefrom, gap: monthDiff, gapstatus: "between" }
                        if (datefromAnddatetoGaps.length === 0) {
                            datefromAnddatetoGaps.push(datefromAnddatetoGap);
                        } else {
                            datefromAnddatetoGaps[datefromAnddatetoGaps.length] = datefromAnddatetoGap;
                        }
                    }
                }

            }
        }

        return datefromAnddatetoGaps;
    }

    custom_sort(a, b) {
        if (a.valid_from) {
            a = a.valid_from.replace(/-/g, '/')
        }
        if (b.valid_from) {
            b = b.valid_from.replace(/-/g, '/')
        }

        return new Date(b).getTime() - new Date(a).getTime();
    }

    custom_sort_to(a, b) {
        if (a.valid_to) {
            a = a.valid_to.replace(/-/g, '/')
        }
        if (b.valid_to) {
            b = b.valid_to.replace(/-/g, '/')
        }

        return new Date(b).getTime() - new Date(a).getTime();
    }

    pushToGapfromAndTo(obj) {
        let gapFromandTodates: Datefromanddatetodates;

        if (obj['valid_to'] === undefined) {
            //let ct = this.currentDate;
            gapFromandTodates = { datefrom: obj['valid_from'], dateto: this.currentDate, id: obj['id'] }
            this.gapFromAndTo.push(gapFromandTodates);
        } else {
            gapFromandTodates = {
                datefrom: obj['valid_from'],
                dateto: obj['valid_to'],
                id: obj['id']
            };

            let isDatesOverlapped: Boolean = false;
            let tempdatefromtodates: Datefromanddatetodates;

            for (var i = 0; i < this.gapFromAndTo.length; i++) {
                tempdatefromtodates = this.gapFromAndTo[i];
                if (dateutils.getDateobj(gapFromandTodates.datefrom) >= dateutils.getDateobj(tempdatefromtodates.datefrom) &&
                    dateutils.getDateobj(gapFromandTodates.dateto) <= dateutils.getDateobj(tempdatefromtodates.dateto)) {
                    isDatesOverlapped = true;
                }

                if (dateutils.getDateobj(gapFromandTodates.datefrom) < dateutils.getDateobj(tempdatefromtodates.datefrom) &&
                    dateutils.getDateobj(gapFromandTodates.dateto) > dateutils.getDateobj(tempdatefromtodates.dateto)) {
                    this.gapFromAndTo.splice(i, 1);
                }
            }
            for (var i = 0; i < this.gapFromAndTo.length; i++) {
                tempdatefromtodates = this.gapFromAndTo[i];
                if (dateutils.getDateobj(gapFromandTodates.datefrom) > dateutils.getDateobj(tempdatefromtodates.datefrom))
                    break;
            }
            if (!isDatesOverlapped) {
                if (i === this.gapFromAndTo.length) {
                    this.gapFromAndTo[i] = gapFromandTodates;
                } else {
                    //this.moveinandout.splice(i,0,moveinoutdates);
                    for (var j = this.gapFromAndTo.length; j > i; j--) {
                        this.gapFromAndTo[j] = this.gapFromAndTo[j - 1];
                    }
                    this.gapFromAndTo[i] = gapFromandTodates;
                }
            }
        }
    }

    getChangeFormatToYMD(dateString) {
        let dt;
        let dtTime;
        let month;

        if (typeof dateString === 'number') {
            let d = new Date(dateString);
            month = d.getMonth() + 1;
            month = (month <= 9) ? "0" + month.toString() : month.toString();
            dt = d.getFullYear() + '-' + month + '-' + "01";
        } else {
            let monthYear = dateString.split(" ");

            if (monthYear[1] == undefined) {
                dt = dateString;
                // dt = dt.replace(/-/g, '/')
            } else {
                month = (this.monthNames.indexOf(monthYear[0])).toString();
                month = parseInt(month) + 1;
                month = (month <= 9) ? "0" + month : month;
                let year = monthYear[1];
                let dd = "01";
                dt = year + "-" + month + '-' + dd;
            }
        }

        return dt;
    }

    fullDateGetChangeFormatToYMD(dateString) {
        let dt;
        let dtTime;
        let month;

        if (typeof dateString === 'number') {
            let d = new Date(dateString);
            month = d.getMonth() + 1;
            month = (month <= 9) ? "0" + month.toString() : month.toString();
            dt = d.getFullYear() + '-' + month + '-' + (d.getDate() <= 9) ? "0" + d.getDate() : d.getDate();
        } else {
            if (dateString.split(" ")[0] == undefined) {
                dt = dateString;
                // dt = dt.replace(/-/g, '/')
            } else {
                month = (this.monthNames.indexOf(dateString.split(" ")[0])).toString();
                month = parseInt(month) + 1;
                month = (month <= 9) ? "0" + month : month;
                let year = dateString.split(" ")[1].split(",")[1];
                let day = dateString.split(" ")[1].split(",")[0];
                let dd =  (parseInt(day) <= 9) ? "0" + parseInt(day): day;
                dt = year + "-" + month + '-' + dd;
            }
        }

        return dt;
    }
 
    createCloneObject(sourceObj) {
        /**getting minimum date */
        var duplicateObjDataList = [];
        for (var i = 0; i < sourceObj.length; i++) {
            //console.log(objarr[i]+" ");
            //duplicateObj.push( objarr[i]);
            duplicateObjDataList.push(Object.assign({}, sourceObj[i]));
        }
        return duplicateObjDataList;
        /*****************/
    }

    getFindMinimumDate(objVal, fullDate) {
        //this.createCloneObject();
        for (var i = 0; i < objVal.length; i++) {
            objVal[i].valid_from = fullDate ? this.fullDateGetChangeFormatToYMD(objVal[i].valid_from) : this.getChangeFormatToYMD(objVal[i].valid_from);

            if (objVal[i].valid_to != undefined) {
                objVal[i].valid_to = fullDate ? this.fullDateGetChangeFormatToYMD(objVal[i].valid_to) : this.getChangeFormatToYMD(objVal[i].valid_to);
            }
        }

        objVal.sort(this.custom_sort);

        return objVal;
    }
}
