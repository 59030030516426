<div class="col-xs-12 col-md-12 m-b-20">
  <div class="group" [formGroup]="group" ng-style="clear:both; width: 100%;">
    <label class="placeholder_white custom_label labelEllipsis" for="country">
      {{config.label|translate}}
    </label>
    <textarea type="textarea" class="form-control" [ngClass]="{dynamicTextArea: getRowsCount(config.name) !== 1}"
      [rows]="getRowsCount(config.name)" cols="50" [formControlName]="config.name"
      [maxLength]="!!config['validation_data'] && !!config['validation_data']['max_length'] ? config['validation_data']['max_length'] : 500">
      </textarea>
  </div>
</div>
