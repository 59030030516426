<div *ngIf="!isDynamicDocumentUpload">
  <i class="icon-info globalCustomInstructions" tabindex="0" *ngIf="isCustomInstruction" (click)="setCustomInstruction()"></i>
    
  <div class="mobile_container anime-clean posrel"
    [class.slideInRight]="(currentSlideWithDirection === 'mandatoryDocs_right') ? true:false"
    [class.slideInLeft]="(currentSlideWithDirection === 'mandatoryDocs_left') ? true:false"
    [class.hide]="(currentSlide !== 'mandatoryDocs_') ? true:false">
    <mandatory-documents [inputGroupObject]="mandatoryGroupData" [inputIsDocEmptyList]="isDocEmptyList"
      [inputEnableNextButton]="enableNextButton" [checkIsIntlGID100P]="isIntlGID100P"
      [checkIsIntlPRNCrim]="isIntlPRNCrim" [inputIs100pTotalPointsReq]="is100pTotalPointsReq"
      [checkIsIntlRegNzNzpv]="isIntlRegNzNzpv" [checkIsAFP100P]="isIntlAFP100P"
      [inputIsAFPTotalPointsReq]="isAFPTotalPointsReq" [inputIsCrimTotalPointsReq]="isCrimTotalPointsReq"
      [configOptions]="{PARENT_CONFIG: PARENT_STAGE_STEP_CONFIG}" [inputIsOptionalDocsPresent]="isOptionalDocsPresent"
      [inputIs100PFeatureAvailable]="is100PCalculationFeatureAvailable"
      [inputAUCrim100PCalculationAvailable]="aUCrim100PCalculationAvailable"
      [inputAFP100PCalculationAvailable]="aFP100PCalculationAvailable" [inputFromEnhancedDashboard]="isFromEnhancedDashboard"
      (outputUploadAction)="uploadAction($event)"
      (outputDocumentOnDelete)='deleteAction($event)' (outputDocumentOnDownload)='downloadAction($event)'
      (outputDocumentOnView)='viewAction($event)' (outputDocumentOnInstruction)='instructionAction($event)'
      (outputDownloadInstructionGuide)="downloadInstructionGuide($event)"
      (outputOnSaveExitClick)="onSaveExitClick($event)" (outputOnNextClick)="onNextClick($event)">
    </mandatory-documents>
  </div>

  <form [formGroup]="documentUploadForm" class="" *ngIf="showSkippedDocSlide && isFormInit">
    <div formArrayName="documentUploadSubForm">
      <div class="mobile_container anime-clean posrel" *ngFor="let documentUploadSubForm of documentUploadForm.get('documentUploadSubForm')['controls']; let i=index" 
        [class.slideInRight]="(currentSlideWithDirection === 'skippedDocs_right') ? true:false"
        [class.slideInLeft]="(currentSlideWithDirection === 'skippedDocs_left') ? true:false"
        [class.hide]="(currentSlide !== 'skippedDocs_') ? true:false">
        <skipped-documents-details [inputSkippedDocumentsData]="skippedDocumentsGroupData" [group]="documentUploadForm.get('documentUploadSubForm')['controls'][i]"
          [configOptions]="{PARENT_CONFIG: PARENT_STAGE_STEP_CONFIG}" [maxNoOfDaysSkippable]="docSkipSubmissionPreference"
          (outputSkippedLeftSwipe)="onSkippedPrevSwipe()" (onDocSubmissionDetails)="onNextClick($event)"></skipped-documents-details>
      </div>
    </div>
  </form>
  
  <div class="mobile_container anime-clean posrel"
    [class.slideInRight]="(currentSlideWithDirection === 'optionalDocs_right') ? true:false"
    [class.slideInLeft]="(currentSlideWithDirection === 'optionalDocs_left') ? true:false"
    [class.hide]="(currentSlide !== 'optionalDocs_') ? true:false">
    <optional-documents [inputOptionalGroupObject]="optionalGroupData"
      [configOptions]="{PARENT_CONFIG: PARENT_STAGE_STEP_CONFIG}" [inputOptionalIsDocEmptyList]="isDocEmptyList" [isInviteEiaf]="isInviteEiaf"
      [inputIsOptionalDocsPresent]="isOptionalDocsPresent" (outputOptionalUploadAction)="uploadAction($event)" 
      (outputOptionalDocumentOnDelete)='deleteAction($event)'
      (outputOptionalDocumentOnDownload)='downloadAction($event)' (outputOptionalDocumentOnView)='viewAction($event)'
      (outputOptionalDocumentOnInstruction)='instructionAction($event)'
      (outputOptionalDownloadInstructionGuide)="downloadInstructionGuide($event)"
      (outputOptionalOnSaveExitClick)="onSaveExitClick($event)" (outputOptionalOnNextClick)="onNextClick($event)"
      (outputOptionalLeftSwipe)="onOptionalPrevSwipe()"></optional-documents>
  </div>
</div>

<div *ngIf="isDynamicDocumentUpload">
    <i class="icon-info globalCustomInstructions" tabindex="0" *ngIf="!miDocument.isInstructionShow" (click)="setDynamicCustomInstruction()"></i>
    <div class="mobile_container anime-clean posrel slideInRight"
    [class.hide]="(currentSlide !== 'dynamicDocumentUpload' ? true : false)">
      <mandatory-documents [miDocument]="miDocument" [isDynamicDocumentUpload]="true" [inputGroupObject]="mandatoryGroupData" [inputIsDocEmptyList]="isDocEmptyList"
        [inputEnableNextButton]="enableNextButton" [checkIsIntlGID100P]="isIntlGID100P"
        [checkIsIntlPRNCrim]="isIntlPRNCrim" [inputIs100pTotalPointsReq]="is100pTotalPointsReq"
        [checkIsIntlRegNzNzpv]="isIntlRegNzNzpv" [checkIsAFP100P]="isIntlAFP100P"
        [inputIsAFPTotalPointsReq]="isAFPTotalPointsReq" [inputIsCrimTotalPointsReq]="isCrimTotalPointsReq"
        [configOptions]="{PARENT_CONFIG: PARENT_STAGE_STEP_CONFIG}" [inputIsOptionalDocsPresent]="isOptionalDocsPresent"
        [inputIs100PFeatureAvailable]="is100PCalculationFeatureAvailable"
        [inputAUCrim100PCalculationAvailable]="aUCrim100PCalculationAvailable"
        [inputAFP100PCalculationAvailable]="aFP100PCalculationAvailable" (outputUploadAction)="uploadAction($event)"
        (outputDocumentOnDelete)='deleteAction($event)' (outputDocumentOnDownload)='downloadAction($event)'
        (outputDocumentOnView)='viewAction($event)' (outputDocumentOnInstruction)='instructionAction($event)'
        (outputDownloadInstructionGuide)="downloadInstructionGuide($event)"
        (outputOnSaveExitClick)="onSaveExitClick($event)" (outputOnNextClick)="onNextClick($event)"
        (outputOnSkipNowClick)="onSkipNowClick()">
      </mandatory-documents>
    </div>
</div>


<!-- Upload document Modal - Starts -->

<div class="modal fade custom_modal" id="uploadDocuments_Modal" tabindex="-1" role="Application" aria-labelledby="docUploadProvideStatus" data-backdrop="static"
  data-keyboard="false">
  <div class="modal-dialog uploadDocumentModel">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" [class.hide]="(modalCurrentSlide === 'docUpload_wait') ? true : false"
          data-dismiss="modal" (click)="closeUploadDocumentModal()">&times;</button>
      </div>
      <div class="modal-body transparentbg">
        <div class="posrel" [class.hide]="(modalCurrentSlide !== 'docUpload_options') ? true : false"
          (onGesture)="doSwipe($event)" hammer-gestures>
          <div class="mobile_container contetnheight document_upload_area posrel">
            <div class="row arrowpadding">
              <div class="col-md-12 col-xs-12 col-sm-12">
                <div class="header_txt_white" >
                  <h2 id="docUploadProvideStatus">{{ 'DOCUMENT_UPLOAD_OPTIONS_HEADER' | translate:[firstName] }}</h2>
                  <h4 class="m-t-20 docUploadtakePicture">{{ 'DOCUMENT_UPLOAD_OPTIONS_SUBHEADER' | translate }}</h4>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-xs-12 col-sm-12 col-lg-6">
                <div class="imagePreviewContainer">
                  <img src="../../assets/img/DL_copy.jpg" class="uploadImageExp" title="Upload image">
                </div>
              </div>
            </div>
            <div class="row fixedbtn arrowpadding">
              <div class="col-xs-12 col-sm-5 col-md-3">
                <button type="button" class="btn button_wbg grow font_extrabold"
                  (click)="optionsUploadClick()">{{ 'DOCUMENT_UPLOAD' | translate }}</button>
              </div>
              <div class="col-xs-12 col-sm-5 col-md-3 docUploadtakePicture">
                <button type="button" class="btn button_wbg grow font_extrabold"
                  (click)="optionsTakePicClick()">{{ 'AMVR_CAPTURE_BTN2' | translate }}</button>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>

        <div class="posrel" tabindex="0" role="Application" aria-labelledby="selectDocUploadStatus" [class.hide]="(modalCurrentSlide !== 'docUpload_upload') ? true : false"
          (onGesture)="doSwipe($event)" hammer-gestures>
          <button type="button" class="btn arrowleftfl" (click)="goBackToUploadOptions()"><i
              class="icon-arrow-left pull-left" role="img" [attr.aria-label]="'SCREEN_BACKWARD_ARROW' | translate"></i></button>
          <div class="mobile_container contetnheight posrel">
            <div class="row marginbott40 arrowpadding">
              <div class="col-md-12 col-xs-12 col-sm-12">
                <div class="header_txt_white">
                  <h2 id="selectDocUploadStatus">{{ 'DOCUMENT_MODAL_UPLOAD_HEADER' | translate:[firstName] }}</h2>
                  <h4 class="m-t-20">{{ 'DOCUMENT_MODAL_UPLOAD_SUBHEADER' | translate }}</h4>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12 uploadMainArea">
                <multiple-file-upload (toggleNextButton)="onToggleNextButtonOutput($event)"
                  (multipleFileOutputEmit)="onMultipleFilesOutput($event)"
                  (multipleFileValidationErrorEmit)="onMultipleFilesValidationError($event)"
                  [inputResetUploadedFile]="resetUploadedFile" [resetList]="inputResetFilesList"
                  [inputUploadNextClick]="onUploadNextClick"></multiple-file-upload>
              </div>
            </div>
            <div class="row arrowpadding">
              <div class="col-xs-12 col-sm-5 col-md-3">
                <button type="button" class="btn button_wbg font_extrabold" [disabled]="!btnEnabled"
                  [class.grow]="btnEnabled" (click)="uploadNextClick()">{{ 'COMMONS_NEXT' | translate }}</button>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>

        <div class="posrel" [class.hide]="(modalCurrentSlide !== 'docUpload_camera') ? true : false"
          (onGesture)="doSwipe($event)" hammer-gestures>
          <button type="button" class="btn arrowleftfl" (click)="goBackToUploadOptions()"><i
              class="icon-arrow-left pull-left" role="img" [attr.aria-label]="'SCREEN_BACKWARD_ARROW' | translate"></i></button>
          <div class=" mobile_container contetnheight posrel">
            <div class="row marginbottom15 arrowpadding">
              <div class="col-md-12 col-xs-12 col-sm-12">
                <div class="header_txt_white">
                  <h2 id="docUploadProvideStatus">{{ 'DOCUMENT_MODAL_CAMERA_HEADER' | translate:[firstName] }}</h2>
                  <h4 class="m-t-20">{{ 'DOCUMENT_MODAL_CAMERA_SUBHEADER' | translate }}<span *ngIf="isMobileDevice">.</span> 
                    <!-- <span style="text-transform: lowercase;" *ngIf="!isMobileDevice">{{ 'DOCUMENT_MODAL_CAMERA_SUBHEADER_MOBILE' | translate }}</span> -->
                  </h4>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <camera [inputCamera]="startCamera" [inputNextClick]="onCameraNextClick"
                  (imageListEmit)="onMultipleFilesOutput($event)" [resetList]="inputResetFilesList"
                  (toggleCamNextButton)="onToggleCamNextButtonOutput($event)"
                  (cameraError)="onCameraInitializeError($event)"></camera>
              </div>
            </div>
            <div class="row fixedbtn arrowpadding">
              <div class="col-xs-12 col-sm-4 col-md-3">
                <button type="button" class="btn button font_extrabold" [disabled]="!camBtnEnabled"
                  [class.grow]="camBtnEnabled" (click)="cameraNextClick()">{{ 'COMMONS_NEXT' | translate }}</button>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
        <div class="posrel" [class.hide]="(modalCurrentSlide !== 'docUpload_fileExt_Error') ? true : false"
          (onGesture)="doSwipe($event)" hammer-gestures>
          <div class=" mobile_container contetnheight posrel">
            <div class="row marginbott40 arrowpadding">
              <div class="col-md-12 col-xs-12 col-sm-12">
                <div class="header_txt_white">
                  <h2 id="docUploadProvideStatus"> {{ 'DOCUMENT_UPLOAD_FILE_EXT_ERROR_HEADER' | translate }}</h2>
                </div>
              </div>
            </div>
            <div class="row fixedbtn arrowpadding">
              <div class="col-xs-12 col-sm-4 col-md-3">
                <button type="button" class="btn button_wbg font_extrabold grow sendButton button_wbgSav"
                  (click)="closeFileExtModal()">{{ 'COMMONS_UNDERSTAND' | translate }}</button>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>

        <div class="posrel" [class.hide]="(modalCurrentSlide !== 'docUpload_fileSize_Error') ? true : false"
          (onGesture)="doSwipe($event)" hammer-gestures>
          <div class=" mobile_container contetnheight posrel">
            <div class="row marginbott40 arrowpadding">
              <div class="col-md-12 col-xs-12 col-sm-12">
                <div class="header_txt_white">
                  <h2 id="docUploadProvideStatus">{{ 'DOCUMENT_UPLOAD_FILE_SIZE_ERROR_HEADER' | translate }}</h2>
                </div>
              </div>
            </div>
            <div class="row fixedbtn arrowpadding">
              <div class="col-xs-12 col-sm-4 col-md-3">
                <button type="button" class="btn button_wbg font_extrabold grow sendButton button_wbgSav"
                  (click)="closeFileSizeModal()">{{ 'COMMONS_UNDERSTAND' | translate }}</button>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>

        <div class="posrel" [class.hide]="(modalCurrentSlide !== 'docUpload_wait') ? true : false"
          (onGesture)="doSwipe($event)" hammer-gestures>
          <div class=" mobile_container contetnheight posrel">
            <div class="row marginbott40 arrowpadding">
              <div class="col-md-12 col-xs-12 col-sm-12">
                <div class="header_txt_white">
                  <h2>{{ 'WAIT_ON_FILE_UPLOAD_MODAL_TEXT' | translate }} </h2>
                </div>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>

        <div class="posrel" [class.hide]="(modalCurrentSlide !== 'docUpload_failed') ? true : false"
          (onGesture)="doSwipe($event)" hammer-gestures>
          <div class=" mobile_container contetnheight posrel">
            <div class="row marginbott40 arrowpadding">
              <div class="col-md-12 col-xs-12 col-sm-12">
                <div class="header_txt_white">
                  <h2> {{ 'DOCUMENT_UPLOAD_FILE_FAIL_ERROR_HEADER' | translate }} </h2>
                </div>
              </div>
            </div>
            <div class="row fixedbtn arrowpadding">
              <div class="col-xs-12 col-sm-4 col-md-3">
                <button type="button" class="btn button_wbg font_extrabold grow sendButton button_wbgSav"
                  (click)="closeFileUploadFailModal()">{{ 'REVIEW_MODAL_BTN_OK' | translate }}</button>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Upload document Modal - Ends -->

<!-- View Pdf Modal - Starts -->

<div class="modal fade custom_modal" id="view_pdf_modal" role="dialog" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="viewPdfModalClose()">&times;</button>
      </div>
      <div class="transparentbg modal-body">
        <div class="row">
          <div class="col-md-4 col-sm-4 col-xs-4 text-center">
            <button class="btn" style="color:#000" id="prev"
              (click)="onPrevPage()">{{ 'COMMONS_LOWER_PREVIOUS' | translate }}</button>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-4 text-center">
            <span>{{ 'DOCUMENT_UPLOAD_PAGE' | translate }}: <span id="page_num"></span> / <span
                id="page_count"></span></span>
          </div>
          <div class="col-md-4 col-sm-4 col-xs-4 text-center">
            <button id="next" id="submit_profile" class="btn" style="color:#000"
              (click)="onNextPage()">{{ 'COMMONS_LOWER_NEXT' | translate }}</button>
          </div>
        </div>
        <div style="text-align: center;">
          <!-- <iframe id="doc_upload_pdfView" src="" style="width:100%; height:600px;" frameborder="0"></iframe> -->
          <canvas id="viewPdfCanvas" style="height: 600px; width:100%;" frameborder="0"></canvas>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- View Pdf Modal - Ends -->

<!-- No pdf modal - Starts -->

<div class="modal fade custom_modal" id="noPdfModal" role="dialog" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="noPdfModalClose()">&times;</button>
      </div>
      <div class="transparentbg modal-body">
        <h4 class="modal-title m-t-10">{{ 'DOCUMENT_UPLOAD_MODAL_ERROR_1' | translate }}</h4>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>

<!-- No pdf modal - Ends -->

<!-- Wait for a moment pdf modal - Starts -->

<div class="modal fade custom_modal" id="wait_for_pdf_modal" tabindex="-1" role="Application" aria-labelledby="documentUploadWaitPDFmodelStatus" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
        <!--<button type="button" class="close" data-dismiss="modal" (click)="modalClose()">&times;</button>-->
      </div>
      <div class="transparentbg modal-body">
        <h4 class="modal-title m-t-10" id="documentUploadWaitPDFmodelStatus">{{'WAIT_ON_AGGREMENT_MODAL_TEXT' | translate }}</h4>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>

<!-- Wait for a moment pdf modal - Ends -->

<!-- Delete Modal starts -->

<div id="document_delete_modal" class="modal fade custom_modal" tabindex="-1" role="Application" aria-labelledby="docUploadDelStatus" data-backdrop="static"
  data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="closeDocumentDeleteModal()">&times;</button>

      </div>
      <div class="transparentbg modal-body">
        <h4 class="modal-title" id="docUploadDelStatus">{{'DOCUMENT_UPLOAD_DELETE_MODAL_HEADER' | translate}}</h4>
      </div>
      <div class="modal-footer">
        <div class="col-xs-12 col-sm-5 col-md-5 p_l_0  p_r_0 m-r-15">
          <button type="button" class="btn button grow font_extrabold"
            (click)="onDeleteConfirm()">{{'REVIEW_MODAL_BTN_DELETE' | translate}}</button>
        </div>
        <div class="col-xs-12 col-sm-5 col-md-5 p_l_0  p_r_0 m-r-15">
          <button type="button" class="btn button_wbg grow font_extrabold"
            (click)="closeDocumentDeleteModal()">{{'DOCUPLOAD_MODAL_BTN_CHANGE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Delete modal - Ends -->

<!-- Confirm Reupload Modal - starts -->

<div class="modal fade custom_modal" id="upload_Reconfirm_modal" tabindex="-1" role="Application" aria-labelledby="documentReUploadStatus" data-backdrop="static"
  data-keyboard="false">
  <div class="modal-dialog uploadDocumentModel">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="closeUploadReConfirmModal()">&times;</button>
      </div>
      <div class="transparentbg modal-body">
        <div class="mobile_container contetnheight posrel">
          <div class="row">
            <div class="col-md-12 col-xs-12 col-sm-12">
              <div class="header_txt_white">
                <h3 id="documentReUploadStatus">{{'DOCUMENT_RECONFIRM_MODAL_HEADER' | translate:[firstName] }}</h3>
              </div>
            </div>
          </div>
          <div class="row fixedbtn arrowpadding">
            <div class="col-xs-12 col-sm-4 col-md-3 btnpd">
              <button type="button" class="btn button grow font_extrabold"
                (click)="onUploadReConfirm()">{{'DOCUMENT_UPLOAD_BTN_YES' | translate}}</button>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-3 btnpd">
              <button type="button" class="btn button_wbg grow font_extrabold"
                (click)="closeUploadReConfirmModal()">{{'DOCUMENT_UPLOAD_BTN_NO' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Confirm Reupload Modal - Ends -->

<!-- Document Upload Info Modal - Starts -->

<div id="docUpload_info_modal" class="modal fade custom_modal" tabindex="-1" role="Application" aria-labelledby="listOfdocumentUploadStatus" data-backdrop="static"
  data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="docUploadInfoModalClose()">&times;</button>
      </div>
      <div class="transparentbg modal-body">
        <!-- For Generic Instructions-->
          <h4 class="modal-title font-16px m-b-20" id="listOfdocumentUploadStatus">{{ 'DOCUPLOAD_INFO_MODAL_HEADER' | translate }}</h4>
          <h5>
            <ul class="custom_ul generic-instructions">
              <li [innerHTML]="'DOCUPLOAD_ICON_INFO_INSTRUCTION' | translate:[infoIcon]"></li>
              <li [innerHTML]="'DOCUPLOAD_ICON_DOWNLOAD_INSTRUCTION' | translate:[downloadIcon]"></li>
              <li [innerHTML]="'DOCUPLOAD_ICON_UPLOAD_INSTRUCTION' | translate:[uploadIcon]"></li>
            </ul>
          </h5>
        <!-- For INTL GID 100P -->
        <h5 *ngIf="isIntlGID100P || isIntlPRNCrim || isIntlAFP100P || isIntlRegNzNzpv">
          <ul class="custom_ul">
            <span [class.hide]="!isIntlGID100P">{{ 'DOCUPLOAD_MODAL_HEADING_1' | translate }}</span>
            <li [class.hide]="!isIntlGID100P">{{ 'DOCUPLOAD_INSTRUCTION_1' | translate }}</li>
            <li [class.hide]="!isIntlGID100P">{{ 'DOCUPLOAD_INSTRUCTION_2' | translate }}</li>
            <li [class.hide]="!isIntlGID100P">{{ 'DOCUPLOAD_INSTRUCTION_3' | translate }}</li>
            <li [class.hide]="!isIntlGID100P">{{ 'DOCUPLOAD_INSTRUCTION_4' | translate }}</li>
            <!-- For INTL PRN CRIM -->
            <span [class.hide]="!isIntlPRNCrim">{{ 'DOCUPLOAD_MODAL_HEADING_2' | translate }}</span>
            <!-- <li [class.hide]="!isIntlPRNCrim">{{ 'DOCUPLOAD_INSTRUCTION_7' | translate }}</li> -->
            <li [class.hide]="!isIntlPRNCrim">{{ 'DOCUPLOAD_INSTRUCTION_5' | translate }}</li>
            <li [class.hide]="!isIntlPRNCrim">{{ 'DOCUPLOAD_INSTRUCTION_8' | translate }}</li>
            <li [class.hide]="!isIntlPRNCrim">{{ 'DOCUPLOAD_INSTRUCTION_9' | translate }}</li>
            <li [class.hide]="!isIntlPRNCrim">{{ 'DOCUPLOAD_INSTRUCTION_21' | translate }}</li>
            <li [class.hide]="!isIntlPRNCrim">{{ 'DOCUPLOAD_INSTRUCTION_10' | translate }}</li>
            <li [class.hide]="!isIntlPRNCrim"><i class="fa fa-id-card-o" title="Photo Document"></i>
              {{ 'DOCUPLOAD_INSTRUCTION_6' | translate }}</li>
            <span [class.hide]="!isIntlAFP100P">{{ 'DOCUPLOAD_MODAL_HEADING_3' | translate }}</span>
            <li [class.hide]="!isIntlAFP100P">{{ 'DOCUPLOAD_INSTRUCTION_12' | translate }}</li>
            <li [class.hide]="!isIntlAFP100P">{{ 'DOCUPLOAD_INSTRUCTION_2' | translate }}</li>
            <li [class.hide]="!isIntlAFP100P">{{ 'DOCUPLOAD_INSTRUCTION_16' | translate }}</li>
            <li [class.hide]="!isIntlAFP100P"><i class="fa fa-id-card-o" title="Photo Document"></i>
              {{ 'DOCUPLOAD_INSTRUCTION_6' | translate }}</li>
            <li [class.hide]="!isIntlAFP100P">{{ 'DOCUPLOAD_INSTRUCTION_17' | translate }}</li>
            <span [class.hide]="!isIntlAFP100P">{{ 'DOCUPLOAD_MODAL_HEADING_5' | translate }}</span>
            <li [class.hide]="!isIntlAFP100P">{{ 'DOCUPLOAD_INSTRUCTION_18' | translate }}</li>
            <li [class.hide]="!isIntlAFP100P">{{ 'DOCUPLOAD_INSTRUCTION_19' | translate }}</li>
            <li [class.hide]="!isIntlAFP100P">{{ 'DOCUPLOAD_INSTRUCTION_20' | translate }}</li>
            <span [class.hide]="!isIntlRegNzNzpv">{{ 'DOCUPLOAD_MODAL_HEADING_4' | translate }}</span>
            <li [class.hide]="!isIntlRegNzNzpv">{{ 'DOCUPLOAD_INSTRUCTION_13' | translate }}</li>
            <li [class.hide]="!isIntlRegNzNzpv">{{ 'DOCUPLOAD_INSTRUCTION_14' | translate }}</li>
            <li [class.hide]="!isIntlRegNzNzpv">{{ 'DOCUPLOAD_INSTRUCTION_15' | translate }}</li>
            <li [class.hide]="!isIntlRegNzNzpv"><i class="fa fa-id-card-o" title="Photo Document"></i>
              {{ 'DOCUPLOAD_INSTRUCTION_6' | translate }}</li>
          </ul>
        </h5>
      </div>
      <div class="modal-footer">
        <div class="col-xs-12 col-sm-5 col-md-5 p_l_0_imp fixedbtnb">
          <button type="button" class="btn button grow font_extrabold"
            (click)="docUploadInfoModalClose()">{{'COMMONS_CONTINUE' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Document Upload Info Modal - Ends -->

<!-- Wait Modal - starts -->
<div class="modal fade custom_modal" id="docWait-modal" tabindex="-1" role="Application" aria-labelledby="docUploadConfirmStatus" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
        <!--<button type="button" class="close" data-dismiss="modal" (click)="modalClose()">&times;</button>-->
      </div>
      <div class="transparentbg modal-body">
        <h4 class="modal-title" id="docUploadConfirmStatus">{{'DISAGREE_WAIT_MODAL' | translate }}</h4>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>
<!-- Wait Modal - ends -->

<!-- Just a Moment for Document Upload Page to load Start -->
<div class="modal fade custom_modal" id="waitModalForDocUpload" tabindex="-1" role="Application" aria-labelledby="documentUploadWaitDocumentStatus" data-backdrop="static"
  data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
        <!--<button type="button" class="close" data-dismiss="modal" (click)="modalClose()">&times;</button>-->
      </div>
      <div class="transparentbg modal-body">
        <h4 class="modal-title m-t-10" id="documentUploadWaitDocumentStatus">{{'WAIT_ON_AGGREMENT_MODAL_TEXT' | translate }}</h4>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>
<!-- Just a Moment for Document Upload Page to load End -->

<!-- Threshold Alert Modal -->
<div class="modal fade custom_modal" id="threshHoldAlertModal" tabindex="-1" role="Application" aria-labelledby="docUploadNetworkStatus" data-backdrop="static"
  data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="closeTModal()">&times;</button>
      </div>
      <div class="transparentbg modal-body m-t-15">
        <h4 class="modal-title " id="docUploadNetworkStatus">{{'NETWORK_THRESHOLD_TEXT' | translate }}
        </h4>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-xs-12 col-sm-4 col-md-4 p_l_0  p_r_0 m-r-15 ">
            <!-- <button type="button" class="btn button font_extrabold" data-dismiss="modal" data-toggle="resetPassword-modal" (click)="goToSignin()">CONTINUE</button>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Upload complete Modal starts -->

<div id="upload_complete_modal" class="modal fade custom_modal" tabindex="-1" role="Application" aria-labelledby="docuploadConfirmStatus" data-backdrop="static"
  data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="closeUploadCompleteModal()">&times;</button>
      </div>
      <div class="transparentbg modal-body">
        <h4 class="modal-title" id="docuploadConfirmStatus">{{'DOCUMENT_UPLOAD_COMPLETE_MODAL_HEADER' | translate}}</h4>
      </div>
      <div class="modal-footer">
        <div class="col-xs-12 col-sm-5 col-md-5 p_l_0  p_r_0 m-r-15">
          <button type="button" class="btn button_wbg grow font_extrabold"
            (click)="closeUploadCompleteModal()">{{'COMMONS_OKAY' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Upload complete modal - Ends -->

<div id="optionalDocumentModal" class="modal fade custom_modal" tabindex="-1" role="Application" aria-labelledby="OptionalDocumentCollection"  data-backdrop="static"
  data-keyboard="false">>
  <div class="modal-dialog sign-modal">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="closeOptionalDocumentModal()">&times;</button>
      </div>
      <div class="transparentbg modal-body">
        <h4 class="modal-title" id="OptionalDocumentCollection"> {{'STANDARD_EMPLOYMENT_SUPPORTING_DOCUMENT' | translate}} </h4>
      </div>
      <div class="modal-footer">
        <div class="col-xs-12 col-sm-4 col-md-3 p_l_0  m-t-10 p_r_0 m-r-15">
          <button type="button" class="btn button grow font_extrabold" data-dismiss="modal" (click)="closeOptionalDocumentModal()">{{'COMMONS_OKAY' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>