<div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
  <div class="group" [formGroup]="group">
    <label class="placeholder_white custom_label" [attr.for]="multiCountryConfigOptions['ATTRIBUTE_ID']" [class.labelEllipsis]="config.label && config.label.length > 30">
      {{ config.label | translate }}
    </label>
    <ng-select [items]="countryList" [typeahead]="countryTypeahead" [typeToSearchText]="" [(ngModel)]="selectedValue" [labelForId]="multiCountryConfigOptions['ATTRIBUTE_ID']"
      bindLabel="country_name" bindvalue="country_name" formControlName="{{multiCountryConfigOptions['FORM_CONTROL_NAME']}}" 
      displayValue="country_name" (change)="trackChange($event)" (clear)="onClearData()" [multiple]="isMultipleSelect" [maxSelectedItems]="countriesSelected"
      (focus)="countryLookup('')" dropdownPosition="bottom">
    </ng-select>
    <span class="error_info" *ngIf="isCountryError">
      <i class="fa fa-exclamation error_icon" aria-hidden="true" [class.red_error]="!isCountryValid"
        [class.cursor_hand_none]="isCountryError" (click)="openCountryError()"></i>
    </span>
    <div class="custom-error error_new_ui" *ngIf="isCountryError">
      <div class="left_div">
        <ul>
          <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate }}</li>
          <li class="message">{{ 'ERROR_COUNTRY_OF_CITIZENSHIP' | translate }}</li>
        </ul>
      </div>
      <div class="right_div">
        <div class="close" aria-hidden="true" (click)="closeCountryError()">
          <i class="icon-cancel"></i>
        </div>
      </div>
    </div>
  </div>
</div>
