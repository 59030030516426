export class ProfileConfig {
    static profileItems = {
        "personal-data-list": "Personal Information",
        "name-data": "Profile2",
        "dob-data": "Personal Information",
        "identification-data": "Personal Information",
        "address-data-list": "Residential",
        "education-data-list": "Education",
        "employment-data-list": "Employment",
        "mvr-data-list": "Driver License",
        "licence-data-list": "Licence",
        "references-data-list": "References",
        "criminal-data-list": "Criminal", // PPA6211
        "alias-data-list": "Profile1",
        "intl-addl-field-list": "Additional Information"
    }

    static profileItemsByValue = {
        "profile": "personal-data-list",
        "identity": "personal-data-list",
        "residence": "address-data-list",
        "education": "education-data-list",
        "employment": "employment-data-list",
        "license": "mvr-data-list",
        "Licence": "licence-data-list",
        "references": "references-data-list",
        "criminal": "criminal-data-list",       // PPA6211
        "profile1": "alias-data-list",
        "profile2": "name-data"
    }

    static viewPersonalDataKey = {
        "ssn": "VP_MY_DATA_SSN",
        "dob": "VP_MY_DATA_DOB"
    }

    static viewProfileAddressKey = {
        "street_address": "VP_ADD_STREET_ADDRESS",
        "address_line_1": "ADDRESS_LINE_1_LABEL",
        "address_line_2": "ADDRESS_LINE_2_LABEL",
        "state_name": "VP_ADD_STATE_NAME",
        "city": "VP_ADD_CITY",
        "zip": "VP_ADD_ZIP_CODE",
        "country_name": "VP_ADD_COUNTRY",
        "valid_from": "VP_ADD_VALID_FROM",
        "valid_to": "VP_ADD_VALID_TO"
    }

    static viewProfileEmploymentKey = {
        "type": "VP_EMP_TYPE",
        "name": "VP_EMP_NAME",
        "permission-to-contact": "VP_EMP_PERMISSION_TO_CONTACT",
        "country_name": "VP_EMP_COUNTRY",
        "state_name": "VP_EMP_STATE_NAME",
        "city": "VP_EMP_CITY",
        "job-title": "VP_EMP_JOB_TITLE",
        "start-date": "VP_EMP_START_DATE",
        "end-date": "VP_EMP_END_DATE",
        "company_name_in_local_lang": "VP_EMP_COMPANY_LOCAL_NAME",
        "company_address": "VP_EMP_COMPANY_ADDRESS",
        "manager_name": "VP_EMP_MANAGER_NAME",
        "manager_position": "VP_EMP_MANAGER_POSITION",
        "manager_country_code": "VP_EMP_MANAGER_COUNTRY_CODE",
        "manager_phone_number": "VP_EMP_MANAGER_PHONE",
        "manager_email": "VP_EMP_MANAGER_EMAIL",
        "employee_id": "VP_EMP_ID",
        "department": "VP_EMP_DEPT",
        "when-to-contact": "VP_EMP_WHEN",
        'job-period': 'VP_POSITION_JOB_PERIOD',
        'job_type': 'VP_EMP_JOB_TYPE',
        "accountant_name": "VP_ACC_MANAGER_NAME",
        "accountant_position": "VP_ACC_MANAGER_POSITION",
        "accountant_country_code": "VP_ACC_MANAGER_COUNTRY_CODE",
        "accountant_phone_number": "VP_ACC_MANAGER_PHONE",
        "accountant_email": "VP_ACC_MANAGER_EMAIL",
        "reason_for_leaving": "VP_EMP_REASON_FOR_LEAVING",
        "faa_safety_check": "EMPLOYMENT_FAA_SAFETY_CHECK_LABLE",
    }

    static viewProfileEducationKey = {
        "type": "VP_EDU_TYPE",
        "degree-type": "VP_EDU_DEGREE_TYPE",
        "name": "VP_EDU_NAME",
        "qualification": "VP_EDU_CURRENT_STUDY",
        "degree-date": "VP_EDU_DEGREE_DATE",
        "country_name": "VP_EDU_COUNTRY",
        "state_name": "VP_EDU_STATE_NAME",
        "start-date": "VP_EDU_START_DATE",
        "end-date": "VP_EDU_END_DATE",
        "name_in_local_language": "VP_EDU_NAME_LOCAL",
        'address': 'VP_EDU_SCHOOL_ADDRESS',
        'city': 'VP_EDUCATION_CITY',
        'future_gradutation_date': 'VP_EDUCATION_FUTURE_GRADUATION_DATE',
        "grade_point_average": "VP_EDUCATION_GRADE_POINT_AVERAGE",
    }

    static viewProfileDriverLicenseKey = {
        "type": "VP_MVR_TYPE",
        "number": "VP_MVR_NUMBER",
        "last_name": "VP_MVR_LAST_NAME",
        "state_name": "VP_EDU_STATE_NAME",
        "city": "VP_MVR_CITY",
        "country_name": "VP_MVR_COUNTRY",
        "valid_from": "VP_MVR_VALID_FROM",
        "valid_to": "VP_MVR_VALID_TO",
        "name_on_license": "VP_MVR_FULL_NAME"
    }

    static viewProfileProfessionalLicenseKey = {
        "type": "VP_PROFESSIONAL_LICENCE_TYPE",
        "license_number": "VP_PROFESSIONAL_LICENCE_NUMBER",
        "detail": "VP_PROFESSIONAL_DETAIL",
        "state_name": "VP_PROFESSIONAL_LICENCE_STATE_NAME",
        "city": "VP_PROFESSIONAL_LICENCE_CITY",
        "country_name": "VP_PROFESSIONAL_LICENCE_COUNTRY",
        "is_using_agency": "VP_PROFESSIONAL_LICENCE_USING_AGENCY",
        "issue_date": "VP_PROFESSIONAL_LICENCE_ISSUE_DATE",
        "full_name": "VP_PROFESSIONAL_LICENCE_FULL_NAME",
    }

    static viewProfileReferencesKey = {
        "type": "VP_REFERENCES_TYPE",
        "reference_firstname": "VP_REFERENCES_FIRSTNAME",
        "reference_middlename": "VP_REFERENCES_MIDDLENAME",
        "reference_lastname": "VP_REFERENCES_LASTNAME",
        "title": "VP_REFERENCES_TITLE",
        "employername": "VP_PROFESSIONAL_EMPLOYER_NAME",
        "mobilenumber": "VP_PROFESSIONAL_MOBILE_NUMBER",
        "alternatemobilenumber": "VP_PROFESSIONAL_ALTERNATE_MOBILE_NUMBER",
        "relation": "VP_PROFESSIONAL_RELATION",
        "state_name": "VP_REFERENCES_STATE_NAME",
        "city": "VP_REFERENCES_CITY",
        "country": "VP_REFERENCES_COUNTRY",
        "email": "VP_REFERENCES_EMAIL"
    }

    static viewProfileCriminalKey = {  // PPA6211
        "type": "VP_CRIMINAL_TYPE",
        "country": "VP_CRIMINAL_COUNTRY",
        "state_code": "VP_CRIMINAL_STATE_CODE",
        "state_name": "VP_CRIMINAL_STATE_NAME",
        "county": "VP_CRIMINAL_COUNTY",
        "country_name": "VP_CRIMINAL_COUNTRY_NAME",
        "city": "VP_CRIMINAL_CITY",
        "isEnforcement": "VP_IS_ENFORCEMENT",
        "isConvicted": "VP_IS_CONVICTED",
        "description": "VP_DISCLOSURE_DESCRIPTION",
        "category": "VP_CRIMINAL_CATEGORY",
        "yes": "VP_YES",
        "no": "VP_NO",
    }

    static viewProfileAliasKey = {
        "first_name": "VP_ALIAS_FIRST_NAME",
        "middle_name": "VP_ALIAS_MIDDLE_NAME",
        "last_name": "VP_ALIAS_LAST_NAME",
        "suffix": "VP_ALIAS_SUFFIX",
        "is_maiden_name": "VP_ALIAS_MAIDEN_NAME",
        "start-date": "START_DATE",
        "end-date": "END_DATE"
    }

    static viewProfileNameKey = {
        "first_name": "VP_FIRST_NAME",
        "middle_name": "VP_MIDDLE_NAME",
        "last_name": "VP_LAST_NAME",
        "suffix": "VP_SUFFIX",
        "gender": "VP_GENDER",
        "title": "VP_TITLE",
        "contactNumber":"VP_INTL_PHONE_NUMBER"
     }

    // Need to work on this - placeholder now
    static viewProfileIntlKey = {
        'MOTHERS_LAST_NAME': 'VP_INTL_MOTHER_LAST_NAME',
        'FATHERS_LAST_NAME': 'SUBJECT_FATHER_FULL_NAME',
        'MAIDEN_NAME': 'VP_INTL_MAIDEN_NAME',
        'SCHOOL_NAME_IN_LOCAL_LANGUAGE': 'VP_INTL_SCHOOL_NAME_IN_LOCAL_LANGUAGE',
        'NAME_IN_LOCAL_LANGUAGE': 'VP_INTL_NAME_IN_LOCAL_LANGUAGE',
        'EMPLOYEE_ID': 'VP_INTL_EMPLOYEE_ID',
        'EMP_NAME_IN_LOCAL_LANGUAGE': 'VP_INTL_EMP_NAME_IN_LOCAL_LANGUAGE',
        'DEPARTMENT': 'VP_INTL_DEPARTMENT',
        'MOTHERS_MAIDEN_NAME': 'VP_INTL_MOTHER_MAIDEN_NAME',
        'BIRTH_COUNTRY': 'VP_INTL_BIRTH_COUNTRY',
        'BIRTH_STATE': 'VP_INTL_BIRTH_STATE',
        'BIRTH_CITY': 'VP_INTL_BIRTH_CITY',
        'POSITION_OFFERED': 'VP_INTL_POSITION_OFFERED',
        'INDUSTRY': 'VP_INTL_INDUSTRY',
        'PHONE': 'VP_INTL_PHONE_NUMBER',
        'DBS_PASSPORT_NATIONALITY': 'VP_INTL_DBS_PASSPORT_NATIONALITY',
        'DBS_DRIVER_LICENSE_TYPE': 'VP_INTL_DBS_DRIVER_LICENSE_TYPE',
        'DBS_DRIVER_LICENSE_VALID_FROM': 'VP_INTL_DBS_DRIVER_LICENSE_VALID_FROM',
        'DBS_PASSPORT_ISSUE_DATE': 'VP_INTL_DBS_PASSPORT_ISSUE_DATE',
        'HUKOU_ADDRESS': 'VP_INTL_HUKOU_ADDRESS',
        'HUKOU_CITY': 'VP_INTL_HUKOU_CITY',
        'HUKOU_REGION': 'VP_INTL_HUKOU_REGION',
        'WWC_STATE': 'WWC_STATE',
        'WWC_NUMBER': 'WWC_NUMBER',
        'COUNTRY_OF_CITIZENSHIP': 'SUBJECT_COUNTRY_OF_CITIZENSHIP',
        'ARE_YOU_MACAO_ID_HOLDER': 'SUBJECT_ARE_YOU_MACAO_ID_HOLDER',
        'LEGAL_NAME_IN_CHINESE': 'SUBJECT_LEGAL_NAME_IN_CHINESE',
        'LEGAL_NAME': 'SUBJECT_LEGAL_NAME',
        'DO_YOU_HAVE_RNF_NUMBER':'SUBJECT_DO_YOU_HAVE_RNF_NUMBER',
        'RNF_NUMBER':'SUBJECT_RNF_NUMBER',
        'NAME_DOCUMENT_TYPE':'NAME_DOCUMENT_TYPE',
        'NAME_AS_PER_DOCUMENT':'NAME_AS_PER_DOCUMENT',
        'HAS_UAN_NUMBER':'SUBJECT_HAS_UAN_NUMBER',
        'UAN_NUMBER':'SUBJECT_UAN_NUMBER',
        'APPLICANT_REPORT_QUESTION':'SUBJECT_APPLICANT_REPORT_QUESTION'
    }

    static getViewPersonalDataKey(): any {
        return ProfileConfig.viewPersonalDataKey;
    }

    static getViewProfileDriverLicenseKey(): any {
        return ProfileConfig.viewProfileDriverLicenseKey;
    }

    static getViewProfileAddressKey(): any {
        return ProfileConfig.viewProfileAddressKey;
    }

    static getViewProfileEmploymentKey(): any {
        return ProfileConfig.viewProfileEmploymentKey;
    }

    static getViewProfileEducationKey(): any {
        return ProfileConfig.viewProfileEducationKey;
    }

    static getViewProfileProfessionalLicenseKey(): any {
        return ProfileConfig.viewProfileProfessionalLicenseKey;
    }

    static getViewAliasKey(): any {
        return ProfileConfig.viewProfileAliasKey;
    }

    static getViewNameKey(): any {
        return ProfileConfig.viewProfileNameKey;
    }

    static getViewProfileReferencesKey(): any {
        return ProfileConfig.viewProfileReferencesKey;
    }

    static getViewProfileCriminalKey(): any {   // PPA6211
        return ProfileConfig.viewProfileCriminalKey;
    }

    static getProfileItems(): any {
        return ProfileConfig.profileItems;
    }
    static getProfileItemsByValue(): any {
        return ProfileConfig.profileItemsByValue;
    }

    static getViewProfileIntlKey(): any {
        return ProfileConfig.viewProfileIntlKey;
    }
}