<div class="pad_30" [formGroup]="empSubForm" (onGesture)="doSwipe($event,empSubForm.value)" hammer-gestures>
  <button type="button" class="btn arrowrightctr" attr.data-id="empPositionRightArrow_{{row}}"
    [disabled]="!enableNextbtn" debounceDirective (debounceClick)="skipEmpPosition(empSubForm.value)"> <i class="icon-arrow-right" role="img" [attr.aria-label]="'SCREEN_FARWARD_ARROW' | translate"></i></button>
  <button type="button" class="btn arrowleftctr" attr.data-id="empPositionLeftArrow_{{row}}"
    debounceDirective (debounceClick)="getPrevSwipe(empSubForm.value)"><i class="icon-arrow-left" role="img" [attr.aria-label]="'SCREEN_BACKWARD_ARROW' | translate"></i></button>
  <div class="anime-clean contetnheight posrel">
    <div class="row">
      <div class="col-md-12 col-xs-12 col-sm-12">
        <div class="header_txt_white" *ngIf="empType === 'current'">
          <h2>{{'EMPLOYMENT_POSITION_HEADER_1'|translate : [empSubForm.controls['companyName'].value]}}</h2>
          <h4 *ngIf="isGapExist" class="modal-title mandatoryGapError m-t-15">
            {{'POSITION_GAP_FOUND' | translate:[positionGapDetails['posGapLabel1'], positionGapDetails['posGapLabel2']] }}
          </h4>
          <h4 *ngIf="isOverlapExist" class="modal-title mandatoryGapError m-t-15">
            {{'POSITION_OVERLAP_FOUND' | translate}}
          </h4>
          <h4 *ngIf="IsFutureDate && !isOverlapExist && !isGapExist" class="modal-title mandatoryGapError m-t-15">
            {{'DATE_OUTOF_RANGE' | translate}}
          </h4>
          <!-- <h4>{{'EMPLOYMENT_POSITION_SUBHEADER_1'|translate }}</h4> -->
        </div>
        <div class="header_txt_white" *ngIf="empType === 'prior'">
          <h2>{{'EMPLOYMENT_POSITION_HEADER_2'|translate : [empSubForm.controls['companyName'].value]}}</h2>
          <!-- <h4>{{'EMPLOYMENT_POSITION_SUBHEADER_1'|translate }}</h4> -->
          <h4 *ngIf="isGapExist" class="modal-title mandatoryGapError m-t-15">
            {{'POSITION_GAP_FOUND' | translate:[positionGapDetails['posGapLabel1'], positionGapDetails['posGapLabel2']] }}
          </h4>
          <h4 *ngIf="isOverlapExist" class="modal-title mandatoryGapError m-t-15">
            {{'POSITION_OVERLAP_FOUND' | translate}}
          </h4>
          <h4 *ngIf="IsFutureDate && !isOverlapExist && !isGapExist" class="modal-title mandatoryGapError m-t-15">
            {{'DATE_OUTOF_RANGE' | translate}}
          </h4>
          <h4 *ngIf="!isValidPriorPosionEndDate" class="modal-title mandatoryGapError m-t-15">
            {{'EMP_POSITION_INVALID_END_DATE' | translate:[invalidPositionTitle] }}
          </h4>
        </div>

      </div>
    </div>

    <div class="multiPositionArea" *ngIf="!this._util.isEmpty(currentRowPositionList)">
      <div *ngFor="let position of currentRowPositionList;let i=index;">
        <!-- <div *ngFor="let position of positionList;let i=index;" > -->
        <div *ngIf="position !== undefined && position['status'] === true"
          class="row form-element-section">
          <div *ngIf="position['intlEndDate'] === 'Present'" class="multiplePositionDate">{{position['empPosition']}},
            {{position['intlStartDate']}} - {{'EMPLOYMENT_PRESENT' | translate }} </div>
          <div *ngIf="position['intlEndDate'] !== 'Present'" class="multiplePositionDate">{{position['empPosition']}},
            {{position['intlStartDate']}} - {{position['intlEndDate']}} </div>
          <div class="multiplePositionIcons">
            <i class="icon-edit" title="{{'MULTI_POSITION_EDIT_ICON' | translate}}"
              debounceDirective (debounceClick)="editRecord(position, i)"></i>
            <i *ngIf="(empType === 'current' &&  i > 0) || empType === 'prior'" class="icon-garbage bin"
              title="{{'MULTI_POSITION_DELETE_ICON' | translate}}" debounceDirective (debounceClick)="deleteRecord(position, i)"></i>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </div>

    <div class="row fixedbtn">

      <div class="col-xs-12 col-sm-4 col-md-3 btnpd">
        <button type="button" class="btn button font_extrabold senButton" attr.data-id="empPositionNext_{{row}}"
          [class.grow]="false" [disabled]="!enableNextbtn"
          debounceDirective (debounceClick)='skipEmpPosition(empSubForm.value)'>{{ 'COMMONS_NEXT' | translate }}</button>
      </div>

      <div class="col-xs-12 col-sm-4 col-md-3 btnpd">
        <button *ngIf="!addMorePositions" type="button" class="btn button_wbg font_extrabold senButton"
          attr.data-id="empPositionAddPosition_{{row}}" [class.grow]="false"
          debounceDirective (debounceClick)='addEmpPosition(empSubForm.value)'>{{ 'ADD_POSITION' | translate }}</button>
        <button *ngIf="addMorePositions" type="button" class="btn button_wbg font_extrabold senButton"
          attr.data-id="empPositionAddMorePosition_{{row}}" [class.grow]="false"
          debounceDirective (debounceClick)='addEmpPosition(empSubForm.value)'>{{ 'ADD_MORE_POSITIONS' | translate }}</button>
      </div>

      <div class="col-xs-12 col-sm-4 col-md-3 btnpd">
        <button type="button" class="btn button_wbg font_extrabold grow button_wbgSav"
          attr.data-id="empPositionSaveExit_{{row}}" [disabled]="!enableNextbtn"
          debounceDirective (debounceClick)='saveExit(empSubForm.value)'>{{ 'COMMONS_SAVE_AND_EXIT' | translate }}</button>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</div>