import { Validator } from './validator.enum';
import { RuleRegexName } from './validation-rules-config';
import { DurationObjectUnits } from 'luxon/src/duration';
import { DateTimeUnit } from 'luxon/src/datetime';


/** these are component driven validators, which expects parameter map supplied from client */
export type ComponentDrivenValidators = Validator.requiredIf | Validator.conditionalUsZip
  | Validator.allowedValueOrLabels | Validator.conditionalUkZip | Validator.conditionalZip;

/** these are standalone validators, which do not require parameters */
export type StaticValidators = Validator.dateIsValid | Validator.dateNotInPast | Validator.dateNotInFuture
  | Validator.noSpace | Validator.noBlanks | Validator.email;

/** Validators which are used for validation of dates using Crono unit and amount */
export type TemporalConfigurableValidators = Validator.legalAge | Validator.dateBefore | Validator.dateAfter
  | Validator.dateNotBefore | Validator.dateNotAfter;

/** Validators which use some other form control value as reference for parameter */
export type ControlReferencingValidators = Validator.fieldMatchAnother | Validator.conditionalPassportValidator | Validator.conditionalDrivingLicenseValidator;

/** configurable validators */
export type ConfigurableValidators = Validator.allowedValue;

/** should we left second boundary open or closed - ie dateNotBefore (but close starting point as well to create range) */
export type Range = 'CLOSED' | 'OPEN';

/**
 * These are available Backend implementations for automatic supply of required values.
 * **/
export enum BackendDataRef {
  dob = 'DOB', // will be substituted for Date of birth value on backend
  maskingEnabled = 'MASKING_ENABLED'
}

/***
 * Defining inclusiveness of upper and lower bound in criteria. Where Start is closer to TODAY,
 * and the END is either towards to the future or past.
 * */
export enum Inclusive {
  none = 'NONE',
  both = 'BOTH',
  start = 'START',
  end = 'END'
}


/**
 * Validator rule definition
 * */
export interface FieldValidationRule {
  controlName: string;
  /* for example when state_name is value for stateCode, stateName, hiddenState, if not set it is the same as controlName */
  valueKeyOverride?: string;
  required?: boolean;
  format?: string; /* primary format, if not specified, default is used */
  restrictions?: Array<Restriction>;
  validators?: Array<StaticValidators | ComponentDrivenValidators>;
  configurations?: Array<ValidatorConfig>;
  rootError?: string;
}

/**  ValidatorConfig */
export interface ValidatorConfig {
  validator: TemporalConfigurableValidators | ConfigurableValidators | ControlReferencingValidators;
  format?: string; /* format override for value */
  duration?: DurationObjectUnits;
  diffUnit?: DateTimeUnit;
  inclusive?: Inclusive;
  range?: Range;
  parameters?: Array<string | number>;
  controlRef?: string;
  valueRef?: BackendDataRef; // dates from backend must be ISO8601+UTC
  value?: string | number; // 2022-10-21 // 123924242434 ? 'Error parsing date'
  error?: string; // error message key.
}

export interface ComplexValidation {
  format?: string;
  static?: Array<StaticValidators>;
  config?: Array<ValidatorConfig>;
}

/**
 * Typed temporal criteria used just for configuring temporal validators
 * */
export interface TemporalCriteria {
  duration?: DurationObjectUnits;
  inclusive?: Inclusive;
  controlRef?: string;
  value?: string | number;
  format?: string;
  diffUnit?: DateTimeUnit;
  range?: Range;
  error?:string;
}

/** Dynamic value evaluation from session storage, object by key or service */
export interface Data {
  type: 'resolver' | 'session' | 'object' | 'service';
  key: string | number;
  value?: any;
}

export interface Restriction {
  weight?: {
    min?: number;
    max?: number;
  },
  regexPattern?: string;
  regexName?: RuleRegexName
}

