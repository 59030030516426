import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FieldConfig, SlideConfig } from '../../models/fieldconfig.interface';
import { BehaviorSubject } from 'rxjs';
import {
  FormGroup,
  FormControl,
  FormArray,
  FormBuilder,
  Validators,
  ControlValueAccessor
} from '@angular/forms';
import { UtilService } from 'framework/utils/util.service';

@Injectable()
export class DynamicFormService {
  static actionVal: string;
  static SetActionVal(actionVal: string) {
    DynamicFormService.actionVal = actionVal;
  }
  static GetActionVal(): string {
    return DynamicFormService.actionVal;
  }

  isDebug: boolean = true;
  public currentSlide: string;
  public currentSlideWithDirection: string;
  public wotcdecisionChange = new BehaviorSubject<string>('');
  wotcdecision$ = this.wotcdecisionChange.asObservable();

  constructor(private _authHttp: HttpClient, private _util: UtilService) {
  }

  SetFlagVal(val) {
    this.wotcdecisionChange.next(val);
  }

  getRandomString(length) {
    return Math.round((Math.pow(36, length + 1) - this._util.getCryptoRandom() * Math.pow(36, length))).toString(36).slice(1);
  }

  getRandomInt(min: number, max: number) {
    return Math.floor(this._util.getCryptoRandom() * (max - min + 1)) + min;
  }

  setSlider(componentName, direction): SlideConfig {
    let seprator: string = '_';
    this.currentSlide = componentName; // componentName,seprator,index(s)

    this.currentSlide += `${seprator}`; // componentName,seprator,index(s)


    if (direction) {
      this.currentSlideWithDirection = `${this.currentSlide}${seprator}${direction}`;
    }
    return {
      currentSlide: this.currentSlide, currentSlideWithDirection: this.currentSlideWithDirection
    };
  }
}
