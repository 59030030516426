<div>
  <ul>
    <li *ngIf="!!viewProfileData['type_description']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['type'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> : </div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{viewProfileData['type_description']}}</div>
      </div>
    </li>
    <li *ngIf="!!viewProfileData['type'] && viewProfileData['type'] == 'neverEmployed'">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['type'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> : </div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{ 'NEVER_EMPLOYED' | translate }}</div>
      </div>
    </li>
    <li *ngIf="!!viewProfileData['name']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['name']| translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['name']}}</div>
      </div>
    </li>
    <li *ngIf="!!viewProfileData['permission-to-contact']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type"> {{profileKey['permission-to-contact'] | translate}}</div>
        <div class="col-md-1 col-xs-1 col-sm-1">:</div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{viewProfileData['permission-to-contact']}}</div>
      </div>
    </li>
    <li *ngIf="!!viewProfileData['when-to-contact']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type"> {{profileKey['when-to-contact'] | translate}}</div>
        <div class="col-md-1 col-xs-1 col-sm-1">:</div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">
          {{viewProfileData['when-to-contact'] | timeStampConvert :'MMMM dd,YYYY'}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['country_name']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['country_name'] | translate}}</div>
        <div class="col-md-1 col-xs-1 col-sm-1">:</div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{viewProfileData['country_name']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['state_name']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['state_name'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1">:</div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{viewProfileData['state_name']}}</div>
      </div>
    </li>
    <li *ngIf="!!viewProfileData['city']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type"> {{profileKey['city'] | translate}}</div>
        <div class="col-md-1 col-xs-1 col-sm-1">:</div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{viewProfileData['city']}}</div>
      </div>
    </li>
    <li *ngIf="!!viewProfileData['job-title']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type"> {{profileKey['job-title'] | translate}}</div>
        <div class="col-md-1 col-xs-1 col-sm-1">:</div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{viewProfileData['job-title']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['company_name_in_local_lang']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type"> {{profileKey['company_name_in_local_lang'] | translate}}</div>
        <div class="col-md-1 col-xs-1 col-sm-1">:</div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{viewProfileData['company_name_in_local_lang']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['company_address']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type"> {{profileKey['company_address'] | translate}}</div>
        <div class="col-md-1 col-xs-1 col-sm-1">:</div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{viewProfileData['company_address']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['manager_name'] && viewProfileData['type'] != 'selfEmployed'">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type"> {{profileKey['manager_name'] | translate}}</div>
        <div class="col-md-1 col-xs-1 col-sm-1">:</div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{viewProfileData['manager_name']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['manager_position'] && viewProfileData['type'] != 'selfEmployed'">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type"> {{profileKey['manager_position'] | translate}}</div>
        <div class="col-md-1 col-xs-1 col-sm-1">:</div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{viewProfileData['manager_position']}}</div>
      </div>
    </li>

    <li
      *ngIf="!!viewProfileData['manager_phone_number'] && !!viewProfileData['manager_country_code'] && viewProfileData['type'] != 'selfEmployed'">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type"> {{profileKey['manager_phone_number'] | translate}}</div>
        <div class="col-md-1 col-xs-1 col-sm-1">:</div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{viewProfileData['manager_country_code']}}
          {{viewProfileData['manager_phone_number']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['manager_email'] && viewProfileData['type'] != 'selfEmployed'">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type"> {{profileKey['manager_email'] | translate}}</div>
        <div class="col-md-1 col-xs-1 col-sm-1">:</div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{viewProfileData['manager_email']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['manager_name'] && viewProfileData['type'] == 'selfEmployed'">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type"> {{profileKey['accountant_name'] | translate}}</div>
        <div class="col-md-1 col-xs-1 col-sm-1">:</div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{viewProfileData['manager_name']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['manager_position'] && viewProfileData['type'] == 'selfEmployed'">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type"> {{profileKey['accountant_position'] | translate}}</div>
        <div class="col-md-1 col-xs-1 col-sm-1">:</div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{viewProfileData['manager_position']}}</div>
      </div>
    </li>

    <li
      *ngIf="!!viewProfileData['manager_phone_number'] && !!viewProfileData['manager_country_code'] && viewProfileData['type'] == 'selfEmployed'">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type"> {{profileKey['accountant_phone_number'] | translate}}</div>
        <div class="col-md-1 col-xs-1 col-sm-1">:</div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{viewProfileData['manager_country_code']}}
          {{viewProfileData['manager_phone_number']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['manager_email'] && viewProfileData['type'] == 'selfEmployed'">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type"> {{profileKey['accountant_email'] | translate}}</div>
        <div class="col-md-1 col-xs-1 col-sm-1">:</div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{viewProfileData['manager_email']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['department']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type"> {{profileKey['department'] | translate}}</div>
        <div class="col-md-1 col-xs-1 col-sm-1">:</div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{viewProfileData['department']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['employee_id']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type"> {{profileKey['employee_id'] | translate}}</div>
        <div class="col-md-1 col-xs-1 col-sm-1">:</div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{viewProfileData['employee_id']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['from_date'] && !!viewProfileData['type'] && viewProfileData['type'] != 'neverEmployed'">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type"> {{profileKey['from_date'] | translate}}</div>
        <div class="col-md-1 col-xs-1 col-sm-1">:</div>
        <div class="col-md-6 col-xs-5 col-sm-5options">{{viewProfileData['from_date'] | timeStampConvert :dateFormat}}
        </div>
      </div>
    </li>
    <li *ngIf="!!viewProfileData['type'] && viewProfileData['type'] != 'neverEmployed'">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['to_date'] | translate}}</div>
        <div class="col-md-1 col-xs-1 col-sm-1">:</div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">
          <span *ngIf="!!viewProfileData['to_date']"> {{viewProfileData['to_date'] | timeStampConvert :dateFormat}}
          </span>
          <span *ngIf="!viewProfileData['to_date']"> {{ 'EMPLOYMENT_PRESENT' | translate}} </span>
        </div>
      </div>
    </li>
    <li *ngIf="!!viewProfileData['job_type']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['job_type'] | translate}}</div>
        <div class="col-md-1 col-xs-1 col-sm-1">:</div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">
          <span> {{viewProfileData['job_type']}} </span>
        </div>
      </div>
    </li>
    <li *ngIf="!!viewProfileData['reason_for_leaving']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type"> {{profileKey['reason_for_leaving'] | translate}}</div>
        <div class="col-md-1 col-xs-1 col-sm-1">:</div>
        <div class="col-md-6 col-xs-5 col-sm-5 options">{{viewProfileData['reason_for_leaving']}}</div>
      </div>
    </li>
    <div *ngFor="let position of viewProfileData['employment_position_list']">
      <li>
        <div class="row">
          <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['job-title'] | translate}}</div>
          <div class="col-md-1 col-xs-1 col-sm-1">:</div>
          <div class="col-md-6 col-xs-5 col-sm-5 options">
            <span *ngIf="!!position['position']"> {{position['position']}} </span>
          </div>
        </div>
      </li>
      <li>
        <div class="row">
          <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['job-period'] | translate}}</div>
          <div class="col-md-1 col-xs-1 col-sm-1">:</div>
          <div class="col-md-6 col-xs-5 col-sm-5 options">
            <span> {{position['from_date'] | timeStampConvert :dateFormat}} -
              {{!!position['to_date'] ? (position['to_date'] | timeStampConvert :dateFormat) : ('EMPLOYMENT_PRESENT' | translate)}}
            </span>
          </div>
        </div>
      </li>
    </div>
    <li *ngIf="viewProfileData['faa_safety_check']===true || viewProfileData['faa_safety_check']===false">
      <div class="row">
        <div class="col-md-12 col-xs-5 col-sm-5"> {{profileKey['faa_safety_check'] | translate}}</div>
        <div class="col-md-1 col-xs-1 col-sm-1">:</div>
        <div class="col-md-11 col-xs-5 col-sm-6 options">{{(viewProfileData['faa_safety_check']? 'VP_YES' : 'VP_NO') | translate }}</div>
      </div>
    </li>
  </ul>
</div>
