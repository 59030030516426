<div>
  <ul>
    <li *ngIf="!!viewProfileData['type_description']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{ profileKey['type'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['type_description']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['country_name']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['country_name'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['country_name']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['state_name']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['state_name'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['state_name']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['city']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['city'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['city']}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['detail']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['detail'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['detail'] }}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['is_using_agency']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['is_using_agency'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['is_using_agency'] }}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['issue_date']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['issue_date'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options">
          {{viewProfileData['issue_date'] | timeStampConvert :'YYYY-MM-DD'}}</div>
      </div>
    </li>

    <li *ngIf="!!viewProfileData['license_number']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['license_number'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['license_number'] }}</div>
      </div>
    </li>

    <li *ngIf="viewProfileData['name_on_license']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['name_on_license'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['name_on_license'] }}</div>
      </div>
    </li>

    <li *ngIf="viewProfileData['full_name']">
      <div class="row">
        <div class="col-md-5 col-xs-5 col-sm-5 type">{{profileKey['full_name'] | translate}} </div>
        <div class="col-md-1 col-xs-1 col-sm-1"> :</div>
        <div class="col-md-6 col-xs-5 col-sm-6 options"> {{viewProfileData['full_name'] }}</div>
      </div>
    </li>
  </ul>
</div>
