import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PaDateComponent } from 'framework/datepicker';
import { FormSlickSliderComponent } from '../../framework/formSlickSlider';
import { HammerGesturesDirective } from '../../framework/hammer';
import { DateFormatPipe, DOBDateFormat, TimeStampDateFormat, YearFormat } from './../pipes/dateformat.pipe';
import { TranslatePipe, TranslateService, TRANSLATION_PROVIDERS } from '../../framework/i18n';
import {
  ComponentHostDirective,
  DynamicComponentLoaderComponent,
  DynamicNavigationService,
  DynamicSubStepMapperService
} from '../../framework/dynamic';
import { CountryPickerComponent, CountryPickerService } from './components/country-picker';
import {
  ImportDataComponent,
  ImportDataInfoComponent,
  ImportDataOptionsComponent
} from './external-data/import/import-data';
import {
  AutoMagicalCameraComponent,
  AutoMagicalCameraRecaptureComponent,
  AutoMagicalCameraSuccessComponent,
  AutoMagicalInfoComponent,
  AutoMagicalMVRComponent,
  AutoMagicalUploadComponent,
  FileUploaderComponent
} from './external-data/import/auto-magical-mvr';
import { CameraComponent, FileUploadComponent, MultipleFileUploadComponent } from './external-data/components';
import { CustomInstructionComponent } from './custom-instruction';
import { SortableDirective } from '../sortable.directive';
// This shared service used to replace Google API
import { SharedService } from './../shared/services/shared.service';
import { UtilService } from '../../framework/utils/util.service';
import { ErrorComponent } from './error/error.component';
import { AtsComponent } from './external-data/ats/ats.component';
import { ElementDisclosureComponent } from './element-disclosure';

import { RuleManagerService } from './rule-manager/rule-manager.service';
import { DotCustomFormComponent } from './dot/dot-customform.component';
import { DOTService } from './dot/dot.service';
import { PlaceHolderFloat } from './../../framework/form/placeholder.directive';
import { ErrorInfoComponent } from './../../framework/form/errorInfo.directive';
import { SliderComponent } from './../../framework/formSlickSlider/slider.component';
import { InterpolationPipe } from './../pipes/interpolation.pipe';
import { Mask, maskGIDPipe, SSNMaskPipe } from '../pipes/Mask.pipe';
import { EqualValidator } from './../profile/passwordEqualValidator.directive';
import { GapconfirmComponent } from './../residential-data/gapconfirm/gapconfirm.component';
import { DynamicCardControlsComponent } from './../../framework/card/dynamic-card.controls.component';
import { DynamicCardComponent } from './../../framework/card/dynamic-card.component';
import { SpaceInputValidator } from './../../framework/form/input-empty-validator.directive';
import { NumberRepeat } from './../../app/pipes/common.pipe';
import { SSNValidator } from './../personal-data/personal-data-ssn/ssnvalidator.directive';
import { GapFindService } from './../../framework/gapfind.service';
import { FeatureFlagDirective } from './feature-flag/feature-flag.directive';
import { WaitingIndicationComponent } from './waiting-indication';
import { SignaturePadPage } from './../wotc/e-signature/e-signature.component';
import { DocumentUploadComponent } from '../document-upload';
import {
  MandatoryDocumentUploadComponent
} from '../document-upload/mandatory-documents-upload/mandatory-documents-upload.component';
import {
  OptionalDocumentUploadComponent
} from '../document-upload/optional-documents-upload/optional-documents-upload.component';
import { DocumentCategoryComponent } from '../document-upload/document-category/document-category.component';
import { LocationPickerService } from 'framework/dynamicCard/components/location-picker/location-picker.service';
import { PhoneNumberComponent } from 'framework/phone-number/phone-number.component';
import { CustomSelectModule } from 'framework/customSelect';
import {
  SkippedDocumentsDetailsComponent
} from '../document-upload/skipped-documents-details/skipped-documents-details.component';
import { AccordionTitleFormat } from '../pipes/accordionTitleFormat.pipe';
import { FieldErrorComponent } from './field-error/field-error.component';
import { FieldValidationService } from './form-validation';
import { SSNBlockCopyPasteDirective } from 'app/personal-data/personal-data-ssn/ssn-disablecopy';


@NgModule({
  imports: [CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomSelectModule
  ],

  declarations: [
    HammerGesturesDirective,
    DateFormatPipe,
    NumberRepeat,
    DOBDateFormat,
    InterpolationPipe,
    SSNMaskPipe,
    maskGIDPipe,
    Mask,
    CountryPickerComponent,
    TimeStampDateFormat,
    TranslatePipe,
    PaDateComponent,
    FormSlickSliderComponent,
    DynamicCardComponent,
    DynamicCardControlsComponent,
    AtsComponent,
    DynamicComponentLoaderComponent,
    ComponentHostDirective,
    ImportDataComponent,
    ImportDataInfoComponent,
    ImportDataOptionsComponent,
    AutoMagicalMVRComponent,
    AutoMagicalInfoComponent,
    AutoMagicalCameraComponent,
    AutoMagicalUploadComponent,
    AutoMagicalCameraSuccessComponent,
    AutoMagicalCameraRecaptureComponent,
    FileUploaderComponent,
    MultipleFileUploadComponent,
    FileUploadComponent,
    CameraComponent,
    YearFormat,
    CustomInstructionComponent,
    WaitingIndicationComponent,
    ElementDisclosureComponent,
    SortableDirective,
    ErrorComponent,
    PlaceHolderFloat,
    ErrorInfoComponent,
    SliderComponent,
    DotCustomFormComponent,
    InterpolationPipe,
    SSNMaskPipe,
    maskGIDPipe,
    EqualValidator,
    GapconfirmComponent,
    DynamicCardComponent,
    DynamicCardControlsComponent,
    SpaceInputValidator,
    NumberRepeat,
    SSNValidator,
    FeatureFlagDirective,
    SignaturePadPage,
    DocumentUploadComponent,
    MandatoryDocumentUploadComponent,
    OptionalDocumentUploadComponent,
    DocumentCategoryComponent,
    PhoneNumberComponent,
    SkippedDocumentsDetailsComponent,
    AccordionTitleFormat,
    FieldErrorComponent,
    SSNBlockCopyPasteDirective
  ],
  exports: [
    HammerGesturesDirective,
    SortableDirective,
    CommonModule,
    DateFormatPipe,
    NumberRepeat,
    DOBDateFormat,
    TimeStampDateFormat,
    InterpolationPipe,
    SSNMaskPipe,
    maskGIDPipe,
    Mask,
    CountryPickerComponent,
    TranslatePipe,
    PaDateComponent,
    FormSlickSliderComponent,
    AtsComponent,
    DynamicCardComponent,
    DynamicCardControlsComponent,
    DynamicComponentLoaderComponent,
    ImportDataComponent,
    ImportDataInfoComponent,
    ImportDataOptionsComponent,
    AutoMagicalMVRComponent,
    AutoMagicalInfoComponent,
    AutoMagicalCameraComponent,
    AutoMagicalUploadComponent,
    AutoMagicalCameraSuccessComponent,
    AutoMagicalCameraRecaptureComponent,
    FileUploaderComponent,
    MultipleFileUploadComponent,
    FileUploadComponent,
    CameraComponent,
    YearFormat,
    CustomInstructionComponent,
    WaitingIndicationComponent,
    ElementDisclosureComponent,
    ErrorComponent,
    DotCustomFormComponent,
    PlaceHolderFloat,
    ErrorInfoComponent,
    SliderComponent,
    InterpolationPipe,
    SSNMaskPipe,
    maskGIDPipe,
    EqualValidator,
    GapconfirmComponent,
    DynamicCardComponent,
    DynamicCardControlsComponent,
    SpaceInputValidator,
    NumberRepeat,
    SSNValidator,
    FeatureFlagDirective,
    SignaturePadPage,
    DocumentUploadComponent,
    MandatoryDocumentUploadComponent,
    OptionalDocumentUploadComponent,
    DocumentCategoryComponent,
    PhoneNumberComponent,
    FieldErrorComponent,
    SSNBlockCopyPasteDirective
  ],
  providers: [
    TRANSLATION_PROVIDERS,
    TranslateService,
    GapFindService,
    CountryPickerService,
    DynamicNavigationService,
    DynamicSubStepMapperService,
    SharedService,
    UtilService,
    TimeStampDateFormat,
    DOTService,
    RuleManagerService,
    LocationPickerService,
    FieldValidationService
  ]
})

export class SharedModule {
}
