import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfig } from '../app/app.config';
import { WorkflowService } from "framework/workflow.service";

declare var jQuery: any;

@Injectable()
export class progressBarStatus {
  static _importData: string;
  static _setname: string;
  static _setdob: string;
  static _personalData: string;
  static _consent: string;
  static _disclosure: string;
  static _address: string;
  static _education: string;
  static _employement: string;
  static _mvr: string;
  static _review: string;
  static _alias: string;
  static _udfCrf: string;
  static _criminal: string;
  static _licence: string;
  static _references: string;
  static _document: string;
  static _intl: string;
  static _customForm: any;
  static _selfSchedule: string;
  static _confirm: string;
  static _onlineSchedule: string;
  static _payment: string;
  static _rightId: string;
  static _eiafPersonalData: string;
  static _paymentInfoData: string;
  static _bandwidthCheckData: string;
  static _groupCustomForm: any;
  static _selfDeclarationForm: string;
  static _instantIdentityId: string;

  setImportData(value: any): void {
    progressBarStatus._importData = value;
  }

  getImportData(): any {
    return progressBarStatus._importData;
  }

  setName(value: any): void {
    progressBarStatus._setname = value;
  }

  getName(): any {
    return progressBarStatus._setname;
  }

  setDob(value: any): void {
    progressBarStatus._setdob = value;
  }

  getDob(): any {
    return progressBarStatus._setdob;
  }

  setPersonalData(value: any): void {
    progressBarStatus._personalData = value;
  }

  getPersonalData(): any {
    return progressBarStatus._personalData;
  }

  setConsent(value: any): void {
    progressBarStatus._consent = value;
  }

  getConsent(): any {
    return progressBarStatus._consent;
  }

  setAddress(value: any): void {
    progressBarStatus._address = value;
  }

  getAddress(): any {
    return progressBarStatus._address;
  }

  setEducation(value: any): void {
    progressBarStatus._education = value;
  }

  getEducation(): any {
    return progressBarStatus._education;
  }

  setEmployement(value: any): void {
    progressBarStatus._employement = value;
  }

  getEmployement(): any {
    return progressBarStatus._employement;
  }

  setMvr(value: any): void {
    progressBarStatus._mvr = value;
  }

  getMvr(): any {
    return progressBarStatus._mvr;
  }

  setReview(value: any): void {
    progressBarStatus._review = value;
  }

  getReview(): any {
    return progressBarStatus._review;
  }

  setAlias(value: any): void {
    progressBarStatus._alias = value;
  }

  getAlias(): any {
    return progressBarStatus._alias;
  }

  setUdfCrf(value: any): void {
    progressBarStatus._udfCrf = value;
  }

  getUdfCrf(): any {
    return progressBarStatus._udfCrf;
  }

  setCriminal(value: any): void {
    progressBarStatus._criminal = value;
  }

  getCriminal(): any {
    return progressBarStatus._criminal;
  }

  setLicense(value: any): void {
    progressBarStatus._licence = value;
  }

  getLicense(): any {
    return progressBarStatus._licence;
  }

  setReferences(value: any): void {
    progressBarStatus._references = value;
  }

  getReferences(): any {
    return progressBarStatus._references;
  }

  setDocumentUpload(value: any): void {
    progressBarStatus._document = value;
  }

  getDocumentUpload(): any {
    return progressBarStatus._document;
  }

  setInternational(value: any): void {
    progressBarStatus._intl = value;
  }

  getInternational(): any {
    return progressBarStatus._intl;
  }

  setCustomForm(key: string, value: any): any {
    if (progressBarStatus._customForm == undefined) {
      progressBarStatus._customForm = {};
    }
    progressBarStatus._customForm[key] = value;
  }

  getCustomForm(key: string): any {
    return progressBarStatus._customForm[key];
  }

  setSelfSchedule(value: any): void {
    progressBarStatus._selfSchedule = value;
  }

  getSelfSchedule(): any {
    return progressBarStatus._selfSchedule;
  }

  setOnlineSchedule(value: any): void {
    progressBarStatus._onlineSchedule = value;
  }

  getOnlineSchedule(): any {
    return progressBarStatus._onlineSchedule;
  }

  setConfirm(value: any): void {
    progressBarStatus._confirm = value;
  }

  getConfirm(): any {
    return progressBarStatus._confirm;
  }

  setPayment(value: any): void {
    progressBarStatus._payment = value;
  }

  getPayment(): any {
    return progressBarStatus._payment;
  }
  
  setRightId(value: any): void {
    progressBarStatus._rightId = value;
  }

  getRightId(): any {
    return progressBarStatus._rightId;
  }

  setEiafPersonalData(value: any): void {
    progressBarStatus._eiafPersonalData = value;
  }

  getEiafPersonalData(): any {
    return progressBarStatus._eiafPersonalData;
  }
  
  setBandwidthCheckData(value: any): void {
    progressBarStatus._bandwidthCheckData = value;
  }

  getBandwidthCheckData(): any {
    return progressBarStatus._bandwidthCheckData;
  }

  setSelfDeclarationForm(value: any): void {
    progressBarStatus._selfDeclarationForm = value;
  }

  getSelfDeclarationForm(): any {
    return progressBarStatus._selfDeclarationForm;
  }  

  setPaymentInfoData(value: any): void {
    progressBarStatus._paymentInfoData = value;
  }

  getPaymentInfoData(): any {
    return progressBarStatus._paymentInfoData;
  }
  setGroupCustomForm(key: string, value: any): any {
    if (progressBarStatus._groupCustomForm == undefined) {
      progressBarStatus._groupCustomForm = {};
    }
    progressBarStatus._groupCustomForm[key] = value;
  }

  getGroupCustomForm(key: string): any {
    return progressBarStatus._groupCustomForm[key];
  }

  setInstantIdentityId(value: any): void {
    progressBarStatus._instantIdentityId = value;
  }

  getInstantIdentityId(): any {
    return progressBarStatus._instantIdentityId;
  }  

  createImportdataProgressbar(nexttab) {
    let importdata = this.getImportData();
    if (importdata == "true") {
      if (/\s/.test(nexttab)) {
        nexttab = this.getDisplayId(nexttab);
      }
      jQuery("#progrssbarMENU_ITEM_32").removeClass("hidden");
      jQuery("#progrssbarMENU_ITEM_32").addClass("visible");
      jQuery('#MENU_ITEM_32').removeClass("cursor_hand");
      jQuery('#MENU_ITEM_32').addClass("cursor_hand_none");
      jQuery('#' + nexttab).removeClass("cursor_hand_none");
      jQuery('#' + nexttab).addClass("cursor_hand");
      jQuery('#iconMENU_ITEM_32').removeClass("inner-icon");
      jQuery('#iconMENU_ITEM_32').addClass("icon_color_green");
      jQuery('#textMENU_ITEM_32').removeClass("inner-txt");
      jQuery('#textMENU_ITEM_32').addClass("text_color_green");
    }
  }

  createnamedobProgressbar(nexttab) {
    let name = this.getName();
    let dob = this.getDob();
    if (name == "true" || dob == "true") {
      if (/\s/.test(nexttab)) {
        nexttab = this.getDisplayId(nexttab);
      }
      jQuery("#progrssbarMENU_ITEM_1").removeClass("hidden");
      jQuery("#progrssbarMENU_ITEM_1").addClass("visible");
      jQuery('#MENU_ITEM_1').removeClass("cursor_hand");
      jQuery('#MENU_ITEM_1').addClass("cursor_hand_none");
      jQuery('#' + nexttab).removeClass("cursor_hand_none");
      jQuery('#' + nexttab).addClass("cursor_hand");

      jQuery('#iconMENU_ITEM_1').removeClass("inner-icon");
      jQuery('#iconMENU_ITEM_1').addClass("icon_color_green");
      jQuery('#textMENU_ITEM_1').removeClass("inner-txt");
      jQuery('#textMENU_ITEM_1').addClass("text_color_green");
    }
  }

  createresdidentialdataProgressbar(nexttab) {
    let residentialdata = this.getAddress();
    if (residentialdata == "true") {
      if (/\s/.test(nexttab)) {
        nexttab = this.getDisplayId(nexttab);
      }
      jQuery("#progrssbarMENU_ITEM_2").removeClass("hidden");
      jQuery("#progrssbarMENU_ITEM_2").addClass("visible");
      jQuery('#MENU_ITEM_2').removeClass("cursor_hand");
      jQuery('#MENU_ITEM_2').addClass("cursor_hand_none");
      jQuery('#' + nexttab).removeClass("cursor_hand_none");
      jQuery('#' + nexttab).addClass("cursor_hand");
      jQuery('#iconMENU_ITEM_2').removeClass("inner-icon");
      jQuery('#iconMENU_ITEM_2').addClass("icon_color_green");
      jQuery('#textMENU_ITEM_2').removeClass("inner-txt");
      jQuery('#textMENU_ITEM_2').addClass("text_color_green");
    }
  }

  createEmployeementProgressbar(nexttab) {
    let employement = this.getEmployement();
    if (employement == "true") {
      if (/\s/.test(nexttab)) {
        nexttab = this.getDisplayId(nexttab);
      }
      jQuery("#progrssbarMENU_ITEM_3").removeClass("hidden");
      jQuery("#progrssbarMENU_ITEM_3").addClass("visible");
      jQuery('#MENU_ITEM_3').removeClass("cursor_hand");
      jQuery('#MENU_ITEM_3').addClass("cursor_hand_none");
      jQuery('#' + nexttab).removeClass("cursor_hand_none");
      jQuery('#' + nexttab).addClass("cursor_hand");
      jQuery('#iconMENU_ITEM_3').removeClass("inner-icon");
      jQuery('#iconMENU_ITEM_3').addClass("icon_color_green");
      jQuery('#textMENU_ITEM_3').removeClass("inner-txt");
      jQuery('#textMENU_ITEM_3').addClass("text_color_green");
    }
  }

  createEducationProgressbar(nexttab) {
    let education = this.getEducation();
    if (education == "true") {
      if (/\s/.test(nexttab)) {
        nexttab = this.getDisplayId(nexttab);
      }      
      jQuery("#progrssbarMENU_ITEM_4").removeClass("hidden");
      jQuery("#progrssbarMENU_ITEM_4").addClass("visible");
      jQuery('#' + nexttab).removeClass("cursor_hand_none");
      jQuery('#' + nexttab).addClass("cursor_hand");
      jQuery('#iconMENU_ITEM_4').removeClass("inner-icon");
      jQuery('#iconMENU_ITEM_4').addClass("icon_color_green");
      jQuery('#textMENU_ITEM_4').removeClass("inner-txt");
      jQuery('#textMENU_ITEM_4').addClass("text_color_green");
    }
  }

  createMvrProgressbar(nexttab) {
    let mvr = this.getMvr();
    if (mvr == "true") {
      if (/\s/.test(nexttab)) {
        nexttab = this.getDisplayId(nexttab);
      }
      jQuery('#MENU_ITEM_5').removeClass("cursor_hand");
      jQuery('#MENU_ITEM_5').addClass("cursor_hand_none");
      jQuery("#progrssbarMENU_ITEM_5").removeClass("hidden");
      jQuery("#progrssbarMENU_ITEM_5").addClass("visible");
      jQuery('#' + nexttab).removeClass("cursor_hand_none");
      jQuery('#' + nexttab).addClass("cursor_hand");
      jQuery('#iconMENU_ITEM_5').removeClass("inner-icon");
      jQuery('#iconMENU_ITEM_5').addClass("icon_color_green");
      jQuery('#textMENU_ITEM_5').removeClass("inner-txt");
      jQuery('#textMENU_ITEM_5').addClass("text_color_green");

    }
  }

  createReviewProgressbar(nexttab) {
    let review = this.getReview();
    if (review == "true") {
      if (/\s/.test(nexttab)) {
        nexttab = this.getDisplayId(nexttab);
      }
      jQuery("#progrssbarMENU_ITEM_9").removeClass("hidden");
      jQuery("#progrssbarMENU_ITEM_9").addClass("visible");
      jQuery('#MENU_ITEM_9').removeClass("cursor_hand");
      jQuery('#MENU_ITEM_9').addClass("cursor_hand_none");
      jQuery('#' + nexttab).removeClass("cursor_hand_none");
      jQuery('#' + nexttab).addClass("cursor_hand");
      jQuery('#iconMENU_ITEM_9').removeClass("inner-icon");
      jQuery('#iconMENU_ITEM_9').addClass("icon_color_green");
      jQuery('#textMENU_ITEM_9').removeClass("inner-txt");
      jQuery('#textMENU_ITEM_9').addClass("text_color_green");
    }
  }

  createidentitydataProgressbar(nexttab) {
    let ssn = this.getPersonalData();
    if (ssn == "true") {
      if (/\s/.test(nexttab)) {
        nexttab = this.getDisplayId(nexttab);
      }
      jQuery('#MENU_ITEM_6').removeClass("cursor_hand");
      jQuery('#MENU_ITEM_6').addClass("cursor_hand_none");
      jQuery("#progrssbarMENU_ITEM_6").removeClass("hidden");
      jQuery("#progrssbarMENU_ITEM_6").addClass("visible");
      jQuery('#' + nexttab).removeClass("cursor_hand_none");
      jQuery('#' + nexttab).addClass("cursor_hand");
      jQuery('#iconMENU_ITEM_6').removeClass("inner-icon");
      jQuery('#iconMENU_ITEM_6').addClass("icon_color_green");
      jQuery('#textMENU_ITEM_6').removeClass("inner-txt");
      jQuery('#textMENU_ITEM_6').addClass("text_color_green");
    }
  }

  createconsentdisclosureProgressbar(nexttab) {
    let consent = this.getConsent();
    if (consent == "true") {
      if (nexttab != "/dashboard") {
        if (/\s/.test(nexttab)) {
          nexttab = this.getDisplayId(nexttab);
        }
        jQuery("#progrssbarMENU_ITEM_7").removeClass("hidden");
        jQuery("#progrssbarMENU_ITEM_7").addClass("visible");
        jQuery('#' + nexttab).removeClass("cursor_hand_none");
        jQuery('#' + nexttab).addClass("cursor_hand");
        jQuery('#MENU_ITEM_7').removeClass("cursor_hand");
        jQuery('#MENU_ITEM_7').addClass("cursor_hand_none");

        jQuery('#iconMENU_ITEM_7').removeClass("inner-icon");
        jQuery('#iconMENU_ITEM_7').addClass("icon_color_green");
        jQuery('#textMENU_ITEM_7').removeClass("inner-txt");
        jQuery('#textMENU_ITEM_7').addClass("text_color_green");
      }
      else {
        jQuery('#iconMENU_ITEM_7').removeClass("inner-icon");
        jQuery('#iconMENU_ITEM_7').addClass("icon_color_green");
        jQuery('#textMENU_ITEM_7').removeClass("inner-txt");
        jQuery('#textMENU_ITEM_7').addClass("text_color_green");
      }
    }
  }

  createAliasProgressbar() {
    let alias = this.getAlias();
    if (alias == "true") {
      /* jQuery("#progrssbar1").removeClass("hidden");
       jQuery("#progrssbar1").addClass("visible");
       jQuery('#disable2').removeClass("cursor_hand_none");
       jQuery('#disable2').addClass("cursor_hand");
       jQuery('#icon1').removeClass("inner-icon");
       jQuery('#icon1').addClass("icon_color_green");
       jQuery('#text1').removeClass("inner-txt");
       jQuery('#text1').addClass("text_color_green");  */
    }
  }

  createUdfCrfProgressbar(nexttab) {
    let udfCrf = this.getUdfCrf();
    if (udfCrf == "true") {
      if (/\s/.test(nexttab)) {
        nexttab = this.getDisplayId(nexttab);
      }      
      jQuery('#MENU_ITEM_10').removeClass("cursor_hand");
      jQuery('#MENU_ITEM_10').addClass("cursor_hand_none");
      jQuery("#progrssbarMENU_ITEM_10").removeClass("hidden");
      jQuery("#progrssbarMENU_ITEM_10").addClass("visible");
      jQuery('#' + nexttab).removeClass("cursor_hand_none");
      jQuery('#' + nexttab).addClass("cursor_hand");
      jQuery('#iconMENU_ITEM_10').removeClass("inner-icon");
      jQuery('#iconMENU_ITEM_10').addClass("icon_color_green");
      jQuery('#textMENU_ITEM_10').removeClass("inner-txt");
      jQuery('#textMENU_ITEM_10').addClass("text_color_green");
    }
  }

  createCriminalProgressbar(nexttab) {
    let criminal = this.getCriminal();
    if (criminal == "true") {
      if (/\s/.test(nexttab)) {
        nexttab = this.getDisplayId(nexttab);
      }
      jQuery('#MENU_ITEM_11').removeClass("cursor_hand");
      jQuery('#MENU_ITEM_11').addClass("cursor_hand_none");
      jQuery("#progrssbarMENU_ITEM_11").removeClass("hidden");
      jQuery("#progrssbarMENU_ITEM_11").addClass("visible");
      jQuery('#' + nexttab).removeClass("cursor_hand_none");
      jQuery('#' + nexttab).addClass("cursor_hand");
      jQuery('#iconMENU_ITEM_11').removeClass("inner-icon");
      jQuery('#iconMENU_ITEM_11').addClass("icon_color_green");
      jQuery('#textMENU_ITEM_11').removeClass("inner-txt");
      jQuery('#textMENU_ITEM_11').addClass("text_color_green");
    }
  }

  createLicenceProgressbar(nexttab) {
    let Licence = this.getLicense();

    if (Licence == "true") {
      if (/\s/.test(nexttab)) {
        nexttab = this.getDisplayId(nexttab);
      }
      jQuery('#MENU_ITEM_12').removeClass("cursor_hand");
      jQuery('#MENU_ITEM_12').addClass("cursor_hand_none");
      jQuery("#progrssbarMENU_ITEM_12").removeClass("hidden");
      jQuery("#progrssbarMENU_ITEM_12").addClass("visible");
      jQuery('#' + nexttab).removeClass("cursor_hand_none");
      jQuery('#' + nexttab).addClass("cursor_hand");
      jQuery('#iconMENU_ITEM_12').removeClass("inner-icon");
      jQuery('#iconMENU_ITEM_12').addClass("icon_color_green");
      jQuery('#textMENU_ITEM_12').removeClass("inner-txt");
      jQuery('#textMENU_ITEM_12').addClass("text_color_green");
    }
  }

  createReferencesProgressbar(nexttab) {
    let references = this.getReferences();
    if (references == "true") {
      if (/\s/.test(nexttab)) {
        nexttab = this.getDisplayId(nexttab);
      }
      jQuery('#MENU_ITEM_13').removeClass("cursor_hand");
      jQuery('#MENU_ITEM_13').addClass("cursor_hand_none");
      jQuery("#progrssbarMENU_ITEM_13").removeClass("hidden");
      jQuery("#progrssbarMENU_ITEM_13").addClass("visible");
      jQuery('#' + nexttab).removeClass("cursor_hand_none");
      jQuery('#' + nexttab).addClass("cursor_hand");
      jQuery('#iconMENU_ITEM_13').removeClass("inner-icon");
      jQuery('#iconMENU_ITEM_13').addClass("icon_color_green");
      jQuery('#textMENU_ITEM_13').removeClass("inner-txt");
      jQuery('#textMENU_ITEM_13').addClass("text_color_green");
    }
  }

  createDocumentUploadProgressbar(nexttab) {
    let document = this.getDocumentUpload();
    if (document == "true") {
      if (/\s/.test(nexttab)) {
        nexttab = this.getDisplayId(nexttab);
      }      
      jQuery('#MENU_ITEM_15').removeClass("cursor_hand");
      jQuery('#MENU_ITEM_15').addClass("cursor_hand_none");
      jQuery("#progrssbarMENU_ITEM_15").removeClass("hidden");
      jQuery("#progrssbarMENU_ITEM_15").addClass("visible");
      jQuery('#' + nexttab).removeClass("cursor_hand_none");
      jQuery('#' + nexttab).addClass("cursor_hand");
      jQuery('#iconMENU_ITEM_15').removeClass('inner-icon');
      jQuery('#iconMENU_ITEM_15').addClass('icon_color_green');
      jQuery('#textMENU_ITEM_15').removeClass('inner-txt');
      jQuery('#textMENU_ITEM_15').addClass('text_color_green');
    }
  }

  createInternationalProgressbar(nexttab) {
    let document = this.getInternational();
    if (document == "true") {
      if (/\s/.test(nexttab)) {
        nexttab = this.getDisplayId(nexttab);
      }      
      jQuery('#MENU_ITEM_16').removeClass("cursor_hand");
      jQuery('#MENU_ITEM_16').addClass("cursor_hand_none");
      jQuery("#progrssbarMENU_ITEM_16").removeClass("hidden");
      jQuery("#progrssbarMENU_ITEM_16").addClass("visible");
      jQuery('#' + nexttab).removeClass("cursor_hand_none");
      jQuery('#' + nexttab).addClass("cursor_hand");
      jQuery('#iconMENU_ITEM_16').removeClass('inner-icon');
      jQuery('#iconMENU_ITEM_16').addClass('icon_color_green');
      jQuery('#textMENU_ITEM_16').removeClass('inner-txt');
      jQuery('#textMENU_ITEM_16').addClass('text_color_green');
    }
  }

  createDOTMenuProgressbar(currentTab, nexttab) {
    let document = this.getCustomForm(currentTab);
    if (document == "true") {
      if (/\s/.test(nexttab)) {
        nexttab = this.getDisplayId(nexttab);
      }      
      jQuery('#' + currentTab).removeClass("cursor_hand");
      jQuery('#' + currentTab).addClass("cursor_hand_none");
      jQuery("#progrssbar" + currentTab).removeClass("hidden");
      jQuery("#progrssbar" + currentTab).addClass("visible");
      jQuery('#' + nexttab).removeClass("cursor_hand_none");
      jQuery('#' + nexttab).addClass("cursor_hand");
      jQuery('#icon' + currentTab).removeClass('inner-icon');
      jQuery('#icon' + currentTab).addClass('icon_color_green');
      jQuery('#text' + currentTab).removeClass('inner-txt');
      jQuery('#text' + currentTab).addClass('text_color_green');
    }
  }

  createSelfScheduleProgressbar(nexttab) {
    let selfSchedule = this.getSelfSchedule();
    if (selfSchedule == "true") {
      if (/\s/.test(nexttab)) {
        nexttab = this.getDisplayId(nexttab);
      }      
      jQuery('#MENU_ITEM_20').removeClass("cursor_hand");
      jQuery('#MENU_ITEM_20').addClass("cursor_hand_none");
      jQuery("#progrssbarMENU_ITEM_20").removeClass("hidden");
      jQuery("#progrssbarMENU_ITEM_20").addClass("visible");
      jQuery('#' + nexttab).removeClass("cursor_hand_none");
      jQuery('#' + nexttab).addClass("cursor_hand");
      jQuery('#iconMENU_ITEM_20').removeClass('inner-icon');
      jQuery('#iconMENU_ITEM_20').addClass('icon_color_green');
      jQuery('#textMENU_ITEM_20').removeClass('inner-txt');
      jQuery('#textMENU_ITEM_20').addClass('text_color_green');
    }
  }  

  createConfirmProgressbar(nexttab) {
    let confirm = this.getConfirm();
    if (confirm == "true") {
      if (/\s/.test(nexttab)) {
        nexttab = this.getDisplayId(nexttab);
      }
      jQuery('#MENU_ITEM_21').removeClass("cursor_hand");
      jQuery('#MENU_ITEM_21').addClass("cursor_hand_none");
      jQuery("#progrssbarMENU_ITEM_21").removeClass("hidden");
      jQuery("#progrssbarMENU_ITEM_21").addClass("visible");
      jQuery('#' + nexttab).removeClass("cursor_hand_none");
      jQuery('#' + nexttab).addClass("cursor_hand");
      jQuery('#iconMENU_ITEM_21').removeClass('inner-icon');
      jQuery('#iconMENU_ITEM_21').addClass('icon_color_green');
      jQuery('#textMENU_ITEM_21').removeClass('inner-txt');
      jQuery('#textMENU_ITEM_21').addClass('text_color_green');
    }
  } 

  createOnlineScheduleProgressbar(nexttab) {
    let onlineSchedule = this.getOnlineSchedule();
    if (onlineSchedule == "true") {
      if (/\s/.test(nexttab)) {
        nexttab = this.getDisplayId(nexttab);
      }
      jQuery('#MENU_ITEM_22').removeClass("cursor_hand");
      jQuery('#MENU_ITEM_22').addClass("cursor_hand_none");
      jQuery("#progrssbarMENU_ITEM_22").removeClass("hidden");
      jQuery("#progrssbarMENU_ITEM_22").addClass("visible");
      jQuery('#' + nexttab).removeClass("cursor_hand_none");
      jQuery('#' + nexttab).addClass("cursor_hand");
      jQuery('#iconMENU_ITEM_22').removeClass('inner-icon');
      jQuery('#iconMENU_ITEM_22').addClass('icon_color_green');
      jQuery('#textMENU_ITEM_22').removeClass('inner-txt');
      jQuery('#textMENU_ITEM_22').addClass('text_color_green');
    }
  }

  createPaymentProgressbar(nexttab) {
    let payment = this.getPayment();
    if (payment == "true") {
      if (/\s/.test(nexttab)) {
        nexttab = this.getDisplayId(nexttab);
      }
      jQuery('#MENU_ITEM_23').removeClass("cursor_hand");
      jQuery('#MENU_ITEM_23').addClass("cursor_hand_none");
      jQuery("#progrssbarMENU_ITEM_23").removeClass("hidden");
      jQuery("#progrssbarMENU_ITEM_23").addClass("visible");
      jQuery('#' + nexttab).removeClass("cursor_hand_none");
      jQuery('#' + nexttab).addClass("cursor_hand");
      jQuery('#iconMENU_ITEM_23').removeClass('inner-icon');
      jQuery('#iconMENU_ITEM_23').addClass('icon_color_green');
      jQuery('#textMENU_ITEM_23').removeClass('inner-txt');
      jQuery('#textMENU_ITEM_23').addClass('text_color_green');
    }
  }

  createRightIdProgressbar(nexttab) {
    let onlineSchedule = this.getRightId();
    if (onlineSchedule == "true") {
      if (/\s/.test(nexttab)) {
        nexttab = this.getDisplayId(nexttab);
      }
      jQuery('#MENU_ITEM_23').removeClass("cursor_hand");
      jQuery('#MENU_ITEM_23').addClass("cursor_hand_none");
      jQuery("#progrssbarMENU_ITEM_23").removeClass("hidden");
      jQuery("#progrssbarMENU_ITEM_23").addClass("visible");
      jQuery('#' + nexttab).removeClass("cursor_hand_none");
      jQuery('#' + nexttab).addClass("cursor_hand");
      jQuery('#iconMENU_ITEM_23').removeClass('inner-icon');
      jQuery('#iconMENU_ITEM_23').addClass('icon_color_green');
      jQuery('#textMENU_ITEM_23').removeClass('inner-txt');
      jQuery('#textMENU_ITEM_23').addClass('text_color_green');
    }
  }

  createEiafPersonalDataProgressbar(nexttab) {
    let eiafPersonalData = this.getEiafPersonalData();
    if (eiafPersonalData == "true") {
      if (/\s/.test(nexttab)) {
        nexttab = this.getDisplayId(nexttab);
      }
      jQuery('#MENU_ITEM_17').removeClass("cursor_hand");
      jQuery('#MENU_ITEM_17').addClass("cursor_hand_none");
      jQuery("#progrssbarMENU_ITEM_17").removeClass("hidden");
      jQuery("#progrssbarMENU_ITEM_17").addClass("visible");
      jQuery('#' + nexttab).removeClass("cursor_hand_none");
      jQuery('#' + nexttab).addClass("cursor_hand");
      jQuery('#iconMENU_ITEM_17').removeClass('inner-icon');
      jQuery('#iconMENU_ITEM_17').addClass('icon_color_green');
      jQuery('#textMENU_ITEM_17').removeClass('inner-txt');
      jQuery('#textMENU_ITEM_17').addClass('text_color_green');
    }
  }

  createPaymentInfoDataProgressbar(nexttab) {
    let paymentInfoData = this.getPaymentInfoData();
    if (paymentInfoData == "true") {
      if (/\s/.test(nexttab)) {
        nexttab = this.getDisplayId(nexttab);
      }
      jQuery('#MENU_ITEM_27').removeClass("cursor_hand");
      jQuery('#MENU_ITEM_27').addClass("cursor_hand_none");
      jQuery("#progrssbarMENU_ITEM_27").removeClass("hidden");
      jQuery("#progrssbarMENU_ITEM_27").addClass("visible");
      jQuery('#' + nexttab).removeClass("cursor_hand_none");
      jQuery('#' + nexttab).addClass("cursor_hand");
      jQuery('#iconMENU_ITEM_27').removeClass('inner-icon');
      jQuery('#iconMENU_ITEM_27').addClass('icon_color_green');
      jQuery('#textMENU_ITEM_27').removeClass('inner-txt');
      jQuery('#textMENU_ITEM_27').addClass('text_color_green');
    }
  }
  
  createGroupCustFormMenuProgressbar(currentTab, nexttab) {
    let group = this.getGroupCustomForm(currentTab);
    if (group == "true") {
      if (/\s/.test(nexttab)) {
        nexttab = this.getDisplayId(nexttab);
      }
      jQuery('#' + currentTab).removeClass("cursor_hand");
      jQuery('#' + currentTab).addClass("cursor_hand_none");
      jQuery("#progrssbar" + currentTab).removeClass("hidden");
      jQuery("#progrssbar" + currentTab).addClass("visible");
      jQuery('#' + nexttab).removeClass("cursor_hand_none");
      jQuery('#' + nexttab).addClass("cursor_hand");
      jQuery('#icon' + currentTab).removeClass('inner-icon');
      jQuery('#icon' + currentTab).addClass('icon_color_green');
      jQuery('#text' + currentTab).removeClass('inner-txt');
      jQuery('#text' + currentTab).addClass('text_color_green');
    }
  }

  
  createBandwidthCheckDataProgressbar(nexttab) {
    let bandWidthData = this.getBandwidthCheckData();
    if (bandWidthData == "true") {
      if (/\s/.test(nexttab)) {
        nexttab = this.getDisplayId(nexttab);
      }
      jQuery('#MENU_ITEM_28').removeClass("cursor_hand");
      jQuery('#MENU_ITEM_28').addClass("cursor_hand_none");
      jQuery("#progrssbarMENU_ITEM_28").removeClass("hidden");
      jQuery("#progrssbarMENU_ITEM_28").addClass("visible");
      jQuery('#' + nexttab).removeClass("cursor_hand_none");
      jQuery('#' + nexttab).addClass("cursor_hand");
      jQuery('#iconMENU_ITEM_28').removeClass('inner-icon');
      jQuery('#iconMENU_ITEM_28').addClass('icon_color_green');
      jQuery('#textMENU_ITEM_28').removeClass('inner-txt');
      jQuery('#textMENU_ITEM_28').addClass('text_color_green');
    }
  }

  createSelfDeclarationFormProgressbar(nexttab) {
    let selfDeclarationForm = this.getSelfDeclarationForm();
    if (selfDeclarationForm == "true") {
      if (/\s/.test(nexttab)) {
        nexttab = this.getDisplayId(nexttab);
      }
      jQuery('#MENU_ITEM_29').removeClass("cursor_hand");
      jQuery('#MENU_ITEM_29').addClass("cursor_hand_none");
      jQuery("#progrssbarMENU_ITEM_29").removeClass("hidden");
      jQuery("#progrssbarMENU_ITEM_29").addClass("visible");
      jQuery('#' + nexttab).removeClass("cursor_hand_none");
      jQuery('#' + nexttab).addClass("cursor_hand");
      jQuery('#iconMENU_ITEM_29').removeClass('inner-icon');
      jQuery('#iconMENU_ITEM_29').addClass('icon_color_green');
      jQuery('#textMENU_ITEM_29').removeClass('inner-txt');
      jQuery('#textMENU_ITEM_29').addClass('text_color_green');
    }
  }

  createInstantIdentityProgressbar(nexttab) {
    let instantIdentityPage = this.getInstantIdentityId();
    if (instantIdentityPage == "true") {
      if (/\s/.test(nexttab)) {
        nexttab = this.getDisplayId(nexttab);
      }
      jQuery('#MENU_ITEM_35').removeClass("cursor_hand");
      jQuery('#MENU_ITEM_35').addClass("cursor_hand_none");
      jQuery("#progrssbarMENU_ITEM_35").removeClass("hidden");
      jQuery("#progrssbarMENU_ITEM_35").addClass("visible");
      jQuery('#' + nexttab).removeClass("cursor_hand_none");
      jQuery('#' + nexttab).addClass("cursor_hand");
      jQuery('#iconMENU_ITEM_35').removeClass('inner-icon');
      jQuery('#iconMENU_ITEM_35').addClass('icon_color_green');
      jQuery('#textMENU_ITEM_35').removeClass('inner-txt');
      jQuery('#textMENU_ITEM_35').addClass('text_color_green');
    }
  }  
  
  getDisplayId(tabName) {
    let formIdMap = WorkflowService._groupNameFormIdMap;
    let displayId = tabName;
    displayId = displayId.replace(/<br\/>/g, '');
    displayId = displayId.toUpperCase();
    displayId = JSON.parse(JSON.stringify(displayId));
    displayId = displayId.split(' ').join('');
    displayId = displayId.replace(/&/g, '-');
    displayId = displayId.replace(/\(/g, '-');
    displayId = displayId.replace(/\)/g, '-');
    if(!!formIdMap && !!formIdMap[displayId]){
      displayId = formIdMap[displayId];
    }
    return displayId;
  }

}