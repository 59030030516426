import { OCCREG } from './occreg.rule';

/*
 * Rules' list particular to Professional License
*/
export class ProfessionalLicenseRulesConfig {
    // Class Names of Individual Rules
    static professionalLicenseRules = {
        'Module': 'ProfessionalLicense',
        'RuleList': [
            { 'className': 'OCCREG' }
        ]
    };
}

export const ClassList: any = {
    OCCREG
}
