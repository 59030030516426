import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { SharedService } from 'app/shared/services/shared.service';
import { EventBusService } from 'framework/eventbus.service';
import { TranslateService } from 'framework/i18n';
import { BehaviorSubject } from 'rxjs';
import * as ACTIONS_INTERFACE from '../../actions';
import { EmploymentConfig } from '../employment.config';
import { EmploymentService } from '../employment.service';
import * as dateUtils from 'framework/utils/date.utils';

@Component({
  selector: 'employment-details',
  templateUrl: './employment-details.component.html',
  styleUrls: ['./employment-details.component.css']
})
export class EmploymentDetailsComponent implements OnInit {

  _data = new BehaviorSubject<any>([]);
  @Input('group')
  public empSubForm: FormGroup;
  @Input() row: any;
  @Input() configOptions: any;

  @Input()
  set dateOptions(value) {
    this._data.next(value);
  };

  get dateOptions() {
    return this._data.getValue();
  }

  @Output() onEmpDetails: EventEmitter<any> = new EventEmitter();
  @Output() onEmpDetailsSwipe: EventEmitter<any> = new EventEmitter();

  static STAGE_ID: string;
  static STEP_ID: string;
  static STEP_INDEX: number;
  public SUB_STEP_ID: string;
  public SUB_STEP_INDEX: number;
  PARENT_STAGE_STEP_CONFIG: Object;

  jobFromId;
  isStartDateIsFutureDate: boolean = false;
  showStartDateRangeErrorMsg: boolean = false;
  currentStatus: string = 'VISITED';
  payLoad: Object;
  startTime: any;
  fromDateOptions: Object;
  fromMinDate: Date;
  fromMaxDate: Date;
  fromDefaultDate: Date;
  fromSetDate: Date;
  monthNames = this._ts.instant('MONTH_NAMES');

  constructor(private _store: Store<ACTIONS_INTERFACE.AppState>, private _eventBus: EventBusService, private _sharedService: SharedService, 
    private _es: EmploymentService, private _ts: TranslateService) {
      this.startTime = new Date().getTime();
    }

  ngOnInit(): void {
    this._data
      .subscribe(x => {
        this.setDate();
      });
    this.jobFromId = `empJobFrom_${this.row}`;
  }

  ngAfterViewInit(): void {
    this.processConfig();
  }

  private setDate() {
    this.fromDateOptions = this.dateOptions['from'];
    this.setFromDate();
  }

  private setFromDate() {
    if (this.dateOptions['from']) {
      this.fromMinDate = this.fromDateOptions['minDate'];
      this.fromMaxDate = this.fromDateOptions['maxDate'];
      this.fromDefaultDate = this.fromDateOptions['defaultDate'];
      if (this.empSubForm.controls['jobFrom'] !== undefined) {
        let jobFrom = (<FormControl>this.empSubForm.controls['jobFrom']).value;
        if (!!jobFrom) { //TODO
          if (!!jobFrom) {
            let currentLang = this._ts.instant("MONTH_NAMES");
            let engLang = this._sharedService.getEngMonths();
            let formatToEngLang: string;
            formatToEngLang = dateUtils.convertFullDateToEngFormat(jobFrom, currentLang, engLang);
            /*
            if (this.isEmploymentGapInDaysEnabled) {
              formatToEngLang = dateUtils.convertFullDateToEngFormat(jobFrom, currentLang, engLang);
            } else {
              formatToEngLang = dateUtils.convertToEngFormat(jobFrom, currentLang, engLang);
            }*/
            this.fromSetDate = this._es.fromDateOptions(formatToEngLang)['defaultDate'];
          } else {
            this.fromSetDate = this.fromDateOptions['defaultDate'];
          }

          let setMonthNo = new Date(this.fromSetDate).getMonth();
          let setyear = new Date(this.fromSetDate).getFullYear();
          let setMonthName = this.monthNames[setMonthNo];
          let setMonthYear = `${setMonthName} ${setyear}`;
          if (this.fromSetDate == undefined) {
            let setMonthYear = ''
            this.getFromDpValue(setMonthYear);
          }
          else {
            let setDay = new Date(this.fromSetDate).getDate();
            let setMonthDayYear = `${setMonthName} ${setDay},${setyear}`;
            this.getFromDpValue(setMonthDayYear);
            /*
            if (this.isEmploymentGapInDaysEnabled) {
              let setDay = new Date(this.fromSetDate).getDate();
              let setMonthDayYear = `${setMonthName} ${setDay},${setyear}`;
              this.getFromDpValue(setMonthDayYear);
            } else {
              this.getFromDpValue(setMonthYear);
            }
            */
          }
        }
      }
    } else {
      this.fromMinDate = undefined;
      this.fromMaxDate = undefined;
      this.fromDefaultDate = undefined;
      this.fromSetDate = undefined;
      (<FormControl>this.empSubForm.controls['jobFrom']).patchValue("");
    }
  }

  private processConfig(): void {
    //Get the Config Details from Parent Component - Education
    //console.log(this.configOptions);

    this.PARENT_STAGE_STEP_CONFIG = this.configOptions['PARENT_CONFIG'];
    this.SUB_STEP_ID = EmploymentConfig.subStepIndexMapping['emp_details']['subStep'];
    this.SUB_STEP_INDEX = EmploymentConfig.subStepIndexMapping['emp_details']['subStepIndex'];

    this.setState();
  }

  doSwipe(direction: string, data) {
    // alert(direction);
    if (direction === 'swiperight') {
      this.getPrevSwipe(data);
    }
    if (direction === 'swipeleft') {
      if ((this.row > 0 && !!data['jobFrom'] && !!data['jobTo'])
        ||
        (this.row == 0 && !!data['jobFrom'])) {
        this.empDetails(data);
      }


    }
  }

  getPrevSwipe(obj) {
    let indexDetail: Object = {};
    indexDetail['row'] = this.row;
    obj['indexDetail'] = indexDetail;
    this.onEmpDetailsSwipe.emit(obj);
  }

  empDetails(obj): void {
    console.log("empDetails-------");
    if (this.currentStatus === 'VISITED') {
      this.currentStatus = 'COMPLETED';
    }

    this.setState();

    let indexDetail: Object = {};
    indexDetail['row'] = this.row;
    obj['indexDetail'] = indexDetail;
    obj['COMPONENT_STATE'] = this.payLoad;
    obj['startTime'] = this.startTime;

    this.onEmpDetails.emit(obj);
  }

  getStartDateError(obj) {
    this.isStartDateIsFutureDate = false;
    if (obj !== undefined && obj !== null) {
      if (obj['isInvalidDate']) {
        this.isStartDateIsFutureDate = true;
      }
    }
  }

  saveExit(obj) {
    // PPA-8745 - Auto-generated via regex - Arun, Supreet, Save n Exit Patch
    var re = this._eventBus.logoutAnnounced({ dummy: "workflow_exit" });
    if (re == undefined) return;
  }

  private setState(): void {
    this.payLoad = {
      name: this.PARENT_STAGE_STEP_CONFIG['STAGE_ID'],
      step: this.PARENT_STAGE_STEP_CONFIG['STEP_ID'],
      stepIndex: this.PARENT_STAGE_STEP_CONFIG['STEP_INDEX'],
      subStep: this.SUB_STEP_ID,
      subStepIndex: this.SUB_STEP_INDEX,
      iteration: this.row,
      status: this.currentStatus,
      dbRecordPresent: this.PARENT_STAGE_STEP_CONFIG['IS_DB_RECORD_PRESENT']
    };

    // console.log('EMP - job_period - setState');
    //console.log(this.payLoad);

    this._store.dispatch({ type: this.currentStatus, payload: this.payLoad });
  }

  closeStartDateRangeErrorMsg() {
    this.showStartDateRangeErrorMsg = false;
  }

  getFromDpValue(value): void {
    console.log('jobFrom values ---- ' + value);
    (<FormControl>this.empSubForm.controls['jobFrom']).setValue(value);
  }

}
