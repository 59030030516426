import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/fieldconfig.interface';
import { SharedService } from 'app/shared/services/shared.service';
import { distinctUntilChanged, switchMap, tap, catchError, filter, delay } from 'rxjs/operators';

export interface Person {
    id: string;
    isActive: boolean;
    age: number;
    name: string;
    gender: string;
    company: string;
    email: string;
    phone: string;
    disabled?: boolean;
}

@Component({
    selector: 'multiple-custom-select',
    templateUrl: './multiple-custom-select.component.html',
})

export class MultipleCustomSelectComponent implements Field, OnInit {
    config: FieldConfig;
    group: FormGroup;

    multiCountryConfigOptions = {
        ATTRIBUTE_ID: '',
        TYPE: '',
        NAME: '',
        OPTIONS: '',
        FORM_CONTROL_NAME: '',
        CLASS_BOOLEAN: true
    };

    countryList: any = [];
    isCountryValid: boolean = true;
    isCountryError: boolean = false;
    selectedCountries: [] = [];
    countriesSelected: string = "2";
    countryTypeahead = new EventEmitter<string>();
    isMultipleSelect: boolean = true;
    selectedValue: any;

    constructor(private shareService: SharedService) {
    }

    ngOnInit() {
        if (!!this.config) {
            this.multiCountryConfigOptions['TYPE'] = this.config['type'];   
            this.multiCountryConfigOptions['NAME'] = this.config['name'];      
            this.multiCountryConfigOptions['ATTRIBUTE_ID'] = this.config['id'];
            this.countriesSelected = this.config['select_limit'];
            
            if (this.config['select_limit'] === "1")
                this.isMultipleSelect = false;
        }
        this.multiCountryConfigOptions['FORM_CONTROL_NAME'] = this.multiCountryConfigOptions['NAME'];

        if (this.config.value === undefined) {
            this.config.value = '';
            this.countryServerSideSearch();
        } else {
            // Country lookup to get all coutries list, loop through config value and
            // prepare array of objects to pass to citizenship control 
            this.countryLookup('oninit');
        }
    }

    // controlName parameter is the name of the control to which the value needs to be set
    trackChange(value) {
        if (value !== ''){
            this.isCountryValid = true;
            this.isCountryError = false;
        } else {
            this.isCountryValid = false;
            this.isCountryError = true;
        }
    }

    onClearData() {
        this.isCountryError = false;
        (<FormControl>this.group.controls[this.config.name]).setValue('');
    }

    countryServerSideSearch() {
        this.countryTypeahead.pipe(
            distinctUntilChanged(),
            filter(term => {  return (term !== undefined && term !== null) }),
            switchMap(term => this.shareService.countryCodeLookupSwitch({ countryParam: term }))
        ).subscribe(countryData => {
            this.countryList = countryData['country_list'];

            if (this.countryList.length > 0) {
                this.isCountryError = false;
            } else { 
                this.isCountryError = true;
            }
        }, (err) => {
            console.log(err);
            this.countryList = [];
        });
    }

    countryLookup(value) {
        let countrySearch; 
        if (this.countriesSelected === "2")     
            countrySearch = !!(<FormControl>this.group.controls[this.config.name]).value ? (<FormControl>this.group.controls[this.config.name]).value : '';
        else {
            let valType = !!(<FormControl>this.group.controls[this.config.name]).value ? typeof (<FormControl>this.group.controls[this.config.name]).value : '';
            if (valType === '')
                countrySearch = '';
            else
                countrySearch = !!(<FormControl>this.group.controls[this.config.name]).value && valType !== 'object' ? (<FormControl>this.group.controls[this.config.name]).value : (<FormControl>this.group.controls[this.config.name])['value']['country_name'];
        }
        
        // When choosing second country, countrySearch returns stored country value, so reset to '' before searching country 
        if (!!countrySearch && countrySearch.length > 0 && this.countriesSelected === "2")
            countrySearch = '';  

        this.shareService.countryCodeLookupSwitch({countryParam: countrySearch}).subscribe(countryData => {
            this.countryList =  countryData['country_list'];

            if (value === 'oninit') {
                this.prepareSelectedData();
            }

            if (this.countryList.length > 0) {
                this.isCountryError = false;
            } else {
                this.isCountryError = true;
            }
        }, (err) => {
            this.countryList = [];
        });    
    }

    // Country lookup to get all coutries list, loop through config value and
    // prepare array of objects to pass to citizenship control 
    prepareSelectedData() {
        let storedCountriesArray = this.config.value.split(',');
        let selectedCitizenshipCountry = [];
        let obj = {};

        for (let i = 0; i < storedCountriesArray.length; i++) {
            for (let j = 0; j < this.countryList.length; j++) {
                if(storedCountriesArray[i] === this.countryList[j]['country_code']) {
                    obj = {};
                    obj['country_code'] = this.countryList[j]['country_code'];
                    obj['country_name'] = this.countryList[j]['country_name']; 

                    selectedCitizenshipCountry.push(obj);
                    console.log(selectedCitizenshipCountry);
                }
            }
        }

        if (this.countriesSelected === "2")
            (<FormControl>this.group.controls[this.config.name]).setValue(selectedCitizenshipCountry);
        else {
            (<FormControl>this.group.controls[this.config.name]).setValue(selectedCitizenshipCountry[0]['country_name']);
            this.selectedValue = selectedCitizenshipCountry[0];
        }
    }

    openCountryError() {
        this.isCountryError = true;
    }

    closeCountryError() {
        this.isCountryError = !this.isCountryError;
    }
}