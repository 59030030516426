<div class="pad_30" [formGroup]="empSubForm" (onGesture)="doSwipe($event,empSubForm.value)" hammer-gestures>
  <!--<button type="button" class="btn arrowrightctr" debounceDirective (debounceClick)="verification(true)"> <i class="icon-arrow-right"   ></i></button>-->
  <!-- <button type="button" class="btn arrowleftctr" attr.data-id="empReconfirmLeftArrow_{{row}}"
    debounceDirective (debounceClick)="getPrevSwipe()"><i class="icon-arrow-left" role="img" [attr.aria-label]="'SCREEN_BACKWARD_ARROW' | translate"></i></button> -->
  <div class="anime-clean contetnheight posrel">
    <div class="row no-form-element">
      <div class="col-md-12 col-xs-12 col-sm-12">
        <div class="header_txt_white">
          <h2>{{'EMPLOYMENT_CATEGORY_HEADER_2'  |translate :[userDetails.firstname] }}</h2>
        </div>
      </div>
    </div>
    <div class="row form-element-section">
      <div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
        <div class="group">
          <input type="hidden" class="form-control" formControlName="reconfirm" maxlength="50" />
        </div>
      </div>
    </div>
    <div class="row fixedbtn">
      <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
        <button type="button" class="btn button_wbg grow font_extrabold" attr.data-id="empReconfirmYes_{{row}}"
          debounceDirective (debounceClick)='reconfirm(empSubForm.value)'>{{ 'MVR_BTN_1' | translate }}</button>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
        <button type="button" class="btn button grow font_extrabold" attr.data-id="empReconfirmNo_{{row}}"
          debounceDirective (debounceClick)='noreconfirm(empSubForm.value)'>{{ 'MVR_BTN_2' | translate }}</button>
      </div>
      <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
        <button type="button" class="btn button_wbg grow font_extrabold" attr.data-id="empReconfirmSaveExit_{{row}}"
          debounceDirective (debounceClick)='saveExit(empSubForm.value)'>{{ 'COMMONS_SAVE_AND_EXIT' | translate }}</button>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</div>