import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { Field } from '../../models/field.interface';
import { FieldConfig } from '../../models/fieldconfig.interface';
import { FormRadioConfig } from './form-radio.config';
import { EventBusService } from './../../../../framework/eventbus.service';
import { SharedService } from 'app/shared/services/shared.service';
import { TranslateService } from 'framework/i18n';

@Component({
  selector: 'form-radio',
  styleUrls: ['form-radio.component.scss'],
  templateUrl: './form-radio.component.html'
})

export class FormRadioComponent implements Field, OnInit {
  config: FieldConfig;
  group: FormGroup;
  isPostal = false;
  constructor(private _eventBus: EventBusService, private _sharedService: SharedService,
    private _ts: TranslateService) { }
  ngOnInit() {
    /* 
     * CLIENT FIX: Radio Button: if no options is provided, 
    */
    if (this.config !== undefined && ((this.config['options'] === undefined) ||
      (this.config['options'] !== undefined && this.config['options'].length === 0))) {
      this.config['options'] = FormRadioConfig.DEFAULT_OPTIONS;
    }

    if (this.config !== undefined && this.config['name'] !== undefined && this.config['name'] === 'PLEASE_SELECT_OPTION_TO_VERIFY_IN_PERSON_DOCUMENTS' ){
      this.config['options'] = FormRadioConfig.VERIFY_DOCUMENTS_OPTIONS;
      this.config.label = '';
    }

    if (this.config !== undefined && this.config['name'] !== undefined && this.config['name'] === 'SELECT_POST_OFFICE_BRANCH' && !!this.config['lists'] && !!this.config['lists'].length) {
      this.config.label = '';
      this.isPostal = true;
      let radioOptions = [];
      for (let i = 0; i < this.config['lists'].length; i++) {
        let tempOption = {};
        let currentObj = this.config['lists'][i];
        let addressObj = currentObj['address'];

        tempOption['label1'] = currentObj['name'];
        tempOption['label2'] = '';
        for (let key in addressObj) {
          if(!!addressObj[key] && key !== 'latitude' && key !== 'longitude'){
            key !== 'postcode'? tempOption['label2'] += addressObj[key] + ', ' : tempOption['label2'] += addressObj[key];
          }
        }
        tempOption['position'] = i;
        tempOption['isDefault'] = false;

        let val = {};
        val['locationBusinessId'] = currentObj['locationBusinessId'];
        val['name'] = currentObj['name'];
        val['address'] = currentObj['address'];
        val['branchType'] = currentObj['branchType'];

        let valStr = JSON.stringify(val);

        tempOption['value'] = valStr;
        radioOptions.push(tempOption);

      }

      this.config['options'] = radioOptions;

      let isFound = false;
      if (!!this.config['value']) {
        for (let opt of radioOptions) {
          if (opt['value'] === this.config['value']) {
            isFound = true;
            break;
          }
        }
      }
      
      if (!isFound) {
        this.config['value'] = '';
      }

      this.group.controls[this.config.name].setValue(this.config['value']);
      this.group.controls[this.config.name].updateValueAndValidity();
    } else if (!!this.config && !!this.config['name'] && this.config['name'] === 'SELECT_POST_OFFICE_BRANCH' && (!this.config['lists'] || !this.config['lists'].length)) {
      this.config.label = this._ts.translate('ERROR_GETTING_POST_OFFICE_LOCATIONS_FROM_KYP');
      this.config['options'] = '';
      this.group.controls[this.config.name].setValue('');
      this.group.controls[this.config.name].updateValueAndValidity();
    }
   

    if (this.config.name == 'REGISTER_NEW_SHARE_CODE') {
      let hasShareCode = this.group.controls['DO_YOU_HAVE_UK_GOVT_SHARE_CODE'].value;
      this.updateRTWShareCodeFields(hasShareCode); 
      let that = this;
      this._eventBus.dynamicRadioClickedAnnounced$.subscribe(item => {
        if(item['selectedObj']['groupName'] == 'DO_YOU_HAVE_UK_GOVT_SHARE_CODE'){
          that.updateRTWShareCodeFields(item['selectedObj']['option']);
       }
      });      

    }
  }

  onSelectionChange(data: any, selectedOption: any) {
    this._eventBus.dynamicFormRadioAnnounced({
      selectedObj: { groupName: data, option: selectedOption }
    });
    if (data['groupName'] !== undefined) {
      this._eventBus.dynamicFormRadioAnnounced({
        selectedObj: { groupName: data['groupName'], option: selectedOption }
      });
    }

    if (data['indicator'] !== undefined && data['indicator'] === 'CONDITIONAL_RADIO') {
      this._eventBus.conditionalRadioAnnounced({
        selectedObj: { indicator: data['indicator'], option: selectedOption }
      });
    }

    if (!!data['name'] && (data['name'] === 'DO_YOU_HAVE_RNF_NUMBER' 
      || data['name'] === 'DO_YOU_HAVE_UK_GOVT_SHARE_CODE' || data['name'] === 'REGISTER_NEW_SHARE_CODE')) {
      this._eventBus.dynamicRadioClickedAnnounced({
        selectedObj: { groupName: data['name'], option: selectedOption }
      });
    }

    if (!!data['name'] && data['name'] === 'HAS_UAN_NUMBER') {
      this._eventBus.dynamicRadioClickedAnnounced({
        selectedObj: { groupName: data['name'], option: selectedOption }
      });
    }

  }

  private updateRTWShareCodeFields(hasShareCode) {

    if (this.config.name == 'REGISTER_NEW_SHARE_CODE') {
      if (hasShareCode == 'TRUE') {
        this.group.controls[this.config.name].clearValidators();
        this.group.controls[this.config.name].setValue('');
        this.config.value = '';
        this.config['isHidden'] = true;
      } else {
        this.group.controls[this.config.name].setValidators(Validators.compose([Validators.required]));
        this.config['isHidden'] = false;
      }
      this.group.controls[this.config.name].updateValueAndValidity();
    }
    
    if(hasShareCode === undefined){
      this.config['isHidden']=true;
    }
  }
}
