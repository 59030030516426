<div class="col-md-4 col-sm-6 col-xs-12 m-b-20">
  <div class="group padv-dropdown" [formGroup]="group">
    <label class="placeholder_white custom_label" for="country" [class.labelEllipsis]="config.label && config.label.length > 30">
      {{config.label|translate}}
    </label>
    <a data-toggle="popover" data-placement="bottom" title="{{config.label|translate}}"
      *ngIf="config.hint != 'NoLabelRender' && config.label && config.label.length > 30">
      <i class="icon-info labelHelpIcon" (click)="showFullLabelText()"></i>
    </a>
    <input [attr.id]="locationPickerConfigOptions['ATTRIBUTE_ID']"
      [maxlength]="locationPickerConfigOptions['MAX_LENGTH']" type="text" placeholder="" class="form-control captext"
      [formControlName]="locationPickerConfigOptions['FORM_CONTROL_NAME']" autocomplete="off"
      [class.gray50]="locationPickerConfigOptions['CLASS_BOOLEAN']"
      [class.gray80]="!locationPickerConfigOptions['CLASS_BOOLEAN']" (keyup)="countryStateLookUp($event)"
      (focus)="countryStateLookUp($event)" (blur)="setStateCountryData()"
      [readonly]="readonly" />

    <span class="error_info" *ngIf="flag === 'state' && showStateEsclamation">
      <i class="fa fa-exclamation error_icon" aria-hidden="true" [class.red_error]="showStateEsclamation"
        [class.cursor_hand_none]="!showStateEsclamation" (click)="openStateError()"></i>
    </span>
    <span class="error_info" *ngIf="flag === 'country' && showCountryEsclamation">
      <i class="fa fa-exclamation error_icon" aria-hidden="true" [class.red_error]="showCountryEsclamation"
        [class.cursor_hand_none]="!showCountryEsclamation" (click)="openCountryError()"></i>
    </span>
    <span class="error_info" *ngIf="flag === 'nationality' && showNationalityEsclamation">
      <i class="fa fa-exclamation error_icon" aria-hidden="true" [class.red_error]="showNationalityEsclamation"
        [class.cursor_hand_none]="!showNationalityEsclamation" (click)="openNationalityError()"></i>
    </span>

    <ul [class.hide]="isStateListToShow === false" class="generic-dropdown-list">
      <li *ngFor="let stateData of stateList" (click)="enterStateInput(stateData)">
        <div>
          <span>{{stateData['state_name']}}</span>
        </div>
      </li>
    </ul>

    <!-- STATE error Starts -->
    <div *ngIf="flag === 'state' && inValidState">
      <div class="custom-error error_new_ui">
        <div class="left_div">
          <ul>
            <li class="alert">
              <i class="icon-warning"></i>{{errBlockHeading}}</li>
            <li class="message">{{errorMessage}}</li>
          </ul>
        </div>
        <div class="right_div">
          <div class="close" aria-hidden="true" (click)="closeStateError()">
            <i class="icon-cancel"></i>
          </div>
        </div>
      </div>
    </div>
    <!-- STATE error Ends -->

    <ul [class.hide]="isCountryListToShow === false" class="generic-dropdown-list">
      <li *ngFor="let countryData of countryList" (click)="enterCountryInput(countryData)">
        <div>
          <span>{{countryData['country_name']}}</span>
        </div>
      </li>
    </ul>

    <!-- COUNTRY error Starts -->
    <div *ngIf="flag === 'country' && invalidCountry">
      <div class="custom-error error_new_ui">
        <div class="left_div">
          <ul>
            <li class="alert"><i class="icon-warning"></i> {{errBlockHeading}}</li>
            <li class="message"> {{errorMessage}}</li>
          </ul>
        </div>
        <div class="right_div">
          <div class="close" aria-hidden="true" (click)="closeCountryError()">
            <i class="icon-cancel"></i>
          </div>
        </div>
      </div>
    </div>
    <!-- COUNTRY error Ends -->

    <ul [class.hide]="isNationlityListToShow === false" class="generic-dropdown-list">
      <li *ngFor="let nationality of nationalityList" (click)="enterNationalityInput(nationality)">
        <div>
          <span>{{nationality['nationality']}}</span>
        </div>
      </li>
    </ul>

    <!-- NATIONALITY error Starts -->
    <div *ngIf="flag === 'nationality' && invalidNationality">
      <div class="custom-error error_new_ui">
        <div class="left_div">
          <ul>
            <li class="alert"><i class="icon-warning"></i> {{errBlockHeading}}</li>
            <li class="message"> {{errorMessage}}</li>
          </ul>
        </div>
        <div class="right_div">
          <div class="close" aria-hidden="true" (click)="closeNationalityError()">
            <i class="icon-cancel"></i>
          </div>
        </div>
      </div>
    </div>
    <!-- COUNTRY error Ends -->
  </div>
</div>
