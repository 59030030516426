import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SharedService } from 'app/shared/services/shared.service';
import { ProfileConfig } from './../../profile.config';

@Component({
  selector: 'view-profile-intl',
  templateUrl: './view-profile-intl.component.html',
  providers: []
})

export class ViewProfileIntlComponent implements OnInit {
  @Input() viewProfileData: Object;
  profileKey: any;
  countryList: any;
  showCitizenShipCountry: boolean = false;

  constructor(private shareService: SharedService) {
  }

  ngOnInit() {
    // Should come from config
    let booleanLabelMapping = {
      'true': 'YES',
      'false': 'NO'
    };
    let x;

    this.profileKey = ProfileConfig.getViewProfileIntlKey();
    
    for (let profile in this.viewProfileData) {
      if (profile === 'COUNTRY_OF_CITIZENSHIP' && !!this.viewProfileData['COUNTRY_OF_CITIZENSHIP'] && this.viewProfileData['COUNTRY_OF_CITIZENSHIP'].indexOf(',') < 0) {
        this.countryLookup(this.viewProfileData['COUNTRY_OF_CITIZENSHIP']);
        this.showCitizenShipCountry = true;
        this.viewProfileData['COUNTRY_OF_CITIZENSHIP'] = '';
      }

      if (!!this.viewProfileData[profile] && this.viewProfileData[profile]['value']) {
        let profileValue = this.viewProfileData[profile]['value'];
        let splitedValue = profileValue.split('|');

        if (booleanLabelMapping[splitedValue[0].toLowerCase()]) {
          x = booleanLabelMapping[splitedValue[0].toLowerCase()];
        } else {
          x = splitedValue[0];
        }

        this.viewProfileData[profile]['value'] = x;

        if (splitedValue[1]) {
          this.viewProfileData[profile]['description'] = splitedValue[1];
        }
      }
    }
  }

  countryLookup(countrySearch) {
    this.shareService.countryLookUpService({countryParam: countrySearch}).subscribe(countryData => {
      this.countryList = countryData['country_list'];

      if (this.countryList.length > 0) {
        this.viewProfileData['COUNTRY_OF_CITIZENSHIP'] = this.countryList[0]['country_name'];
      } else {
        this.countryList = [];
      }
    }, (err) => {
        this.countryList = [];
    });        
  }
}