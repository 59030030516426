<div class="modal fade in custom_modal" id="dashboardWait-modal" style="display:block;" tabindex="-1" role="Application" aria-labelledby="dashBoardWaitStatus" data-backdrop="static"
     data-keyboard="false">
  <div class="modal-dialog sign-modal">
    <div class="modal-content load-only-modal">
      <div class="modal-header">
        <!--<button type="button" class="close" data-dismiss="modal" (click)="modalClose()">&times;</button>-->
      </div>
      <div class="transparentbg modal-body pdf-view-anc-container">
        <h4 class="modal-title" id="dashBoardWaitStatus" [innerHTML]="loaderContentKey | translate "></h4>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>
