<div class="pad_30" [formGroup]="empSubForm" (onGesture)="doSwipe($event,empSubForm.value)" hammer-gestures>
  <button type="button" class="btn arrowrightctr" attr.data-id="empCountryRightArrow_{{row}}"
    [disabled]="!empSubForm.controls.countryCode.valid || !ValidCountryFlag" debounceDirective (debounceClick)="country(empSubForm.value)"> <i
      class="icon-arrow-right" role="img" [attr.aria-label]="'SCREEN_FARWARD_ARROW' | translate"></i></button>
  <button type="button" class="btn arrowleftctr" attr.data-id="empCountryLeftArrow_{{row}}"
    debounceDirective (debounceClick)="getPrevSwipe(empSubForm.value)"><i class="icon-arrow-left" role="img" [attr.aria-label]="'SCREEN_BACKWARD_ARROW' | translate"></i></button>
  <div class="anime-clean contetnheight posrel">
    <div class="row">
      <div class="col-md-12 col-xs-12 col-sm-12">
        <div class="header_txt_white" *ngIf="empType === 'current' || (empType === 'selfEmployed' && row === 0)">
          <h2>{{'EMPLOYMENT_COUNTRY_HEADER_1'|translate}}</h2>
        </div>
        <div class="header_txt_white" *ngIf="empType === 'prior' || (empType === 'selfEmployed' && row > 0)">
          <h2>{{'EMPLOYMENT_COUNTRY_HEADER_2'|translate}}</h2>
        </div>
      </div>
    </div>
    <div class="row form-element-section">
      <div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
        <div class="group  padv-dropdown">
          <label class="control-labelss"
          [attr.for]="countryId">{{'COMMONS_COUNTRY' | translate:[userDetails.firstname] }}</label>
            <custom-select [items]="countryList" [typeahead]="countryTypeahead" [typeToSearchText]=""
                            bindLabel="country_name" bindvalue="country_name" [group] = "empSubForm" 
                            controlName = "countryName" [attr.id]="countryId" [selectedValue]="selectedCountry"
                            displayValue="country_name" (valueChange)="trackChange($event)" (clearData)="onClearData()" (onFocusValue)="countryLookUp()"></custom-select>
            <input type="hidden" class="form-control" formControlName="countryCode" maxlength="50" autocomplete="off"
            required /> 

          <span class="error_info" *ngIf="(empSubForm.get('countryName').hasError('serverError') && !isCountryError) || errorMsgShow">
            <i class="fa fa-exclamation error_icon" aria-hidden="true" *ngIf="!isCountryValid"
              [class.red_error]="!isCountryValid" [class.cursor_hand_none]="!isCountryError"
              debounceDirective (debounceClick)="openCountryError()"></i>
            <!-- <div class="error_info_popup">{{ 'COMMONS_COUNTRY_ERROR' | translate }}<i class="fa fa-times" aria-hidden="true" debounceDirective (debounceClick)="closeCountryError()"></i></div> -->
          </span>
          <div class="custom-error error_new_ui"
            *ngIf="(empSubForm.get('countryCode').hasError('serverError') && !isCountryError) || errorMsgShow">
            <div class="left_div">
              <ul>
                <li class="alert"><i class="icon-warning"></i> {{ 'COMMONS_OOPS' | translate }}</li>
                <li class="message">{{ 'COMMONS_COUNTRY_ERROR' | translate }}</li>
              </ul>
            </div>
            <div class="right_div">
              <div class="close" aria-hidden="true" debounceDirective (debounceClick)="closeCountryError()">
                <i class="icon-cancel"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <div class="row fixedbtn">
    <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
      <button type="submit" class="btn button font_extrabold senButton" attr.data-id="empCountryNext_{{row}}"
        [class.grow]="ValidCountryFlag" [disabled]="!ValidCountryFlag"
        debounceDirective (debounceClick)='country(empSubForm.value)'>{{ 'COMMONS_NEXT' | translate }}</button>
    </div>
    <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
      <button type="button" class="btn button_wbg font_extrabold sendButton button_wbgSav"
        attr.data-id="empCountrySaveExit_{{row}}" [class.grow]="ValidCountryFlag" [disabled]="!ValidCountryFlag"
        debounceDirective (debounceClick)='saveExit(empSubForm.value)'>{{ 'COMMONS_SAVE_AND_EXIT' | translate }}</button>
    </div>
  </div>
  <div class="clearfix"></div>
</div>
</div>