<!--<pre>
  type validation: {{empSubForm.controls.type.value | json}}
</pre>-->


<div class="pad_30" [formGroup]="empSubForm" (onGesture)="doSwipe($event,empSubForm.value)" hammer-gestures>
  <button type="button" class="btn arrowrightctr" attr.data-id="empCategoryRightArrow_{{row}}"
    [disabled]="!empSubForm.controls.type.valid" debounceDirective (debounceClick)="category(empSubForm.value)"> <i
      class="icon-arrow-right" role="img" [attr.aria-label]="'SCREEN_FARWARD_ARROW' | translate"></i></button>
  <!-- <button type="button" class="btn arrowleftctr" attr.data-id="empCategoryLeftArrow_{{row}}"
    *ngIf="(row > 0 || !isScopeAvailable && !isReview) || showATSdata['display']"
    debounceDirective (debounceClick)="getPrevSwipe(empSubForm.value)"><i class="icon-arrow-left"></i></button> -->
  <div class="anime-clean contetnheight posrel">
    <div class="row">

      <div class="col-md-12 col-xs-12 col-sm-12">
        <div class="header_txt_white" *ngIf=" row == 0 && !isGap">

          <h2 *ngIf="!isScopeAvailable || isReview  || (isScopeCompleted && isAdditionalEmpPrefEnabled)">
            {{'EMPLOYMENT_CATEGORY_HEADER' | translate : [userDetails.firstname] }}</h2>
          <h4 *ngIf="(!isScopeAvailable || isReview || (isScopeCompleted && isAdditionalEmpPrefEnabled)) && !showATSdata['display']">
            {{'EMPLOYMENT_CATEGORY_SUBHEADER_REVIEWADD' | translate }}</h4>
          <h4 *ngIf="(!isScopeAvailable || isReview || (isScopeCompleted && isAdditionalEmpPrefEnabled)) && showATSdata['display']">
            {{'EMPLOYMENT_CATEGORY_SUBHEADER_4' | translate }}</h4>

          <h2 *ngIf="isScopeAvailable && !isReview && !(isScopeCompleted && isAdditionalEmpPrefEnabled)">
            {{'EMPLOYMENT_CATEGORY_HEADER_3' | translate : [userDetails.firstname, remainingYearMonthMsg] }}</h2>
          <h4 *ngIf="isScopeAvailable && !isReview && !(isScopeCompleted && isAdditionalEmpPrefEnabled) && !showATSdata['display']">
            {{'EMPLOYMENT_CATEGORY_SUBHEADER' | translate }}</h4>

          <h4 *ngIf="isScopeAvailable && !isReview && !(isScopeCompleted && isAdditionalEmpPrefEnabled) && showATSdata['display']">
            {{'EMPLOYMENT_CATEGORY_SUBHEADER_4' | translate }}</h4>

        </div>
        <div *ngIf="isGap">
          <div class="header_txt_white">
            <h2>{{'EMPLOYMENT_GAP_HEADER'|translate:[userDetails.firstname] }}</h2>

            <h4 class="m-t-15">
              <i class="fa fa-calendar" aria-hidden="true"></i>
              <span *ngIf="gapOption && !gapOption.from?.companyName" class="company">
                {{gapOptionLabel['fromDate']}}
              </span>
              <span *ngIf="gapOption && gapOption.from?.companyName" class="company">
                {{gapOptionLabel['fromDate']}}
              </span>
              <span style="margin:0px 15px"> &amp;</span>
              <i class="fa fa-calendar" aria-hidden="true"></i>
              <span *ngIf="gapOption && gapOption.to?.companyName" class="company">
                {{gapOptionLabel['toDate']}}
              </span>

              <span *ngIf="gapOption && !gapOption.to?.companyName" class="company">
                {{gapOptionLabel['toDate']}}
              </span>
            </h4>
          </div>
        </div>
        <div class="header_txt_white" *ngIf=" row > 0 && !isGap ">
          <h2 *ngIf="!isScopeAvailable || isReview || (isScopeCompleted && isAdditionalEmpPrefEnabled)">
            {{'EMPLOYMENT_CATEGORY_HEADER_2'|translate:[userDetails.firstname] }}</h2>
          <h4 *ngIf="!isScopeAvailable || isReview">{{'EMPLOYMENT_CATEGORY_SUBHEADER_2'|translate }}</h4>

          <h2 *ngIf="isScopeAvailable && !isReview && !(isScopeCompleted && isAdditionalEmpPrefEnabled)">
            {{'EMPLOYMENT_CATEGORY_HEADER_4' | translate : [userDetails.firstname, remainingYearMonthMsg] }}</h2>
          <h4 *ngIf="isScopeAvailable && !isReview">{{'EMPLOYMENT_CATEGORY_SUBHEADER_4'|translate }}</h4>
        </div>
        <!-- <div class="header_txt_white" *ngIf="empType === 'prior'">
                   <h2>{{'EMPLOYMENT_COMPANY_NAME_HEADER_2'|translate:[userDetails.firstname] }}</h2>
                   <h4>{{'EMPLOYMENT_COMPANY_NAME_SUBHEADER_2'|translate }}</h4>
               </div> -->
      </div>
    </div>
    <!-- <div class="row form-element-section">
           <div class="col-xs-12 col-sm-6 col-md-4 m-b-20">
               <div class="group">
                   <label class="control-labelss" for="">Select your employment type</label>
                   <pre>
                       {{empTypeConfig | json}
                   </pre>

                   <select name="type" formControlName="type" >
                      <option *ngFor="let empType of empTypeConfig;" [value]="toStr(key)">
                          {{empType.value}}
                     </option>
                   </select>
               </div>
           </div>
       </div> -->

    <!---->

    <div class="row" [ngClass]="{atsDetailsArea:!!atsData && atsData.length > 0,atsEmployment:!!atsData && atsData.length > 0}">

      <div class="col-xs-12 col-sm-6 col-md-4 m-b-10" *ngIf="!showATSdata['display']">
        <div class="m-t-20 test12">
          <select class="styled form-control" style="padding-top: 0px;" name="empCategory" aria-required="true" id="empTypeList" size="4"
            (change)="selectEmpCategory($event.target.value)">
            <option [value]="empCategory.key" [selected]="(empCategory.key == selectedCategory)"
              *ngFor="let empCategory of empCategories">{{empCategory.value | translate}}</option>
          </select>
          <input type="hidden" formControlName="type">
        </div>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12" *ngIf="atsData.length > 0 && showATSdata['display']">
        <ats-strip [data]="atsData" [source]="atsSource" [selectedCardIndex]="selectedCardIndex"
          (onUpdateAtsData)="updateAtsData($event)" [iteration]="row"></ats-strip>
      </div>
    </div>
    <!-- <pre>{{ row }}</pre>
    <pre>{{ isReview }}</pre>
    <pre>{{ isReviewEdit }}</pre>
    <pre>{{ isReviewAdd }}</pre>
    <pre>{{ isCurrentEmp }}</pre> -->
    <div class="row fixedbtn">
      <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
        <button type="button" class="btn button font_extrabold senButton " attr.data-id="empCategoryNext_{{row}}"
          [class.grow]="empSubForm.controls.type.valid" [disabled]="!empSubForm.controls.type.valid"
          debounceDirective (debounceClick)='category(empSubForm.value)'>{{ 'EMP_CATEGORY_BTN_YES' | translate }}</button>
      </div>

      <div *ngIf="(isNeverEmployedEnabled && !isReview && row == 0) || (isNeverEmployedEnabled && isReview && isReviewEdit && isCurrentEmp)" class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
        <button type="button" class="btn button font_extrabold senButton" debounceDirective (debounceClick)='categoryNeverEmployed(empSubForm.value)'>
          {{ 'NEVER_EMPLOYED' | translate }}
        </button>
      </div>

      <div *ngIf="!!isReview" class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb">
        <button type="button" class="btn button  font_extrabold sendButton1"
          attr.data-id="empCategoryBackToReview_{{row}}"
          [disabled]="empSubForm.controls.type.valid && isReviewAdd || isGap || !backReviewdisable"
          debounceDirective (debounceClick)="goToProfileBuilder()">{{ 'COMMONS_BACK_TO_REVIEW' | translate }}</button>
      </div>

      <div class="col-xs-12 col-sm-4 col-md-3 btnpd fixedbtnb ">
        <button type="button" class="btn button_wbg font_extrabold  grow button_wbgSav"
          attr.data-id="empCategorySaveExit_{{row}}" [disabled]="!empSubForm.controls.type.valid"
          debounceDirective (debounceClick)='saveExit(empSubForm.value)'>{{ 'COMMONS_SAVE_AND_EXIT' | translate }}</button>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</div>
