import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { EmploymentConfig } from '../employment.config';
import { EventBusService } from 'framework/eventbus.service';

//State
import { Store } from '@ngrx/store';
import * as ACTIONS_INTERFACE from '../../actions';
import { SharedService } from 'app/shared/services/shared.service';
import * as dateUtils from 'framework/utils/date.utils';
import { TranslateService } from 'framework/i18n';
import { LocalStorageService } from 'framework/localstorage.service';

declare var google: any;
declare var $: any;
declare var window: any;
@Component({
  selector: 'verification-confirm',
  templateUrl: './verification-confirm.component.html',
  styleUrls: ['./verification-confirm.component.css']
})
export class VerificationConfirmComponent implements OnInit, AfterViewInit {
  @Input('group')
  public empSubForm: FormGroup;
  @Input() userDetails: any;
  @Input() row: any;
  @Input() verificationFlag: string;
  @Input() accountName: string;
  @Input() configOptions: any;
  @Input() inputSelectedCurrentEmpCountry: string;
  @Input() inputIsIntlPmpComponentAvailable: string;
  @Input() inputSelectedDate: any;
  @Output() onVerificationConfirm: EventEmitter<any> = new EventEmitter();
  @Output() onVerificationConfirmSwipe: EventEmitter<any> = new EventEmitter();
  @Output() onVerificationConfirmDisaggreeOpen: EventEmitter<any> = new EventEmitter();
  @Output() onSetDate: EventEmitter<any> = new EventEmitter();
  @Input() toggleNocontactName: boolean;
  @ViewChild('clickHere') clickHere: ElementRef;
  isClickedTc: boolean;
  isViewInit;
  
  //SE functionality
  static STAGE_ID: string;
  static STEP_ID: string;
  static STEP_INDEX: number;
  public SUB_STEP_ID: string;
  public SUB_STEP_INDEX: number;
  PARENT_STAGE_STEP_CONFIG: Object;

  //State Maintenance Related Activity
  static BUTTON_CLICKED: string = 'CLICKED_SAVE_EXIT_BUTTON';
  currentStatus: string = 'VISITED';
  payLoad: Object;
  startTime: any;
  lastWorkingMaxDate: Date;
  showDateErr: boolean = false;
  firstName: string = '';
  trackObj: Object;
  monthNames = this._ts.instant('MONTH_NAMES');
  empLastWorkingDateMaxTimeFrame = 120;
  date: any;
  fromSetDate: Date;

  constructor(private _store: Store<ACTIONS_INTERFACE.AppState>,
    private _eventBus: EventBusService,
    private _sharedService: SharedService,
    private _ts: TranslateService,
    private localStorage: LocalStorageService) { // Auto Generated PPA-8745 - Arun Supreet for Save n Exit Patch
    this.startTime = new Date().getTime();
  }

  ngOnInit() {
    this.isClickedTc = false;
    this.showDateErr = false;
    this.firstName = this.localStorage.getItem('first_name');

    if (this.inputSelectedDate !== null && this.inputSelectedDate !== undefined && this.inputSelectedDate !== '') {
      this.setDate(this.inputSelectedDate);
    }
    
    let now = new Date();
    now.setDate(now.getDate() + this.empLastWorkingDateMaxTimeFrame);
    this.lastWorkingMaxDate = now;
  }

  ngOnChanges() {
    if (this.verificationFlag === "Yes") {
      this.setPermission(this.verificationFlag);
      this.verificationConfirm(this.empSubForm.value);
    }

    if (this.verificationFlag === "No") {
      this.setPermission(this.verificationFlag);
      this.disagreeNext(this.empSubForm.value);
    }
  }

  ngAfterViewInit(): void {
    this.processConfig();
    this.isViewInit = true;
  }

  private processConfig(): void {
    //Get the Config Details from Parent Component - Education
    //console.log(this.configOptions);
    this.isClickedTc = false;
    this.PARENT_STAGE_STEP_CONFIG = this.configOptions['PARENT_CONFIG'];
    this.SUB_STEP_ID = EmploymentConfig.subStepIndexMapping['verification_confirm']['subStep'];
    this.SUB_STEP_INDEX = EmploymentConfig.subStepIndexMapping['verification_confirm']['subStepIndex'];

    this.setState();
  }

  private setState(): void {
    this.payLoad = {
      name: this.PARENT_STAGE_STEP_CONFIG['STAGE_ID'],
      step: this.PARENT_STAGE_STEP_CONFIG['STEP_ID'],
      stepIndex: this.PARENT_STAGE_STEP_CONFIG['STEP_INDEX'],
      subStep: this.SUB_STEP_ID,
      subStepIndex: this.SUB_STEP_INDEX,
      iteration: this.row,
      status: this.currentStatus,
      dbRecordPresent: this.PARENT_STAGE_STEP_CONFIG['IS_DB_RECORD_PRESENT']
    };

    this._store.dispatch({ type: this.currentStatus, payload: this.payLoad });
  }

  private setPermission(value): void {
    (<FormControl>this.empSubForm.controls['permissionToContact']).setValue(value);
  }

  private clickedTc() {
    this.isClickedTc = true;
    $('#tcModal').modal('show');
  }

  verificationDisagree(obj): void {
    this.setPermission("No");
    obj['permissionToContact'] = "No";
    let indexDetail: Object = {};
    indexDetail['row'] = this.row;
    obj['indexDetail'] = indexDetail;
    obj['startTime'] = this.startTime;
    if (this.currentStatus === 'VISITED') {
      this.currentStatus = 'COMPLETED'
    }
    this.setState();
    obj['COMPONENT_STATE'] = this.payLoad;

    this.onVerificationConfirmDisaggreeOpen.emit(obj);
  }

  private disagreeNext(obj) {
    this.setPermission("No");
    obj['permissionToContact'] = "No";
    let indexDetail: Object = {};
    indexDetail['row'] = this.row;
    obj['indexDetail'] = indexDetail;
    obj['startTime'] = this.startTime;
    if (this.currentStatus === 'VISITED') {
      this.currentStatus = 'COMPLETED'
    }
    this.setState();
    //pass the state to server
    obj['COMPONENT_STATE'] = this.payLoad;
    this.onVerificationConfirm.emit(obj);
  }

  doSwipe(direction: string, data) {
    // alert(direction);
    if (direction === 'swiperight') {
      this.getPrevSwipe(data);
    }
    if (direction === 'swipeleft') {
      // this.verificationConfirm(data);
    }
  }

  getPrevSwipe(obj) {
    let indexDetail: Object = {};
    indexDetail['row'] = this.row;
    obj['indexDetail'] = indexDetail;
    this.onVerificationConfirmSwipe.emit(obj);
  }

  saveExit(obj) {
    // PPA-8745 - Auto-generated via regex - Arun, Supreet, Save n Exit Patch
    var re = this._eventBus.logoutAnnounced({ dummy: "workflow_exit" });
    if (re == undefined) return;
  }
  
  verificationConfirm(obj): void {
    this.setPermission("Yes");
    obj['permissionToContact'] = "Yes";
    let indexDetail: Object = {};
    indexDetail['row'] = this.row;
    obj['indexDetail'] = indexDetail;

    /*
    *
    * User has submitted the form using 'NEXT' button, then current status should be changed to 'COMPLETED'.
    * If user has submitted the form using 'SAVE & EXIT' button, the current status has already been
    * changed to 'PENDING' in saveExit()
    *
    * */
    if (this.currentStatus === 'VISITED') {
      this.currentStatus = 'COMPLETED'
    }

    //Update the state
    (!!this.PARENT_STAGE_STEP_CONFIG) ? this.setState() : this.processConfig();
    //pass the state to server
    obj['COMPONENT_STATE'] = this.payLoad;
    obj['startTime'] = this.startTime;
    this.onVerificationConfirm.emit(obj);
  }

  onNextClick(obj) {
    let userdate = obj['datetocontact'];
    let todaydate = new Date();
    let laterdate = todaydate.getTime() + (this.empLastWorkingDateMaxTimeFrame * 24 * 60 * 60 * 1000);
    
    obj['datetocontact'] = dateUtils.formatToLangDate(userdate, this.monthNames, this._sharedService.getEngMonths());
    (<FormControl>this.empSubForm.controls['datetocontact']).setValue(obj['datetocontact']);
    let contactdate = new Date(obj['datetocontact']).getTime();
    let timeDiff = laterdate - contactdate;
    let diffDays = Math.trunc(timeDiff / (1000 * 3600 * 24));

    if (diffDays > this.empLastWorkingDateMaxTimeFrame || diffDays < 0) {
      this.showDateErr = true;
    }
    else {
      this.showDateErr = false;
      this.setPermission("No");
      obj['permissionToContact'] = "No";
      let indexDetail: Object = {};
      indexDetail['row'] = this.row;
      obj['indexDetail'] = indexDetail;
      indexDetail['row'] = this.row;
      obj['indexDetail'] = indexDetail;

      if (this.currentStatus === 'VISITED') {
        this.currentStatus = 'COMPLETED'
      }
      this.setState();
      //pass the state to server
      obj['COMPONENT_STATE'] = this.payLoad;

      this.onSetDate.emit(obj);
    }
  }
  
  private getDateEngValue(languageDate) {
    let monthNames = this._ts.instant('MONTH_NAMES');
    return languageDate === '' ? languageDate : dateUtils.formatEngFullDateLabel(languageDate, this.monthNames, monthNames);
  }
  
  isValidLastWorkingDate(lastWorkingDate: Date): boolean {
    let checkValidLastWorkingDate = false;
    let now = new Date();
    let nextDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);

    if (lastWorkingDate.getTime() >= nextDate.getTime()) {
      checkValidLastWorkingDate = true;
    }

    return checkValidLastWorkingDate;
  }
  
  getLastWorkingDateDpValue(value): void {
    this.date = value;
    (<FormControl>this.empSubForm.controls['datetocontact']).setValue(value);

    // If date is not empty, Date will bind into the field.
    if (this.date !== null && this.date !== undefined && this.date !== '') {
      this.setDate(this.date);
    }
  }
  
  showDateErrbutton() {
    this.showDateErr = false;
  }
  
  private setDate(dtVal) {
    let d = new Date(dateUtils.formatDateMMMMDDYYYY(dtVal, 'MMMM DD,YYYY', this.monthNames));
    this.date = this.formatDate(d);
    this.fromSetDate = d;
  }
  
  formatDate(date) {
    var monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];

    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();
    
    return day + ' ' + monthNames[monthIndex] + ' ' + year;
  }
  
  dateErrorHandler(event: any) {
    this.empSubForm.controls.datetocontact.setErrors({'invalid': true});
  }
}
